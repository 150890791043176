import React from 'react';
import { Typography } from '@mui/material';
import { ReadNowWrapper } from './ReadNow.style';
import DOMPurify from 'dompurify';

const ReadNow = ({ children, ...props }) => {
    return (
        <ReadNowWrapper>
            {children && (
                <>
                    <Typography
                        className="text"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(children, { ALLOWED_TAGS: ['strong', 'b'] })
                        }}
                    />

                    <Typography className="read-hide-text">{'read now »'}</Typography>
                </>
            )}
        </ReadNowWrapper>
    );
};

export default ReadNow;
