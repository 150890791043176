import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function usePermissions(module) {
    const [canAccess, setCanAccess] = useState({});
    const permissions = useSelector((state) => state.App.permissions);

    useEffect(() => {
        if (!permissions?.length)
            return setCanAccess({
                permissionLoaded: false,
                canAccess: true,
                isHidden: false,
                badge: ''
            });
        let currentModule = permissions?.find((permission) => permission?.moduleName === module);

        setCanAccess({
            canAccess: module ? currentModule?.canAccess : true,
            isHidden: module ? currentModule?.isHidden : false,
            badge: module ? currentModule?.badge : '',
            permissionLoaded: true
        });
    }, [module, permissions]);

    return canAccess;
}

export default usePermissions;
