import styled from 'styled-components';

import { Box, Popover } from '@mui/material';
import { COLORS, FONTS, BREAKPOINTS_VALUE } from 'Styles/Constants';

export const ChatWrapper =  styled(Box)`
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #B59F6C;
    padding-top: 70px;
    position: relative;
    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
        /* max-height: calc(100vh - 70px); */
        overflow-y: hidden;
        padding-top: 70px;
    }
    @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET_500}px) {
        max-height: 100vh;
    }
    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
        align-items: unset;
    }
    .back-button-container {
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                display: flex;
            }
            display: none;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            overflow: hidden;
            border-radius: 50%;
            transform: rotate(90deg);
            .arrow-btn {
                width: 100%;
                height: 100%;
                svg {
                    width: 23px;
                    height: 23px;
                    margin-top: 4px;
                    path {
                        fill: white;
                    }
                }
            }
        }
    .mobile-top-container {
        display: none;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
            display: flex;
            align-items: center;
            width: 98%;
        }
        position: absolute;
        top: 1.1rem;
        left: 1rem;
        z-index: 7;
        
        .mobile-title-container {
            display: flex;
            align-items: center;
            flex-grow: 1;
            .title {
                letter-spacing: -0.02em;
                text-transform: capitalize;
                font-weight: 700;
                font-size: 40px;
                line-height: 40px;
                font-family: ${FONTS.SECONDARY_BOLD};
                color: white;
            }
        }
        .mobile-new-conv-btn {
            background-color: white;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 18px;
        }
    }
    .page-content {
        max-width: 938px;
        display: flex;
        position: relative;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1200}px) {
            margin-left: 70px;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1100}px) {
            margin-left: 106px;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
            /* margin-top: 110px; */
            margin-left: 0px;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
            /* margin-top: 110px; */
            width: 100%;
            max-width: 600px;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
            max-width: 100%;
        }
        .left-container {
            display: flex;
            flex-direction: column;
            width: 383px;
            margin-right: auto;
            position: relative;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
                width: 340px;
            }
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                width: 100%;
                max-width: 600px;
            }
            .title-container {
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
                    display: none;
                }
                display: flex;
                justify-content: space-between;
                width: 90%;
                align-items: center;
                .title {
                    letter-spacing: -0.02em;
                    text-transform: capitalize;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 40px;
                    font-family: ${FONTS.SECONDARY_BOLD};
                    color: white;
                }
                .new-chat-btn {
                    background-color: white;
                    color: ${COLORS.PRIMARY_DARK};
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
                    border-radius: 12px;
                    height: 60px;
                    width: 169px;
                }
            }
            .friends-container {
                display: flex;
                flex-direction: column;
                width: 90%;
                position: relative;
                .friends {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 26px;
                    color: white;
                    padding-left: 8px;
                }
                .friends-list {
                    max-width: 90%;
                    margin: 0 auto;
                    margin-top: 8px;
                    overflow-x: auto;
                    overflow-y: hidden;
                    display: flex;
                    gap: 10px;
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                        width: 100%;
                        max-width: 600px;
                        margin: 0 8px;
                    }
                    .friend-card {
                        min-height: 85px;
                        min-width: 85px;
                        border-radius: 8px;
                        overflow: hidden;
                        /* background-position: center; */
                        background-repeat: no-repeat;
                        background-size: cover;
                        .skeleton {
                            height: 85px;
                            width: 85px;
                        }
                    }
                    .blur {
                        pointer-events: none;
                        position: absolute;
                        background: linear-gradient(90deg, rgba(196, 196, 196, 0) 10%, rgb(181 159 108 / 75%) 53.19%);
                        height: 100%;
                        width: 85px;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        z-index: 15;
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
                            display: none;
                        }
                    }
                }
            }
            .chat-list-option-container {
                position: relative;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                }
                .conversation-edit-options {
                    position: absolute;
                    bottom: 0;
                    /* padding: 40px 20px; */
                    height: 107px;
                    width: 100%;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    background-color: white;
                    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
                    .option-btn {
                        font-size: 20px;
                        line-height: 28px;
                        color: #656565;
                        text-transform: capitalize;
                    }
                }
                .conversation-opt-animate-enter {
                    height: 0;
                    opacity: 0;
                }
                .conversation-opt-animate-enter-active {
                    transition: height 450ms, opacity 450ms;
                    height: 107px;
                    opacity: 1;
                }
                .conversation-opt-animate-exit {
                    height: 107px;
                    opacity: 1;
                }
                .conversation-opt-animate-exit-active {
                    height: 0;
                    opacity: 0;
                    transition: height 450ms, opacity 450ms;
                }
            }
            .conversation-container {
                width: 100%;
                max-height: 585px;
                height: 58vh;
                overflow-y: auto;
                background-color: white;
                box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.1);
                border: 0.5px solid #E8E8E8;
                margin-top: 25px;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                position: relative;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                    flex-grow: 1;
                    border-radius: 16px 16px 0 0;
                }
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                    max-height: none;
                }
                &.padding-bottom {
                    padding-bottom: 107px;
                }
                .conversation-title-container {
                    padding: 15px 0 8px 25px;
                    .title {
                        color: ${COLORS.PRIMARY_DARK};
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 40px;
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD}
                    }
                    .edit-btn {
                        color: #656565;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 28px;
                        padding: 0;
                        /* justify-content: flex-start; */
                        display: block;
                        text-align: left;
                        width: max-content;
                        text-transform: none;
                    }
                }
                .conversation-list-container {
                    /* padding-top: 14px; */
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    .skeleton-conversation-container {
                        display: flex;
                        /* justify-content: space-between; */
                        gap: 10px;
                        align-items: center;
                        padding: 18px;
                        .profile-container {
                            width: 85px;
                            height: 85px;
                            border-radius: 8px;
                            overflow: hidden;
                            background-color: #f7f7f7;
                            .skeleton {
                                width: 85px;
                                height: 85px;
                            }
                        }
                        .conversation-name {
                            flex-grow: 1;
                            .skeleton {
                                height: 24px;
                                width: 100%;
                                &:nth-child(2) {
                                    width: 85%;
                                    height: 16px;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                    .chat-container {
                        display: flex;
                        flex-direction: column;
                        max-width: 100%;
                        overflow-x: hidden;
                        .conversation-tab {
                            border-radius: 8px;
                            padding: 17px 12px;
                            display: flex;
                            align-items: center;
                            transition: background-color 300ms;
                            min-width: 100%;
                            /* gap: 10px; */
                            cursor: pointer;
                            -webkit-tap-highlight-color: transparent;
                            &:nth-child(2n) {
                                background-color: rgb(181 159 108 / 25%);
                            }
                            &.selected-tab {
                                background-color: #B08C3D;
                                border: 2px solid #FFFFFF;
                                .conversation-info-container {
                                    .name {
                                        color: white;
                                    }
                                    .message {
                                        color: white;
                                    }
                                }
                                .count-container {
                                    margin-bottom: auto;
                                    .time {
                                        margin-top: 10px;
                                        color: white;
                                    }
                                    .notification-count {
                                        display: none;
                                    }
                                }
                            }
                            .selector-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 0 20px;
                                .check-box {
                                    border-radius: 50%;
                                }
                            }
                            .conv-details {
                                min-width: 100%;
                                display: flex;
                                align-items: center;
                            }
                            .profile-image {
                                min-width: 85px;
                                height: 85px;
                                overflow: hidden;
                                background-size: cover;
                                background-repeat: no-repeat;
                                border-radius: 8px;
                                position: relative;
                            }
                            .group-chat-2-image-1 {
                                position: absolute;
                                background-size: cover;
                                background-repeat: no-repeat;
                                top: 0;
                                left: 0;
                                min-width: 50px;
                                height: 50px;
                                z-index: 5;
                                border-radius: 8px;
                                border: 1px solid #E8E8E8;
                            }
                            .group-chat-2-image-2 {
                                position: absolute;
                                bottom: 0;
                                border-radius: 8px;
                                right: 0;
                                min-width: 50px;
                                height: 50px;
                                background-size: cover;
                                background-repeat: no-repeat;
                                border: 1px solid #E8E8E8;
                            }
                            .group-chat-3-images {
                                display: flex;
                                flex-wrap: wrap;
                                gap: 4px;

                                & > div {
                                    min-width: 40px;
                                    height: 40px;
                                    border: 1px solid #E8E8E8;
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    border-radius: 8px;
                                }

                                .addition-people {
                                    background-color: #656565;
                                    color: white;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 23px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                } 
                            }
                            .conversation-info-container {
                                display: flex;
                                flex-direction: column;
                                flex-grow: 1;
                                overflow: hidden;
                                margin-left: 10px;
                                margin-right: 6px;
                                .name {
                                    font-weight: 500;
                                    font-size: 18px;
                                    line-height: 20px;
                                    text-overflow: ellipsis;
                                    width: 100%;
                                    white-space: nowrap;
                                    overflow: hidden;
                                }
                                .message {
                                    color: #656565;
                                    font-size: 14px;
                                    line-height: 21px;
                                    max-height: 42px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    /* white-space: nowrap; */
                                    overflow: hidden;
                                    width: 100%;
                                }
                            }
                            .count-container {
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                align-items: flex-end;
                                min-width: 64px;
                                .time {
                                    color: #656565;
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                                .notification-count {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    background-color: ${COLORS.PRIMARY};
                                    color: white;
                                    width: 28px;
                                    height: 28px;
                                    border-radius: 50%;
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                            }
                            .more-btn-container {
                                margin-bottom: auto;
                                .more-icon {
                                    /* padding: 0; */
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }
                
            }
        }

        .right-container{
            display: flex;
            flex-direction: column;
            position: relative;
            transition: width 600ms;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                display: none;
            }
            &.min-width {
                min-width: 538px;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
                    min-width: 440px;
                }
            }
        }        

        .conversation-animate-enter {
            width: 0;
            opacity: 0;
            margin-left: 0;
        }
        .conversation-animate-enter-active {
            width: 538px;
            opacity: 1;
            margin-left: 21px;
            transition: width 300ms, opacity 300ms, margin-left 300ms;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1200}px) {
                margin-left: 10px;
            }
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
                width: 440px;
            }
        }
        .conversation-animate-enter-done {
            width: 538px;
            margin-left: 21px;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1200}px) {
                margin-left: 10px;
            }
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
                width: 440px;
            }
        }
        .conversation-animate-exit {
            width: 538px;
            opacity: 1;
            margin-left: 21px;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1200}px) {
                margin-left: 10px;
            }
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
                width: 440px;
            }
        }
        .conversation-animate-exit-active {
            transition: width 300ms, opacity 300ms, margin-left 300ms;
            width: 0;
            opacity: 0;
            margin-left: 0;
        }

        /* .conversation-animate-exit-done {
            width: 0;
            opacity: 0;
        } */
    }
`;

export const ChatWindowWrapper = styled(Box)`
    flex-grow: 1;
    width: 100%;
    max-height: 75vh;
    min-height: 75vh;
    /* max-height: 703px; */
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.1);
    margin-top: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &.chat-tablet-style {
        position: fixed;
        z-index: 15;
        min-width: 100vw;
        min-height: 100vh;
        margin-left: 0px;
        left: 0;
        top: 0;
        border-radius: 0;
        background-color: #B59F6C;
        .back-button-container {
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                display: flex;
            }
            display: none;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            overflow: hidden;
            border-radius: 50%;
            transform: rotate(90deg);
            .arrow-btn {
                width: 100%;
                height: 100%;
                svg {
                    width: 23px;
                    height: 23px;
                    margin-top: 4px;
                    path {
                        fill: white;
                    }
                }
            }
        }
        .chat-members-container {
            /* background-color: #B59F6C; */
            padding-left: 18px;
            .add-btn {
                background-color: white;
                svg {
                    color: ${COLORS.PRIMARY_DARK}
                }
            }
        }
        .name-list-container {
            /* background-color: #B59F6C; */
            .names {
                color: white;
                padding-left: 40px;
            }
        }
        .messages-container {
            border-radius: 36px 36px 0 0;
            background-color: white;
            padding-top: 40px;
        }
    }
    .chat-members-container {
        padding: 18px 22px 10px 22px;
        /* flex-grow: 1; */
        display: flex;
        align-items: center;
        .members-list {
            display: flex;
            gap: 8px;
            overflow-x: auto;
            flex-grow: 1;
            .member-image {
                background-size: cover;
                background-repeat: no-repeat;
                min-width: 60px;
                max-width: 60px;
                min-height: 60px;
                max-height: 60px;
                border-radius: 8px;
                border: 1px solid #E8E8E8;
            }
        }
        .add-btn {
            background-color: ${COLORS.PRIMARY_DARK};
            width: 52px;
            height: 52px;
            border-radius: 50%;
            color: white;
            margin-top: auto;

        }
    }

    .name-list-container {
        display: flex;
        padding-left: 22px;
        .names {
            /* width: 100%;
            height: 40px; */
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            font-size: 28px;
            line-height: 40px;
            text-transform: capitalize;
            color: ${COLORS.PRIMARY_DARK};
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .messages-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding-bottom: 12px;
        .date-container {
            display: flex;
            align-items: center;
            margin-top: 12px;
            /* flex-direction: column; */
            .line {
                flex-grow: 1;
                border: 1px solid #E8E8E8;
            }
            .date {
                padding: 0 8px;
                font-size: 16px;
                line-height: 22px;
            }
        }

        .messages {
            .message {
                display: flex;
                flex-direction: column;
                max-width: 65%;
                margin-top: 12px;
                
                &.to {
                    margin-right: 20px;
                    margin-left: auto;
                    .message-container-shape {
                        background-color: #F2E9D8;
                        border-radius: 16px 16px 0px 16px;
                        margin-left: auto;
                    }
                }
                &.from {
                    margin-left: 20px;
                    margin-right: auto;
                    .message-container-shape {
                        background-color: #e8e8e8cc;
                        border-radius: 0px 16px 16px 16px;
                    }
                }

                .name {
                    color: #848484;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                }
                .message-text-container {
                    padding: 20px 16px;
                    padding-bottom: 6px;
                    width: max-content;
                    display: flex;
                    /* flex-direction: column; */
                    max-width: 80%;
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET_500}px) {
                        max-width: 90%;
                    }
                    .text {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 26px;
                        color: #31290E;
                    }
                }

                .message-audio-container {
                    display: flex;
                    align-items: center;
                    padding: 20px 16px;
                    padding-top: 15px;
                    padding-bottom: 6px;
                    width: max-content;
                    max-width: 80%;
                    gap: 10px;
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET_500}px) {
                        max-width: 90%;
                    }
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET_500}px) {
                        max-width: 100%;
                    }
                    .play-pause-icon-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50px;
                        height: 50px;
                    }
                    .audio-container {
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        .wave-icon {
                            width: 100%;
                        }
                        .progress-bar {
                            width: 100%;
                            position: relative;
                            background-color: #8484848f;
                            height: 3px;
                            overflow: hidden;
                            border-radius: 28px;
                            .progress {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                border-radius: 28px;
                                background-color: #656565;
                            }
                        }
                        .timing-container {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .player-time {
                                color: #656565;
                                font-size: 12px;
                                line-height: 108.1%;
                            }
                        }
                    }   
                }
                .message-time {
                    margin-left: auto;
                    width: max-content;
                    width: 18%;
                    min-width: 70px;
                    margin-left: auto;
                    color: #848484;
                    font-size: 12px;
                    line-height: 17px;
                    text-align: right;
                    /* padding-left: 12px; */
                }
                
            }
        }

    }

    .user-message-container {
        width: 100%;
        background-color: #E8E8E8;
        min-height: 78px;
        display: flex;
        align-items: center;
        padding-left: 22px; 
        .message-input {
            font-weight: 400;
            font-size: 18px;
            /* line-height: 26px; */
            color: #656565;
            background-color: white;
            border-radius: 30px;
            height: 52px;
            /* width: 85%; */
            flex-grow: 1;
            input {
                margin-left: 1.2rem;
            }
            &::placeholder {
                color: #656565;
            }
            .send-btn {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: #E8E8E8;
                margin: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                .send-icon {
                    margin-top: 2px;
                    margin-right: 1px;
                }
            }
        }

        .recorder-btn {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            margin-left: 8px;
            margin-right: 18px;
        }
    }
        
`;

export const MessagePopover = styled(Popover)`
    .popover-container {
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
        border-radius: 0px 14px 14px 14px;
        width: 188px;
        /* height: 94px; */
        /* padding: 10px; */
        .edit-icon {
            path {
                stroke: #656565;
            }
        }
        .text{

        }
    }
`;

export const ChatOptionsWrapper = styled(Popover)`
    .popover-container {
        width: 188px;
        /* box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05); */
        border-radius: 14px 0px 14px 14px;
        svg.option-icon {
                /* fill: #848484;
                stroke: #848484; */
                width: 36px;
                height: 36px;
                path {
                    /* fill: #848484 !important; */
                    stroke: #848484;
                }

                &.pin-icon {
                    path {
                        fill: #848484;
                        stroke: transparent;
                    }
                }
                &.delete-icon {
                    width: 36px;
                    height: 36px;
                    path[fill="#B08C3D"] {
                        fill: #848484;
                        stroke: transparent;
                    }
                }
        }
    }
`;

export const NewChatWrapper = styled(Box)`
    flex-grow: 1;
    width: 100%;
    max-width: 538px;
    max-height: 75vh;
    min-height: 75vh;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    /* position: absolute; */
    margin-left: 21px;
    margin-top: auto;
    background-color: #B59F6C;
    bottom: 0;
    @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1200}px) {
        margin-left: 10px;
    @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
        width: 440px;
    }
    }
    &.min-width {
        min-width: 538px;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
            min-width: 440px;
        }
    }
    &.tablet-styles {
        position: fixed;
        z-index: 15;
        min-width: 100vw;
        min-height: 100vh;
        margin-left: 0px;
        left: 0;
        top: 0;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE}px) {
            width: 100%;
        }
    }
    .new-title-container {
        display: flex;
        justify-content: space-between;
        padding: 30px 20px;
        .title {
            color: white;
            font-size: 40px;
            line-height: 40px;
            font-family: ${FONTS.SECONDARY_SEMI_BOLD};
            font-weight: 700;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                margin-right: auto;
            }
        }
        .btn {
            font-size: 20px;
            line-height: 28px;
            margin-top: auto;
            color: white;
            text-transform: capitalize;
            height: max-content;
            padding-top: 0;
            padding-bottom: 0;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                display: none;
            }
        }
    }

    .filter-input {
        background-color: #A8905C;
        border: 2px solid #A8905C;
        border-radius: 35px;
        height: 50px;
        padding-left: 20px;
        width: 90%;
        margin: 0 auto;
        input {
            margin-left: 1rem;
            margin-top: 2px;
            font-weight: 400;
            font-size: 18px;
            color: white;
            &::placeholder {
                color: white;
            }
        }

        .search-icon {
            width: 30px;
            height: 30px;
            path {
                fill: white;
            }
        }

        .clear-btn {
            background-color: white;
            width: 38px;
            height: 38px;
            margin-right: 6px;
            svg {
                path {
                    fill: ${COLORS.PRIMARY_DARK};
                }
            }
        }
    }

    .list-container {
        margin-top: 28px;
        
        .title {
            color: white;
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            font-size: 18px;
            line-height: 110%;
            padding-left: 24px;
        }
        .list {
            display: flex;
            flex-direction: column;
            max-height: 130px;
            overflow-y: auto;
            ::-webkit-scrollbar-thumb {
                background-color: white;
            }
            .list-item {
                padding: 13px 37px 12px 20px;
                display: flex;
                align-items: center;
                &:nth-child(2n + 1) {
                    background-color: #ffffff1a;
                }
                .profile-image {
                    width: 40px;
                    height: 40px;
                    border-radius: 8px;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                .name {
                    font-size: 16px;
                    font-family: ${FONTS.PRIMARY_BOLD};
                    line-height: 23px;
                    color: white;
                    margin-left: 25px;
                }
            }
        }
    }
`;

export const AddMemberModalWrapper = styled(Box)`
    flex-grow: 1;
    width: 100%;
    max-width: 538px;
    max-height: 75vh;
    min-height: 75vh;
    /* max-height: 703px; */
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.1);
    margin-top: auto;
    margin-left: 21px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1200}px) {
        margin-left: 10px;
    }
    @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
        width: 440px;
    }
    &.add-member-tablet-style {
        position: fixed;
        z-index: 15;
        min-width: 100vw;
        min-height: 100vh;
        margin-left: 0px;
        left: 0;
        top: 0;
        border-radius: 0;
        .arrow-btn {
            svg {
                path {
                    fill: #848484;
                }
            }
        }
        .title {
            margin-right: auto;
        }
        .btn {
            display: none;
        }
    }
    .add-member-title-container {
        display: flex;
        justify-content: space-between;
        padding: 30px 20px 8px 20px;
        .title {
            color:  ${COLORS.PRIMARY_DARK};
            font-size: 40px;
            line-height: 40px;
            font-family: ${FONTS.SECONDARY_BOLD};
            font-weight: 700;
        }
        .btn {
            font-size: 20px;
            line-height: 28px;
            margin-top: auto;
            color: white;
            text-transform: capitalize;
            height: max-content;
            padding-top: 0;
            padding-bottom: 0;
            color: #848484;
        }
    }
    .filter-input-container {
        padding: 0 19px;
        margin-top: 8px;
        .filter-input {
            background-color: #F2F2F2;
            border: 1px solid #DBDBDB;
            border-radius: 35px;
            height: 50px;
            padding-left: 20px;
            width: 100%;
            margin: 0 auto;
            input {
                margin-left: 1rem;
                margin-top: 2px;
                font-weight: 400;
                font-size: 18px;
                /* line-height: 26px; */
                color: #656565;
                &::placeholder {
                    color: #656565;
                }
            }
        }
    }
    .profile-image {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            .remove-icon {
                top: -4px;
                right: -4px;
                position: absolute;
                padding: 0;
            }
        }
        .selected-list-container {
            display: flex;
            padding: 0 19px;
            align-items: center;
            /* overflow: hidden; */
            .selected-list {
                display: flex;
                gap: 10px;
                flex-grow: 1;
                min-height: 48px;
            }
            .add-btn-container {
                padding: 0 6px;
                .add-btn {
                    background-color: #C3A157;
                    border-radius: 20px;
                    color: white;
                    min-height: 40px;
                }
            }
        }

    .list-container {
        margin-top: 16px;
        flex-grow: 1;
        overflow-y: auto;
        .title {
            color: #656565;
            margin-bottom: 11px;
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            font-size: 18px;
            line-height: 20px;
            padding-left: 24px;
        }
        .list {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            .list-item {
                padding: 13px 37px 12px 20px;
                display: flex;
                align-items: center;
                cursor: pointer;
                &:nth-child(2n + 1) {
                    background-color: rgba(176, 140, 61, 0.1);
                }
                .name {
                    font-size: 16px;
                    font-family: ${FONTS.PRIMARY_BOLD};
                    line-height: 23px;
                    color: #31290E;
                    margin-left: 25px;
                }
            }
        }
    }

    .selection-container-enter {
        height: 0;
        opacity: 0;
        /* padding: 0; */
    }
    .selection-container-enter-active {
        height: 48px;
        opacity: 1;
        /* padding: 8px 19px; */
        transition: height 300ms, opacity 300ms;
    }
    .selection-container-enter-done {
        /* min-height: 48px; */
    }
    .selection-container-exit {
        height: 48px;
        opacity: 1;
        /* padding: 8px 19px; */
    }
    .selection-container-exit-active {
        transition: height 300ms, opacity 300ms;
        height: 0;
        opacity: 0;
        /* padding: 0; */
    }
    .selection-container-exit-done {
        /* padding: 0; */
    }
`;