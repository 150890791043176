import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ChoosePaymentWrapper } from './ChoosePayment.style';
import { ReferralShadowBoxLayout } from '../Referral.style';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_URL, URL_ORDER_SUCCESS } from 'Helpers/Paths';
import Api from 'Helpers/ApiHandler';
import { digitsFormatter } from 'Helpers';
import TaoLoader from 'Components/common/TaoBackdropLoader';

const PAYMENT_METHODS = {
    POINTS: 'POINTS',
    CASH: 'CASH'
};

const PointsMethod = ({ availableRewardPoints, totalPriceInPoint, ...props }) => (
    <Box className="flex full-width f-v-center f-h-space-between">
        <Typography className="reward-points">{totalPriceInPoint} Reward Points</Typography>
        <Typography
            className={`available-points ${
                availableRewardPoints >= totalPriceInPoint && 'enough-points'
            }`}>
            {digitsFormatter(availableRewardPoints)} <span className="text">available</span>
        </Typography>
    </Box>
);

const CashMethod = ({ totalPrice, ...props }) => (
    <Box>
        <Typography className="cash">${totalPrice}+Tax</Typography>
    </Box>
);
const ChoosePayment = () => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PAYMENT_METHODS.POINTS);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const API = useMemo(() => new Api(), []);
    const [isLoading, setLoading] = useState(false);
    const location = useLocation();
    const [data] = useState(location?.state?.data);
    const [isOrderFailed, setOrderFailed] = useState(false);

    const orderPlace = useCallback(async () => {
        try {
            setLoading(true);
            let response = await API.post(API_URL.ORDER, {
                data: {
                    cartItemIds: data?.cartItemList.map((item) => item?.cartItemId),
                    isPaidByPoints: true,
                    isPaidByPrice: false
                }
            });

            if (response) {
                setLoading(false);
                navigate(URL_ORDER_SUCCESS, { state: { data: { ...response?.data?.data } } });
            }
        } catch (error) {
            setOrderFailed(true);
            setLoading(false);
        }
    }, [API, data?.cartItemList, navigate]);

    useEffect(() => {
        dispatch(hideSideBar());

        return () => dispatch(showSideBar());
    }, [dispatch]);
    return (
        <React.Fragment>
            <TaoLoader isLoading={isLoading} />
            <TaoCalligraphyWatermark />
            <ChoosePaymentWrapper>
                <ReferralShadowBoxLayout>
                    <Typography className={`title ${isOrderFailed && 'order-failed'}`}>
                        {isOrderFailed ? 'Order Failed' : 'Choose a Payment Method'}
                    </Typography>
                    <Box className="container">
                        <Box className="scrollable-area">
                            {isOrderFailed && (
                                <Typography className="order-failed-info">
                                    There was a problem with your order. Please see the message(s)
                                    in <span className="danger">red</span> below.
                                </Typography>
                            )}
                            <Typography className="sub-title">Items</Typography>

                            {data?.cartItemList?.map((item, index) => (
                                <React.Fragment key={item?.cartItemId}>
                                    <Box className="list-item flex f-v-center">
                                        <Box
                                            className="image"
                                            style={{ backgroundImage: `url(${item?.itemImage})` }}>
                                            <Typography className="duration elipsis">
                                                {item?.availableDays}
                                            </Typography>
                                        </Box>
                                        <Box className="flex f-column item-right-container">
                                            <Box className="flex">
                                                <Typography className="item-title elipsis">
                                                    {item?.itemName}
                                                </Typography>
                                                <Typography className="price">
                                                    {item?.currencySymbol}
                                                    {item?.price}
                                                </Typography>
                                            </Box>
                                            <Typography className="delivery-tag">
                                                {item?.deliveryStatus}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        className={`divider-border ${
                                            data?.cartItemList?.length === index + 1 && 'last-child'
                                        }`}
                                    />
                                </React.Fragment>
                            ))}

                            <Box className="flex f-h-space-between">
                                <Typography className="sub-title decrease-font-size text-uppercase">
                                    Subtotal
                                </Typography>
                                <Typography className="sub-title decrease-font-size">
                                    ${data?.subTotalPrice}
                                </Typography>
                            </Box>
                            {/* <Box className="flex f-h-space-between f-v-center">
                                <Typography className="order-tax-and-shipping-value key">
                                    Ship To:
                                </Typography>
                                <Typography className="order-tax-and-shipping-value value">
                                    42 Street Name, City, State, Country, ZIP
                                </Typography>
                                <Typography className="change-shipping-address hover">
                                    Change
                                </Typography>
                            </Box>
                            <Box className="flex f-h-space-between">
                                <Typography className="order-tax-and-shipping-value ">
                                    {'Tax (9%)'}
                                </Typography>
                                <Typography className="order-tax-and-shipping-value">
                                    $4.50
                                </Typography>
                            </Box>
                            <Box className="flex f-h-space-between">
                                <Typography className="order-tax-and-shipping-value ">
                                    {'Shipping (7–10 Days)'}
                                </Typography>
                                <Typography className="order-tax-and-shipping-value">
                                    $10.00
                                </Typography>
                            </Box> */}
                            <Box className="divider-border" />
                            <Box className="flex f-h-space-between">
                                <Typography className="total-order key text-uppercase flex f-v-center">
                                    Total
                                </Typography>
                                <Typography className="total-order">
                                    ${data?.totalPrice} <span className="or">OR</span>{' '}
                                    {data?.totalPriceInPoint} Pts
                                </Typography>
                            </Box>

                            <Box className="payment-methods">
                                <Typography className="payment-method-title">
                                    Payment Method
                                </Typography>
                                {Object.keys(PAYMENT_METHODS).map((item, index) => (
                                    <Box className="method-item flex" key={index}>
                                        <IconButton
                                            classes={{ root: 'radio-root' }}
                                            onClick={() => setSelectedPaymentMethod(item)}>
                                            <Box
                                                className={`radio flex f-v-center f-h-center ${
                                                    selectedPaymentMethod === item && 'active'
                                                }`}>
                                                {selectedPaymentMethod === item && (
                                                    <Box className="selected flex f-v-center f-h-center">
                                                        <Box className="icon" />
                                                    </Box>
                                                )}
                                            </Box>
                                        </IconButton>
                                        {item === PAYMENT_METHODS.POINTS && (
                                            <PointsMethod
                                                availableRewardPoints={data?.availableRewardPoints}
                                                totalPriceInPoint={data?.totalPriceInPoint}
                                            />
                                        )}
                                        {item === PAYMENT_METHODS.CASH && (
                                            <CashMethod totalPrice={data?.totalPrice} />
                                        )}
                                    </Box>
                                ))}
                            </Box>
                            {isOrderFailed && (
                                <Typography className="payment-declined">
                                    The payment was declined. Please update the payment method and
                                    try again.
                                </Typography>
                            )}
                        </Box>
                        <Button
                            className="place-order-button"
                            endIcon={<Box className="arrow" />}
                            onClick={() => orderPlace()}>
                            Place Order
                        </Button>
                    </Box>
                </ReferralShadowBoxLayout>
            </ChoosePaymentWrapper>
        </React.Fragment>
    );
};
export default ChoosePayment;
