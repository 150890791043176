import styled from 'styled-components';
import { responsive } from 'Styles/Constants';

export const ContentWrapper = styled.div`
    background-color: #fff;
    overflow: hidden;
    height: 100%;

    ${responsive.TABLET`
        padding:${(props) => (props?.$ShowSideBar ? '0 0 70px' : '0')};
    `}
`;

export const WebsiteWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 100%;
    overflow: ${(props) => (props?.$tourEnabled ? 'hidden' : 'visible')};
`;
