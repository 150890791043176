import styled from 'styled-components';
import { Box } from '@mui/material';
import { responsive, FONTS, COLORS } from 'Styles/Constants';
import ProfilePageBackgroundImage from 'Assets/images/Profile/profile-page-background-image.png';

export const TopScroll = styled(Box)`
    position: absolute;
    width: 100%;
    top: -150px;
`;

export const ShareProfileViewWrapper = styled(Box)`
    display: flex;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    align-items: center;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-image: url(${ProfilePageBackgroundImage});
    background-repeat: no-repeat;

    ${responsive.TABLET`
        background: none;
        border-radius: none;
        padding: 0;
    `}

    .user-profile-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: calc(100vh - 150px);
        width: 40%;
        border-radius: 30px 30px 0 0;
        padding: 20px;
        position: relative;
        background: #fbf7ed;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        margin: 100px 0 0 0;

        ${responsive.LAPTOP`
            width: 60%;
        `}

        ${responsive.TABLET`
            height: calc(100vh - 0px);
            width: 100%;
            padding: 0;
            margin: 0;
        `}


        .user-profile-image {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 255px;
            width: 100%;

            ${responsive.TABLET`
                margin-top: 50px;
                position: relative;
                z-index: 10;
            `}

            &::before {
                content: 'Image is not available';
                position: absolute;
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                color: ${COLORS.PRIMARY};
                border: 6px solid ${COLORS.PRIMARY_DARK};
                border-radius: 180px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 255px;
                width: 255px;

                ${responsive.TABLET`
                    content: none;
                `}
            }

            .user-image {
                height: 265px;
                width: 265px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                border: 6px solid ${COLORS.PRIMARY_DARK};
                border-radius: 180px;
                z-index: 10;

                ${responsive.LAPTOP`
                    height: 275px;
                    width: 275px;
                `}

                ${responsive.TABLET`
                    height: 400px;
                    width: 100%;
                    border: none;
                    border-radius: 0;
                    position: fixed;
                    top: 0;
                `}
            }
        }

        .display-mobile-format {
            display: flex;
            flex-direction: column;
            gap: 10px;
            z-index: 10;

            ${responsive.TABLET`
                background: #ffffff;
                box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
                border-radius: 30px 30px 0px 0px;
                width: 100%;
                padding: 20px;
                min-height: 80vh;
                z-index: 20;
                position: relative;
            `}

            .user-name {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;

                font-family: ${FONTS.SECONDARY};
                font-weight: 700;
                font-size: 40px;
                line-height: 40px;
                letter-spacing: -0.02em;
                text-transform: capitalize;
                color: ${COLORS.PRIMARY_DARK};

                ${responsive.TABLET`
                    justify-content: flex-start;
                `}
            }

            .user-reviews-and-add-friend {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 5px;

                .user-location {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;

                    .location-icon {
                        ${responsive.TABLET`
                            height: 15px;
                            width: 15px;
                        `}
                    }
                }

                .add-friend-button {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    width: 173px;
                    height: 36px;
                    background: #ffffff;
                    border: 1px solid ${COLORS.PRIMARY_DARK};
                    border-radius: 20px;

                    .add-friend-icon {
                        ${responsive.TABLET_800`
                            height: 15px;
                            width: 15px;
                        `}
                    }
                    .friend-btn-text {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 12px;
                    }
                }

                .user-rating {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    ${responsive.TABLET`
                        gap: 3px;
                    `}

                    ${responsive.PHABLET`
                        gap: 1px;
                    `}
                }
            }
        }
    }

    .profile-tabs {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .selected-tab {
            background: #ffffff;
            border: 1.5px solid ${COLORS.PRIMARY_DARK};
            border-radius: 22px;
            color: ${COLORS.PRIMARY_DARK};

            .chip-label {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                text-transform: uppercase;
                color: ${COLORS.PRIMARY_DARK};
            }
        }

        .non-selected-tab {
            background-color: ${COLORS.LIGHT_GREY_SECONDARY};
            border-radius: 22px;
            border: 1px solid ${COLORS.LIGHT_GREY_SECONDARY};
        }
    }

    .user-bio {
        font-family: ${FONTS.PRIMARY};
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: ${COLORS.PRIMARY_WARM};
    }

    .date-of-birth-section,
    .user-language-section {
        width: 50%;
    }

    .user-language-section,
    .user-live-session-section,
    .user-interest-section,
    .date-of-birth-section {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .language-title,
        .live-session-by-title,
        .interest-title,
        .date-of-birth-title {
            font-family: ${FONTS.PRIMARY_BOLD};
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            text-transform: uppercase;
            color: #31290e;
        }

        .user-known-languages,
        .user-dob {
            font-family: ${FONTS.PRIMARY};
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #31290e;
        }

        .live-session-name {
            font-family: ${FONTS.PRIMARY};
            font-weight: 400;
            font-size: 16px;
            line-height: 32px;
            text-decoration-line: underline;
            color: #31290e;
        }

        .interest-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;

            .user-interest-list {
                height: 45px;
                border-radius: 30px;
                padding: 10px 15px;

                .interest-label {
                    font-family: ${FONTS.PRIMARY};
                    font-size: 16px;
                    color: ${COLORS.WHITE};
                }
            }
        }
    }

    .user-reviews-section {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .review-details {
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex: 48%;

            .session-name {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                text-transform: uppercase;
                color: ${COLORS.PRIMARY_WARM};
            }

            .session-rating {
                display: flex;
                flex-direction: row;
                gap: 5px;

                .session-rating-value {
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: ${COLORS.MEDIUM_GREY};
                }
            }

            .participant-felt-title {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                text-transform: uppercase;
                color: ${COLORS.TEXT_DARK};

                margin-top: 5px;
            }

            .participant-felt-feedback {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .spec-item {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    gap: 5px;
                    padding: 6px 0;

                    .bar {
                        height: 9px;
                        background-color: #c4c4c4;
                        border-radius: 5px;
                        margin-right: 0.8rem;
                    }

                    h5 {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 26px;
                        text-align: right;
                        text-transform: capitalize;
                        color: #b08c3d;
                        width: 30%;
                        min-width: 84px;
                        margin-right: 1rem;
                    }

                    h6 {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 26px;
                        text-transform: capitalize;
                        color: #848484;
                    }
                }
            }
        }
    }
`;
