import React from 'react';
import { Backdrop } from '@mui/material';
import Lottie from 'lottie-react';
import wavesAnimation from 'Assets/AnimationFiles/loading-animation.json';

const TaoLoader = ({ isLoading, backdropCustomStyle }) => (
    <Backdrop
        sx={{
            zIndex: 1100,
            ...backdropCustomStyle
        }}
        open={isLoading}>
        <Lottie loop autoplay animationData={wavesAnimation} style={{ width: 100, height: 100 }} />
    </Backdrop>
);

export default TaoLoader;
