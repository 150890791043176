import { Box, Dialog } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const ProfileGoalsSectionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #fff;
    border-radius: 30px 30px 0 0;
    position: relative;
    height: 100%;
    padding: 100px 0 20px 20px;

    ${responsive.TABLET`
        height: calc(100% - 80px);
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        border-radius: 30px 30px 0px 0px;
        background: #FFFFFF;
        padding: 20px 0 0 20px;
    `}

    ${responsive.PHABLET`
        background: none;
        box-shadow: none;
        border-radius: none;
    `}

    .goals-container {
        padding: 0 20px 0 0;

        .goal-instruction-text {
            color: ${COLORS.TEXT_DARK};
            font-size: 21px;
            margin: 0 0 10px;
        }

        .select-time {
            width: 100%;
        }
        .goals-text-style {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            text-transform: capitalize;
            color: ${COLORS.MEDIUM_GREY};
        }
        .btn-style {
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            font-size: 16px;
            text-transform: uppercase;
            color: ${COLORS.WHITE};
            margin: 50px 0 40px;
            height: 60px;
            width: 100%;
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
            border-radius: 12px;
        }
    }

    .selector {
        background-color: white;
        border: 1px solid rgba(132, 132, 132, 0.4);
        border-radius: 8px;
        width: 35%;

        .filled-select {
            background-color: #fff;
            border-radius: 8px;
        }
        .down-arrow-icon {
            position: absolute;
            cursor: pointer;
            right: 20px;
        }
    }
`;

export const DialogWrapper = styled(Dialog)`
    .dialog-wrapper {
        width: 100%;
        max-width: 525px;
        max-height: 640px;
        border-radius: 30px;
        position: relative;

        .dialog-title {
            font-weight: 500;
            font-size: 24px;
            line-height: 35px;
            font-style: normal;
            font-family: ${FONTS.PRIMARY};
            color: ${COLORS.TEXT_DARK};
        }

        .dialog-btn {
            &.next-btn {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 0px;
                gap: 10px;
                width: 470px;
                height: 60px;
                background: rgba(255, 255, 255, 0.9);
                box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
                backdrop-filter: blur(10px);
                border-radius: 12px;
                margin: 20px 0;
            }

            &.save-btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 0px;
                gap: 10px;
                width: 470px;
                height: 60px;
                background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                border-radius: 12px;
                margin: 20px 0;
            }
        }

        .btn-text {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: ${(props) =>
                props?.$screenType === 'screenOne' ? COLORS.PRIMARY_WARM : COLORS.WHITE};
        }
    }
`;
