import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { IconButton, Typography, Box, Button, Chip, Slide } from '@mui/material';
import CrossIcon from 'Assets/images/PlayList/cross-icon.svg';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { TagWrapper } from './Tag.style';
import TaoScrollbar from 'Components/common/Scrollbar';

const TagSelection = ({
    setPage,
    page,
    selectedCategoryTag,
    selectedTopicTag,
    tagRelatedIds,
    selectedTag,
    setSelectedTag,
    selectedTagId,
    setSelectedTagId,
    ...props
}) => {
    const API = useMemo(() => new Api(), []);
    const [tagList, setTagList] = useState([]);
    const [isLoading, setsIsLoading] = useState(false);

    const [selectedTags, setSelectedTags] = useState([...selectedTagId]);

    const selectTag = (tagId) => {
        const newSelectedTags = [...selectedTags];
        if (!newSelectedTags.includes(tagId)) newSelectedTags.push(tagId);
        else {
            let findIndex = newSelectedTags.findIndex((item) => item === tagId);
            findIndex > -1 && newSelectedTags.splice(findIndex, 1);
        }
        setSelectedTags(newSelectedTags);
    };

    const getTagList = useCallback(async () => {
        setsIsLoading(true);
        let categoryIds = [];
        let tempCategoryIds = [];
        if (!selectedTopicTag.length) {
            if (selectedCategoryTag[0]) {
                categoryIds.push(selectedCategoryTag[0]?.categoryId);
                tempCategoryIds = selectedCategoryTag[0]?.subCategoryDetails.map((subCategory) => {
                    return subCategory?.id;
                });
                categoryIds.push(...tempCategoryIds);
            }
        } else {
            tempCategoryIds = selectedTopicTag.map((subCategory) => {
                return subCategory?.id;
            });
            categoryIds.push(...tempCategoryIds);
        }

        let response = await API.post(API_URL.GET_TAGS, { data: { categoryIds } });

        if (response) {
            setTagList(response?.data?.data?.tagsDetails);
            setsIsLoading(false);
        }
    }, [selectedCategoryTag, selectedTopicTag, API]);

    useEffect(() => {
        getTagList();
    }, [getTagList]);

    const onDone = () => {
        const selectedMultipleTags = [...selectedTags];
        let selectedTagData = [];
        let selectedTagsIds = [];

        selectedMultipleTags.forEach((tagId) => {
            let foundedData = tagList?.find((value) => value?.id === tagId);

            if (foundedData) {
                selectedTagData.push(foundedData);
                selectedTagsIds.push(foundedData?.id);
            }
        });
        setSelectedTag(selectedTagData);
        setSelectedTagId(selectedTagsIds);
    };

    const onClose = () => {
        setPage(null);
    };

    return (
        <>
            <TaoLoader isLoading={isLoading} />
            <Slide in={Boolean(page)} direction="up" timeout={{ enter: 500, exit: 300 }}>
                <TagWrapper>
                    <Box className="top-bar">
                        <Typography className="heading">Choose Tags</Typography>
                        <IconButton className="cross-button" onClick={onClose}>
                            <img className="cross-icon" src={CrossIcon} alt="" />
                        </IconButton>
                    </Box>
                    <Box className="selected-category-section">
                        <Typography className="heading">Category:</Typography>
                        <Box
                            style={{ display: 'flex', justifyContent: 'center' }}
                            className="chip-container">
                            {!!selectedCategoryTag?.length &&
                                selectedCategoryTag?.map((value, index) => {
                                    return (
                                        <Chip
                                            key={index}
                                            className="chip"
                                            label={value?.categoryName}
                                        />
                                    );
                                })}
                            {!selectedCategoryTag.length && (
                                <Chip
                                    className="chip"
                                    classes={{ label: 'chip-label' }}
                                    label={'ALL'}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box className="selected-category-section">
                        <Typography className="heading">Topic:</Typography>
                        <Box className="chip-container">
                            <TaoScrollbar keyId="topic-chip" horizontalScroll>
                                {!!selectedTopicTag?.length &&
                                    selectedTopicTag?.map((value, index) => {
                                        return (
                                            <Chip
                                                key={index}
                                                className="chip"
                                                label={value?.name}
                                            />
                                        );
                                    })}
                            </TaoScrollbar>
                            {!selectedTopicTag.length && (
                                <Chip
                                    className="chip"
                                    classes={{ label: 'chip-label' }}
                                    label={'ALL'}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box className="tag-count-container">
                        <Typography className="count">{tagList?.length} Tags Available</Typography>
                        <Typography className="text">choose one or more</Typography>
                    </Box>
                    <TaoScrollbar displayScroll>
                        <Box className="tag-selection-container">
                            <Box className="chip-container">
                                {!!tagList?.length &&
                                    tagList?.map((value, index) => {
                                        return (
                                            <Chip
                                                key={index}
                                                className={`chip ${
                                                    selectedTags.includes(value?.id) &&
                                                    'change-color'
                                                }`}
                                                classes={{ label: 'chip-label' }}
                                                label={value?.name}
                                                onClick={() => {
                                                    selectTag(value?.id);
                                                }}
                                            />
                                        );
                                    })}
                            </Box>
                        </Box>
                    </TaoScrollbar>
                    <Box className="tag-button-container">
                        <Button
                            className="submit-button"
                            onClick={() => {
                                onDone();
                                onClose();
                            }}>
                            <Typography className="text">Done</Typography>
                        </Button>
                    </Box>
                </TagWrapper>
            </Slide>
        </>
    );
};
export default TagSelection;
