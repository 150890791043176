import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

const LIGHT_GREY_With_OPACITY_4 = '#e8e8e866';
const LIGHT_GREY_With_OPACITY_8 = '#e8e8e8cc';
const MEDIUM_GREY_With_OPACITY_1 = '#8484841a';
const LIGHT_YELLOW = '#fdfaf1';

export const JournalMainWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    flex-wrap: wrap;
    user-select: none;

    .container {
        position: relative;
        width: 65%;
        height: 100%;
        display: flex;
        min-height: 600px;
        max-height: 80vh;
        padding: 100px 0 0;

        ${responsive.LAPTOP_1050`
            width: 70%;
        `}
        ${responsive.TABLET`
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 0;
        `}
        
        .left {
            display: flex;
            flex-direction: column;
            margin: 0;
            width: 40%;
            height: 100%;
            padding: 30px 20px;
            background-color: ${LIGHT_GREY_With_OPACITY_4};
            border-radius: 24px 24px 0px 0px;

            &.hidden {
                ${responsive.TABLET`
                    display: none;
                `}
            }
            ${responsive.TABLET`
                width: 100%;
                background-color: #fff;
                border-radius: 0;
                padding: 60px 0px 12px 0px;                
            `}

            .left-right-padding {
                ${responsive.TABLET`
                   padding: 0px 20px 12px 20px;
                `}
            }

            .heading {
                width: 100%;

                ${responsive.TABLET`
                   margin: 10px 0 0 0;
                `}

                .title {
                    font-family: ${FONTS.SECONDARY};
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 40px;
                    letter-spacing: -0.02em;
                    text-transform: capitalize;
                    color: ${COLORS.PRIMARY_DARK};
                }

                .add-new-note {
                    background: ${COLORS.PRIMARY};
                    width: 110px;
                    height: 40px;
                    border-radius: 25px;
                    padding: 10px 0;
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: 0.05rem;
                    text-transform: uppercase;
                    color: #fff;

                    &:disabled {
                        background: linear-gradient(180deg, #cbac698f 0%, #b6954f8f 100%);
                    }
                }

                .add-new-note-icon {
                    display: none;
                    background: ${COLORS.PRIMARY} !important;
                    width: 40px;
                    height: 40px;
                    padding: 4px 0 0 0;

                    &:disabled {
                        background: linear-gradient(
                            180deg,
                            #cbac698f 0%,
                            #b6954f8f 100%
                        ) !important;
                    }

                    ${responsive.LAPTOP_1100`
                        display: block;
                    `}
                }

                .disable-color-btn {
                    background: linear-gradient(180deg, #cbac698f 0%, #b6954f8f 100%);
                }
            }

            .search-box {
                margin: 20px 0 25px 0;
                .search {
                    background-color: ${LIGHT_GREY_With_OPACITY_8};
                    border: 2px solid #fff;
                    border-radius: 35px;
                    width: 100%;
                    padding: 0 0 0 15px;
                    height: 50px;

                    .search-clear-icon {
                        width: 45px;
                        height: 45px;
                    }

                    .start-icon {
                        margin: 0 5px 0 0;
                    }

                    .input-root {
                        font-size: 20px;
                        color: ${COLORS.TEXT_DARK};
                        margin: 0 0 0 5px;
                    }
                }
            }
            .note-box {
                .pinned-box {
                    margin: 0 0 30px 0;

                    .pinned-text {
                        margin: 0 0 10px 0;
                    }
                    .subtitle {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 18px;
                        text-align: center;
                        color: ${COLORS.MEDIUM_GREY};
                    }
                }
            }

            .empty-journal {
                height: 100%;
                width: 100%;
                .text {
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    font-size: 18px;
                    text-transform: capitalize;
                    color: ${COLORS.GREY_TEXT_COLOR};
                    min-width: max-content;
                }
                .no-searched-data {
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    font-style: italic;
                    font-size: 18px;
                    color: ${COLORS.GREY_TEXT_COLOR};
                    padding: 0 2px 0 0;

                    &.normal {
                        margin: 0 0 0 5px;
                        font-style: normal;
                        min-width: 30px;
                    }
                }
            }

            .pinned-journal-date {
                .text {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 40px;
                    color: ${COLORS.PRIMARY};
                    text-transform: capitalize;
                }
            }

            .date-box {
                .empty-journal-date {
                    .text {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 40px;
                        color: ${COLORS.PRIMARY};
                        text-transform: capitalize;
                    }
                }
            }

            .secondary-title {
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                line-height: 40px;
                text-transform: capitalize;
                padding: 0 0 0 15px;
                color: ${COLORS.PRIMARY_DARK};

                ${responsive.TABLET`
                    font-size: 24px;
                    line-height: 35px;
                `}
            }
        }

        .right {
            padding: 20px 10px 0 20px;
            display: flex;
            flex-direction: column;
            margin: 0;
            width: 60%;

            &.hidden {
                ${responsive.TABLET`
                    display: none;
                `}
            }
            ${responsive.TABLET`
                width: 100%;
                padding: 0px;
                overflow: hidden;
            `}

            .custom-icon-section {
                ${responsive.TABLET`
                    height: 60px;
                    margin: 10px 0 15px 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                `}
            }

            .extra-feture-icon {
                width: 48px;
                height: 48px;
                margin: 0 0 0 10px;
            }

            .save-icon,
            .delete-icon {
                display: flex;
                height: 40px;
                width: 40px;

                &.save-box-shadow {
                    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.3);
                }

                ${responsive.TABLET`
                   height: 48px;
                    width: 48px;
                `}
            }

            .delete-icon {
                box-shadow: rgb(0 0 0 / 30%) 0px 4px 24px;
            }

            .buttonSection {
                width: 100%;
                flex-wrap: wrap;
                margin: 0 0 20px 0;
                gap: 10px;

                ${responsive.TABLET`
                    margin: 0 0 30px 15px;
                `}

                .toolbar {
                    border: 1px solid rgba(132, 132, 132, 0.1);
                    border-radius: 8px;
                    display: inline-flex;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 35px;
                    text-transform: capitalize;
                    padding: 5px;
                    flex-wrap: wrap;
                    box-shadow: rgb(0 0 0 / 15%) 0px 4px 24px;

                    ${responsive.TABLET_725`
                        padding-bottom: 5px;
                    `}

                    ${responsive.MOBILE_376`
                        width: 95%;
                        overflow-x: auto;
                        white-space: nowrap;

                        &::-webkit-scrollbar-thumb {
                            display: none;
                        }
                    `}

                    .quill-btn {
                        font-size: 15px;
                        line-height: 20px;
                        height: 30px;
                        width: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        ${responsive.TABLET`
                            height: 35px;
                            width: 35px;
                        `}

                        svg {
                            float: none;
                        }

                        &:hover {
                            color: ${COLORS.PRIMARY};

                            .ql-stroke {
                                stroke: ${COLORS.PRIMARY};
                            }

                            .ql-fill {
                                fill: ${COLORS.PRIMARY};
                            }
                        }

                        &.ql-active {
                            color: ${COLORS.PRIMARY};

                            path {
                                stroke: ${COLORS.PRIMARY};
                            }

                            .ql-stroke {
                                stroke: ${COLORS.PRIMARY};
                            }

                            rect {
                                fill: ${COLORS.PRIMARY};
                            }
                        }
                    }

                    .ql-bold {
                        border: 1px solid #e8e8e8;
                        border-radius: 18px 0 0 18px;

                        svg {
                            height: 75%;
                        }
                    }

                    .ql-italic {
                        border: 1px solid #e8e8e8;
                        border-left: none;
                        width: 32px;
                        font-style: italic;
                        font-weight: 400;
                        font-size: 21px;
                        line-height: 27px;
                        color: ${COLORS.TEXT_DARK};
                        padding-right: 7px;

                        svg {
                            height: 75%;
                        }
                    }

                    .ql-underline {
                        border: 1px solid #e8e8e8;
                        color: ${COLORS.PRIMARY};
                        border-left: none;
                        width: 36px;
                        padding-top: 4px;
                        text-decoration: underline;
                        text-decoration-color: ${COLORS.PRIMARY};
                        font-weight: 300;
                        font-size: 20px;
                        line-height: 27px;

                        svg {
                            height: 75%;
                        }
                    }

                    .ql-strike {
                        border: 1px solid #e8e8e8;
                        border-radius: 0 18px 18px 0;
                        border-left: none;
                        width: 36px;
                        font-weight: 400;
                        font-size: 21px;
                        line-height: 27px;
                        text-decoration-line: line-through;

                        svg {
                            height: 75%;

                            rect {
                                fill: ${COLORS.PRIMARY};
                            }
                        }

                        &.ql-active {
                            path {
                                fill: ${COLORS.PRIMARY};
                            }
                        }
                    }

                    .ql-unOrderList {
                        margin-left: 24px;
                        border: 1px solid #e8e8e8;
                        border-radius: 18px 0 0 18px;
                    }

                    .ql-orderList {
                        border: 1px solid #e8e8e8;
                        border-radius: 0 18px 18px 0;
                    }

                    .ql-outdent {
                        border: 1px solid #e8e8e8;
                        border-radius: 0 18px 18px 0;
                    }

                    .ql-customIndent {
                        margin-left: 24px;
                        border: 1px solid #e8e8e8;
                        border-radius: 18px 0 0 18px;
                    }
                }

                .delete-icon {
                    height: 40px;
                    width: 40px;
                    box-shadow: rgb(0 0 0 / 30%) 0px 4px 24px;
                }

                .save-icon {
                    display: flex;
                    height: 40px;
                    width: 40px;
                }

                .share-icon {
                    display: flex;
                    padding: 5px 0 0 0;
                    height: 40px;
                    width: 40px;
                }
                .desktop-display {
                    display: block;
                    ${responsive.TABLET`
                        display: none;
                    `}
                }
            }
            .selectedNote {
                padding: 20px 0 0 15px;
                background: ${LIGHT_YELLOW};
                border-radius: 30px 30px 0 0;

                .title-input-root {
                    border: none;
                    outline: none;
                    font-family: ${FONTS.SECONDARY_BOLD};
                    /* font-style: normal; */
                    /* font-weight: 700; */
                    font-size: 40px;
                    line-height: initial;
                    /* letter-spacing: -0.02em; */
                    text-transform: capitalize;
                    color: ${COLORS.PRIMARY_DARK};
                }

                .title {
                    width: 100%;
                    padding: 0 15px 0 0;
                }

                .selectNoteData {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    color: ${COLORS.PRIMARY_DARK};
                    text-transform: uppercase;
                }
            }

            #new-post-quill-content,
            #title-new-post-quill-content {
                white-space: pre;
                margin: 0 0 10px 0;
                border-radius: 0 0 30px 30px;
                min-height: 120px;
                height: calc(100% - 120px);
                background: ${LIGHT_YELLOW};
                padding: 0 0 10px 0;

                ${responsive.MOBILE_376`
                    margin-top: 0.5rem;
                `}
                ${responsive.TABLET`
                    margin-bottom: 5rem;
                    border-radius: 0;
                `}

                .ql-editor {
                    padding: 0 15px;
                }

                .ql-container.ql-snow {
                    border: none;

                    p {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 30px;
                        color: ${COLORS.PRIMARY_WARM};
                    }
                }
            }
            .add-note {
                width: max-content;
                margin: 0 10px 0 auto;
            }
        }
    }

    .indentIcons,
    .orderListIcons {
        ${responsive.TABLET_800`
            margin: 0 0 0 -2px;
        `}
        ${responsive.TABLET_725`
            margin: 0 0 0 -4px;
        `}
        ${responsive.TABLET_700`
            margin: 0 0 0 -6px;
        `}
        ${responsive.PHABLET`
            margin: 0 0 0 -8px;
        `}
        ${responsive.PHABLET_500`
            margin: 0 0 0 -10px;
        `}
        ${responsive.MOBILE`
            margin: 0 0 0 -13px;
        `}
        ${responsive.MOBILE_376`
            margin: 0 0 0 -15px;
        `}
        ${responsive.MOBILE_325`
            margin: 0 0 0 -20px;
        `}
    }

    .tablet-display {
        display: none;
        padding: 0 10px 0 0;
        ${responsive.TABLET`
            display: block;
        `}
    }

    .card-bg-color {
        background: ${MEDIUM_GREY_With_OPACITY_1};
        border-radius: 8px;
        &:nth-child(2n) {
            background: #ffffff1a;
        }
        ${responsive.TABLET`
            border-radius: 0;
        `}
        z-index: 5;
    }

    .swipe-card {
        position: relative;
        overflow-x: hidden;
        border: 2px solid transparent;

        &.selected-card {
            border-color: ${COLORS.PRIMARY};
        }

        .swipe-unpin,
        .swipe-pin {
            position: absolute;
            top: 0;
            left: -2000%;
            width: 2000%;
            height: 90px;
            background: ${COLORS.TEXT_DARK};
            justify-content: start;
            padding: 0 0 0 20px;
        }

        .swipe-pin-icon,
        .swipe-unpin-icon {
            position: absolute;
            left: 25px;
            top: 25px;
            z-index: 5;
            display: none;
        }

        .swipe-delete {
            position: absolute;
            top: 0;
            left: 100%;
            width: 200%;
            height: 90px;
            background: ${COLORS.BADGE};
            justify-content: end;
            padding: 0 20px 0 0;
            z-index: 1;
        }

        .swipe-delete-icon {
            position: absolute;
            right: 25px;
            top: 25px;
            z-index: 10;
            display: none;
        }

        .card {
            height: 90px;
            width: 100%;
            border-radius: 8px;
            position: relative;
            top: 0;
            left: 0;
            justify-content: space-evenly;
            z-index: 15;

            ${responsive.TABLET`
                border-radius: 0;
                margin: 0;
            `}

            .CardContent {
                width: 83%;
                gap: 20px;

                .paper-date {
                    padding: 0 15px;
                    background-color: #f7f7f7;
                    border-radius: 15px;
                    box-shadow: -1px 5px 10px rgba(0, 0, 0, 0.09);

                    ${responsive.DESKTOP`
                        margin: 0 8px;
                        padding: 0 12px;
                    `}

                    ${responsive.LAPTOP`
                        margin: 0 8px 0 6px;
                        padding: 0 10px;
                    `}

                    ${responsive.LAPTOP_1200`
                        margin: 0 8px 0 4px;
                        padding: 0 10px;
                    `}

                    ${responsive.LAPTOP_1100`
                        margin: 0 10px;
                        padding: 0 15px;
                    `}

                    .CardMonth,
                    .CardDay {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 22px;
                        text-align: center;
                        letter-spacing: -0.02em;
                        color: ${COLORS.TEXT_DARK};
                    }

                    .CardDay {
                        font-size: 26px;
                        line-height: 32px;
                    }
                }

                .CardTextArea {
                    height: 100%;
                    width: inherit;

                    .Highlight-title {
                        background: ${COLORS.PRIMARY};
                    }

                    .card-para,
                    .card-para > p {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 20px;
                        color: ${COLORS.TEXT_DARK};
                        width: inherit;
                        height: 50px;
                        overflow: hidden;

                        .Highlight-body {
                            background: ${COLORS.PRIMARY};
                        }

                        .card-description {
                            display: block;
                            width: 100%;
                            white-space: pre-wrap;
                            -webkit-line-clamp: 2;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            display: -webkit-box;
                        }
                    }

                    .card-para > p {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .card-title {
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                        font-size: 18px;
                        width: inherit;
                        color: ${COLORS.PRIMARY_WARM};
                    }
                }
            }

            .action-icons {
                width: 35px;
                height: 35px;
            }
        }
    }
`;
