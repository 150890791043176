import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { FONTS, COLORS } from 'Styles/Constants';

const ShowCounterWrapper = styled(Box)`
    display: flex;
    flex-direction: row;
    gap: 20px;

    .countdown {
        display: flex;
        flex-direction: column;
        align-items: center;

        &.danger {
            .time-value {
                color: ${COLORS.BADGE};
            }
        }

        .time-value {
            font-family: ${FONTS.TERTIARY};
            font-weight: 500;
            font-size: 45px;
            line-height: 65px;
            color: ${COLORS.PRIMARY_WARM};
        }

        .time-type {
            font-family: ${FONTS.TERTIARY};
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            text-transform: capitalize;
            color: ${COLORS.MEDIUM_GREY};
            margin-top: -8px;
        }
    }
`;

const useCountDown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

    return [days, hours, minutes, seconds];
};

// const ExpiredNotice = () => {
//     return (
//         <div className="expired-notice">
//             <span>Expired!!!</span>
//             <p>Please select a future date and time.</p>
//         </div>
//     );
// };

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
        <ShowCounterWrapper>
            <DateTimeDisplay value={days} type={'Days'} isDanger={false} />
            <DateTimeDisplay value={hours} type={'Hrs'} isDanger={false} />
            <DateTimeDisplay value={minutes} type={'Mins'} isDanger={minutes <= 3} />
            <DateTimeDisplay value={seconds} type={'Sec'} isDanger={minutes <= 3} />
        </ShowCounterWrapper>
    );
};

const CountDownTimer = (props) => {
    const countDowntimerProper = { ...props };
    const [days, hours, minutes, seconds] = useCountDown(countDowntimerProper.targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return props.completeCountDownTimer();
    } else {
        return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />;
    }
};

const DateTimeDisplay = ({ value, type, isDanger }) => {
    return (
        <div className={isDanger ? 'countdown danger' : 'countdown'}>
            <span className="time-value">{value}</span>
            <span className="time-type">{type}</span>
        </div>
    );
};

export default CountDownTimer;
