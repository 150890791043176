import styled from 'styled-components';
import ResetPasswordBackground from 'Assets/images/Signup/reset-password-background.webp';
import EmailSentBackground from 'Assets/images/Signup/email-sent-background.webp';
import NewPasswordBackground from 'Assets/images/Signup/new-password-background.webp';
import MobileNewPasswordBackground from 'Assets/images/Signup/mobile-view-reset-password.webp';
import MobileEmailSentbackground from 'Assets/images/Signup/mobile-view-email-sent.webp';
import MobileViewResetPasswordEmail from 'Assets/images/Signup/mobile-view-reset-password-email.webp';

import { COLORS, FONTS, responsive } from 'Styles/Constants';
import { LinearProgress, Box } from '@mui/material';

export const ResetPasswordWrapper = styled.div`
    /* background-image: url(${(props) =>
        props.component === 'email'
            ? EmailSentBackground
            : props.component === 'new_password'
            ? NewPasswordBackground
            : ResetPasswordBackground}); */
    width: 100%;
    /* height: calc(100vh - 67px); */
    height: 100vh;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    ${responsive.MOBILE`
        background-size: contain;
        background-image: url(${(props) =>
            props.component === 'email'
                ? MobileEmailSentbackground
                : props.component === 'new_password'
                ? MobileNewPasswordBackground
                : MobileViewResetPasswordEmail});
    `}

    .image {
        position: absolute;
        background-image: ${(props) =>
            props.component === 'email'
                ? `url(${EmailSentBackground})`
                : props.component === 'new_password'
                ? `url(${NewPasswordBackground})`
                : `url(${ResetPasswordBackground})`};
        width: 100%;
        height: ${(props) => `calc(${props.screen.height - 150}px)`};
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;

        ${responsive.MOBILE`
            display: none;
        `}
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        top: 10%;
        overflow: hidden;
        z-index: 100;
    }

    .reset-password-container {
        display: flex;
        flex-direction: column;
        width: 25%;

        ${responsive.LAPTOP`
            width: 37%;
        `}

        ${responsive.TABLET`
            width: 55%;
        `}

        ${responsive.PHABLET`
            width: 70%;
        `}

        .expired-token-container {
            height: 200px;
            margin: 50px 0;
            .token-expired-text {
                font-size: 20px;
                text-align: center;
                color: ${COLORS.TEXT_DARK};
            }
            .links {
                display: flex;
                justify-content: center;
                margin: 70px 0 0;

                .divider {
                    height: 27px;
                    margin: 0 20px;
                    background-color: ${COLORS.TEXT_DARK};
                }

                .link {
                    text-decoration: underline;
                    font-family: ${FONTS.PRIMARY};
                    font-size: 20px;
                    color: ${COLORS.TEXT_DARK};
                    cursor: pointer;
                }
            }
        }
    }

    .arrow-icon {
        margin-left: -35px;
        margin-right: 10px;
        margin-top: 5px;
        cursor: pointer;
    }

    .margin-bottom-spacing {
        margin-bottom: 10px;

        .confirm-password-container {
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;
            align-content: center;
            justify-content: end;
            margin-top: 10px;

            .text {
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: ${COLORS.MEDIUM_GREY};

                &.success {
                    color: #83d841;
                }

                &.danger {
                    color: #d32f2f;
                }
            }
        }
    }

    .title {
        font-size: 40px;
        font-family: ${FONTS.SECONDARY_BOLD};
        color: ${COLORS.MEDIUM_GREY};
        width: max-content;
        text-transform: capitalize;
        ${responsive.PHABLET`
            font-size: 24px;
        `}
    }

    .icon-text {
        display: flex;
        flex-direction: row;
        align-items: center;

        ${responsive.PHABLET`
            flex-direction: column;
        `}
    }

    .title2 {
        font-size: 40px;
        font-family: ${FONTS.PRIMARY};
        color: ${COLORS.PRIMARY};
        margin: 0 0 0 20px;

        ${responsive.PHABLET`
            font-size: 32px;
            margin: 0;
        `}
    }


    .reset-password-text {
        font-size: 40px;
        font-family: ${FONTS.SECONDARY_BOLD};
        color: ${COLORS.PRIMARY};
        margin: 0 0 0 20px;
        text-transform: capitalize;

        ${responsive.PHABLET`
            font-size: 32px;
            margin: 0;
        `}
    }

    .description {
        font-size: 20px;
        font-family: ${FONTS.PRIMARY};
        color: ${COLORS.TEXT_DARK};

        ${responsive.PHABLET`
            font-size: 18px;
        `}

        .email {
            font-family: ${FONTS.PRIMARY_MEDIUM};
        }
    }

    .button {
        width: 100%;

        &:hover {
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
        }
    }

    .column {
        ${responsive.MOBILE`
            flex-direction: column;
        `}
    }

    .text-center {
        text-align: left;
        ${responsive.MOBILE`
            text-align: center;
        `}
    }

    .align-center {
        align-items: flex-start;
        ${responsive.MOBILE`
            align-items: center;
        `}
    }

    .button-color {
        height: 60px;
        background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
        border-radius: 12px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        padding: 10px 0;
        color: #ffffff;
    }
`;

export const LinearProgressWrapper = styled(LinearProgress)`
    .bg-color {
        background-color: ${(props) => props?.background};
    }
`;

export const PasswordStrengthWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .progress-bar-wrapper {
        width: 70%;
    }
    .password-strength-text-wrapper {
        display: flex;
        align-items: center;
        min-width: max-content;
        margin: 0 0 0 10px;

        .password-strength-text {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: ${COLORS.MEDIUM_GREY};
        }
    }
`;
