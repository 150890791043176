import React, { useState, useEffect } from 'react';

import { Box, Typography, IconButton } from '@mui/material';

import { FooterWrapper } from './Footer.style';
import WhiteFacebookLogo from 'Assets/images/Footer/white-facebook-logo.svg';
import WhiteInstagramLogo from 'Assets/images/Footer/white-instagram-logo.svg';
import WhiteYoutubeLogo from 'Assets/images/Footer/white-youtube-logo.svg';
import WhitePInterestLogo from 'Assets/images/Footer/white-pinterest-logo.svg';
import WhiteTwitterLogo from 'Assets/images/Footer/white-twitter-logo.svg';

import BlackFacebookLogo from 'Assets/images/Footer/black-facebook-logo.svg';
import BlackInstagramLogo from 'Assets/images/Footer/black-instagram-logo.svg';
import BlackYoutubeLogo from 'Assets/images/Footer/black-youtube-logo.svg';
import BlackPInterestLogo from 'Assets/images/Footer/black-pinterest-logo.svg';
import BlackTwitterLogo from 'Assets/images/Footer/black-twitter-logo.svg';

import { BREAKPOINTS_VALUE } from 'Styles/Constants';

import { useDispatch, useSelector } from 'react-redux';
import { getWindowDimensions } from 'Helpers';
import { hideFooter, showFooter } from 'Redux/App/Actions';
import { PAGE } from 'Helpers/Constants';
import Page from './FooterDialog/FooterDialog';
import moment from 'moment';

const Footer = (props) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const displayFooter = useSelector((state) => state.App.showFooter);
    const dispatch = useDispatch();
    const [selectedPageTitle, setSelectedPageTitle] = useState('');
    const [selectedPageType, setSelectedPageType] = useState('');
    const [openPageDialog, setOpenPageDialog] = useState(false);

    const handlePageDialog = (type) => {
        if (type === PAGE?.PRIVACY_POLICY) setSelectedPageTitle('Privacy Policy');
        if (type === PAGE?.TERMS_AND_CONDITIONS) setSelectedPageTitle('Terms & Conditions');
        setSelectedPageType(type);
        setOpenPageDialog(true);
    };

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            dispatch(hideFooter());
        } else {
            dispatch(showFooter());
        }
    }, [windowDimensions.width, dispatch]);

    return (
        <FooterWrapper
            showFooter={displayFooter}
            isBottomZero={props.isBottomZero}
            topBorderColor={
                props.topBorderColor === 'gray' ? '#E8E8E8' : props.topBorderColor || '#fff'
            }
            isWhite={props?.showWhiteBackground}
            className={`${props.showWhiteBackground ? 'white-background' : 'black-background'}`}
            $zIndex={props?.zIndex || 10}
            position={props?.position}>
            <Box className="footer-content">
                <Box
                    className={`footer-subContent ${
                        props.showWhiteBackground ? 'white-background' : 'black-background'
                    }`}>
                    <Box className="links">
                        <Typography classes={{ root: 'typo-root' }}>
                            © {moment().format('YYYY')} Universal Soul Service Corp.{' '}
                        </Typography>
                    </Box>
                    <Box className="flex f-v-center">
                        <Typography
                            classes={{ root: 'typo-root' }}
                            className="hover"
                            onClick={() => {
                                handlePageDialog(PAGE?.TERMS_AND_CONDITIONS);
                            }}>
                            Terms & Conditions
                        </Typography>
                        <Typography className="policy-separate" classes={{ root: 'typo-root' }}>
                            |
                        </Typography>
                        <Typography
                            classes={{ root: 'typo-root' }}
                            className="hover"
                            onClick={() => {
                                handlePageDialog(PAGE?.PRIVACY_POLICY);
                            }}>
                            Privacy Policy
                        </Typography>
                    </Box>
                    <Box className="icons">
                        <IconButton>
                            <img
                                src={
                                    props.showWhiteBackground
                                        ? WhiteFacebookLogo
                                        : BlackFacebookLogo
                                }
                                alt=""
                                className="tao-logo"
                            />
                        </IconButton>
                        <IconButton>
                            <img
                                src={
                                    props.showWhiteBackground
                                        ? WhiteInstagramLogo
                                        : BlackInstagramLogo
                                }
                                alt=""
                                className="tao-logo"
                            />
                        </IconButton>
                        <IconButton>
                            <img
                                src={
                                    props.showWhiteBackground ? WhiteYoutubeLogo : BlackYoutubeLogo
                                }
                                alt=""
                                className="tao-logo"
                            />
                        </IconButton>
                        <IconButton>
                            <img
                                src={
                                    props.showWhiteBackground
                                        ? WhitePInterestLogo
                                        : BlackPInterestLogo
                                }
                                alt=""
                                className="tao-logo"
                            />
                        </IconButton>
                        <IconButton>
                            <img
                                src={
                                    props.showWhiteBackground ? WhiteTwitterLogo : BlackTwitterLogo
                                }
                                alt=""
                                className="tao-logo"
                            />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            {openPageDialog && (
                <Page
                    openPageDialog={openPageDialog}
                    setOpenPageDialog={setOpenPageDialog}
                    selectedPageTitle={selectedPageTitle}
                    selectedPageType={selectedPageType}
                />
            )}
        </FooterWrapper>
    );
};

export default Footer;
