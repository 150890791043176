import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { FONTS } from 'Styles/Constants';

const CounterWapper = styled(Box)`
    display: flex;
    flex-direction: row;

    .time-value,
    span {
        font-family: ${FONTS.TERTIARY};
        font-weight: 700;
        font-size: 24px;
        line-height: 35px;
        color: #ffffff;
    }
`;

const useCountup = () => {
    const [countDown, setCountDown] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown((countDown) => countDown + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [countDown]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
    // calculate time left
    const hours = Math.floor((countDown % (60 * 60 * 24)) / (60 * 60)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    const minutes = Math.floor((countDown % (60 * 60)) / 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    const seconds = Math.floor(countDown % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

    return [hours, minutes, seconds];
};

const ShowCounter = ({ hours, minutes, seconds }) => {
    return (
        <CounterWapper>
            {hours && hours > 0 && <DateTimeDisplay value={hours} type="hours" />}
            <DateTimeDisplay value={minutes} type="minutes" />
            <DateTimeDisplay value={seconds} type="seconds" />
        </CounterWapper>
    );
};

const CountUpTimer = (props) => {
    const [hours, minutes, seconds] = useCountup();
    return <ShowCounter hours={hours} minutes={minutes} seconds={seconds} />;
};

const DateTimeDisplay = ({ value, type }) => {
    return (
        <>
            <span className="time-value">{value}</span>
            {(type === 'hours' || type === 'minutes') && <span>:</span>}
        </>
    );
};

export default CountUpTimer;
