import styled from 'styled-components';
import { Box } from '@mui/material';
import { responsive, FONTS, COLORS } from 'Styles/Constants';

export const PlayListWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    ${responsive.TABLET`
        justify-content: start;
        align-items: baseline;
    `}

    .full-width-section {
        width: 60%;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100%;

        ${responsive.TABLET`
            display: none;
        `}

        .playlist-page-content {
            margin-top: 100px;

            .button-container {
                display: flex;
                justify-content: flex-end;

                .add-button {
                    padding: 10px 40px 10px 40px;
                    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
                    border-radius: 33px;
                    align-items: center;
                    background-color: ${COLORS.LIGHT_GREY};
                    height: 55px;

                    .text {
                        font-family: ${FONTS.PRIMARY};
                        text-transform: none;
                        color: ${COLORS.BLACK};
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
            }

            .slider-content {
                position: relative;

                .slider-header {
                    font-family: ${FONTS.SECONDARY};
                    font-weight: 600;
                    font-size: 28px;
                    margin-bottom: 5px;
                }
            }

            .description-container {
                display: flex;
                flex-direction: column;

                .media-time {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: ${COLORS.MEDIUM_GREY};
                    font-family: ${FONTS.PRIMARY};
                }
            }
        }

        .desktop-search-filter {
            margin: 20px 0 0 0;
        }

        .energy-playlist-container {
            margin: 30px 0 0 0;
            width: 100%;
        }
    }

    .playlist-tablet-view {
        display: none;
        height: 100%;

        ${responsive.TABLET`
            display: flex;
        `}

        .heading {
            padding: 0 16px;
            gap: 20px;
            margin: 60px 0 0 5px;

            .title {
                font-family: ${FONTS.SECONDARY};
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 40px;
                letter-spacing: -0.02em;
                text-transform: capitalize;
                color: ${COLORS.PRIMARY_DARK};
            }
        }

        .search-filter {
            padding: 0 16px;
            margin: 40px 0 0 0;

            .playlist-rooms-filter {
                .MuiInputLabel-root {
                    margin-left: 40px;
                }

                .MuiFilledInput-root {
                    border: 1px solid ${COLORS.LIGHT_GREY};
                    flex-direction: row-reverse;
                    background-color: white;
                    border-radius: 30px;

                    .MuiSelect-select {
                        width: 100px;

                        ${responsive.TABLET`
                            width: 70px;
                        `}
                    }

                    svg {
                        margin-left: 20px;
                        cursor: pointer;
                    }
                }

                .filter-select {
                    height: 45px;

                    .MuiSelect-select {
                        padding: 15px 10px;
                        background: none;

                        &:focus,
                        &:active {
                            background: none;
                        }
                    }
                }
            }
        }

        .playlist-list {
            margin-top: 30px;

            .add-item-wrapper {
                padding: 20px 16px;
                width: 100vw;
                gap: 25px;

                .add-item-card {
                    width: max-content;
                }

                .add-item {
                    position: relative;
                    width: 150px;
                    height: 85px;
                    background: ${COLORS.LIGHT_GREY};
                    border-radius: 8px;

                    .lock-icon {
                        position: absolute;
                        bottom: 5px;
                        left: 5%;
                        height: 24px;
                        width: 24px;
                        padding: 2px;
                        border-radius: 50%;
                        background-color: ${COLORS.WHITE};
                        display: flex;
                        justify-content: center;
                    }

                    .add-new-playlist-icon {
                        z-index: 10;
                        transform: scale(1.2);
                    }

                    .playlist-borders {
                        width: 150px;
                        top: 5px;
                        position: absolute;
                        margin-left: 15px;
                    }
                }

                .new-playlist-title {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: ${COLORS.MEDIUM_GREY};
                }
            }

            .play-list-item-wrapper {
                padding: 20px 16px;
                width: 100vw;
                gap: 25px;
                justify-content: space-between;

                ${responsive.PHABLET`
                    padding: 10px 16px;
                `}

                &:nth-child(2n) {
                    background: #8484841a;
                }

                .play-list-content {
                    width: 100%;
                    gap: 25px;

                    .mobile-tao-card {
                        width: max-content;
                    }

                    .add-item {
                        position: relative;
                        width: 150px;
                        height: 85px;
                        border-radius: 8px;

                        .playlist-img {
                            width: 150px;
                            height: 85px;
                            border-radius: 8px;
                        }

                        .play-icon {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 10;
                        }

                        .playlist-borders {
                            width: 150px;
                            top: 5px;
                            position: absolute;
                            margin-left: 15px;
                        }
                    }
                    .playlist-description {
                        align-self: flex-start;

                        .title {
                            font-family: ${FONTS.PRIMARY};
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 27px;
                            color: ${COLORS.BLACK};
                        }

                        .no-of-meditation {
                            font-family: ${FONTS.PRIMARY};
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            text-transform: capitalize;
                            color: ${COLORS.MEDIUM_GREY};
                        }

                        .meditation-time {
                            font-family: ${FONTS.PRIMARY};
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: ${COLORS.MEDIUM_GREY};
                        }
                    }
                }
            }
        }
    }

    .tablet-load-more {
        padding: 20px 0;
        width: max-content;
        margin: 0 auto;

        &:hover {
            .load-more-arrow {
                transition: all 0.3s ease-in-out;
                margin: 5px 0 0;
            }
        }

        .load-more-text {
            color: ${COLORS.PRIMARY};
        }

        .load-more-arrow {
            width: 10px;
            height: 10px;
            border-right: 1px solid ${COLORS.PRIMARY};
            border-bottom: 1px solid ${COLORS.PRIMARY};
            transform: rotate(45deg);
        }
    }
`;

export const PlaylistItemsWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    max-height: 100vh;

    .full-width-section {
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .regulation-page-content {
            display: flex;
            width: 100%;
            margin: 20px 0;
            position: relative;

            .control-section {
                .control-card {
                    box-shadow: none;

                    .card-content-heading {
                        padding-top: 0;
                        max-width: 277px;
                    }

                    .text-heading {
                        font-family: ${FONTS.PRIMARY};
                        color: ${COLORS.PRIMARY_DARK};
                        font-size: 28px;
                        font-weight: 600;
                        width: 100%;
                        text-transform: capitalize;
                        white-space: pre-wrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }

                    .button-container {
                        margin-top: 10px;

                        .button {
                            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                            box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
                            border-radius: 12px;

                            .text {
                                font-family: ${FONTS.PRIMARY};
                                color: ${COLORS.WHITE};
                            }
                            width: 100%;
                        }
                    }

                    .action-button-container {
                        margin-top: 20px;
                        display: flex;
                        width: 100%;
                        gap: 10px;

                        .action-button {
                            background: rgba(255, 255, 255, 0.8);
                            border: 1px solid ${COLORS.LIGHT_GREY};
                            border-radius: 24px;
                            padding: 10px 20px 10px 20px;
                            text-transform: capitalize;
                            width: 130px;

                            .text {
                                font-family: ${FONTS.PRIMARY};
                                color: ${COLORS.BLACK};
                                line-height: initial;
                            }
                        }
                    }
                }
            }

            .slider-content {
                width: 100%;
                overflow: hidden;
            }

            .slider-header {
                font-family: ${FONTS.SECONDARY};
                font-weight: 600;
                font-size: 28px;
                margin-bottom: 5px;
            }

            .card-content-root {
                padding: 0;
            }

            .media-time {
                width: 200px;
                color: ${COLORS.MEDIUM_GREY};
                font-family: ${FONTS.PRIMARY};
                font-size: 12px;
                text-align: left;
            }

            .card-content-comp {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                width: 222px;
                height: 120px;
            }

            .card-img {
                width: 222px;
                height: 120px;
            }

            .play-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 30;
            }

            .load-more {
                padding: 20px 0;
                width: max-content;
                margin: 0 auto;
                height: 135px;

                &:hover {
                    .load-more-arrow {
                        transition: all 0.3s ease-in-out;
                        margin-left: 2px;
                    }
                }

                .load-more-arrow {
                    width: 15px;
                    height: 15px;
                }

                .load-more-text {
                    color: ${COLORS.PRIMARY};
                    width: max-content;
                }
            }
        }
    }
`;

export const NoPlaylistWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${COLORS.WHITE};
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    width: 60%;
    height: 40%;

    .container {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .heading-message {
            font-family: ${FONTS.PRIMARY};
            font-weight: 500;
            font-size: 24px;
            line-height: 35px;
            display: flex;
            align-items: flex-end;
            text-align: center;
            color: ${COLORS.TEXT_DARK};
        }

        .add-button {
            background: ${COLORS.LIGHT_GREY};
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            height: 50px;
            margin-top: 50px;

            .text {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                text-transform: none;
                color: ${COLORS.PRIMARY_WARM};
            }
        }
    }
`;

export const PlayListMediaImg = styled(Box)`
    width: ${(props) => props.width && `${props.width}`};
    border-radius: ${(props) => (props.radius ? `${props.radius}` : `20px 10px`)};
    height: ${(props) => props.height && `${props.height}`};
    background: ${(props) => props.img && `url(${props.img})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;
