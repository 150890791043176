import { COLORS } from 'Styles/Constants';

export const CartImageActiveSVG = () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16 44C17.1046 44 18 43.1046 18 42C18 40.8954 17.1046 40 16 40C14.8954 40 14 40.8954 14 42C14 43.1046 14.8954 44 16 44Z"
            stroke={COLORS.PRIMARY}
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M38 44C39.1046 44 40 43.1046 40 42C40 40.8954 39.1046 40 38 40C36.8954 40 36 40.8954 36 42C36 43.1046 36.8954 44 38 44Z"
            stroke={COLORS.PRIMARY}
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4.09961 4.09961H8.09961L13.4196 28.9396C13.6148 29.8493 14.121 30.6626 14.851 31.2393C15.5811 31.8161 16.4894 32.1203 17.4196 32.0996H36.9796C37.89 32.0981 38.7726 31.7862 39.4817 31.2153C40.1907 30.6444 40.6839 29.8487 40.8796 28.9596L44.1796 14.0996H10.2396"
            stroke={COLORS.PRIMARY}
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const OrderSVG = () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M38 42V38C38 35.8783 37.1571 33.8434 35.6569 32.3431C34.1566 30.8429 32.1217 30 30 30H18C15.8783 30 13.8434 30.8429 12.3431 32.3431C10.8429 33.8434 10 35.8783 10 38V42"
            stroke={COLORS.TEXT_DARK}
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M24 22C28.4183 22 32 18.4183 32 14C32 9.58172 28.4183 6 24 6C19.5817 6 16 9.58172 16 14C16 18.4183 19.5817 22 24 22Z"
            stroke={COLORS.TEXT_DARK}
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const CartImageSVG = () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16 44C17.1046 44 18 43.1046 18 42C18 40.8954 17.1046 40 16 40C14.8954 40 14 40.8954 14 42C14 43.1046 14.8954 44 16 44Z"
            stroke="#656565"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M38 44C39.1046 44 40 43.1046 40 42C40 40.8954 39.1046 40 38 40C36.8954 40 36 40.8954 36 42C36 43.1046 36.8954 44 38 44Z"
            stroke="#656565"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4.09961 4.09961H8.09961L13.4196 28.9396C13.6148 29.8493 14.121 30.6626 14.851 31.2393C15.5811 31.8161 16.4894 32.1203 17.4196 32.0996H36.9796C37.89 32.0981 38.7726 31.7862 39.4817 31.2153C40.1907 30.6444 40.6839 29.8487 40.8796 28.9596L44.1796 14.0996H10.2396"
            stroke="#656565"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
