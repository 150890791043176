import { Switch } from '@mui/material';
import styled from 'styled-components';

export const CustomSwitch = styled(Switch)`
    &.switch-root {
        padding: 7px;
    }
    .switch-track {
        border-radius: 20px;
        background: rgba(164, 164, 164, 0.6);

        &.active {
            background: rgba(217, 167, 52, 0.6);
            mix-blend-mode: multiply;
        }
    }
    .switch-thumb {
        background: linear-gradient(180deg, #c4c4c4 0%, #8d8d8d 100%);
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

        &.active {
            background: linear-gradient(180deg, #d3ab43 0%, #ad8724 100%);
        }
    }
`;
