import React, { useState } from 'react';

import { Button, Box, Typography, Input, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import Friend1 from "Assets/images/Profile/default-user-icon.jpg";
import Friend2 from "Assets/images/Profile/default-user-icon.jpg";
import Friend3 from "Assets/images/Profile/default-user-icon.jpg";
import Friend4 from "Assets/images/Profile/default-user-icon.jpg";

import { ReactComponent as SearchIcon } from 'Assets/images/Common/outlined-search-icon.svg'
import { ReactComponent as ArrowImage } from "Assets/images/Common/down-arrow.svg"

import { NewChatWrapper } from './Chat.style';

const NewChat = React.forwardRef(({ style = {}, onClose = () => { }, className = "", ...props }, ref) => {
    const [filterVal, setFilterValue] = useState("");

    const clearFilter = () => {
        setFilterValue("");
    }

    const onFilterChange = (e) => {
        setFilterValue(e.target.value);
    }
    return (
        <NewChatWrapper style={{ ...style }} className={className} ref={ref}>
            <Box className="new-title-container flex">
                <Box className="back-button-container">
                    <IconButton onClick={onClose} className="arrow-btn">
                        <ArrowImage />
                    </IconButton>
                </Box>
                <Typography className="title">New Chat</Typography>
                <Button className="btn" onClick={onClose}>Cancel</Button>
            </Box>
            <Input value={filterVal} onChange={onFilterChange} startAdornment={<SearchIcon className="search-icon" />} endAdornment={<IconButton onClick={clearFilter} className="clear-btn"><CloseIcon /></IconButton>} disableUnderline className="filter-input" variant="filled" />
            <Box className="list-container">
                <Typography variant="h5" className="title">FRIENDS</Typography>
                <Box className="list">
                    {
                        FRIENDS.map((f, index) => <Box className="list-item">
                            <Box style={{ backgroundImage: `url(${f.image})` }} className="profile-image"></Box>
                            <Typography className="name">{f.name}</Typography>
                        </Box>)
                    }
                </Box>
            </Box>
            <Box className="list-container">
                <Typography variant="h5" className="title">
                    COMMUNITY
                </Typography>
                <Box className="list">
                    {
                        COMMUNITIES.map((f, index) => <Box className="list-item">
                            <Box style={{ backgroundImage: `url(${f.image})` }} className="profile-image"></Box>
                            <Typography className="name">{f.name}</Typography>
                        </Box>)
                    }
                </Box>
            </Box>
        </NewChatWrapper>
    );
});

export default NewChat;

const FRIENDS = [
    {
        image: Friend1,
        name: "Eleanor"
    },
    {
        image: Friend2,
        name: "Thomas"
    },
    {
        image: Friend3,
        name: "Eleanor"
    },
    {
        image: Friend4,
        name: "Thomas"
    },
];

const COMMUNITIES = [
    {
        image: Friend1,
        name: "Eleanor"
    },
    {
        image: Friend1,
        name: "Eleanor"
    },
]