import styled from 'styled-components';
import { Box } from '@mui/material';
import { FONTS, COLORS, responsive } from 'Styles/Constants';

export const TaoMeditationCardWrapper = styled(Box)`
    p {
        font-family: ${FONTS.PRIMARY};
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #848484;
        margin: 0px;
    }
    .meditation-content-container {
        display: flex;
        flex-direction: column;

        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        margin: 5px;
        position: relative;

        width: 165px;
        height: 245px;

        .content-image-container {
            width: 165px;
            height: 95px;
            min-height: 95px;

            .content-image {
                border-radius: 12px 12px 0px 0px;
                width: 100%;
                height: 100%;
            }
        }

        .play-icon {
            position: absolute;
            width: 40px;
            height: 40px;
            left: 60px;
            top: 30px;
            cursor: pointer;
        }

        .meditation-content-description-details {
            padding: 12px;
            overflow: hidden;

            .meditation-content-title {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: ${COLORS.PRIMARY_WARM};
                max-height: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .meditation-content-details {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                gap: 5px;
                img {
                    width: 20px;
                    height: 20px;
                }

                .type-and-time-description {
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    color: ${COLORS.PRIMARY_DARK};
                }
            }

            .purchase-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 5px 0;

                .purchase-text-container {
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    color: ${COLORS.PRIMARY_DARK};
                }

                .purchase-by-heart-text-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .purchase-by-heart-text {
                        margin: 0 0 0 4px;
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        color: ${COLORS.PRIMARY_DARK};
                    }
                    .heart-image {
                        margin: 0 0 0 4px;
                    }
                }
            }

            .purchase-text {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                color: ${COLORS.PRIMARY_DARK};
            }

            .meditation-content-description-container {
                min-height: 40px;
                max-height: 88px;

                .meditation-content-description {
                    width: 146px;
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: ${COLORS.MEDIUM_GREY};
                    line-height: 18px;
                    overflow: hidden;
                    word-wrap: break-word;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;

                    &.two-line-clamp {
                        -webkit-line-clamp: 2;
                    }

                    &.three-line-clamp {
                        -webkit-line-clamp: 3;
                    }
                }
            }
        }

        .unlock-container {
            max-width: 160px;
            background: ${COLORS.PRIMARY_DARK};
            display: flex;
            flex-direction: row;
            justify-content: center;
            position: absolute;
            top: 0%;
            left: 0%;
            z-index: 12;

            ${responsive.PHABLET`
                max-width: ${(props) => (props?.$supportedResponsiveView ? '100px' : '160px')};
            `}

            &.badge {
                top: 25%;
                border-radius: 50px;
                background: rgba(101, 101, 101, 0.7);
                padding: 5px;
                left: 3%;
            }

            &.get {
                right: 0%;
                left: auto;
                border-radius: 0 6px 0 6px;
            }

            .unlock-text {
                font-family: ${FONTS.TERTIARY_BOLD};
                font-size: 12px;
                text-transform: uppercase;
                color: ${COLORS.WHITE};
                padding: 2px 8px;
                width: 100%;
            }
        }

        .favorite-heart-image-container {
            position: absolute;
            right: 4px;
            top: 4px;
            cursor: pointer;
        }

        .lock-content-container {
            position: absolute;
            height: 100%;
            width: 100%;
            border-radius: 12px;
            background-color: rgba(255, 255, 255, 0.5);

            .lock-icon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                height: 70px;
                width: 70px;
            }
        }
    }
`;
