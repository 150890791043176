import { Modal } from '@mui/material';
import styled from 'styled-components';
import { COLORS, responsive } from 'Styles/Constants';

export const PurchaseModalWrapper = styled(Modal)`
    &.modal-root {
        display: flex;
        justify-content: center;
        align-items: center;
        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.039))
            drop-shadow(0px 7px 24px rgba(0, 0, 0, 0.19));
    }
    .backdrop-root {
        background: rgba(0, 0, 0, 0.3);
    }
    .dialog-container {
        width: 360px;
        height: 140px;
        position: absolute;
        bottom: 0;
        background-color: ${COLORS.WHITE};
        border: 1px solid ${COLORS.LIGHT_GREY};
        border-radius: 32px 32px 0px 0px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        ${responsive.MOBILE`
            width: 90%;
        `}

        .list-item {
            padding: 5px;
            width: 100%;
            border-radius: 8px;
            color: ${COLORS.BLACK};
            text-transform: capitalize;
            justify-content: flex-start;

            &:hover {
                background-color: ${COLORS.LIGHT_GREY};
            }
            .text {
                font-size: 16px;
                margin: 0 0 0 20px;
            }
            .divider {
                width: 30%;
                position: absolute;
                bottom: -15px;
                transform: translate(100%, 0px);
                height: 2px;
                background: rgba(0, 0, 0, 0.6);
                border-radius: 4px;
            }
        }
    }
`;
