import { Dialog } from '@mui/material';
import styled from 'styled-components';
import { COLORS, responsive } from 'Styles/Constants';

export const DeleteConfirmationWrapper = styled(Dialog)`
    .root {
        height: max-content;
        padding: 50px 20px;
        background: #ffffff;
        box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.15);
        border-radius: 24px;
        align-items: center;
        max-width: 600px;
    }

    .text {
        color: ${COLORS.PRIMARY_WARM};
        font-size: 24px;
        text-align: center;
        min-width: 100%;

        ${responsive.PHABLET`
            font-size: 18px;
        `}
    }
    .btn {
        width: 161px;
        height: 57px;
        margin: 20px 0 0;
        font-size: 16px;

        ${responsive.PHABLET`
            width: 80px;
            font-size: 14px;
            height: 48px;
        `}
        &:last-child {
            margin-left: 10px;
        }

        &.positive {
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
            border-radius: 12px;
            color: #fff;
        }
        &.negative {
            background: #fff;
            border: 1px solid #b08c3d;
            border-radius: 12px;
        }
    }
`;
