import React, { useState, useEffect, useCallback } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    Typography,
    Box,
    Backdrop,
    Slide,
    IconButton,
    TextField,
    FormHelperText
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { ReactComponent as InviteUserIcon } from 'Assets/images/Common/invite-user-icon.svg';

import { ModalWrapper } from './Modal.style';
import { TaoButton } from 'Components/common/Button.style';

const AddUserModal = ({ open = false, roomDetails = {}, onClose = () => {}, ...props }) => {
    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required('Email is required').email('Please Enter valid Email')
        }),
        onSubmit: async (values) => {
            console.log(values);
            setSubmitted(true);
        }
    });

    const [submitted, setSubmitted] = useState(false);

    const resetFormHandler = useCallback(() => {
        formik.resetForm();
        setSubmitted(false);
    }, [formik]);

    useEffect(() => {
        if (!open) {
            resetFormHandler();
        }
    }, [open, resetFormHandler]);

    return (
        <ModalWrapper
            open={open}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 250
            }}
            onClose={onClose}
            closeAfterTransition>
            <Slide timeout={250} in={open} direction="up">
                <Box className="dialog-container">
                    <IconButton className="close-btn" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    <Box className="content-container">
                        <Box className="title-container">
                            <InviteUserIcon className="icon" />
                            <Typography className="title">{roomDetails.title}</Typography>
                        </Box>
                        {!submitted ? (
                            <form className="flex f-column" onSubmit={formik.handleSubmit}>
                                <Box className="invite-container">
                                    <Typography className="invite-text" gutterBottom>
                                        Invite users to follow
                                    </Typography>
                                    <TextField
                                        className="text-field"
                                        variant="filled"
                                        label="Enter an Email address"
                                        name="email"
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                        InputProps={{
                                            disableUnderline: true,
                                            className: 'filled-input'
                                        }}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <FormHelperText style={{ marginLeft: '14px' }} error>
                                            {formik.errors.email}
                                        </FormHelperText>
                                    )}
                                </Box>
                                <TaoButton className="invite-btn" type="submit">
                                    send invite
                                </TaoButton>
                            </form>
                        ) : (
                            <Box className="successful-invite-container">
                                <Typography className="success" gutterBottom>
                                    Invite successfully sent to
                                </Typography>
                                <Typography className="email" gutterBottom>
                                    {formik.values.email}
                                </Typography>
                                <Box className="btn-container">
                                    <TaoButton onClick={resetFormHandler} className="send-btn">
                                        SEND ANOTHER
                                    </TaoButton>
                                    <TaoButton
                                        onClick={onClose}
                                        variant="outlined"
                                        className="modal-close-btn">
                                        CLOSE
                                    </TaoButton>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Slide>
        </ModalWrapper>
    );
};

export default AddUserModal;
