import { Box } from '@mui/system';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import MeditationShadeBackground from 'Assets/images/Common/shade-pic.svg';

export const MeditationReadWrapper = styled(Box)`
    height: calc(100vh - 50px);
    width: 100%;
    position: relative;
    overflow: auto;

    ${responsive.TABLET`
        height: 100%;
    `}

    .top-image {
        height: 70%;
        width: 100%;
        position: absolute;
        top: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100%;

        ${responsive.TABLET`
            display: none;
        `}
    }

    .bottom-image {
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0;
        background-image: url(${MeditationShadeBackground});
        background-repeat: no-repeat;
        background-size: 100% auto;
    }

    .read-container {
        position: relative;
        width: 85%;
        padding: 50px 0 0 0;
        min-height: 500px;

        ${responsive.TABLET`
            flex-direction: column;
            width: 100%;
        `}
        .extra-feature-icon {
            background: #fff;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);

            ${responsive.TABLET`
                display: none;
            `}
        }

        .extra-feature-icon {
            width: 35px;
            height: 35px;
            padding: 0;
            margin: 0;
        }

        .content {
            width: 80%;
            min-height: 100%;
            margin: 0 auto;
            background: ${COLORS.WHITE};
            border-radius: 15px 15px 0px 0px;
            padding: 0 50px;
            overflow: auto;
            position: relative;

            /* HIDE SCROLL BAR
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            } */

            ${responsive.TABLET`
                width: 100%;
                padding: 0 10px;
            `}

            .title {
                font-family: ${FONTS.SECONDARY_BOLD};
                font-size: 40px;
                text-transform: capitalize;
                color: ${COLORS.PRIMARY_DARK};
                margin: 0 0 50px 0;
                padding: 50px 0 0;
                position: sticky;
                top: 0;
                width: 100%;
                background: ${COLORS.WHITE};
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            .content-overflow {
                height: max-content;
            }
        }
    }
`;
