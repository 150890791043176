// CORE
import React, { Component, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import Toast from 'Components/common/Toast/Toast';

import { hideToast, setPermissions } from 'Redux/App/Actions';
import Api from 'Helpers/ApiHandler';
import CODES from 'Helpers/StatusCodes';
import { logoutUser, updateUserInfo } from 'Redux/Auth/Actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

class EventManager extends Component {
    render() {
        return (
            <React.Fragment>
                <Toast
                    open={this.props.toast.enable}
                    message={this.props.toast.message}
                    variant={this.props.toast.variant}
                    duration={this.props.toast.duration}
                    handleClose={this.props.hideToast}
                />
                {this.props.isLoggedIn && <AfterLoginEvents />}
            </React.Fragment>
        );
    }
}

const AfterLoginEvents = () => {
    const API = useMemo(() => new Api(), []);
    const dispatch = useDispatch();

    const fetchUserDetails = useCallback(async () => {
        try {
            const result = await Promise.all([
                API.get('user/profile'),
                API.get('user/module/permission')
            ]);

            if (result?.[0]?.status !== CODES.SUCCESS) {
                logoutUser();
                return;
            }

            if (result?.[0]?.data) dispatch(updateUserInfo(result?.[0]?.data?.data));
 
            if (result?.[1]?.data) {
                dispatch(setPermissions(result?.[1]?.data?.data));
            }
        } catch (error) {
            console.log('TCL ->', error);
        }
    }, [API, dispatch]);

    useEffect(() => {
        fetchUserDetails();
    }, [fetchUserDetails]);

    return <React.Fragment />;
};

const mapReduxStateToProps = (state) => {
    return {
        toast: state.App.toast,
        isLoggedIn: state.Auth.isLoggedIn,
        permissions: state.App.permissions
    };
};

export default connect(mapReduxStateToProps, {
    hideToast,
    logoutUser
})(withRouter(EventManager));

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return <Component {...props} location={location} navigate={navigate} params={params} />;
    }
    return ComponentWithRouterProp;
}
