import styled from 'styled-components';
import { responsive, COLORS, FONTS } from 'Styles/Constants';

export const TopbarWrapper = styled.div`
    flex: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    transition-property: top;
    transition-duration: 800ms;
    transition-timing-function: ease-out;
    display: ${(props) => (props.showTopBar ? 'flex' : 'none')};
    height: 60px;
    z-index: 1000;

    &.hide-topbar {
        top: -70px;

        ${responsive.PHABLET`
           top: -100px;
        `}
    }

    .login-page-headers {
        display: flex;
        justify-content: end;
        align-items: center;
        min-width: max-content;
        gap: 30px;

        .typo-root {
            cursor: pointer;
            min-width: max-content;
            color: ${COLORS.TEXT_DARK};
            text-transform: uppercase;
            font-size: 16px;

            ${responsive.LAPTOP`
                display: none
            `}
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        gap: 10px;

        .btn {
            border-radius: 37px;
            font-size: 16px;
            text-transform: uppercase;
            width: 170px;
            color: ${COLORS.PRIMARY_DARK};
            height: 48px;

            ${responsive.LAPTOP`
                width : 150px;
            `}

            &.login {
                border: 2px solid ${COLORS.PRIMARY};
                background-color: ${COLORS.WHITE};
            }
            &.sign-up {
                background-color: ${COLORS.LIGHT_GREY};
            }

            ${responsive.PHABLET`
                display: none;
            `}
        }
    }

    .questionnaire-page-headers {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 15px;

        .default-profile-container {
            ${responsive.PHABLET`
               margin-top: 25px;
            `}
        }
    }

    .app-bar-root {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: transparent;
        transition: none;
        box-shadow: none;
        padding: 0 20px 0 15px;

        ${responsive.PHABLET`
            padding: 0 10px;
        `}

        .header-logo-section {
            padding: 0;

            &.back-arrow-icon {
                width: 48px;
                height: 48px;
                align-self: center;
            }
            &.tao-logo-icon {
                /* ${responsive.PHABLET`
                    display: none;
                `} */
            }
        }

        .white-circle-wrapper {
            height: 48px;
            width: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
            border-radius: 24px;

            &.disabled {
                opacity: 0.5;
            }
        }

        .second-section {
            display: flex;
            align-items: center;
            margin: 0 0 0 auto;

            ${responsive.PHABLET`
               flex-direction: row-reverse;
               width: 100%;
            `}

            .favorites-container {
                background-color: #ffffff;
                border-radius: 25px;
                margin-left: 20px;

                .favorites-button {
                    height: 48px;
                    width: 48px;
                }

                box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
            }

            .interaction-icons-wrapper {
                display: flex;
                gap: 10px;
            }

            .profile-overlay {
                position: relative;
                height: 48px;
                width: 48px;

                ${responsive.PHABLET`
                    margin-left: 10px;
                `}

                .profile-container {
                    height: 100%;
                    width: 100%;
                    background-color: #ffffff;
                    border-radius: 26px;
                    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }

                .outerbox {
                    overflow: hidden;
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    top: 31px;
                    left: -40px;

                    ${responsive.DESKTOP`
                        left: -39px;
                    `}

                    ${responsive.PHABLET`
                        left: 58px;
                    `}

                    &::before {
                        content: '';
                        display: block;
                        width: 200%;
                        height: 200%;
                        position: absolute;
                        border-radius: 30%;
                        bottom: 0;
                        right: 0;
                        box-shadow: 50px 50px 0 0 #ffffff;

                        ${responsive.PHABLET`
                            right: -41px;
                            box-shadow: -50px 50px 0 0 #ffffff;
                            top: -41px;
                        `}
                    }
                }
            }
        }

        .tao-logo {
            height: 45px;
            width: 50px;
        }

        .heart-logo {
            height: 45px;
            width: 130px;

            ${responsive.TABLET`
                display: none;
            `}
        }
    }
`;

export const UserMenuMainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #ffffff;
    border-radius: 15px;
    width: 262px;
    z-index: 300;
    padding: 25px 20px 20px 10px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
    margin-right: -5px;
    border-top-right-radius: 0;

    ${responsive.DESKTOP`
        position: relative;
        top: -1px;
    `}

    ${responsive.PHABLET`
        border-top-right-radius: 15px;
        border-top-left-radius: 0;

        position: relative;
        right: 5px;
        top: -1px;
    `}
    
    .menu-details {
        display: flex;
        padding: 0;

        .user-menu-logo {
            flex: 30%;
            height: 24px;
            width: 24px;
        }

        .user-menu-badge {
            flex: 30%;

            .BaseBadge-badge {
                margin-right: 10px;

                font-family: ${FONTS.PRIMARY};
                font-weight: 700;
                font-size: 10px;
                line-height: 14px;
                display: flex;
                align-items: flex-end;
                text-align: center;
                color: #ffffff;
            }
        }

        .MuiTypography-root {
            flex: 70%;
            display: flex;
            justify-content: flex-start;
            font-family: ${COLORS.PRIMARY};
            font-weight: 400;
            font-size: 18px;
            line-height: 18px;
            text-transform: capitalize;
            color: ${COLORS.TEXT_DARK};
        }
    }
`;
