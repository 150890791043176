import { ACTION_TYPES } from './Actions';

const initState = {
    showTopBar: true,
    showSubscriptionModal: false,
    showTopBarWithTransition: true,
    showFooter: true,
    showLoginPageHeader: false,
    showQuestionnairePageHeader: false,
    showNormalHeader: false,
    showUserMenuToggle: false,
    toast: {
        enable: false,
        message: '',
        variant: '',
        duration: 0
    },
    interactionDetail: {},
    scrollIntoViewId: '',
    hideInteractionButton: false,
    permissions: [],
    tourEnabled: false
};

const Reducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SHOW_TOP_BAR:
            return {
                ...state,
                showTopBar: true
            };

        case ACTION_TYPES.HIDE_TOP_BAR:
            return {
                ...state,
                showTopBar: false
            };
        case ACTION_TYPES.TOUR_ENABLED:
            return {
                ...state,
                tourEnabled: true
            };

        case ACTION_TYPES.TOUR_DISABLED:
            return {
                ...state,
                tourEnabled: false
            };
        case ACTION_TYPES.SHOW_USER_MENU_TOGGLE:
            return {
                ...state,
                showUserMenuToggle: action?.data?.show
            };

        case ACTION_TYPES.SHOW_TOP_BAR_WITH_TRANSITION:
            return {
                ...state,
                showTopBarWithTransition: true
            };

        case ACTION_TYPES.HIDE_TOP_BAR_WITH_TRANSITION:
            return {
                ...state,
                showTopBarWithTransition: false
            };

        case ACTION_TYPES.SHOW_FOOTER:
            return {
                ...state,
                showFooter: true
            };
        case ACTION_TYPES.HIDE_FOOTER:
            return {
                ...state,
                showFooter: false
            };

        case ACTION_TYPES.TOAST_MESSAGE_ENABLE:
            return {
                ...state,
                toast: {
                    enable: true,
                    message: action.message,
                    variant: action.variant,
                    duration: action.duration
                }
            };

        case ACTION_TYPES.TOAST_MESSAGE_DISABLE:
            return {
                ...state,
                toast: {
                    enable: false,
                    message: '',
                    variant: ''
                }
            };
        case ACTION_TYPES.CONTENT_DETAIL:
            return {
                ...state,
                interactionDetail: { ...action?.interactionDetail }
            };
        case ACTION_TYPES.SCROLL_INTO_VIEW_ID:
            return {
                ...state,
                scrollIntoViewId: action?.scrollIntoViewId
            };
        case ACTION_TYPES.SHOW_SUBSCRIPTION_MODAL:
            return {
                ...state,
                showSubscriptionModal: true
            };

        case ACTION_TYPES.HIDE_SUBSCRIPTION_MODAL:
            return {
                ...state,
                showSubscriptionModal: false
            };
        case ACTION_TYPES.HIDE_INTERACTION_BUTTON:
            return {
                ...state,
                hideInteractionButton: action?.hideInteractionButton
            };
        case ACTION_TYPES.SET_PERMISSIONS:
            return {
                ...state,
                permissions: action?.permissions
            };
        // case ACTION_TYPES.SHOW_LOGIN_PAGE_HEADER:
        //     return {
        //         ...state,
        //         showLoginPageHeader: true,
        //         showQuestionnairePageHeader: false,
        //         showNormalHeader: false,
        //     };

        // case ACTION_TYPES.SHOW_QUESTIONNAIRE_PAGE_HEADER:
        //     return {
        //         ...state,
        //         showLoginPageHeader: false,
        //         showQuestionnairePageHeader: true,
        //         showNormalHeader: false,
        //     };
        // case ACTION_TYPES.SHOW_NORMAL_HEADER:
        //     return {
        //         ...state,
        //         showLoginPageHeader: false,
        //         showQuestionnairePageHeader: false,
        //         showNormalHeader: true,
        //     };
        default:
            return state;
    }
};

export default Reducer;
