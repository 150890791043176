import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

// THEMES
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';

// USER DEFINED
import Website from './Components/Website';
import { THEME_SETTINGS } from './themeSettings';
import { UtilityStyles } from './Styles/Utils';
import { SnackbarProvider } from 'notistack';

import { GoogleOAuthProvider } from '@react-oauth/google';
import styled from 'styled-components';

const theme = createTheme(THEME_SETTINGS);

function App() {
    return (
        <NotificationStackWrapper
            maxSnack={3}
            classes={{ containerRoot: 'containerRoot' }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}>
            <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
                <MuiThemeProvider theme={theme}>
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                        <Website />
                        <UtilityStyles />
                    </GoogleOAuthProvider>
                </MuiThemeProvider>
            </BrowserRouter>
        </NotificationStackWrapper>
    );
}

export default App;

// NOTIFICATION GLOBAL CSS

const NotificationStackWrapper = styled(SnackbarProvider)`
    &.SnackbarContent-root {
        padding: 0 !important;
        border-radius: 12px !important;
        background-color: #fff !important;
    }
    .SnackbarItem-message {
        padding: 0 !important;
    }
`;
