import { Box, Typography } from '@mui/material';
import { TaoButton } from 'Components/common/Button.style';
import { MEDIA_TYPE } from 'Helpers/Constants';
import React from 'react';
import { ReactComponent as UpArrowSVG } from 'Assets/images/MeditationContent/up-arrow.svg';

const ButtonState = ({
    currentPhase,
    PHASES,
    contentDetails,
    redirectToPlayer,
    setCurrentPhase,
    buyDetails,
    buyByHeartDetails,
    openPurchaseModal,
    ...props
}) => {
    return currentPhase === PHASES.LISTEN ? (
        <Box className="flex f-h-center" style={{ margin: '15px 0', position: 'relative' }}>
            <TaoButton
                onClick={() => {
                    if (
                        (contentDetails?.isAssessmentDone &&
                            contentDetails?.isPostAssessmentCompleted) ||
                        contentDetails?.isInstructional
                    ) {
                        redirectToPlayer();
                        return;
                    }
                    setCurrentPhase(PHASES.PRE_ASSESSMENT);
                }}
                variant="filled">
                {contentDetails?.type === MEDIA_TYPE?.VIDEO
                    ? 'Watch'
                    : contentDetails?.type === MEDIA_TYPE?.TEXT
                    ? 'Read'
                    : 'Listen'}
            </TaoButton>
        </Box>
    ) : (
        <Box className="flex get-button">
            <TaoButton onClick={openPurchaseModal}>
                <Typography>Get</Typography>
                <UpArrowSVG style={{ marginLeft: '10px' }} />
            </TaoButton>
        </Box>
    );
};

export default ButtonState;
