import React, { useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from 'Assets/images/Common/outlined-search-icon.svg';

import { Typography, Box, Input, Chip, Switch, FormControlLabel } from '@mui/material';
import { CSSTransition } from 'react-transition-group';

import MyRooms from './MyRooms/MyRooms';
import RoomFilter from './RoomFilter/RoomFilter';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import { MeditationRoomsWrapper } from './MeditationRooms.style';
import TaoSlider from 'Components/common/TaoSlider';

const ROOMS = {
    ALL: {
        title: 'ALL',
        screenNumber: 0
    },
    FOLLOWING: {
        title: 'FOLLOWING',
        screenNumber: 1
    },
    FAVORITES: {
        title: 'FAVORITES',
        screenNumber: 2
    },
    MY_ROOMS: {
        title: 'MY ROOMS',
        screenNumber: 3
    }
};

const SearchInput = ({ filterInputValue, filterInputChangeHandler, ...props }) => (
    <Input
        startAdornment={<SearchIcon />}
        classes={{ root: 'search-input-root', input: 'input' }}
        value={filterInputValue}
        onChange={filterInputChangeHandler}
        disableUnderline
        className="filter-input"
        variant="filled"
        placeholder="Search Rooms"
    />
);

const MeditationRooms = ({ ...props }) => {
    const [selectedChip, setSelectedChip] = useState(ROOMS.ALL.title);
    const [screenNumber, setScreenNumber] = useState(ROOMS.ALL.screenNumber);
    const [filterInputValue, setFilterInputValue] = useState('');
    const meditationRoomRef = useRef(null);

    const setScreenHandler = () => {
        let findActiveScreenNumber = Object.values(ROOMS).find(
            (item) => item?.title === selectedChip
        );

        setScreenNumber(findActiveScreenNumber.screenNumber);
    };

    const filterInputChangeHandler = (e) => {
        setFilterInputValue(e.target.value);
    };

    return (
        <>
            <TaoCalligraphyWatermark />
            <MeditationRoomsWrapper>
                <Box className="page-content">
                    <Box className="page-title-container">
                        <Typography className="title">Meditation Rooms</Typography>
                        <Box className="filter-container mobile-view">
                            <SearchInput
                                filterInputValue={filterInputValue}
                                filterInputChangeHandler={filterInputChangeHandler}
                            />
                        </Box>

                        <Box className="top-filter-container">
                            <Box className="tag-container">
                                <TaoSlider ref={meditationRoomRef} gap={10} showArrows={false}>
                                    {Object.values(ROOMS).map((room, index) => (
                                        <Chip
                                            clickable
                                            key={index}
                                            variant={
                                                selectedChip === room?.title ? 'outlined' : 'filled'
                                            }
                                            label={room?.title}
                                            className={`chip ${
                                                selectedChip === room?.title && 'selected-chip'
                                            }`}
                                            onClick={() => {
                                                setSelectedChip(room?.title);
                                            }}
                                        />
                                    ))}
                                </TaoSlider>
                            </Box>
                            <Box className="filter-container screen-view">
                                <SearchInput
                                    filterInputValue={filterInputValue}
                                    filterInputChangeHandler={filterInputChangeHandler}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box className="page-content-container">
                        <Box className="border">
                            <Box className="bottom-blur" />
                        </Box>

                        <CSSTransition
                            timeout={450}
                            classNames="all-rooms"
                            in={
                                selectedChip !== ROOMS.MY_ROOMS?.title &&
                                screenNumber !== ROOMS.MY_ROOMS.screenNumber
                            }
                            onExited={setScreenHandler}
                            unmountOnExit>
                            <RoomFilter roomType={selectedChip} />
                        </CSSTransition>
                        <CSSTransition
                            timeout={450}
                            classNames="my-rooms"
                            in={
                                selectedChip === ROOMS.MY_ROOMS?.title &&
                                screenNumber === ROOMS.MY_ROOMS.screenNumber
                            }
                            onExited={setScreenHandler}
                            unmountOnExit>
                            <MyRooms roomType={selectedChip} />
                        </CSSTransition>
                    </Box>
                </Box>
                <Box className="flower-image-container" />
            </MeditationRoomsWrapper>
        </>
    );
};

export default MeditationRooms;
