import React from 'react';

export const StartImage1 = (props) => {
    return (
        <>
            {props?.fill ? (
                <svg
                    {...props}
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.4833 29.8519C18.8011 29.6601 19.1989 29.6601 19.5167 29.8519L29.331 35.7754C30.0883 36.2325 31.0225 35.5535 30.8216 34.692L28.2171 23.5278C28.1328 23.1663 28.2556 22.7878 28.5362 22.5448L37.2086 15.0319C37.8772 14.4527 37.5198 13.3544 36.6384 13.2796L25.2209 12.311C24.8513 12.2796 24.5294 12.0463 24.3847 11.7048L19.9207 1.17237C19.5761 0.359343 18.4239 0.359342 18.0793 1.17237L13.6153 11.7048C13.4706 12.0463 13.1487 12.2796 12.7791 12.311L1.36163 13.2796C0.480202 13.3544 0.122796 14.4527 0.791394 15.0319L9.46379 22.5448C9.74436 22.7878 9.86721 23.1663 9.78287 23.5278L7.17841 34.692C6.97745 35.5535 7.91168 36.2325 8.669 35.7754L18.4833 29.8519Z"
                        fill="#B08C3D"
                    />
                </svg>
            ) : (
                <svg
                    {...props}
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M37.2157 15.0286C37.883 14.4495 37.5264 13.3529 36.6462 13.277L25.2195 12.2917C24.8506 12.2599 24.5295 12.0269 24.3849 11.6861L19.9212 1.17023C19.5763 0.35763 18.4245 0.357942 18.08 1.17073L13.6153 11.7048C13.4706 12.0463 13.1487 12.2796 12.7791 12.311L1.36163 13.2796C0.480202 13.3544 0.122796 14.4527 0.791394 15.0319L9.46379 22.5448C9.74436 22.7878 9.86721 23.1663 9.78287 23.5278L7.17841 34.692C6.97745 35.5535 7.91168 36.2325 8.669 35.7754L18.4833 29.8519C18.8011 29.6601 19.1989 29.6601 19.5167 29.8519L29.3348 35.7777C30.0916 36.2344 31.0254 35.5567 30.8257 34.6957L28.236 23.5265C28.1523 23.1657 28.275 22.7882 28.5547 22.5454L37.2157 15.0286ZM19.517 26.1127C19.1991 25.9204 18.8008 25.9203 18.4827 26.1123L13.5102 29.1143C12.7527 29.5716 11.8182 28.8923 12.0195 28.0307L13.3427 22.3675C13.4272 22.0062 13.3045 21.6278 13.0242 21.3846L8.62395 17.5675C7.95667 16.9887 8.31274 15.8922 9.19279 15.8159L14.9994 15.3121C15.3688 15.2801 15.6902 15.0462 15.8343 14.7045L18.0802 9.38051C18.4238 8.56594 19.5779 8.56524 19.9225 9.37939L22.1853 14.7256C22.3297 15.0667 22.6508 15.3 23.0198 15.332L28.8272 15.8359C29.7073 15.9122 30.0633 17.0087 29.396 17.5875L24.9958 21.4046C24.7155 21.6478 24.5928 22.0262 24.6773 22.3875L25.9999 28.0483C26.2013 28.9102 25.266 29.5895 24.5086 29.1315L19.517 26.1127Z"
                        fill="#B08C3D"
                    />
                </svg>
            )}
        </>
    );
};

export const StartImage2 = (props) => {
    return (
        <>
            {props?.fill ? (
                <svg
                    {...props}
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.4833 29.8519C18.8011 29.6601 19.1989 29.6601 19.5167 29.8519L29.331 35.7754C30.0883 36.2325 31.0225 35.5535 30.8216 34.692L28.2171 23.5278C28.1328 23.1663 28.2556 22.7878 28.5362 22.5448L37.2086 15.0319C37.8772 14.4527 37.5198 13.3544 36.6384 13.2796L25.2209 12.311C24.8513 12.2796 24.5294 12.0463 24.3847 11.7048L19.9207 1.17237C19.5761 0.359343 18.4239 0.359342 18.0793 1.17237L13.6153 11.7048C13.4706 12.0463 13.1487 12.2796 12.7791 12.311L1.36163 13.2796C0.480202 13.3544 0.122796 14.4527 0.791394 15.0319L9.46379 22.5448C9.74436 22.7878 9.86721 23.1663 9.78287 23.5278L7.17841 34.692C6.97745 35.5535 7.91168 36.2325 8.669 35.7754L18.4833 29.8519Z"
                        fill="#B08C3D"
                    />
                </svg>
            ) : (
                <svg
                    {...props}
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M37.2157 15.0286C37.883 14.4495 37.5264 13.3529 36.6462 13.277L25.2195 12.2917C24.8506 12.2599 24.5295 12.0269 24.3849 11.6861L19.9212 1.17023C19.5763 0.35763 18.4245 0.357942 18.08 1.17073L13.6153 11.7048C13.4706 12.0463 13.1487 12.2796 12.7791 12.311L1.36163 13.2796C0.480202 13.3544 0.122796 14.4527 0.791394 15.0319L9.46379 22.5448C9.74436 22.7878 9.86721 23.1663 9.78287 23.5278L7.17841 34.692C6.97745 35.5535 7.91168 36.2325 8.669 35.7754L18.4833 29.8519C18.8011 29.6601 19.1989 29.6601 19.5167 29.8519L29.3348 35.7777C30.0916 36.2344 31.0254 35.5567 30.8257 34.6957L28.236 23.5265C28.1523 23.1657 28.275 22.7882 28.5547 22.5454L37.2157 15.0286ZM19.517 26.1127C19.1991 25.9204 18.8008 25.9203 18.4827 26.1123L13.5102 29.1143C12.7527 29.5716 11.8182 28.8923 12.0195 28.0307L13.3427 22.3675C13.4272 22.0062 13.3045 21.6278 13.0242 21.3846L8.62395 17.5675C7.95667 16.9887 8.31274 15.8922 9.19279 15.8159L14.9994 15.3121C15.3688 15.2801 15.6902 15.0462 15.8343 14.7045L18.0802 9.38051C18.4238 8.56594 19.5779 8.56524 19.9225 9.37939L22.1853 14.7256C22.3297 15.0667 22.6508 15.3 23.0198 15.332L28.8272 15.8359C29.7073 15.9122 30.0633 17.0087 29.396 17.5875L24.9958 21.4046C24.7155 21.6478 24.5928 22.0262 24.6773 22.3875L25.9999 28.0483C26.2013 28.9102 25.266 29.5895 24.5086 29.1315L19.517 26.1127Z"
                        fill="#B08C3D"
                    />
                </svg>
            )}
        </>
    );
};

export const StartImage3 = (props) => {
    return (
        <>
            {props?.fill ? (
                <svg
                    {...props}
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.4833 29.8519C18.8011 29.6601 19.1989 29.6601 19.5167 29.8519L29.331 35.7754C30.0883 36.2325 31.0225 35.5535 30.8216 34.692L28.2171 23.5278C28.1328 23.1663 28.2556 22.7878 28.5362 22.5448L37.2086 15.0319C37.8772 14.4527 37.5198 13.3544 36.6384 13.2796L25.2209 12.311C24.8513 12.2796 24.5294 12.0463 24.3847 11.7048L19.9207 1.17237C19.5761 0.359343 18.4239 0.359342 18.0793 1.17237L13.6153 11.7048C13.4706 12.0463 13.1487 12.2796 12.7791 12.311L1.36163 13.2796C0.480202 13.3544 0.122796 14.4527 0.791394 15.0319L9.46379 22.5448C9.74436 22.7878 9.86721 23.1663 9.78287 23.5278L7.17841 34.692C6.97745 35.5535 7.91168 36.2325 8.669 35.7754L18.4833 29.8519Z"
                        fill="#B08C3D"
                    />
                </svg>
            ) : (
                <svg
                    {...props}
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M37.2157 15.0286C37.883 14.4495 37.5264 13.3529 36.6462 13.277L25.2195 12.2917C24.8506 12.2599 24.5295 12.0269 24.3849 11.6861L19.9212 1.17023C19.5763 0.35763 18.4245 0.357942 18.08 1.17073L13.6153 11.7048C13.4706 12.0463 13.1487 12.2796 12.7791 12.311L1.36163 13.2796C0.480202 13.3544 0.122796 14.4527 0.791394 15.0319L9.46379 22.5448C9.74436 22.7878 9.86721 23.1663 9.78287 23.5278L7.17841 34.692C6.97745 35.5535 7.91168 36.2325 8.669 35.7754L18.4833 29.8519C18.8011 29.6601 19.1989 29.6601 19.5167 29.8519L29.3348 35.7777C30.0916 36.2344 31.0254 35.5567 30.8257 34.6957L28.236 23.5265C28.1523 23.1657 28.275 22.7882 28.5547 22.5454L37.2157 15.0286ZM19.517 26.1127C19.1991 25.9204 18.8008 25.9203 18.4827 26.1123L13.5102 29.1143C12.7527 29.5716 11.8182 28.8923 12.0195 28.0307L13.3427 22.3675C13.4272 22.0062 13.3045 21.6278 13.0242 21.3846L8.62395 17.5675C7.95667 16.9887 8.31274 15.8922 9.19279 15.8159L14.9994 15.3121C15.3688 15.2801 15.6902 15.0462 15.8343 14.7045L18.0802 9.38051C18.4238 8.56594 19.5779 8.56524 19.9225 9.37939L22.1853 14.7256C22.3297 15.0667 22.6508 15.3 23.0198 15.332L28.8272 15.8359C29.7073 15.9122 30.0633 17.0087 29.396 17.5875L24.9958 21.4046C24.7155 21.6478 24.5928 22.0262 24.6773 22.3875L25.9999 28.0483C26.2013 28.9102 25.266 29.5895 24.5086 29.1315L19.517 26.1127Z"
                        fill="#B08C3D"
                    />
                </svg>
            )}
        </>
    );
};

export const StartImage4 = (props) => {
    return (
        <>
            {props?.fill ? (
                <svg
                    {...props}
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.4833 29.8519C18.8011 29.6601 19.1989 29.6601 19.5167 29.8519L29.331 35.7754C30.0883 36.2325 31.0225 35.5535 30.8216 34.692L28.2171 23.5278C28.1328 23.1663 28.2556 22.7878 28.5362 22.5448L37.2086 15.0319C37.8772 14.4527 37.5198 13.3544 36.6384 13.2796L25.2209 12.311C24.8513 12.2796 24.5294 12.0463 24.3847 11.7048L19.9207 1.17237C19.5761 0.359343 18.4239 0.359342 18.0793 1.17237L13.6153 11.7048C13.4706 12.0463 13.1487 12.2796 12.7791 12.311L1.36163 13.2796C0.480202 13.3544 0.122796 14.4527 0.791394 15.0319L9.46379 22.5448C9.74436 22.7878 9.86721 23.1663 9.78287 23.5278L7.17841 34.692C6.97745 35.5535 7.91168 36.2325 8.669 35.7754L18.4833 29.8519Z"
                        fill="#B08C3D"
                    />
                </svg>
            ) : (
                <svg
                    {...props}
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M37.2157 15.0286C37.883 14.4495 37.5264 13.3529 36.6462 13.277L25.2195 12.2917C24.8506 12.2599 24.5295 12.0269 24.3849 11.6861L19.9212 1.17023C19.5763 0.35763 18.4245 0.357942 18.08 1.17073L13.6153 11.7048C13.4706 12.0463 13.1487 12.2796 12.7791 12.311L1.36163 13.2796C0.480202 13.3544 0.122796 14.4527 0.791394 15.0319L9.46379 22.5448C9.74436 22.7878 9.86721 23.1663 9.78287 23.5278L7.17841 34.692C6.97745 35.5535 7.91168 36.2325 8.669 35.7754L18.4833 29.8519C18.8011 29.6601 19.1989 29.6601 19.5167 29.8519L29.3348 35.7777C30.0916 36.2344 31.0254 35.5567 30.8257 34.6957L28.236 23.5265C28.1523 23.1657 28.275 22.7882 28.5547 22.5454L37.2157 15.0286ZM19.517 26.1127C19.1991 25.9204 18.8008 25.9203 18.4827 26.1123L13.5102 29.1143C12.7527 29.5716 11.8182 28.8923 12.0195 28.0307L13.3427 22.3675C13.4272 22.0062 13.3045 21.6278 13.0242 21.3846L8.62395 17.5675C7.95667 16.9887 8.31274 15.8922 9.19279 15.8159L14.9994 15.3121C15.3688 15.2801 15.6902 15.0462 15.8343 14.7045L18.0802 9.38051C18.4238 8.56594 19.5779 8.56524 19.9225 9.37939L22.1853 14.7256C22.3297 15.0667 22.6508 15.3 23.0198 15.332L28.8272 15.8359C29.7073 15.9122 30.0633 17.0087 29.396 17.5875L24.9958 21.4046C24.7155 21.6478 24.5928 22.0262 24.6773 22.3875L25.9999 28.0483C26.2013 28.9102 25.266 29.5895 24.5086 29.1315L19.517 26.1127Z"
                        fill="#B08C3D"
                    />
                </svg>
            )}
        </>
    );
};

export const StartImage5 = (props) => {
    return (
        <>
            {props?.fill ? (
                <svg
                    {...props}
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.4833 29.8519C18.8011 29.6601 19.1989 29.6601 19.5167 29.8519L29.331 35.7754C30.0883 36.2325 31.0225 35.5535 30.8216 34.692L28.2171 23.5278C28.1328 23.1663 28.2556 22.7878 28.5362 22.5448L37.2086 15.0319C37.8772 14.4527 37.5198 13.3544 36.6384 13.2796L25.2209 12.311C24.8513 12.2796 24.5294 12.0463 24.3847 11.7048L19.9207 1.17237C19.5761 0.359343 18.4239 0.359342 18.0793 1.17237L13.6153 11.7048C13.4706 12.0463 13.1487 12.2796 12.7791 12.311L1.36163 13.2796C0.480202 13.3544 0.122796 14.4527 0.791394 15.0319L9.46379 22.5448C9.74436 22.7878 9.86721 23.1663 9.78287 23.5278L7.17841 34.692C6.97745 35.5535 7.91168 36.2325 8.669 35.7754L18.4833 29.8519Z"
                        fill="#B08C3D"
                    />
                </svg>
            ) : (
                <svg
                    {...props}
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M37.2157 15.0286C37.883 14.4495 37.5264 13.3529 36.6462 13.277L25.2195 12.2917C24.8506 12.2599 24.5295 12.0269 24.3849 11.6861L19.9212 1.17023C19.5763 0.35763 18.4245 0.357942 18.08 1.17073L13.6153 11.7048C13.4706 12.0463 13.1487 12.2796 12.7791 12.311L1.36163 13.2796C0.480202 13.3544 0.122796 14.4527 0.791394 15.0319L9.46379 22.5448C9.74436 22.7878 9.86721 23.1663 9.78287 23.5278L7.17841 34.692C6.97745 35.5535 7.91168 36.2325 8.669 35.7754L18.4833 29.8519C18.8011 29.6601 19.1989 29.6601 19.5167 29.8519L29.3348 35.7777C30.0916 36.2344 31.0254 35.5567 30.8257 34.6957L28.236 23.5265C28.1523 23.1657 28.275 22.7882 28.5547 22.5454L37.2157 15.0286ZM19.517 26.1127C19.1991 25.9204 18.8008 25.9203 18.4827 26.1123L13.5102 29.1143C12.7527 29.5716 11.8182 28.8923 12.0195 28.0307L13.3427 22.3675C13.4272 22.0062 13.3045 21.6278 13.0242 21.3846L8.62395 17.5675C7.95667 16.9887 8.31274 15.8922 9.19279 15.8159L14.9994 15.3121C15.3688 15.2801 15.6902 15.0462 15.8343 14.7045L18.0802 9.38051C18.4238 8.56594 19.5779 8.56524 19.9225 9.37939L22.1853 14.7256C22.3297 15.0667 22.6508 15.3 23.0198 15.332L28.8272 15.8359C29.7073 15.9122 30.0633 17.0087 29.396 17.5875L24.9958 21.4046C24.7155 21.6478 24.5928 22.0262 24.6773 22.3875L25.9999 28.0483C26.2013 28.9102 25.266 29.5895 24.5086 29.1315L19.517 26.1127Z"
                        fill="#B08C3D"
                    />
                </svg>
            )}
        </>
    );
};

export const WhiteStart = (props) => {
    return (
        <>
            {props?.fill ? (
                <svg
                    {...props}
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.4833 29.8519C18.8011 29.6601 19.1989 29.6601 19.5167 29.8519L29.331 35.7754C30.0883 36.2325 31.0225 35.5535 30.8216 34.692L28.2171 23.5278C28.1328 23.1663 28.2556 22.7878 28.5362 22.5448L37.2086 15.0319C37.8772 14.4527 37.5198 13.3544 36.6384 13.2796L25.2209 12.311C24.8513 12.2796 24.5294 12.0463 24.3847 11.7048L19.9207 1.17237C19.5761 0.359343 18.4239 0.359342 18.0793 1.17237L13.6153 11.7048C13.4706 12.0463 13.1487 12.2796 12.7791 12.311L1.36163 13.2796C0.480202 13.3544 0.122796 14.4527 0.791394 15.0319L9.46379 22.5448C9.74436 22.7878 9.86721 23.1663 9.78287 23.5278L7.17841 34.692C6.97745 35.5535 7.91168 36.2325 8.669 35.7754L18.4833 29.8519Z"
                        fill="#FFF"
                    />
                </svg>
            ) : (
                <svg
                    {...props}
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M37.2157 15.0286C37.883 14.4495 37.5264 13.3529 36.6462 13.277L25.2195 12.2917C24.8506 12.2599 24.5295 12.0269 24.3849 11.6861L19.9212 1.17023C19.5763 0.35763 18.4245 0.357942 18.08 1.17073L13.6153 11.7048C13.4706 12.0463 13.1487 12.2796 12.7791 12.311L1.36163 13.2796C0.480202 13.3544 0.122796 14.4527 0.791394 15.0319L9.46379 22.5448C9.74436 22.7878 9.86721 23.1663 9.78287 23.5278L7.17841 34.692C6.97745 35.5535 7.91168 36.2325 8.669 35.7754L18.4833 29.8519C18.8011 29.6601 19.1989 29.6601 19.5167 29.8519L29.3348 35.7777C30.0916 36.2344 31.0254 35.5567 30.8257 34.6957L28.236 23.5265C28.1523 23.1657 28.275 22.7882 28.5547 22.5454L37.2157 15.0286ZM19.517 26.1127C19.1991 25.9204 18.8008 25.9203 18.4827 26.1123L13.5102 29.1143C12.7527 29.5716 11.8182 28.8923 12.0195 28.0307L13.3427 22.3675C13.4272 22.0062 13.3045 21.6278 13.0242 21.3846L8.62395 17.5675C7.95667 16.9887 8.31274 15.8922 9.19279 15.8159L14.9994 15.3121C15.3688 15.2801 15.6902 15.0462 15.8343 14.7045L18.0802 9.38051C18.4238 8.56594 19.5779 8.56524 19.9225 9.37939L22.1853 14.7256C22.3297 15.0667 22.6508 15.3 23.0198 15.332L28.8272 15.8359C29.7073 15.9122 30.0633 17.0087 29.396 17.5875L24.9958 21.4046C24.7155 21.6478 24.5928 22.0262 24.6773 22.3875L25.9999 28.0483C26.2013 28.9102 25.266 29.5895 24.5086 29.1315L19.517 26.1127Z"
                        fill="#fff"
                    />
                </svg>
            )}
        </>
    );
};
