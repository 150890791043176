import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReadMoreWrapper } from './ReadMore.style';

const ReadMore = (props) => {
    let { children, isFirstTwoWordBold, max, callback } = props;
    const [isReadMore, setIsReadMore] = useState(true);
    const [firstTwoWords, setFirstTwoWords] = useState();
    const [text] = useState(children.replace(/<[^>]+>/g, ''));

    useEffect(() => {
        if (!isFirstTwoWordBold) return;

        let splitedWords = text.split(' ');
        if (splitedWords?.length > 1) {
            setFirstTwoWords(splitedWords[0] + ' ' + splitedWords[1]);
            // splitedWords = splitedWords.splice(2);
            // setText(splitedWords.join(' '));
        }
    }, [isFirstTwoWordBold, text]);

    const toggleReadMore = () => {
        callback && callback();
        setIsReadMore(!isReadMore);
    };

    const handleIsReadMoreTrue = () => {
        let splitedWords = text.split(' ');
        if (firstTwoWords) {
            return splitedWords.slice(2, max - 2).join(' ') + '... ';
        } else {
            return splitedWords.slice(0, max).join(' ') + '... ';
        }
    };

    const handleIsReadMoreFalse = () => {
        let splitedWords = text.split(' ');
        if (firstTwoWords) {
            return splitedWords.slice(2).join(' ');
        } else {
            return text;
        }
    };

    return (
        <ReadMoreWrapper>
            <Typography className="text">
                {firstTwoWords && <b>{firstTwoWords + ' '}</b>}
                {isReadMore ? handleIsReadMoreTrue() : handleIsReadMoreFalse()}
                <span onClick={toggleReadMore} className="read-hide-text">
                    {isReadMore ? 'read more »' : ' show less'}
                </span>
            </Typography>
        </ReadMoreWrapper>
    );
};

export default ReadMore;
