import styled from 'styled-components';
import { responsive, COLORS } from 'Styles/Constants';

export const UserChoicesWrapper = styled.div`
    position: absolute;
    padding: 0 0 20px 0;

    ${responsive.TABLET`
        max-width: 100%;
    `}

    .message-container {
        position: relative;
        padding: 60px 0;

        ${responsive.TABLET`
            padding: 150px 0 15px;
        `}

        ${responsive.PHABLET`
            padding: 120px 0 15px;
        `}

        .box-root {
            max-width: 350px;
            width: 90%;

            ${responsive.TABLET`
                max-width: 260px;
            `}

            ${responsive.PHABLET`
                max-width: 100%;
            `}

            ${responsive.MOBILE`
                width: 100%;
            `}

            .input-root {
                font-size: 24px;
                color: ${COLORS.PRIMARY_WARM};

                ${responsive.MOBILE`
                    font-size: 20px;
                `}
            }
        }

        .lottie-animation {
            position: absolute;
            transform: translate(70%, -30%);
            right: 0;
            z-index: 10;

            ${responsive.TABLET`
                left:0;
                transform: translate(-8%,-70%);
            `}

            ${responsive.PHABLET`
                transform: translate(-10%,-70%);
            `}

            /* FORCE UPDATE FOR MASCOT LOTTIE FILE */
            svg {
                width: 400px !important;
                height: 400px !important;

                ${responsive.TABLET`
                    width: 280px !important;
                    height: 280px !important;
                `}

                ${responsive.PHABLET`
                    width: 220px !important;
                    height: 220px !important;
                `}
            }
        }

        .message-txt {
            font-size: 24px;
            margin: auto;
            margin-left: 0;
            ${responsive.PHABLET`
                font-size: 18px;
            `}
        }

        .achievement-title-2 {
            ${responsive.PHABLET`
                margin-bottom: 5%;
            `}
        }

        .goals-doll {
            ${responsive.DISPLAY`
                height: 130px;
            `}
            ${responsive.PHABLET`
                width: 116px;
                height: 120px;
            `}
        }

        .doll {
            margin: 0 0 0 20px;
            ${responsive.DISPLAY`
                height: 130px;
            `}
            ${responsive.PHABLET`
                width: 132px;
                height: 112px;
            `}
        }

        ${responsive.PHABLET`
            margin-bottom: 5%;
        `}
    }

    .goals {
        display: flex;
        flex-wrap: wrap;
        max-height: calc(100vh - 550px);
        min-height: 100px;
        overflow-y: auto;
        position: relative;
        z-index: 20;

        ${responsive.PHABLET`
            max-height: calc(100vh - 500px);          
        `}

        &.large-container {
            width: 400px;

            ${responsive.PHABLET`
                width: 100%;
            `}
        }

        &.max-width-container {
            max-width: 384px;
        }

        .goal-chip {
            padding: 10px 20px;
            margin: 3px 5px;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            height: 46px;
            border-radius: 26px;
            text-transform: capitalize;
            border: 2px solid transparent;
            &.non-selected-goal {
                border: 2px solid #c3a157;
                background-color: rgba(255, 255, 255, 0.6);
                /* color: #656565; */
                &:hover {
                    background-color: transparent;
                }
            }

            &.selected-goal {
                color: white;
            }

            ${responsive.DISPLAY`
                font-size: 16px;
            `}
        }

        .large {
            width: 370px;
        }
    }
`;
