import styled from 'styled-components';
import { Box, TextField } from '@mui/material';
import { responsive, FONTS, COLORS } from 'Styles/Constants';

export const SearchSectionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    .search-section {
        display: flex;
        flex-direction: column;
        width: ${(props) => (props.$searched ? '950px' : '560px')};
        margin: 100px 0 60px;
        gap: 20px;

        ${responsive.LAPTOP`
            width: 70%;
        `}

        ${responsive.TABLET`
            width: 90%;
        `}

        ${responsive.MOBILE`
            width: 100%;
        `}

        .search-box-section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 15px;

            ${responsive.TABLET`
                flex-direction: column;
                justify-content: start;
                align-items: start;
                padding: 0 20px;
            `}

            .search-title {
                font-family: ${FONTS.SECONDARY_BOLD};
                font-weight: 700;
                font-size: 40px;
                line-height: 40px;
                letter-spacing: -0.02em;
                text-transform: capitalize;
                color: ${COLORS.PRIMARY_DARK};
            }

            .search-input-button {
                height: 100%;
                width: 100%;
                gap: 15px;

                .search-go-button {
                    border-radius: 27px;
                    height: 100%;
                    width: 100px;

                    ${responsive.TABLET`
                        width: 50px;
                    `}
                }
            }
        }

        .popular-search-section {
            display: flex;
            flex-direction: column;
            gap: 5px;

            ${responsive.TABLET`
                width: 100%;
                padding: 0 20px;
            `}

            .popular-search-title {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 20px;
                line-height: 29px;
                color: ${COLORS.MEDIUM_GREY};
            }

            .popular-searches-list {
                height: 50px;

                ${responsive.TABLET`
                    width: 100%;
                    max-width: 100%;
                `}

                .popular-searches-chip {
                    margin: 0 10px 0 0;
                    padding: 0 0 0 10px;
                    border: 1px solid ${COLORS.TEXT_DARK};

                    .chip-label {
                        font-family: ${FONTS.PRIMARY};
                        font-size: 15px;
                        color: ${COLORS.TEXT_DARK};
                        padding-left: 8px;
                    }
                }
            }
        }

        .search-by-category-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            width: 65%;
            margin: 0 auto;

            ${responsive.TABLET`
                width: 80%;
                padding: 0 20px
            `}

            ${responsive.PHABLET`
                width: 100%;
            `}

            .search-by-category-details {
                width: 100%;

                .you-can-also-text {
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 29px;
                    color: ${COLORS.MEDIUM_GREY};
                }

                .search-by-category-text {
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    font-size: 28px;
                    text-transform: capitalize;
                    color: ${COLORS.PRIMARY_DARK};

                    ${responsive.TABLET`
                        font-size: 24px;
                    `}
                }

                .wellness-list {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: start;
                    flex-wrap: wrap;

                    &.decrease-opacity {
                        opacity: 0.5;
                        pointer-events: none;
                    }

                    .wellness-content {
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        align-items: center;
                        height: auto;
                        width: 33%;
                        text-align: center;
                        margin-bottom: 30px;

                        .wellness-icon-button {
                            margin-bottom: 10px;

                            .wellness-icon {
                                height: 70px;
                                width: 70px;

                                ${responsive.DISPLAY`
                                    height: 60px;
                                    width: 60px;
                                `}
                            }

                            .wellness-ribbon-icon {
                                position: absolute;
                                top: 14%;
                                width: 40%;
                                height: 40%;
                                left: 10%;
                                z-index: 10;
                            }

                            /* &:hover {
                                transform: scale(1.2);
                                background: none;
                            } */

                            &:active {
                                background: none;
                            }
                        }

                        .wellness-icon-button:hover > .wellness-icon {
                            transition: all 0.7s ease-in-out;
                            transform: scale(1.2);

                            ${responsive.DISPLAY`
                                transform: scale(1.2);
                            `}
                        }

                        .wellness-icon-button:hover > .wellness-ribbon-icon {
                            transition: all 0.7s ease-in-out;
                            transform: scale(1.2);

                            ${responsive.DISPLAY`
                                transform: scale(1.2);
                            `}
                        }

                        .wellness-category-name {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 18px;
                            text-align: center;
                            color: ${COLORS.MEDIUM_GREY};

                            ${responsive.TABLET`
                                font-size: 16px;
                            `}
                        }

                        .wellness-icon {
                            height: 80px;
                            width: 80px;
                        }
                    }
                }
            }
        }

        .result-count {
            font-family: ${FONTS.PRIMARY};
            font-weight: 500;
            font-size: 24px;
            line-height: 35px;
            text-transform: capitalize;
            color: ${COLORS.MEDIUM_GREY};

            ${responsive.TABLET`
                margin-left: 20px;
            `}
        }

        .meditation-videos-title,
        .live-session-title,
        .meditation-rooms-title {
            font-family: ${FONTS.PRIMARY};
            font-weight: 600;
            font-size: 24px;
            line-height: 35px;
            text-transform: capitalize;
            color: ${COLORS.PRIMARY_DARK};
        }

        .meditation-content {
            display: flex;
            flex-direction: column;
            gap: 10px;

            &.program-section {
                margin: 20px 0;
            }

            .meditation-videos-title-section {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-left: 0;

                ${responsive.PHABLET`
                    width: 90%;
                    margin-left: 20px;
                `}

                .meditation-content-filter {
                    .MuiInputLabel-root {
                        margin-left: 40px;
                    }

                    .MuiFilledInput-root {
                        border: 1px solid ${COLORS.LIGHT_GREY};
                        flex-direction: row-reverse;
                        background-color: white;
                        border-radius: 30px;

                        .MuiSelect-select {
                            width: 100px;

                            ${responsive.TABLET`
                                width: 70px;
                            `}
                        }

                        svg {
                            margin-left: 20px;
                            cursor: pointer;
                        }
                    }

                    .filter-select {
                        .MuiSelect-select {
                            padding: 15px 10px;
                            background: none;
                            &:focus,
                            &:active {
                                background: none;
                            }
                        }
                    }
                }
            }

            .meditation-videos-content-details {
                .no-record-para {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 500;
                    font-size: 21px;
                    line-height: 27px;
                    color: ${COLORS.PRIMARY_WARM};
                    text-transform: capitalize;
                }

                .slider-content {
                    position: relative;
                    margin-bottom: 25px;
                }

                /* .slick-slider {
                    ${responsive.PHABLET`
                        margin-left: 15px;
                    `}
                } */

                /* .slick-slide {
                    display: none;
                    float: left;
                    height: 100%;
                    min-height: 1px;
                    width: 180px !important;
                }

                .slick-initialized .slick-slide {
                    display: block;
                }

                .slick-track {
                    width: 1150px !important;

                    ${responsive.DISPLAY`
                        width: 1935px !important;
                    `}
                } */

                .meditation-videos {
                    display: flex;
                    flex-direction: column;

                    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
                    border-radius: 12px;
                    margin: 5px;
                    position: relative;

                    width: 165px;
                    height: 240px;

                    overflow: hidden;

                    .meditation-video-image {
                        border-radius: 12px 12px 0px 0px;
                        height: 40%;
                    }

                    .play-icon {
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        left: 60px;
                        top: 25px;
                    }

                    .video-description-details {
                        padding: 12px;

                        .meditation-video-title {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                            color: ${COLORS.PRIMARY_WARM};
                        }

                        .video-details {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            gap: 5px;

                            img {
                                width: 20px;
                                height: 20px;
                            }

                            .MuiTypography-root {
                                font-family: ${FONTS.PRIMARY};
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 18px;
                                color: ${COLORS.PRIMARY_DARK};
                            }
                        }

                        .meditation-video-description {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            padding: 5px;
                            color: ${COLORS.MEDIUM_GREY};
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }

                    .get-text {
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        width: 43px;
                        height: 25px;
                        background: ${COLORS.PRIMARY};
                        border-radius: 10px 0px 5px 0px;

                        font-family: ${FONTS.PRIMARY};
                        font-weight: 500;
                        font-size: 12.0969px;
                        line-height: 18px;
                        color: #ffffff;
                    }

                    .added-to-fav-heart-image {
                        position: absolute;
                        right: 10px;
                        top: 15px;
                    }
                }
            }
        }

        .live-session-content {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .live-session-title {
                width: 100%;
                margin-left: 0;

                ${responsive.PHABLET`
                    width: 90%;
                    margin-left: 20px;
                `}
            }

            .live-session-content-details {
                .slider-content {
                    position: relative;
                    margin-bottom: 25px;
                }

                .slick-slider {
                    ${responsive.PHABLET`
                        margin-left: 15px;
                    `}
                }

                .slick-slide {
                    display: none;
                    float: left;
                    height: 100%;
                    min-height: 1px;
                    width: 160px !important;
                }

                .slick-initialized .slick-slide {
                    display: block;
                }

                .slick-track {
                    width: 1150px !important;

                    ${responsive.DISPLAY`
                        width: 1935px !important;
                    `}
                }
            }
        }

        .meditation-rooms-content {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            margin-bottom: 50px;
            gap: 10px;

            .meditation-rooms-title-section {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-left: 0;

                ${responsive.PHABLET`
                    width: 90%;
                    margin-left: 20px;
                `}

                .meditation-rooms-filter {
                    .MuiInputLabel-root {
                        margin-left: 40px;
                    }

                    .MuiFilledInput-root {
                        border: 1px solid ${COLORS.LIGHT_GREY};
                        flex-direction: row-reverse;
                        background-color: white;
                        border-radius: 30px;

                        .MuiSelect-select {
                            width: 100px;

                            ${responsive.TABLET`
                                width: 70px;
                            `}
                        }

                        svg {
                            margin-left: 20px;
                            cursor: pointer;
                        }
                    }

                    .filter-select {
                        .MuiSelect-select {
                            padding: 15px 10px;
                            background: none;
                            &:focus,
                            &:active {
                                background: none;
                            }
                        }
                    }
                }
            }

            .meditation-rooms-content-details {
                .slider-content {
                    position: relative;
                    margin-bottom: 25px;
                }

                .slick-slider {
                    ${responsive.PHABLET`
                        margin-left: 15px;
                    `}
                }

                .slick-slide {
                    display: none;
                    float: left;
                    height: 100%;
                    min-height: 1px;
                    width: 160px !important;
                }

                .slick-initialized .slick-slide {
                    display: block;
                }

                .slick-track {
                    width: 1150px !important;

                    ${responsive.DISPLAY`
                        width: 1935px !important;
                    `}
                }

                .meditation-room {
                    display: flex;
                    flex-direction: column;

                    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
                    border-radius: 12px;
                    margin: 5px;
                    position: relative;

                    .user-image {
                        width: 150px;
                        height: 144px;
                        /* border: 1px solid ${COLORS.LIGHT_GREY}; */
                        border-radius: 12px 12px 0px 0px;
                    }

                    .meditation-room-title {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 18px;
                        color: ${COLORS.PRIMARY_WARM};
                        padding: 5px 10px 0px 10px;
                    }

                    .room-rating {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 0px 10px 10px 10px;

                        svg {
                            width: 18px !important;
                        }

                        .meditation-room-rating {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 17px;
                            text-transform: uppercase;
                            color: ${COLORS.MEDIUM_GREY};
                            margin-left: 5px;
                            margin-top: 2px;
                        }
                    }

                    .added-to-fav-heart-image {
                        position: absolute;
                        right: 10px;
                        top: 15px;
                    }
                }
            }
        }

        .arrow {
            position: absolute;
            color: #000000;
            font-size: 2.5em;
            font-weight: bold;
            cursor: pointer;
            height: 50px;
            width: 50px;
            display: flex !important;
            align-items: center;
            justify-content: center;
        }

        .nextArrow {
            top: 20%;
            right: -7%;
        }

        .prevArrow {
            top: 20%;
            left: -7%;
        }
    }
`;

export const FooterSectionWrapper = styled(Box)`
    footer {
        background-color: #ffffff;
    }
`;

export const CustomSearchInputBox = styled(TextField)`
    width: 100%;

    .start {
        border-radius: 30px;
        background-color: ${COLORS.LIGHT_GREY};
        height: 50px;
        font-size: 20px;
        border: 2px solid transparent;
    }
    .focused {
        border-color: ${COLORS.PRIMARY};
        border-radius: 30px;
    }

    .base-root {
        font-family: ${FONTS.PRIMARY};
        font-weight: 400;
        font-size: 20px;
        color: ${COLORS.TEXT_DARK};
        height: 50px;
    }

    .notchedOutline {
        border: none;
    }
    .search-input-text-icon {
        width: 40px;
        height: 40px;
    }
`;
