export const TIME_MENU = [
    { id: 1, text: '1 min', time: 1 },
    { id: 2, text: '3 min', time: 3 },
    { id: 3, text: '5 min', time: 5 },
    { id: 4, text: '10 min', time: 10 },
    { id: 5, text: '15 min', time: 15 },
    { id: 6, text: '20 min', time: 20 },
    { id: 7, text: '25 min', time: 25 },
    { id: 8, text: '30 min', time: 30 },
    { id: 9, text: '45 min', time: 45 },
    { id: 10, text: '1 hour', time: 60 },
    { id: 11, text: '2 hour', time: 120 },
    { id: 12, text: '4 hour', time: 240 },
    { id: 13, text: '8 hour', time: 480 }
];