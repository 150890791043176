export const contentSubscribedAndAccessible = (content) => {
    return !(
        (content.subscription.isAccessible && content.isPaidContent && !content.isPurchased) ||
        (!content.subscription.isAccessible && content.subscription.badge)
    );
};

export const isSubscribedAndAccessible = (arr) => {
    return arr?.filter((video) => contentSubscribedAndAccessible(video)) || [];
};
