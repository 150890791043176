import { Box } from '@mui/material';
import { COLORS, FONTS } from 'Styles/Constants';
import styled from 'styled-components';
import CrossIcon from 'Assets/images/PlayList/cross-icon.svg';

export const ReferralUsersWrapper = styled(Box)`
    height: 100%;
    .referral-container {
        padding: 0 20px;
        height: 100%;

        .referrals-title {
            font-size: 40px;
            font-family: ${FONTS.SECONDARY_BOLD};
            color: ${COLORS.PRIMARY_DARK};
        }

        .close-icon {
            width: 20px;
            height: 20px;
            background-image: url(${CrossIcon});
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        .count {
            font-size: 22px;
            font-family: ${FONTS.PRIMARY_MEDIUM};

            .number {
                font-size: 30px;
                color: ${COLORS.PRIMARY};
            }
        }

        .referrals-users {
            height: calc(100% - 220px);
            overflow: auto;
            margin: 20px 0 0;

            ::-webkit-scrollbar {
                display: none;
            }

            .users-card-grid {
                display: grid;
                grid-template-columns: auto auto auto auto;
                gap: 20px;

                .card-wrapper {
                    width: auto;
                    .user-wrapper {
                        display: flex;
                        justify-content: center;

                        .user-image {
                            height: 100px;
                            width: 100px;
                            border-radius: 10px;
                            overflow: hidden;
                        }
                    }

                    .user-information-wrapper {
                        display: flex;
                        flex-direction: column;
                        margin: 5px 0 0 0;

                        .user-name {
                            font-family: ${FONTS.PRIMARY};
                            font-size: 12px;
                            color: ${COLORS.BLACK};
                            line-height: 17px;
                            text-align: center;
                        }

                        .user-join-date {
                            font-family: ${FONTS.PRIMARY};
                            font-size: 10px;
                            line-height: 14px;
                            text-align: center;
                            color: ${COLORS.TEXT_DARK};
                        }
                    }
                }
            }
        }

        .invite-friend-share-btn {
            margin: 10px 0;

            .share-btn-container {
                background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                border-radius: 12px;
                height: 60px;
                width: 100%;

                .share-btn-text {
                    text-transform: uppercase;
                    font-size: 16px;
                    font-family: ${FONTS.PRIMARY_MEDIUM};
                    color: ${COLORS.WHITE};
                    margin: 0 0 0 10px;
                }

                .invite-friend-btn-icon {
                    height: 25px;
                    width: 25px;
                }
            }
        }
    }
`;
