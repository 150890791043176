import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { IconButton, Typography, Box, Button, Chip, InputAdornment } from '@mui/material';
import ForwardIcon from 'Assets/images/Common/statistics-right-dark-arrow.svg';
import RefreshIcon from 'Assets/images/Common/refresh-icon.svg';
import CrossIcon from 'Assets/images/PlayList/cross-icon.svg';
import { ReactComponent as SearchIcon } from 'Assets/images/Common/outlined-search-icon.svg';
import TaoScrollbar from 'Components/common/Scrollbar';
import CategorySelection from 'Components/Pages/PlayList/PlaylistManipulationModal/CategorySelection/Category.jsx';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import TopicSelection from 'Components/Pages/PlayList/PlaylistManipulationModal/TopicSelection/Topic.jsx';
import TagSelection from 'Components/Pages/PlayList/PlaylistManipulationModal/TagSelection/Tag.jsx';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { useDispatch } from 'react-redux';
import { getWindowDimensions } from 'Helpers';
import { hideSideBar } from 'Redux/Sidebar/Actions';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { CustomSearchInputBox, SelectionPageWrapper } from './SelectionPage.style';
import { PlayListMediaImg } from '../../Playlist.style';
import { SLIDER_PER_PAGE } from 'Helpers/Constants';
import { SubscriptionLightLock } from 'Components/common/SubscriptionLock';

const SelectionPage = ({
    visible,
    onClose,
    selectedPlaylistDetails,
    setSelectedPlaylistDetails,
    handleNewDataInEdit,
    requestType,
    ...props
}) => {
    let order = 0;
    const [isLoading, setsIsLoading] = useState(false);
    const [page, setPage] = useState(null);
    const [selectedCategoryTag, setSelectedCategoryTag] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState([]);

    const [selectedTopicTag, setSelectedTopicTag] = useState([]);
    const [selectedTopicId, setSelectedTopicId] = useState([]);
    const [tempId, setTempId] = useState([]);

    const [selectedTag, setSelectedTag] = useState([]);
    const [selectedTagId, setSelectedTagId] = useState([]);

    const [recommendedPlaylist, setRecommendedPlaylist] = useState({
        totalRecords: 0,
        list: []
    });

    const [selectedPlaylist, setSelectedPlaylist] = useState([]);
    const [searchMeditation, setSearchMeditation] = useState('');
    const [searchMeditationPagination, setSearchMeditationPagination] = useState({
        current_page: 0,
        per_page: SLIDER_PER_PAGE
    });

    const [debounceFlag, setDebounceFlag] = useState(false);

    const API_OBJ = useMemo(() => new Api(), []);
    const dispatch = useDispatch();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        if (windowDimensions.width < BREAKPOINTS_VALUE.TABLET) {
            dispatch(hideSideBar());
        }
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch, windowDimensions]);

    useEffect(() => {
        setSearchMeditationPagination((prev) => {
            return { ...prev, current_page: 0 };
        });
    }, [selectedTagId, selectedCategoryId, selectedTopicId]);

    useEffect(() => {
        // if (requestType === 'Add') {
        let tempSelectedPlaylistIds = [];
        selectedPlaylistDetails.map((data) => tempSelectedPlaylistIds.push(data.id));
        setSelectedPlaylist(tempSelectedPlaylistIds);
        // }
    }, [selectedPlaylistDetails]);

    const loadMoreData = async () => {
        await setSearchMeditationPagination((prev) => {
            return { ...prev, current_page: prev.current_page + 1 };
        });
        setsIsLoading(true);
        handleMeditationSearch(searchMeditationPagination.current_page + 1);
    };

    const handelSearch = (e) => {
        setDebounceFlag(true);
        setSearchMeditation(e.target.value);
        setSearchMeditationPagination((prev) => {
            return { ...prev, current_page: 0 };
        });
    };

    const handleMeditationSearch = useCallback(
        async (current_page_arg) => {
            setsIsLoading(true);
            const tempCurrenPge = current_page_arg || searchMeditationPagination.current_page;
            let data = {
                categoryId: selectedCategoryId[0],
                subCategoryIds: selectedTopicId,
                tagsId: selectedTagId,
                current_page: tempCurrenPge,
                per_page: searchMeditationPagination.per_page,
                search: searchMeditation
            };
            let response = await API_OBJ.post(API_URL.GET_PLAYLIST_IMAGES, {
                data
            });
            if (response) {
                setRecommendedPlaylist((prev) => {
                    let arr =
                        tempCurrenPge === 0
                            ? response?.data?.data?.list
                            : prev.list.concat(response?.data?.data?.list);
                    return {
                        totalRecords: response?.data?.data?.totalRecords,
                        list: [...new Map(arr.map((item) => [item['id'], item])).values()]
                    };
                });
                setsIsLoading(false);
                setDebounceFlag(false);
            }
        },
        [
            API_OBJ,
            searchMeditation,
            searchMeditationPagination,
            selectedTopicId,
            selectedCategoryId,
            selectedTagId
        ]
    );

    useEffect(() => {
        if (debounceFlag) {
            let timerOut = setTimeout(() => {
                handleMeditationSearch();
            }, 2000);
            return () => clearTimeout(timerOut);
        } else {
            handleMeditationSearch();
        }
    }, [handleMeditationSearch, debounceFlag]);

    const selectPlaylist = (playListId, index) => {
        const newSelectedPlaylist = [...selectedPlaylist];
        if (!newSelectedPlaylist.includes(playListId)) newSelectedPlaylist.push(playListId);
        else {
            let findIndex = newSelectedPlaylist.findIndex((item) => item === playListId);
            findIndex > -1 && newSelectedPlaylist.splice(findIndex, 1);
        }
        setSelectedPlaylist(newSelectedPlaylist);

        let copyRecommendedPlaylist = [...recommendedPlaylist?.list];

        copyRecommendedPlaylist[index].isSelected = !copyRecommendedPlaylist[index].isSelected;

        setRecommendedPlaylist((prev) => ({
            ...prev,
            list: copyRecommendedPlaylist
        }));
    };

    useEffect(() => {}, [selectedTopicTag]);

    const clickHandler = (pageType) => {
        if (pageType === 'tag') {
            let copySelectedCategoryId = [...selectedCategoryId];
            let copySelectedTopicId = [...selectedTopicId];

            let tagRelatedIds = copySelectedCategoryId.concat(copySelectedTopicId);
            tagRelatedIds = tagRelatedIds.flat();

            setTempId(tagRelatedIds);
        }
        setPage(pageType);
    };

    const onSelect = () => {
        const selectedPlaylistCopy = [...selectedPlaylist];
        let selectedPlaylistData = requestType === 'Add' ? [...selectedPlaylistDetails] : [];

        selectedPlaylistCopy.forEach((playlistId) => {
            let foundedData = recommendedPlaylist?.list?.find((value) => value?.id === playlistId);

            if (foundedData) {
                foundedData.order = order + 1;
                selectedPlaylistData.push(foundedData);
                order++;
            }
        });

        setSelectedPlaylistDetails([
            ...new Map(selectedPlaylistData.map((item) => [item['id'], item])).values()
        ]);
        handleNewDataInEdit(selectedPlaylistData);
    };

    const resetDataHandler = () => {
        setSelectedCategoryId([]);
        setSelectedCategoryTag([]);
        setSelectedTopicId([]);
        setSelectedTopicTag([]);
        setSelectedTagId([]);
        setSelectedTag([]);
        setSearchMeditation('');
        setSelectedPlaylist([]);
    };

    useEffect(() => {
        setSelectedTopicTag([]);
        setSelectedTopicId([]);
        setSelectedTagId([]);
        setSelectedTag([]);
    }, [selectedCategoryId]);

    return (
        <>
            <SelectionPageWrapper
                open={visible}
                fullScreen={windowDimensions.width < BREAKPOINTS_VALUE.TABLET ? true : false}
                maxWidth="md"
                BackdropProps={{ classes: { root: 'custom-backdrop' } }}
                PaperProps={{
                    classes: {
                        rounded: `dialog-background ${
                            windowDimensions.width >= BREAKPOINTS_VALUE.TABLET && 'rounded-popup'
                        }`
                    }
                }}
                onClose={onClose}>
                <TaoLoader isLoading={isLoading} />
                {!page && (
                    <>
                        <Box className="top-bar">
                            <IconButton className="refresh-button" onClick={resetDataHandler}>
                                <img className="refresh-icon" src={RefreshIcon} alt="" />
                            </IconButton>
                            <Typography className="heading">Choose A Meditation</Typography>
                            <IconButton className="cross-button" onClick={onClose}>
                                <img className="cross-icon" src={CrossIcon} alt="" />
                            </IconButton>
                        </Box>
                        <TaoScrollbar displayScroll>
                            <Box className="scroll-area flex f-column">
                                <Box className="search-bar">
                                    <Typography className="heading">Search</Typography>
                                    <Box className="search-container">
                                        <CustomSearchInputBox
                                            InputProps={{
                                                classes: {
                                                    root: 'search-root',
                                                    notchedOutline: 'search-notchedOutline'
                                                },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            placeholder="Keywords"
                                            id="search-input"
                                            value={searchMeditation}
                                            onChange={handelSearch}
                                        />
                                    </Box>
                                </Box>
                                <Box className="filter-container">
                                    <Typography className="heading">Filter</Typography>
                                    <Box className="filter-container">
                                        <Box className="category-row">
                                            <Box className="category-section">
                                                <Box
                                                    className={`combination-container-left category f-v-center`}>
                                                    <Typography className="heading">
                                                        Category
                                                    </Typography>
                                                    <Box className="category-tag-container">
                                                        <Box className="chip-scroll">
                                                            {!selectedCategoryTag.length && (
                                                                <Chip
                                                                    className="chip"
                                                                    classes={{
                                                                        label: 'chip-label'
                                                                    }}
                                                                    label={'ALL'}
                                                                />
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box
                                                    className="combination-container"
                                                    onClick={() => clickHandler('category')}>
                                                    <Typography className="text hover">
                                                        choose
                                                    </Typography>
                                                    <IconButton className="arrow-button">
                                                        <img
                                                            className="arrow-icon"
                                                            src={ForwardIcon}
                                                            alt=""
                                                        />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                            <Box className="selected-tag-chip">
                                                {!!selectedCategoryTag.length &&
                                                    selectedCategoryTag.map((value, index) => {
                                                        return (
                                                            <Chip
                                                                key={index}
                                                                className="chip"
                                                                classes={{
                                                                    label: 'chip-label'
                                                                }}
                                                                label={
                                                                    !!selectedCategoryTag?.length
                                                                        ? value?.categoryName
                                                                        : 'ALL'
                                                                }
                                                            />
                                                        );
                                                    })}
                                            </Box>
                                        </Box>
                                        <Box className="category-row">
                                            <Box className="category-section">
                                                <Box className={`combination-container-left`}>
                                                    <Typography className="heading">
                                                        Topic
                                                    </Typography>
                                                    <Box className="category-tag-container">
                                                        <Box className="chip-scroll">
                                                            {!selectedTopicTag.length && (
                                                                <Chip
                                                                    className="chip"
                                                                    classes={{
                                                                        label: 'chip-label'
                                                                    }}
                                                                    label={'ALL'}
                                                                />
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box
                                                    className="combination-container"
                                                    onClick={() => clickHandler('topic')}>
                                                    <Typography className="text hover">
                                                        choose
                                                    </Typography>
                                                    <IconButton className="arrow-button">
                                                        <img
                                                            className="arrow-icon"
                                                            src={ForwardIcon}
                                                            alt=""
                                                        />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                            <Box className="selected-tag-chip">
                                                <TaoScrollbar
                                                    keyId="topic-chip-scroll"
                                                    horizontalScroll>
                                                    {!!selectedTopicTag.length &&
                                                        selectedTopicTag?.map((value, index) => {
                                                            return (
                                                                <Chip
                                                                    key={index}
                                                                    className="chip"
                                                                    classes={{
                                                                        label: 'chip-label'
                                                                    }}
                                                                    label={
                                                                        !!selectedTopicTag?.length
                                                                            ? value?.name
                                                                            : 'ALL'
                                                                    }
                                                                />
                                                            );
                                                        })}
                                                </TaoScrollbar>
                                            </Box>
                                        </Box>
                                        <Box className="category-row">
                                            <Box className="category-section">
                                                <Box
                                                    className={`combination-container-left f-v-center`}>
                                                    <Typography className="heading">
                                                        Tags
                                                    </Typography>
                                                    <Box className="category-tag-container">
                                                        <Box className="chip-scroll">
                                                            {!selectedTag.length && (
                                                                <Chip
                                                                    className="chip"
                                                                    classes={{
                                                                        label: 'chip-label'
                                                                    }}
                                                                    label={'ALL'}
                                                                />
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box
                                                    className="combination-container"
                                                    onClick={() => clickHandler('tag')}>
                                                    <Typography className="text hover">
                                                        choose
                                                    </Typography>
                                                    <IconButton className="arrow-button">
                                                        <img
                                                            className="arrow-icon"
                                                            src={ForwardIcon}
                                                            alt=""
                                                        />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                            <Box className="selected-tag-chip">
                                                <TaoScrollbar
                                                    keyId="tag-chip-scroll"
                                                    horizontalScroll>
                                                    {!!selectedTag?.length &&
                                                        selectedTag.map((value, index) => {
                                                            return (
                                                                <Chip
                                                                    key={index}
                                                                    classes={{
                                                                        label: 'chip-label'
                                                                    }}
                                                                    className="chip"
                                                                    label={value?.name}
                                                                />
                                                            );
                                                        })}
                                                </TaoScrollbar>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="result-container">
                                    <Box className="heading-container">
                                        <Typography className="heading">
                                            {recommendedPlaylist?.totalRecords} results
                                        </Typography>
                                    </Box>
                                    <Box className="image-container">
                                        <Box className="flex playlist">
                                            {recommendedPlaylist?.list?.map((value, index) => (
                                                <Box className="card-box" key={index}>
                                                    <Box
                                                        className="card-container"
                                                        onClick={() => {
                                                            selectPlaylist(value?.id, index);
                                                        }}>
                                                        <Box
                                                            className={`image-test-container flex f-v-center f-h-center ${
                                                                selectedPlaylist.includes(
                                                                    value?.id
                                                                ) && 'selected'
                                                            }`}>
                                                            <PlayListMediaImg
                                                                className="hover"
                                                                img={value?.thumbnailImage}
                                                                height={'100%'}
                                                                width={'100%'}
                                                                radius={'0px'}
                                                            />
                                                            {selectedPlaylist.includes(
                                                                value?.id
                                                            ) && (
                                                                <Box className="selected-box">
                                                                    <Box className="tick-icon" />
                                                                </Box>
                                                            )}
                                                            {value?.subscription?.isAccessible &&
                                                                value?.isPaidContent &&
                                                                !value?.isPurchased && (
                                                                    <Box className="unlock-container get text-uppercase">
                                                                        <Typography className="unlock-text">
                                                                            {'GET'}
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                            {!value?.subscription?.isAccessible && (
                                                                <Box className="unlock-container badge text-uppercase">
                                                                    <SubscriptionLightLock />
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                    <Box className="image-description-container">
                                                        <Typography className="text elipsis">
                                                            {value?.contentName}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                        {recommendedPlaylist?.totalRecords !==
                                            recommendedPlaylist?.list?.length && (
                                            <Box
                                                className="load-more flex f-column f-v-center f-h-center hover"
                                                onClick={() => loadMoreData()}>
                                                <Typography className="load-more-text">
                                                    Load More
                                                </Typography>
                                                <Box className="load-more-arrow" />
                                            </Box>
                                        )}
                                        {!recommendedPlaylist?.list?.length && (
                                            <Box className="no-result-found-wrapper flex f-v-center f-h-center">
                                                <Typography className="no-result-message">{`${
                                                    isLoading
                                                        ? 'Content loading...'
                                                        : 'No Result Found'
                                                }`}</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </TaoScrollbar>
                        <Box className="button-container">
                            <Button
                                variant="contained"
                                className="submit-button"
                                disabled={!selectedPlaylist.length}
                                onClick={() => {
                                    onSelect();
                                    onClose();
                                }}>
                                <Typography className="text">Select</Typography>
                            </Button>
                        </Box>
                    </>
                )}

                {page && page === 'category' && (
                    <CategorySelection
                        setPage={setPage}
                        page={page}
                        setSelectedCategoryTag={setSelectedCategoryTag}
                        selectedCategoryTag={selectedCategoryTag}
                        selectedCategoryId={selectedCategoryId}
                        setSelectedCategoryId={setSelectedCategoryId}
                    />
                )}
                {page && page === 'topic' && (
                    <TopicSelection
                        setPage={setPage}
                        page={page}
                        selectedCategoryTag={selectedCategoryTag}
                        selectedTopicTag={selectedTopicTag}
                        setSelectedTopicTag={setSelectedTopicTag}
                        selectedTopicId={selectedTopicId}
                        setSelectedTopicId={setSelectedTopicId}
                    />
                )}
                {page && page === 'tag' && (
                    <TagSelection
                        setPage={setPage}
                        page={page}
                        selectedCategoryTag={selectedCategoryTag}
                        selectedTopicTag={selectedTopicTag}
                        tagRelatedIds={tempId}
                        selectedTag={selectedTag}
                        setSelectedTag={setSelectedTag}
                        selectedTagId={selectedTagId}
                        setSelectedTagId={setSelectedTagId}
                    />
                )}
            </SelectionPageWrapper>
        </>
    );
};
export default SelectionPage;
