import { Box } from '@mui/material';
import styled from 'styled-components';
import { styled as materialStyled } from '@mui/material/styles';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

const DIALOG_COLOR = '#cacaca66';

export const ProfileSettingSectionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #fff;
    position: relative;
    height: 100%;
    border-radius: 30px 30px 0 0;

    ${responsive.TABLET`
        height: calc(100% - 80px);
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        border-radius: 30px 30px 0px 0px;
        background: #FFFFFF;
    `}

    ${responsive.PHABLET`
        background: none;
        box-shadow: none;
        border-radius: none;
    `}

    .container {
        padding: 100px 20px 0;

        ${responsive.TABLET`
            padding: 20px 20px 0;
        `}
    }

    .sub-title {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: ${COLORS.MEDIUM_GREY};
        text-transform: capitalize;
    }

    .body-text {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 30px;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_WARM};
    }

    .language-font {
        font-size: 18px;
        line-height: 22px;
    }

    .radio-label {
    }

    .before-session {
        text-transform: none;
    }

    .user-email {
        margin: 0 0 13px 0;
        text-transform: none;
        line-height: 26px;
    }

    .rightArrowIcon {
        margin: 0 5px 0 0;
        height: 12px;
        width: 12px;
        transition: all 0.1s;

        &.open-options {
            transform: rotate(90deg);
        }
    }

    .zenMode-options {
        transition: height 0.5s;
        display: flex;
        overflow: hidden;
        height: 60px;

        &.options-closed {
            height: 0;
        }
    }

    .silence-notification {
        line-height: 21px;
        cursor: pointer;
    }

    #open-zen-mode,
    #close-zen-mode {
        transition: all 1s ease-in;
    }

    .zen-notification-options {
        width: 70%;

        ${responsive.MOBILE_376`
            width: 90%;
        `}
    }

    .down-arrow-icon {
        cursor: pointer;
    }

    .silence-notification {
        text-transform: none;
    }

    .bell-icon {
        width: 20px;
        height: 20px;
        margin: 0 5px 0 0;
    }

    .divider {
        height: inherit;
        margin: 0 10px 0 6px;
    }

    .reminder-notification {
        margin: 33px 0 0 0;
    }

    .remainder {
        margin: 16px 0 0 0;
        width: 70%;
        gap: 25px;

        ${responsive.TABLET`
            width: 80%;
        `}

        ${responsive.MOBILE`
            width: 100%;
        `}
    }

    .remainder-selector {
        width: 45%;
    }

    .selector {
        background-color: white;
        border: 1px solid rgba(132, 132, 132, 0.4);
        border-radius: 8px;
        font-size: 18px;
        line-height: 24px;
        width: 100%;

        .filled-select {
            background-color: #fff;
            border-radius: 8px;
        }
        .down-arrow-icon {
            position: absolute;
            cursor: pointer;
            right: 20px;
        }
    }

    .time-format-switch {
        margin: 20px 0 0 0;
        gap: 10px;

        .time-format-switch-label {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: ${COLORS.MEDIUM_GREY};
        }

        .farmer-motion-switch {
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */

            width: 250px;
            height: 35px;
            background: rgba(118, 118, 128, 0.12);
            border-radius: 10px;
            padding: 2px;
            position: relative;
            cursor: pointer;

            &.start {
                justify-content: flex-start;
            }

            &.end {
                justify-content: flex-end;
            }

            .switch-handle {
                width: 50%;
                height: 30px;
                background: ${COLORS.WHITE};
                border: 0.5px solid rgba(0, 0, 0, 0.04);
                box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
                border-radius: 10px;
            }

            .active-hour-text-style,
            .hour-text-style {
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: -0.08px;
            }

            .hour-text-style {
                font-weight: 500;
            }

            .twenty-four {
                position: absolute;
                right: 25%;
                top: 50%;
                transform: translate(50%, -50%);
            }

            .twelve {
                position: absolute;
                left: 25%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .languages-section {
        margin: 50px -20px 0;
        padding: 30px 20px 0 20px;
        background: #f8f8f8;
        border: 0.5px solid ${COLORS.LIGHT_GREY};
        border-radius: 35px 35px 0px 0px;

        ${responsive.TABLET`
            margin: 40px -20px 0;
        `}

        .radio-button-group {
            ${responsive.TABLET`
                display: flex;
                flex-direction: row;
                gap: 10px;
            `}

            .radio-button.Mui-checked span {
                border: 2px solid ${COLORS.PRIMARY_DARK};
            }
        }
    }

    .room-section-lang-section {
        margin: 0 0 50px 0;
        width: 100%;
    }

    .lang-title {
        margin: 0 0 10px 0;
    }

    .lang-divider {
        margin: 0 0 0 -20px;
    }

    .room-session-lang {
        margin: 25px 0 0 0;
        width: 60%;
        text-transform: none;
    }

    .app-details {
        margin: -25px -20px 0;
        padding: 30px 20px 0 20px;
        background: #f2e9d8;
        border: 0.5px solid ${COLORS.PRIMARY};
        border-radius: 35px 35px 0px 0px;
    }

    .version-no {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 30px;
        text-align: right;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_DARK};

        ${responsive.TABLET_860`
            font-size: 19px;
            line-height: 28px;
        `}

        ${responsive.PHABLET`
            font-size: 17px;
            line-height: 26px;
        `}
    }

    .update-button {
        width: 100%;
        background: #fff;
        border: 1px solid #b08c3d;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 18px 42px;
        margin: 10px 0 0 0;
        height: 60px;

        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        text-transform: uppercase;
        &:hover {
            background: #fff;
        }

        ${responsive.TABLET_860`
            height: 55px;
        `}

        ${responsive.PHABLET`
            height: 50px;
        `}
    }

    .tutorial,
    .information {
        margin: 20px 0 0 0;
    }

    .tutorial-button,
    .feedback-button {
        width: 100%;
        background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
        border-radius: 12px;
        padding: 10px 0px;
        margin: 20px 0 0 0;
        color: #fff;
        height: 60px;

        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        text-align: right;
        letter-spacing: 0.05em;

        ${responsive.TABLET_860`
            height: 55px;
        `}

        ${responsive.PHABLET`
            height: 50px;
        `}
    }

    .feedback-button {
        margin: 20px 0;
    }

    .btn-icon {
        ${responsive.TABLET_860`
            height: 22px;
            width: 22px;
        `}

        ${responsive.PHABLET`
            height: 20px;
            width: 20px;
        `}
    }

    .extra-margin {
        margin: 0 0 24px 0;
    }

    .delete-account-button {
        width: 100%;
        background: ${COLORS.BADGE};
        border-radius: 12px;
        padding: 10px 0px;
        margin: 10px 0;
        color: ${COLORS.WHITE};
        height: 60px;
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        font-size: 16px;
        letter-spacing: 0.05em;

        ${responsive.TABLET`
            height: 55px;
        `}

        ${responsive.PHABLET`
            height: 50px;
        `}
    }
    .dialog-container {
        height: 733px;
    }

    .devices-section {
        margin: 0 -20px 0 0;

        .devices-title {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: ${COLORS.MEDIUM_GREY};
            text-transform: capitalize;
        }
        .devices-subtitle {
            margin-top: 18px;
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: ${COLORS.PRIMARY_WARM};
        }
        .devices-list {
            gap: 15px;
            margin: 20px 0 50px 0;

            .whole-devices-card {
                background: ${COLORS.WHITE};
                padding: 15px 30px;
                border-radius: 12px 0 0 12px;

                ${responsive.PHABLET`
                    padding: 15px;
                `}

                .devices-card {
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;

                    .devices-type {
                        width: calc(100% - 60% - 90px);
                        min-width: max-content;

                        .device-name {
                            font-family: ${FONTS.PRIMARY};
                            max-width: 100%;
                            font-size: 16px;
                            line-height: 22px;
                            text-align: center;
                            color: ${COLORS.PRIMARY_WARM};
                        }
                    }

                    .devices-details {
                        width: 60%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .devices-location {
                            font-family: ${FONTS.PRIMARY};
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            color: #000000;
                            white-space: pre-wrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }

                        .this-device {
                            font-family: ${FONTS.PRIMARY};
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 22px;
                            text-transform: capitalize;
                            color: ${COLORS.PRIMARY_DARK};
                        }

                        .devices-last-used {
                            font-family: ${FONTS.PRIMARY};
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            color: ${COLORS.MEDIUM_GREY};

                            &.static {
                                min-width: max-content;
                            }
                        }
                    }

                    .logout {
                        .logout-btn {
                            padding: 16px 14px;
                            gap: 2px;
                            width: 90px;
                            height: 50px;
                            background: #f2f2f2;
                            border-radius: 25px;
                        }

                        .logout-btn-text {
                            font-family: ${FONTS.PRIMARY};
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 18px;
                            text-align: center;
                            text-transform: capitalize;
                            color: ${COLORS.TEXT_DARK};
                        }
                    }
                }
            }

            .devices-note {
                font-family: ${FONTS.PRIMARY};
                font-style: italic;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: ${COLORS.MEDIUM_GREY};
            }
        }
    }
`;

export const PrivacyPolicyWrapper = styled(Box)`
    background: ${DIALOG_COLOR};
    padding: 10px;
    height: 100%;
    width: 100%;
    .policy-title {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_DARK};
    }

    .close-icon {
        float: right;
    }

    .dialog-content {
        height: 90%;
        .dialog-body-text {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 500;
            font-size: 21px;
            line-height: 27px;
            color: ${COLORS.DARK_GREY};
        }
    }
`;

export const DialogWrapper = styled(Box)`
    background: ${DIALOG_COLOR};
    padding: 10px;
    .feedback-title {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_DARK};
    }

    .close-icon {
        float: right;
    }

    .dialog-content {
        gap: 24px;

        .selector {
            background-color: white;
            border: 1px solid rgba(132, 132, 132, 0.4);
            border-radius: 8px;
            font-size: 16px;
            width: 100%;

            .filled-select {
                background-color: transparent;
            }
            .down-arrow-icon {
                position: absolute;
                cursor: pointer;
                right: 20px;
            }
        }

        .text-field {
            width: 100%;
            background-color: #fff;
            border: 1px solid rgba(132, 132, 132, 0.4);
            border-radius: 8px;
            backdrop-filter: blur(30px);
            .filled-input {
                background-color: transparent;
            }
        }

        .submit-btn {
            height: 60px;
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
            border-radius: 12px;
            color: #fff;
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            text-align: right;
            letter-spacing: 0.05em;
            text-transform: uppercase;
        }
    }
`;

export const CustomRadioIcon = materialStyled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 25,
    height: 25,
    border: '2px solid #989898',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2
    }
}));

export const CustomRadioCheckedIcon = materialStyled(CustomRadioIcon)({
    '&:before': {
        display: 'block',
        width: 25,
        height: 25,
        backgroundImage: 'radial-gradient(#9C7F3C 45%, #CAA44D 48%, transparent 52%)',
        content: '""',
        position: 'absolute',
        left: '9px',
        top: '9px'
    }
});
