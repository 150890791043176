import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText
} from '@mui/material';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import CODES from 'Helpers/StatusCodes';
import useTimezoneWatcher from 'Hooks/useTimezoneWatcher';
import React, { useEffect, useMemo, useState } from 'react';

const TimeZoneWatcher = () => {
    const { hasChanged } = useTimezoneWatcher();
    const [showModel, setShowModel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const API = useMemo(() => new Api(), []);

    useEffect(() => {
        if (hasChanged) {
            setShowModel(true);
        }
    }, [hasChanged]);

    const updateTimeZone = async () => {
        try {
            setIsLoading(true);
            const response = await API.put(API_URL.TIMEZONE_CHANGED);
            if (response.status === CODES.SUCCESS) {
                setShowModel(false);
            }
            setIsLoading(false);
            setShowModel(false);
        } catch (error) {
            console.error('Error updating timezone:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Dialog open={showModel}>
                <DialogContent>
                    <DialogContentText>
                        Looks like your device's time zone has changed. Would you like to update the
                        app's time zone settings to keep receiving notifications on time?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowModel(false)}>No, keep current</Button>
                    <Button onClick={updateTimeZone} disabled={isLoading}>
                        {isLoading ? <CircularProgress size={20} /> : 'Yes, update'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TimeZoneWatcher;
