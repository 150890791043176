import { Box } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

const ListWrapper = styled(Box)`
    margin: ${(props) => (props?.$itemsExists ? '20px 0 0' : '0')};
    height: ${(props) =>
        props?.$ContainSubCategory ? 'calc(100% - 300px)' : 'calc(100% - 160px)'};
    overflow: auto;
    display: ${(props) => (props?.$itemsExists ? 'inherit' : 'flex')};
    align-items: ${(props) => (props?.$itemsExists ? 'flex-start' : 'center')};
    justify-content: ${(props) => (props?.$itemsExists ? 'flex-start' : 'center')};

    ${responsive.TABLET`
        height: ${(props) =>
            props?.$ContainSubCategory ? 'calc(100% - 300px)' : 'calc(100% - 160px)'};
    `}

    .scroll-height {
        overflow-x: hidden !important;
    }

    .list-item {
        margin: 20px 0 0;
        display: flex;

        .text-container {
            margin: 0 0 0 10px;
            max-width: calc(100% - 220px);

            ${responsive.PHABLET`
                max-width: calc(100% - 140px);
            `}

            .sub-title {
                font-size: 18px;
                color: ${COLORS.PRIMARY_WARM};
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            }

            .desc {
                font-size: 16px;
                color: ${COLORS.TEXT_DARK};
                width: inherit;
                white-space: pre-wrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;

                ${responsive.PHABLET`
                    -webkit-line-clamp: 2;
                `}
            }
        }
    }

    .placeholder-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;

        .placeholder-text {
            font-family: ${FONTS.PRIMARY};
            font-size: 24px;
            color: ${COLORS.MEDIUM_GREY};
        }
    }
`;

export default ListWrapper;
