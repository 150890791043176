import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useMemo } from 'react';
import { DownloadWrapper, PopOverWrapper } from './Download.style';
import { ReactComponent as DownloadIcon } from 'Assets/images/Downloads/download.svg';
import { ReactComponent as Selected } from 'Assets/images/Downloads/selected.svg';
import { ReactComponent as UnSelected } from 'Assets/images/Downloads/un-selected.svg';
import { ReactComponent as MobileDownloaded } from 'Assets/images/Downloads/mobile-downloaded.svg';
import { ReactComponent as DeleteIcon } from 'Assets/images/Journal/delete.svg';
import Api from 'Helpers/ApiHandler';
import { useEffect } from 'react';
import { API_URL, URL_FAVORITES, URL_MEDITATION_MEDIA_PLAYER, URL_SEARCH } from 'Helpers/Paths';
import { useCallback } from 'react';
import { useState } from 'react';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import TaoScrollbar from 'Components/common/Scrollbar';
import { ReactComponent as PlayButton } from 'Assets/images/Common/play-button.svg';
import { ReactComponent as DownArrowIcon } from 'Assets/images/Common/selected-back-arrow.svg';
import { useNavigate } from 'react-router-dom';
import { getWindowDimensions } from 'Helpers';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { hideTopBar, showTopBar } from 'Redux/App/Actions';
import { useDispatch } from 'react-redux';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';

const CATEGORY_SORT = [{ categoryId: 0, categoryName: 'All Categories' }];

const SORT_BY_API_VALUE = {
    'Most Recent': 'Most Recent',
    'A->Z': 'A-Z'
};
const SORT_BY_VALUE = {
    RATING: 'Most Recent',
    A_Z: 'A->Z'
};

const Downloads = () => {
    const API = useMemo(() => new Api(), []);
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: 7,
        search: '',
        sort: {
            field: 'Most Recent',
            order: 'Asc'
        },
        totalRecords: 0
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [isEditing, setEditingStatus] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(CATEGORY_SORT[0]);
    const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
    const [categories, setCategories] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [sortBy, setSortBy] = useState(SORT_BY_VALUE?.RATING);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const fetchCategories = useCallback(async () => {
        let response = await API.get(API_URL.WELLNESS_CATEGORY);
        setCategories(response?.data?.data?.list);
    }, [API]);

    const fetchDownloadList = useCallback(async () => {
        setLoading(true);
        let response = await API.post(API_URL.DOWNLOADS, {
            params: {
                categoryId: selectedCategory?.categoryId
            },
            data: {
                current_page: pagination?.current_page - 1,
                per_page: pagination?.per_page,
                search: pagination?.search,
                sort: pagination?.sort
            }
        });
        if (response) {
            if (
                selectedCategory.categoryId === CATEGORY_SORT[0].categoryId &&
                !response?.data?.data?.list?.length
            ) {
                setCategories([]);
            }
            setData((prev) => {
                let arr =
                    pagination?.current_page === 1
                        ? response?.data?.data?.list || []
                        : prev.concat(response?.data?.data?.list || []);
                return [...new Map(arr.map((item) => [item['contentId'], item])).values()];
            });
            setLoading(false);
            setPagination((prev) => ({
                ...prev,
                totalRecords: response?.data?.data?.totalRecords || 0
            }));
        }
    }, [
        API,
        pagination?.current_page,
        pagination?.per_page,
        pagination?.search,
        pagination?.sort,
        selectedCategory?.categoryId
    ]);

    // DYNAMIC CATEGORIES & SUBCATEGORIES LIST
    const prepareCategory = (items) => {
        const parents = [...new Set(items?.map((item) => item?.parentCategory))];
        let text = '';
        parents.forEach((parent) => {
            let allSubCategory = items
                .filter((item) => item?.parentCategory === parent)
                .map((item) => item?.subCategory);
            let subcategoryText = '';
            allSubCategory.forEach((ele, index) => {
                subcategoryText += ele + `${allSubCategory?.length - 1 === index ? '' : ', '}`;
            });
            text += parent + '>' + subcategoryText;
        });

        return text;
    };

    const loadMoreData = () => {
        setPagination((prev) => ({ ...prev, current_page: prev?.current_page + 1 }));
    };

    const handleClose = () => {
        setCategoryAnchorEl(null);
        setAnchorEl(null);
    };

    const deleteSelectedDownloads = async () => {
        setLoading(true);
        let response = await API.delete(API_URL.DOWNLOADS, {
            data: {
                contentIds: selectedIds
            }
        });
        if (response) {
            setEditingStatus(false);
            setSelectedIds([]);
            fetchDownloadList();
            return;
        }
        setLoading(false);
    };

    useEffect(() => {
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            dispatch(hideTopBar());
        } else {
            dispatch(showTopBar());
        }
    }, [dispatch, windowDimensions.width]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            dispatch(showTopBar());
            dispatch(showSideBar());
        };
    }, [dispatch]);

    useEffect(() => {
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET && isEditing) {
            dispatch(hideSideBar());
        } else {
            dispatch(showSideBar());
        }
    }, [isEditing, dispatch, windowDimensions.width]);

    useEffect(() => {
        fetchDownloadList();
    }, [fetchDownloadList]);

    useEffect(() => {
        if (!categories.length && pagination?.totalRecords) fetchCategories();
    }, [categories?.length, fetchCategories, pagination?.totalRecords]);

    const categoryOpen = Boolean(categoryAnchorEl);
    const categoryId = categoryOpen ? 'simple-popover' : undefined;

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <TaoCalligraphyWatermark />
            <DownloadWrapper className="main-container flex f-v-center f-h-center">
                <TaoLoader isLoading={loading} />
                <Box className="parent-box">
                    <Typography className="title">Downloads</Typography>
                    <Box className="card flex f-h-center">
                        {!data?.length && !categories?.length ? (
                            <Box className="container flex f-column f-v-center">
                                <DownloadIcon className="download-icon" />
                                <Typography className="sub-title">You have no downloads</Typography>

                                <Button className="btn" onClick={() => navigate(URL_FAVORITES)}>
                                    Download your favorites
                                </Button>
                                <Button className="btn" onClick={() => navigate(URL_SEARCH)}>
                                    find something new
                                </Button>
                            </Box>
                        ) : (
                            <Box className="data-container flex f-column f-v-center">
                                <Box className="header flex f-v-center">
                                    <Typography
                                        className="event-text hover"
                                        onClick={() => setEditingStatus(!isEditing)}>
                                        {isEditing ? 'Done' : 'Edit'}
                                    </Typography>

                                    <Box className="category-selection">
                                        <Button
                                            aria-describedby={id}
                                            variant="outlined"
                                            className="sorting-btn"
                                            endIcon={
                                                <DownArrowIcon
                                                    className={` down-arrow-icon ${
                                                        categoryOpen && 'rotate'
                                                    }`}
                                                />
                                            }
                                            onClick={(e) => setCategoryAnchorEl(e.currentTarget)}>
                                            <Typography className="button-text elipsis">
                                                {selectedCategory?.categoryName}
                                            </Typography>
                                        </Button>
                                        <PopOverWrapper
                                            id={categoryId}
                                            open={categoryOpen}
                                            anchorEl={categoryAnchorEl}
                                            onClose={handleClose}
                                            classes={{ paper: 'sorting-box' }}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left'
                                            }}>
                                            <Box className="box full-width" height={200}>
                                                <TaoScrollbar>
                                                    {[...CATEGORY_SORT, ...categories].map(
                                                        (item, index) => (
                                                            <Box
                                                                key={index}
                                                                className="list-item flex"
                                                                onClick={() => {
                                                                    if (
                                                                        selectedCategory?.categoryId !==
                                                                        item?.categoryId
                                                                    ) {
                                                                        setPagination((prev) => ({
                                                                            ...prev,
                                                                            current_page: 1
                                                                        }));
                                                                        setSelectedIds([]);
                                                                        setEditingStatus(false);
                                                                        setSelectedCategory({
                                                                            categoryName:
                                                                                item?.categoryName,
                                                                            categoryId:
                                                                                item?.categoryId
                                                                        });
                                                                    }
                                                                    setCategoryAnchorEl(null);
                                                                }}>
                                                                {item?.smallIcon && (
                                                                    <Box
                                                                        className="category-icon"
                                                                        sx={{
                                                                            backgroundImage: `url(${item?.smallIcon})`
                                                                        }}
                                                                    />
                                                                )}
                                                                <Typography
                                                                    className="sorting-text elipsis"
                                                                    key={index}>
                                                                    {item?.categoryName}
                                                                </Typography>
                                                            </Box>
                                                        )
                                                    )}
                                                </TaoScrollbar>
                                            </Box>
                                        </PopOverWrapper>
                                    </Box>

                                    <Box className="category-selection">
                                        <Button
                                            aria-describedby={id}
                                            variant="outlined"
                                            className="sorting-btn"
                                            endIcon={
                                                <DownArrowIcon
                                                    className={`down-arrow-icon ${
                                                        open && 'rotate'
                                                    }`}
                                                />
                                            }
                                            onClick={(e) => setAnchorEl(e.currentTarget)}>
                                            <Typography className="button-text">
                                                {sortBy}
                                            </Typography>
                                        </Button>
                                        <PopOverWrapper
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            classes={{ paper: 'sorting-box' }}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left'
                                            }}>
                                            <Box className="box">
                                                {Object.values(SORT_BY_VALUE).map((item, index) => (
                                                    <Box
                                                        key={index}
                                                        className="list-item flex"
                                                        onClick={() => {
                                                            if (sortBy !== item) {
                                                                setPagination((prev) => ({
                                                                    ...prev,
                                                                    current_page: 1,
                                                                    sort: {
                                                                        field: SORT_BY_API_VALUE[
                                                                            item
                                                                        ],
                                                                        order: prev?.sort?.order
                                                                    }
                                                                }));
                                                                setSortBy(item);
                                                            }
                                                            setAnchorEl(null);
                                                        }}>
                                                        <Typography
                                                            className="sorting-text elipsis"
                                                            key={index}>
                                                            {item}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </PopOverWrapper>
                                    </Box>
                                </Box>
                                <Box className="list">
                                    <TaoScrollbar>
                                        {data?.map((item) => (
                                            <Box
                                                className="list-item flex full-width"
                                                key={item?.contentId}>
                                                <Box className="item flex f-v-center full-width">
                                                    {isEditing && (
                                                        <Box className="hover">
                                                            {!selectedIds?.includes(
                                                                item?.contentId
                                                            ) ? (
                                                                <UnSelected
                                                                    className="radio-icon"
                                                                    onClick={() =>
                                                                        setSelectedIds((prev) => [
                                                                            ...prev,
                                                                            item?.contentId
                                                                        ])
                                                                    }
                                                                />
                                                            ) : (
                                                                <Selected
                                                                    className="radio-icon"
                                                                    onClick={() => {
                                                                        let newSelectedIDs = [
                                                                            ...selectedIds
                                                                        ];
                                                                        let index =
                                                                            newSelectedIDs.findIndex(
                                                                                (selectedId) =>
                                                                                    selectedId ===
                                                                                    item?.contentId
                                                                            );
                                                                        newSelectedIDs.splice(
                                                                            index,
                                                                            1
                                                                        );
                                                                        setSelectedIds(
                                                                            newSelectedIDs
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        </Box>
                                                    )}
                                                    <Box
                                                        className={`swipe-data ${
                                                            isEditing && 'add-margin'
                                                        } flex full-width`}>
                                                        <Box
                                                            className="image flex f-v-center f-h-center"
                                                            sx={{
                                                                backgroundImage: `url(${item?.contentImage})`
                                                            }}>
                                                            <Button
                                                                onClick={() =>
                                                                    navigate(
                                                                        URL_MEDITATION_MEDIA_PLAYER,
                                                                        {
                                                                            state: {
                                                                                contentId:
                                                                                    item?.contentId
                                                                            }
                                                                        }
                                                                    )
                                                                }>
                                                                <PlayButton className="hover" />
                                                            </Button>
                                                        </Box>
                                                        <Box className="desc">
                                                            <Typography className="desc-title">
                                                                {item?.title}
                                                            </Typography>
                                                            <Typography className="desc-sub-title elipsis">
                                                                {prepareCategory(item?.category)}
                                                            </Typography>
                                                            <Typography className="desc-sub-title">
                                                                {item?.sizeInMb} MB
                                                            </Typography>
                                                        </Box>
                                                        {!isEditing && (
                                                            <MobileDownloaded className="mobile-download-icon" />
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                        {pagination?.totalRecords !== data?.length && (
                                            <Box
                                                className="load-more flex f-column f-v-center f-h-center hover"
                                                onClick={() => loadMoreData()}>
                                                <Typography className="load-more-text">
                                                    Load More
                                                </Typography>
                                                <Box className="load-more-arrow" />
                                            </Box>
                                        )}
                                    </TaoScrollbar>
                                </Box>
                            </Box>
                        )}
                    </Box>
                    {!!(selectedIds?.length && isEditing) && (
                        <Button
                            className="delete-icon flex f-column f-v-center f-h-center hover"
                            onClick={() => deleteSelectedDownloads()}>
                            <DeleteIcon className="icon" />
                            <Typography className="delete-text">
                                Delete Selected Downloads
                            </Typography>
                        </Button>
                    )}
                </Box>
            </DownloadWrapper>
        </React.Fragment>
    );
};

export default Downloads;
