import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SalesPageOneWrapper } from './SalesPageOne.style';
import { Box, Button, Typography } from '@mui/material';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import { SlickDotSlider } from 'Components/common/TaoSlider/SlickDotSlider';
import { hideTopBarInteractionButton, showFooter } from 'Redux/App/Actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API_URL, URL_LOGIN, URL_SALES_PAGE_TWO, URL_SIGN_UP } from 'Helpers/Paths';
import Api from 'Helpers/ApiHandler';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import { useSelector } from 'react-redux';
import { Footer } from 'Components/common';

const SalesPageOne = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
    const [slides, setSlides] = useState([]);

    const API = useMemo(() => new Api(), []);

    const fetchSlider = useCallback(async () => {
        const response = await API.get(API_URL.TESTIMONIAL);
        if (response?.data) setSlides(response?.data?.data);
    }, [API]);

    const handleNavigate = (url, state = {}) => {
        if (url) {
            navigate(url, {
                state
            });
        }
    };

    useEffect(() => {
        fetchSlider();
        dispatch(hideTopBarInteractionButton(false));
    }, [dispatch, fetchSlider]);

    useEffect(() => {
        dispatch(showFooter());
        if (isLoggedIn) dispatch(hideSideBar());
        return () => {
            if (isLoggedIn) dispatch(showSideBar());
        };
    }, [dispatch, isLoggedIn]);

    return (
        <React.Fragment>
            <SalesPageOneWrapper>
                <Box className="bg-color" />
                <TaoCalligraphyWatermark />
                <Box className="container">
                    <SlickDotSlider arrowsNeeded>
                        {slides.map((item) => (
                            <React.Fragment key={item?.id}>
                                {item?.title ? (
                                    <Typography className="title">{item?.title}</Typography>
                                ) : (
                                    <Box className="quote-image" />
                                )}
                                <Typography className="description">{item?.description}</Typography>
                                <Box className="flex f-v-center f-h-center">
                                    {item?.author && <Box className="author-by-dash" />}
                                    <Typography className="description">{item?.author}</Typography>
                                </Box>
                            </React.Fragment>
                        ))}
                    </SlickDotSlider>
                </Box>
                <Box className="footer-container" />

                <Box className="redirection-links">
                    <Button
                        className="redirection-btn"
                        onClick={() => {
                            handleNavigate(URL_SALES_PAGE_TWO);
                        }}>
                        explore wellness library
                    </Button>
                    <Box className="flex f-h-center">
                        <Typography
                            className="footer-text"
                            onClick={() => handleNavigate(URL_SIGN_UP)}>
                            Create Account
                        </Typography>
                        <Typography className="footer-text divider">|</Typography>
                        <Typography
                            className="footer-text"
                            onClick={() => handleNavigate(URL_LOGIN)}>
                            Login
                        </Typography>
                    </Box>
                </Box>
                <Box className="flower-image" />
                <Box className="bottom-gradient" />
            </SalesPageOneWrapper>
            <Footer
                showWhiteBackground
                topBorderColor="#fff"
                isBottomZero
                zIndex={210}
                position="fixed"
            />
        </React.Fragment>
    );
};

export default SalesPageOne;
