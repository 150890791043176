import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import Footer from 'Components/common/Footer/Footer';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';

import { ProfileSectionWrapper, FooterSectionWrapper } from './Profile.style';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';

import { showFooter, hideFooter } from 'Redux/App/Actions';
import { getWindowDimensions } from 'Helpers';
import { useNavigate } from 'react-router-dom';

import ProfileItems from './ProfileItems';
import ProfileItemManipulation from './ProfileItemManipulation';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import { ReactComponent as BackIcon } from 'Assets/images/Common/gray-left-arrow.svg';
import { IconButton } from '@mui/material';
import usePermissions from 'Components/common/Permissions';
import { ACCESS_LEVELS_MODULEs } from 'Helpers/Constants';

const Profile = (props) => {
    const dispatch = useDispatch();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [menuDisplay, setMenuDisplay] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const permissions = usePermissions(ACCESS_LEVELS_MODULEs.ACCOUNT_INFO);
    const [selectedMenu, setSelectedMenu] = useState({});
    const navigate = useNavigate();

    const onSetIsLoading = (selectedValue) => {
        setIsLoading(selectedValue);
    };

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);

            dispatch(showFooter());
        };
    }, [dispatch]);

    useEffect(() => {
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            dispatch(hideFooter());
        }
    }, [dispatch, windowDimensions]);

    return (
        <>
            <TaoCalligraphyWatermark />

            <ProfileSectionWrapper $menuDisplay={menuDisplay ? true : false}>
                <Box className="back-arrow-container">
                    <IconButton
                        onClick={() => {
                            if (windowDimensions.width > BREAKPOINTS_VALUE.TABLET) {
                                return navigate(-1);
                            }
                            if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
                                if (!menuDisplay) {
                                    return navigate(-1);
                                } else {
                                    setMenuDisplay((prevState) => !prevState);
                                    return;
                                }
                            }
                        }}>
                        <Box className="white-circle-wrapper">
                            <BackIcon className="back-icon" />
                        </Box>
                    </IconButton>
                </Box>
                <TaoLoader isLoading={isLoading || !permissions?.permissionLoaded} />
                <Box className="profile-section-details">
                    <ProfileItems
                        setIsLoading={onSetIsLoading}
                        menuDisplay={menuDisplay}
                        setMenuDisplay={setMenuDisplay}
                        type={selectedMenu?.type}
                        setSelectedMenu={setSelectedMenu}
                    />

                    {selectedMenu && (
                        <ProfileItemManipulation
                            {...selectedMenu}
                            menuDisplay={menuDisplay}
                            setMenuDisplay={setMenuDisplay}
                        />
                    )}
                </Box>
            </ProfileSectionWrapper>

            <FooterSectionWrapper>
                <Footer showWhiteBackground={false} topBorderColor="gray" />
            </FooterSectionWrapper>
        </>
    );
};

export default Profile;
