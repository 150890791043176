import styled from 'styled-components';
import { Box } from '@mui/material';
import { FONTS, /* COLORS */ } from "Styles/Constants";
export const TaoSessionCardWrapper = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 20px;
    padding: 15px;

    .upcoming-session-image {
        position: relative;
        display: inline-block;

        .avatar {
            border: ${(props) => props.isTaoCalligraphyTeacher ? "3px solid #C3A157;": "none"};
            border-radius: 13px;
        }

        .live-indicator {
            position: absolute;
            background-color: #bc1218;
            color: #ffffff;
            font-family: ${FONTS.PRIMARY_MEDIUM};
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 98%;
            top: 58px;
            border-radius: 0px 0px 10px 10px;
            margin-left: 1px;
        }

        .playlist-session {
            position: absolute;
            top: 5px;
            left: 10px;
        }
    }

    .upcoming-session-date-details {
        flex: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 2px;
        color: #31290E;

        .session-time {
            color: #848484;
        }

        .challenge-completed {
            color: #059669;
        }

        .MuiTypography-root {
            font-family: ${FONTS.PRIMARY};
            font-size: 14px;
        }

        .session-title {
            font-family: ${FONTS.PRIMARY_MEDIUM};
            font-size: 18px;
            line-height: 18px;
        }

        .session-teacher {
            color: #848484;
        }

        .host-container {
            display: flex;
            gap: 5px;

            .hosting-text {
                line-height: 20px;
                font-weight: 500;
            }
        }
    }

    .upcoming-session-navigate {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }
`;