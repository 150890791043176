import React from 'react';

import { Box, Typography, Backdrop, Slide, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import moment from 'moment';
import { useNavigate } from 'react-router';
import { URL_MEDITATION_ROOM_ADD_UPDATE } from 'Helpers/Paths';

import { RoomStatusModalWrapper } from './MyRoom.style';
import { TaoButton } from 'Components/common/Button.style';



const NotApprovedModal = ({ open = false, roomDetails = { title: "", submittedTime: moment().add(-7, 'day'), reviewedTime: moment().add(-4, 'day'), resubmitTime: moment().add(4, 'day') }, onClose = () => { }, ...props }) => {

    const navigate = useNavigate();

    const editRoomNavHandler = () => {
        navigate(URL_MEDITATION_ROOM_ADD_UPDATE)
    }

    return (
        <RoomStatusModalWrapper
            open={open}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 250,
            }}
            onClose={onClose}
            closeAfterTransition
        >
            <Slide timeout={250} in={open} direction="up">
                <Box className="dialog-container">
                    <IconButton className="close-btn" onClick={onClose}><CloseIcon /></IconButton>
                    <Box className="content-container not-app-modal">
                        <Typography className="title">{roomDetails.title}</Typography>
                        <Box className="dates-container">
                            <Typography className="date-text">Submitted: <b>{roomDetails?.submittedTime?.format("MMM D, YYYY ")}</b> <Typography>{roomDetails?.submittedTime?.format("hh:mm a")}</Typography></Typography>
                            <Typography className="date-text">Reviewed: <b>{roomDetails?.reviewedTime?.format("MMM D, YYYY ")}</b> <Typography>{roomDetails?.reviewedTime?.format("hh:mm a")}</Typography></Typography>
                        </Box>
                        <Box className="text-container">
                            <Typography className="not-approved-title">Your room Was</Typography>
                            <Typography className="danger-text">
                                Not Approved
                                <Box className="red-line"></Box>
                            </Typography>
                        </Box>
                        <Box className="message-container">
                            <Typography className="title-text">Message from Moderator:</Typography>
                            <Typography className="message">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum lacinia luctus risus pharetra dui elementum ornare. Arcu ut interdum ullamcorper potenti. Sed tellus egestas et nunc lacus </Typography>
                        </Box>
                        <Box className="resubmit-container">
                            <Typography className="title-text">You may resubmit with changes until</Typography>
                            <Typography className="resubmit-date"><b>{roomDetails?.resubmitTime?.format("MMM D, YYYY ")}</b> <Typography className="time">{roomDetails?.resubmitTime?.format("hh:mm a")}</Typography></Typography>
                            <TaoButton className="edit-room-btn" onClick={editRoomNavHandler}>edit room</TaoButton>

                            <Typography className="option-text">OR you may</Typography>
                            <TaoButton className="contact-btn">Contact the Moderator</TaoButton>
                        </Box>
                    </Box>
                </Box>
            </Slide>
        </RoomStatusModalWrapper>
    );
};

export default NotApprovedModal;