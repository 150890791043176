import React from 'react';
import { BoxLoader, TitleLoader } from '../ShimmerEffectLoader/ShimmerEffectLoader';
import { TaoCardLoaderWrapper } from './TaoCard.style';

const TaoCardLoader = ({ ...props }) => {
    return (
        <TaoCardLoaderWrapper>
            <BoxLoader customStyle={{ borderRadius: '24px 8px' }} width={210} height={120} />
            <TitleLoader width={210} height={24} fillWidth={190} fillHeight={10} marginLeft={10} />
        </TaoCardLoaderWrapper>
    );
};
export default TaoCardLoader;
