import React, { useEffect, useMemo, useState, useRef } from 'react';
import { LoginWrapper } from './Login.style';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ReactComponent as AppleIcon } from 'Assets/images/Signup/apple-icon.svg';
import { ReactComponent as FacebookIcon } from 'Assets/images/Signup/facebook-icon.svg';
import { ReactComponent as GoogleIcon } from 'Assets/images/Signup/google-icon.svg';
import { ReactComponent as CheckboxIcon } from 'Assets/images/Signup/check-box.svg';
import { ReactComponent as CheckboxSelectedIcon } from 'Assets/images/Signup/check-box-selected.svg';
import {
    Button,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Typography,
    FormHelperText,
    Checkbox
} from '@mui/material';
import { ReactComponent as VisibilityOff } from 'Assets/images/Common/chevron-down.svg';
import { ReactComponent as Visibility } from 'Assets/images/Common/eye.svg';
import {
    URL_SIGN_UP,
    URL_QUESTIONNAIRE_MAIN,
    URL_RESET_PASSWORD,
    API_URL,
    URL_VERIFY_EMAIL,
    URL_LOGIN,
    URL_HOME_PAGE
} from 'Helpers/Paths';
import { useNavigate } from 'react-router-dom';
import Footer from 'Components/common/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';

// FORM VALIDATION
import { Formik } from 'formik';
import * as Yup from 'yup';
import { loginUser } from 'Redux/Auth/Actions';
import axios from 'axios';
import { DEVICE_TYPE, SIGN_UP_TYPE } from 'Helpers/Constants';
import Api from 'Helpers/ApiHandler';
import CODES from 'Helpers/StatusCodes';
import { showToast } from 'Redux/App/Actions';
import { decodeToken } from 'react-jwt';
import { detectBrowser, detectOS } from 'Helpers';
import TaoLoader from 'Components/common/TaoBackdropLoader';

const AppURL = process.env.PUBLIC_URL;

const Login = () => {
    const API = useMemo(() => new Api(), []);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [loginChoice, setLoginChoice] = useState(false);

    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
    const userDetails = useSelector((state) => state.Auth.userInfo);
    const formRef = useRef(null);

    const initialValues = {
        email: '',
        password: '',
        remember: false
    };

    const validationSchema = Yup.object({
        email: Yup.string()
            .required('Please enter your email address')
            .email('Please enter a valid email address'),
        password: Yup.string().required('Please enter password')
    });

    const initApple = () => {
        window.AppleID.auth.init({
            clientId: 'com.taocalligraphy.onenessheartweb', // This is the service ID we created.
            scope: 'name', // To tell apple we want the user name and emails fields in the response it sends us.
            redirectURI: AppURL + URL_LOGIN, // As registered along with our service ID
            state: 'origin:web', // Any string of your choice that you may use for some logic. It's optional and you may omit it.
            usePopup: true // Important if we want to capture the data apple sends on the client side.
        });
    };

    const singInApple = async () => {
        const response = await window.AppleID.auth.signIn();

        if (response) {
            const { user, authorization } = response;
            let decodedToken = decodeToken(authorization?.id_token);
            let values = {
                firstName: user?.name?.firstName || '',
                lastName: user?.name?.lastName || '',
                socialId: decodedToken?.sub || '',
                email: decodedToken?.email || '',
                emailVerified: true,
                socialType: SIGN_UP_TYPE.APPLE
            };
            handleSubmit(values, true);
        }
        // return response;
    };

    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const userInfo = await axios.get(API_URL?.GOOGLE_APIs_USER_INFO, {
                headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
            });
            if (userInfo) {
                const { data } = userInfo;
                let values = {
                    firstName: data?.given_name,
                    lastName: data?.family_name,
                    socialId: data?.sub,
                    email: data?.email,
                    emailVerified: data?.email_verified,
                    socialType: SIGN_UP_TYPE.GOOGLE
                };
                handleSubmit(values, true);
            }
        },
        onError: (errorResponse) => console.log(errorResponse)
    });

    const responseFacebook = (data) => {
        let values = {
            firstName: data?.name.split(' ')[0],
            lastName: data?.name.split(' ')[1],
            socialId: data?.userID,
            email: data?.email,
            emailVerified: data?.email ? true : false,
            socialType: SIGN_UP_TYPE.FACEBOOK
        };
        handleSubmit(values, true);
    };

    const handleSubmit = async (values, isSocial = false) => {
        try {
            setIsLoading(true);
            let reqBody = {
                email: values?.email,
                password: values?.password || '',
                deviceType: DEVICE_TYPE.WEB,
                deviceToken: '',
                loginType: isSocial ? values?.socialType : SIGN_UP_TYPE.NORMAL,
                socialId: isSocial ? values?.socialId : '',
                location: '',
                browserType: detectBrowser(),
                osType: detectOS(),
                deviceModel: '',
                deviceVersion: ''
            };

            let response = await API.post(API_URL.LOG_IN, {
                data: reqBody
                // skipErrorHandling: isSocial ? true : false
            });

            if (response?.status === CODES.SUCCESS && response?.data?.data?.isEmailVerified) {
                if (!isSocial) {
                    document.cookie = `email=${values?.remember ? values?.email : ''}`;
                    document.cookie = `password=${values?.remember ? values?.password : ''};`;
                    document.cookie = `rememberCredential=${values?.remember};`;
                }

                dispatch(loginUser(response?.data?.data));
                response?.data?.data?.isQuestionnaireCompleted
                    ? navigate(URL_HOME_PAGE)
                    : navigate(URL_QUESTIONNAIRE_MAIN);
                return;
            }
            setIsLoading(false);
        } catch (error) {
            if (
                error?.response?.status === CODES.UNAUTHORIZED ||
                error?.response?.status === CODES.PRECONDITION_FAILED
            ) {
                dispatch(showToast(error?.response?.data?.message, 'error'));
                navigate(URL_VERIFY_EMAIL, {
                    state: { email: values?.email, password: values?.password }
                });
            }

            // if (isSocial && error?.response?.status === CODES.NOT_FOUND) {
            //     navigate(URL_SIGN_UP, {
            //         state: {
            //             userSocialSignUpValues: values
            //         }
            //     });
            // }
            setIsLoading(false);
        }
    };

    const getCookieValue = (name) =>
        document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

    useEffect(() => {
        if (isLoggedIn) {
            if (!userDetails?.isQuestionnaireCompleted) return navigate(URL_QUESTIONNAIRE_MAIN);
            return navigate(URL_HOME_PAGE);
        }
        initApple();
    }, [isLoggedIn, navigate, userDetails?.isQuestionnaireCompleted]);

    useEffect(() => {
        const { setFieldValue } = formRef?.current;
        if (loginChoice) {
            let email = getCookieValue('email');
            let password = getCookieValue('password');

            let rememberCredential = getCookieValue('rememberCredential');
            if (rememberCredential) rememberCredential = JSON?.parse(rememberCredential);

            if (!rememberCredential) return;

            setFieldValue('email', email);
            setFieldValue('password', password);
            setFieldValue('remember', rememberCredential);
        }
    }, [loginChoice]);

    return (
        <>
            <LoginWrapper>
                <TaoLoader isLoading={isLoading} />
                <div className="container">
                    <div className="login-container">
                        <div>
                            <div className="margin-bottom-spacing flex f-v-center top-margin">
                                <div className="arrow-icon">
                                    <ArrowBackIosIcon
                                        onClick={
                                            loginChoice
                                                ? () => setLoginChoice(false)
                                                : () => navigate(-1)
                                        }
                                        style={{ color: '#848484', cursor: 'pointer' }}
                                    />
                                </div>
                                <div>
                                    <Typography className="title">Login</Typography>
                                </div>
                            </div>
                        </div>

                        <Formik
                            innerRef={formRef}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => handleSubmit(values)}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleSubmit,
                                setFieldValue
                            }) => (
                                <form
                                    ref={formRef}
                                    onSubmit={handleSubmit}
                                    style={{ display: `${loginChoice ? 'block' : 'none'}` }}>
                                    <div>
                                        <div className="margin-bottom-spacing">
                                            <OutlinedInput
                                                style={{ width: '100%', backgroundColor: 'white' }}
                                                placeholder="Email address"
                                                onChange={handleChange}
                                                key={'email'}
                                                name="email"
                                                value={values.email}
                                            />
                                            {touched?.email && errors.email && (
                                                <FormHelperText error={true}>
                                                    {errors.email}
                                                </FormHelperText>
                                            )}
                                        </div>

                                        <div className="margin-bottom-spacing">
                                            <OutlinedInput
                                                style={{ width: '100%', backgroundColor: 'white' }}
                                                type={passwordVisible ? 'text' : 'password'}
                                                placeholder="Password"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() =>
                                                                setPasswordVisible(
                                                                    (prevState) => !prevState
                                                                )
                                                            }
                                                            aria-label="toggle password visibility">
                                                            {!passwordVisible ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                onChange={handleChange}
                                                key={'password'}
                                                name="password"
                                                value={values.password}
                                            />
                                            {touched?.password && errors.password && (
                                                <FormHelperText error={true}>
                                                    {errors.password}
                                                </FormHelperText>
                                            )}
                                        </div>

                                        <div className="remember-wrapper flex ">
                                            <Checkbox
                                                checked={values.remember}
                                                classes={{
                                                    root: 'checkbox-root'
                                                }}
                                                onChange={handleChange}
                                                key={'remember'}
                                                name="remember"
                                                icon={<CheckboxIcon />}
                                                checkedIcon={<CheckboxSelectedIcon />}
                                            />
                                            <Typography className="remember-text">
                                                Remember me
                                            </Typography>
                                        </div>
                                        <div>
                                            <Button
                                                type="submit"
                                                disabled={isLoading}
                                                className="button button-color"
                                                variant="contained">
                                                LOGIN
                                            </Button>
                                        </div>

                                        <div
                                            className="flex f-h-center"
                                            style={{ padding: '12px' }}>
                                            <div
                                                className="forget-password link"
                                                onClick={() => navigate(URL_RESET_PASSWORD)}>
                                                Forgot Password?
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>

                        <div
                            style={{
                                display: `${loginChoice ? 'none' : 'block'}`,
                                margin: '70px 0 0 0'
                            }}>
                            <div id="login-by-mail-btn">
                                <Button
                                    type="button"
                                    className="button button-color"
                                    variant="contained"
                                    onClick={() => setLoginChoice(true)}>
                                    LOGIN WITH EMAIL
                                </Button>
                            </div>

                            <div className="login-options-container">
                                <div className="divider-container">
                                    <span style={{ padding: '0 10px' }}>or continue with</span>
                                </div>

                                <div className="social-icon">
                                    <div className="social-background apple-background">
                                        <AppleIcon
                                            style={{ marginBottom: '4px' }}
                                            onClick={() => singInApple()}
                                        />
                                    </div>

                                    <div className="social-background google-background">
                                        <GoogleIcon onClick={() => googleLogin()} />
                                    </div>

                                    <div className="social-background facebook-background">
                                        <FacebookLogin
                                            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                            size="small"
                                            textButton=""
                                            autoLoad={false}
                                            icon={<FacebookIcon />}
                                            fields="name,email,picture"
                                            callback={responseFacebook}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex f-h-center signup signup-container">
                            Don't have an account?
                            <div className="signup bold link" onClick={() => navigate(URL_SIGN_UP)}>
                                Sign Up
                            </div>
                        </div>
                    </div>
                </div>
            </LoginWrapper>
            <Footer showWhiteBackground topBorderColor="gray" />
        </>
    );
};

export default Login;
