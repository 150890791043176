export const StarFilled = (props) => {
    return (
        <svg
            width={props.width ? props.width : '12'}
            height={props.height ? props.height : '12'}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.38545 10.2504C5.72025 10.0484 6.13943 10.0484 6.47423 10.2504L8.39114 11.4074C9.18899 11.8889 10.1732 11.1736 9.9615 10.2661L9.45277 8.0854C9.36393 7.70456 9.49335 7.30584 9.78893 7.04978L11.4841 5.58122C12.1885 4.97102 11.812 3.81398 10.8834 3.7352L8.65394 3.54605C8.26458 3.51302 7.92549 3.2672 7.77301 2.90742L6.89983 0.84722C6.5368 -0.0093183 5.32288 -0.0093183 4.95985 0.84722L4.08667 2.90742C3.93419 3.2672 3.5951 3.51302 3.20574 3.54605L0.976295 3.7352C0.0476992 3.81398 -0.328835 4.97102 0.375544 5.58122L2.07075 7.04978C2.36633 7.30584 2.49575 7.70456 2.40691 8.0854L1.89818 10.2661C1.68647 11.1736 2.6707 11.8889 3.46854 11.4074L5.38545 10.2504Z"
                fill="#B08C3D"
            />
        </svg>
    );
};

export const StarOutlined = (props) => {
    return (
        <svg
            width={props.width ? props.width : '12'}
            height={props.height ? props.height : '12'}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.6323 5.57772C12.3352 4.96763 11.9596 3.81241 11.0322 3.73245L8.79312 3.53937C8.40453 3.50586 8.06625 3.26041 7.91386 2.90139L7.04097 0.844966C6.67758 -0.0111223 5.46413 -0.010793 5.10121 0.845493L4.2273 2.90742C4.07481 3.2672 3.73572 3.51302 3.34637 3.54605L1.11692 3.7352C0.188324 3.81398 -0.18821 4.97102 0.516169 5.58122L2.21138 7.04978C2.50696 7.30584 2.63638 7.70456 2.54753 8.0854L2.03881 10.2661C1.82709 11.1736 2.81132 11.8889 3.60917 11.4074L5.52608 10.2504C5.86088 10.0484 6.28006 10.0484 6.61485 10.2504L8.53579 11.4098C9.33305 11.891 10.3168 11.1771 10.1065 10.2699L9.59964 8.08402C9.5115 7.70389 9.64069 7.30618 9.93539 7.05041L11.6323 5.57772ZM6.61518 8.85972C6.28024 8.65715 5.8606 8.65699 5.5255 8.8593L5.41124 8.92827C4.61326 9.41004 3.62878 8.69437 3.84086 7.78668L3.87197 7.65351C3.96092 7.27282 3.83175 6.87418 3.53643 6.618L3.42542 6.5217C2.72243 5.91188 3.09755 4.75675 4.0247 4.67631L4.17264 4.66348C4.56185 4.62971 4.90042 4.38334 5.05226 4.02338L5.10142 3.90683C5.46343 3.04867 6.67925 3.04793 7.0423 3.90565L7.09574 4.03192C7.24784 4.39127 7.58611 4.63712 7.97487 4.67085L8.12367 4.68376C9.05082 4.76419 9.42595 5.91932 8.72296 6.52914L8.61194 6.62545C8.31663 6.88162 8.18745 7.28026 8.2764 7.66095L8.3069 7.79151C8.51907 8.69958 7.53377 9.41526 6.73583 8.93268L6.61518 8.85972Z"
                fill="#B08C3D"
            />
        </svg>
    );
};
