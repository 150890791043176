import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { TaoButton } from 'Components/common/Button.style';
import React from 'react';
import { ClearModalWrapper } from './Notifications.style';

const ClearNotificationModal = ({ onOk, onCancel, type, isSingleNeedToRemove, ...props }) => {
    return (
        <ClearModalWrapper
            open
            classes={{ paper: 'paper' }}
            $isSingleNeedToRemove={isSingleNeedToRemove ? true : false}>
            <Box className="flex f-v-center full-width">
                <Typography className="title">
                    {isSingleNeedToRemove
                        ? 'Are you sure want to clear notification?'
                        : 'Clear All Notifications?'}
                </Typography>
            </Box>

            <Box className="buttonPadding full-width">
                <TaoButton onClick={() => onOk(type)}>YES, CLEAR</TaoButton>
            </Box>

            <Box className=" full-width">
                <Button className="keep-btn full-width" onClick={onCancel} variant="text">
                    No, Keep
                </Button>
            </Box>
        </ClearModalWrapper>
    );
};

export default ClearNotificationModal;
