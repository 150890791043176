import React, { useRef } from 'react';
import TaoSlider from 'Components/common/TaoSlider';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { URL_REFERRAL_STORE } from 'Helpers/Paths';
import { StoreItemWrapper } from './Referral.style';
import ContentLoader from 'react-content-loader';

const StoreItemLoader = (props) => {
    const storeItemLoaderRef = useRef(null);
    return (
        <TaoSlider
            gap={10}
            ref={storeItemLoaderRef}
            slidesToShow={1}
            showArrows={false}
            showShadow={false}>
            {[0, 1, 2, 3].map((item) => (
                <Box className="item" key={item}>
                    <ContentLoader
                        speed={2}
                        width="190"
                        height={220}
                        backgroundColor="#dfdfdf"
                        foregroundColor="#ecebeb"
                        preserveAspectRatio="none"
                        {...props}>
                        <rect x="0" y="0" rx="6" ry="6" width="190" height="190" />
                        <rect x="0" y="200" rx="6" ry="6" width="100%" height="20" />
                    </ContentLoader>
                </Box>
            ))}
        </TaoSlider>
    );
};

const StoreItems = ({ title = 'Shop with Points or Cash', data = [], ...props }) => {
    const storeTypeRef = useRef(null);
    const navigate = useNavigate();

    return (
        <StoreItemWrapper>
            <Typography className="store-item-title">{title}</Typography>
            {props?.isLoading ? (
                <StoreItemLoader />
            ) : (
                <TaoSlider
                    gap={10}
                    ref={storeTypeRef}
                    cardHeight={80}
                    slidesToShow={1}
                    showArrows={false}
                    showShadow={false}>
                    {data.map((item) => (
                        <Box key={item?.id} className="item">
                            <Box className="store-item">
                                <Box
                                    className="image"
                                    style={{ backgroundImage: `url(${item?.image})` }}
                                />
                                <Box className="bottom-bar">
                                    <Typography className="feature-text">
                                        {item?.availableDays}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography className="type">{item?.categoryName}</Typography>
                        </Box>
                    ))}
                </TaoSlider>
            )}
            <Button
                type="submit"
                className="button"
                variant="contained"
                onClick={() => navigate(URL_REFERRAL_STORE)}>
                browse the store
            </Button>
        </StoreItemWrapper>
    );
};

export default StoreItems;
