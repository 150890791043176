import { Chip } from '@mui/material';
import styled from 'styled-components';
import { COLORS, responsive } from 'Styles/Constants';

export const TaoChipWrapper = styled(Chip)`
    &.chip-root {
        height: 46px;
        padding: 10px 20px;
        border-radius: 26px;
        text-transform: capitalize;
        font-size: 18px;
        line-height: 26px;
        border: 2px solid;
        margin: 5px 5px 5px 0;
        border-color: ${(props) => (props?.selected ? 'transparent' : COLORS.PRIMARY)};
        background-color: ${(props) => (props?.selected ? COLORS.PRIMARY : COLORS.WHITE)};
        color: ${(props) => (props?.selected ? COLORS.WHITE : COLORS.TEXT_DARK)};

        ${responsive.TABLET`
            font-size: 16px;
            line-height: 24px;
        `}
    }
`;
