import React, { useState, useEffect, useCallback, useMemo } from 'react';

// WATERMARK
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';

// LOADER
import TaoLoader from 'Components/common/TaoBackdropLoader';

// WRAPPER
import {
    StatisticsWrapper,
    CardMainWrapper,
    DayCardWrapper,
    WeekCardWrapper,
    MonthCardWrapper,
    StatisticAnalyticsDurationWrapper
} from './Statistics.style';

// MATERIAL
import { Box, Typography, Button } from '@mui/material';

// PROGRESS BAR
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

// HELPER
import { convertHrsMinToMins, getWindowDimensions, getHrsAndMinStringFromMin } from 'Helpers';
import { API_URL } from 'Helpers/Paths';
import { STATISTICS_RANGE_TYPE } from 'Helpers/Constants';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';

// CHART
import {
    BarChart,
    Bar,
    XAxis,
    // YAxis,
    // Tooltip,
    // CartesianGrid,
    ReferenceLine,
    AreaChart,
    Area,
    ResponsiveContainer
} from 'recharts';

// MOMENT
import moment from 'moment';

// IMAGE
import { ReactComponent as StatisticsLeftDarkArrow } from 'Assets/images/Common/gray-left-arrow.svg';
import { ReactComponent as StatisticsRightDarkArrow } from 'Assets/images/Common/statistics-right-dark-arrow.svg';
import { ReactComponent as StatisticsRightLightArrow } from 'Assets/images/Common/statistics-right-light-arrow.svg';

import Api from 'Helpers/ApiHandler';
import { maxBy } from 'lodash';
// CONSTANT
const DAILY_AVERAGE = 'meditationAveragePerDay';
const WEEKLY_AVERAGE = 'weeklyMeditationStats';
const MONTHLY_AVERAGE = 'monthlyMeditationStats';

const CIRCULAR_PROGRESS_BAR_ID = 'circular-progress';

let statisticAnalyticsDuration = [
    {
        key: 1,
        duration: 'Day',
        type: STATISTICS_RANGE_TYPE.DAY
    },
    {
        key: 2,
        duration: 'Week',
        type: STATISTICS_RANGE_TYPE.WEEK
    },
    {
        key: 3,
        duration: 'Month',
        type: STATISTICS_RANGE_TYPE.MONTH
    }
];

const GradientSVG = ({ percentage, ...props }) => {
    const idCSS = CIRCULAR_PROGRESS_BAR_ID;
    const gradientTransform = `rotate(90)`;
    return (
        <svg style={{ height: 0 }}>
            <defs>
                <linearGradient id={idCSS} gradientTransform={gradientTransform}>
                    {percentage > 40 && <stop offset="16.29%" stopColor="#fff6e1" />}
                    <stop offset="85.56%" stopColor="#ffd26d" />
                </linearGradient>
            </defs>
        </svg>
    );
};

const Statistics = () => {
    const API = useMemo(() => new Api(), []);
    const [loading, setLoading] = useState(false);
    const [userStats, setUserStats] = useState({
        day: {},
        weekly: {},
        monthly: {},
        dayDate: null
    });
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [selectedDurationType, setSelectedDurationType] = useState(STATISTICS_RANGE_TYPE.ALL);

    // HANDLE DURATION CHANGE
    const handleDurationChange = (type) => {
        setSelectedDurationType(type);
    };

    // USER STATISTICS
    const userStatistics = useCallback(
        async (type = STATISTICS_RANGE_TYPE.ALL, date = moment()?.format('YYYY-MM-DD')) => {
            setLoading(true);
            let response = await API.get(API_URL.STATISTICS, {
                params: {
                    type,
                    date
                }
            });

            if (response?.data?.data && type === STATISTICS_RANGE_TYPE.ALL) {
                if (response?.data?.data) {
                    setUserStats((prev) => ({
                        dayDate: date,
                        day: {
                            ...response?.data?.data?.daily
                        },
                        weekly: {
                            ...response?.data?.data?.weekly
                        },
                        monthly: {
                            ...response?.data?.data?.monthly
                        }
                    }));
                }
            }

            if (response?.data?.data && type === STATISTICS_RANGE_TYPE.DAY) {
                setUserStats((prev) => ({
                    ...prev,
                    dayDate: date,
                    day: {
                        ...response?.data?.data
                    }
                }));
            }

            setLoading(false);
        },
        [API]
    );

    // UPDATE DAY SELECTION
    const updateDaySelection = (dayDate) => {
        setUserStats((prev) => ({
            ...prev,
            dayDate
        }));
    };

    // RESIZE UPDATE
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowDimensions.width < BREAKPOINTS_VALUE.TABLET) {
            if (selectedDurationType === STATISTICS_RANGE_TYPE.ALL) {
                setSelectedDurationType(STATISTICS_RANGE_TYPE.DAY);
            }
            return;
        }

        setSelectedDurationType(STATISTICS_RANGE_TYPE.ALL);
    }, [windowDimensions.width, selectedDurationType]);

    useEffect(() => {
        userStatistics();
    }, [userStatistics]);

    return (
        <>
            <TaoCalligraphyWatermark />
            <TaoLoader isLoading={loading} />
            <StatisticsWrapper>
                <Box className="full-width-section">
                    <Box className="statistic-content">
                        <Box className="statistic-header-content">
                            <Typography color="primary" className="statistic-header">
                                Stats
                            </Typography>
                        </Box>
                        {windowDimensions.width < BREAKPOINTS_VALUE.TABLET && (
                            <>
                                <StatisticAnalyticsDurationWrapper>
                                    {statisticAnalyticsDuration?.map((value) => (
                                        <React.Fragment key={value?.key}>
                                            <StatisticAnalyticsDuration
                                                handleDurationChange={handleDurationChange}
                                                duration={value?.duration}
                                                selected={value?.type === selectedDurationType}
                                                value={value}
                                            />
                                        </React.Fragment>
                                    ))}
                                </StatisticAnalyticsDurationWrapper>
                            </>
                        )}
                        <Box className="statistic-card-container">
                            <DayStatistics
                                showCard={
                                    selectedDurationType === STATISTICS_RANGE_TYPE.DAY ||
                                    selectedDurationType === STATISTICS_RANGE_TYPE.ALL
                                }
                                userStats={userStats}
                                userStatistics={userStatistics}
                                updateDaySelection={updateDaySelection}
                                windowDimensions={windowDimensions}
                            />
                            <WeekStatistics
                                showCard={
                                    selectedDurationType === STATISTICS_RANGE_TYPE.WEEK ||
                                    selectedDurationType === STATISTICS_RANGE_TYPE.ALL
                                }
                                userStats={userStats}
                                userStatistics={userStatistics}
                                windowDimensions={windowDimensions}
                            />
                            <MonthStatistics
                                showCard={
                                    selectedDurationType === STATISTICS_RANGE_TYPE.MONTH ||
                                    selectedDurationType === STATISTICS_RANGE_TYPE.ALL
                                }
                                userStats={userStats}
                                userStatistics={userStatistics}
                                windowDimensions={windowDimensions}
                            />
                        </Box>
                    </Box>
                </Box>
            </StatisticsWrapper>
        </>
    );
};

// DAY STATISTICS
const DayStatistics = ({
    showCard,
    userStats,
    userStatistics,
    updateDaySelection,
    windowDimensions,
    ...props
}) => {
    const nextDay = () => {
        if (userStats?.dayDate) {
            let nextDayDate = moment(userStats?.dayDate, 'YYYY-MM-DD')
                ?.add(1, 'day')
                ?.format('YYYY-MM-DD');
            if (nextDayDate) {
                userStatistics(STATISTICS_RANGE_TYPE.DAY, nextDayDate);
                updateDaySelection(nextDayDate);
            }
        }
    };

    const previousDay = () => {
        if (userStats?.dayDate) {
            let previousDayDate = moment(userStats?.dayDate, 'YYYY-MM-DD')
                .subtract(1, 'day')
                ?.format('YYYY-MM-DD');
            if (previousDayDate) {
                userStatistics(STATISTICS_RANGE_TYPE.DAY, previousDayDate);
                updateDaySelection(previousDayDate);
            }
        }
    };

    return (
        <>
            {showCard && (
                <CardMainWrapper>
                    <Typography color="primary" className="card-header">
                        Day
                    </Typography>

                    <DayCardWrapper classes={{ root: 'statistics-card' }}>
                        <Box className="statistics-header-container">
                            <Typography color="primary" className="card-text light text-uppercase">
                                {moment(userStats?.dayDate).format('D MMM')}
                            </Typography>
                        </Box>
                        <Box className="statistics-content">
                            <GradientSVG
                                percentage={userStats?.day?.dailyMeditationStats?.goalPercentage}
                            />
                            <CircularProgressbarWithChildren
                                value={userStats?.day?.dailyMeditationStats?.goalPercentage}
                                maxValue={100}
                                styles={{
                                    path: {
                                        stroke: `url(#${CIRCULAR_PROGRESS_BAR_ID})`,
                                        height: '100%'
                                    }
                                }}>
                                <Box className="meditation-progress-inner-details">
                                    <Box className="current-week-day elipsis">
                                        {userStats?.dayDate &&
                                            moment(userStats?.dayDate).format('ddd')}
                                    </Box>
                                    <Box className="total-meditation-time elipsis">
                                        {getHrsAndMinStringFromMin(
                                            userStats?.day?.dailyMeditationStats?.meditationTime ||
                                                '00:00',
                                            true,
                                            true
                                        )}
                                    </Box>
                                    <Box className="goal-in-percentage elipsis">
                                        {userStats?.day?.dailyMeditationStats?.goalPercentage}% of
                                        goal
                                    </Box>
                                    <Box className="arrow-icon-container">
                                        <Box className="left-arrow">
                                            <StatisticsLeftDarkArrow
                                                style={{ width: '13px', height: '21px' }}
                                                onClick={() => previousDay()}
                                            />
                                        </Box>
                                        <Box
                                            className={`right-arrow ${
                                                userStats?.dayDate >=
                                                    moment().format('YYYY-MM-DD') && 'removeCursor'
                                            }`}>
                                            {userStats?.dayDate < moment().format('YYYY-MM-DD') ? (
                                                <StatisticsRightDarkArrow
                                                    onClick={() => nextDay()}
                                                />
                                            ) : (
                                                <StatisticsRightLightArrow />
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </CircularProgressbarWithChildren>
                        </Box>
                        <Box className="footer-content">
                            <Box className="meditation-count">
                                <Typography color="primary" className="card-text dark">
                                    {userStats?.day?.dailyMeditationStats?.numberOfMeditations}
                                </Typography>
                                <Typography color="primary" className="card-text light">
                                    Meditations
                                </Typography>
                            </Box>
                            {/* <Box className="live-session-count">
                                <Typography color="primary" className="card-text dark">
                                    {userStats?.day?.dailyMeditationStats?.liveSessionCount}
                                </Typography>
                                <Typography color="primary" className="card-text light">
                                    Live Session
                                </Typography>
                            </Box> */}
                        </Box>
                    </DayCardWrapper>
                </CardMainWrapper>
            )}
        </>
    );
};

// WEEK STATISTICS
const WeekStatistics = ({ showCard, userStats, windowDimensions, ...props }) => {
    const [barChartDetails, setBarChartDetails] = useState([]);
    const [weeklyStatsDetailsShowBy, setWeeklyStatsDetailsShowBy] = useState(WEEKLY_AVERAGE);
    const [maxLine, setMaxLine] = useState(0);
    const [averageLine, setAverageLine] = useState(0);
    const [cardWidth, setCardWidth] = useState(0);
    const [week, setWeek] = useState({
        startDay: null,
        endDay: null
    });
    const [countDetails, setCountDetails] = useState({
        meditationAveragePerDay: {
            liveSessionCount: 0,
            meditationTime: '00:00',
            numberOfMeditations: 0
        },
        weeklyMeditationStats: {
            liveSessionCount: 0,
            meditationTime: '00:00',
            numberOfMeditations: 0
        }
    });

    const handleWeeklyStatsDetailsShowBy = (type) => {
        setWeeklyStatsDetailsShowBy(type);
    };

    const getWeekChartStartAndEndDay = useCallback(() => {
        if (userStats?.weekly?.dailyMeditationStatsDetails) {
            let copyDailyMeditationStatsDetails =
                userStats?.weekly?.dailyMeditationStatsDetails || [];

            if (
                copyDailyMeditationStatsDetails?.length &&
                copyDailyMeditationStatsDetails?.[0]?.date &&
                copyDailyMeditationStatsDetails?.[copyDailyMeditationStatsDetails?.length - 1]?.date
            ) {
                let startDay = moment(copyDailyMeditationStatsDetails?.[0]?.date).format('D MMM');
                let endDay = moment(
                    copyDailyMeditationStatsDetails?.[copyDailyMeditationStatsDetails?.length - 1]
                        ?.date
                ).format('D MMM');

                if (startDay && endDay) setWeek({ startDay, endDay });
            }
        }
    }, [userStats?.weekly?.dailyMeditationStatsDetails]);

    const getAverageAndMaxValues = useCallback((weekDetails, userStats) => {
        if (
            weekDetails?.dailyMeditationStatsDetails &&
            weekDetails?.dailyMeditationStatsDetails?.length > 0
        ) {
            let max = maxBy(weekDetails?.dailyMeditationStatsDetails, 'meditationTimeInMin');
            if (max) setMaxLine(max?.meditationTimeInMin || 0);
        }

        if (weekDetails?.weeklyMeditationStats?.totalMeditationTime) {
            let hrsToMins = convertHrsMinToMins(
                weekDetails?.weeklyMeditationStats?.totalMeditationTime || '00:00'
            );

            if (hrsToMins) {
                hrsToMins = hrsToMins / 7;
                setAverageLine(hrsToMins);
            }
        }
    }, []);

    useEffect(() => {
        const width = document?.getElementById?.('statistics-chart-content')?.clientWidth;
        setCardWidth(width);
    }, [showCard]);

    useEffect(() => {
        getWeekChartStartAndEndDay();

        let result = [];

        result = userStats?.weekly?.dailyMeditationStatsDetails?.map((value) => {
            if (value?.meditationTime) {
                value.meditationTimeInMin = convertHrsMinToMins(value?.meditationTime);
                value.day = moment(value?.date).format('ddd');
            }
            return value;
        });

        if (result) setBarChartDetails(result);

        if (userStats?.weekly) {
            let meditationTimePerDay =
                userStats?.weekly?.meditationAveragePerDay?.meditationTimePerDay || '00:00';
            let totalMeditationTime =
                userStats?.weekly?.weeklyMeditationStats?.totalMeditationTime || '00:00';

            if (meditationTimePerDay)
                meditationTimePerDay = getHrsAndMinStringFromMin(meditationTimePerDay, true, true);

            if (totalMeditationTime)
                totalMeditationTime = getHrsAndMinStringFromMin(totalMeditationTime, true, true);

            setCountDetails((prev) => ({
                ...prev,
                meditationAveragePerDay: {
                    liveSessionCount:
                        userStats?.weekly?.meditationAveragePerDay?.liveSessionCountPerDay,
                    meditationTime: meditationTimePerDay || '0m',
                    numberOfMeditations:
                        userStats?.weekly?.meditationAveragePerDay?.numberOfMeditationsPerDay
                },
                weeklyMeditationStats: {
                    ...userStats?.weekly?.weeklyMeditationStats,
                    meditationTime: totalMeditationTime || '0m'
                }
            }));

            getAverageAndMaxValues(userStats?.weekly);
        }
    }, [userStats?.weekly, getWeekChartStartAndEndDay, getAverageAndMaxValues]);

    return (
        <>
            {showCard && (
                <CardMainWrapper>
                    <Typography color="primary" className="card-header">
                        Week
                    </Typography>
                    <WeekCardWrapper classes={{ root: 'statistics-card' }}>
                        <Box className="statistics-header-container">
                            <Typography color="primary" className="card-text light text-uppercase">
                                {week?.startDay} {` - ${week?.endDay}`}
                            </Typography>
                            <Typography color="primary" className="card-text dark">
                                {countDetails?.[weeklyStatsDetailsShowBy]?.meditationTime || '0m'}
                            </Typography>
                            <Typography color="primary" className="card-text light">
                                {weeklyStatsDetailsShowBy === DAILY_AVERAGE
                                    ? 'Daily Average'
                                    : 'Total Time'}
                            </Typography>
                        </Box>
                        <ButtonContainerForWeek
                            weeklyStatsDetailsShowBy={weeklyStatsDetailsShowBy}
                            handleWeeklyStatsDetailsShowBy={handleWeeklyStatsDetailsShowBy}
                            needToDisplay={windowDimensions?.width < BREAKPOINTS_VALUE.TABLET}
                        />
                        <Box className="statistics-chart-content" id="statistics-chart-content">
                            {cardWidth && (
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart width={cardWidth} height={200} data={barChartDetails}>
                                        <defs>
                                            <linearGradient
                                                id="colorUv"
                                                x1="0"
                                                y1="0"
                                                x2="0"
                                                y2="1">
                                                <stop
                                                    offset="5%"
                                                    stopColor="#FFD26D"
                                                    stopOpacity={0.7}
                                                />
                                                <stop
                                                    offset="95%"
                                                    stopColor="#FFD26D"
                                                    stopOpacity={1}
                                                />
                                            </linearGradient>
                                        </defs>
                                        <ReferenceLine
                                            y={maxLine || 0}
                                            stroke="#B08C3D"
                                            strokeDasharray="3 3"
                                            strokeWidth={0.3}
                                        />
                                        <ReferenceLine
                                            y={averageLine || 0}
                                            stroke="#B08C3D"
                                            strokeWidth={0.3}
                                        />
                                        <XAxis
                                            dataKey="day"
                                            tickLine={false}
                                            textDecoration="none"
                                            axisLine={false}
                                        />
                                        {/* <YAxis /> */}
                                        {/* <Tooltip /> */}
                                        {/* <CartesianGrid
                                        stroke="#ccc"
                                        strokeDasharray="5 5"
                                        opacity={0.5}
                                    /> */}
                                        <Bar
                                            dataKey="meditationTimeInMin"
                                            fill="url(#colorUv)"
                                            barSize={26}
                                            radius={5}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            )}
                        </Box>
                        <Box className="footer-content">
                            <Box className="count-container">
                                <Box className="meditation-count">
                                    <Typography color="primary" className="card-text dark">
                                        {countDetails?.[weeklyStatsDetailsShowBy]
                                            ?.numberOfMeditations || 0}
                                    </Typography>
                                    <Typography color="primary" className="card-text light">
                                        {weeklyStatsDetailsShowBy === DAILY_AVERAGE
                                            ? 'Meditations/day'
                                            : 'Meditations'}
                                    </Typography>
                                </Box>
                                {/* <Box className="live-session-count">
                                    <Typography color="primary" className="card-text dark">
                                        {countDetails?.[weeklyStatsDetailsShowBy]
                                            ?.liveSessionCount || 0}
                                    </Typography>
                                    <Typography color="primary" className="card-text light">
                                        {weeklyStatsDetailsShowBy === DAILY_AVERAGE
                                            ? 'Live Session/day'
                                            : 'Live Session'}
                                    </Typography>
                                </Box> */}
                            </Box>
                            <ButtonContainerForWeek
                                weeklyStatsDetailsShowBy={weeklyStatsDetailsShowBy}
                                handleWeeklyStatsDetailsShowBy={handleWeeklyStatsDetailsShowBy}
                                needToDisplay={windowDimensions?.width > BREAKPOINTS_VALUE.TABLET}
                            />
                        </Box>
                    </WeekCardWrapper>
                </CardMainWrapper>
            )}
        </>
    );
};
const ButtonContainerForWeek = ({
    weeklyStatsDetailsShowBy,
    handleWeeklyStatsDetailsShowBy,
    needToDisplay
}) => {
    return (
        <Box className={`btn-container ${!needToDisplay && 'hide-view'}`}>
            <Button
                className={`daily-average-btn ${
                    weeklyStatsDetailsShowBy === DAILY_AVERAGE && `selected-btn`
                }`}
                onClick={() => handleWeeklyStatsDetailsShowBy(DAILY_AVERAGE)}>
                <Typography
                    className={`btn-text ${
                        weeklyStatsDetailsShowBy === DAILY_AVERAGE && `selected-text`
                    }`}>
                    DAILY AVERAGE
                </Typography>
            </Button>
            <Button
                className={`weekly-total-btn ${
                    weeklyStatsDetailsShowBy === WEEKLY_AVERAGE && `selected-btn`
                }`}
                onClick={() => handleWeeklyStatsDetailsShowBy(WEEKLY_AVERAGE)}>
                <Typography
                    className={`btn-text ${
                        weeklyStatsDetailsShowBy === WEEKLY_AVERAGE && `selected-text`
                    }`}>
                    WEEKLY TOTAL
                </Typography>
            </Button>
        </Box>
    );
};

// MONTH STATISTICS
const MonthStatistics = ({ showCard, userStats, windowDimensions, ...props }) => {
    const [barChartDetails, setBarChartDetails] = useState([]);
    const [monthlyStatsDetailsShowBy, setMonthlyStatsDetailsShowBy] = useState(MONTHLY_AVERAGE);
    const [month, setMonth] = useState({
        startDay: null,
        endDay: null
    });
    const [cardWidth, setCardWidth] = useState(0);

    const [countDetails, setCountDetails] = useState({
        meditationAveragePerDay: {
            liveSessionCount: 0,
            meditationTime: '00:00',
            numberOfMeditations: 0
        },
        monthlyMeditationStats: {
            liveSessionCount: 0,
            meditationTime: '00:00',
            numberOfMeditations: 0
        }
    });
    const [maxLine, setMaxLine] = useState(0);
    const [averageLine, setAverageLine] = useState(0);

    // HANDLE MONTHLY STATS DETAILS SHOW BY
    const handleMonthlyStatsDetailsShowBy = (type) => {
        setMonthlyStatsDetailsShowBy(type);
    };

    // GET MONTH CHART START AND END DAY
    const getMonthChartStartAndEndDay = useCallback(() => {
        if (userStats?.monthly?.dailyMeditationStatsDetails) {
            let copyDailyMeditationStatsDetails =
                userStats?.monthly?.dailyMeditationStatsDetails || [];

            if (
                copyDailyMeditationStatsDetails?.length &&
                copyDailyMeditationStatsDetails?.[0]?.date &&
                copyDailyMeditationStatsDetails?.[copyDailyMeditationStatsDetails?.length - 1]?.date
            ) {
                let startDay = moment(copyDailyMeditationStatsDetails?.[0]?.date).format('D MMM');
                let endDay = moment(
                    copyDailyMeditationStatsDetails?.[copyDailyMeditationStatsDetails?.length - 1]
                        ?.date
                ).format('D MMM');

                if (startDay && endDay) setMonth({ startDay, endDay });
            }
        }
    }, [userStats?.monthly?.dailyMeditationStatsDetails]);

    const getAverageAndMaxValues = useCallback((monthDetails, userStats) => {
        if (
            monthDetails?.dailyMeditationStatsDetails &&
            monthDetails?.dailyMeditationStatsDetails?.length > 0
        ) {
            let max = maxBy(monthDetails?.dailyMeditationStatsDetails, 'meditationTimeInMin');
            if (max) setMaxLine(max?.meditationTimeInMin || 0);
        }

        if (monthDetails?.monthlyMeditationStats?.totalMeditationTime) {
            let hrsToMins = convertHrsMinToMins(
                monthDetails?.monthlyMeditationStats?.totalMeditationTime || '00:00'
            );

            if (hrsToMins) {
                hrsToMins = hrsToMins / monthDetails?.dailyMeditationStatsDetails?.length || 30;
                setAverageLine(hrsToMins);
            }
        }
    }, []);

    // PREPARE BAR CHART DETAILS
    const prepareBarChartDetails = useCallback(() => {
        let result = [];
        result = userStats?.monthly?.dailyMeditationStatsDetails?.map((value) => {
            if (value?.meditationTime) {
                value.meditationTimeInMin = convertHrsMinToMins(value?.meditationTime);
                value.day = moment(value?.date).format('ddd');
            }
            return value;
        });

        if (result) setBarChartDetails(result);

        if (userStats?.monthly) {
            let meditationTimePerDay =
                userStats?.monthly?.meditationAveragePerDay?.meditationTimePerDay || '00:00';
            let totalMeditationTime =
                userStats?.monthly?.monthlyMeditationStats?.totalMeditationTime || '00:00';

            if (meditationTimePerDay)
                meditationTimePerDay = getHrsAndMinStringFromMin(meditationTimePerDay, true, true);

            if (totalMeditationTime)
                totalMeditationTime = getHrsAndMinStringFromMin(totalMeditationTime, true, true);

            setCountDetails((prev) => ({
                ...prev,
                meditationAveragePerDay: {
                    liveSessionCount:
                        userStats?.monthly?.meditationAveragePerDay?.liveSessionCountPerDay,
                    meditationTime: meditationTimePerDay || '0m',
                    numberOfMeditations:
                        userStats?.monthly?.meditationAveragePerDay?.numberOfMeditationsPerDay
                },
                monthlyMeditationStats: {
                    ...userStats?.monthly?.monthlyMeditationStats,
                    meditationTime: totalMeditationTime || '0m'
                }
            }));

            getAverageAndMaxValues(userStats?.monthly);
        }
    }, [userStats?.monthly, getAverageAndMaxValues]);

    useEffect(() => {
        const width = document?.getElementById?.('statistics-chart-content')?.clientWidth;
        setCardWidth(width);
    }, [showCard]);

    useEffect(() => {
        getMonthChartStartAndEndDay();
        prepareBarChartDetails();
    }, [getMonthChartStartAndEndDay, prepareBarChartDetails]);

    return (
        <>
            {showCard && (
                <CardMainWrapper>
                    <Typography color="primary" className="card-header">
                        Month
                    </Typography>

                    <MonthCardWrapper classes={{ root: 'statistics-card' }}>
                        <Box className="statistics-header-container">
                            <Typography color="primary" className="card-text light text-uppercase">
                                {month?.startDay} {` - ${month?.endDay}`}
                            </Typography>
                            <Typography color="primary" className="card-text dark">
                                {countDetails?.[monthlyStatsDetailsShowBy]?.meditationTime || '0m'}
                            </Typography>
                            <Typography color="primary" className="card-text light">
                                {monthlyStatsDetailsShowBy === DAILY_AVERAGE
                                    ? 'Daily Average'
                                    : 'Total Time'}
                            </Typography>
                        </Box>
                        <ButtonContainerForAverage
                            needToDisplay={windowDimensions?.width < BREAKPOINTS_VALUE.TABLET}
                            monthlyStatsDetailsShowBy={monthlyStatsDetailsShowBy}
                            handleMonthlyStatsDetailsShowBy={handleMonthlyStatsDetailsShowBy}
                        />

                        <Box className="statistics-chart-content" id="statistics-chart-content">
                            {/* <ResponsiveContainer width="100%" height="100%"> */}
                            {cardWidth && (
                                <AreaChart
                                    width={cardWidth}
                                    height={149}
                                    data={barChartDetails}
                                    style={{
                                        width: '100%'
                                    }}
                                    margin={{
                                        top: 0,
                                        right: 0,
                                        left: 0,
                                        bottom: 0
                                    }}>
                                    <defs>
                                        <linearGradient
                                            id="areaChartColorUv"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1">
                                            <stop offset="5%" stopColor="#FFC645" stopOpacity={1} />
                                            <stop
                                                offset="95%"
                                                stopColor="#FFD26D"
                                                stopOpacity={0.4}
                                            />
                                        </linearGradient>
                                    </defs>
                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                    {/* <XAxis dataKey="name" /> */}
                                    {/* <YAxis /> */}
                                    {/* <Tooltip /> */}
                                    <Area
                                        type="monotone"
                                        dataKey="meditationTimeInMin"
                                        stroke="#FFC645"
                                        fill="url(#areaChartColorUv)"
                                    />
                                    <ReferenceLine
                                        y={maxLine || 0}
                                        stroke="#B08C3D"
                                        strokeDasharray="3 3"
                                        strokeWidth={0.3}
                                    />
                                    <ReferenceLine
                                        y={averageLine || 0}
                                        stroke="#B08C3D"
                                        strokeWidth={0.3}
                                    />
                                </AreaChart>
                            )}
                            {/* </ResponsiveContainer> */}
                            {barChartDetails && barChartDetails?.length > 0 && (
                                <Box className="chart-date-range-container">
                                    <Typography className="date-text">{month?.startDay}</Typography>
                                    <Typography className="date-text">{month?.endDay}</Typography>
                                </Box>
                            )}
                        </Box>
                        <Box className="footer-content">
                            <Box className="count-container">
                                <Box className="meditation-count">
                                    <Typography color="primary" className="card-text dark">
                                        {countDetails?.[monthlyStatsDetailsShowBy]
                                            ?.numberOfMeditations || 0}
                                    </Typography>
                                    <Typography color="primary" className="card-text light">
                                        {monthlyStatsDetailsShowBy === DAILY_AVERAGE
                                            ? 'Meditations/day'
                                            : 'Meditations'}
                                    </Typography>
                                </Box>
                                {/* <Box className="live-session-count">
                                    <Typography color="primary" className="card-text dark">
                                        {countDetails?.[monthlyStatsDetailsShowBy]
                                            ?.liveSessionCount || 0}
                                    </Typography>
                                    <Typography color="primary" className="card-text light">
                                        {monthlyStatsDetailsShowBy === DAILY_AVERAGE
                                            ? 'Live Session/day'
                                            : 'Live Session'}
                                    </Typography>
                                </Box> */}
                            </Box>
                            <ButtonContainerForAverage
                                needToDisplay={windowDimensions?.width > BREAKPOINTS_VALUE.TABLET}
                                monthlyStatsDetailsShowBy={monthlyStatsDetailsShowBy}
                                handleMonthlyStatsDetailsShowBy={handleMonthlyStatsDetailsShowBy}
                            />
                        </Box>
                    </MonthCardWrapper>
                </CardMainWrapper>
            )}
        </>
    );
};

const ButtonContainerForAverage = ({
    monthlyStatsDetailsShowBy,
    handleMonthlyStatsDetailsShowBy,
    needToDisplay
}) => {
    return (
        <Box className={`btn-container ${!needToDisplay && 'hide-view'}`}>
            <Button
                className={`daily-average-btn ${
                    monthlyStatsDetailsShowBy === DAILY_AVERAGE && `selected-btn`
                }`}
                onClick={() => handleMonthlyStatsDetailsShowBy(DAILY_AVERAGE)}>
                <Typography
                    className={`btn-text ${
                        monthlyStatsDetailsShowBy === DAILY_AVERAGE && `selected-text`
                    }`}>
                    DAILY AVERAGE
                </Typography>
            </Button>
            <Button
                className={`monthly-total-btn ${
                    monthlyStatsDetailsShowBy === MONTHLY_AVERAGE && `selected-btn`
                }`}
                onClick={() => handleMonthlyStatsDetailsShowBy(MONTHLY_AVERAGE)}>
                <Typography
                    className={`btn-text ${
                        monthlyStatsDetailsShowBy === MONTHLY_AVERAGE && `selected-text`
                    }`}>
                    MONTHLY TOTAL
                </Typography>
            </Button>
        </Box>
    );
};

// STATISTIC ANALYTICS DURATION
const StatisticAnalyticsDuration = ({
    index,
    duration,
    selected,
    handleDurationChange,
    value,
    ...props
}) => {
    return (
        <Button
            variant="outlined"
            className={`duration-btn ${selected && `selected`}`}
            onClick={() => handleDurationChange(value?.type)}>
            {duration}
        </Button>
    );
};
export default Statistics;
