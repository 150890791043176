import { SubscriptionDarkLock } from 'Components/common/SubscriptionLock';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showSubscriptionModal } from 'Redux/App/Actions';
import { SeeAllTypographyWrapper } from './SeeAllTypography.style';

const SeeAllTypography = ({
    redirectionURL,
    state,
    onClick,
    child,
    reduxAction,
    marginAuto = true,
    disableArrow = false,
    title = 'See All',
    ...props
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <SeeAllTypographyWrapper
                color="primary"
                className="flex f-v-center"
                $spaceAuto={marginAuto}
                classes={{
                    root: 'root'
                }}
                onClick={() => {
                    if (disableArrow) return dispatch(showSubscriptionModal());
                    if (onClick) return onClick();
                    navigate(redirectionURL, {
                        state: {
                            ...state
                        }
                    });
                    if (reduxAction) reduxAction();
                }}>
                {child ? child : <span style={{ opacity: disableArrow ? 0.5 : 1 }}>{title}</span>}
                {!child && !disableArrow && <span className="indicator">›</span>}
            </SeeAllTypographyWrapper>
            {disableArrow && <SubscriptionDarkLock customStyle={{ margin: '0 0 0 5px' }} />}
        </React.Fragment>
    );
};

export default SeeAllTypography;
