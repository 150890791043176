import styled from 'styled-components';
import { Box, Modal } from '@mui/material';
import { responsive, FONTS, COLORS } from 'Styles/Constants';

import AudioSessionBackgroundImage from 'Assets/images/MeditationRoomSession/AudioSession/audio-session-background-image.webp';

export const AudioSessionHostViewWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: start;

    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    background-image: url(${AudioSessionBackgroundImage});
    background-repeat: no-repeat;
    background-size: cover;

    ${responsive.TABLET`
        justify-content: start;
    `}

    .left-side-gradient {
        position: absolute;
        /* width: 956px; */
        width: 908px;
        /* height: 998px; */
        /* height: calc(100vh - 0px); */
        height: 80vh;
        /* left: -130px; */
        left: -200px;
        /* left: 998px; */
        top: 0px;

        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
        transform: rotate(90deg);
    }

    .bottom-side-gradient {
        position: absolute;
        /* width: 1505px; */
        width: 100%;
        /* height: 424px; */
        /* height: 325px; */
        height: calc(100vh - 550px);
        left: 7px;
        /* top: 534px; */
        top: 564px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    }

    .host-and-session-details {
        display: flex;
        flex-direction: column;
        /* width: 480px; */
        gap: 20px;
        margin-left: 150px;
        /* margin-top: 280px; */
        z-index: 100;

        ${responsive.TABLET_800`
            width: 100%;
            margin-left: 0;
            align-items: center;
            margin-top: 50px;
        `}

        ${responsive.MOBILE_376`
            margin-bottom: 50px; 
        `}

        .back-button {
            width: 480px;
            margin-left: 30px;
            margin-bottom: 10px;

            ${responsive.PHABLET_500`
                width: 100%;
            `}
        }

        .session-details-section {
            display: flex;
            flex-direction: row;
            gap: 100px;

            ${responsive.LAPTOP`
                flex-direction: column;
                gap: 30px;
                margin: 0 10px;
            `}

            ${responsive.PHABLET_500`
                width: 100%;
            `}

            .host-details {
                display: flex;
                flex-direction: row;
                gap: 30px;
                width: 480px;

                ${responsive.PHABLET_500`
                    width: 90%;
                    margin-left: 20px;
                    margin-right: 20px;
                `}

                .host-image {
                    border: 2px solid ${COLORS.PRIMARY_DARK};
                    border-radius: 24px;
                }

                .session-details {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    ${responsive.PHABLET_500`
                        gap: 0;
                    `}

                    .session-title {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 600;
                        font-size: 36px;
                        line-height: 36px;
                        text-transform: capitalize;
                        color: ${COLORS.PRIMARY_WARM};

                        ${responsive.PHABLET_500`
                            font-size: 20px;
                        `}
                    }

                    .self-host {
                        display: flex;
                        flex-direction: row;
                        justify-content: start;
                        align-items: center;
                        gap: 5px;

                        .hosting-text {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 26px;
                            color: ${COLORS.PRIMARY_DARK};
                        }
                    }

                    .session-time {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 35px;
                        text-transform: capitalize;
                        color: ${COLORS.PRIMARY_DARK};

                        ${responsive.PHABLET_500`
                            font-size: 18px;
                        `}

                        .session-date {
                            font-weight: 600;
                            margin-right: 10px;
                        }
                    }

                    .session-language {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 26px;
                        text-transform: capitalize;
                        color: ${COLORS.MEDIUM_GREY};
                    }

                    .joined-user-details {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;

                        background-color: ${COLORS.PRIMARY_DARK};
                        border-radius: 18px;
                        width: 180px;
                        height: 36px;
                        padding: 6px 16px 6px 20px;
                        margin-top: 15px;
                        cursor: pointer;

                        .total-count {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 400;
                            font-size: 15.6316px;
                            line-height: 23px;
                            color: #ffffff;
                            mix-blend-mode: normal;
                            border-right: 2px solid #ffffff;
                            padding-right: 10px;
                        }

                        .manage-text {
                            margin-left: 5px;
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 400;
                            font-size: 15.6316px;
                            line-height: 23px;
                            color: #ffffff;
                            mix-blend-mode: normal;
                        }
                    }
                }
            }

            .session-description {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 480px;

                ${responsive.PHABLET_500`
                    width: 90%;
                    margin-left: 20px;
                    margin-right: 20px;
                `}

                .session-breadcrumb {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;

                    .MuiTypography-root,
                    span {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 18px;
                        text-transform: uppercase;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                        color: ${COLORS.PRIMARY_DARK};
                        mix-blend-mode: multiply;
                    }
                }

                .session-description-text {
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: ${COLORS.PRIMARY_WARM};
                }
            }
        }

        .start-session-button {
            width: 480px;
            height: 70px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(20px);
            border-radius: 12px;

            ${responsive.PHABLET_500`
                width: 90%;
                align-self: center;
            `}

            .start-text {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: #ffffff;
            }
        }

        .session-timer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 480px;

            background-color: rgba(255, 255, 255, 0.6);
            border-radius: 15px;
            padding: 20px;

            ${responsive.PHABLET_500`
                border-radius: 0;
                width: 100%;
            `}

            .MuiTypography-root {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 24px;
                line-height: 35px;
                color: ${COLORS.TEXT_DARK};
                text-transform: uppercase;
            }
        }

        .join-now-button {
            height: 50px;
            border-radius: 12px;
            font-family: ${FONTS.PRIMARY};
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            text-align: right;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #ffffff;
            width: 480px;

            ${responsive.PHABLET_500`
                width: 90%;
                align-self: center;
            `}
        }

        .session-waiting-to-start-section {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 480px;

            ${responsive.PHABLET_500`
                width: 90%;
                align-self: center;
            `}

            .MuiButton-root {
                border-radius: 14px;
                padding: 10px 10px 10px 20px;
                background-color: #ffffff;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
            }

            .user-details {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                img {
                    height: 60px;
                    width: 60px;
                    border-radius: 30px;
                }

                .open-chat-button {
                    flex: 80%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    font-family: ${FONTS.PRIMARY};
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 23px;
                    text-align: center;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    color: ${COLORS.PRIMARY_WARM};
                    /* margin-right: 30px; */
                }

                .close-chat-icon {
                    flex: 10;
                }
            }

            .manage-participant-button {
                height: 71px;
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                text-align: center;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: ${COLORS.PRIMARY_WARM};
            }
        }

        .live-session-details {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 15px;

            background-color: ${COLORS.BADGE};
            border-radius: 12px;
            width: 480px;
            height: 71px;

            ${responsive.PHABLET_500`
                width: 90%;
                align-self: center;
            `}

            /* ${responsive.PHABLET_500`
                width: 100%;
                border-radius: 0px;
            `} */

            .heart-wave-animation {
                width: 50px;
                height: 50px;
            }

            .end-session-text {
                font-family: ${FONTS.PRIMARY};
                font-weight: 700;
                font-size: 24px;
                line-height: 35px;
                color: #ffffff;
                margin-left: 20px;
            }
        }

        .actions-section {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            gap: 20px;

            ${responsive.PHABLET_500`
                padding: 0px 30px;
            `}

            .action-icon {
                display: flex;
                align-items: center;
                justify-content: center;

                background-color: #ffffff;
                border: 3px solid ${COLORS.MEDIUM_GREY};
                border-radius: 36px;
                width: 62px;
                height: 62px;
            }
        }
    }

    .session-ended-view {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-left: 150px;
        width: 455px;
        z-index: 100;

        ${responsive.TABLET`
            width: 100%;
            margin-left: 0px;
            margin-top: 50%;
        `}

        .session-title {
            font-family: ${FONTS.PRIMARY};
            font-weight: 500;
            font-size: 30px;
            line-height: 108.1%;
            text-align: center;
            letter-spacing: -0.01em;
            color: ${COLORS.PRIMARY_WARM};
            width: 60%;
        }

        .session-ended-text {
            font-family: ${FONTS.TERTIARY};
            font-weight: 700;
            font-size: 24px;
            line-height: 108.1%;
            color: ${COLORS.TEXT_DARK};
        }

        .schedule-another-session-button {
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
            border-radius: 12px;
            width: 100%;
            height: 60px;

            ${responsive.TABLET`
                width: 90%;
            `}

            .MuiTypography-root {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: #ffffff;
            }
        }

        .close-without-scheduling-button {
            background-color: #ffffff;
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
            border-radius: 12px;
            width: 100%;
            height: 60px;

            ${responsive.TABLET`
                width: 90%;
            `}

            .MuiTypography-root {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: ${COLORS.PRIMARY_WARM};
            }
        }
    }
`;

export const EndSessionModalWrapper = styled(Modal)`
    .MuiBackdrop-root {
        background-color: rgba(255, 255, 255, 0.6);
    }

    .end-session-modal {
        position: absolute;
        top: 42%;
        left: 150px;
        z-index: 100;
        background-color: #ffffff;
        width: 480px;
        height: 265px;
        border-radius: 25px;
        padding: 15px 24px 24px 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(40px);
        gap: 30px;

        ${responsive.DESKTOP`
            left: 13%;
        `}

        ${responsive.LAPTOP`
            left: 15%;
        `}

        ${responsive.LAPTOP_1200`
            left: 16%;
        `}

        ${responsive.LAPTOP_1100`
            left: 17%;
        `}

        ${responsive.LAPTOP_1050`
            left: 18%;
        `}

        ${responsive.TABLET`
            left: unset;
            right: unset;
            top: unset;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: start;
        `}

        .end-session-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .confirmation {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 24px;
                line-height: 108.1%;
                letter-spacing: -0.01em;
                color: ${COLORS.TEXT_DARK};
            }
        }

        .end-session-action-buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .MuiButton-root {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.05em;

                border-radius: 12px;
                background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                color: #ffffff;
                width: 100%;
                height: 50px;
            }

            .no-button {
                background: #ffffff;
                color: ${COLORS.PRIMARY_DARK};
            }
        }
    }
`;

export const FooterContentWrapper = styled(Box)`
    .black-background {
        background-color: #ffffff;
    }
`;
