import styled from 'styled-components';
import { Box } from '@mui/material';
import PageBGImage from 'Assets/images/MeditationRooms/meditation-rooms-my-rooms.png';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const MeditationRoomsWrapper = styled(Box)`
    height: 100%;
    padding: 100px 0 0;
    overflow: auto;

    .flower-image-container {
        position: fixed;
        bottom: 0px;
        width: 100%;
        height: 100%;
        max-height: 60%;
        background-image: url(${PageBGImage});
        background-repeat: no-repeat;
        background-size: cover;
        pointer-events: none;

        ${responsive.TABLET`
            background-image: none;
        `}
    }

    .page-content {
        width: 60%;
        margin: 0 auto;

        ${responsive.DESKTOP`
            width: 70%;
        `}

        .page-title-container {
            padding: 0 25px;

            .title {
                font-size: 40px;
                color: ${COLORS.PRIMARY_DARK};
                text-transform: capitalize;
                font-family: ${FONTS.SECONDARY_BOLD};
                margin-bottom: 8px;
            }

            .filter-container {
                &.screen-view {
                    display: inline-block;
                    margin: 0 0 0 20px;

                    ${responsive.TABLET`
                        display: none;
                    `}
                }

                &.mobile-view {
                    display: none;

                    ${responsive.TABLET`
                        display: inline-block;
                        z-index: 15;
                        width: 80%;
                        height: 60px;
                        bottom: 70px;
                        position: fixed;
                        transform: translate(-50%,0%);
                        left: 50%;
                        background-color: ${COLORS.WHITE};
                    `}

                    ${responsive.PHABLET`
                        width: 100%;
                    `}
                }

                .filter-input {
                    width: 100%;
                    background-color: ${COLORS.LIGHT_GREY_SECONDARY};
                    border: 2px solid ${COLORS.WHITE};
                    border-radius: 35px;
                    height: 50px;
                    padding: 0 20px;
                }

                .search-input-root {
                    color: ${COLORS.TEXT_DARK};
                }

                .input {
                    margin: 0 0 0 5px;
                }
            }

            .top-filter-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .tag-container {
                    width: calc(100% - 35%);

                    ${responsive.TABLET`
                        width: 100%;
                    `}

                    .chip {
                        padding: 10px 8px;
                        height: 46px;
                        border-radius: 22px;
                        text-transform: capitalize;
                        border: 2px solid transparent;
                        font-size: 16px;
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                        color: ${COLORS.MEDIUM_GREY};

                        &.selected-chip {
                            border: 2px solid ${COLORS.PRIMARY_DARK};
                            color: ${COLORS.PRIMARY_DARK};
                            background-color: ${COLORS.WHITE};
                        }
                    }
                }
            }
        }

        .page-content-container {
            position: relative;
            background-color: rgba(255, 255, 255, 0.6);
            background: rgba(255, 255, 255, 0.9);
            margin: 20px 0;
            border-radius: 36px;
            z-index: 5;
            height: 100%;
            min-height: 60vh;

            .border {
                border: 1px solid ${COLORS.PRIMARY};
                border-top-left-radius: 36px;
                border-top-right-radius: 36px;
                border-bottom-color: transparent;
                position: absolute;
                width: 100%;
                height: 100%;
                max-height: 60vh;

                .bottom-blur {
                    position: absolute;
                    width: 100%;
                    height: 120px;
                    background: linear-gradient(
                        180deg,
                        rgba(255, 255, 255, 0.1) 20%,
                        rgba(255, 255, 255, 0.5) 20%,
                        rgba(255, 255, 255, 0.6) 22.03%
                    );
                    bottom: 0;
                    z-index: 10;
                    width: calc(100% + 10px);
                    transform: translate(-50%, 0);
                    left: 50%;
                }
            }
        }
    }
`;
