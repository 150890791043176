import React /* , { useState } */ from 'react';
import { Box, Typography, IconButton, Avatar, Tooltip, Button } from '@mui/material';

import DefaultBackgroundImg from 'Assets/images/Common/session-background-image.png';
import { ReactComponent as HostIcon } from 'Assets/images/Common/hosting.svg';
import { ReactComponent as AddToCalendarIcon } from 'Assets/images/Common/add-to-calendar-toggle.svg';
import { ReactComponent as RemoveFromCalendarIcon } from 'Assets/images/Common/add-to-calendar-toggle.svg';
import { ReactComponent as MarkAsFavIcon } from 'Assets/images/Common/mark-as-favorite.svg';
// import { ReactComponent as ArrowIcon } from "Assets/images/Common/slider-next-button.svg"
import { ReactComponent as TaoCalligraphyTeacherIndicator } from 'Assets/images/Common/tao-calligraphy-teacher-indicator.svg';

// import SessionSignup from './SessionSignup';
import {
    TaoSessionRecoCardWrapper,
    TaoSessionRecoCardMobileWrapper
} from './TaoSessionRecoCard.style';
const TaoSessionRecoCard = ({
    key = '',
    cardRootClass = '',
    isLive = false,
    sessionTime = '',
    sessionTitle = '',
    sessionWith = '',
    isTaoCalligraphyTeacher = '',
    youAreHost = false,
    hostingText = 'You are hosting',
    needSignUp = false,
    // ----Vertical card props only----
    verticalCard = false,
    meditationImage = DefaultBackgroundImg,
    avatarImage = '',
    hasScheduled = false,
    signupClickHandler = () => {},
    ...props
}) => {
    if (verticalCard) {
        return (
            <React.Fragment key={key}>
                <TaoSessionRecoCardWrapper
                    $isLive={isLive}
                    $meditationImage={meditationImage}
                    className={cardRootClass}
                    $isTaoCalligraphyTeacher={isTaoCalligraphyTeacher}>
                    <Box className="card-top">
                        <Avatar className="avatar" width="68px" height="68px" src={avatarImage} />
                        {isTaoCalligraphyTeacher && (
                            <TaoCalligraphyTeacherIndicator className="teacher-icon" />
                        )}
                        {isLive && <Box className="live-bar">LIVE</Box>}
                        <div className="blur" />
                    </Box>
                    <Box className="text-container">
                        <Typography className="session-time">{sessionTime}</Typography>
                        <Tooltip title={sessionTitle}>
                            <Typography className="session-title">{sessionTitle}</Typography>
                        </Tooltip>
                        {<Typography className="session-teacher">{sessionWith}</Typography>}
                    </Box>
                    {!needSignUp && (
                        <Box className="icon-container">
                            <IconButton>
                                {!hasScheduled ? <AddToCalendarIcon /> : <RemoveFromCalendarIcon />}
                            </IconButton>
                            <IconButton>
                                <MarkAsFavIcon />
                            </IconButton>
                        </Box>
                    )}
                    {needSignUp && (
                        <Button
                            variant="contained"
                            color="primary"
                            className="signup-btn"
                            onClick={() => signupClickHandler(sessionTitle, sessionTime)}>
                            Sign up
                        </Button>
                    )}
                </TaoSessionRecoCardWrapper>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment key={key}>
                <TaoSessionRecoCardMobileWrapper className={cardRootClass}>
                    <Box className="upcoming-session-image">
                        <img src={avatarImage} alt="" />
                        {isLive && (
                            <Typography color="primary" className="live-indicator">
                                LIVE
                            </Typography>
                        )}
                    </Box>
                    <Box className="upcoming-session-date-details">
                        <Typography className="session-time">{sessionTime}</Typography>
                        <Typography className="session-title">{sessionTitle}</Typography>
                        {!youAreHost && (
                            <Typography className="session-teacher">{sessionWith}</Typography>
                        )}
                        {youAreHost && (
                            <Box className="host-container">
                                <HostIcon />
                                <Typography color="primary.dark" className="hosting-text">
                                    {hostingText}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Box className="upcoming-session-navigate">
                        {isTaoCalligraphyTeacher && (
                            <TaoCalligraphyTeacherIndicator width="25" height="25" />
                        )}
                        {needSignUp && (
                            <Button
                                variant="contained"
                                color="primary"
                                className="signup-btn"
                                onClick={() => signupClickHandler(sessionTitle, sessionTime)}>
                                Sign up
                            </Button>
                        )}
                        {!needSignUp && (
                            <Box className="icon-container">
                                <IconButton>
                                    {!hasScheduled ? (
                                        <AddToCalendarIcon />
                                    ) : (
                                        <RemoveFromCalendarIcon />
                                    )}
                                </IconButton>
                                <IconButton>
                                    <MarkAsFavIcon />
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                </TaoSessionRecoCardMobileWrapper>
            </React.Fragment>
        );
    }
};

export default TaoSessionRecoCard;
