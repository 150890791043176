import React from 'react';

export const RatingEmoji6 = (props) => {
    return (
        <svg
            {...props}
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10018_18892)">
                <circle
                    cx="25.0977"
                    cy="25.6602"
                    r="24.5"
                    fill={props.fill === true ? '#b08c3e' : 'white'}
                />
                <path
                    d="M17.0806 21.2787C18.5404 21.2787 19.7239 20.0953 19.7239 18.6355C19.7239 17.1756 18.5404 15.9922 17.0806 15.9922C15.6208 15.9922 14.4373 17.1756 14.4373 18.6355C14.4373 20.0953 15.6208 21.2787 17.0806 21.2787Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M33.2694 21.2787C34.7292 21.2787 35.9127 20.0953 35.9127 18.6355C35.9127 17.1756 34.7292 15.9922 33.2694 15.9922C31.8095 15.9922 30.6261 17.1756 30.6261 18.6355C30.6261 20.0953 31.8095 21.2787 33.2694 21.2787Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M33.563 37.5484C33.2359 37.5482 32.9175 37.4426 32.655 37.2475C32.3925 37.0523 32.1997 36.7778 32.1052 36.4647C31.6567 34.9939 30.755 33.7024 29.5287 32.7747C28.2738 31.8299 26.7457 31.3189 25.1749 31.3189C23.6041 31.3189 22.0759 31.8299 20.8211 32.7747C19.595 33.7026 18.6933 34.994 18.2445 36.4647C18.1916 36.6614 18.0998 36.8456 17.9744 37.0063C17.8491 37.1669 17.6927 37.3008 17.5147 37.3999C17.3367 37.4991 17.1406 37.5615 16.938 37.5835C16.7354 37.6055 16.5305 37.5866 16.3353 37.528C16.1402 37.4693 15.9588 37.3722 15.8018 37.2422C15.6449 37.1121 15.5157 36.952 15.4218 36.7711C15.3279 36.5903 15.2712 36.3924 15.2552 36.1893C15.2391 35.9862 15.264 35.7819 15.3283 35.5885C15.9649 33.4991 17.2452 31.6643 18.9866 30.3458C20.77 29.0021 22.9423 28.2754 25.1752 28.2754C27.4082 28.2754 29.5804 29.0021 31.3639 30.3458C33.105 31.6643 34.3851 33.4992 35.0215 35.5885C35.0895 35.8158 35.1035 36.0559 35.0623 36.2896C35.0211 36.5233 34.9259 36.7442 34.7842 36.9345C34.6425 37.1249 34.4584 37.2796 34.2463 37.3861C34.0343 37.4927 33.8003 37.5483 33.563 37.5484Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M25.1749 50.5104C20.1958 50.5104 15.3285 49.0338 11.1885 46.2675C7.04853 43.5012 3.82182 39.5694 1.9164 34.9693C0.0109785 30.3692 -0.487574 25.3074 0.483787 20.4239C1.45515 15.5404 3.8528 11.0547 7.37354 7.53387C12.0948 2.81256 18.4983 0.160156 25.1752 0.160156C31.8522 0.160156 38.2556 2.81256 42.977 7.53387C47.6983 12.2552 50.3507 18.6586 50.3507 25.3356C50.3507 32.0125 47.6983 38.416 42.977 43.1373C40.6445 45.4822 37.8701 47.3413 34.8144 48.6069C31.7587 49.8725 28.4823 50.5195 25.1749 50.5104ZM25.1749 3.20568C20.7981 3.20577 16.5197 4.50372 12.8805 6.9354C9.24141 9.36707 6.40508 12.8233 4.73022 16.8669C3.05535 20.9106 2.61717 25.36 3.47108 29.6527C4.32499 33.9454 6.43265 37.8885 9.52752 40.9833C13.6819 45.1131 19.3042 47.4273 25.162 47.4186C31.0198 47.4099 36.6351 45.079 40.7772 40.9368C44.9192 36.7947 47.25 31.1793 47.2586 25.3215C47.2672 19.4637 44.9529 13.8414 40.823 9.68718C38.7727 7.62591 36.3341 5.99171 33.6481 4.87917C30.9621 3.76662 28.0822 3.19782 25.1749 3.20568Z"
                    fill={props.fill === true ? '#b08c3e' : '#B08C3D'}
                />
            </g>
            <defs>
                <clipPath id="clip0_10018_18892">
                    <rect
                        width="50.3504"
                        height="50.3504"
                        fill="white"
                        transform="translate(0 0.160156)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export const RatingEmoji7 = (props) => {
    return (
        <svg
            {...props}
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10018_18900)">
                <circle
                    cx="25.7986"
                    cy="25.6602"
                    r="24.5"
                    fill={props.fill === true ? '#b08c3e' : 'white'}
                />
                <path
                    d="M17.4315 21.2787C18.8913 21.2787 20.0748 20.0953 20.0748 18.6355C20.0748 17.1756 18.8913 15.9922 17.4315 15.9922C15.9716 15.9922 14.7882 17.1756 14.7882 18.6355C14.7882 20.0953 15.9716 21.2787 17.4315 21.2787Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M33.6203 21.2787C35.0801 21.2787 36.2636 20.0953 36.2636 18.6355C36.2636 17.1756 35.0801 15.9922 33.6203 15.9922C32.1604 15.9922 30.977 17.1756 30.977 18.6355C30.977 20.0953 32.1604 21.2787 33.6203 21.2787Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M34.0694 34.5602C33.671 34.5617 33.2879 34.4062 33.0032 34.1275C31.1835 32.3442 28.4587 31.3212 25.5256 31.3212C22.5925 31.3212 19.8643 32.3442 18.0446 34.1275C17.9034 34.2754 17.7339 34.3933 17.5462 34.4743C17.3584 34.5552 17.1563 34.5975 16.9519 34.5986C16.7474 34.5998 16.5448 34.5597 16.3562 34.4808C16.1676 34.402 15.9968 34.286 15.854 34.1397C15.7112 33.9934 15.5993 33.8198 15.525 33.6293C15.4507 33.4389 15.4156 33.2354 15.4216 33.031C15.4277 32.8267 15.4748 32.6256 15.5603 32.4399C15.6457 32.2542 15.7677 32.0875 15.9189 31.95C18.302 29.612 21.8053 28.2715 25.5289 28.2715C29.2526 28.2715 32.7525 29.612 35.1356 31.95C35.3514 32.1618 35.4993 32.4331 35.5605 32.7292C35.6216 33.0254 35.5932 33.3331 35.4788 33.613C35.3644 33.893 35.1693 34.1326 34.9183 34.3013C34.6673 34.4699 34.3718 34.5601 34.0694 34.5602Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M25.5258 50.5104C20.5467 50.5104 15.6794 49.0338 11.5394 46.2675C7.39943 43.5012 4.17271 39.5694 2.26729 34.9693C0.36187 30.3692 -0.136683 25.3074 0.834678 20.4239C1.80604 15.5404 4.20369 11.0547 7.72443 7.53387C12.4457 2.81256 18.8492 0.160156 25.5261 0.160156C32.2031 0.160156 38.6065 2.81256 43.3278 7.53387C48.0492 12.2552 50.7016 18.6586 50.7016 25.3356C50.7016 32.0125 48.0492 38.416 43.3278 43.1373C40.9954 45.4822 38.221 47.3413 35.1653 48.6069C32.1096 49.8725 28.8332 50.5195 25.5258 50.5104ZM25.5258 3.20568C21.149 3.20564 16.8704 4.50349 13.2312 6.93512C9.59195 9.36674 6.75551 12.8229 5.08056 16.8666C3.40561 20.9103 2.96737 25.3598 3.82126 29.6526C4.67515 33.9453 6.78282 37.8884 9.87774 40.9833C14.0279 45.1334 19.6566 47.4649 25.5258 47.4649C31.395 47.4649 37.0237 45.1334 41.1739 40.9833C45.324 36.8332 47.6555 31.2044 47.6555 25.3352C47.6555 19.4661 45.324 13.8373 41.1739 9.68718C39.1236 7.62591 36.685 5.99171 33.999 4.87917C31.313 3.76662 28.4331 3.19782 25.5258 3.20568Z"
                    fill="#B08C3D"
                />
            </g>
            <defs>
                <clipPath id="clip0_10018_18900">
                    <rect
                        width="50.3504"
                        height="50.3504"
                        fill="white"
                        transform="translate(0.350403 0.160156)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export const RatingEmoji8 = (props) => {
    return (
        <svg
            {...props}
            width="52"
            height="51"
            viewBox="0 0 52 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10018_18908)">
                <circle
                    cx="25.5001"
                    cy="24.6602"
                    r="24.5"
                    fill={props.fill === true ? '#b08c3e' : 'white'}
                />
                <path
                    d="M17.7827 21.2787C19.2425 21.2787 20.426 20.0953 20.426 18.6355C20.426 17.1756 19.2425 15.9922 17.7827 15.9922C16.3228 15.9922 15.1394 17.1756 15.1394 18.6355C15.1394 20.0953 16.3228 21.2787 17.7827 21.2787Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M36.6141 18.6367C36.6142 19.1596 36.4593 19.6707 36.1689 20.1055C35.8786 20.5402 35.4658 20.8791 34.9828 21.0793C34.4998 21.2795 33.9683 21.3319 33.4555 21.23C32.9427 21.1281 32.4717 20.8764 32.102 20.5067C31.7322 20.1371 31.4804 19.6661 31.3784 19.1533C31.2763 18.6406 31.3286 18.1091 31.5287 17.626C31.7287 17.143 32.0675 16.7301 32.5022 16.4397C32.9369 16.1492 33.448 15.9941 33.9708 15.9941C34.6717 15.9941 35.3439 16.2725 35.8396 16.7681C36.3353 17.2637 36.6139 17.9358 36.6141 18.6367Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M34.2645 33.3741H17.4882C17.2856 33.3784 17.0841 33.3421 16.8956 33.2675C16.7071 33.1929 16.5354 33.0814 16.3906 32.9396C16.2457 32.7978 16.1306 32.6284 16.0521 32.4416C15.9735 32.2547 15.933 32.054 15.933 31.8513C15.933 31.6486 15.9735 31.4479 16.0521 31.261C16.1306 31.0741 16.2457 30.9048 16.3906 30.763C16.5354 30.6212 16.7071 30.5097 16.8956 30.435C17.0841 30.3604 17.2856 30.3242 17.4882 30.3285H34.2645C34.4672 30.3242 34.6687 30.3604 34.8572 30.435C35.0457 30.5097 35.2174 30.6212 35.3622 30.763C35.507 30.9048 35.6221 31.0741 35.7007 31.261C35.7792 31.4479 35.8197 31.6486 35.8197 31.8513C35.8197 32.054 35.7792 32.2547 35.7007 32.4416C35.6221 32.6284 35.507 32.7978 35.3622 32.9396C35.2174 33.0814 35.0457 33.1929 34.8572 33.2675C34.6687 33.3421 34.4672 33.3784 34.2645 33.3741Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M25.877 50.5104C20.8978 50.5105 16.0304 49.0341 11.8903 46.2678C7.75027 43.5016 4.52345 39.5698 2.61794 34.9696C0.71244 30.3695 0.213831 25.3076 1.18517 20.4241C2.15651 15.5405 4.55417 11.0547 8.07495 7.53387C10.4127 5.19611 13.188 3.3417 16.2424 2.07652C19.2969 0.811338 22.5706 0.160156 25.8767 0.160156C29.1827 0.160156 32.4565 0.811338 35.5109 2.07652C38.5653 3.3417 41.3406 5.19611 43.6784 7.53387C46.0161 9.87162 47.8705 12.6469 49.1357 15.7014C50.4009 18.7558 51.0521 22.0295 51.0521 25.3356C51.0521 28.6417 50.4009 31.9154 49.1357 34.9698C47.8705 38.0242 46.0161 40.7995 43.6784 43.1373C41.346 45.4822 38.5718 47.3413 35.5162 48.6069C32.4606 49.8725 29.1843 50.5195 25.877 50.5104ZM25.877 3.20568C21.5002 3.20564 17.2216 4.50349 13.5824 6.93512C9.94314 9.36674 7.10671 12.8229 5.43176 16.8666C3.75681 20.9103 3.31857 25.3598 4.17246 29.6526C5.02635 33.9453 7.13402 37.8884 10.2289 40.9833C14.3833 45.1131 20.0056 47.4273 25.8634 47.4186C31.7212 47.4099 37.3366 45.079 41.4786 40.9368C45.6207 36.7947 47.9514 31.1793 47.96 25.3215C47.9686 19.4637 45.6543 13.8414 41.5244 9.68718C39.4743 7.62596 37.0357 5.99179 34.3499 4.87925C31.664 3.7667 28.7842 3.19787 25.877 3.20568Z"
                    fill="#B08C3D"
                />
            </g>
            <defs>
                <clipPath id="clip0_10018_18908">
                    <rect
                        width="50.3511"
                        height="50.3504"
                        fill="white"
                        transform="translate(0.700806 0.160156)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export const RatingEmoji9 = (props) => {
    return (
        <svg
            {...props}
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10018_18916)">
                <circle
                    cx="25.2013"
                    cy="25.6602"
                    r="24.5"
                    fill={props.fill === true ? '#b08c3e' : '#fff'}
                />
                <path
                    d="M17.1329 21.2787C18.5927 21.2787 19.7762 20.0953 19.7762 18.6355C19.7762 17.1756 18.5927 15.9922 17.1329 15.9922C15.6731 15.9922 14.4896 17.1756 14.4896 18.6355C14.4896 20.0953 15.6731 21.2787 17.1329 21.2787Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M35.9643 18.6367C35.9644 19.1596 35.8095 19.6707 35.5192 20.1055C35.2288 20.5402 34.816 20.8791 34.333 21.0793C33.85 21.2795 33.3185 21.3319 32.8058 21.23C32.293 21.1281 31.8219 20.8764 31.4522 20.5067C31.0824 20.1371 30.8306 19.6661 30.7286 19.1533C30.6265 18.6406 30.6788 18.1091 30.8789 17.626C31.0789 17.143 31.4177 16.7301 31.8524 16.4397C32.2871 16.1492 32.7982 15.9941 33.321 15.9941C34.0219 15.9941 34.6942 16.2725 35.1899 16.7681C35.6856 17.2637 35.9641 17.9358 35.9643 18.6367Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M25.2265 34.5611C21.5029 34.5611 17.9996 33.2206 15.6165 30.8826C15.4697 30.7435 15.3521 30.5765 15.2705 30.3915C15.189 30.2064 15.145 30.007 15.1414 29.8048C15.1377 29.6026 15.1743 29.4016 15.2491 29.2137C15.3239 29.0258 15.4354 28.8547 15.577 28.7104C15.7186 28.566 15.8876 28.4513 16.074 28.3729C16.2605 28.2945 16.4607 28.2541 16.6629 28.2539C16.8651 28.2537 17.0654 28.2938 17.252 28.3718C17.4385 28.4499 17.6077 28.5643 17.7496 28.7084C19.5693 30.4917 22.2935 31.5148 25.2265 31.5148C28.1596 31.5148 30.8878 30.4917 32.7075 28.7084C32.9974 28.4336 33.3836 28.2838 33.783 28.291C34.1823 28.2983 34.5628 28.4621 34.8425 28.7472C35.1223 29.0323 35.2788 29.4159 35.2784 29.8153C35.2781 30.2147 35.1208 30.598 34.8406 30.8826C32.4541 33.2199 28.9509 34.5611 25.2265 34.5611Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M25.2265 50.5104C20.2474 50.5104 15.3801 49.0338 11.2401 46.2675C7.10017 43.5012 3.87346 39.5694 1.96804 34.9693C0.0626142 30.3692 -0.435938 25.3074 0.535423 20.4239C1.50678 15.5404 3.90443 11.0547 7.42517 7.53387C12.1465 2.81256 18.5499 0.160156 25.2269 0.160156C31.9038 0.160156 38.3073 2.81256 43.0286 7.53387C47.7499 12.2552 50.4023 18.6586 50.4023 25.3356C50.4023 32.0125 47.7499 38.416 43.0286 43.1373C40.6961 45.4822 37.9218 47.3413 34.8661 48.6069C31.8103 49.8725 28.534 50.5195 25.2265 50.5104ZM25.2265 3.20568C20.8498 3.20577 16.5713 4.50372 12.9322 6.9354C9.29305 9.36707 6.45672 12.8233 4.78185 16.8669C3.10699 20.9106 2.6688 25.36 3.52272 29.6527C4.37663 33.9454 6.48428 37.8885 9.57916 40.9833C13.7335 45.1131 19.3558 47.4273 25.2136 47.4186C31.0714 47.4099 36.6868 45.079 40.8288 40.9368C44.9709 36.7947 47.3016 31.1793 47.3102 25.3215C47.3188 19.4637 45.0045 13.8414 40.8746 9.68718C38.8244 7.62591 36.3857 5.99171 33.6997 4.87917C31.0138 3.76662 28.1338 3.19782 25.2265 3.20568Z"
                    fill="#B08C3D"
                />
            </g>
            <defs>
                <clipPath id="clip0_10018_18916">
                    <rect
                        width="50.3504"
                        height="50.3504"
                        fill="white"
                        transform="translate(0.0518799 0.160156)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export const RatingEmoji10 = (props) => {
    return (
        <svg
            {...props}
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10018_18924)">
                <circle
                    cx="25.9022"
                    cy="25.6602"
                    r="24.5"
                    fill={props.fill === true ? '#b08c3e' : '#fff'}
                />
                <path
                    d="M25.5774 37.5469C23.3432 37.5529 21.1688 36.825 19.3884 35.4752C17.6476 34.1563 16.3676 32.3215 15.7308 30.2324C15.6665 30.039 15.6416 29.8348 15.6577 29.6316C15.6738 29.4285 15.7304 29.2306 15.8243 29.0498C15.9182 28.8689 16.0475 28.7088 16.2044 28.5788C16.3613 28.4488 16.5427 28.3516 16.7378 28.2929C16.933 28.2343 17.1379 28.2154 17.3405 28.2374C17.5431 28.2594 17.7392 28.3218 17.9172 28.421C18.0953 28.5201 18.2516 28.654 18.3769 28.8146C18.5023 28.9753 18.5941 29.1595 18.647 29.3563C19.0958 30.8269 19.9975 32.1183 21.2236 33.0462C22.4785 33.991 24.0066 34.502 25.5774 34.502C27.1482 34.502 28.6763 33.991 29.9312 33.0462C31.1575 32.1185 32.0593 30.827 32.5078 29.3563C32.5607 29.1595 32.6525 28.9753 32.7779 28.8146C32.9032 28.654 33.0595 28.5201 33.2376 28.421C33.4156 28.3218 33.6117 28.2594 33.8143 28.2374C34.0169 28.2154 34.2218 28.2343 34.417 28.2929C34.6121 28.3516 34.7935 28.4488 34.9504 28.5788C35.1073 28.7088 35.2366 28.8689 35.3305 29.0498C35.4244 29.2306 35.481 29.4285 35.4971 29.6316C35.5132 29.8348 35.4883 30.039 35.424 30.2324C34.7875 32.3216 33.5074 34.1565 31.7664 35.4752C29.986 36.825 27.8116 37.5529 25.5774 37.5469Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M17.4831 21.2787C18.943 21.2787 20.1264 20.0953 20.1264 18.6355C20.1264 17.1756 18.943 15.9922 17.4831 15.9922C16.0233 15.9922 14.8398 17.1756 14.8398 18.6355C14.8398 20.0953 16.0233 21.2787 17.4831 21.2787Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M33.672 21.2787C35.1318 21.2787 36.3152 20.0953 36.3152 18.6355C36.3152 17.1756 35.1318 15.9922 33.672 15.9922C32.2121 15.9922 31.0287 17.1756 31.0287 18.6355C31.0287 20.0953 32.2121 21.2787 33.672 21.2787Z"
                    fill={props.fill === true ? '#fff' : '#B08C3D'}
                />
                <path
                    d="M25.5774 50.5104C20.5983 50.5104 15.731 49.0338 11.591 46.2675C7.45106 43.5012 4.22435 39.5694 2.31893 34.9693C0.413505 30.3692 -0.0850468 25.3074 0.886314 20.4239C1.85767 15.5404 4.25532 11.0547 7.77606 7.53387C12.4974 2.81256 18.9008 0.160156 25.5778 0.160156C32.2547 0.160156 38.6582 2.81256 43.3795 7.53387C48.1008 12.2552 50.7532 18.6586 50.7532 25.3356C50.7532 32.0125 48.1008 38.416 43.3795 43.1373C41.047 45.4822 38.2727 47.3413 35.217 48.6069C32.1612 49.8725 28.8849 50.5195 25.5774 50.5104ZM25.5774 3.20568C21.2007 3.20577 16.9222 4.50372 13.2831 6.9354C9.64394 9.36707 6.80761 12.8233 5.13274 16.8669C3.45788 20.9106 3.0197 25.36 3.87361 29.6527C4.72752 33.9454 6.83517 37.8885 9.93005 40.9833C14.0844 45.1131 19.7067 47.4273 25.5645 47.4186C31.4223 47.4099 37.0377 45.079 41.1797 40.9368C45.3218 36.7947 47.6525 31.1793 47.6611 25.3215C47.6697 19.4637 45.3554 13.8414 41.2255 9.68718C39.1753 7.62591 36.7366 5.99171 34.0506 4.87917C31.3647 3.76662 28.4847 3.19782 25.5774 3.20568Z"
                    fill="#B08C3D"
                />
            </g>
            <defs>
                <clipPath id="clip0_10018_18924">
                    <rect
                        width="50.3504"
                        height="50.3504"
                        fill="white"
                        transform="translate(0.402222 0.160156)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
