import { Box } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const ProfileInterestSectionWrapper = styled(Box)`
    gap: 10px;
    border-radius: 30px 30px 0px 0px;
    height: 100%;
    background: ${COLORS.WHITE};
    padding: 100px 0 20px 20px;

    ${responsive.TABLET`
        height: calc(100% - 80px);
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        border-radius: 30px 30px 0px 0px;
        background: #FFFFFF;
        padding: 20px 0 0 20px;
    `}

    ${responsive.PHABLET`
        background: none;
        box-shadow: none;
        border-radius: none;
    `}

    .interest-container {
        padding: 0 20px 0 0;

        .interest-instruction-text {
            color: ${COLORS.TEXT_DARK};
            font-size: 21px;
            margin: 0 0 10px;
        }

        .title {
            margin: 0 0 5px 0;
            font-family: ${FONTS.PRIMARY};
            font-size: 18px;
            text-transform: capitalize;
            color: ${COLORS.MEDIUM_GREY};

            ${responsive.TABLET`
                font-size: 16px;
            `}
        }
        .privacy {
            margin-top: 75px;

            .show-on-public-profile {
                font-family: ${FONTS.PRIMARY};
                font-size: 21px;
                text-transform: capitalize;
                color: ${COLORS.PRIMARY_WARM};

                ${responsive.TABLET`
                    font-size: 18px;
                `}
            }
        }
    }

    .interest-submit-btn {
        margin: 10px 0 0 0;
        padding: 10px 0px;
        width: 100%;
        height: 60px;
        background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
        border-radius: 12px;
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        font-size: 16px;
        line-height: 23px;
        text-transform: uppercase;
        color: ${COLORS.WHITE};
    }
`;
