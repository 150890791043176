import styled from 'styled-components';

import { Box } from '@mui/material';

import { COLORS, FONTS, BREAKPOINTS_VALUE } from 'Styles/Constants';

export const AddUpdateRoomWrapper = styled(Box)`
    padding-top: 140px;
    /* padding-bottom: 100px; */
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
        padding-top: 40px;
        ::-webkit-scrollbar {
            display: none;
        }
    }

    .back-button-container {
        display: none;
        transform: rotate(90deg);
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
            display: flex;
        }
        position: absolute;
        top: 2.1rem;
        left: 1rem;
        z-index: 7;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 52px;
        overflow: hidden;
        border-radius: 50%;
        .arrow-btn {
            width: 100%;
            height: 100%;
            svg {
                width: 23px;
                height: 23px;
                margin-top: 4px;
                path {
                    /* fill: ${COLORS.PRIMARY_DARK}; */
                }
            }
        }
    }

    .page-container {
        width: 80%;
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 12px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
            width: 90%;
            max-width: 900px;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
            width: 100%;
        }
        .page-type-title {
            color: ${COLORS.PRIMARY_DARK};
            font-weight: 700;
            font-size: 40px;
            line-height: 40px;
            margin-bottom: 28px;
            padding-left: 25px;
            font-family: ${FONTS.SECONDARY_SEMI_BOLD};
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_900}px) {
                margin-left: 1.2rem;
            }
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
                margin-left: 2.2rem;
            }
        }
        .room-detail-container {
            box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.15);
            border-radius: 24px;
            padding: 25px 30px;
            width: 100%;
            flex-grow: 1;
            .category-container {
                .title-container {
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    h5 {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 29px;
                        color: ${COLORS.PRIMARY_DARK};
                        font-family: ${FONTS.PRIMARY_MEDIUM};
                    }
                    h6 {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 23px;
                        color: #848484;
                    }
                }
                .category-list {
                    display: flex;
                    gap: 13px;
                    margin-top: 8px;
                    /* justify-content: center; */
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
                        gap: 7px;
                    }
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
                        /* min-width: 455px; */
                        white-space: nowrap;
                        overflow-x: auto;
                        &::-webkit-scrollbar-thumb {
                            visibility: hidden;
                        }
                    }
                    .icon-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        overflow: hidden;
                        cursor: pointer;
                        gap: 11px;
                        -webkit-tap-highlight-color: transparent;
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                            min-width: 51px;
                        }
                            svg {
                                width: 72px;
                                height: 72px;
                                border-radius: 50%;
                                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                                    width: 51px;
                                    height: 51px;
                                }
                                circle {
                                    transition: fill 400ms, filter 400ms;
                                }
                                path {
                                    transition: fill 400ms;
                                }
                                &.selected-category {
                                    circle {
                                        fill: ${COLORS.PRIMARY};
                                        /* filter: drop-shadow(0 0 0.75rem black); */
                                    }
                                    path[fill="#231F20"] {
                                        fill: white;
                                    }
    
                                    path[fill="#B08C3D"] {
                                        filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.65));
                                    }
                                }
                            }
                            p {
                                font-size: 11.5px;
                                line-height: 17px;
                                color: #656565;
                                font-weight: 400;
                                text-align: center;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                -webkit-box-orient: vertical;
                                width: 100%;
                            }
                    }
                }
            }

            .details-container {
                margin-top: 20px;
                .title {
                    color: ${COLORS.PRIMARY_DARK};
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 35px;
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                }
                form {
                    margin-top: 10px;
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
    
                    .text-field {
                        width: 100%;
                        background-color: white;
                        border: 1px solid rgba(132, 132, 132, 0.4);
                        border-radius: 8px;
                        /* padding: 10px; */
                        .filled-input {
                            background-color: transparent;
                        }
                    }
                    .selector {
                        background-color: white;
                        border: 1px solid rgba(132, 132, 132, 0.4);
                        border-radius: 8px;
                        height: 60px;
                        .filled-select {
                            background-color: transparent;
                        }
                        .down-arrow-icon {
                            position: absolute;
                            cursor: pointer;
                            right: 20px;
                        }
                    }

                    .background-select-container {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        .image-list {
                            max-width: 100%;
                            overflow-x: auto;
                            display: flex;
                            gap: 10px;
                            ::-webkit-scrollbar {
                                display: none;
                            }
                            .image-container {
                                width: 130px;
                                height: 208px;
                                border: 1px solid #E8E8E8;
                                border-radius: 12px;
                                background-repeat: no-repeat;
                                background-position: center;
                                /* background-size: cover; */
                                /* transition: border 300ms; */
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                &.selected {
                                    border: 4px solid ${COLORS.PRIMARY_DARK}
                                }
                                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                                    min-width: 127px;
                                }
                            }
                        }
                    }
                    .terms-container {
                        display: flex;
                        flex-direction: column;
                        .admin-approval-txt {
                            color: #D25800;
                            font-size: 14px;
                            line-height: 20px;
                        }
                        .terms-check-container {
                            display: flex;
                            gap: 4px;
                            align-items: center;
                            margin-left: -10px;
                            .text {
                                color: #656565;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 20px;
                                .link {
                                    text-decoration-line: underline;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .btn-container {
                        display: flex;
                        gap: 10px;
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
                            flex-direction: column;
                        }
                        .create-room-btn {
                            width: 255px;
                            @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
                                width: 100%;
                            }
                        }
                        .cancel-room-btn {
                            width: 255px;
                            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
                            background: white;
                            color: black;
                            @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
                                width: 100%;
                            }
                        }
                    }
                    .delete-room-container {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        margin: 30px 0;
                        .icon-btn {
                            /* width: max-content; */
                            .delete-icon {
                                path {
                                    fill: #656565;
                                }
                            }
                        }
                        .delete-text {
                            font-size: 16px;
                            line-height: 23px;
                            text-align: center;
                            color: #656565;
                            font-family: ${FONTS.PRIMARY_MEDIUM};
                        }
                    }
                }
            }

        }
    }

    footer {
        bottom: 0;
    }
`;

