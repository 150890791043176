import { Box, IconButton } from '@mui/material';
import { COLORS, FONTS } from 'Styles/Constants';
import React, { useRef } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import SliderNextButton from 'Assets/images/Common/next-arrow.svg';
import SliderPreviousButton from 'Assets/images/Common/previous-arrow.svg';

export const SlickDotSlider = ({
    arrowsNeeded = false,
    prevArrowPercentage = '-9%',
    nextArrowPercentage = '-9%',
    children,
    ...props
}) => {
    const ref = useRef();

    const SlideNextArrow = ({ currentSlide, slideCount, type, ...props }) => {
        return (
            <React.Fragment>
                <IconButton
                    {...props}
                    className="nextArrow arrow"
                    id="nextArrow"
                    sx={{ height: '23px', width: '14px', right: nextArrowPercentage }}
                    onClick={() => {
                        ref?.current?.slickNext();
                    }}>
                    <img src={SliderNextButton} alt="" />
                </IconButton>
            </React.Fragment>
        );
    };

    const SlidePreviousArrow = ({ currentSlide, slideCount, ...props }) => {
        return (
            <React.Fragment>
                <IconButton
                    {...props}
                    className="prevArrow arrow"
                    id="prevArrow"
                    sx={{
                        height: '23px',
                        width: '14px',
                        left: prevArrowPercentage
                    }}>
                    <img src={SliderPreviousButton} alt="" />
                </IconButton>
            </React.Fragment>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: arrowsNeeded,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SlideNextArrow />,
        prevArrow: <SlidePreviousArrow />
    };

    return (
        <SliderWrapper className="slider-main">
            <Slider {...settings} ref={ref}>
                {children}
            </Slider>
        </SliderWrapper>
    );
};

const SliderWrapper = styled(Box)`
    &.slider-main {
        width: 100%;

        .slide-title {
            font-size: 20px;
            font-weight: 400;
            line-height: 27px;
            text-align: center;
            color: ${COLORS.PRIMARY_DARK};
            font-family: ${FONTS.SECONDARY};
        }

        .slick-slider {
            .slick-list {
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                font-size: 18px;
                line-height: 27px;
                text-align: center;
                color: ${COLORS.TEXT_DARK};
            }

            .slick-dots {
                bottom: -40px;
                li {
                    margin: 0 3px;
                }

                button {
                    display: block;
                    width: 0.75rem;
                    height: 0.75rem;
                    padding: 0;
                    border: none;
                    border-radius: 100%;
                    background-color: #e8e8e8;

                    ::before {
                        content: '';
                    }
                }
            }

            .slick-dots li.slick-active button {
                background-color: #b08c3d;
            }
        }
    }

    .prevArrow {
        display: block;
        position: absolute;
        top: ${(props) => `calc(${props?.cardHeight || 200}px / 2);`};
        height: 40px;
        width: 40px;

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .nextArrow {
        display: block;
        position: absolute;
        top: ${(props) => `calc(${props?.cardHeight || 200}px / 2);`};
        height: 40px;
        width: 40px;

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
`;
