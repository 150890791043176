import React, { Fragment } from 'react';
import { Navigate } from 'react-router-dom';

import { URL_LOGIN } from 'Helpers/Paths';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

function ProtectedRoute(props) {
    const { children } = props;
    const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
    const pathname = window.location.pathname;
    const urlSearchString = window.location.search;

    if (!isLoggedIn && urlSearchString) {
        sessionStorage.setItem('redirectionUrl', pathname + urlSearchString);
    }

    return (
        <Box style={{ height: 'inherit' }}>
            {!isLoggedIn ? <Navigate replace to={URL_LOGIN} /> : <Fragment>{children}</Fragment>}
        </Box>
    );
}

export default ProtectedRoute;
