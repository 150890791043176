import React from 'react';

import { RoomStatusModalWrapper } from './MyRoom.style';

import { Box, Typography, Backdrop, Slide, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { TaoButton } from 'Components/common/Button.style';

const PendingApprovedModal = ({ open = false, roomDetails = { title: "" }, onClose = () => { }, ...props }) => {
    return (
        <RoomStatusModalWrapper
            // closeAfterTransition
            open={open}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 250,
            }}
            onClose={onClose}
        >
            <Slide timeout={250} in={open} direction="up" >
                <Box className="dialog-container">
                    <IconButton className="close-btn" onClick={onClose}><CloseIcon /></IconButton>
                    <Box className="content-container">
                        <Typography className="title">{roomDetails.title}</Typography>
                        <Box className="request-text-container">
                            <Typography variant="h6">We’ve received your request.</Typography>
                            <Typography variant="h6">&nbsp;</Typography>
                            <Typography variant="h6">Your room will be reviewed by our team before it can be approved.</Typography>
                            <Typography variant="h6">&nbsp;</Typography>
                            <Typography variant="h6">Thank you for your heart of service to the community!</Typography>
                        </Box>
                        <TaoButton className="tao-back-btn" onClick={onClose}>Back to Rooms</TaoButton>
                    </Box>
                </Box>
            </Slide>
        </RoomStatusModalWrapper>
    );
};

export default PendingApprovedModal;