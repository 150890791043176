import styled from 'styled-components';
import { Box } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const PlayMeditationWrapper = styled(Box)`
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;

    .full-width-section {
        height: 100%;

        .play-meditation-content {
            .slider-container {
                display: flex;
                flex-direction: column;
                margin: 0 auto;
                flex-wrap: wrap;
                width: 50%;

                ${responsive.DESKTOP`
                    width: 60%;
                `}

                ${responsive.LAPTOP`
                    width: 80%;
                `}
                ${responsive.TABLET`
                    width: 90%;
                `}

                .titleWrapper {
                    display: flex;
                    margin-left: 10px;
                    align-items: center;

                    .title {
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                        font-size: 24px;
                        color: ${COLORS.PRIMARY};
                        text-transform: capitalize;
                        margin-right: 15px;
                    }
                    .navigation {
                        color: ${COLORS.MEDIUM_GREY};
                        font-size: 14px;
                        display: flex;
                        justify-content: space-around;
                        .link {
                            padding-left: 5px;
                            color: ${COLORS.MEDIUM_GREY};
                            cursor: pointer;
                            font-style: italic;
                        }
                    }
                }
                .energy-playlist-container {
                    margin-top: 20px;
                    margin-bottom: 100px;
                    width: 100%;

                    .selected-card-bg {
                        background: #8484841a;
                    }
                    .slider-card {
                        border-radius: 18px;
                        width: 383px;
                        height: 106px;
                        border: 1px solid ${COLORS.LIGHT_GREY};
                        box-shadow: none;
                        padding: 16px;
                        display: flex;
                        align-items: center;
                        .card-content {
                            display: flex;
                            padding: 0;
                            margin: 0;
                            align-items: center;
                            .card-img {
                                display: flex;
                                position: absolute;
                                justify-content: center;
                                align-items: center;
                                /* border-radius: 20px 5px;
                                width: 123px;
                                height: 68px; */
                            }
                            .play-icon {
                                position: absolute;
                                margin: -90px 75px;
                            }
                            .card-details {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                width: 100%;
                                max-width: 210px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                .card-heading {
                                    color: ${COLORS.BLACK};
                                    font-weight: 500;
                                    font-size: 18px;
                                    line-height: 27px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    width: 100%;
                                }
                                .media-target {
                                    color: ${COLORS.MEDIUM_GREY};
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 18px;
                                }
                                .media-time {
                                    color: ${COLORS.MEDIUM_GREY};
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 18px;
                                }
                                .media-text {
                                    color: ${COLORS.MEDIUM_GREY};
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 18px;
                                }
                            }
                        }
                    }
                    /* } */
                }
            }
        }
    }
    .tablet-playlist-view {
        padding: 10px;

        .selected-card-bg {
            background: #8484841a;
        }
        .playlist-card {
            padding: 10px 0;
            gap: 10px;
            .img-container {
                position: relative;
                .image {
                    border-radius: 20px 5px 20px 5px;
                    width: 130px;
                    height: 75px;
                }
                .play-button {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .text {
                width: 100%;
                .title {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: ${COLORS.PRIMARY_WARM};
                    width: 100%;
                    white-space: pre-wrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                .media-path {
                    .sub-title {
                        white-space: pre-wrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }

                .media-path,
                .time {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: ${COLORS.MEDIUM_GREY};
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .load-more {
            padding: 20px 0;
            width: max-content;
            margin: 0 auto;

            &:hover {
                .load-more-arrow {
                    transition: all 0.3s ease-in-out;
                    margin: 5px 0 0;
                }
            }

            .load-more-text {
                color: ${COLORS.PRIMARY};
            }
            .load-more-arrow {
                width: 10px;
                height: 10px;
                border-right: 1px solid ${COLORS.PRIMARY};
                border-bottom: 1px solid ${COLORS.PRIMARY};
                transform: rotate(45deg);
            }
        }
    }
`;
