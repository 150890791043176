import { Chip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { FieldHealingWrapper } from "./FieldHealing.style"
import { MaleSVG, FemaleSVG } from "./BodySVG";
import { ReactComponent as BodyChannelMale } from "Assets/images/FieldHealing/body-channel-male.svg";
import { ReactComponent as BodyChannelFemale } from "Assets/images/FieldHealing/body-channel-female.svg";
import Slider from "react-slick";
import { ReactComponent as DownArrow } from "Assets/images/Common/down-arrow.svg";
import { ReactComponent as UpArrow } from "Assets/images/Notifications/up-arrow.svg";
import { ReactComponent as LeftArrow } from "Assets/images/Common/gray-left-arrow.svg";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { BREAKPOINTS_VALUE } from "Styles/Constants";
import { URL_BODY_ORGANS_DETAILS } from "Helpers/Paths";
import { useNavigate } from "react-router-dom";
import { ReactComponent as FemaleBodyLined } from "Assets/images/FieldHealing/female-body-lined.svg";
import { ReactComponent as MaleBodyLined } from "Assets/images/FieldHealing/male-body-lined.svg";
import { ReactComponent as FemaleBodyNaming } from "Assets/images/FieldHealing/female-body-naming-2.svg";
import { ReactComponent as MaleBodyNaming } from "Assets/images/FieldHealing/male-body-naming-2.svg";

import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';

import { getWindowDimensions } from 'Helpers';

let body = null;

const FieldHealing = (props) => {
    const [healingChips] = useState(BUTTON_LIST);
    const [selectedChip, setSelectedChip] = useState({ key: 1, value: "physical body", selectionCount: '16' },);
    const [selectedGender, setSelectedGender] = useState('male');
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const navigate = useNavigate();

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    useEffect(() => {
        function handleClick(e) {
            getClickedId(e.target.id)
        }

        if (selectedChip.key === 1) {
            // this will remove listner of previous gender before creadting new event listner
            body && body.removeEventListener('click', handleClick);

            body = document.getElementById("body")

            body.addEventListener('click', handleClick)
        }


        return () => body && body.removeEventListener('click', handleClick);
    }, [selectedGender, selectedChip])

    const getClickedId = (id) => {
        switch (id) {
            case 'Mouth_Lips_Teeth_Gums':
                alert("Mouth");
                break;
            case 'Neck':
                alert("Neck");
                break;
            case 'Shoulders':
                alert("Shoulders");
                break;
            case 'Elbows':
                alert("Elbows");
                break;
            case 'Wrists':
                alert("Wrists");
                break;
            case 'Hands':
                alert("Hands");
                break;
            case 'Knees':
                alert("Knees");
                break;
            case 'Ankles':
                alert("Ankles");
                break;
            case 'Feet':
                alert("Feet");
                break;
            case 'Hipbones':
                alert("Hipbones");
                break;
            case 'Muscles':
                alert("Muscles");
                break;
            case 'Nose':
                alert("Nose");
                break;
            case 'Ears':
                alert("Ears");
                break;
            case 'Eyes':
                alert("Eyes");
                break;
            case 'Lower_Back':
                alert("Lower back");
                break;
            default:
                alert("Body");
        }
    }

    const SLIDER_SETTINGS = {
        arrows: false,
        infinite: false,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 8,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: BREAKPOINTS_VALUE.PHABLET,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 2,
                }
            }
        ]
    };

    let selectionItemSlider = useRef(null);

    const onSliderChange = (newIndex) => {
        setSelectedChip(healingChips[newIndex])
    };

    let BODY_SLIDER_SETTINGS = {
        // arrows: false,
        // autoplay: false,-
        // autoplaySpeed: 3000,
        // dots: false,
        // infinite: false,
        // speed: 500,
        // slidesToShow: 3,
        // slidesToScroll: 1,

        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        centerPadding: '60px',
        // edgeFriction: 1,
        infinite: false,
        swipeToSlide: false,
        variableWidth: true,
        accessibility: true,
        focusOnSelect: false,
        draggable: false,
        afterChange: onSliderChange,
        responsive: [
            {
                breakpoint: BREAKPOINTS_VALUE.DESKTOP,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: BREAKPOINTS_VALUE.LAPTOP,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: BREAKPOINTS_VALUE.TABLET,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: BREAKPOINTS_VALUE.PHABLET,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: BREAKPOINTS_VALUE.MOBILE,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    centerPadding: "30px",
                }
            }
        ]
    };



    return (
        <FieldHealingWrapper>
            <TaoCalligraphyWatermark />
            <Box className="container">
                <Box className="left-side">
                    <Box className="flex f-v-center">
                        <Box className="leftArrow">
                            <LeftArrow />
                        </Box>
                        <Box>
                            <Typography className="title">
                                Field Healing
                            </Typography>
                        </Box>
                    </Box>
                    <Typography className="description">
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </Typography>

                    {
                        windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET && (
                            <div className="slider-content" style={{ marginTop: '10px' }}>
                                <Slider {...BODY_SLIDER_SETTINGS}>
                                    {healingChips.map(chip => (
                                        <Chip
                                            sx={{ color: 'text.dark' }}
                                            onClick={() => setSelectedChip(chip)}
                                            key={chip.key}
                                            className={"goal-chip " + (chip.key === selectedChip.key ? "selected-goal" : 'non-selected-goal')}
                                            size="medium"
                                            color="primary"
                                            variant={chip.key === selectedChip.key ? "filled" : 'outlined'}
                                            label={chip.value}
                                        />
                                    ))}
                                </Slider>
                            </div>
                        )
                    }

                    <Box className="chips-div">
                        {healingChips.map(chip => (
                            <Chip
                                sx={{ color: 'text.dark' }}
                                onClick={() => setSelectedChip(chip)}
                                key={chip.key}
                                className={"goal-chip " + (chip.key === selectedChip.key ? "selected-goal" : 'non-selected-goal')}
                                size="medium"
                                color="primary"
                                variant={chip.key === selectedChip.key ? "filled" : 'outlined'}
                                label={chip.value}
                            />
                        ))}
                    </Box>

                </Box>

                <Box className="right-side">
                    <Box>
                        <Box className="flex f-h-center selection-count">
                            {selectedChip.selectionCount} selections
                        </Box>

                        {selectedChip.key === 1 ? (
                            <>
                                {selectedGender === "male" ? (
                                    <MaleBodyNaming
                                        width={windowDimensions?.width <= BREAKPOINTS_VALUE.PHABLET ? "337" : "415"}
                                        height={windowDimensions?.width <= BREAKPOINTS_VALUE.PHABLET ? "500" : "597"}
                                    />
                                ) : (
                                    <FemaleBodyNaming
                                        width={windowDimensions?.width <= BREAKPOINTS_VALUE.PHABLET ? "337" : "415"}
                                        height={windowDimensions?.width <= BREAKPOINTS_VALUE.PHABLET ? "500" : "597"}
                                    />
                                )}

                            </>
                        ) : (
                            <>
                                {selectedChip.key === 5 ? (
                                    <>
                                        {
                                            selectedGender === "male" ? (
                                                <BodyChannelMale
                                                    width={windowDimensions?.width <= BREAKPOINTS_VALUE.PHABLET ? "440" : "470"}
                                                    height={windowDimensions?.width <= BREAKPOINTS_VALUE.PHABLET ? "400" : "530"}
                                                />
                                            ) : (
                                                <BodyChannelFemale
                                                    width={windowDimensions?.width <= BREAKPOINTS_VALUE.PHABLET ? "440" : "470"}
                                                    height={windowDimensions?.width <= BREAKPOINTS_VALUE.PHABLET ? "400" : "530"}
                                                />
                                            )
                                        }
                                    </>
                                ) : (
                                    <>
                                        {
                                            selectedGender === "male" ? (
                                                <>
                                                    <MaleBodyLined
                                                        width={windowDimensions?.width <= 768 ? windowDimensions?.width <= BREAKPOINTS_VALUE.PHABLET ? "337" : "380" : "500"}
                                                        height={windowDimensions?.width <= BREAKPOINTS_VALUE.PHABLET ? "500" : "590"}
                                                    />

                                                </>
                                            ) : (
                                                <FemaleBodyLined
                                                    width={windowDimensions?.width <= 768 ? windowDimensions?.width <= BREAKPOINTS_VALUE.PHABLET ? "337" : "380" : "500"}
                                                    height={windowDimensions?.width <= BREAKPOINTS_VALUE.PHABLET ? "500" : "590"}
                                                />
                                            )
                                        }
                                    </>
                                )}
                            </>
                        )}

                        <Box className={`flex f-h-center ${selectedChip.key === 1 && 'svg-div'}`}>
                            <Box onClick={() => setSelectedGender('male')}>
                                <MaleSVG isSelected={selectedGender === 'male'} />
                            </Box>
                            <Box style={{ marginLeft: "10px" }} onClick={() => setSelectedGender('female')}>
                                <FemaleSVG isSelected={selectedGender === 'female'} />
                            </Box>
                        </Box>

                    </Box>

                    {![1, 5].includes(selectedChip.key) && selectedChip?.children?.length && (
                        <Box className="selection-container f-v-center">
                            {
                                selectedChip.key === 2 ? (
                                    <>
                                        <Box onClick={() => selectionItemSlider.current.slickPrev()}>
                                            <UpArrow />
                                        </Box>
                                        <Slider className="flex flex-h-center selection-div" ref={selectionItemSlider} {...SLIDER_SETTINGS}>
                                            {
                                                selectedChip.children.map(ele => (
                                                    // eslint-disable-next-line
                                                    <a onClick={() => ele.redirectTo && navigate(ele.redirectTo)} className="selection-item">{ele.name}</a>
                                                ))
                                            }
                                        </Slider>
                                        <Box onClick={() => selectionItemSlider.current.slickNext()}>
                                            <DownArrow />
                                        </Box>
                                    </>
                                ) : (
                                    <Box className="flex flex-h-center selection-div p-t" >
                                        {
                                            selectedChip.children.map(ele => (
                                                // eslint-disable-next-line
                                                <a className="selection-item">{ele.name}</a>
                                            ))
                                        }
                                    </Box>
                                )
                            }
                        </Box>
                    )}

                </Box>
            </Box>
        </FieldHealingWrapper>
    )
}


export default FieldHealing

const BUTTON_LIST = [
    { key: 1, value: "physical body", selectionCount: '16' },
    { key: 2, value: "internal organs", selectionCount: '22', children: [{ name: 'Adrenal Glands' }, { name: 'Blood' }, { name: 'Blood Vessels' }, { name: 'Bones' }, { name: 'Brain' }, { name: 'Gallbladder' }, { name: 'Heart', redirectTo: URL_BODY_ORGANS_DETAILS }, { name: 'Kidney' }, { name: 'Large Intestine' }, { name: 'Liver' }, { name: 'Lungs' }, { name: 'Pancreas' }] },
    { key: 3, value: "emotions", selectionCount: '7', children: [{ name: 'Happiness' }, { name: 'Healing Anger' }, { name: 'Healing Depression & Anxiety' }, { name: 'Healing Worry' }, { name: 'Healing Sadness' }, { name: 'Healing Fear' }, { name: 'Healing Addiction' }] },
    { key: 4, value: "relationships", selectionCount: '9', children: [{ name: 'True Love' }, { name: 'Self Love' }, { name: 'Parents' }, { name: 'Children' }, { name: 'Grandparents' }, { name: 'Siblings' }, { name: 'Pets' }, { name: 'Friends' }, { name: 'Colleagues' }] },
    { key: 5, value: "body channels", selectionCount: '3' },
]