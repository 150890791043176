import styled from 'styled-components';

import { BREAKPOINTS_VALUE, COLORS, FONTS } from 'Styles/Constants';

import { Modal } from '@mui/material';

export const ModalWrapper = styled(Modal)`
    &.MuiModal-root {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .MuiBackdrop-root {
        background-color: rgba(255, 255, 255, 0.4);
    }
    .dialog-container {
        width: 70%;
        position: relative;
        max-width: 916px;
        min-height: 55%;
        padding-bottom: 1rem;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
        border-radius: 24px;
        z-index: 20;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
            width: 85%;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
            width: 95%;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE}px) {
            width: 100%;
        }
        .close-btn {
            right: 35px;
            top: 30px;
            position: absolute;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                right: 6px;
                top: 20px;
            }
        }
        .content-container {
            width: 60%;
            max-width: 370px;
            min-width: 325px;
            height: 80%;
            display: flex;
            flex-direction: column;
            padding: 0 8px;
            /* @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE_376}px) {
            } */
            .title-container {
                display: flex;
                gap: 1rem;
                justify-content: center;
                &.report-title {
                    flex-direction: column;
                    align-items: center;

                    .inappropriate-content {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 26px;
                        color: ${COLORS.PRIMARY_DARK};
                        font-family: ${FONTS.PRIMARY_MEDIUM};
                    }
                }
                .icon {
                }
                .title {
                    color: #31290E;
                    letter-spacing: -0.01em;
                    font-weight: 500;
                    font-size: 30px;
                    line-height: 30px;
                    max-width: 220px;
                }
            }

            form {
                margin-top: 20px;
                display: flex;
                .invite-text {
                    text-align: center;
                    color: #31290E;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 26px;
                }
                .text-field {
                    width: 100%;
                    background-color: white;
                    min-height: 60px;
                    border: 1px solid rgba(132, 132, 132, 0.4);
                    border-radius: 8px;
                    .filled-input {
                        background-color: transparent;
                    }
                }
                .invite-btn {
                    margin-top: 20px;
                }
            }
            .successful-invite-container {
                display: flex;
                flex-direction: column;
                .success {
                    text-align: center;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    margin-top: 1rem;
                    color: #31290E;
                }
                .email {
                    text-align: center;
                    color: #31290E;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    font-family: ${FONTS.TERTIARY};
                }
                .btn-container {
                    margin-top: 50px;
                    display: flex;
                    justify-content: space-between;
                    .send-btn {
                        width: 45%;
                    }
                    .modal-close-btn {
                        width: 45%;
                        background: transparent;
                        color: ${COLORS.PRIMARY};
                    }
                }
            }
            .received-report-container {
                .acknowledge-text {
                    color: #31290E;
                    /* font-weight: 500; */
                    font-size: 18px;
                    line-height: 26px;
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                }
                .home-btn {
                    margin-top: 26px;
                }
            }


        }
    }
`;