import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    MeditationCardContainer,
    MeditationCardWrapper,
    PopoverMeditationTimerWrapper
} from './MeditationTimer.style';
import { ReactComponent as Dots } from 'Assets/images/MeditationTimer/dots.svg';
import { Typography, Box } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'Assets/images/MeditationTimer/delete-icon.svg';
import { ReactComponent as CloneIcon } from 'Assets/images/MeditationTimer/clone.svg';
import { ReactComponent as EditIcon } from 'Assets/images/MeditationTimer/edit.svg';
import { ReactComponent as PlusIcon } from 'Assets/images/MeditationTimer/plus.svg';
import { ReactComponent as CrossIcon } from 'Assets/images/MeditationTimer/cross-icon.svg';
import { COLORS, FONTS } from 'Styles/Constants';
import { useEffect } from 'react';
import { convertMinsToHrsMins } from 'Helpers';
import { SubscriptionDarkLock, SubscriptionLightLock } from '../../common/SubscriptionLock';
import usePermissions from '../../common/Permissions';
import { ACCESS_LEVELS_MODULEs } from 'Helpers/Constants';
import { showSubscriptionModal } from 'Redux/App/Actions';

let TEXT_STYLE = {
    fontSize: '14px',
    fontFamily: `${FONTS.PRIMARY}`,
    color: `${COLORS.GREY_TEXT_COLOR}`,
    margin: '0 0 0 10px'
};

const MeditationCard = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();

    const createMeditationTimerPermission = usePermissions(
        ACCESS_LEVELS_MODULEs?.CREATE_MEDITATION_TIMER
    );

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        props?.handlePopper(props?.index);
    };

    const edit = () => {
        if (!createMeditationTimerPermission?.canAccess) return dispatch(showSubscriptionModal());

        props.editMeditationTimer(props.meditation.meditationId);

        if (props?.cardEdit) {
            props.cardEdit(props.meditation.meditationId);
        }
        props?.handlePopper(props?.index);
    };

    useEffect(() => {
        if (!props?.selectedMeditationCard?.meditationId) setAnchorEl(null);
    }, [props?.selectedMeditationCard?.meditationId]);

    return (
        <MeditationCardWrapper>
            <PopoverMeditationTimerWrapper
                open={props?.openPopper}
                anchorEl={anchorEl}
                onClose={() => props?.handlePopper(props?.index)}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                classes={{ paper: 'popover-paper' }}>
                <Box
                    style={{
                        width: '130px',
                        padding: '10px',
                        gap: '10px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    className="flex f-column">
                    <Box onClick={edit} className="flex f-v-center hover">
                        <EditIcon />

                        <Typography style={TEXT_STYLE}>Edit</Typography>
                        {!createMeditationTimerPermission?.canAccess && (
                            <SubscriptionDarkLock customStyle={{ margin: '0 0 0 auto' }} />
                        )}
                    </Box>

                    <Box
                        onClick={() => {
                            createMeditationTimerPermission?.canAccess
                                ? props.cloneMeditationTimer(props?.meditation?.meditationId)
                                : dispatch(showSubscriptionModal());
                        }}
                        className="flex f-v-center hover">
                        <CloneIcon />
                        <Typography style={TEXT_STYLE}>Clone</Typography>
                        {!createMeditationTimerPermission?.canAccess && (
                            <SubscriptionDarkLock customStyle={{ margin: '0 0 0 auto' }} />
                        )}
                    </Box>

                    <Box
                        onClick={() => {
                            props.deleteMeditationTimer(props?.meditation?.meditationId);
                        }}
                        className="flex hover">
                        <DeleteIcon />

                        <Typography style={TEXT_STYLE}>Delete</Typography>
                    </Box>
                </Box>
            </PopoverMeditationTimerWrapper>

            <MeditationCardContainer
                onClick={() => {
                    if (!createMeditationTimerPermission?.canAccess && !props?.meditation?.name) {
                        return dispatch(showSubscriptionModal());
                    }
                    props.updateSelectedMeditationCard(props.meditation);
                }}
                meditation={props?.meditation}
                isHighlightedBackground={props.isHighlightedBackground}
                className={`meditationCard ${
                    props?.selectedMeditationCard?.meditationId ===
                        props?.meditation?.meditationId && 'meditationCardBorder'
                }`}
                style={{
                    backgroundImage: `url(${props?.meditation?.backgroundImage?.backgroundImageOriginal})`
                }}>
                {props?.meditation?.name ? (
                    <>
                        <Box className={`dots index${props?.index}`}>
                            <Dots onClick={handleClick} />
                        </Box>

                        <Box className="flex f-h-center">
                            <Box className="time flex f-v-center f-h-center">
                                <Typography className="timer-fonts">
                                    {convertMinsToHrsMins(props?.meditation?.meditationTime, true)}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                ) : (
                    <>
                        {props.isHighlightedBackground && (
                            <>
                                <Box
                                    className="flex f-h-center f-v-center"
                                    style={{
                                        height: '100%',
                                        opacity: createMeditationTimerPermission?.canAccess
                                            ? 1
                                            : 0.5
                                    }}>
                                    {props?.selectedMeditationCard?.meditationId ===
                                    props?.meditation?.meditationId ? (
                                        <CrossIcon />
                                    ) : (
                                        <PlusIcon />
                                    )}
                                </Box>
                            </>
                        )}
                    </>
                )}
                {createMeditationTimerPermission?.permissionLoaded &&
                    !createMeditationTimerPermission?.canAccess &&
                    !props?.meditation?.name && (
                        <Box className="timer-lock">
                            <SubscriptionLightLock />
                        </Box>
                    )}
            </MeditationCardContainer>

            <Typography className="description">{props?.meditation?.name || 'Add New'}</Typography>
        </MeditationCardWrapper>
    );
};

export default MeditationCard;
