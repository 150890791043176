import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { FieldHealingPlayerWrapper } from "./FieldHealing.style";
// import { ReactComponent as PlayerImage } from "Assets/images/FieldHealing/player_image.svg";
import { Button, Slide, Slider, Typography } from "@mui/material";
import { ReactComponent as PreviousSVG } from "Assets/images/FieldHealing/previousSVG.svg";
import { ReactComponent as ForwardSVG } from "Assets/images/FieldHealing/forwardSVG.svg";
import { ReactComponent as PauseSVG } from "Assets/images/FieldHealing/pause.svg";
import { ReactComponent as PlaySVG } from "Assets/images/FieldHealing/play.svg";
import SubtitleFile from "Assets/Subtitles/FieldHealing/subtitle.srt";
import { ReactComponent as Subtitle } from "Assets/images/FieldHealing/subtitle.svg";
import { ReactComponent as FullScreenSVG } from "Assets/images/FieldHealing/full-screen.svg";
import { ReactComponent as ClearNotificationSVG } from "Assets/images/Notifications/clear-notification-icon.svg";
import ReactPlayer from 'react-player';
import { floor } from "lodash";
import { TaoButton } from "Components/common/Button.style";
import { ReactComponent as LeftArrow } from "Assets/images/Common/gray-left-arrow.svg";
import { ReactComponent as RepeatSVG } from "Assets/images/FieldHealing/repeat.svg";
import { BREAKPOINTS_VALUE } from "Styles/Constants";
// import Thumbnail from "Assets/images/FieldHealing/thumbnail.png";
import ReactFullscreen from 'react-easyfullscreen';
import axios from 'axios';

import { getWindowDimensions } from 'Helpers';

let SEEK_SECONDS = 15;

const FieldHealingPlayer = (props) => {
    const playerRef = useRef(null);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [videoPlay, setVideoPlay] = useState(false);
    const [duration, setDuration] = useState('00:00');
    const [elapsedTime, setElapsedTime] = useState('00:00');
    const [videoLengthInSeconds, setVideoLengthInSeconds] = useState(0)
    const [showSubtitle, setShowSubtitle] = useState(false);
    const [boostHealingPopup, setBoostHealingPopup] = useState(false);
    const [elapsedTimeInSeconds, setElapsedTimeInSeconds] = useState(0);
    const [selectedStep, setSelectedStep] = useState(1);
    const [isVideoFullScreen, setIsVideoFullScreen] = useState(false);
    const [Link, setLink] = useState('')
    let containerRef = useRef(null)

    useEffect(() => {
        // TODO: code to hide topbar & sidebar

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [])

    useEffect(() => {
        if (videoPlay) {
            var interval = setInterval(() => {
                let time = getTotalTimeInMins(playerRef.current.getCurrentTime())
                setElapsedTimeInSeconds(playerRef.current.getCurrentTime());
                setElapsedTime(time)
            }, 1000);
            return;
        }

        clearInterval(interval);

        return () => clearInterval(interval)
        // eslint-disable-next-line
    }, [videoPlay])

    useEffect(() => {
        getVideoUrl()
    }, [])

    const getTotalTimeInMins = (seconds) => {
        let duration = seconds;
        if (duration) {
            let min = floor(duration / 60);
            let sec = 0;
            min < 1 ? sec = duration : sec = duration - (min * 60);

            return numberFormat(min) + ":" + numberFormat(floor(sec));
        }

        return "00:00"
    }

    const numberFormat = (number) => {
        return number.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })
    }

    const getVideoUrl = () => {
        axios.get(
            "https://player.vimeo.com/video/339349131/config", {
            "Content-Type": "application/json"
        })
            .then((response) => {
                if (response?.data?.request?.files?.progressive.length) {
                    console.log("response?.request?.files?.progressive[0].url ", response?.data?.request?.files?.progressive[0].url)
                    setLink(response?.data?.request?.files?.progressive[0].url)
                }
            }).catch(error => {
                console.log("🚀 ~ file: FieldHealingPlayer.jsx ~ line 108 ~ .then ~ error", error)
            })
    }

    return (
        <FieldHealingPlayerWrapper screen={windowDimensions}>
            <Box className="container" ref={containerRef}>
                <Box className="left-arrow">
                    <LeftArrow />
                </Box>
                <ReactFullscreen>
                    {({ ref, onRequest, onExit }) => (
                        <div ref={ref}>
                            {
                                // !window.screenTop && !window.screenY
                                document.fullscreen && (
                                    <Box className="player-custom-controller">
                                        <Box className="player-buttons flex f-v-center f-h-center ">
                                            <Box className="previous">
                                                <PreviousSVG
                                                    onClick={() => {
                                                        playerRef?.current && playerRef.current.seekTo(playerRef.current.getCurrentTime() - 15, 'seconds')
                                                        setElapsedTimeInSeconds(playerRef.current.getCurrentTime() - SEEK_SECONDS)
                                                    }}
                                                />
                                            </Box>

                                            <Box className="pause">
                                                {
                                                    !videoPlay ? (
                                                        <PlaySVG onClick={() => setVideoPlay(prevState => !prevState)} />
                                                    ) : (
                                                        <PauseSVG onClick={() => setVideoPlay(prevState => !prevState)} />
                                                    )
                                                }

                                            </Box>

                                            <Box className="forward">
                                                <ForwardSVG
                                                    onClick={() => {
                                                        playerRef?.current && playerRef.current.seekTo(playerRef.current.getCurrentTime() + SEEK_SECONDS, 'seconds')
                                                        setElapsedTimeInSeconds(playerRef.current.getCurrentTime() + SEEK_SECONDS)
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            }

                            <Box className="player flex f-h-center full-width" >
                                <Box className="player-width" >
                                    <ReactPlayer
                                        playing={videoPlay}
                                        ref={playerRef}
                                        height={!document.fullscreen ? windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET ? "300px" : "400px" : `${windowDimensions.height - 150}px`}
                                        // height={windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET ? document.fullscreen ? `${windowDimensions.height - 150}px` : "100%" : document.fullscreen ? "85%" : "100%"} //400px
                                        width={windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET ? "100%" : "100%"}
                                        url={Link}
                                        onDuration={(duration) => {
                                            setDuration(getTotalTimeInMins(duration));
                                            setVideoLengthInSeconds(duration)
                                        }}
                                        config={showSubtitle ? {
                                            file: {
                                                tracks: [
                                                    { kind: 'subtitles', src: SubtitleFile, srcLang: 'en', default: true },
                                                ]
                                            }
                                        } : {
                                            youtube: {
                                                playerVars: { fs: 0, controls: 0 }
                                            },
                                        }}
                                        id="videoPlayer"
                                        controls={false}
                                        // light={!document.fullscreen && Thumbnail}
                                        // playIcon={!videoPlay && !document.fullscreen ?
                                        //     <Box className="pause">
                                        //         {
                                        //             !videoPlay ? (
                                        //                 <PlaySVG onClick={() => setVideoPlay(prevState => !prevState)} />
                                        //             ) : (
                                        //                 <PauseSVG onClick={() => setVideoPlay(prevState => !prevState)} />
                                        //             )
                                        //         }
                                        //     </Box> : <></>
                                        // }
                                        onPlay={() => setVideoPlay(true)}
                                        onPause={() => setVideoPlay(false)}
                                    />

                                    <Box className="videoOptionContainer">
                                        {
                                            // videoPlay && (
                                            <Box className="flex full-width" style={{ justifyContent: "space-between" }}>
                                                <Box style={{ cursor: 'pointer' }}>
                                                    <Subtitle onClick={() => setShowSubtitle(prevState => !prevState)} />
                                                </Box>
                                                <Box style={{ cursor: 'pointer' }}>
                                                    <FullScreenSVG onClick={() => {
                                                        isVideoFullScreen ? onExit() : onRequest()
                                                        setIsVideoFullScreen(prevState => !prevState)
                                                        // document.getElementById('videoPlayer').requestFullscreen()
                                                    }} />
                                                </Box>
                                            </Box>
                                            // )
                                        }


                                        {/* Below code is to display for full screen mode */}
                                        {
                                            document.fullscreen && (
                                                <>
                                                    <Box className="progressBar-fullscreen">
                                                        <Slider
                                                            value={elapsedTimeInSeconds}
                                                            max={videoLengthInSeconds}
                                                            aria-label="Default"
                                                            valueLabelDisplay="off"
                                                            onChange={(e, value) => {
                                                                playerRef?.current && playerRef.current.seekTo(value, 'seconds')
                                                                setElapsedTimeInSeconds(value)
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box className="time-fullscreen">
                                                        <Typography >
                                                            {elapsedTime}
                                                        </Typography>

                                                        <Typography>
                                                            {duration}
                                                        </Typography>
                                                    </Box>
                                                </>
                                            )
                                        }

                                    </Box>

                                </Box>
                            </Box>
                        </div>
                    )}
                </ReactFullscreen>
                <Box className="playerDetailsWrapper">
                    <Box className="flex f-v-center">
                        <Box className="titleWrapper">
                            <Typography className="title">
                                Heal & Transform the Heart
                            </Typography>
                        </Box>
                        <Box className="boostHealingButton">
                            <Button onClick={() => setBoostHealingPopup(prevState => !prevState)} className="button" variant="contained">
                                Boost Your Healing
                            </Button>
                        </Box>
                    </Box>

                    {
                        windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET && (
                            <Box className="flex f-h-center f-v-center full-width">
                                <Box style={{ cursor: 'pointer' }}>
                                    <RepeatSVG />
                                </Box>
                                <Box className="flex">
                                    <Typography onClick={() => setSelectedStep(1)} className={`step ${selectedStep === 1 && "selected-step"}`}>
                                        STEP 1
                                    </Typography>

                                    <Typography onClick={() => setSelectedStep(2)} className={`step ${selectedStep === 2 && "selected-step"}`}>
                                        STEP 2
                                    </Typography>

                                    <Typography onClick={() => setSelectedStep(3)} className={`step ${selectedStep === 3 && "selected-step"}`}>
                                        STEP 3
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    }

                    <Box className="flex f-v-center playerManagement">
                        <Box className="player-buttons flex f-v-center f-h-center ">
                            <Box className="previous">
                                <PreviousSVG
                                    onClick={() => {
                                        playerRef?.current && playerRef.current.seekTo(playerRef.current.getCurrentTime() - 15, 'seconds')
                                        setElapsedTimeInSeconds(playerRef.current.getCurrentTime() - SEEK_SECONDS)
                                    }}
                                />
                            </Box>

                            <Box className="pause">
                                {
                                    !videoPlay ? (
                                        <PlaySVG onClick={() => setVideoPlay(prevState => !prevState)} />
                                    ) : (
                                        <PauseSVG onClick={() => setVideoPlay(prevState => !prevState)} />
                                    )
                                }

                            </Box>

                            <Box className="forward">
                                <ForwardSVG
                                    onClick={() => {
                                        playerRef?.current && playerRef.current.seekTo(playerRef.current.getCurrentTime() + SEEK_SECONDS, 'seconds')
                                        setElapsedTimeInSeconds(playerRef.current.getCurrentTime() + SEEK_SECONDS)
                                    }}
                                />
                            </Box>
                        </Box>

                        {
                            windowDimensions.width > BREAKPOINTS_VALUE.PHABLET && (
                                <Box className="time">
                                    <Typography >
                                        {elapsedTime} / {duration}
                                    </Typography>
                                </Box>
                            )
                        }


                        <Box className="progressBar">
                            <Slider
                                value={elapsedTimeInSeconds}
                                max={videoLengthInSeconds}
                                aria-label="Default"
                                valueLabelDisplay="off"
                                onChange={(e, value) => {
                                    playerRef?.current && playerRef.current.seekTo(value, 'seconds')
                                    setElapsedTimeInSeconds(value)
                                }}
                            />
                        </Box>

                        {
                            windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET && (
                                <Box className="time">
                                    <Typography >
                                        {elapsedTime}
                                    </Typography>

                                    <Typography>
                                        {duration}
                                    </Typography>
                                </Box>
                            )
                        }


                        {
                            windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET && (
                                <Box>
                                    <Button onClick={() => setBoostHealingPopup(prevState => !prevState)} className="button" variant="contained">
                                        Boost Your Healing
                                    </Button>
                                </Box>
                            )
                        }
                    </Box>
                </Box>

                <Slide key="slide" direction="up" in={boostHealingPopup} container={containerRef.current} mountOnEnter unmountOnExit>
                    <Box className="healingPopupContainer">
                        <Box className="clearNotification">
                            <ClearNotificationSVG onClick={() => setBoostHealingPopup(prevState => !prevState)} style={{ cursor: "pointer" }} />
                        </Box>

                        <Box className="boostMessage flex f-v-center f-h-center">
                            <Box className="boostPopupContent">
                                <Typography className="boostPopupTitle"><b>Boost Your Healing</b></Typography>
                                <Typography className="boostPopupDescription">Receive a Light Transmission for your heart to boost your healing process!</Typography>
                            </Box>

                            <Box className="boostPopupButton">
                                <TaoButton style={{ color: "#fff" }}>TELL ME MORE</TaoButton>
                            </Box>
                        </Box>
                    </Box>
                </Slide>
            </Box>

            <Box className="heart-container" />

        </FieldHealingPlayerWrapper>
    )
}

export default FieldHealingPlayer;