import { Box } from '@mui/material';
import { COLORS, FONTS } from 'Styles/Constants';
import styled from 'styled-components';

export const OrderHistoryWrapper = styled(Box)`
    .title {
        font-size: 40px;
        font-family: ${FONTS.SECONDARY_BOLD};
        color: ${COLORS.PRIMARY_DARK};
    }

    .container {
        margin: 20px 0 0;
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 16px 0px #00000026;
        background-color: ${COLORS.WHITE};
        border-radius: 30px 30px 0 0;

        .search-bar {
            width: 90%;
            padding: 30px 0;
            margin: 0 auto;
        }

        .items {
            overflow: auto;
            height: calc(100% - 200px);

            ::-webkit-scrollbar {
                display: none;
            }
            .ordered-item {
                height: 100px;
                padding: 20px;
                gap: 30px;
                transition: 0.5s all;

                &:hover {
                    transform: scale(0.95);
                }

                &.loading {
                    transition: none;
                    &:hover {
                        transform: none;
                    }

                    :nth-child(even) {
                        background: ${COLORS.WHITE};
                    }
                }

                :nth-child(even) {
                    background: #8484841a;
                }

                .month {
                    font-size: 16px;
                    text-transform: uppercase;
                    color: ${COLORS.PRIMARY_DARK};
                    font-family: ${FONTS.PRIMARY_MEDIUM};
                    line-height: 1;
                }
                .day {
                    font-size: 36px;
                    color: ${COLORS.PRIMARY_DARK};
                    font-family: ${FONTS.PRIMARY_BOLD};
                    line-height: 1;
                }

                .arrow {
                    border-top: 2px solid ${COLORS.PRIMARY};
                    border-right: 2px solid ${COLORS.PRIMARY};
                    width: 10px;
                    height: 10px;
                    transform: rotate(45deg);
                    margin: 0 0 0 auto;
                }

                .content {
                    width: 80%;

                    .item-title {
                        font-size: 18px;
                        font-family: ${FONTS.PRIMARY_MEDIUM};
                        color: ${COLORS.BLACK};
                    }
                    .item-inventory {
                        font-size: 16px;
                        color: ${COLORS.MEDIUM_GREY};

                        &.separator {
                            margin: 0 5px;
                        }
                    }
                }
            }
        }
    }
`;
