import { Box } from '@mui/material';
import { COLORS, FONTS } from 'Styles/Constants';
import styled from 'styled-components';
import Referrals from 'Assets/images/Profile/paid-by-points.svg';

export const OrderDetailsWrapper = styled(Box)`
    .title {
        font-size: 40px;
        font-family: ${FONTS.SECONDARY_BOLD};
        color: ${COLORS.PRIMARY_DARK};
    }

    .container {
        margin: 20px 0 0;
        width: 100%;
        box-shadow: 0px 0px 16px 0px #00000026;
        background-color: ${COLORS.WHITE};
        border-radius: 30px 30px 0 0;
        padding: 20px 0 20px 20px;
        height: 100%;

        .scrollable-area {
            height: ${(props) =>
                props?.$isOrderSuccess ? 'calc(100% - 120px)' : 'calc(100% - 50px)'};
            overflow: auto;

            ::-webkit-scrollbar {
                display: none;
            }

            .key {
                font-size: 24px;
                color: ${COLORS.PRIMARY_DARK};
                font-family: ${FONTS.PRIMARY_MEDIUM};
            }
            .value {
                font-size: 20px;
                color: ${COLORS.TEXT_DARK};
                margin: 0 0 0 10px;
            }

            .sub-title {
                font-size: 24px;
                color: ${COLORS.MEDIUM_GREY};
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                margin: 20px 0 10px;
                padding: 0 20px 0 0;

                &.decrease-font-size {
                    font-size: 18px;
                }
            }

            .payment-and-shipping {
                font-size: 20px;
                color: ${COLORS.TEXT_DARK};
                margin: 5px 0;

                &.value {
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    margin: 5px;
                }
            }
            .payment-icon {
                background-image: url(${Referrals});
                height: 24px;
                width: 24px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                margin: 0 0 0 5px;
            }

            .bottom-space {
                padding: 0 0 50px;
            }

            .total-order-value {
                padding: 0 20px 0 0;
                font-size: 18px;
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                color: ${COLORS.PRIMARY_DARK};
                margin: 20px 0;
            }

            .divider-border {
                border: 1px solid ${COLORS.MEDIUM_GREY};
                opacity: 40%;

                &.last-child {
                    opacity: 100%;
                }
            }
            .order-tax-and-shipping-value {
                font-size: 18px;
                padding: 0 20px 0 0;
                color: ${COLORS.MEDIUM_GREY};
                margin: 5px 0;
            }

            .list-item {
                padding: 10px 20px 10px 0;
                gap: 20px;

                .image {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 60px;
                    width: 60px;
                    min-width: 60px;
                    position: relative;
                    border-radius: 6px;
                    overflow: hidden;

                    .duration {
                        background-color: ${COLORS.PRIMARY_LIGHT};
                        font-size: 8px;
                        font-family: ${FONTS.PRIMARY_MEDIUM};
                        color: ${COLORS.BLACK};
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        text-transform: uppercase;
                        text-align: center;
                        padding: 0 5px;
                    }
                }

                .item-title {
                    font-size: 18px;
                    color: ${COLORS.BLACK};
                    font-family: ${FONTS.PRIMARY_MEDIUM};
                    width: 80%;
                }

                .price {
                    font-size: 18px;
                    color: ${COLORS.MEDIUM_GREY};
                    margin: 0 0 0 auto;
                }
            }
            .access-it-now {
                background-color: #d4fac7;
                border-radius: 12px;
                width: calc(100% - 20px);
                margin: 0 0 5px;

                .text {
                    font-size: 18px;
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    color: ${COLORS.PRIMARY_DARK};
                    text-decoration: underline;
                    padding: 6px 10px;
                    width: max-content;
                }
            }
        }

        .earn-points-button {
            width: calc(100% - 20px);
            background-color: ${COLORS.WHITE};
            height: 60px;
            color: ${COLORS.BLACK};
            border-radius: 12px;
            font-size: 16px;
            font-family: ${FONTS.PRIMARY_BOLD};
            box-shadow: 0px 0px 16px 0px #00000026;
        }

        .arrow {
            height: 10px;
            width: 10px;
            border-top: 2px solid ${COLORS.MEDIUM_GREY};
            border-right: 2px solid ${COLORS.MEDIUM_GREY};
            transform: rotate(45deg);
        }
    }
`;
