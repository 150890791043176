import React, { useState, useRef } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import defaultLocal from 'date-fns/locale/en-US';
import moment from 'moment';
import { Box, Typography, /* Tooltip, Hidden, */ Button } from '@mui/material';

import { CalendarWrapper } from './Calendar.style';
import { TaoButton } from 'Components/common/Button.style';
import TaoSessionCard from 'Components/common/TaoSessionCard/TaoSessionCard';
import TaoSessionRecoCard from 'Components/common/TaoSessionRecoCard/TaoSessionRecoCard';

import DefaultUserProfileIcon from 'Assets/images/Profile/default-user-icon.jpg';
// import ProfileImage1 from 'Assets/images/Common/profile-image-1.png';
// import ProfileImage2 from 'Assets/images/Common/profile-image-2.png';
// import ProfileImage3 from 'Assets/images/Common/profile-image-3.png';
import ProfileImage4 from 'Assets/images/Profile/default-user-icon.jpg';
import ChallengesIcon from 'Assets/images/Common/challenges-icon-for-calendar.svg';
// import TeacherImage from 'Assets/images/Common/tao-calligraphy-teacher.png';
// import AvatarImage1 from "Assets/images/Common/session-background-image.png";
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import Slider from 'react-slick';
import { ReactComponent as SliderNextButton } from 'Assets/images/Common/next-arrow.svg';
import { ReactComponent as SliderPreviousButton } from 'Assets/images/Common/previous-arrow.svg';
import WeekCalendar from 'Components/common/WeekCalendar';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
// import SessionSignup from 'Components/common/TaoSessionRecoCard/SessionSignup';

const theme = createTheme({
    components: {
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    width: '445px',
                    backgroundColor: 'white',
                    borderRadius: 20,
                    height: '490px'
                },
                viewTransitionContainer: {
                    width: '445px',
                    height: '439px',
                    '.PrivatePickersSlideTransition-root': {
                        height: '399px'
                    },
                    '@media only screen and (max-width: 1050px)': {
                        width: '420px'
                    },
                    '@media only screen and (max-width: 870px)': {
                        width: '100%'
                    }
                }
            }
        }
    }
});

const SLIDER_SETTINGS = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    infinite: false,
    swipeToSlide: false,
    variableWidth: true,
    accessibility: true,
    nextArrow: <SliderNextButton className="next-btn" style={{ width: '16', height: '16' }} />,
    prevArrow: <SliderPreviousButton />,
    focusOnSelect: false,
    draggable: true,
    responsive: [
        {
            breakpoint: BREAKPOINTS_VALUE.DESKTOP,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: BREAKPOINTS_VALUE.LAPTOP,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: BREAKPOINTS_VALUE.TABLET,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: BREAKPOINTS_VALUE.PHABLET,
            settings: {
                slidesToShow: 1,
                arrows: false
            }
        }
    ]
};

const Calendar = (props) => {
    const todayDate = useRef(moment());
    const slickSliderRef = useRef();
    const [currentMonthView, setCurrentMonthView] = useState(moment().startOf('month'));
    // const [showSignupPopup, setShowSignupPopup] = useState(false);
    // const [sessionSignupDetails, setSessionSignupDetails] = useState({
    //   sessionTitle: "",
    //   sessionTime: "",
    // });

    const handleSignUpClick = (sessionTitle = '', sessionTime = '') => {
        // setSessionSignupDetails({
        //   sessionTime,
        //   sessionTitle,
        // });
        // setShowSignupPopup(true)
    };

    // const onClosePopup = () => {
    //   setShowSignupPopup(false);
    //   setSessionSignupDetails({
    //     sessionTitle: "",
    //     sessionTime: "",
    //   });
    // }

    const monthChangeHandler = (month) => {
        setCurrentMonthView(moment(month));
    };
    return (
        <>
            <TaoCalligraphyWatermark />
            {/* <SessionSignup open={showSignupPopup} onClose={onClosePopup} {...sessionSignupDetails} /> */}
            <CalendarWrapper
                currentWeekDay={
                    moment(currentMonthView).startOf('months').format('MM/DD/YYYY') ===
                    moment(todayDate.current).startOf('months').format('MM/DD/YYYY')
                        ? todayDate.current.day()
                        : -1
                }>
                <Box className="page-content">
                    <Box className="page-item sessions-timings-container">
                        <Box className="schedule-list-container">
                            <Box className="date-container">
                                <Typography variant="h3" className="week-day" color="primary">
                                    {todayDate.current.format('dddd')}
                                </Typography>
                                <Typography className="date-text">
                                    {todayDate.current.format('Do MMMM')}
                                </Typography>
                                <TaoButton className="filter-btn">Today »</TaoButton>
                            </Box>
                            <Box className="schedule-list">
                                {upComingSessions.map((ss, index) => (
                                    <TaoSessionCard
                                        {...ss}
                                        key={index}
                                        cardRootClass="session-card"
                                    />
                                ))}
                            </Box>
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={defaultLocal}>
                            <ThemeProvider theme={theme}>
                                <CalendarPicker
                                    className="static-calendar-root"
                                    onChange={(val) => {
                                        console.log(val.toISOString());
                                    }}
                                    onMonthChange={monthChangeHandler}
                                    renderDay={(day, _value, DayComponentProps) => {
                                        return (
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="center"
                                                mb={1}>
                                                <PickersDay
                                                    {...DayComponentProps}
                                                    disableMargin={false}
                                                    classes={{
                                                        root: 'calendar-day-root',
                                                        today: 'today-date'
                                                    }}
                                                    showDaysOutsideCurrentMonth={false}
                                                />
                                                {!DayComponentProps.outsideCurrentMonth && (
                                                    <div
                                                        className={`dot ${
                                                            DayComponentProps.today && 'primary'
                                                        } ${
                                                            DARK_GRAY.includes(
                                                                moment(day).format('YYYY-MM-DD')
                                                            ) && 'dark-gray'
                                                        } ${
                                                            LIGHT_GRAY.includes(
                                                                moment(day).format('YYYY-MM-DD')
                                                            ) && 'light'
                                                        } `}></div>
                                                )}
                                            </Box>
                                        );
                                    }}></CalendarPicker>
                            </ThemeProvider>
                        </LocalizationProvider>
                        <Box className="week-calendar-container">
                            <WeekCalendar
                                start_time={moment()}
                                cardRootClass="week-calendar"
                                weekGrayDots={weekGrayDots}
                                darkGrayDots={darkGrayDots}
                            />
                        </Box>
                    </Box>
                    <Box className="page-item recommendation-listing-container">
                        <Typography
                            color="primary.dark"
                            fontWeight={600}
                            variant="h5"
                            paddingLeft="12px"
                            gutterBottom>
                            More from Rooms You Follow
                        </Typography>
                        <Box className="recommendation-listing">
                            <Box className="slider-btn-container">
                                <SliderPreviousButton
                                    onClick={() => slickSliderRef.current.slickPrev()}
                                    className="slider-btn"
                                />
                            </Box>
                            <Slider ref={slickSliderRef} {...SLIDER_SETTINGS}>
                                {roomsSuggestion.map((rs, index) => (
                                    <TaoSessionRecoCard
                                        {...rs}
                                        verticalCard
                                        key={index}
                                        signupClickHandler={handleSignUpClick}
                                    />
                                ))}
                            </Slider>
                            <Box className="slider-btn-container">
                                <SliderNextButton
                                    onClick={() => slickSliderRef.current.slickNext()}
                                    className="slider-btn"
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box className="page-item mobile-recommendation-listing-container">
                        <Typography
                            color="primary.dark"
                            fontWeight={600}
                            variant="h5"
                            paddingLeft="12px"
                            gutterBottom>
                            More from Rooms You Follow
                        </Typography>
                        <Box className="recommendation-list">
                            {mobileRoomSuggestion.map((rs, index) => (
                                <TaoSessionRecoCard
                                    {...rs}
                                    key={index}
                                    cardRootClass="session-card"
                                    signupClickHandler={handleSignUpClick}
                                />
                            ))}
                        </Box>
                        <Button className="load-more-btn">Load more</Button>
                    </Box>
                </Box>
            </CalendarWrapper>
        </>
    );
};

export default Calendar;

const DARK_GRAY = ['2022-05-11', '2022-05-29', '2022-05-06', '2022-05-10', '2022-05-27'];

const LIGHT_GRAY = ['2022-05-04', '2022-05-05', '2022-05-09', '2022-05-14', '2022-05-24'];

const weekGrayDots = [
    moment().add(1, 'day').format('YYYY/MM/DD'),
    moment().add(4, 'day').format('YYYY/MM/DD')
];

const darkGrayDots = [moment().add(2, 'day')];

const upComingSessions = [
    {
        isChallenge: true,
        challengeDay: 2,
        challengeName: 'Kindness Challenge',
        sessionImage: ChallengesIcon,
        sessionTime: '3:00 - 4:00 PM',
        sessionTitle: 'Do One Act of Unconditional Kindness',
        sessionWith: 'Day 2 of Kindness Challenge',
        isTaoCalligraphyTeacher: false,
        isPlaylist: false
    },
    {
        youAreHost: true,
        hostingText: "You're Hosting",
        isLive: false,
        sessionImage: ProfileImage4,
        sessionTime: ' 5:30 - 6:30 PM',
        sessionTitle: 'Wellness Boost',
        sessionWith: 'with Francisco',
        isTaoCalligraphyTeacher: true,
        isPlaylist: false
    },
    {
        youAreHost: false,
        isLive: false,
        playlistDay: 2,
        totalPlaylistDays: 10,
        playlistName: ' Days to Better Sleep',
        sessionImage: ProfileImage4,
        sessionTime: '11:00 PM',
        sessionTitle: 'Sound Sleep',
        sessionWith: 'Day 2 of 7 Days to Better Sleep',
        isTaoCalligraphyTeacher: false,
        isPlaylist: true
    },
    {
        youAreHost: true,
        hostingText: 'You’re Co-hosting with Elizabeth',
        isLive: true,
        sessionImage: ProfileImage4,
        sessionTime: '3:00 - 4:00 PM',
        sessionTitle: 'Breathwork for Boosting Energy',
        sessionWith: 'with Harsha',
        isTaoCalligraphyTeacher: false,
        isPlaylist: false
    }
];

const roomsSuggestion = [
    {
        isLive: false,
        avatarImage: DefaultUserProfileIcon,
        sessionTime: '3:00 - 4:00 PM',
        sessionTitle: 'Breathwork for Boosting Energy',
        sessionWith: 'with Harsha',
        isTaoCalligraphyTeacher: false,
        isPlaylist: false
    },
    {
        youAreHost: true,
        hostingText: 'You’re Co-hosting with Elizabeth',
        isLive: false,
        avatarImage: DefaultUserProfileIcon,
        sessionTime: '3:00 - 4:00 PM',
        sessionTitle: 'Breathwork for Boosting Energy',
        sessionWith: 'with Harsha',
        isTaoCalligraphyTeacher: false,
        isPlaylist: false
    },
    {
        isLive: true,
        avatarImage: DefaultUserProfileIcon,
        sessionTime: '3:00 - 4:00 PM',
        sessionTitle: 'Breathwork for Boosting Energy',
        sessionWith: 'with Harsha',
        isTaoCalligraphyTeacher: false,
        isPlaylist: false
    },
    {
        isLive: false,
        avatarImage: DefaultUserProfileIcon,
        sessionTime: '3:00 - 4:00 PM',
        sessionTitle: 'Breathwork for Boosting Energy',
        sessionWith: 'with Harsha',
        isTaoCalligraphyTeacher: true,
        isPlaylist: false
    },
    {
        isLive: false,
        avatarImage: DefaultUserProfileIcon,
        sessionTime: '3:00 - 4:00 PM',
        sessionTitle: 'Wellness Boost',
        sessionWith: 'with Harsha',
        isTaoCalligraphyTeacher: true,
        isPlaylist: false,
        needSignUp: true
    },
    {
        isLive: true,
        avatarImage: DefaultUserProfileIcon,
        sessionTime: '3:00 - 4:00 PM',
        sessionTitle: 'Breathwork for Boosting Energy',
        sessionWith: 'with Harsha',
        isTaoCalligraphyTeacher: false,
        isPlaylist: false
    },
    {
        isLive: false,
        avatarImage: DefaultUserProfileIcon,
        sessionTime: '3:00 - 4:00 PM',
        sessionTitle: 'Wellness Boost',
        sessionWith: 'with Harsha',
        isTaoCalligraphyTeacher: true,
        isPlaylist: false,
        needSignUp: true
    },
    {
        isLive: true,
        avatarImage: DefaultUserProfileIcon,
        sessionTime: '3:00 - 4:00 PM',
        sessionTitle: 'Breathwork for Boosting Energy',
        sessionWith: 'with Harsha',
        isTaoCalligraphyTeacher: false,
        isPlaylist: false
    }
];

const mobileRoomSuggestion = [
    {
        isLive: true,
        avatarImage: ProfileImage4,
        sessionTime: '3:00 - 4:00 PM',
        sessionTitle: 'Breathwork for Boosting Energy',
        sessionWith: 'with Harsha',
        isTaoCalligraphyTeacher: false,
        isPlaylist: false
    },
    {
        isLive: false,
        avatarImage: ProfileImage4,
        sessionTime: '3:00 - 4:00 PM',
        sessionTitle: 'Wellness Boost',
        sessionWith: 'with Harsha',
        isTaoCalligraphyTeacher: true,
        isPlaylist: false,
        needSignUp: true
    },
    {
        isLive: true,
        avatarImage: ProfileImage4,
        sessionTime: '3:00 - 4:00 PM',
        sessionTitle: 'Breathwork for Boosting Energy',
        sessionWith: 'with Harsha',
        isTaoCalligraphyTeacher: false,
        isPlaylist: false
    }
];
