import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
    Box,
    Typography,
    Button,
    Skeleton,
    Tooltip,
    Slide,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Divider,
    ListItemText,
    Checkbox
} from '@mui/material';
import { MoreVert, RadioButtonUnchecked, CheckCircle } from '@mui/icons-material';

import { CSSTransition } from 'react-transition-group';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as EditIcon } from 'Assets/images/Chat/edit-icon.svg';
import { ReactComponent as ArrowImage } from 'Assets/images/Common/down-arrow.svg';
import { ReactComponent as DeleteIcon } from 'Assets/images/MeditationTimer/delete-icon.svg';
import { ReactComponent as BellOffIcon } from 'Assets/images/Chat/bell-off-icon.svg';
import { ReactComponent as MarkAsUnReadIcon } from 'Assets/images/Chat/mark-unread-icon.svg';
import { ReactComponent as PinnedIcon } from 'Assets/images/Chat/pinned-icon.svg';
import Friend1 from 'Assets/images/Profile/default-user-icon.jpg';
import Friend2 from 'Assets/images/Profile/default-user-icon.jpg';
import Friend3 from 'Assets/images/Profile/default-user-icon.jpg';
import Friend4 from 'Assets/images/Profile/default-user-icon.jpg';

import ChatWindow from './ChatWindow';
import NewChat from './NewChat';
import AddMemberModal from './AddMemberModal';
import { ChatWrapper, ChatOptionsWrapper } from './Chat.style';

import { getWindowDimensions } from 'Helpers';
import { showSideBar, hideSideBar } from 'Redux/Sidebar/Actions';
import { showTopBar, hideTopBar, showFooter, hideFooter } from 'Redux/App/Actions';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';

/**
 *
 * @param {Moment} momentDate
 */
const getTimeFormat = (momentDate) => {
    const diff = moment().diff(momentDate, 'day');
    if (diff === 0) {
        return 'hh:mm a';
    } else if (diff < 7) {
        return 'dddd';
    } else {
        return 'MM/DD/YY';
    }
};

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Visualizations_with_Web_Audio_API
// https://codesandbox.io/s/2e507  ==> for audio visualizer
const Chat = ({ ...props }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [isConvEdit, setIsConvEditing] = useState(false);
    const [selectedChat, setSelectedChat] = useState('');
    const [selectedConversationId, setSelectedConversationId] = useState('');
    const [converAnchor, setCoverAnchor] = useState(null);
    const [conversationInfo, setConversationInfo] = useState({});
    const [messages, setMessages] = useState(cloneDeep([]));
    const [addMemberModal, setAddMemberModal] = useState(false);
    const [showNewChat, setShowNewChat] = useState(false);
    const [minWidth, setMinWidth] = useState(''); // WHEN USER OPENS THE NEW CHAT MODAL FIRST THEN IT OPENS CONVERSATION MODAL, NEED TO GIVE MIN WIDTH TI RIGHT CONTAINER
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const chatOpenHandler = useMemo((chatId) => {
        setSelectedChat(chatId);
        closeNewChat();
        closeAddMemberModal();
        setConversationInfo(cloneDeep(CONVERSATIONS.find((c) => c.id === chatId)));
    }, []);

    // TODO: needs optimization()
    const conversations = useMemo(() => {
        const result = [];
        CONVERSATIONS.forEach((c) => {
            switch (c.members.length) {
                case 1:
                    result.push(
                        <Box
                            onClick={() => chatOpenHandler(c.id)}
                            className={`conversation-tab ${
                                selectedChat === c.id && 'selected-tab'
                            }`}>
                            {isConvEdit && (
                                <Box className="selector-container">
                                    <Checkbox
                                        onChange={(e) => checkBoxHandler(e)}
                                        onClick={(e) => e.stopPropagation()}
                                        checkedIcon={<CheckCircle />}
                                        icon={<RadioButtonUnchecked />}
                                    />
                                </Box>
                            )}
                            <Box className="conv-details">
                                <Box
                                    style={{ backgroundImage: `url("${c.members[0].image}")` }}
                                    className="profile-image"></Box>
                                <Box className="conversation-info-container">
                                    <Typography className="name">
                                        {c.members[0].firstName + ' ' + c.members[0].lastName}
                                    </Typography>
                                    <Typography className="message">{c.message}</Typography>
                                </Box>
                                <Box className="count-container">
                                    <Typography className="time">
                                        {c.time.format(getTimeFormat(c.time))}
                                    </Typography>

                                    {c.notificationCount && (
                                        <Box className="notification-count">
                                            {c.notificationCount}
                                        </Box>
                                    )}
                                </Box>
                                <Box className="more-btn-container">
                                    <IconButton
                                        className="more-icon"
                                        onClick={(e) => openConvOptionPopover(e, c.id)}>
                                        <MoreVert />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    );
                    break;
                case 2:
                    result.push(
                        <Box
                            onClick={() => chatOpenHandler(c.id)}
                            className={`conversation-tab ${
                                selectedChat === c.id && 'selected-tab'
                            }`}>
                            {isConvEdit && (
                                <Box className="selector-container">
                                    <Checkbox
                                        onChange={(e) => checkBoxHandler(e)}
                                        onClick={(e) => e.stopPropagation()}
                                        checkedIcon={<CheckCircle />}
                                        icon={<RadioButtonUnchecked />}
                                    />
                                </Box>
                            )}
                            <Box className="conv-details">
                                <Box className="profile-image group-chat-2-images">
                                    <Box
                                        className="group-chat-2-image-1"
                                        style={{
                                            backgroundImage: `url("${c.members[0].image}")`
                                        }}></Box>
                                    <Box
                                        className="group-chat-2-image-2"
                                        style={{
                                            backgroundImage: `url("${c.members[1].image}")`
                                        }}></Box>
                                </Box>
                                <Box className="conversation-info-container">
                                    <Typography className="name">
                                        {c.members[0].firstName + ', ' + c.members[1].firstName}
                                    </Typography>
                                    <Typography className="message">{c.message}</Typography>
                                </Box>
                                <Box className="count-container">
                                    <Typography className="time">
                                        {c.time.format(getTimeFormat(c.time))}
                                    </Typography>
                                    {c.notificationCount && (
                                        <Box className="notification-count">
                                            {c.notificationCount}
                                        </Box>
                                    )}
                                </Box>
                                <Box className="more-btn-container">
                                    <IconButton
                                        className="more-icon"
                                        onClick={(e) => openConvOptionPopover(e, c.id)}>
                                        <MoreVert />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    );
                    break;

                case 3:
                    result.push(
                        <Box
                            onClick={() => chatOpenHandler(c.id)}
                            className={`conversation-tab ${
                                selectedChat === c.id && 'selected-tab'
                            }`}>
                            {isConvEdit && (
                                <Box className="selector-container">
                                    <Checkbox
                                        onChange={(e) => checkBoxHandler(e)}
                                        onClick={(e) => e.stopPropagation()}
                                        checkedIcon={<CheckCircle />}
                                        icon={<RadioButtonUnchecked />}
                                    />
                                </Box>
                            )}
                            <Box className="conv-details">
                                <Box className="profile-image group-chat-3-images">
                                    <Box
                                        style={{
                                            backgroundImage: `url("${c.members[0].image}")`
                                        }}></Box>
                                    <Box
                                        style={{
                                            backgroundImage: `url("${c.members[1].image}")`
                                        }}></Box>
                                    <Box
                                        style={{
                                            backgroundImage: `url("${c.members[2].image}")`
                                        }}></Box>
                                </Box>
                                <Box className="conversation-info-container">
                                    <Tooltip
                                        title={
                                            c.members[0].firstName +
                                            ', ' +
                                            c.members[1].firstName +
                                            ', ' +
                                            c.members[2].firstName
                                        }>
                                        <Typography className="name">
                                            {c.members[0].firstName +
                                                ', ' +
                                                c.members[1].firstName +
                                                ', ' +
                                                c.members[2].firstName}
                                        </Typography>
                                    </Tooltip>
                                    <Typography className="message">{c.message}</Typography>
                                </Box>
                                <Box className="count-container">
                                    <Typography className="time">
                                        {c.time.format(getTimeFormat(c.time))}
                                    </Typography>
                                    {c.notificationCount && (
                                        <Box className="notification-count">
                                            {c.notificationCount}
                                        </Box>
                                    )}
                                </Box>
                                <Box className="more-btn-container">
                                    <IconButton
                                        className="more-icon"
                                        onClick={(e) => openConvOptionPopover(e, c.id)}>
                                        <MoreVert />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    );
                    break;

                case 4:
                    result.push(
                        <Box
                            onClick={() => chatOpenHandler(c.id)}
                            className={`conversation-tab ${
                                selectedChat === c.id && 'selected-tab'
                            }`}>
                            {isConvEdit && (
                                <Box className="selector-container">
                                    <Checkbox
                                        onChange={(e) => checkBoxHandler(e)}
                                        onClick={(e) => e.stopPropagation()}
                                        checkedIcon={<CheckCircle />}
                                        icon={<RadioButtonUnchecked />}
                                    />
                                </Box>
                            )}
                            <Box className="conv-details">
                                <Box className="profile-image group-chat-3-images">
                                    <Box
                                        style={{
                                            backgroundImage: `url("${c.members[0].image}")`
                                        }}></Box>
                                    <Box
                                        style={{
                                            backgroundImage: `url("${c.members[1].image}")`
                                        }}></Box>
                                    <Box
                                        style={{
                                            backgroundImage: `url("${c.members[2].image}")`
                                        }}></Box>
                                    <Box
                                        style={{
                                            backgroundImage: `url("${c.members[3].image}")`
                                        }}></Box>
                                </Box>
                                <Box className="conversation-info-container">
                                    <Tooltip
                                        title={
                                            c.members[0].firstName +
                                            ', ' +
                                            c.members[1].firstName +
                                            ', ' +
                                            c.members[2].firstName +
                                            ', ' +
                                            c.members[3].firstName
                                        }>
                                        <Typography className="name">
                                            {c.members[0].firstName +
                                                ', ' +
                                                c.members[1].firstName +
                                                ', ' +
                                                c.members[2].firstName +
                                                ', ' +
                                                c.members[3].firstName}
                                        </Typography>
                                    </Tooltip>
                                    <Typography className="message">{c.message}</Typography>
                                </Box>
                                <Box className="count-container">
                                    <Typography className="time">
                                        {c.time.format(getTimeFormat(c.time))}
                                    </Typography>
                                    {c.notificationCount && (
                                        <Box className="notification-count">
                                            {c.notificationCount}
                                        </Box>
                                    )}
                                </Box>
                                <Box className="more-btn-container">
                                    <IconButton
                                        className="more-icon"
                                        onClick={(e) => openConvOptionPopover(e, c.id)}>
                                        <MoreVert />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    );
                    break;

                default:
                    result.push(
                        <Box
                            onClick={() => chatOpenHandler(c.id)}
                            className={`conversation-tab ${
                                selectedChat === c.id && 'selected-tab'
                            }`}>
                            {isConvEdit && (
                                <Box className="selector-container">
                                    <Checkbox
                                        className="check-box"
                                        onChange={(e) => checkBoxHandler(e)}
                                        onClick={(e) => e.stopPropagation()}
                                        checkedIcon={<CheckCircle />}
                                        icon={<RadioButtonUnchecked />}
                                    />
                                </Box>
                            )}
                            <Box className="conv-details">
                                <Box className="profile-image group-chat-3-images">
                                    <Box
                                        style={{
                                            backgroundImage: `url("${c.members[0].image}")`
                                        }}></Box>
                                    <Box
                                        style={{
                                            backgroundImage: `url("${c.members[1].image}")`
                                        }}></Box>
                                    <Box
                                        style={{
                                            backgroundImage: `url("${c.members[2].image}")`
                                        }}></Box>
                                    {/* <Box style={{ backgroundImage: `url("${c.members[3].image}")` }}></Box> */}
                                    <Box className="addition-people">+{c.members.length - 4}</Box>
                                </Box>
                                <Box className="conversation-info-container">
                                    <Tooltip
                                        title={c.members.reduce(
                                            (preVal, curVal, index) =>
                                                c.members.length > index + 1
                                                    ? preVal + curVal.firstName + ', '
                                                    : preVal + curVal.firstName,
                                            ''
                                        )}>
                                        <Typography className="name">
                                            {c.members.reduce(
                                                (preVal, curVal, index) =>
                                                    c.members.length > index + 1
                                                        ? preVal + curVal.firstName + ', '
                                                        : preVal + curVal.firstName,
                                                ''
                                            )}
                                        </Typography>
                                    </Tooltip>
                                    <Typography className="message">{c.message}</Typography>
                                </Box>
                                <Box className="count-container">
                                    <Typography className="time">
                                        {c.time.format(getTimeFormat(c.time))}
                                    </Typography>
                                    {c.notificationCount && (
                                        <Box className="notification-count">
                                            {c.notificationCount}
                                        </Box>
                                    )}
                                </Box>
                                <Box className="more-btn-container">
                                    <IconButton
                                        className="more-icon"
                                        onClick={(e) => openConvOptionPopover(e, c.id)}>
                                        <MoreVert />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    );
                    break;
            }
        });
        return result;
    }, [selectedChat, isConvEdit, chatOpenHandler]);

    // mimicking API call for fetching list of conversations
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1500);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);

            dispatch(showSideBar());
            dispatch(showTopBar());
            dispatch(showFooter());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET_860) {
            dispatch(hideSideBar());
            dispatch(hideTopBar());
            dispatch(hideFooter());
        }
    }, [dispatch, windowDimensions]);

    useEffect(() => {
        if (selectedChat) {
            const groupedMessages = {};
            for (let mess = 0; mess < Messages.length; mess++) {
                const element = Messages[mess];
                const dateFormat =
                    moment().diff(element.time, 'day') !== 0
                        ? element.time.format('ddd, MMM D')
                        : 'Today';
                if (!groupedMessages[dateFormat]) {
                    groupedMessages[dateFormat] = [];
                    groupedMessages[dateFormat].push(element);
                } else {
                    groupedMessages[dateFormat].push(element);
                }
            }
            setMessages(Object.entries(groupedMessages));
        }
    }, [selectedChat]);

    useEffect(() => {
        if (!selectedChat && !showNewChat) {
            setMinWidth('');
        }
    }, [selectedChat, showNewChat]);

    const closeChatWindowForMobile = () => {
        setSelectedChat(false);
        closeNewChat();
        closeAddMemberModal();
        setConversationInfo({});
    };

    const openNewChatList = () => {
        setShowNewChat(true);
        closeAddMemberModal();
    };

    const closeNewChat = () => {
        setShowNewChat(false);
    };

    const openAddMemberModal = () => {
        setAddMemberModal(true);
    };

    const closeAddMemberModal = () => {
        setAddMemberModal(false);
    };

    const openConvOptionPopover = (e, id) => {
        e.stopPropagation();
        setSelectedConversationId(id);
        setCoverAnchor(e.currentTarget);
    };

    const closeConvOptionPopover = () => {
        setSelectedConversationId('');
        setCoverAnchor(null);
    };

    const checkBoxHandler = (e) => {
        e.stopPropagation();
    };

    return (
        <ChatWrapper>
            <ChatOptionsWrapper
                open={selectedConversationId}
                anchorEl={converAnchor}
                onClose={closeConvOptionPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                PaperProps={{
                    classes: {
                        root: 'popover-container'
                    }
                }}>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <BellOffIcon className="option-icon" />
                            </ListItemIcon>
                            <ListItemText className="text">Mute</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <DeleteIcon className="option-icon delete-icon" />
                            </ListItemIcon>
                            <ListItemText className="text">Delete</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <MarkAsUnReadIcon className="option-icon" />
                            </ListItemIcon>
                            <ListItemText className="text">Read</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <PinnedIcon className="option-icon pin-icon" />
                            </ListItemIcon>
                            <ListItemText className="text">Pin</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </ChatOptionsWrapper>
            <Box className="mobile-top-container">
                <Box className="back-button-container">
                    <IconButton onClick={() => navigate(-1)} className="arrow-btn">
                        <ArrowImage />
                    </IconButton>
                </Box>
                <Box className="mobile-title-container">
                    <Typography className="title">Chat</Typography>
                </Box>
                <IconButton
                    disabled={isLoading}
                    onClick={openNewChatList}
                    className="mobile-new-conv-btn">
                    <EditIcon />
                </IconButton>
            </Box>
            <Box className="page-content">
                <Box className="left-container">
                    <Box className="title-container">
                        <Typography className="title">Chat</Typography>
                        <Button
                            disabled={isLoading}
                            onClick={openNewChatList}
                            className="new-chat-btn">
                            new chat <EditIcon />
                        </Button>
                    </Box>
                    {isLoading ? (
                        <>
                            <Box className="friends-container">
                                <Box className="flex">
                                    <Typography className="friends">Friends</Typography>
                                </Box>
                                <Box className="friends-list">
                                    <Box className="friend-card">
                                        <Skeleton
                                            animation="wave"
                                            sx={{ bgcolor: '#af9a69' }}
                                            className="skeleton"
                                            height
                                            variant="rectangular"></Skeleton>
                                    </Box>
                                    <Box className="friend-card">
                                        <Skeleton
                                            animation="wave"
                                            sx={{ bgcolor: '#af9a69' }}
                                            className="skeleton"
                                            height
                                            variant="rectangular"></Skeleton>
                                    </Box>
                                    <Box className="friend-card">
                                        <Skeleton
                                            animation="wave"
                                            sx={{ bgcolor: '#af9a69' }}
                                            className="skeleton"
                                            height
                                            variant="rectangular"></Skeleton>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                className={`conversation-container ${
                                    isConvEdit && 'padding-bottom'
                                }`}>
                                <Box className="conversation-title-container">
                                    <Typography variant="h5" className="title">
                                        Conversations
                                    </Typography>
                                    {/* <Button variant="text" disableRipple className="edit-btn">Edit</Button> */}
                                </Box>
                                <Box className="conversation-list-container">
                                    <Box className="skeleton-conversation-container">
                                        <Box className="profile-container">
                                            <Skeleton
                                                animation="wave"
                                                sx={{ bgcolor: '#f7f7f7' }}
                                                className="skeleton"
                                                height
                                                variant="rectangular"></Skeleton>
                                        </Box>
                                        <Box className="conversation-name">
                                            <Skeleton
                                                animation="wave"
                                                sx={{ bgcolor: '#f7f7f7' }}
                                                className="skeleton"
                                                height
                                                variant="rectangular"></Skeleton>
                                            <Skeleton
                                                animation="wave"
                                                sx={{ bgcolor: '#f7f7f7' }}
                                                className="skeleton"
                                                height
                                                variant="rectangular"></Skeleton>
                                        </Box>
                                    </Box>
                                    <Box className="skeleton-conversation-container">
                                        <Box className="profile-container">
                                            <Skeleton
                                                animation="wave"
                                                sx={{ bgcolor: '#f7f7f7' }}
                                                className="skeleton"
                                                height
                                                variant="rectangular"></Skeleton>
                                        </Box>
                                        <Box className="conversation-name">
                                            <Skeleton
                                                animation="wave"
                                                sx={{ bgcolor: '#f7f7f7' }}
                                                className="skeleton"
                                                height
                                                variant="rectangular"></Skeleton>
                                            <Skeleton
                                                animation="wave"
                                                sx={{ bgcolor: '#f7f7f7' }}
                                                className="skeleton"
                                                height
                                                variant="rectangular"></Skeleton>
                                        </Box>
                                    </Box>
                                    <Box className="skeleton-conversation-container">
                                        <Box className="profile-container">
                                            <Skeleton
                                                animation="wave"
                                                sx={{ bgcolor: '#f7f7f7' }}
                                                className="skeleton"
                                                height
                                                variant="rectangular"></Skeleton>
                                        </Box>
                                        <Box className="conversation-name">
                                            <Skeleton
                                                animation="wave"
                                                sx={{ bgcolor: '#f7f7f7' }}
                                                className="skeleton"
                                                height
                                                variant="rectangular"></Skeleton>
                                            <Skeleton
                                                animation="wave"
                                                sx={{ bgcolor: '#f7f7f7' }}
                                                className="skeleton"
                                                height
                                                variant="rectangular"></Skeleton>
                                        </Box>
                                    </Box>
                                    <Box className="skeleton-conversation-container">
                                        <Box className="profile-container">
                                            <Skeleton
                                                animation="wave"
                                                sx={{ bgcolor: '#f7f7f7' }}
                                                className="skeleton"
                                                height
                                                variant="rectangular"></Skeleton>
                                        </Box>
                                        <Box className="conversation-name">
                                            <Skeleton
                                                animation="wave"
                                                sx={{ bgcolor: '#f7f7f7' }}
                                                className="skeleton"
                                                height
                                                variant="rectangular"></Skeleton>
                                            <Skeleton
                                                animation="wave"
                                                sx={{ bgcolor: '#f7f7f7' }}
                                                className="skeleton"
                                                height
                                                variant="rectangular"></Skeleton>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box className="friends-container">
                                <Box className="flex">
                                    <Typography className="friends">Friends</Typography>
                                    <Box className="request-container"></Box>
                                </Box>
                                <Box className="friends-list">
                                    {FRIENDS.map((f) => (
                                        <Box
                                            style={{ backgroundImage: `url("${f.image}")` }}
                                            className="friend-card"></Box>
                                    ))}
                                    <Box className="blur"></Box>
                                </Box>
                            </Box>
                            <Box className="chat-list-option-container">
                                <Box
                                    className={`conversation-container ${
                                        isConvEdit && 'padding-bottom'
                                    }`}>
                                    <Box className="conversation-title-container">
                                        <Typography variant="h5" className="title">
                                            Conversations
                                        </Typography>
                                        <Button
                                            variant="text"
                                            disableRipple
                                            onClick={() =>
                                                setIsConvEditing((preState) => !preState)
                                            }
                                            className="edit-btn">
                                            {isConvEdit ? 'Done' : 'Edit'}
                                        </Button>
                                    </Box>
                                    <Box className="conversation-list-container">
                                        <Box className="pinned-chat-container"></Box>
                                        <Box className="chat-container">{conversations}</Box>
                                    </Box>
                                </Box>
                                <CSSTransition
                                    in={isConvEdit}
                                    classNames="conversation-opt-animate"
                                    timeout={450}
                                    unmountOnExit>
                                    <Box className="conversation-edit-options">
                                        <Button variant="text" className="option-btn">
                                            Pin
                                        </Button>
                                        <Button variant="text" className="option-btn">
                                            Read
                                        </Button>
                                        <Button variant="text" className="option-btn">
                                            Mute
                                        </Button>
                                        <Button variant="text" className="option-btn">
                                            Delete
                                        </Button>
                                    </Box>
                                </CSSTransition>
                            </Box>
                            {windowDimensions.width <= BREAKPOINTS_VALUE.TABLET_800 && (
                                <>
                                    <Slide
                                        in={selectedChat}
                                        timeout={450}
                                        unmountOnExit
                                        direction="left">
                                        <ChatWindow
                                            onClose={closeChatWindowForMobile}
                                            className="chat-tablet-style"
                                            conversationInfo={conversationInfo}
                                            messages={messages}
                                            openAddMember={openAddMemberModal}
                                        />
                                    </Slide>
                                    <Slide
                                        in={showNewChat}
                                        unmountOnExit
                                        timeout={450}
                                        direction="left">
                                        <NewChat
                                            className={`tablet-styles`}
                                            onClose={closeNewChat}
                                        />
                                    </Slide>
                                    <Slide
                                        in={addMemberModal}
                                        timeout={450}
                                        unmountOnExit
                                        direction="left">
                                        <AddMemberModal
                                            className="add-member-tablet-style"
                                            onClose={closeAddMemberModal}
                                        />
                                    </Slide>
                                </>
                            )}
                        </>
                    )}
                </Box>
                {windowDimensions.width > BREAKPOINTS_VALUE.TABLET_800 && (
                    <Box className={`right-container ${minWidth === 'min-width' && 'min-width'}`}>
                        <CSSTransition
                            in={selectedChat}
                            unmountOnExit
                            classNames="conversation-animate"
                            timeout={450}
                            onEnter={() => setShowNewChat(false)}>
                            <ChatWindow
                                conversationInfo={conversationInfo}
                                messages={messages}
                                openAddMember={openAddMemberModal}
                            />
                        </CSSTransition>
                        <CSSTransition
                            in={showNewChat}
                            unmountOnExit
                            classNames="conversation-animate"
                            timeout={450}
                            onExit={() => {
                                setMinWidth('min-width');
                            }}>
                            <>{!selectedChat && <NewChat onClose={closeNewChat} />}</>
                        </CSSTransition>
                        {selectedChat && (
                            <Slide
                                in={showNewChat}
                                unmountOnExit
                                timeout={450}
                                direction="up"
                                // style={{ minWidth: "538px" }}
                            >
                                <NewChat
                                    onClose={closeNewChat}
                                    className="min-width"
                                    style={{ position: 'absolute' }}
                                />
                            </Slide>
                        )}
                        <Slide in={addMemberModal} unmountOnExit timeout={450} direction="up">
                            <AddMemberModal onClose={closeAddMemberModal} />
                        </Slide>
                    </Box>
                )}
            </Box>
        </ChatWrapper>
    );
};

export default Chat;

const FRIENDS = [
    {
        image: Friend1,
        name: 'Eleanor'
    },
    {
        image: Friend2,
        name: 'Thomas'
    },
    {
        image: Friend3,
        name: 'Eleanor'
    },
    {
        image: Friend4,
        name: 'Thomas'
    }
];

const Messages = [
    {
        name: 'Eleanor Pena',
        type: 'FROM',
        message: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid, fugiat.',
        time: moment().add(-1, 'day'),
        messageType: 'TEXT'
    },
    {
        name: 'Eleanor Pena',
        type: 'FROM',
        message: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid, fugiat.',
        time: moment().add(-1, 'hour'),
        messageType: 'TEXT'
    },
    {
        name: 'Eleanor Pena',
        type: 'FROM',
        message: 'Lorem, ipsum dolor.',
        time: moment().add(-1, 'hour'),
        messageType: 'TEXT'
    },
    {
        name: 'Shridutt Patel',
        type: 'TO',
        message: 'Lorem, ipsum dolor.',
        time: moment().add(-1, 'hour'),
        messageType: 'TEXT'
    },
    {
        name: 'Shridutt Patel',
        type: 'TO',
        message: 'Lorem, ipsum dolor.',
        time: moment(),
        messageType: 'AUDIO'
    }
];

const CONVERSATIONS = [
    {
        id: 1,
        members: [
            {
                image: Friend1,
                firstName: 'Thomas',
                lastName: 'Leimert'
            }
        ],
        notificationCount: 3,
        time: moment().add(-5, 'hour'),
        message:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel voluptatem iure ex quod. Ducimus expedita eligendi dicta repellendus delectus in sunt quia ullam? Enim fugiat voluptate consequatur.'
    },
    {
        id: 2,
        notificationCount: 4,
        members: [
            {
                image: Friend3,
                firstName: 'Thomas',
                lastName: 'Leimert'
            },
            {
                image: Friend2,
                firstName: 'Eleanor',
                lastName: 'Leimert'
            }
        ],
        time: moment().add(-6, 'day'),
        message:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel voluptatem iure ex quod. Ducimus expedita eligendi dicta repellendus delectus in sunt quia ullam? Enim fugiat voluptate consequatur.'
    },
    {
        id: 3,
        notificationCount: 4,
        members: [
            {
                image: Friend3,
                firstName: 'Thomas',
                lastName: 'Leimert'
            },
            {
                image: Friend2,
                firstName: 'Eleanor',
                lastName: 'Leimert'
            },
            {
                image: Friend1,
                firstName: 'Thomas',
                lastName: 'Leimert'
            },
            {
                image: Friend4,
                firstName: 'Eleanor',
                lastName: 'Leimert'
            }
            // {
            //     image: Friend2,
            //     firstName: "Thomas",
            //     lastName: "Leimert",
            // },
        ],
        time: moment().add(-6, 'day'),
        message:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel voluptatem iure ex quod. Ducimus expedita eligendi dicta repellendus delectus in sunt quia ullam? Enim fugiat voluptate consequatur.'
    },
    {
        id: 4,
        notificationCount: 4,
        members: [
            {
                image: Friend3,
                firstName: 'Thomas',
                lastName: 'Leimert'
            },
            {
                image: Friend2,
                firstName: 'Eleanor',
                lastName: 'Leimert'
            },
            {
                image: Friend1,
                firstName: 'Thomas',
                lastName: 'Leimert'
            }
            // {
            //     image: Friend4,
            //     firstName: "Thomas",
            //     lastName: "Leimert",
            // },
            // {
            //     image: Friend2,
            //     firstName: "Thomas",
            //     lastName: "Leimert",
            // },
        ],
        time: moment().add(-4, 'day'),
        message:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel voluptatem iure ex quod. Ducimus expedita eligendi dicta repellendus delectus in sunt quia ullam? Enim fugiat voluptate consequatur.'
    },
    {
        id: 5,
        notificationCount: 4,
        members: [
            {
                image: Friend3,
                firstName: 'Thomas',
                lastName: 'Leimert'
            },
            {
                image: Friend2,
                firstName: 'Eleanor',
                lastName: 'Leimert'
            },
            {
                image: Friend1,
                firstName: 'Thomas',
                lastName: 'Leimert'
            },
            {
                image: Friend4,
                firstName: 'Eleanor',
                lastName: 'Leimert'
            },
            {
                image: Friend2,
                firstName: 'Thomas',
                lastName: 'Leimert'
            }
        ],
        time: moment().add(-2, 'day'),
        message:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel voluptatem iure ex quod. Ducimus expedita eligendi dicta repellendus delectus in sunt quia ullam? Enim fugiat voluptate consequatur.'
    },
    {
        id: 6,
        notificationCount: 1,
        members: [
            {
                image: Friend3,
                firstName: 'Thomas',
                lastName: 'Leimert'
            }
        ],
        time: moment().add(-10, 'day'),
        message:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel voluptatem iure ex quod. Ducimus expedita eligendi dicta repellendus delectus in sunt quia ullam? Enim fugiat voluptate consequatur.'
    }
];
