import React from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { ReactComponent as IndentIcon } from "Assets/images/MeditationRooms/indent.svg"
import { ReactComponent as OutdentIcon } from "Assets/images/MeditationRooms/outdent.svg"
import { ReactComponent as OrderListIcon } from "Assets/images/MeditationRooms/list-ordered.svg"
import { ReactComponent as UnorderedListIcon } from "Assets/images/MeditationRooms/list.svg"

import { Box, Typography, Button } from '@mui/material';
import { TaoButton } from 'Components/common/Button.style';
import { AnnouncementWrapper } from './RoomDetails.style';
import moment from 'moment';

const Announcement = ({ ...props }) => {
    return (
        <AnnouncementWrapper>
            <Box className="post-content-container">
                <Box className="title-container">
                    <Typography>Post New Announcement</Typography>
                </Box>
                <CustomToolBar />
                <ReactQuill
                    // theme={"snow"}
                    id="new-post-quill-content"
                    rows="5"
                    modules={modules}
                    formats={formats}
                />
                <TaoButton className="post-btn">POST NOW</TaoButton>
                <Box className="posts-list-container">
                    {
                        POSTS.map(p => {
                            return <Box className="post-container">
                                <Box className="date-container">
                                    <Typography className="month">{p.postTime.format("MMM")}</Typography>
                                    <Typography className="date">{p.postTime.format("DD")}</Typography>
                                    <Typography className="time">{p.postTime.format("hh:mm A")}</Typography>
                                </Box>
                                <Box className="post-text-container">
                                    <Typography className='text'>{p.postText}  </Typography>
                                    <Button className="more-btn" variant="text">More »</Button>
                                </Box>
                            </Box>
                        })
                    }
                </Box>
            </Box>

        </AnnouncementWrapper>
    );
};

export default Announcement;

const CustomToolBar = ({ ...props }) => {

    return <>
        <Box id="toolbar">
            <button className="ql-bold quill-btn">B</button>
            <button className="ql-italic quill-btn">I</button>
            <button className="ql-underline quill-btn">U</button>
            <button className="ql-strike quill-btn">S</button>

            <button className="ql-unOrderList quill-btn"><UnorderedListIcon /></button>
            <button className="ql-orderList quill-btn"><OrderListIcon /></button>

            <button className="ql-customIndent quill-btn" ><IndentIcon /></button>
            <button className="ql-outdent quill-btn" ><OutdentIcon /></button>
        </Box>
    </>
}


const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // "color",
    "align",
];

const modules = {
    toolbar: {
        container: '#toolbar',
        handlers: {
            unOrderList: function (params) {
                const listFormat = this.quill.getFormat()?.list;
                if (listFormat === "bullet") {
                    this.quill.format("list", false);
                } else {
                    this.quill.format("list", "bullet");
                }

            },

            orderList: function () {
                const listFormat = this.quill.getFormat()?.list;
                if (listFormat === "ordered") {
                    this.quill.format("list", false);
                } else {
                    this.quill.format("list", "ordered");
                }
            },

            outdent: function () {
                this.quill.format("indent", "-1");
            },
            customIndent: function () {
                this.quill.format("indent", "+1");
            },

        }
    },
    keyboard: {
        bindings: {
            tab: {
                key: 9,
                handler: function () {
                    alert("Tab is disabled please use indentation from above toolbar")
                }
            }
        }
    }
}

const POSTS = [
    {
        postTime: moment().add(-2, 'd'),
        postText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum lacinia luctus risus pharetra dui elementum ornare. Arcu ut interdum ullamcorper"
    },
    {
        postTime: moment().add(-3, 'd'),
        postText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum lacinia luctus risus pharetra dui elementum ornare. Arcu ut interdum ullamcorper"
    },
    {
        postTime: moment().add(-5, 'd'),
        postText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum lacinia luctus risus pharetra dui elementum ornare. Arcu ut interdum ullamcorper"
    }
]