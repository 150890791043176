import { Box, TextField } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import styled from 'styled-components';

export const ReferralStoreWrapper = styled(Box)`
    .title {
        font-size: 40px;
        font-family: ${FONTS.PRIMARY_BOLD};
        color: ${COLORS.PRIMARY_DARK};

        ${responsive.TABLET`
            font-size: 32px;
        `}
    }

    .search-input-button {
        width: 100%;
        gap: 15px;

        .search-go-button {
            border-radius: 27px;
            height: 100%;
            width: 100px;

            ${responsive.TABLET`
                width: 50px;
            `}
        }
    }

    .history-filter-tab {
        gap: 10px;
        margin: 10px 0;
        overflow-x: auto;
        ::-webkit-scrollbar {
            display: none;
        }

        .filter-tab {
            background-color: ${COLORS.LIGHT_GREY_SECONDARY};
            width: max-content;
            padding: 10px 20px;
            border-radius: 22px;
            border: 2px solid ${COLORS.LIGHT_GREY_SECONDARY};
            height: 45px;
            padding: 0 20px;

            &.active {
                border-color: ${COLORS.PRIMARY};
                background-color: transparent;
            }

            ${responsive.TABLET`
                height: 40px;
            `}

            .filter-text {
                color: ${COLORS.MEDIUM_GREY};
                font-size: 16px;
                font-family: ${FONTS.PRIMARY_MEDIUM};
                text-transform: uppercase;

                &.active {
                    color: ${COLORS.PRIMARY};
                }

                ${responsive.TABLET`
                    font-size: 14px;
                `}
            }
        }
    }

    .store-products {
        /* display: grid;
        grid-template-columns: auto auto auto;
        gap: 20px; */
        height: calc(100% - 250px);
        overflow-x: auto;
        padding: 0 0 50px 0;

        ::-webkit-scrollbar {
            display: none;
        }

        .inner-child {
            display: grid;
            grid-template-columns: auto auto auto;
            gap: 20px;
            margin: 0 0 20px;

            ${responsive.PHABLET`
                gap: 10px;
            `}

            .item {
                position: relative;
                min-width: 100%;

                .product-image {
                    position: relative;
                    height: 220px;
                    width: 100%;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    border-radius: 22px;
                    overflow: hidden;
                    z-index: 10;
                    box-shadow: 4.24px 4.24px 8.48px 0px #00000026;

                    &.available-in-cart {
                        opacity: 40%;
                    }

                    ${responsive.TABLET`
                        height: 180px;
                        border-radius: 20px;
                    `}

                    ${responsive.PHABLET`
                        height: 120px;
                        border-radius: 16px;
                    `}
                    ${responsive.MOBILE`
                        height: 80px;
                    `}
    
                    .item-duration {
                        position: absolute;
                        bottom: 0;
                        background-color: ${COLORS.PRIMARY_LIGHT};
                        font-size: 20px;
                        font-family: ${FONTS.PRIMARY_BOLD};
                        color: ${COLORS.BLACK};
                        width: 100%;
                        text-align: center;
                        text-transform: uppercase;

                        ${responsive.TABLET`
                            font-size: 16px;
                        `}
                        ${responsive.PHABLET`
                            font-size: 14px;
                        `}
                        ${responsive.MOBILE`
                            font-size: 12px;
                        `}

                        &.purchased {
                            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                            color: ${COLORS.WHITE};
                        }
                    }
                }
                .cart-added-item {
                    box-shadow: 2.5px 2.5px 5.01px 0px #00000026;
                    background-color: ${COLORS.WHITE};
                    height: 36px;
                    width: 36px;
                    border-radius: 50%;
                    padding: 5px;
                    position: absolute;
                    z-index: 20;
                    top: 10px;
                    left: 10px;

                    &.purchased {
                        background: linear-gradient(324.25deg, #b08c3d 5.58%, #deb760 79.15%);
                    }
                }
                .item-desc {
                    font-size: 16px;
                    color: ${COLORS.TEXT_DARK};

                    ${responsive.PHABLET`
                        font-size: 14px;
                    `}
                    ${responsive.MOBILE`
                        font-size: 12px;
                    `}
                }
            }
        }
    }
`;

export const CustomSearchInputBox = styled(TextField)`
    width: 100%;

    .start {
        border-radius: 30px;
        background-color: ${COLORS.LIGHT_GREY};
        height: 50px;
        font-size: 20px;
        border: 2px solid transparent;
    }
    .focused {
        border-color: ${COLORS.PRIMARY};
        border-radius: 30px;
    }

    .base-root {
        font-family: ${FONTS.PRIMARY};
        font-weight: 400;
        font-size: 20px;
        color: ${COLORS.TEXT_DARK};
        height: 50px;
    }

    .notchedOutline {
        border: none;
    }
    .search-input-text-icon {
        width: 40px;
        height: 40px;
    }
`;
