import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';

import { TaoSessionCardWrapper } from './TaoSessionCard.style';
import PlaylistIcon from "Assets/images/Common/playlist-icon.svg";
import { ReactComponent as TaoCalligraphyTeacherIndicator } from "Assets/images/Common/tao-calligraphy-teacher-indicator.svg";
import { ReactComponent as SliderNextButton } from "Assets/images/Common/next-arrow.svg";

import { ReactComponent as HostIcon } from "Assets/images/Common/hosting.svg"

const TaoSessionCard = ({
    cardRootClass = "",
    sessionImage = "",
    isLive = false,
    sessionTime = "",
    sessionTitle = "",
    sessionWith = "",
    isTaoCalligraphyTeacher = "",
    youAreHost = false,
    hostingText = "You are hosting",
    // challenge related props
    isChallenge = false,
    challengeCompleted = false,
    challengeDay = 0,
    challengeName = "",
    // playlist related props
    isPlaylist = false,
    playlistDay = 0,
    totalPlaylistDays = 0,
    playlistName = "",
    ...props }) => {

    return (
        <TaoSessionCardWrapper className={cardRootClass} isTaoCalligraphyTeacher={isTaoCalligraphyTeacher}>
            <Box className="upcoming-session-image">
                <img className="avatar" src={sessionImage} alt="" width={75} height={75} />
                {isLive && <Typography color="primary" className="live-indicator">
                    LIVE
                </Typography>}

                {isPlaylist && <img className="playlist-session" src={PlaylistIcon} alt="" />}
            </Box>
            <Box className="upcoming-session-date-details">
                {!isChallenge && <Typography className="session-time">
                    {sessionTime}
                </Typography>}
                {
                    (isChallenge && challengeCompleted) && <Typography className="challenge-completed">
                        COMPLETED
                    </Typography>
                }
                <Typography className="session-title">
                    {sessionTitle}
                </Typography>
                {
                    (!youAreHost && !isChallenge && !isPlaylist) && <Typography className="session-teacher">
                        {sessionWith}
                    </Typography>
                }
                {youAreHost && <Box className="host-container">
                    <HostIcon />
                    <Typography color="primary.dark" className="hosting-text">{hostingText}</Typography>
                </Box>
                }
                {
                    isChallenge && <Typography color="GrayText">
                        Day {challengeDay} of  <em>{challengeName}</em>
                    </Typography>
                }
                {
                    isPlaylist && <Typography color="GrayText">
                        Day {playlistDay} of {totalPlaylistDays} <em>{playlistName}</em>
                    </Typography>
                }
            </Box>
            <Box className="upcoming-session-navigate">
                {isTaoCalligraphyTeacher && <TaoCalligraphyTeacherIndicator width="25" height="25" />}
                <IconButton>
                    <SliderNextButton />
                </IconButton>
            </Box>
        </TaoSessionCardWrapper>
    );
};

export default TaoSessionCard;