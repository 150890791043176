//core
import React, { useState } from 'react';
import { Box, Chip, Typography } from '@mui/material';

//custom
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import { HistoryWrapper } from './History.style';
import TaoScrollbar from 'Components/common/Scrollbar';
import Programs from './Programs/Programs';
import LightTransmission from './LightTransmission/LightTransmission';
import Meditations from './Meditations/Meditations';
import Sessions from './Sessions/Sessions';
import Challenges from './Challenges/Challenges';
import TaoLoader from 'Components/common/TaoBackdropLoader';

const CHIPS_NAME = [
    {
        id: 1,
        name: 'programs'
    },
    // {
    //     id: 2,
    //     name: 'challenges'
    // },
    // {
    //     id: 3,
    //     name: 'sessions'
    // },
    {
        id: 4,
        name: 'Meditate & Manifest'
    }
    // {
    //     id: 5,
    //     name: 'light transmissions'
    // }
];

const History = () => {
    const [loading, setLoading] = useState(false);
    const [selectedChipId, setSelectedChipId] = useState(1);
    const [cardComponent, setCardComponent] = useState({
        componentName: 'Programs',
        component: <Programs setLoading={setLoading} />
    });

    const handleCardComponent = (id) => {
        switch (id) {
            case 1:
                return {
                    componentName: 'Programs',
                    component: <Programs setLoading={setLoading} />
                };
            case 2:
                return {
                    componentName: 'Challenges',
                    component: <Challenges />
                };
            case 3:
                return {
                    componentName: 'Sessions',
                    component: <Sessions />
                };
            case 4:
                return {
                    componentName: 'Meditations',
                    component: <Meditations setLoading={setLoading} />
                };
            case 5:
                return {
                    componentName: 'LightTransmission',
                    component: <LightTransmission />
                };
            default:
                return null;
        }
    };

    const handleCardDisplay = (values) => {
        if (selectedChipId === values?.id) return;
        setSelectedChipId(values.id);
        setCardComponent(handleCardComponent(values.id));
    };

    return (
        <React.Fragment>
            <TaoCalligraphyWatermark />
            <TaoLoader isLoading={loading} />
            <HistoryWrapper>
                <Box className="content-wrapper flex f-column">
                    <Box className="title-wrapper flex">
                        <Typography className="history-title">History</Typography>
                    </Box>
                    <Box className="chip-section flex">
                        <TaoScrollbar horizontalScroll keyId="historyChips">
                            {CHIPS_NAME.map((values) => {
                                return (
                                    <Chip
                                        label={values.name}
                                        key={values.id}
                                        className={
                                            'history-chip ' +
                                            `${
                                                selectedChipId === values.id
                                                    ? 'chip-selected'
                                                    : 'chip-unselected'
                                            } `
                                        }
                                        variant={
                                            selectedChipId === values.id ? 'outlined' : 'filled'
                                        }
                                        onClick={() => handleCardDisplay(values)}
                                    />
                                );
                            })}
                        </TaoScrollbar>
                        <Box className="light-shadow"></Box>
                    </Box>
                    <Box className="card-section">{cardComponent.component}</Box>
                </Box>
            </HistoryWrapper>
        </React.Fragment>
    );
};

export default History;
