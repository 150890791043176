import { Box } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import styled from 'styled-components';
import QuoteIcon from 'Assets/images/Common/quote.svg';
import Flower from 'Assets/images/Signup/sales-page-flower-bg.svg';

export const SalesPageTwoWrapper = styled(Box)`
    /* background: linear-gradient(180deg, rgba(75, 161, 188, 0) 0%, rgba(75, 161, 188, 0.7) 100%); */
    height: 100%;

    .container {
        display: flex;
        flex-direction: column;
        z-index: 20;
        /* background-color: #ffffff; */
        /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); */
        /* border-radius: 15px 15px 15px 15px; */
        height: auto;
        width: 45%;
        padding: 5%;
        margin: 0 auto;

        ${responsive.DISPLAY`
            width: 60%;
        `};
        ${responsive.DESKTOP`
            width: 70%;
        `};
        ${responsive.LAPTOP`
            width: 80%;
        `};
        ${responsive.TABLET`
            background-color: transparent;
            width: 90%;
            box-shadow: none;
            border-radius: none;
            padding: 80px 0 0;
        `};
        ${responsive.PHABLET`
            width: 95%;
        `};

        .title {
            color: ${COLORS.PRIMARY_DARK};
            font-family: ${FONTS.SECONDARY};
            font-size: 30px;
            font-weight: 700;
            text-transform: capitalize;

            ${responsive.PHABLET`
                font-size: 28px;
            `}

            .wellness-library-highlight {
                font-size: 40px;
            }
        }
        .sub-title {
            color: ${COLORS.TEXT_DARK};
            font-size: 18px;
        }

        .wellness-list-loader-wrapper {
            height: 150px;
            width: 100px;
            min-width: 100px;
            display: flex !important;
            flex-direction: column;
            align-items: center;

            .loader-circle {
                width: 90px;
                height: 90px;
            }
        }

        .wellness-content {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            height: 150px;
            width: 100px;
            text-align: center;

            .wellness-icon-button {
                height: 90px;
                width: 90px;

                &:hover {
                    .wellness-icon,
                    .wellness-ribbon-icon {
                        transition: all 0.7s ease-in-out;
                        transform: scale(1.1);
                    }
                }
                .wellness-icon {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    height: 70px;
                    width: 70px;

                    ${responsive.DISPLAY`
                        height: 60px;
                        width: 60px;
                    `}
                }

                .wellness-ribbon-icon {
                    position: absolute;
                    top: 14%;
                    width: 40%;
                    height: 40%;
                    left: 5%;
                    z-index: 10;
                    background-position: center;
                    background-repeat: no-repeat;

                    ${responsive.DISPLAY`
                        left: 10%;
                    `}
                }
            }

            .wellness-category-name {
                color: ${COLORS.MEDIUM_GREY};
                width: 100px;
                font-size: 16px;
                line-height: 18px;
                overflow: hidden;
                word-wrap: break-word;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                ${responsive.PHABLET`
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18px;
                `}
            }
        }

        .meditation-contents {
            display: flex;
            flex-direction: column;
            position: relative;

            .category-see-all {
                font-family: ${FONTS.PRIMARY};
                font-size: 16px;
                margin: 0 0 0 auto;
                color: ${COLORS.MEDIUM_GREY};
                cursor: pointer;
                z-index: 30;
                position: relative;
            }

            .content-type-container {
                display: flex;
                align-items: center;

                .content-icons {
                    height: 30px;
                    display: flex;
                    align-items: center;
                    margin: 0 10px 0 0;
                }

                .content-type-text {
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    font-size: 24px;
                    line-height: 26px;
                    color: ${COLORS.PRIMARY};

                    ${responsive.PHABLET`
                        font-size: 18px;
                    `}
                }
            }

            .card-list-container {
                .meditation-contents-list-loader-wrapper {
                    width: 240px;
                    height: 149px;
                    display: flex !important;
                    flex-direction: column;
                    justify-content: space-between;
                }

                .placeholder-box-wrapper {
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    width: 210px !important;
                    height: 120px !important;
                    border-radius: 16px 8px;
                    background: #f6f6f6;

                    .placeholder-box-text {
                        font-family: ${FONTS.PRIMARY};
                        font-size: 16px;
                        line-height: 18px;
                        text-align: center;
                        color: ${COLORS.MEDIUM_GREY};
                    }
                }
            }

            .load-more-button-container {
                height: 120px;
                display: flex;
                padding: 0 15px;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                z-index: 30;

                &:hover {
                    .next-arrow-icon {
                        transition: all 0.2s ease-in-out;
                        margin: 0 0 0 7px;
                    }
                }

                .load-more {
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 400;
                    font-size: 16px;
                    color: ${COLORS.PRIMARY};
                    cursor: pointer;
                    width: max-content;

                    ${responsive.PHABLET`
                    width: 22%;
                `}
                }
                .next-arrow-icon {
                    margin: 0 0 0 5px;
                }
            }
        }

        .wisdom-slider {
            width: 100%;
            margin: 0 auto;

            .wisdom-title {
                font-size: 40px;
                font-family: ${FONTS.SECONDARY_BOLD};
                color: ${COLORS.PRIMARY_DARK};
            }

            .wisdom-section {
                display: flex;
                flex-direction: column;
                position: relative;
                height: 490px;
                width: 300px !important;
                border-radius: 10px;
                margin-inline: 4px;
                margin-bottom: 2px;
                border-radius: 16px;
                box-shadow: 0px 4px 16px 0px #00000026;
                margin: 20px 10px;
                overflow: hidden;

                ${responsive.MOBILE`
                    width: 250px !important;
                    height: 400px;
                `};

                .card-media {
                    height: 200px;
                    background-color: #f6f6f6;

                    .default-image {
                        height: 50%;
                        width: 50%;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                    ${responsive.MOBILE`
                        height: 150px;
                    `};
                }

                .card-content {
                    display: flex;
                    align-items: center;
                    height: calc(100% - 200px - 30px);
                    flex-direction: column;
                    padding-block: 0;
                    padding: 15px;

                    ${responsive.MOBILE`
                        height: 220px;
                    `};

                    .quote-date {
                        color: ${COLORS.TEXT_DARK};
                        font-family: ${FONTS.PRIMARY};
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0.7px;
                        text-align: center;
                        margin-bottom: 14px;

                        ${responsive.MOBILE`
                            font-size: 14px;
                            margin-bottom:18px;
                            line-height: 18px;
                            letter-spacing: 1px;
                        `};
                    }

                    .wisdom-quote {
                        color: ${COLORS.TEXT_DARK};
                        font-family: ${FONTS.QUINARY};
                        margin-bottom: 16px;
                        max-height: 240px;
                        font-size: 24px;
                        font-style: italic;
                        font-weight: 400;
                        line-height: 26px;
                        letter-spacing: 0px;
                        text-align: center;
                        white-space: pre-wrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;

                        ${responsive.MOBILE`
                            font-size: 14px;
                            
                        `};
                    }

                    .quote-by {
                        font-family: ${FONTS.PRIMARY};
                        color: ${COLORS.MEDIUM_GREY};
                        font-size: 18px;
                        margin-bottom: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0px;
                        text-align: center;
                        white-space: pre-wrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;

                        ${responsive.MOBILE`
                            font-size: 12px;
                            line-height: 18px;
                        `};
                    }
                }

                .card-action {
                    display: flex;
                    justify-content: flex-end;
                    /* padding: 0 15px; */

                    .card-action-button {
                        font-family: ${FONTS.PRIMARY};
                        color: ${COLORS.MEDIUM_GREY};
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 14px;

                        .button-text {
                            text-decoration: none;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .testimonials {
            width: 70%;
            margin: 100px auto 200px;
            z-index: 20;

            ${responsive.TABLET`
                margin: 100px auto 250px;
            `}

            .quote-image {
                background-image: url(${QuoteIcon});
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                height: 50px;
                width: 50px;
                margin: 0 auto 20px;
            }
            .slide-text {
                color: ${COLORS.TEXT_DARK};
                font-size: 24px;

                &.primary {
                    color: ${COLORS.PRIMARY};
                    font-family: ${FONTS.SECONDARY_BOLD};
                    font-size: 40px;
                }
            }

            .author-by-dash {
                width: 10px;
                height: 2px;
                background-color: ${COLORS.TEXT_DARK};
                margin: 0 2px 0 0;
            }
        }
    }

    .footer-container {
        position: fixed;
        width: 100%;
        bottom: 0;
        height: 200px;
        z-index: 200;
        // background: linear-gradient(180deg, rgba(75, 161, 188, 0) 0%, rgba(75, 161, 188, 0.7) 100%);
        background-color: ${COLORS.WHITE};

        .shadow {
            height: 20px;
            position: absolute;
            width: 35%;
            top: 5px;
            right: 50%;
            transform: translate(50%, -50%);
            background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #d9d9d9 100%);

            ${responsive.DISPLAY`
                width: 50%;
            `};
            ${responsive.DESKTOP`
                width: 60%;
            `};
            ${responsive.LAPTOP`
                width: 70%;
            `};
            ${responsive.TABLET`
                width: 90%;
            `};
            ${responsive.PHABLET`
                width: 95%;
            `};
        }
    }
    .redirection-links {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        bottom: 70px;
        transform: translate(-50%, -0%);
        left: 50%;
        z-index: 210;

        ${responsive.DESKTOP`
            width: 60%;
        `}

        ${responsive.LAPTOP`
            width: 80%;
        `}

        ${responsive.MOBILE`
            width: 90%;
        `}

        .redirection-btn {
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(10px);
            border-radius: 12px;
            height: 60px;
            width: 370px;
            color: ${COLORS.WHITE};
            font-size: 16px;
            text-transform: uppercase;
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};

            ${responsive.MOBILE`
                width: 200px;
            `}
        }

        .footer-text {
            color: ${COLORS.WHITE};
            font-family: ${FONTS.PRIMARY};
            font-weight: 700;
            font-size: 18px;
            margin: 10px 0 0;
            text-decoration: underline;

            &:hover {
                cursor: pointer;
            }

            &.divider {
                padding: 0 5px;
            }
        }
    }
    .flower-image {
        background-image: url(${Flower});
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: fixed;
        bottom: 0;
        right: 0;
        height: 250px;
        width: 500px;
        z-index: 201;

        ${responsive.TABLET`
            height: 220px;
            width: 350px;
        `}
        ${responsive.PHABLET`
            height: 180px;
            width: 300px;
        `}
    }
    .bottom-gradient {
        // background: linear-gradient(180deg, rgba(75, 161, 188, 0) 0%, rgba(75, 161, 188, 0.7) 100%);
        background: linear-gradient(180deg, rgba(75, 161, 188, 0) 0%, rgba(75, 161, 188, 0.7) 100%);
        height: 382px;
        position: fixed;
        width: 100%;
        z-index: 200;
        bottom: 0;

        ${responsive.LAPTOP`
            height: 40%;
        `}
    }
`;
