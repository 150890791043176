import React from 'react';
import { Box, Typography } from '@mui/material';
import { CartBottomWrapper } from './CartBottom.style';
import { useNavigate } from 'react-router-dom';
import { URL_ORDER_HISTORY, URL_YOUR_CART } from 'Helpers/Paths';
import { CartImageActiveSVG, CartImageSVG, OrderSVG } from './SvgImages';

const COUNT_MAX_LIMIT = 9;

const CartBottom = ({ totalCartItem, isLoading, ...props }) => {
    const navigate = useNavigate();
    return (
        <CartBottomWrapper className="carts-and-order flex f-v-center f-h-center">
            <Box
                className="flex f-column f-v-center hover"
                onClick={() => navigate(URL_ORDER_HISTORY)}>
                <OrderSVG />
                <Typography className="icon-indicator">My Orders</Typography>
            </Box>

            <Box
                className="flex f-column f-v-center hover relative"
                onClick={() => navigate(URL_YOUR_CART)}>
                {totalCartItem ? <CartImageActiveSVG /> : <CartImageSVG />}
                {!isLoading && (
                    <Box
                        className={`count-container flex f-v-center f-h-center absolute ${
                            totalCartItem && 'active'
                        } ${totalCartItem > COUNT_MAX_LIMIT && 'max-count-reached'}`}>
                        <Typography className="count-number">
                            {totalCartItem > COUNT_MAX_LIMIT
                                ? `${COUNT_MAX_LIMIT}+`
                                : totalCartItem}
                        </Typography>
                    </Box>
                )}
                <Typography className="icon-indicator">Cart</Typography>
            </Box>
        </CartBottomWrapper>
    );
};

export default CartBottom;
