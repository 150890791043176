//core
import React, { useMemo, useState } from 'react';
import { Box, Paper, CardContent, CardActions, Typography, IconButton } from '@mui/material';
import moment from 'moment';
import DOMPurify from 'dompurify';
import Highlighter from 'react-highlight-words';
import { motion, useAnimation } from 'framer-motion';

//icons
import { ReactComponent as RightSideArrowIcon } from 'Assets/images/Common/next-arrow.svg';
import { ReactComponent as DeleteIconImg } from 'Assets/images/Journal/delete-white-color.svg';
// import { ReactComponent as PinnedIconImg } from 'Assets/images/Journal/pinned.svg';
import { ReactComponent as PinnedIconImg } from 'Assets/images/Chat/pinned-icon.svg';
import { ReactComponent as UnPinnedIconImg } from 'Assets/images/Journal/unpinned.svg';

//custom
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { DeleteConfirmation } from 'Components/common/Modal/Delete';
import { useDispatch } from 'react-redux';
import { showToast } from 'Redux/App/Actions';

const DELETE_THRESHOLD = -100;
const PIN_UNPIN_THRESHOLD = 100;
const DELETE_ICON_THRESHOLD = -50;
const PIN_UNPIN_ICON_THRESHOLD = 50;
const TIME_DURATION = 0.7;

const JournalCard = (props) => {
    const API = useMemo(() => new Api(), []);
    const controls = useAnimation();
    const dispatch = useDispatch();
    const MyMonth = moment(props.date, 'YYYY MM DD').format('MMM');
    const MyDay = moment(props.date, 'YYYY MM DD').format('DD');

    const [deleteIconState, setDeleteIconState] = useState(false);
    const [pinIconState, setPinIconState] = useState(false);
    const [unPinIconState, setUnPinIconState] = useState(false);

    const [deleteModal, setDeleteModal] = useState(false);

    const cardSelectionHandler = () => {
        props.setCardSelect(props.id);
    };

    const closeDeleteModal = () => {
        controls.start({ x: '0%' });
        setDeleteModal(false);
        setTimeout(() => {
            setDeleteIconState(false);
        }, 500);
    };

    const deleteCardHandler = async () => {
        let response = await API.delete(API_URL.JOURNAL_API, {
            params: { journalId: props.id }
        });
        if (response) {
            props.setRefreshData((prevState) => !prevState);
            props.setDateValue(moment(new Date()).format('MMM D YYYY'));
            dispatch(showToast(response?.data?.message));
            props.EmptyNoteHandler();
        }
    };

    const pinUnpinHandler = async () => {
        let response = await API.post(API_URL.JOURNAL_PIN, {
            params: { journalId: props.id }
        });
        if (response) {
            props.setRefreshData((prevState) => !prevState);
            return;
        }
    };

    const handleDragEvent = async (event, info) => {
        if (info.offset.x <= DELETE_THRESHOLD) {
            //delete card
            await controls.start({ x: '-100%' });
            setDeleteModal(true);
        } else if (info.offset.x >= PIN_UNPIN_THRESHOLD) {
            // pin/unpin card
            controls.start({ x: '100%' });
            pinUnpinHandler();
        } else {
            // props.setCardSelect(props.id);
            setDeleteIconState(false);
            if (!props.isPinned) {
                setPinIconState(false);
            } else {
                setUnPinIconState(false);
            }
        }
    };

    const monitorDragEvent = (event, info) => {
        //delete icon
        if (info.offset.x <= DELETE_ICON_THRESHOLD) {
            setDeleteIconState(true);
        } else {
            setDeleteIconState(false);
        }
        //pinned icon
        if (!props.isPinned) {
            if (info.offset.x >= PIN_UNPIN_ICON_THRESHOLD) {
                setPinIconState(true);
            } else {
                setPinIconState(false);
            }
        }

        if (props.isPinned) {
            if (info.offset.x >= PIN_UNPIN_ICON_THRESHOLD) {
                setUnPinIconState(true);
            } else {
                setUnPinIconState(false);
            }
        }
    };

    return (
        <React.Fragment>
            <Box
                className={`swipe-card ${
                    props.cardSelect === props.id && 'selected-card'
                } card-bg-color`}
                onClick={cardSelectionHandler}>
                {props.isPinned === true ? (
                    <UnPinnedIconImg
                        className="swipe-unpin-icon"
                        style={unPinIconState ? { display: 'inline-block' } : { display: 'none' }}
                    />
                ) : (
                    <PinnedIconImg
                        className="swipe-pin-icon"
                        style={pinIconState ? { display: 'inline-block' } : { display: 'none' }}
                    />
                )}
                <DeleteIconImg
                    className="swipe-delete-icon"
                    style={deleteIconState ? { display: 'inline-block' } : { display: 'none' }}
                />
                <motion.div
                    className="drag-div"
                    drag="x"
                    animate={controls}
                    onDrag={monitorDragEvent}
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={0.8}
                    onDragEnd={handleDragEvent}
                    transition={{ duration: TIME_DURATION }}>
                    {props.isPinned === true ? (
                        <>
                            <Box className="swipe-unpin flex f-v-center"></Box>
                        </>
                    ) : (
                        <>
                            <Box className="swipe-pin flex f-v-center"></Box>
                        </>
                    )}
                    <Box className="swipe-delete "></Box>
                    <Box className="flex card hover">
                        <CardContent className="flex CardContent elipsis">
                            <Paper className="flex f-v-center f-h-center f-column paper-date">
                                <Typography className="CardMonth">{MyMonth}</Typography>
                                <Typography className="CardDay">{MyDay}</Typography>
                            </Paper>
                            <Box className="CardTextArea">
                                <Typography className="elipsis card-title">
                                    <Highlighter
                                        className=""
                                        highlightClassName="Highlight-title"
                                        searchWords={props.searchKeyword.split(' ')}
                                        textToHighlight={props.title ? props.title : ' '}
                                    />
                                </Typography>
                                <Typography className="card-para">
                                    <Highlighter
                                        className="card-description"
                                        highlightClassName="Highlight-body"
                                        searchWords={props.searchKeyword.split(' ')}
                                        textToHighlight={DOMPurify.sanitize(
                                            props.body.replace(/&nbsp;/gi, ''),
                                            {
                                                USE_PROFILES: { html: false }
                                            }
                                        )}
                                    />
                                </Typography>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <IconButton className="action-icons">
                                <RightSideArrowIcon style={{ width: '8px', height: '14px' }} />
                            </IconButton>
                        </CardActions>
                    </Box>
                </motion.div>
            </Box>
            {deleteModal && (
                <DeleteConfirmation
                    title="Delete this journal entry?"
                    subTitle="(This cannot be undone)"
                    onClose={() => closeDeleteModal()}
                    onConfirm={deleteCardHandler}
                />
            )}
        </React.Fragment>
    );
};

export default JournalCard;
