import styled from 'styled-components';
import NotificationBackground from 'Assets/images/Notifications/notification-background.svg';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import { Box } from '@mui/system';
import { Dialog } from '@mui/material';

export const NotificationWrapper = styled.div`
    background-image: url(${NotificationBackground});
    height: 100%;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;

    ${responsive.DESKTOP`
        background-position: left;
    `}

    .load-more {
        width: max-content;
        margin: 0 auto;
        padding: 0 10px 0 0;

        &:hover {
            .load-more-arrow {
                transition: all 0.3s ease-in-out;
                margin: 5px 0 0;
            }
        }

        .load-more-text {
            color: ${COLORS.PRIMARY};
        }
        .load-more-arrow {
            width: 10px;
            height: 10px;
            border-right: 1px solid ${COLORS.PRIMARY};
            border-bottom: 1px solid ${COLORS.PRIMARY};
            transform: rotate(45deg);
        }
    }

    .container {
        width: 36%;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 100px 0 0;

        ${responsive.DESKTOP`
            width: 47%;
        `}

        ${responsive.LAPTOP`
            width: 57%;
        `}

        ${responsive.TABLET`
            padding: 70px 0 0;
            width: 70%;
        `}
        ${responsive.PHABLET`
            width: 100%;
        `}

        .title {
            color: ${COLORS.PRIMARY};
            font-family: ${FONTS.SECONDARY};
            font-weight: 700;
            font-size: 40px;

            ${responsive.TABLET`
                padding: 0 20px;
            `}
        }
    }

    .arrow-div {
        padding: 0px 20px;
        cursor: pointer;
        display: none;

        ${responsive.TABLET`
            display: flex;
        `}
    }

    .empty-title {
        font-family: ${FONTS.PRIMARY};
        font-size: 28px;
        color: ${COLORS.GREY_TEXT_COLOR};
    }

    .alert-list {
        background-color: #f2f2f2;
        padding: 24px 0;
        border-radius: 24px;
        margin-top: 10px;
        height: 70%;

        ${responsive.TABLET`
            background-color: unset;
            padding: 0 10px;
            height: 80%;
        `}
    }

    .clear_all {
        height: 40px;
        width: 150px;
        color: #848484;
        display: flex;
        border-radius: 20px;
        display: flex;
        padding: 5px 6px;
        justify-content: space-around;
        align-items: center;
        font-family: ${FONTS.PRIMARY};
        margin: 10px 5px;
        cursor: pointer;
        text-transform: uppercase;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);

        ${responsive.TABLET`
            border: 1px solid #E8E8E8;
            background-color: #fff;
            width: 120px;
        `}

        &:hover {
            background-color: transparent;
        }
    }
`;

export const NotificationItemWrapper = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .collapse-root {
        width: 100%;
    }

    .border-line-one {
        height: 5px;
        width: 100%;
        border-radius: 6px;
        position: relative;
        background-color: ${COLORS.PRIMARY};
        z-index: 5;

        &.hide-line {
            background-color: transparent;
        }
    }

    .border-line-two {
        height: 20px;
        width: 95%;
        border-radius: 12px;
        position: relative;
        background-color: #e0e0e0;
        z-index: 3;
        top: -12px;
    }

    .border-line-three {
        height: 18px;
        width: 90%;
        border-radius: 12px;
        position: relative;
        background-color: #d6d6d6;
        z-index: 2;
        top: -20px;
    }

    .type-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        transition: 1s;
        transition-timing-function: ease;
        margin-bottom: 10px;

        ${responsive.TABLET`
            justify-content: flex-end;
        `}

        .type {
            font-size: 21px;
            color: ${COLORS.PRIMARY};
            font-family: ${FONTS.PRIMARY};
            text-transform: capitalize;

            ${responsive.TABLET`
                display: none;
            `}
        }
        .actions {
            ${responsive.PHABLET`
                width: 100%;
                justify-content: space-between;
            `}
        }
        .open-div {
            height: 40px;
            width: 100px;
            background-color: #fff;
            color: #848484;
            display: flex;
            padding: 5px;
            justify-content: space-around;
            align-items: center;
            font-family: ${FONTS.PRIMARY};
            margin: 2px 5px;
            background: #ffffff;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
            border-radius: 19px;
        }
    }

    .notification {
        position: relative;
        padding: 6px 0;
        height: 100px;
        width: 100%;
        background-color: ${COLORS.WHITE};
        border-radius: 12px;
        z-index: 5;
        border: 1px solid #e0e0e0;
        display: flex;
        align-items: center;

        &.list-item {
            margin: 0 0 15px;
            background-color: #e8e8e8;
            border: 1px solid #e8e8e8;
        }

        &.single-notification {
            margin: 0 0 15px;
        }
        &.no-unread-notification {
            background-color: ${COLORS.LIGHT_GREY};
        }

        .container-width {
            width: 100%;

            .float-div {
                position: absolute;
                left: -15px;
                height: 85px;
                width: 30px;
                display: flex;
                align-items: center;

                .float-count {
                    background-color: #bc1218;
                    height: 30px;
                    width: 30px;
                    position: absolute;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                }
            }
            .image {
                width: ${(props) => (props.isSnackbarNotification ? '20%' : '15%')};
                margin: 0 20px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                height: 50px;
                width: 50px;
                align-self: center;
                border-radius: 8px;
                position: relative;

                ${responsive.PHABLET`
                    margin: 0 10px;
                `}

                .program-icon {
                    position: absolute;
                    height: 30px;
                    width: 30px;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    top: 50%;
                    right: 50%;
                    transform: translate(50%, -50%);
                }
            }
            .description {
                width: 75%;
                padding: 12px 20px 12px 0;

                .name {
                    color: ${COLORS.PRIMARY};
                }

                .message {
                    font-size: 15px;
                    font-family: ${FONTS.PRIMARY_MEDIUM};
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: ${COLORS.PRIMARY_WARM};
                }

                .time-ago {
                    font-size: 15px;
                    font-family: ${FONTS.PRIMARY};
                    color: ${COLORS.GREY_TEXT_COLOR};
                }
            }
        }

        .close {
            width: 10%;
            padding: 12px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
        }
    }
`;

export const ClearModalWrapper = styled(Dialog)`
    .paper {
        height: 340px;
        width: 400px;
        border-radius: 25px;
        padding: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        ${responsive.PHABLET`
            width: 80%;
            height: 250px;
        `}
    }

    .title {
        font-size: 24px;
        font-family: ${FONTS.PRIMARY};
        color: #0f172a;
        font-weight: 500;
        width: ${(props) => (props?.$isSingleNeedToRemove ? '80%' : '50%')};
        margin: 0 auto;
        text-transform: uppercase;

        ${responsive.PHABLET`
            font-size: 18px;
        `}
    }

    .buttonPadding {
        margin: 16px 0 12px;
    }

    .keep-btn {
        color: ${COLORS.TEXT_DARK};
        font-size: 16px;
        text-transform: capitalize;
    }
`;
