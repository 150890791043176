import React, { useImperativeHandle, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import DollAnimation from 'Assets/AnimationFiles/Questionnaire/MascotFormsAndGreetsUser.json';
import { Box, Typography } from '@mui/material';
import StandingDollAnimation from 'Assets/AnimationFiles/Questionnaire/MascotStandingIdleLoop.json';

import { WelcomeWrapper } from './Questions.style';

const WelcomeUser = React.forwardRef(
    ({ userDetail, questionNum, BackButtonHandler, nextClickHandler, ...props }, ref) => {
        const rootRef = useRef();
        const [isCompletedAnimation, setIsCompletedAnimation] = useState(false);
        const [loop, setLoop] = useState(false);

        useImperativeHandle(ref, () => ({
            btnWidth: rootRef.current.clientWidth,
            scrollHeight: rootRef.current.clientHeight,
            ref: rootRef.current
        }));

        return (
            <WelcomeWrapper ref={rootRef}>
                <Box className="message-container" display="flex" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                        <Typography className="welcome-header-text" color="primary">
                            Welcome, {userDetail?.firstName}!
                        </Typography>
                    </Box>

                    <Lottie
                        loop={loop}
                        onComplete={() => {
                            setIsCompletedAnimation(true);
                            setLoop(true);
                        }}
                        animationData={isCompletedAnimation ? StandingDollAnimation : DollAnimation}
                        className="lottie-animation"
                    />
                </Box>
                <Box display="flex" gap={1.5} flexDirection="column">
                    <Typography variant="h5" color="#656565" fontWeight="600">
                        Let's get to know you.
                    </Typography>
                    <Typography variant="body1" fontSize={20} color="#656565" fontWeight="400">
                        Answer just a few questions, so we can:
                    </Typography>
                    <ul className="question-list-ul">
                        <li>
                            <Typography
                                variant="body1"
                                fontSize={20}
                                color="#656565"
                                fontWeight="400">
                                Recommend our most ideal meditations for you
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                variant="body1"
                                fontSize={20}
                                color="#656565"
                                fontWeight="400">
                                Point you to LIVE community sessions you might like
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                variant="body1"
                                fontSize={20}
                                color="#656565"
                                fontWeight="400">
                                Create the perfect new releases to keep you motivated!
                            </Typography>
                        </li>
                    </ul>
                </Box>
            </WelcomeWrapper>
        );
    }
);

export default WelcomeUser;
