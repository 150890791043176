import styled from 'styled-components';
import { Box } from '@mui/material';
import { responsive, FONTS, COLORS } from 'Styles/Constants';
import ProfilePageBackgroundImage from 'Assets/images/Profile/profile-page-background-image.png';
import SelectedProfileCurve from 'Assets/images/Profile/subtract.svg';

export const ProfileSectionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    background-image: url(${ProfilePageBackgroundImage});
    background-repeat: no-repeat;

    .back-arrow-container {
        display: flex;
        position: fixed;
        left: 10px;
        height: 50px;
        width: 50px;
        top: 5px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 1002; // TOPBAR Z-INDEX : 1000

        .white-circle-wrapper {
            height: 48px;
            width: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
            border-radius: 24px;
        }
    }

    .profile-section-details {
        display: flex;
        flex-direction: row;
        margin-top: 100px;
        width: 920px;
        height: calc(100vh - 150px);
        filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.15));

        ${responsive.LAPTOP`
            width: 750px;
        `}

        ${responsive.TABLET`
            margin-top: 0;
            padding-top: 70px;
            width: 100%;
            height: 100%;
            overflow: hidden;
            filter: none;
        `}

        .profile-page-menu-list {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 40%;
            transition: all 0.5s;

            ${responsive.TABLET`
                display: ${(props) => (props?.$menuDisplay ? 'none' : 'flex')};
                width: 70%;
                margin: 0 auto;
            `}

            ${responsive.PHABLET`
                display: ${(props) => (props?.$menuDisplay ? 'none' : 'flex')};
                width: 100%;
                padding: 0 15px 0 15px;
            `}

            .profile-title-section {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                justify-content: center;

                ${responsive.TABLET`
                    justify-content: flex-start;
                    padding: 0 20px;
                `}
                .profile-title {
                    font-family: ${FONTS.SECONDARY};
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 40px;
                    letter-spacing: -0.02em;
                    text-transform: capitalize;
                    color: ${COLORS.PRIMARY_DARK};
                }

                .view-my-profile-button {
                    background: ${COLORS.PRIMARY};
                    border-radius: 8px;
                    height: 28px;

                    &.disabled {
                        opacity: 0.5;
                    }

                    ${responsive.TABLET`
                        display: none;
                    `}
                }
                .profile-btn-root {
                    font-family: ${FONTS.PRIMARY};
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    color: ${COLORS.WHITE};

                    ${responsive.PHABLET`
                        font-size: 12px;
                    `}
                }
            }

            .profile-menu-list {
                display: flex;
                flex-direction: column;
                height: 100%;
                z-index: 10;
                position: relative;

                ${responsive.TABLET`
                    background: #FFFFFF;
                    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
                    border-radius: 30px 30px 0px 0px;
                    padding: 20px 0 70px;
                `}
                ${responsive.PHABLET`
                    background: transparent;
                    box-shadow: none;
                    border-radius: none;
                `}

                .profile-view-tablet-section {
                    display: none;
                    padding: 0;

                    ${responsive.TABLET`
                        padding: 10px 20px;
                        display: flex;
                    `}

                    .user-profile-image {
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        height: 100px;
                        width: 100px;
                        min-width: 100px;
                        border-radius: 50%;
                        border: 3px solid ${COLORS.PRIMARY};
                        margin: 0 30px 0 0;
                    }

                    .user-name {
                        font-size: 40px;
                        font-family: ${FONTS.SECONDARY_BOLD};
                        color: ${COLORS.PRIMARY};
                        text-transform: capitalize;
                        word-wrap: break-word;
                    }

                    .user-email {
                        font-size: 14px;
                        color: ${COLORS.MEDIUM_GREY};
                    }
                }
                .border {
                    z-index: 20;
                    position: absolute;
                    transform: translate(0, -20px);
                    right: 0;
                    height: 20px;
                    width: 20px;
                    background-image: url(${SelectedProfileCurve});
                    visibility: hidden;
                    margin: 0 0 0 auto;
                    visibility: visible;

                    ${responsive.TABLET`
                        display: none;
                    `}

                    &.lower {
                        transform: rotate(270deg) translate(0, 0px);
                        bottom: -20px;
                    }
                }

                .white-box {
                    border-radius: 20px 0px 0px 20px;
                    position: absolute;
                    width: 100%;
                    height: 70px;
                    transition: all 0.5s;
                    background-color: ${COLORS.WHITE};

                    ${responsive.TABLET`
                        background: transparent;
                    `}
                }

                .menu-details {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;
                    cursor: pointer;
                    padding: 10px 20px;
                    height: 70px;
                    margin: 15px 0 0;
                    position: relative;

                    &.logout-margin {
                        ${responsive.TABLET`
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            margin: 40px 0 0 0;
                            background: #F8F8F8;
                            border-radius: 30px 30px 0 0;
                        `}
                    }

                    img {
                        height: 25px;
                        width: 25px;
                    }

                    .menu-name-details {
                        display: flex;
                        flex-direction: column;
                        flex: 60%;

                        .menu-name {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 40px;
                            text-transform: capitalize;
                            color: ${COLORS.PRIMARY_DARK};
                        }

                        .menu-description {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            text-transform: capitalize;
                            color: ${COLORS.MEDIUM_GREY};
                            white-space: pre-wrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
        }

        .profile-right-side-details {
            position: relative;
            border-radius: 30px 30px 0 0;
            width: 60%;

            ${responsive.TABLET`
                width: 70%;
                margin: 0 auto;
            `}

            ${responsive.PHABLET`
                width: 100%;
                box-shadow: none;
            `}
        }
    }
    .content-wrapper {
        /* position: absolute; */
        /* top: -25px; */
        z-index: 10;
        margin: 20px 0 20px 20px;

        ${responsive.MOBILE`
            top: -30px;
        `}

        .content {
            font-family: ${FONTS.SECONDARY};
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 40px;
            letter-spacing: -0.02em;
            text-transform: capitalize;
            color: ${COLORS.PRIMARY_DARK};

            /* TABLET_860 */
            ${responsive.TABLET`
                font-size: 36px;
                line-height: 36px;
            `}
        }
    }
`;

export const FooterSectionWrapper = styled(Box)`
    footer {
        background-color: #ffffff;
    }
`;

export const TopScroll = styled(Box)`
    /* border: 1px solid blue; */
    position: absolute;
    width: 100%;
    top: -150px;
`;
