import styled from 'styled-components';

import { Modal } from '@mui/material';
import { BREAKPOINTS_VALUE, FONTS } from 'Styles/Constants';

export const UnlockModalWrapper = styled(Modal)`
    &.MuiModal-root {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .MuiBackdrop-root {
        background-color: rgba(255, 255, 255, 0.4);
    }
    .dialog-container {
        width: 100%;
        max-width: 414px;
        position: relative;
        height: 35%;
        min-height: 315px;
        padding-bottom: 1rem;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* margin-top: 30px; */
        box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
        border-radius: 24px;
        z-index: 20;
        .close-btn {
            right: 35px;
            top: 30px;
            position: absolute;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                right: 6px;
                top: 20px;
            }
        }
        .content-container {
            width: 60%;
            max-width: 370px;
            min-width: 325px;
            height: 80%;
            display: flex;
            flex-direction: column;
            padding: 0 8px;
            justify-content: center;
            gap: 1rem;
            .title-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                .lock-icon {

                }
                .unlock {
                    color: #0F172A;
                    font-size: 20px;
                    line-height: 36px;
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                }
                .heart-text {
                    color: #656565;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                }

                .heart-list {
                    display: flex;
                    justify-content: space-between;
                    max-width: 100%;
                    overflow-x: auto;
                    gap: 6px;
                }
            }
            .btn-container {
                .unlock-btn {
                    gap: 8px;
                }
            }


        }
    }
`