import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import { BREAKPOINTS_VALUE, COLORS, DIMENSIONS, FONTS } from './Styles/Constants';

const breakpoints = createBreakpoints({
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
        xs: 0,
        sm: BREAKPOINTS_VALUE.PHABLET,
        md: BREAKPOINTS_VALUE.TABLET,
        lg: BREAKPOINTS_VALUE.LAPTOP,
        xl: BREAKPOINTS_VALUE.DESKTOP
    }
});

export const THEME_SETTINGS = {
    palette: {
        primary: {
            light: COLORS.PRIMARY_LIGHT,
            main: COLORS.PRIMARY,
            dark: COLORS.PRIMARY_DARK,
            warm: COLORS.PRIMARY_WARM,
            contrastText: COLORS.WHITE,
        },
        secondary: {
            light: COLORS.SECONDARY_LIGHT,
            main: COLORS.SECONDARY,
            dark: COLORS.SECONDARY_DARK
        },
        badge: {
            main: COLORS.BADGE
        },
        text: {
            // TODO: add primary, secondary and colors
            dark: COLORS.TEXT_DARK
        },
        type: 'dark'
    },
    breakpoints,
    overrides: {
        MuiAppBar: {
            root: {
                backgroundColor: '#f7f7f7',
                boxShadow: '0px 0px 10px #bdbbbb',
                height: `${DIMENSIONS.TOPBAR}px`,
                [breakpoints.down('md')]: {
                    height: '60px'
                }
            }
        },
        MuiButton: {
            contained: {
                backgroundColor: '#e2e6ea',
                boxShadow: 'none',
                borderRadius: '10px',
                color: 'white',
                fontSize: '14px',
                minHeight: '42px',
                minWidth: '150px',
                textTransform: 'none',
                '&:hover': {
                    // backgroundColor: '#dae0e5'
                },
                [breakpoints.down('lg')]: {
                    minHeight: '40px'
                },
                [breakpoints.down('sm')]: {
                    minWidth: '120px',
                    minHeight: '32px'
                }
            }
        }
    },
    typography: {
        useNextVariants: true,
        fontFamily: FONTS.PRIMARY // "Jost Regular",
        // fontSize: '13px'
    }
};
