import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { Box, Typography, Link, IconButton, Card, CardContent, Divider } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlayMeditationWrapper } from './PlayMeditationList.style';
import PlayButton from 'Assets/images/Common/play-button.svg';
import { getWindowDimensions } from 'Helpers';
import MeditationMediaPlayer from 'Components/Pages/MeditationContent/Media/MeditationMediaPlayer';
import { API_URL, URL_PLAYLIST } from 'Helpers/Paths';
import Api from 'Helpers/ApiHandler';
import TaoSlider from 'Components/common/TaoSlider';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { useDispatch } from 'react-redux';
import { showSideBar, hideSideBar } from 'Redux/Sidebar/Actions';
import { PlayListMediaImg } from '../Playlist.style';
import { convertHoursMinsSecondsToReadableString } from 'Helpers';
import { isSubscribedAndAccessible } from '../PlaylistCommon';
import TaoLoader from 'Components/common/TaoBackdropLoader';

const PlayMeditationList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { playListId, playContentId, playlistName } = location?.state;
    let playListContentSlider = useRef(null);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const API = useMemo(() => new Api(), []);
    const [playlistRepeat, setPlaylistRepeat] = useState(false);

    const [playListContent, setPlayListContent] = useState({
        totalRecords: 0,
        list: []
    });

    const [handlePlayingMediaId, setHandlePlayingMediaId] = useState(null);

    const fetchPlayListContent = useCallback(async () => {
        let response = await API.get(API_URL.GET_PLAYLIST_CONTENT, {
            params: {
                programId: playListId
            }
        });

        if (response?.data?.data) {
            let tempList = isSubscribedAndAccessible(response?.data?.data?.list);

            setPlayListContent({
                totalRecords: response?.data?.data?.totalRecords,
                list: tempList
            });

            if (playContentId) {
                setHandlePlayingMediaId(playContentId);
            } else {
                setHandlePlayingMediaId(tempList[0].id);
            }
        }
    }, [API, playListId, playContentId]);

    const handleClick = () => {
        navigate(URL_PLAYLIST);
    };

    const playHandler = (index, id) => {
        setHandlePlayingMediaId(id);
    };

    const playOver = () => {
        let tempPlayList = [...playListContent?.list];
        let Index = tempPlayList.findIndex((value) => {
            return value.id === handlePlayingMediaId;
        });

        if (tempPlayList[Index + 1]) {
            setHandlePlayingMediaId(tempPlayList[Index + 1].id);
        } else {
            playlistRepeat ? setHandlePlayingMediaId(tempPlayList[0].id) : navigate(URL_PLAYLIST);
        }
    };

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        dispatch(hideSideBar());
        return () => {
            window.removeEventListener('resize', handleResize);
            dispatch(showSideBar());
        };
    }, [dispatch, windowDimensions]);

    useEffect(() => {
        fetchPlayListContent();
    }, [fetchPlayListContent]);

    return (
        <>
            <PlayMeditationWrapper>
                <Box className="full-width-section">
                    <TaoLoader isLoading={!handlePlayingMediaId} />
                    <Box className="play-meditation-content">
                        {handlePlayingMediaId && (
                            <MeditationMediaPlayer
                                playOver={playOver}
                                propsContentId={handlePlayingMediaId}
                                setPlaylistRepeat={setPlaylistRepeat}
                                playlistRepeat={playlistRepeat}
                                canRepeatPlaylist={playListContent?.list?.length > 1}
                            />
                        )}
                        {!document.fullscreenElement && (
                            <Box className="slider-container">
                                {handlePlayingMediaId && (
                                    <Box className="titleWrapper" screen={windowDimensions}>
                                        <Typography className="title">Up Next</Typography>
                                        <Typography className="navigation">
                                            From
                                            <Link className="link" onClick={handleClick}>
                                                {playlistName}
                                            </Link>
                                        </Typography>
                                    </Box>
                                )}

                                {windowDimensions.width >= BREAKPOINTS_VALUE.TABLET && (
                                    <Box className="energy-playlist-container">
                                        <TaoSlider
                                            gap={10}
                                            cardHeight={90}
                                            slidesToShow={2}
                                            ref={playListContentSlider}>
                                            {playListContent?.list?.map((value, index) => (
                                                <Box key={index}>
                                                    <Card
                                                        onClick={() =>
                                                            playHandler(index, value?.id)
                                                        }
                                                        className={`slider-card hover ${
                                                            handlePlayingMediaId === value.id &&
                                                            'selected-card-bg'
                                                        }`}>
                                                        <CardContent className="card-content">
                                                            <PlayListMediaImg
                                                                className="card-img"
                                                                img={value?.backgroundImageWeb}
                                                                height={'68px'}
                                                                width={'123px'}
                                                                radius={'20px 5px'}
                                                            />
                                                            <IconButton
                                                                style={{ margin: '0px 60px' }}
                                                                className="play-button">
                                                                <img
                                                                    src={PlayButton}
                                                                    alt=""
                                                                    className="play-icon"
                                                                />
                                                            </IconButton>
                                                            <Box className="card-details">
                                                                <Typography className="card-heading">
                                                                    {value.contentName}
                                                                </Typography>
                                                                <Box className="media-target">
                                                                    {!!value?.categoryDetailsList
                                                                        ?.length && (
                                                                        <Typography
                                                                            style={{ gap: '5px' }}
                                                                            className="media-time flex">
                                                                            {
                                                                                value
                                                                                    ?.categoryDetailsList[0]
                                                                                    ?.mainCategory
                                                                            }
                                                                            {value
                                                                                ?.categoryDetailsList[0] &&
                                                                                value
                                                                                    ?.categoryDetailsList[0]
                                                                                    .subCategory[0] && (
                                                                                    <span className="media-time flex">
                                                                                        {'> ' +
                                                                                            value
                                                                                                ?.categoryDetailsList[0]
                                                                                                .subCategory[0]}
                                                                                    </span>
                                                                                )}
                                                                        </Typography>
                                                                    )}
                                                                </Box>
                                                                <Typography className="media-time">
                                                                    {convertHoursMinsSecondsToReadableString(
                                                                        value.contentDuration
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Box>
                                            ))}
                                        </TaoSlider>
                                    </Box>
                                )}
                                {windowDimensions.width < BREAKPOINTS_VALUE.TABLET && (
                                    <Box className="tablet-playlist-view">
                                        {playListContent?.list?.map((value, index) => (
                                            <React.Fragment key={value.id}>
                                                <Box
                                                    onClick={() => playHandler(index, value?.id)}
                                                    className={`playlist-card flex f-v-center ${
                                                        handlePlayingMediaId === value.id &&
                                                        'selected-card-bg'
                                                    }`}>
                                                    <Box className="img-container">
                                                        <img
                                                            className="image"
                                                            src={value.contentImage}
                                                            alt=""
                                                        />
                                                        <IconButton className="play-button">
                                                            <img
                                                                src={PlayButton}
                                                                alt=""
                                                                className="play-icon"
                                                            />
                                                        </IconButton>
                                                    </Box>
                                                    <Box className="text flex f-column">
                                                        <Typography className="title">
                                                            {value.contentName}
                                                        </Typography>
                                                        <Box className="media-path">
                                                            {!!value?.categoryDetailsList
                                                                ?.length && (
                                                                <Typography
                                                                    style={{ gap: '5px' }}
                                                                    className="sub-title">
                                                                    {
                                                                        value
                                                                            ?.categoryDetailsList[0]
                                                                            ?.mainCategory
                                                                    }
                                                                    {value
                                                                        ?.categoryDetailsList[0] &&
                                                                        value
                                                                            ?.categoryDetailsList[0]
                                                                            .subCategory[0] && (
                                                                            <span className="">
                                                                                {'> ' +
                                                                                    value
                                                                                        ?.categoryDetailsList[0]
                                                                                        .subCategory[0]}
                                                                            </span>
                                                                        )}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                        <Typography className="time">
                                                            {convertHoursMinsSecondsToReadableString(
                                                                value.contentDuration
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
            </PlayMeditationWrapper>
        </>
    );
};

export default PlayMeditationList;
