import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, IconButton } from '@mui/material';

import moment from 'moment';
import Slider from 'react-slick';

import DefaultProfilePic from 'Assets/images/Profile/default-user-icon.jpg';
import { ReactComponent as SliderNextButton } from 'Assets/images/Common/next-arrow.svg';
import { ReactComponent as SliderPreviousButton } from 'Assets/images/Common/previous-arrow.svg';
import CommunityRoomImag from 'Assets/images/Profile/default-user-icon.jpg';
import DefaultRoomImg from 'Assets/images/MeditationRooms/room-default-image.png';

import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { URL_MEDITATION_ROOM_DETAILS } from 'Helpers/Paths';
import TaoRoomCard from 'Components/common/TaoRoomCard/TaoRoomCard';
import { MyRoomsWrapper } from './MyRoom.style';
import PendingApprovedModal from './PendingApprovedModal';
import NotApprovedModal from './NotApprovedModal';
import { ROOM_STATUS } from 'Helpers/Constants';
const SLIDER_SETTINGS = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    swipeToSlide: false,
    variableWidth: true,
    accessibility: true,
    // nextArrow: <SliderNextButton className="next-btn" style={{ width: '16', height: '16' }} />,
    // prevArrow: <SliderPreviousButton />,
    focusOnSelect: false,
    draggable: true,
    responsive: [
        {
            breakpoint: BREAKPOINTS_VALUE.DESKTOP,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: BREAKPOINTS_VALUE.LAPTOP,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: BREAKPOINTS_VALUE.TABLET,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: BREAKPOINTS_VALUE.PHABLET,
            settings: {
                slidesToShow: 1,
                arrows: false
            }
        }
    ]
};

const MyRooms = ({ trialUser = false, profilePic = DefaultProfilePic, ...props }) => {
    const sliderRef = useRef();
    const navigate = useNavigate();

    const [pendingAppRoveDetail, setNotAppRoveDetail] = useState({ title: '' });
    const [openPendingModal, setPendingModal] = useState(false);

    const [openNotAppModal, setNotAPPModal] = useState(false);
    const [notAppModalDetail, setNotAppModalDetail] = useState({});

    const roomClickHandler = (roomId, roomDetails) => {
        if (
            roomDetails.roomStatus === ROOM_STATUS.NEW ||
            roomDetails.roomStatus === ROOM_STATUS.APPROVED
        ) {
            navigate(URL_MEDITATION_ROOM_DETAILS + '/' + roomId, { state: { roomDetails } });
        } else if (roomDetails.roomStatus === ROOM_STATUS.PENDING) {
            setNotAppRoveDetail({ title: roomDetails.title });
            setPendingModal(true);
        } else if (roomDetails.roomStatus === ROOM_STATUS.NOT_APPROVED) {
            setNotAPPModal(true);
            setNotAppModalDetail({
                title: roomDetails.title,
                submittedTime: moment().add(-7, 'day'),
                reviewedTime: moment().add(-4, 'day'),
                resubmitTime: moment().add(4, 'day')
            });
        }
    };

    const closePendingModal = () => {
        setPendingModal(false);
    };

    const closeNotAppModal = () => {
        setNotAPPModal(false);
    };
    return (
        <>
            <PendingApprovedModal
                open={openPendingModal}
                roomDetails={pendingAppRoveDetail}
                onClose={closePendingModal}
            />
            <NotApprovedModal
                open={openNotAppModal}
                roomDetails={notAppModalDetail}
                onClose={closeNotAppModal}
            />

            <MyRoomsWrapper profilePic={profilePic}>
                <Box className="image-container">
                    <Box className="blur-layer"></Box>
                </Box>
                <Box className="room-list-container">
                    <Box className="list-title">
                        <>
                            <Typography className="welcome-txt">
                                Welcome to your rooms, Sunil!
                            </Typography>
                            <Typography className="suggestion-text">
                                Subscribers can host up to ONE room and co-host up to THREE.
                            </Typography>
                            {trialUser ? (
                                <Box className="suggestion-box">
                                    <Box className="box-text">
                                        <strong>SUBSCRIBE</strong>
                                        <Typography>TO CREATE</Typography>
                                        <Typography>OR CO-HOST</Typography>
                                        <Typography>A ROOM</Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Box className="rooms-listing-container">
                                    <Box className="icon-container">
                                        <IconButton onClick={() => sliderRef.current.slickPrev()}>
                                            <SliderPreviousButton className="icon" />
                                        </IconButton>
                                    </Box>
                                    <Box className="room-list">
                                        <Slider ref={sliderRef} {...SLIDER_SETTINGS}>
                                            {MY_ROOMS.map((r, index) => (
                                                <TaoRoomCard
                                                    myRoom
                                                    key={index}
                                                    cardRootClass="tao-room-card"
                                                    roomClickHandler={() =>
                                                        roomClickHandler(index, {
                                                            ...r,
                                                            singleHost: r.host
                                                        })
                                                    }
                                                    {...r}
                                                />
                                            ))}
                                        </Slider>
                                    </Box>
                                    <Box className="icon-container">
                                        <IconButton onClick={() => sliderRef.current.slickNext()}>
                                            <SliderNextButton className="icon" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            )}
                        </>
                    </Box>
                </Box>
            </MyRoomsWrapper>
        </>
    );
};

export default MyRooms;

const MY_ROOMS = [
    {
        title: 'Mindfulness for IT Professionals',
        ratingCount: 20,
        followerCount: 0,
        roomImage: DefaultRoomImg,
        cardRootClass: 'tao-room-card',
        isFav: true,
        myRoom: true,
        host: CommunityRoomImag,
        hostName: 'Sunil',
        roomStatus: ROOM_STATUS.APPROVED,
        isNew: true
    },
    {
        title: 'Mindfulness for IT Professionals',
        ratingCount: 40,
        followerCount: 60,
        roomImage: DefaultRoomImg,
        cardRootClass: 'tao-room-card',
        myRoom: true,
        host: CommunityRoomImag,
        hostName: 'Sunil',
        roomStatus: ROOM_STATUS.NOT_APPROVED
    },
    {
        title: 'Mindfulness for IT Professionals',
        ratingCount: 26,
        followerCount: 30,
        roomImage: DefaultRoomImg,
        cardRootClass: 'tao-room-card',
        myRoom: true,
        host: CommunityRoomImag,
        hostName: 'Sunil',
        roomStatus: ROOM_STATUS.PENDING
    },
    {
        title: 'Mindfulness for IT Professionals',
        ratingCount: 26,
        followerCount: 31,
        roomImage: DefaultRoomImg,
        cardRootClass: 'tao-room-card',
        isFav: true,
        myRoom: true,
        host: CommunityRoomImag,
        hostName: 'Sunil',
        roomStatus: ROOM_STATUS.APPROVED
    },
    {
        title: 'Mindfulness for IT Professionals',
        ratingCount: 16,
        followerCount: 32,
        roomImage: DefaultRoomImg,
        cardRootClass: 'tao-room-card',
        myRoom: true,
        host: CommunityRoomImag,
        hostName: 'Sunil',
        roomStatus: ROOM_STATUS.APPROVED
    },
    {
        title: 'Mindfulness for IT Professionals',
        ratingCount: 56,
        followerCount: 34,
        roomImage: DefaultRoomImg,
        cardRootClass: 'tao-room-card',
        myRoom: true,
        host: CommunityRoomImag,
        hostName: 'Sunil',
        roomStatus: ROOM_STATUS.APPROVED
    }
];
