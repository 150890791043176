import React from 'react';
import LockIcon from 'Assets/images/Common/lock-content.svg';
import DarkLockIcon from 'Assets/images/Common/dark-lock-content.svg';
import { SubscriptionLockWrapper } from './index.style';

export const SubscriptionDarkLock = ({ height = 15, width = 15, ...props }) => {
    return (
        <SubscriptionLockWrapper
            height={height}
            width={width}
            style={{ ...props?.customStyle, backgroundImage: `url(${DarkLockIcon})` }}
        />
    );
};

export const SubscriptionLightLock = ({ height = 15, width = 15, ...props }) => {
    return (
        <SubscriptionLockWrapper
            height={height}
            width={width}
            style={{ ...props?.customStyle, backgroundImage: `url(${LockIcon})` }}
        />
    );
};
