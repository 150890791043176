import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { getWindowDimensions } from 'Helpers';
import Lottie from 'lottie-react';
import {
    URL_HOME_PAGE,
    URL_PROGRAMS_DETAILS,
    URL_SEARCH,
    URL_HOW_TO_MEDITATE
} from 'Helpers/Paths';
// MASCOT
import PointingUpToStanding from 'Assets/AnimationFiles/TourAnimation/pointing-up-to-standing.json';
import PointingUpRight from 'Assets/AnimationFiles/TourAnimation/points-up-and-right.json';
import StandingToPointingUp from 'Assets/AnimationFiles/TourAnimation/standing-to-pointing-up.json';
import PointingRightIdle from 'Assets/AnimationFiles/TourAnimation/pointing-up-right-idle.json';
// import PointingDownRight from 'Assets/AnimationFiles/TourAnimation/points-down-and-right.json';

import StandingDollAnimation from 'Assets/AnimationFiles/Questionnaire/MascotStandingIdleLoop.json';
import PointingUpIdle from 'Assets/AnimationFiles/TourAnimation/pointing-up-idle.json';

import { ReactComponent as CloseIcon } from 'Assets/images/Common/close-icon.svg';

import { WebsiteTourWrapper } from './WebsiteTour.style';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import DOMPurify from 'dompurify';
import Slider from 'react-slick';
import { tourDisable, tourEnable } from 'Redux/App/Actions';
import { useDispatch } from 'react-redux';
import { INIT_SPOT_ANIMATION } from 'Helpers/Constants';

const WHITE_CIRCLE_DIMENSION = {
    WIDTH: '200px',
    HEIGHT: '200px',
    WIDTH_BY_TWO: '100px',
    HEIGHT_BY_TWO: '100px'
};

const TOP_BAR_WHITE_CIRCLE_DIMENSION = {
    WIDTH: '60px',
    HEIGHT: '60px',
    WIDTH_BY_TWO: '30px',
    HEIGHT_BY_TWO: '30px'
};

const CONTENT_DIMENSION = {
    WIDTH: '500px',
    HEIGHT: '200px',
    WIDTH_BY_TWO: '250px',
    HEIGHT_BY_TWO: '100px'
};
const MASCOT_WIDTH = 300;
const MASCOT_TIMEOUT = 500;

const STEPs_INDEX = [
    { step: 'INIT', content: 'This app has video, guided audio, and music meditation.' },
    // { step: 'ABOUT', content: 'See what this meditation is <b>about</b>.' },
    // { step: 'REVIEW', content: '<b>Review</b> what others have experienced.' },
    { step: 'LIKE', content: 'Like...' },
    { step: 'SHARE', content: 'Share...' },
    { step: 'FAV', content: 'Favorite...' },

    // OTHERS
    { step: 'OTHER', content: 'While you meditate, <b>Zen Mode</b> will disable notifications.' },
    { step: 'OTHER', content: 'You can manage Zen Mode settings in your <b>profile</b>.' },
    { step: 'OTHER', content: 'Ready to Start the Mediation?' },
    { step: 'OTHER', content: 'What would you like to check out next, <First Name>?' }
];

// 6 STEPS
const LOTTIE_ANIMATION = [
    StandingDollAnimation,
    PointingUpRight,
    PointingUpRight,
    PointingUpRight,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const IDLE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    PointingRightIdle,
    PointingRightIdle,
    PointingRightIdle,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const TABLET_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    PointingUpIdle,
    PointingRightIdle,
    PointingRightIdle,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const TABLET_IDLE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    PointingUpIdle,
    PointingRightIdle,
    PointingRightIdle,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const MOBILE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    PointingUpIdle,
    PointingRightIdle,
    PointingRightIdle,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const MOBILE_IDLE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    PointingUpIdle,
    PointingRightIdle,
    PointingRightIdle,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    touchMove: false
};

const ListButtons = forwardRef(
    (
        {
            backButtonTitle = '',
            nextButtonTitle = '',
            currentStep,
            setCurrentStep,
            updateButtons,
            isFinalStep = true,
            contentId,
            programId,
            ...props
        },
        ref
    ) => {
        const navigate = useNavigate();

        return (
            <Box className="button-parent-wrapper flex f-column gap">
                <Button
                    onClick={() => {
                        if (isFinalStep) {
                            return navigate(URL_HOME_PAGE);
                        }
                    }}
                    className={`btn primary full-width`}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize('Go Home')
                        }}
                        className="font"
                    />
                </Button>
                {programId !== -1 && (
                    <Button
                        onClick={() => {
                            if (isFinalStep) {
                                return navigate(URL_PROGRAMS_DETAILS, {
                                    state: { contentId, programId, isRedirectedFromHistory: false }
                                });
                            }
                        }}
                        className={`btn primary full-width`}>
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize('Join a Program')
                            }}
                            className="font"
                        />
                    </Button>
                )}
                <Button
                    onClick={() => {
                        if (isFinalStep) {
                            return navigate(URL_SEARCH, {
                                state: { contentId, programId }
                            });
                        }
                    }}
                    className={`btn primary full-width`}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize('Search for More')
                        }}
                        className="font"
                    />
                </Button>
                <Button
                    onClick={() => {
                        if (isFinalStep) {
                            return navigate(URL_HOW_TO_MEDITATE, {
                                state: { contentId, programId, isRedirectedFromHistory: false }
                            });
                        }
                    }}
                    className={`btn primary full-width`}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize('Learn How This Works')
                        }}
                        className="font"
                    />
                </Button>
            </Box>
        );
    }
);

const FooterButtons = forwardRef(
    (
        {
            backButtonTitle = '',
            nextButtonTitle = '',
            currentStep,
            setCurrentStep,
            updateButtons,
            isFinalStep = false,
            ...props
        },
        ref
    ) => (
        <Box className="button-parent-wrapper">
            <Button
                onClick={() => {
                    if (currentStep + 1 === STEPs_INDEX?.length - 1) {
                        ref.current.slickNext();
                        updateButtons();
                        setCurrentStep(currentStep + 1);
                        return;
                    }
                    ref.current.slickPrev();
                    setCurrentStep(currentStep - 1);
                }}
                className={`btn back ${!currentStep && 'hide'}`}>
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(backButtonTitle)
                    }}
                    className={`font ${isFinalStep && 'text-transform medium'}`}
                />
            </Button>
            <Button
                onClick={() => {
                    if (isFinalStep) return props?.closeTutorial();
                    if (currentStep + 1 === STEPs_INDEX?.length - 2) {
                        updateButtons();
                        setCurrentStep(currentStep + 1);
                    }
                    ref.current.slickNext();
                    setCurrentStep(currentStep + 1);
                }}
                className={`btn font primary`}>
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(nextButtonTitle)
                    }}
                    className={`font ${isFinalStep && 'text-transform medium'} `}
                />
            </Button>
        </Box>
    )
);

const WebsiteTour = ({
    isLoading,
    contentId,
    programId,
    handleMascotTour,
    userDetails,
    ...props
}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isCompletedAnimation, setIsCompletedAnimation] = useState(false);
    const [loop, setLoop] = useState(false);
    const animationRef = useRef(null);
    const slickRef = useRef(null);
    const buttonRef = useRef(null);
    const [mascotPosition, setMascotPosition] = useState({
        transform: undefined
    });
    const [initAnimationSpot, setInitAnimationSpot] = useState('');
    const dispatch = useDispatch();

    const [tourStaringId, setStartingTourId] = useState({
        width: undefined,
        height: undefined,
        transform: undefined
    });
    const [contentDimension, setContentDimension] = useState({
        width: CONTENT_DIMENSION.WIDTH,
        height: CONTENT_DIMENSION.HEIGHT,
        halfWidth: CONTENT_DIMENSION.WIDTH_BY_TWO,
        halfHeight: CONTENT_DIMENSION.HEIGHT_BY_TWO
    });
    const [polygonDimension, setPolygonDimension] = useState('');

    const [boxPosition, setBoxPosition] = useState('');
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const setDimensions = useCallback(
        (animationDimension) => {
            if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                setStartingTourId({
                    transform: `translate(calc(${animationDimension?.left}px - 75px + ${
                        animationDimension.width / 2
                    }px), calc(${animationDimension?.top}px - 75px + ${
                        animationDimension?.height / 2
                    }px))`,
                    width: '150px',
                    height: '150px'
                });
                return;
            }

            setStartingTourId({
                transform: `translate(calc(${animationDimension?.left}px - ${
                    WHITE_CIRCLE_DIMENSION.WIDTH_BY_TWO
                } + ${animationDimension.width / 2}px), calc(${animationDimension?.top}px - ${
                    WHITE_CIRCLE_DIMENSION.HEIGHT_BY_TWO
                } + ${animationDimension?.height / 2}px))`,
                width: WHITE_CIRCLE_DIMENSION.WIDTH,
                height: WHITE_CIRCLE_DIMENSION.HEIGHT
            });
        },
        [windowDimensions.width]
    );

    const setTopBarDimensions = (animationDimension) => {
        setStartingTourId({
            transform: `translate(calc(${animationDimension?.left}px - ${
                TOP_BAR_WHITE_CIRCLE_DIMENSION.WIDTH_BY_TWO
            } + ${animationDimension.width / 2}px), calc(${animationDimension?.top}px - ${
                TOP_BAR_WHITE_CIRCLE_DIMENSION.HEIGHT_BY_TWO
            } + ${animationDimension?.height / 2}px))`,
            width: TOP_BAR_WHITE_CIRCLE_DIMENSION.WIDTH,
            height: TOP_BAR_WHITE_CIRCLE_DIMENSION.HEIGHT
        });
    };

    const fetchLikeIconContent = useCallback(() => {
        let animationDimension = document
            ?.getElementById?.('like-icon-id')
            ?.getBoundingClientRect?.();

        if (animationDimension)
            setTimeout(() => setTopBarDimensions(animationDimension), MASCOT_TIMEOUT);
    }, []);

    const fetchFavIconContent = useCallback(() => {
        let animationDimension = document
            ?.getElementById?.('fav-icon-id')
            ?.getBoundingClientRect?.();

        if (animationDimension)
            setTimeout(() => setTopBarDimensions(animationDimension), MASCOT_TIMEOUT);
    }, []);

    const fetchShareIconContent = useCallback(() => {
        let animationDimension = document
            ?.getElementById?.('top-bar-share-id')
            ?.getBoundingClientRect?.();

        if (animationDimension)
            setTimeout(() => setTopBarDimensions(animationDimension), MASCOT_TIMEOUT);
    }, []);

    const fetchAboutTabContent = useCallback(() => {
        let animationDimension = document?.getElementById?.('about-tab')?.getBoundingClientRect?.();

        if (animationDimension) setTimeout(() => setDimensions(animationDimension), MASCOT_TIMEOUT);
    }, [setDimensions]);

    const fetchReviewTabContent = useCallback(() => {
        let animationDimension = document
            ?.getElementById?.('review-tab')
            ?.getBoundingClientRect?.();

        if (animationDimension) setTimeout(() => setDimensions(animationDimension), MASCOT_TIMEOUT);
    }, [setDimensions]);

    const initAnimation = useCallback(() => {
        let animationDimension = document
            ?.getElementById?.('tour-staring-box')
            ?.getBoundingClientRect?.();

        if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
            const initTransform =
                currentStep === 7
                    ? `translate(calc(250px), calc(125px))`
                    : `translate(calc(${windowDimensions.width}px - ${
                          MASCOT_WIDTH / 2
                      }px - 25px), calc(${windowDimensions.height - MASCOT_WIDTH - 80}px))`;
            if (currentStep !== 7) {
                let animationStartingSpot = (INIT_SPOT_ANIMATION - 150) / 2;
                setInitAnimationSpot(
                    `translate(calc(${windowDimensions.width}px - ${
                        MASCOT_WIDTH / 2
                    }px - 25px - ${animationStartingSpot}px), calc(${
                        windowDimensions.height - MASCOT_WIDTH - 80
                    }px - ${animationStartingSpot}px))`
                );
            }
            setStartingTourId({
                transform: initTransform,
                width: '150px',
                height: '150px'
            });
            const initMascot =
                currentStep === 7
                    ? `translate(calc(225px), calc(100px))`
                    : `translate(calc(${windowDimensions.width}px - 200px), calc(${
                          windowDimensions.height - MASCOT_WIDTH - 80
                      }px - 25px))`;
            setMascotPosition({
                transform: initMascot
            });

            const initBox =
                currentStep === 7
                    ? `translate(calc(20px), calc(299px))`
                    : `translate(20px, calc(${windowDimensions.height}px - ${contentDimension.height} - 20px - 15px))`;
            setBoxPosition(initBox);
            setPolygonDimension(`translate(-70px, -30px) rotate(45deg)`);

            return;
        }
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            let animationStartingSpot = (INIT_SPOT_ANIMATION - 200) / 2;
            setInitAnimationSpot(
                `translate(calc(${
                    windowDimensions.width
                }px - ${MASCOT_WIDTH}px + 50px - ${animationStartingSpot}px), calc(${
                    MASCOT_WIDTH / 2
                }px - ${animationStartingSpot}px))`
            );
            setStartingTourId({
                transform: `translate(calc(${
                    windowDimensions.width
                }px - ${MASCOT_WIDTH}px + 50px), ${MASCOT_WIDTH / 2}px)`,
                width: WHITE_CIRCLE_DIMENSION.WIDTH,
                height: WHITE_CIRCLE_DIMENSION.HEIGHT
            });
            setBoxPosition(
                `translate(calc(${windowDimensions.width}px - ${contentDimension.width} - 250px), 150px)`
            );
            setPolygonDimension(
                `translate(0px, calc(${contentDimension.halfHeight} - 17px - 20px)) rotate(45deg)`
            );
            setMascotPosition({
                transform: `translate(calc(${windowDimensions.width}px - ${MASCOT_WIDTH}px), 100px)`
            });
            return;
        }
        if (animationDimension && windowDimensions.width >= BREAKPOINTS_VALUE.TABLET) {
            let MINUS_TOP_FOR_TEXT = 150;

            let initTransform = `translate(calc(${
                animationDimension?.left + animationDimension.width * 0.8
            }px), calc(${animationDimension?.top}px - ${
                WHITE_CIRCLE_DIMENSION.HEIGHT
            } - ${MINUS_TOP_FOR_TEXT}px - 20px))`;

            let initMascotTransform = `translate(calc(${
                animationDimension?.left + animationDimension.width * 0.8
            }px - 50px), calc(${animationDimension?.top}px - ${
                WHITE_CIRCLE_DIMENSION.HEIGHT
            } - ${MINUS_TOP_FOR_TEXT}px - 20px - 50px))`;

            let animationStartingSpot = (INIT_SPOT_ANIMATION - 200) / 2;
            setInitAnimationSpot(
                `translate(calc(${
                    animationDimension?.left + animationDimension.width * 0.8
                }px - ${animationStartingSpot}px), calc(${animationDimension?.top}px - ${
                    WHITE_CIRCLE_DIMENSION.HEIGHT
                } - ${MINUS_TOP_FOR_TEXT}px - 20px - ${animationStartingSpot}px))`
            );

            setStartingTourId({
                transform: initTransform,
                width: WHITE_CIRCLE_DIMENSION.WIDTH,
                height: WHITE_CIRCLE_DIMENSION.HEIGHT
            });
            setBoxPosition(
                `translate(calc(${animationDimension?.left}px - ${
                    contentDimension.width
                } - 10px + ${animationDimension.width * 0.8}px), calc(${
                    animationDimension?.top
                }px - ${WHITE_CIRCLE_DIMENSION.HEIGHT} - ${MINUS_TOP_FOR_TEXT}px - 20px))`
            );
            setPolygonDimension(
                `translate(0px, calc(${contentDimension.halfHeight} - 17px - 20px)) rotate(45deg)`
            );

            setMascotPosition({ transform: initMascotTransform });
            return;
        }
    }, [
        windowDimensions.width,
        contentDimension.width,
        contentDimension.halfHeight,
        windowDimensions.height,
        contentDimension.height,
        currentStep
    ]);

    const replayAnimation = () => {
        animationRef.current.stop();
        animationRef.current.play();
    };

    const updateAnimationStatus = () => {
        setIsCompletedAnimation(false);
        setLoop(false);
    };

    const handleCloseMascotTour = () => {
        handleMascotTour();
        props?.setTourEnable(false);
    };

    const jumpToStep = useCallback(() => {
        switch (STEPs_INDEX?.[currentStep]?.step) {
            case 'INIT':
                updateAnimationStatus();
                initAnimation();
                break;
            case 'ABOUT':
                updateAnimationStatus();
                // replayAnimation();
                fetchAboutTabContent();
                break;
            case 'REVIEW':
                updateAnimationStatus();
                // replayAnimation();
                fetchReviewTabContent();
                break;
            case 'SHARE':
                // updateAnimationStatus();
                // replayAnimation();
                fetchShareIconContent();
                break;
            case 'FAV':
                // updateAnimationStatus();
                // replayAnimation();
                fetchFavIconContent();
                break;
            case 'LIKE':
                // updateAnimationStatus();
                // replayAnimation();
                fetchLikeIconContent();
                break;
            default:
                updateAnimationStatus();
                initAnimation();
                return;
        }
    }, [
        initAnimation,
        currentStep,
        fetchAboutTabContent,
        fetchReviewTabContent,
        fetchShareIconContent,
        fetchFavIconContent,
        fetchLikeIconContent
    ]);

    const updateButtons = () => {
        buttonRef.current.slickNext();
    };

    const startFormInit = () => {
        slickRef?.current?.slickGoTo(0);
        buttonRef?.current?.slickPrev();
        setCurrentStep(0);
    };

    const boxClass = useCallback(
        (currentStep) => {
            if (currentStep === 7) {
                if (
                    windowDimensions.width >= BREAKPOINTS_VALUE.PHABLET &&
                    windowDimensions.width <= BREAKPOINTS_VALUE.TABLET
                ) {
                    return 'tablet';
                }

                if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                    return 'phablet';
                }
            }
            return '';
        },
        [windowDimensions.width]
    );

    const mascotAnimation = useCallback(
        (currentStep, isCompletedAnimation) => {
            if (windowDimensions.width >= BREAKPOINTS_VALUE.TABLET) {
                if (isCompletedAnimation) {
                    return IDLE_LOTTIE_ANIMATION[currentStep];
                }
                return LOTTIE_ANIMATION[currentStep];
            }

            if (
                windowDimensions.width >= BREAKPOINTS_VALUE.PHABLET &&
                windowDimensions.width <= BREAKPOINTS_VALUE.TABLET
            ) {
                if (isCompletedAnimation) {
                    return TABLET_IDLE_LOTTIE_ANIMATION[currentStep];
                }
                return TABLET_LOTTIE_ANIMATION[currentStep];
            }

            if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                if (isCompletedAnimation) {
                    return MOBILE_IDLE_LOTTIE_ANIMATION[currentStep];
                }
                return MOBILE_LOTTIE_ANIMATION[currentStep];
            }
        },
        [windowDimensions.width]
    );

    useEffect(() => {
        jumpToStep();
        if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
            setContentDimension((prev) => ({ ...prev, width: '90%', height: '200px' }));
            return;
        }
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            setContentDimension((prev) => ({ ...prev, width: '300px', height: '250px' }));
            return;
        }
    }, [windowDimensions?.height, windowDimensions?.width, isLoading, jumpToStep]);

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };
        dispatch(tourEnable());
        window.addEventListener('resize', handleResize);
        return () => {
            dispatch(tourDisable());
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);

    return (
        <React.Fragment>
            {!isLoading &&
                createPortal(
                    <WebsiteTourWrapper $initAnimationSpot={initAnimationSpot}>
                        {tourStaringId?.transform && (
                            <Box className="tour">
                                <Box
                                    className="tour-starting-box"
                                    style={{
                                        transform: tourStaringId?.transform,
                                        width: tourStaringId?.width,
                                        height: tourStaringId?.width
                                    }}
                                />
                            </Box>
                        )}
                        <Box className="content-container">
                            <Lottie
                                className="mascot"
                                animationData={mascotAnimation(currentStep, isCompletedAnimation)}
                                style={{
                                    transform: mascotPosition?.transform
                                }}
                                onComplete={() => {
                                    setIsCompletedAnimation(true);
                                    setLoop(true);
                                }}
                                lottieRef={animationRef}
                                loop={loop}
                            />
                            <Box
                                className="tour-content"
                                style={{
                                    transform: boxPosition,
                                    width: contentDimension?.width,
                                    height: currentStep === 7 ? 400 : contentDimension?.height
                                }}>
                                <Box className="polygon" style={{ transform: polygonDimension }} />
                                <Box
                                    className="close-icon-btn hover"
                                    onClick={() => handleCloseMascotTour()}>
                                    <CloseIcon className="close-svg" />
                                </Box>
                                <Box className="content">
                                    <Slider {...settings} ref={slickRef}>
                                        {STEPs_INDEX.map((item, index) => (
                                            <Box
                                                className="text"
                                                key={index}
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.content?.includes('<First Name>')
                                                        ? DOMPurify.sanitize(
                                                              item?.content?.replace(
                                                                  '<First Name>',
                                                                  userDetails?.firstName
                                                              )
                                                          )
                                                        : DOMPurify.sanitize(item?.content)
                                                }}
                                            />
                                        ))}
                                    </Slider>

                                    <Box className={`footer-buttons ${boxClass(currentStep)}`}>
                                        <Slider {...settings} ref={buttonRef}>
                                            <FooterButtons
                                                ref={slickRef}
                                                currentStep={currentStep}
                                                setCurrentStep={setCurrentStep}
                                                backButtonTitle="back"
                                                nextButtonTitle="next"
                                                updateButtons={updateButtons}
                                            />
                                            <FooterButtons
                                                ref={slickRef}
                                                currentStep={currentStep}
                                                setCurrentStep={setCurrentStep}
                                                backButtonTitle="NO <br/> Continue Tutorial"
                                                nextButtonTitle="YES <br/> Close Tutorial"
                                                updateButtons={updateButtons}
                                                startFormInit={startFormInit}
                                                closeTutorial={() => handleCloseMascotTour()}
                                                isFinalStep={true}
                                            />
                                            <ListButtons
                                                ref={slickRef}
                                                currentStep={currentStep}
                                                setCurrentStep={setCurrentStep}
                                                backButtonTitle="back"
                                                nextButtonTitle="next"
                                                updateButtons={updateButtons}
                                                isFinalStep={true}
                                                contentId={contentId}
                                                programId={programId}
                                            />
                                        </Slider>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </WebsiteTourWrapper>,
                    document.body
                )}
        </React.Fragment>
    );
};

export default WebsiteTour;
