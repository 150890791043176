import { Box } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import styled from 'styled-components';

export const CartBottomWrapper = styled(Box)`
    &.carts-and-order {
        box-shadow: 0px 0px 8px 0px #00000040;
        height: 100px;
        border-radius: 20px 20px 0px 0px;
        background-color: white;
        position: fixed;
        bottom: 0;
        width: 50%;
        gap: 10%;
        z-index: 20;

        ${responsive.LAPTOP`
            width: 70%;
        `}
        ${responsive.TABLET`
            width: 90%;
        `}
        ${responsive.MOBILE`
            width: 95%;
        `}

        .icon-indicator {
            font-size: 12px;
            color: ${COLORS.MEDIUM_GREY};
            text-transform: uppercase;
        }

        .count-container {
            height: 20px;
            width: 20px;
            background-color: ${COLORS.MEDIUM_GREY};
            border-radius: 50%;
            right: -5px;
            top: 5px;

            &.active {
                background-color: ${COLORS.BADGE};
            }
            &.max-count-reached {
                border-radius: 10px;
                width: 30px;
            }

            .count-number {
                font-family: ${FONTS.PRIMARY_MEDIUM};
                color: ${COLORS.WHITE};
                font-size: 14px;
            }
        }
    }
`;
