import styled from 'styled-components';
import { COLORS, responsive, FONTS } from 'Styles/Constants';

export const SidebarWrapper = styled.div`
    position: fixed;
    top: calc(60px + 10%);
    left: 0;
    width: 100px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 0px 16px 16px 0px;
    background-color: ${COLORS.WHITE};
    z-index: 999;
    display: ${(props) => (props.showSideBar ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 400px;
    padding: 15px;

    ${responsive.TABLET`
        height: 70px;
        top: unset;
        left: unset;
        box-shadow: unset;
        border-radius: unset;
        position:fixed;    
        bottom: 0;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        align-items: start;
        padding: 10px 15%;
        box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    `}

    ${responsive.PHABLET`
        padding: 10px;
    `}

    .menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        transition: all 0.2s;
        position: relative;

        ${responsive.TABLET`
            justify-content: space-between;
            height: 100%;
            gap: 0;
        `}

        ${responsive.MOBILE`          
            padding: 0;
        `} 

        &:hover {
            transform: scale(1.1);
        }

        .menu-icon {
            height: 30px;
            width: 30px;
        }

        .menu-text {
            font-family: ${FONTS.PRIMARY};
            font-weight: 400;
            font-size: 10px;
        }

        .has-children {
            display: flex;
            flex-direction: row;
            gap: 6px;
            justify-content: center;
            align-items: center;
        }

        .selected-menu-text {
            color: ${COLORS.PRIMARY};
        }

        .notifications-badge {
            color: #ffffff;
        }
    }

    .sub-menu-list {
        position: absolute;
        top: 0;
        left: 100px;
        height: auto;
        width: 300px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.3);
        border-radius: 10px 16px 16px 10px;
        background-color: #fff;
        padding: 14px 24px;
        display: flex;
        flex-direction: column;
        color: #656565;
        overflow: auto;
        z-index: 999;

        ${responsive.TABLET`
            top: unset;
            left: 25%;
            bottom: 86px;
        `}
        ${responsive.MOBILE`
            left: 5%;            
        `}

        .sub-menu-list-loader-wrapper {
            display: flex;
            padding: 8px 0;

            .loader-title {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 220px;
                margin: 0px 10px;
            }
        }
    }

    .placeholder {
        background-color: #fff;
        height: 10px;
        width: 100%;
    }

    .top {
        border-bottom-right-radius: 50px;
    }

    .bottom {
        border-top-right-radius: 50px;
    }

    .item-svg {
        padding-right: 8px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 24px;
        width: 24px;
    }

    .item-text {
        padding: 0 8px;

        .child-text {
            min-width: max-content;
        }

        .badge-text {
            margin: 0 0 0 5px;
            color: ${COLORS.BADGE};
            text-transform: uppercase;
            font-size: 12px;
        }
    }

    .item-padding {
        padding: 8px 0;
    }

    .divider {
        width: 100%;
        background-color: ${COLORS.PRIMARY};
        height: 1px;
        margin-left: -5px;
    }

    .float-div {
        position: absolute;
        height: 125px;
        width: 33px;
        left: 76px;
        top: 14%;
        z-index: 999999;
        background-color: #fff;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        box-shadow: 0px 4px 16px white;

        &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            box-shadow: 0px 4px 16px white;
            top: -41px;
            left: 22px;
        }

        ${responsive.TABLET`
            top: unset;
            left: 67px;
            bottom: 68px;
            width: 114px;
            height: 29px;
            border-top-left-radius: unset;
            border-bottom-left-radius: 50px;
            border-bottom-right-radius: 50px;
            box-shadow: 0px 4px 16px white;
        `}

        ${responsive.PHABLET`
            top: unset;
            left: 67px;
            bottom: 90px;
            width: 114px;
            height: 29px;
            border-top-left-radius: unset;
            border-bottom-left-radius: 50px;
            border-bottom-right-radius: 50px;
            box-shadow: 0px 4px 16px white;
        `}
    }

    .float-div-arrow {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        margin-left: -0.5em;
        bottom: -2em;
        left: 109px;
        top: 32%;
        box-sizing: border-box;
        border: 1em solid black;
        border-color: transparent transparent #fff #fff;
        z-index: 99999;
        transform-origin: 0 0;
        transform: rotate(45deg);
        box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.1);
        border-bottom-left-radius: 7px;

        ${responsive.TABLET`
            top: unset;
            bottom: 55px;
            left: 35%;
            transform: rotate(-45deg);
        `}

        ${responsive.MOBILE`
            left: 32%;
        `}
    }
`;
