import React, { useState, useEffect } from 'react';

import ProgramEvent from './ProgramEvent';
import { Box } from '@mui/system';
import { Divider, Typography } from '@mui/material';
import moment from 'moment';
import TaoScrollbar from 'Components/common/Scrollbar';
import { programProgressDataFormat, programTimeFormat } from 'Helpers';
import {
    BoxLoader,
    TitleLoader,
    TitleWithFourProgressBarLoader
} from 'Components/common/ShimmerEffectLoader/ShimmerEffectLoader';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { getWindowDimensions } from 'Helpers';

const ProgramList = ({ list, isLoading, ...props }) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const canContentPlay = (data, nextData) => {
        if (data?.isPlayed) return true;
        if (props?.programDetails?.isPostAssessmentCompleted && props?.programDetails?.canRejoin)
            return false;
        if (props?.programDetails?.isRatingSkipped) return false;

        if (parseInt(props?.currentSelectedDay) !== parseInt(props?.completedDay)) return false;

        const videoPlayTime = moment(data?.contentAvailabilityTime, 'HH:mm:ss').format('hh:mm a');
        let currentTime = moment().format('hh:mm a');

        // let videoPlayNextTime = moment(nextData?.contentAvailabilityTime, 'HH:mm:ss').format(
        //     'hh:mm a'
        // );

        // VIDEO PLAY TIME MUST BE SAME OR BEFORE CURRENT TIME
        let currentVideoCanAccess = moment(currentTime, 'hh:mm a').isSameOrAfter(
            moment(videoPlayTime, 'hh:mm a')
        );

        // if (!nextData?.contentAvailabilityTime) return currentVideoCanAccess;

        // let nextIsVisible = moment(currentTime, 'hh:mm a').isBefore(
        //     moment(videoPlayNextTime, 'hh:mm a')
        // );
        return /* nextIsVisible && */ currentVideoCanAccess;
    };

    // ENABLE NEW JOINED PROGRAM TEXT FOR USERS
    const needToShowNotificationSection = (firstDayDate, firstContentTime) => {
        if (!firstDayDate || !firstContentTime) return false;

        if (!props?.programDetails?.isProgramJoined) return false;

        if (moment(firstDayDate, 'YYYY-MM-DD').isSameOrAfter(moment().format('YYYY-MM-DD'))) {
            if (moment(firstDayDate, 'YYYY-MM-DD').isAfter(moment().format('YYYY-MM-DD')))
                return true;
            let currentTime = moment().format('HH:mm:ss');
            let contentTime = moment(firstContentTime, 'HH:mm:ss').format('HH:mm:ss');

            return moment(contentTime, 'HH:mm:ss').isAfter(moment(currentTime, 'HH:mm:ss'));
        }
        return false;
    };

    const dynamicHeight = () => {
        let height = 100;

        if (windowDimensions?.width <= BREAKPOINTS_VALUE.PHABLET) return 120;

        if (windowDimensions?.width <= BREAKPOINTS_VALUE.TABLET) return 175;

        return height;
    };

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <TaoScrollbar displayScroll>
            {needToShowNotificationSection(
                props?.programDetails?.daysList?.[0]?.programDate,
                props?.firstDayFirstContent?.contentAvailabilityTime
            ) && (
                <Box className="notify-user">
                    <Typography className="text">
                        Congratulations for joining
                        <span className="bold"> “{props?.programDetails?.title?.trim?.()}.” </span>
                        Your first session becomes available
                        <span className="bold mutate-case">
                            {' '}
                            {programProgressDataFormat(
                                props?.programDetails?.daysList?.[0]?.programDate
                            )}{' '}
                        </span>
                        <span className="bold">
                            {' '}
                            {programTimeFormat(
                                moment(
                                    props?.firstDayFirstContent?.contentAvailabilityTime,
                                    'HH:mm:ss'
                                ).format('h:mm a'),
                                moment(
                                    props?.firstDayFirstContent?.contentAvailabilityTime,
                                    'HH:mm:ss'
                                ).format('h:mm')
                            )}{' '}
                        </span>
                        and you will receive a notification. Watch anytime throughout the day
                    </Typography>
                </Box>
            )}

            {isLoading && (
                <>
                    {[1, 2, 3, 4, 5, 6]?.map((value, index) => (
                        <React.Fragment key={index}>
                            <Box
                                className={`program-list-loader-wrapper ${
                                    index % 2 !== 0 && `reverse`
                                }`}>
                                <Box className="image-with-text-loader-wrapper">
                                    <Box className="text-loader-wrapper">
                                        <TitleLoader
                                            isFullWidth={true}
                                            isFullHeight={true}
                                            fillWidth={100}
                                            fillHeight={10}
                                            marginLeft={0}
                                            marginTop={10}
                                            baseCustomStyle={{
                                                height: '100%',
                                                width: '100%'
                                            }}
                                        />
                                    </Box>
                                    <Box className="image-loader-wrapper">
                                        <BoxLoader
                                            isFullWidth={true}
                                            height={dynamicHeight()}
                                            baseCustomStyle={{
                                                height: '100%',
                                                width: '100%'
                                            }}
                                            customStyle={{
                                                borderRadius: '16px 8px'
                                            }}
                                            rectCustomStyle={{
                                                height: '100%',
                                                width: '100%'
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box className="program-description-loader-wrapper">
                                    <Box className="program-description-loader">
                                        <TitleWithFourProgressBarLoader
                                            baseCustomStyle={{
                                                height: '100%',
                                                width: '100%'
                                            }}
                                            isFullWidth={true}
                                            isFullHeight={true}
                                            fillWidth={100}
                                            fillHeight={10}
                                            marginLeft={10}
                                            rectCustomStyle={{
                                                height: '100%',
                                                width: '100%'
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            {[1, 2, 3, 4, 5, 6]?.length - 1 !== index && (
                                <Box style={{ padding: '20px 60px 5px 0' }}>
                                    <Divider />
                                </Box>
                            )}
                        </React.Fragment>
                    ))}
                </>
            )}

            {!isLoading && !!list?.length ? (
                list.map((data, index) => (
                    <React.Fragment key={index}>
                        <ProgramEvent
                            data={data}
                            swap={index % 2}
                            canPlay={canContentPlay(
                                data,
                                list?.length - 1 === index ? {} : list[index + 1]
                            )}
                            isLastRecord={list.length - 1 === index}
                        />
                        {list.length - 1 !== index && (
                            <Box style={{ padding: '20px 60px 20px 0' }}>
                                <Divider />
                            </Box>
                        )}
                    </React.Fragment>
                ))
            ) : (
                <Typography className="no-data-text">
                    {!isLoading && 'No Content Available.'}
                </Typography>
            )}
        </TaoScrollbar>
    );
};

export default ProgramList;
