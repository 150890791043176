import React, { useState } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    Typography,
    Box,
    Backdrop,
    Slide,
    IconButton,
    TextField,
    FormHelperText
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { ModalWrapper } from './Modal.style';
import { TaoButton } from 'Components/common/Button.style';

const ReportRoomModal = ({ open = false, roomDetails = {}, onClose = () => {}, ...props }) => {
    const formik = useFormik({
        initialValues: {
            description: ''
        },
        validationSchema: Yup.object({
            description: Yup.string().required('Description is required')
        }),
        onSubmit: async (values) => {
            console.log(values);
            setSubmitted(true);
        }
    });

    const [submitted, setSubmitted] = useState(false);
    return (
        <ModalWrapper
            open={open}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 250
            }}
            onClose={onClose}
            closeAfterTransition>
            <Slide timeout={250} in={open} direction="up">
                <Box className="dialog-container">
                    <IconButton className="close-btn" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    <Box className="content-container">
                        <Box className="title-container report-title">
                            <Typography className="title">{roomDetails.title}</Typography>
                            <Typography className="inappropriate-content">
                                Report Inappropriate Content
                            </Typography>
                        </Box>
                        {!submitted ? (
                            <form className="flex f-column" onSubmit={formik.handleSubmit}>
                                <Box className="invite-container">
                                    <TextField
                                        className="text-field"
                                        variant="filled"
                                        label="What would you like to report?"
                                        name="description"
                                        onChange={formik.handleChange}
                                        value={formik.values.description}
                                        multiline
                                        rows={4}
                                        InputProps={{
                                            disableUnderline: true,
                                            className: 'filled-input'
                                        }}
                                    />
                                    {formik.touched.description && formik.errors.description && (
                                        <FormHelperText style={{ marginLeft: '14px' }} error>
                                            {formik.errors.description}
                                        </FormHelperText>
                                    )}
                                </Box>
                                <TaoButton className="invite-btn" type="submit">
                                    send report
                                </TaoButton>
                            </form>
                        ) : (
                            <Box className="received-report-container">
                                <Typography className="acknowledge-text">
                                    We’ve received your report. Our team will look into the matter.
                                </Typography>
                                <br />
                                <Typography className="acknowledge-text">
                                    Thank you for your efforts to keep our community a positive
                                    place.
                                </Typography>
                                <br />
                                <Typography className="acknowledge-text">
                                    A copy of your report has been emailed to you at{' '}
                                    <b>darrellstew@gmail.com</b>
                                </Typography>

                                <TaoButton className="home-btn" onClick={onClose}>
                                    return home
                                </TaoButton>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Slide>
        </ModalWrapper>
    );
};

export default ReportRoomModal;
