import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { Box, Button, Typography } from '@mui/material';
import { getWindowDimensions } from 'Helpers';
import Lottie from 'lottie-react';
import {
    URL_HOME_PAGE,
    URL_PROGRAMS_DETAILS,
    URL_HOW_TO_MEDITATE_CONTENT,
    URL_HOW_TO_MEDITATE
} from 'Helpers/Paths';
// MASCOT
import PointingUpToStanding from 'Assets/AnimationFiles/TourAnimation/pointing-up-to-standing.json';
import PointingDownLeft from 'Assets/AnimationFiles/TourAnimation/points-down-and-left.json';
// import PointingDownRight from 'Assets/AnimationFiles/TourAnimation/points-down-and-right.json';
import PointingUpLeft from 'Assets/AnimationFiles/TourAnimation/points-up-and-left.json';
import PointingLeft from 'Assets/AnimationFiles/TourAnimation/pointing-left.json';

import StandingDollAnimation from 'Assets/AnimationFiles/Questionnaire/MascotStandingIdleLoop.json';
import { ReactComponent as CloseIcon } from 'Assets/images/Common/close-icon.svg';

import { WebsiteTourWrapper } from './WebsiteTour.style';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import DOMPurify from 'dompurify';
import Slider from 'react-slick';
import { INIT_SPOT_ANIMATION } from 'Helpers/Constants';

const WHITE_CIRCLE_DIMENSION = {
    WIDTH: '200px',
    HEIGHT: '200px',
    WIDTH_BY_TWO: '100px',
    HEIGHT_BY_TWO: '100px'
};

const CONTENT_DIMENSION = {
    WIDTH: '500px',
    HEIGHT: '200px',
    WIDTH_BY_TWO: '250px',
    HEIGHT_BY_TWO: '100px'
};
const MASCOT_WIDTH = 300;
const MASCOT_TIMEOUT = 500;

const STEPs_INDEX = [
    { step: 'INIT', content: 'Three ways to <b>Search</b> our library and community:' },
    { step: 'SEARCH', content: '1. Type in a condition you’re seeking to transform' },
    { step: 'POPULAR_SEARCH', content: '2. Pick suggested searches for you' },
    { step: 'SEARCH_BY_CATEGORY', content: '3. Explore the wellness library by <b>Category</b>' },

    // OTHERS
    { step: 'OTHER', content: 'Ready to surf the search?' },
    { step: 'OTHER', content: 'What would you like to check out next, <First Name>?' }
];

// 6 STEPS
const LOTTIE_ANIMATION = [
    StandingDollAnimation,
    PointingUpLeft,
    PointingLeft,
    PointingLeft,
    StandingDollAnimation,
    StandingDollAnimation
];

const IDLE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const TABLET_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    PointingLeft,
    PointingLeft,
    PointingDownLeft,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const TABLET_IDLE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const MOBILE_LOTTIE_ANIMATION = [
    PointingUpToStanding,
    PointingUpLeft,
    PointingUpLeft,
    PointingUpLeft,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const MOBILE_IDLE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    touchMove: false
};

const ListButtons = forwardRef(
    (
        {
            backButtonTitle = '',
            nextButtonTitle = '',
            currentStep,
            setCurrentStep,
            updateButtons,
            isFinalStep = true,
            contentId,
            programId,
            ...props
        },
        ref
    ) => {
        const navigate = useNavigate();
        return (
            <Box className="button-parent-wrapper flex f-column gap">
                <Button
                    onClick={() => {
                        if (isFinalStep) {
                            return navigate(URL_HOME_PAGE);
                        }
                    }}
                    className={`btn primary full-width`}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize('Go Home')
                        }}
                        className="font"
                    />
                </Button>
                <Button
                    onClick={() => {
                        if (isFinalStep && contentId) {
                            return navigate(URL_HOW_TO_MEDITATE_CONTENT, {
                                state: { contentId, programId }
                            });
                        }
                    }}
                    className={`btn primary full-width`}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize('Experience a Meditation')
                        }}
                        className="font"
                    />
                </Button>
                {programId !== -1 && (
                    <Button
                        onClick={() => {
                            if (isFinalStep) {
                                return navigate(URL_PROGRAMS_DETAILS, {
                                    state: { contentId, programId, isRedirectedFromHistory: false }
                                });
                            }
                        }}
                        className={`btn primary full-width`}>
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize('Join a Program')
                            }}
                            className="font"
                        />
                    </Button>
                )}
                <Button
                    onClick={() => {
                        if (isFinalStep) {
                            return navigate(URL_HOW_TO_MEDITATE, {
                                state: { contentId, programId }
                            });
                        }
                    }}
                    className={`btn primary full-width`}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize('Learn How This Works')
                        }}
                        className="font"
                    />
                </Button>
            </Box>
        );
    }
);

const FooterButtons = forwardRef(
    (
        {
            backButtonTitle = '',
            nextButtonTitle = '',
            currentStep,
            setCurrentStep,
            updateButtons,
            isFinalStep = false,
            ...props
        },
        ref
    ) => (
        <Box className="button-parent-wrapper">
            <Button
                onClick={() => {
                    if (currentStep + 1 === STEPs_INDEX?.length - 1) {
                        ref.current.slickNext();
                        updateButtons();
                        setCurrentStep(currentStep + 1);
                        return;
                    }
                    ref.current.slickPrev();
                    setCurrentStep(currentStep - 1);
                }}
                className={`btn back ${!currentStep && 'hide'}`}>
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(backButtonTitle)
                    }}
                    className={`font ${isFinalStep && 'text-transform'}`}
                />
            </Button>
            <Button
                onClick={() => {
                    if (isFinalStep) return props?.closeTutorial();
                    if (currentStep + 1 === STEPs_INDEX?.length - 2) {
                        updateButtons();
                        setCurrentStep(currentStep + 1);
                    }
                    ref.current.slickNext();
                    setCurrentStep(currentStep + 1);
                }}
                className={`btn font primary`}>
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(nextButtonTitle)
                    }}
                    className={`font ${isFinalStep && 'text-transform'}`}
                />
            </Button>
        </Box>
    )
);

const WebsiteTour = ({
    isLoading,
    contentId,
    programId,
    handleMascotTour,
    userDetails,
    ...props
}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isCompletedAnimation, setIsCompletedAnimation] = useState(false);
    const [loop, setLoop] = useState(false);
    const animationRef = useRef(null);
    const slickRef = useRef(null);
    const buttonRef = useRef(null);
    const [mascotPosition, setMascotPosition] = useState({
        transform: undefined
    });
    const [initAnimationSpot, setInitAnimationSpot] = useState('');

    const [tourStaringId, setStartingTourId] = useState({
        width: undefined,
        height: undefined,
        transform: undefined
    });
    const [contentDimension, setContentDimension] = useState({
        width: CONTENT_DIMENSION.WIDTH,
        height: CONTENT_DIMENSION.HEIGHT,
        halfWidth: CONTENT_DIMENSION.WIDTH_BY_TWO,
        halfHeight: CONTENT_DIMENSION.HEIGHT_BY_TWO
    });
    const [polygonDimension, setPolygonDimension] = useState('');

    const [boxPosition, setBoxPosition] = useState('');
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const setSearchDimensions = useCallback(
        (animationDimension) => {
            if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                setStartingTourId({
                    transform: `translate(calc(${animationDimension?.left}px - 21px), calc(${animationDimension?.top}px - 29px))`,
                    width: animationDimension.width + 40,
                    height: animationDimension.width + 40
                });
                return;
            }

            if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                setStartingTourId({
                    transform: `translate(calc(${animationDimension?.left}px - 75px + ${
                        animationDimension.width / 2
                    }px), calc(${animationDimension?.top}px - 75px + ${
                        animationDimension?.height / 2
                    }px))`,
                    width: '150px',
                    height: '150px'
                });
                return;
            }

            setStartingTourId({
                transform: `translate(calc(${animationDimension?.left}px - 42px), calc(${animationDimension?.top}px - 54px))`,
                width: animationDimension.width + 90,
                height: animationDimension.width + 90
            });
        },
        [windowDimensions.width]
    );
    const setPopularSearchDimensions = useCallback(
        (animationDimension) => {
            if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                setStartingTourId({
                    transform: `translate(calc(${animationDimension?.left}px - 10px), calc(${
                        animationDimension?.top
                    }px - ${animationDimension.width / 2}px))`,
                    width: animationDimension.width + 20,
                    height: animationDimension.width + 20
                });
                return;
            }

            if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                setStartingTourId({
                    transform: `translate(calc(${animationDimension?.left}px - 75px + ${
                        animationDimension.width / 2
                    }px), calc(${animationDimension?.top}px - 75px + ${
                        animationDimension?.height / 2
                    }px))`,
                    width: '150px',
                    height: '150px'
                });
                return;
            }

            setStartingTourId({
                transform: `translate(calc(${animationDimension?.left}px - 20px), calc(${animationDimension?.top}px - 60px))`,
                width: animationDimension.width + 40,
                height: animationDimension.width + 40
            });
        },
        [windowDimensions.width]
    );
    const setSearchByCategoryDimensions = useCallback(
        (animationDimension) => {
            if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                setStartingTourId({
                    transform: `translate(calc(${animationDimension?.left}px - 10px), calc(${
                        animationDimension?.top
                    }px - ${animationDimension.width / 2}px))`,
                    width: animationDimension.width + 20,
                    height: animationDimension.width + 20
                });
                return;
            }

            if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                setStartingTourId({
                    transform: `translate(calc(${animationDimension?.left}px - 75px + ${
                        animationDimension.width / 2
                    }px), calc(${animationDimension?.top}px - 75px + ${
                        animationDimension?.height / 2
                    }px))`,
                    width: '150px',
                    height: '150px'
                });
                return;
            }

            setStartingTourId({
                transform: `translate(calc(${animationDimension?.left}px - 20px), calc(${animationDimension?.top}px - 100px))`,
                width: animationDimension.width + 40,
                height: animationDimension.width + 40
            });
        },
        [windowDimensions.width]
    );

    const shareContent = useCallback(() => {
        let animationDimension = document?.getElementById?.('search-id')?.getBoundingClientRect?.();

        if (animationDimension)
            setTimeout(() => setSearchDimensions(animationDimension), MASCOT_TIMEOUT);
    }, [setSearchDimensions]);

    const popularSearchContent = useCallback(() => {
        let animationDimension = document
            ?.getElementById?.('popular-search-1-id')
            ?.getBoundingClientRect?.();

        if (animationDimension)
            setTimeout(() => setPopularSearchDimensions(animationDimension), MASCOT_TIMEOUT);
    }, [setPopularSearchDimensions]);

    const searchByCategoryContent = useCallback(() => {
        let animationDimension = document
            ?.getElementById?.('search-by-category-text-id')
            ?.getBoundingClientRect?.();

        if (animationDimension)
            setTimeout(() => setSearchByCategoryDimensions(animationDimension), MASCOT_TIMEOUT);
    }, [setSearchByCategoryDimensions]);

    const initAnimation = useCallback(() => {
        let animationDimension = document?.getElementById?.('mascot-id')?.getBoundingClientRect?.();

        if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
            const initTransform =
                currentStep === 5
                    ? `translate(calc(250px), calc(125px))`
                    : `translate(calc(${windowDimensions.width}px - ${
                          MASCOT_WIDTH / 2
                      }px - 25px), calc(${windowDimensions.height - MASCOT_WIDTH - 80}px))`;

            if (currentStep !== 5) {
                let animationStartingSpot = (INIT_SPOT_ANIMATION - 150) / 2;

                setInitAnimationSpot(
                    `translate(calc(${windowDimensions.width}px - ${
                        MASCOT_WIDTH / 2
                    }px - 25px - ${animationStartingSpot}px), calc(${
                        windowDimensions.height - MASCOT_WIDTH - 80
                    }px - ${animationStartingSpot}px))`
                );
            }
            setStartingTourId({
                transform: initTransform,
                width: '150px',
                height: '150px'
            });

            const initMascot =
                currentStep === 5
                    ? `translate(calc(225px), calc(100px))`
                    : `translate(calc(${windowDimensions.width}px - 200px), calc(${
                          windowDimensions.height - MASCOT_WIDTH - 80
                      }px - 25px))`;

            setMascotPosition({
                transform: initMascot
            });

            const initBox =
                currentStep === 5
                    ? `translate(calc(20px), calc(299px))`
                    : `translate(20px, calc(${windowDimensions.height}px - ${contentDimension.height} - 20px - 15px))`;

            setBoxPosition(initBox);
            setPolygonDimension(
                `translate(${windowDimensions?.width - 150}px, -35px) rotate(45deg)`
            );
            return;
        }
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            let animationStartingSpot = (INIT_SPOT_ANIMATION - 200) / 2;

            setInitAnimationSpot(
                `translate(calc(${
                    windowDimensions.width
                }px - ${MASCOT_WIDTH}px + 50px - ${animationStartingSpot}px), calc(${
                    MASCOT_WIDTH / 2
                }px - 50px - ${animationStartingSpot}px))`
            );
            setStartingTourId({
                transform: `translate(calc(${
                    windowDimensions.width
                }px - ${MASCOT_WIDTH}px + 50px), calc(${MASCOT_WIDTH / 2}px - 50px))`,
                width: WHITE_CIRCLE_DIMENSION.WIDTH,
                height: WHITE_CIRCLE_DIMENSION.HEIGHT
            });

            setBoxPosition(
                `translate(calc(${windowDimensions.width}px - ${contentDimension.width} - 50px), 310px)`
            );
            setPolygonDimension(
                `translate(calc(${contentDimension.width} - 100px), calc(-35px)) rotate(45deg)`
            );
            setMascotPosition({
                transform: `translate(calc(${windowDimensions.width}px - ${MASCOT_WIDTH}px), 50px)`
            });
            return;
        }
        if (animationDimension && windowDimensions.width >= BREAKPOINTS_VALUE.TABLET) {
            let initTransform = `translate(calc(${windowDimensions.width}px - ${
                contentDimension.halfWidth
            } - ${MASCOT_WIDTH / 3}px), calc(${animationDimension?.height / 2}px + 40px))`;
            let animationStartingSpot = (INIT_SPOT_ANIMATION - 200) / 2;

            setInitAnimationSpot(
                `translate(calc(${windowDimensions.width}px - ${contentDimension.halfWidth} - ${
                    MASCOT_WIDTH / 3
                }px - ${animationStartingSpot}px), calc(${
                    animationDimension?.height / 2
                }px + 40px - ${animationStartingSpot}px))`
            );

            setStartingTourId({
                transform: initTransform,
                width: '200px',
                Height: '200px'
            });

            setBoxPosition(
                `translate(calc(${windowDimensions.width}px - ${contentDimension.halfWidth} - ${MASCOT_WIDTH}px), calc(${animationDimension?.height}px + 100px))`
            );
            setPolygonDimension(
                `translate(calc(${contentDimension.width} - 200px), calc(-35px)) rotate(45deg)`
            );

            setMascotPosition({
                transform: `translate(calc(${windowDimensions.width}px - ${
                    contentDimension.halfWidth
                } - ${MASCOT_WIDTH / 2}px), calc(${animationDimension?.height / 2}px))`
            });
            return;
        }
    }, [
        windowDimensions.width,
        contentDimension.width,
        windowDimensions.height,
        contentDimension.height,
        contentDimension.halfWidth,
        currentStep
    ]);

    const mascotAnimation = useCallback(
        (currentStep, isCompletedAnimation) => {
            if (windowDimensions.width >= BREAKPOINTS_VALUE.TABLET) {
                if (isCompletedAnimation) {
                    return IDLE_LOTTIE_ANIMATION[currentStep];
                }
                return LOTTIE_ANIMATION[currentStep];
            }

            if (
                windowDimensions.width >= BREAKPOINTS_VALUE.PHABLET &&
                windowDimensions.width <= BREAKPOINTS_VALUE.TABLET
            ) {
                if (isCompletedAnimation) {
                    return TABLET_IDLE_LOTTIE_ANIMATION[currentStep];
                }
                return TABLET_LOTTIE_ANIMATION[currentStep];
            }

            if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                if (isCompletedAnimation) {
                    return MOBILE_IDLE_LOTTIE_ANIMATION[currentStep];
                }
                return MOBILE_LOTTIE_ANIMATION[currentStep];
            }
        },
        [windowDimensions.width]
    );

    const replayAnimation = () => {
        animationRef.current.stop();
        animationRef.current.play();
    };

    const updateAnimationStatus = () => {
        setIsCompletedAnimation(false);
        setLoop(false);
    };

    const jumpToStep = useCallback(() => {
        switch (STEPs_INDEX?.[currentStep]?.step) {
            case 'INIT':
                updateAnimationStatus();
                initAnimation();
                break;
            case 'SEARCH':
                updateAnimationStatus();
                replayAnimation();
                shareContent();
                break;
            case 'POPULAR_SEARCH':
                updateAnimationStatus();
                replayAnimation();
                popularSearchContent();
                break;
            case 'SEARCH_BY_CATEGORY':
                replayAnimation();
                searchByCategoryContent();
                break;
            default:
                updateAnimationStatus();
                initAnimation();
                return;
        }
    }, [initAnimation, currentStep, shareContent, popularSearchContent, searchByCategoryContent]);

    const updateButtons = () => {
        buttonRef.current.slickNext();
    };

    const startFormInit = () => {
        slickRef?.current?.slickGoTo(0);
        buttonRef?.current?.slickPrev();
        setCurrentStep(0);
    };

    const handleCloseMascotTour = () => {
        handleMascotTour();
        props?.setTourEnable(false);
    };

    useEffect(() => {
        jumpToStep();
        if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
            setContentDimension((prev) => ({
                ...prev,
                width: `${windowDimensions?.width - 40}px`,
                height: '200px'
            }));
            return;
        }
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            setContentDimension((prev) => ({ ...prev, width: '300px', height: '200px' }));
            return;
        }
    }, [windowDimensions?.height, windowDimensions?.width, isLoading, jumpToStep]);

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <React.Fragment>
            {!isLoading &&
                createPortal(
                    <WebsiteTourWrapper $initAnimationSpot={initAnimationSpot}>
                        <Box className="tour">
                            {tourStaringId?.transform && (
                                <Box
                                    className="tour-starting-box"
                                    style={{
                                        transform: tourStaringId?.transform,
                                        width: tourStaringId?.width,
                                        height: tourStaringId?.width
                                    }}
                                />
                            )}
                        </Box>
                        <Box className="content-container">
                            <Lottie
                                className="mascot"
                                id="mascot-id"
                                animationData={mascotAnimation(currentStep, isCompletedAnimation)}
                                style={{
                                    transform: mascotPosition?.transform
                                }}
                                onComplete={() => {
                                    setIsCompletedAnimation(true);
                                    setLoop(true);
                                }}
                                lottieRef={animationRef}
                                loop={loop}
                            />
                            {boxPosition && (
                                <Box
                                    className="tour-content"
                                    style={{
                                        transform: boxPosition,
                                        width: contentDimension?.width,
                                        height: currentStep === 5 ? 400 : contentDimension?.height
                                    }}>
                                    <Box
                                        className="polygon"
                                        style={{ transform: polygonDimension }}
                                    />
                                    <Box
                                        className="close-icon-btn hover"
                                        onClick={() => handleCloseMascotTour()}>
                                        <CloseIcon className="close-svg" />
                                    </Box>
                                    <Box className="content">
                                        <Slider {...settings} ref={slickRef}>
                                            {STEPs_INDEX.map((item, index) => (
                                                <Box
                                                    className="text"
                                                    key={index}
                                                    dangerouslySetInnerHTML={{
                                                        __html: item?.content?.includes(
                                                            '<First Name>'
                                                        )
                                                            ? DOMPurify.sanitize(
                                                                  item?.content?.replace(
                                                                      '<First Name>',
                                                                      userDetails?.firstName
                                                                  )
                                                              )
                                                            : DOMPurify.sanitize(item?.content)
                                                    }}
                                                />
                                            ))}
                                        </Slider>

                                        <Box
                                            className={`footer-buttons ${
                                                currentStep === 5 &&
                                                windowDimensions.width <=
                                                    BREAKPOINTS_VALUE.TABLET &&
                                                `tablet`
                                            }`}>
                                            <Slider {...settings} ref={buttonRef}>
                                                <FooterButtons
                                                    ref={slickRef}
                                                    currentStep={currentStep}
                                                    setCurrentStep={setCurrentStep}
                                                    backButtonTitle="back"
                                                    nextButtonTitle="next"
                                                    updateButtons={updateButtons}
                                                />
                                                <FooterButtons
                                                    ref={slickRef}
                                                    currentStep={currentStep}
                                                    setCurrentStep={setCurrentStep}
                                                    backButtonTitle="NO <br/> Continue Tutorial"
                                                    nextButtonTitle="YES <br/> Close Tutorial"
                                                    updateButtons={updateButtons}
                                                    startFormInit={startFormInit}
                                                    isFinalStep={true}
                                                    closeTutorial={() => handleCloseMascotTour()}
                                                />
                                                <ListButtons
                                                    ref={slickRef}
                                                    currentStep={currentStep}
                                                    setCurrentStep={setCurrentStep}
                                                    backButtonTitle="back"
                                                    nextButtonTitle="next"
                                                    updateButtons={updateButtons}
                                                    contentId={contentId}
                                                    programId={programId}
                                                />
                                            </Slider>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </WebsiteTourWrapper>,
                    document.body
                )}
        </React.Fragment>
    );
};

export default WebsiteTour;
