import { Box, Popover, StepConnector, stepConnectorClasses } from '@mui/material';
import styled from 'styled-components';
import { BREAKPOINTS_VALUE, COLORS, FONTS, responsive } from 'Styles/Constants';

export const MeditationsWrapper = styled.div`
    height: 100%;

    .meditations-title {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 35px;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_DARK};
        margin: 0 0 10px 0;

        ${responsive.TABLET`
            padding: 0 0 0 19px;
        `}
    }

    .meditations-data {
        gap: 21px;
        height: 100%;
        position: relative;

        ${responsive.TABLET`
            flex-direction: column;
            height: calc(100% - 10px);
        `}

        .timeline-section {
            width: 25%;
            min-width: 25%;

            ${responsive.TABLET`
                width: 100%;
                order: 2;
                border-radius: 30px 30px 0px 0px;
                box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
                padding: 20px;
                position: absolute;
                bottom: 0;
                background: white;
                z-index: 10;
            `}

            .timeline-title {
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 35px;
                text-transform: capitalize;
                color: ${COLORS.MEDIUM_GREY};
            }

            .steeper-wrapper {
                margin-bottom: 10px;

                ${responsive.TABLET`
                    width: 100%;
                    padding: 0;
                    margin: 0;
                `}

                .steeper {
                    margin: 0 auto;
                    width: max-content;
                    padding: 20px 0 0 30px;

                    ${responsive.TABLET`
                        width: 100%;
                        padding: 10px 0;
                    `}

                    .vertical-completed {
                        border: 1px solid #fff;
                        border-radius: 50%;
                        transform: scale(1.1);

                        &.vertical-root {
                            border-radius: 50%;
                            transform: scale(1.1);
                            border: 10px solid ${COLORS.WHITE};
                        }
                    }

                    .label-container {
                        position: absolute;
                        width: max-content;
                        margin: 0 -75px;
                        cursor: pointer;

                        ${responsive.TABLET`
                            position: relative;
                            margin: 0;
                        `}
                    }

                    .icon-container {
                        padding: 0;

                        ${responsive.TABLET`
                            border-radius: 50%;
                            background-color: #fff;
                            z-index: 20;
                            border: 5px solid #fff;
                            transform: scale(1.1);
                            height: 40px;
                            width: 40px;
                            top: 5px;
                            position: relative;
                        `}
                    }

                    .active-container {
                        ${responsive.TABLET`
                            top: 0;
                            height: 50px;
                            width: 50px;
                        `}
                    }

                    .completed-container {
                        ${responsive.TABLET`
                            top: 5px;
                            position: relative;
                        `}
                    }

                    .step-label {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 26px;
                        text-transform: capitalize;
                        color: ${COLORS.MEDIUM_GREY};
                        text-align: right;
                        width: 65px;

                        ${responsive.TABLET`
                            text-align: center;
                        `}
                    }
                    .label-root {
                        padding: 0;

                        ${responsive.TABLET`
                            padding: auto;
                        `}
                    }
                }
            }
        }

        .empty {
            width: 100%;

            .message {
                font-family: ${FONTS.PRIMARY};
                color: ${COLORS.TEXT_DARK};
                font-style: normal;
                font-size: 24px;
            }
        }

        .video-section {
            width: 75%;
            height: calc(100% - 45px);

            ${responsive.TABLET`
                width: 100%;
                height: calc(100% - 200px);
            `}

            .video-card {
                gap: 13px;
                padding: 20px 15px;
                width: 100%;

                &:nth-child(2n) {
                    background: rgba(242, 242, 242, 0.8);
                }

                .img-section {
                    position: relative;
                    padding: 0;
                    overflow: hidden;
                    border-radius: 11px;
                    height: 80px;
                    min-width: 150px;

                    .meditation-img {
                        width: 150px;
                        border-radius: 11px;
                        height: 80px;
                    }

                    .play-button {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 45px;
                        height: 45px;

                        .playlist-icon {
                            width: 35px;
                            height: 35px;
                        }
                    }

                    .progress-bar {
                        position: absolute;
                        bottom: 0;
                        z-index: 5;
                        width: 150px;

                        &.bar1 {
                            background: #e8e8e8aa;
                        }
                    }
                }

                .desc-section {
                    position: relative;
                    width: 100%;

                    .meditation-desc {
                        gap: 7px;
                        max-width: 90%;

                        .desc-title {
                            font-family: ${FONTS.PRIMARY};
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 20px;
                            color: ${COLORS.PRIMARY_WARM};
                        }

                        .desc-path {
                            font-family: ${FONTS.PRIMARY};
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: ${COLORS.TEXT_DARK};
                        }

                        .desc-date {
                            font-family: ${FONTS.PRIMARY};
                            font-style: normal;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 17px;
                            color: ${COLORS.TEXT_DARK};
                        }

                        .two-line-ellipse {
                            width: inherit;
                            white-space: pre-wrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }

                    .option-section {
                        position: absolute;
                        right: 0;

                        .option-icon-btn {
                            width: 35px;
                            height: 35px;
                        }
                    }
                }
            }

            .load-more {
                padding: 20px 0;
                width: max-content;
                margin: 0 auto;

                &:hover {
                    .load-more-arrow {
                        transition: all 0.3s ease-in-out;
                        margin: 5px 0 0;
                    }
                }

                .load-more-text {
                    color: ${COLORS.PRIMARY};
                }

                .load-more-arrow {
                    width: 10px;
                    height: 10px;
                    border-right: 1px solid ${COLORS.PRIMARY};
                    border-bottom: 1px solid ${COLORS.PRIMARY};
                    transform: rotate(45deg);
                }
            }
        }
    }
`;

export const MeditationsImageWrapper = styled.div`
    width: 150px;
    border-radius: 11px;
    height: 80px;
    background-image: ${(props) => props.meditationsImage && `url(${props.meditationsImage})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const StepIconWrapper = styled(Box)`
    background-color: #ccc;
    z-index: 1;
    color: ${COLORS.WHITE};
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 0;

    &.active {
        background: ${COLORS.PRIMARY};
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
        width: 40px;
        height: 40px;
    }

    &.completed {
        background: ${COLORS.PRIMARY};
    }
`;

export const StepperLineConnectorWrapper = styled(StepConnector)(
    ({ theme, $windowDimensions }) => ({
        [`&.${stepConnectorClasses?.alternativeLabel}`]: {
            top: $windowDimensions?.width < BREAKPOINTS_VALUE.TABLET ? 20 : 'auto'
        },

        [`&.${stepConnectorClasses?.active}`]: {
            [`& .${stepConnectorClasses?.line}`]: {
                background: '#C3A157'
            }
        },

        [`&.${stepConnectorClasses?.completed}`]: {
            [`& .${stepConnectorClasses?.line}`]: {
                background: '#C3A157'
            }
        },

        [`& .${stepConnectorClasses.line}`]: {
            height: $windowDimensions?.width < BREAKPOINTS_VALUE.TABLET ? 10 : 150,
            width: $windowDimensions?.width < BREAKPOINTS_VALUE.TABLET ? 'auto' : 10,
            border: 0,
            backgroundColor: '#eaeaf0'
        },

        [`&.${stepConnectorClasses.root}`]: {
            margin: 0
            // top: '30px'
        }
    })
);

export const PopOverWrapper = styled(Popover)`
    .remove-box {
        width: 150px;
        height: 45px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(237, 237, 237);
        border-radius: 15px;

        .popup-text {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
            text-transform: capitalize;
            color: #000000;
            user-select: none;
        }

        .close-icon {
            width: 35px;
            height: 35px;
        }
    }
`;
