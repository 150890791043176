import { IconButton, Dialog } from '@mui/material';
import styled from 'styled-components';
import CopyImage from 'Assets/images/Common/copy.png';
import { responsive, COLORS, FONTS } from 'Styles/Constants';

export const ShareWrapper = styled(Dialog)`
    .paper {
        height: max-content;
        width: 70%;
        display: flex;
        flex-direction: row;
        background: #ffffff;
        padding: 10px;
        box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.15);
        border-radius: 24px;
        align-items: center;
        margin: 5px 0;
        padding: 20px;
    }

    .icon {
        margin: 5px 10px;
    }

    .preview-container {
        height: 200px;
        width: 100%;
        overflow: hidden;
        margin: 0 0 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .preview-text {
            font-family: ${FONTS.SECONDARY_BOLD};
            font-size: 26px;
            text-transform: capitalize;
            color: ${COLORS.PRIMARY_DARK};
            margin: 0 0 10px 0;
        }

        .preview-image-container {
            height: 200px;
            overflow: scroll;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: baseline;

            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            .preview-image {
                ${responsive.TABLET`
                    width: 100%;
                `}

                width: 400px;
            }
        }
    }

    .share-button-container {
        display: flex;
        justify-content: space-between;
        margin: 0px 0 20px 0;
        overflow: auto;
    }

    .copy-link-container {
        height: max-content;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .link {
            display: flex;
            align-items: center;
            padding: 10px 0;
            height: 40px;
            border-radius: 10px;
            background-color: #e7e7e7;
            width: 90%;

            ${responsive.TABLET`
                width: 80%;
            `}

            .link-text {
                width: 90%;
                margin: 0 10px;
                overflow: auto;
                white-space: nowrap;
            }
        }

        .copy-icon {
            cursor: pointer;
            background-image: url(${CopyImage});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 30px;
            height: 30px;
        }
    }
`;

export const IconButtonWrapper = styled(IconButton)`
    width: ${(props) => (props?.$dynamic_dimension ? props?.$dynamic_dimension : '50px')};
    height: ${(props) => (props?.$dynamic_dimension ? props?.$dynamic_dimension : '50px')};
    &.root {
        padding: 0;
    }
`;
