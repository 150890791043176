import React from 'react';
import Lottie from 'lottie-react';
import { Box } from '@mui/material';
import ToggleOnWhite from 'Assets/AnimationFiles/Favorite/favorite-toggle-on-white.json';
import ToggleOnPrimary from 'Assets/AnimationFiles/Favorite/favorite-toggle-on.json';
import ToggleOffWhite from 'Assets/AnimationFiles/Favorite/favorite-toggle-off-white.json';
import ToggleOffPrimary from 'Assets/AnimationFiles/Favorite/favorite-toggle-off.json';

const FavoriteIconAnimation = ({
    isFavorite,
    isWhiteIcon = false,
    height = 37,
    width = 40,
    ...props
}) => (
    <Box className="flex">
        {isFavorite ? (
            !isWhiteIcon ? (
                <Lottie
                    loop={false}
                    animationData={ToggleOnPrimary}
                    style={{ width: width, height: height }}
                />
            ) : (
                <Lottie
                    loop={false}
                    animationData={ToggleOnWhite}
                    style={{ width: width, height: height }}
                />
            )
        ) : isWhiteIcon ? (
            <Lottie
                loop={false}
                animationData={ToggleOffWhite}
                style={{ width: width, height: height }}
            />
        ) : (
            <Lottie
                loop={false}
                animationData={ToggleOffPrimary}
                style={{ width: width, height: height }}
            />
        )}
    </Box>
);

export default FavoriteIconAnimation;
