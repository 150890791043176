import styled from 'styled-components';
import { Box } from '@mui/material';

import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const CalendarWrapper = styled(Box)`
    margin: auto;
    overflow-y: auto;
    max-height: 100vh;
    display: flex;
    justify-content: center;

    .page-content {
        margin-top: 127px;
        display: flex;
        flex-direction: column;
        width: 60%;
        z-index: 5;
        gap: 26px;
        max-width: 980px;
        min-width: 980px;

        @media only screen and (max-width: 1200px) {
            min-width: 910px;
            margin-left: 10%;
        }

        @media only screen and (max-width: 1050px) {
            min-width: 850px;
        }

        @media only screen and (max-width: 860px) {
            min-width: 800px;
            width: 100%;
            padding: 5px;
            margin-top: 100px;
        }

        @media only screen and (max-width: 800px) {
            min-width: 700px;
        }

        @media only screen and (max-width: 700px) {
            min-width: 600px;
        }

        @media only screen and (max-width: 600px) {
            min-width: 100%;
            padding: 0;
        }

        ${responsive.TABLET`
			margin-left: 0;
		`}
        /* CALENDAR CLASSES */
    	.week-calendar-container {
            display: none;

            @media only screen and (max-width: 800px) {
                display: flex;
                min-width: 90%;
                justify-content: center;
            }

            ${responsive.PHABLET`
				width: 100%;
			`}

            .week-calendar {
                min-width: 90%;
                justify-content: space-around;

                ${responsive.PHABLET`
					min-width: 100%;
				`}
            }
        }

        .static-calendar-root {
            width: 450px;
            height: 495px;
            background-color: rgb(232 232 232 / 50%);
            max-height: 495px;
            max-width: 45%;
            z-index: 25;
            margin: 0;

            @media only screen and (max-width: 1200px) {
                max-width: 50%;
            }

            @media only screen and (max-width: 870px) {
                max-width: 45%;
            }

            @media only screen and (max-width: 800px) {
                display: none;
            }

            .PrivatePickersFadeTransitionGroup-root {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                text-transform: uppercase;
            }

            .PrivatePickersYear-root > button {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
            }

            .MuiCalendarPicker-viewTransitionContainer > div > div:first-child {
                justify-content: space-evenly;
                & > span {
                    margin: 0px 8px;
                    font-weight: 500;
                    font-size: 12.5312px;
                    line-height: 18px;
                    text-align: center;
                    font-family: ${FONTS.PRIMARY_MEDIUM};
                    color: #656565;

                    @media only screen and (max-width: 1050px) {
                        margin: 0px -9px;
                    }

                    @media only screen and (max-width: 870px) {
                        margin: 0px 6px;
                    }

                    &:nth-child(1) {
                        &::after {
                            content: 'UN';
                        }
                        color: ${(props) => (props.currentWeekDay === 0 ? COLORS.PRIMARY_DARK : '#656565')};
                    }

                    &:nth-child(2) {
                        &::after {
                            content: 'ON';
                        }
                        color: ${(props) => (props.currentWeekDay === 1 ? COLORS.PRIMARY_DARK : '#656565')};
                    }

                    &:nth-child(3) {
                        &::after {
                            content: 'UE';
                        }
                        color: ${(props) => (props.currentWeekDay === 2 ? COLORS.PRIMARY_DARK : '#656565')};
                    }

                    &:nth-child(4) {
                        &::after {
                            content: 'ED';
                        }
                        color: ${(props) => (props.currentWeekDay === 3 ? COLORS.PRIMARY_DARK : '#656565')};
                    }

                    &:nth-child(5) {
                        &::after {
                            content: 'HU';
                        }
                        color: ${(props) => (props.currentWeekDay === 4 ? COLORS.PRIMARY_DARK : '#656565')};
                    }

                    &:nth-child(6) {
                        &::after {
                            content: 'RI';
                        }
                        color: ${(props) => (props.currentWeekDay === 5 ? COLORS.PRIMARY_DARK : '#656565')};
                    }

                    &:nth-child(7) {
                        &::after {
                            content: 'AT';
                        }
                        color: ${(props) => (props.currentWeekDay === 6 ? COLORS.PRIMARY_DARK : '#656565')};
                    }
                }
            }
        }

        .calendar-root {
            max-width: 490px;
            width: 490px;

            @media only screen and (max-width: 1050px) {
                width: 380px;
            }
        }

        .calendar-day-root {
            border: 1.5px solid rgb(132 132 132 / 50%);
            margin: 10px 11px;
            width: 40px;
            height: 40px;
            font-weight: 500;
            font-size: 21.482px;
            line-height: 31px;
            background-color: transparent;
            font-family: ${FONTS.PRIMARY};
            color: #656565;

            @media only screen and (max-width: 1050px) {
                margin: 10px 7px;
            }

            @media only screen and (max-width: 850px) {
                margin: 10px 5px;
            }
        }

        .today-date {
            border: 2px solid ${COLORS.PRIMARY} !important;
            color: ${COLORS.PRIMARY};
            background-color: #ffffff;

            &.dark-gray {
                background-color: #848484;
            }
        }

        .dot {
            &.hide {
                display: none;
            }

            width: 7px;
            height: 7px;
            border-radius: 50%;

            &.light {
                background-color: #e8e8e8;
            }

            &.dark-gray {
                background-color: #848484;
            }

            &.primary {
                background-color: ${COLORS.PRIMARY_DARK};
            }
        }

        div.calendar-day-root {
            border: 0;
        }

        .page-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            /* &:last-child {
				padding-bottom: 2rem;
				${responsive.TABLET`
				padding-bottom: 6rem;
				`}

				${responsive.PHABLET`
				padding-bottom: 7rem;
				`}
			} */

            &.sessions-timings-container {
                @media only screen and (max-width: 800px) {
                    flex-direction: column-reverse;
                    align-items: center;
                    width: 100%;
                    gap: 1rem;
                }
            }

            .schedule-list-container {
                min-width: 477px;
                /* min-width: 400px; */
                display: flex;
                width: 49%;
                flex-direction: column;
                align-items: center;
                margin-top: 0.5rem;
                gap: 30px;

                @media only screen and (max-width: 1200px) {
                    width: 45%;
                    min-width: 430px;
                }

                @media only screen and (max-width: 800px) {
                    min-width: 95%;
                }

                ${responsive.PHABLET`
					width: 100%;
				`}

                .date-container {
                    display: flex;
                    width: 100%;
                    justify-content: space-around;
                    align-items: center;
                    gap: 0.6rem;

                    ${responsive.PHABLET`
						display: none;
					`}

                    .week-day {
                        font-family: ${FONTS.SECONDARY_BOLD};
                        font-size: 60px;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 60px;
                        line-height: 40px;
                        letter-spacing: -0.02em;
                        text-transform: capitalize;
                    }

                    .date-text {
                        font-weight: 400;
                        font-size: 30px;
                        letter-spacing: -0.02em;
                        text-transform: capitalize;
                        color: #848484;
                    }

                    .filter-btn {
                        width: 90px;
                        height: 40px;
                        margin-top: auto;
                        margin-bottom: 5px;
                    }
                }

                .schedule-list {
                    display: flex;
                    flex-direction: column;
                    min-width: 95%;
                    max-height: 403px;
                    overflow-y: auto;
                    align-items: center;
                    ${responsive.PHABLET`
						width: 100%;
						max-height: 477px;
					`}

                    .session-card {
                        ${responsive.PHABLET`
							width: 100%;
						`}

                        &:nth-child(2n) {
                            background-color: #f2f2f2;
                            border-radius: 8px;
                        }
                        /* cursor: pointer; */
                        width: 96%;
                    }
                }
            }
        }

        .recommendation-listing-container {
            display: flex;
            flex-direction: column;
            padding-bottom: 2rem;
            ${responsive.TABLET`
				padding-bottom: 6rem;
			`}

            @media only screen and (max-width: 800px) {
                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }

            ${responsive.PHABLET`
				display: none;
			`}

            .recommendation-listing {
                display: flex;
                /* gap: 1rem; */

                @media only screen and (max-width: 800px) {
                    /* max-width: 90%; */
                }

                .slider-btn-container {
                    width: 5%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media only screen and (max-width: 800px) {
                        display: none;
                    }
                    .slider-btn {
                        margin: auto 1rem;
                        width: 44px;
                        height: 23px;
                        cursor: pointer;
                    }
                }

                .slider-content {
                    position: relative;
                    margin-bottom: 50px;

                    ${responsive.PHABLET`
						margin-bottom: 30px;
					`}
                }

                .slick-slider {
                    overflow: hidden;

                    ${responsive.PHABLET`
						margin-left: 15px;
					`}
                }

                .slick-center {
                    transform: scale(1.2);
                }

                .slick-slide {
                    display: none;
                    float: left;
                    height: 100%;
                    min-height: 1px;
                    margin-left: 1.5rem;
                }

                .slick-initialized .slick-slide {
                    display: block;
                }
            }
        }

        .mobile-recommendation-listing-container {
            display: none;
            background-color: #f6f6f6;
            box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
            border-radius: 35px 35px 0px 0px;

            & > div.MuiBox-root {
                min-width: 100%;
            }

            @media only screen and (max-width: 600px) {
                min-width: 90%;
                padding-top: 37px;
                /*padding-left: 22px; */
                padding-bottom: 6rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .recommendation-list {
                .session-card {
                    padding-top: 37px;
                    padding-left: 22px;

                    &:nth-child(2n) {
                        background-color: #f2f2f2;
                        border-radius: 8px;
                    }
                }
            }

            .load-more-btn {
                background-color: #f2f2f2;
                mix-blend-mode: multiply;
                margin-left: auto;
                margin-right: auto;
                padding: 8px 30px;
                color: black;
                text-transform: capitalize;
                font-size: 16px;
                line-height: 18px;
                font-weight: 300;
            }
        }
    }
`;
