import styled from 'styled-components';
import { responsive } from 'Styles/Constants';

export const TaoCalligraphyWatermarkWrapper = styled.div`
    position: relative;
    width: 100%;

    img {
        pointer-events: none;
        z-index: 200;
        position: fixed;
        top: ${(props) => (props && props.customTopHeight ? props.customTopHeight : '100px')};
        right: -70px;
        mix-blend-mode: multiply;
        user-select: none;
        opacity: 0.6;
        pointer-events: none;

        ${responsive.TABLET`
            top: 50px;
        `}
    }
`;
