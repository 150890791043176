import React, { useState } from 'react';
import { NotificationItemWrapper } from './Notifications.style';
import { Box } from '@mui/system';
import { Collapse, IconButton, Typography } from '@mui/material';
import { ReactComponent as ClearNotificationIcon } from 'Assets/images/Notifications/clear-notification-icon.svg';
import { ReactComponent as WhiteClearNotificationIcon } from 'Assets/images/Notifications/white-clear-notification-icon.svg';
import { ReactComponent as UpArrow } from 'Assets/images/Notifications/up-arrow.svg';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { URL_MEDITATION_TIMER, URL_PROGRAMS_DETAILS, URL_HOME_PAGE } from 'Helpers/Paths';
import { ACCESS_LEVELS_MODULEs, NOTIFICATION_TYPE_FOR_API } from 'Helpers/Constants';
import { useDispatch } from 'react-redux';
import { handleScrollToViewId, showSubscriptionModal } from 'Redux/App/Actions';
import usePermissions from 'Components/common/Permissions';
import ProgramIcon from 'Assets/images/Common/playlist-icon.svg';

const NotificationCard = ({
    notification,
    redirectionOfNotification,
    totalUnReadNotificationCount,
    image,
    initial,
    type,
    closeNotification = () => <></>,
    ...props
}) => (
    <Box className="flex full-width f-column" sx={{ overflow: 'hidden', borderRadius: '12px' }}>
        <Box
            onClick={() => redirectionOfNotification && redirectionOfNotification(notification)}
            className="container-width flex">
            {!!totalUnReadNotificationCount && (
                <Box className="float-div">
                    <Typography className="float-count">{totalUnReadNotificationCount}</Typography>
                </Box>
            )}
            <Box className="image" sx={{ backgroundImage: `url(${image})` }}>
                {NOTIFICATION_TYPE_FOR_API.PROGRAMS === type && (
                    <Box sx={{ backgroundImage: `url(${ProgramIcon})` }} className="program-icon" />
                )}
            </Box>
            <Box className="description">
                <Typography className="name elipsis">{notification.title}</Typography>
                <Typography className="message">{notification.body}</Typography>
                <Typography className="time-ago">
                    {moment.utc(notification.notificationTime).local().fromNow()}
                </Typography>
            </Box>
        </Box>
        {closeNotification}
        {!!initial && (
            <Box className={`border-line-one ${!totalUnReadNotificationCount && 'hide-line'}`} />
        )}
    </Box>
);

const NotificationDetails = ({
    totalUnReadNotificationCount,
    notification,
    image,
    remainingList,
    type,
    totalRecords,
    loadMore,
    removeNotification,
    setClearNotificationType,
    readNotificationList,
    updatedReadNotification,
    notificationStateType,
    setClearSingleNotification,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const meditationPermissions = usePermissions(ACCESS_LEVELS_MODULEs.MEDITATION_TIMER);

    const redirectionByType = (notification) => {
        if (NOTIFICATION_TYPE_FOR_API.MEDITATION === notification?.notificationType) {
            meditationPermissions?.canAccess
                ? navigate(URL_MEDITATION_TIMER)
                : dispatch(showSubscriptionModal());
        }

        if (NOTIFICATION_TYPE_FOR_API.PROGRAMS === notification?.notificationType) {
            navigate(URL_PROGRAMS_DETAILS, {
                state: {
                    programId: notification?.redirectionId
                }
            });
        }

        if (NOTIFICATION_TYPE_FOR_API.SUBSCRIPTION === notification?.notificationType) {
        }

        if (NOTIFICATION_TYPE_FOR_API.DAILY_WISDOM === notification?.notificationType) {
            dispatch(handleScrollToViewId('daily-wisdom'));
            navigate(URL_HOME_PAGE);
        }

        return;
    };

    const redirectionOfNotification = (notification) => {
        if (!remainingList?.length) {
            let ids = [];
            if (!notification?.notificationRead) ids.push(notification?.id);
            ids?.length && readNotificationList(ids, notificationStateType);
            redirectionByType(notification);
            return;
        }
        if (isOpen) {
            redirectionByType(notification);
            return;
        }
        setIsOpen((prev) => !prev);
        readNotification();
    };

    const readNotification = () => {
        let ids = [];
        if (!notification?.notificationRead) ids.push(notification?.id);

        let remainingIds = remainingList
            .filter((item) => !item?.notificationRead)
            .map((list) => list.id);

        if (remainingIds?.length) ids = ids?.concat(remainingIds || []);

        ids?.length && readNotificationList(ids, notificationStateType);
    };

    return (
        <>
            <NotificationItemWrapper
                key={props.key}
                $isSnackbarNotification={props.snackbarNotification}>
                <Collapse
                    orientation="vertical"
                    in={isOpen && remainingList?.length ? true : false}
                    collapsedSize={0}
                    classes={{
                        root: 'collapse-root'
                    }}>
                    <Box className="type-container f-v-center">
                        <Typography className="type">{notification?.notificationType}</Typography>

                        <Box className="actions flex">
                            <IconButton
                                className="open-div hover"
                                onClick={() => {
                                    updatedReadNotification(type);
                                    setIsOpen((prevState) => !prevState);
                                }}>
                                <Typography>LESS</Typography>
                                <UpArrow />
                            </IconButton>
                            <IconButton
                                className="open-div hover"
                                onClick={() => setClearNotificationType(type)}>
                                <Typography>CLEAR</Typography>
                                <ClearNotificationIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Collapse>

                <Box
                    className={`notification flex hover ${
                        !remainingList?.length && 'single-notification'
                    } ${!totalUnReadNotificationCount && 'no-unread-notification'}`}>
                    <NotificationCard
                        notification={notification}
                        redirectionOfNotification={redirectionOfNotification}
                        totalUnReadNotificationCount={totalUnReadNotificationCount}
                        image={image}
                        type={type}
                        initial
                        closeNotification={
                            <Box className="close">
                                {(isOpen || !remainingList?.length) &&
                                    (totalUnReadNotificationCount ? (
                                        <ClearNotificationIcon
                                            onClick={() =>
                                                setClearSingleNotification({
                                                    type,
                                                    id: notification?.id
                                                })
                                            }
                                        />
                                    ) : (
                                        <WhiteClearNotificationIcon
                                            onClick={() =>
                                                setClearSingleNotification({
                                                    type,
                                                    id: notification?.id
                                                })
                                            }
                                        />
                                    ))}
                            </Box>
                        }
                    />
                </Box>

                {!!remainingList?.length && (
                    <React.Fragment>
                        {!isOpen && <Box className="border-line-two" />}
                        {!isOpen && <Box className="border-line-three" />}
                    </React.Fragment>
                )}
            </NotificationItemWrapper>

            <Collapse orientation="vertical" in={isOpen} collapsedSize={0}>
                <Box sx={{ margin: '10px 0 20px' }}>
                    {!!remainingList?.length &&
                        remainingList.map((notificationChildListItem, i) => (
                            <NotificationItemWrapper key={i}>
                                <Box className="notification list-item flex hover">
                                    <NotificationCard
                                        notification={notificationChildListItem}
                                        image={notificationChildListItem?.image || image}
                                        type={type}
                                        redirectionOfNotification={redirectionOfNotification}
                                        closeNotification={
                                            <Box className="close">
                                                <WhiteClearNotificationIcon
                                                    onClick={() => {
                                                        setClearSingleNotification({
                                                            type,
                                                            id: notificationChildListItem?.id
                                                        });
                                                    }}
                                                />
                                            </Box>
                                        }
                                    />
                                </Box>
                            </NotificationItemWrapper>
                        ))}

                    {remainingList?.length + 1 !== totalRecords && (
                        <Box
                            className="load-more flex f-column f-v-center f-h-center hover"
                            onClick={() => {
                                loadMore(type);
                            }}>
                            <Typography className="load-more-text">Load More</Typography>
                            <Box className="load-more-arrow" />
                        </Box>
                    )}
                </Box>
            </Collapse>
        </>
    );
};

export default NotificationDetails;
