import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import { ReferralStoreWrapper } from './Store.style';
import { Typography } from '@mui/material';
import { SearchBar } from './SearchBar';
import { FilterTabs } from './Filter';
import { StoreProducts } from './Products';
import { useDispatch } from 'react-redux';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import { ReferralLayout } from '../Referral.style';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';

const ReferralStore = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState({ list: [], totalRecords: 0, totalCartItem: 0 });
    const API = useMemo(() => new Api(), []);
    const [isCategoryLoading, setCategoryLoading] = useState(false);
    const [isStoreLoading, setStoreLoading] = useState(false);
    const [payLoad, setPayload] = useState({
        current_page: 0,
        per_page: 12,
        search: ''
    });
    const [activeCategory, setActiveCategory] = useState(0);

    const [categories, setCategories] = useState([]);

    const fetchStoreProducts = useCallback(async () => {
        setStoreLoading(true);
        let response = await API.post(API_URL.REFERRAL_PRODUCT_LIST, {
            params: { categoryId: activeCategory },
            data: {
                ...payLoad
            }
        });

        if (response) {
            setData((prev) => ({
                ...prev,
                totalCartItem: response?.data?.data?.totalCartItem,
                totalRecords: response?.data?.data?.totalRecords,
                list: prev?.list.concat(response?.data?.data?.list)
            }));
            setStoreLoading(false);
        }
    }, [API, payLoad, activeCategory]);

    const fetchProductCategories = useCallback(async () => {
        setCategoryLoading(true);
        let response = await API.post(API_URL.REFERRAL_PRODUCT_CATEGORIES_LIST);
        setCategories(response?.data?.data?.list);
        setCategoryLoading(false);
    }, [API]);

    const loadMoreItems = () => {
        setPayload({ ...payLoad, current_page: payLoad.current_page + 1 });
    };

    const updateCategory = (category) => {
        if (activeCategory === category) return;
        setData({ list: [], totalRecords: 0, totalCartItem: 0 });
        setPayload((prev) => ({ ...prev, current_page: 0 }));
        setActiveCategory(category);
    };

    useEffect(() => {
        dispatch(hideSideBar());
        fetchStoreProducts();

        return () => dispatch(showSideBar());
    }, [dispatch, fetchProductCategories, fetchStoreProducts]);

    useEffect(() => {
        fetchProductCategories();
    }, [fetchProductCategories]);

    return (
        <React.Fragment>
            <TaoCalligraphyWatermark />
            <ReferralStoreWrapper>
                <ReferralLayout>
                    <Typography className="title">Store</Typography>
                    <SearchBar />
                    <FilterTabs
                        categories={categories}
                        updateCategory={updateCategory}
                        activeCategory={activeCategory}
                        isCategoryLoading={isCategoryLoading}
                    />
                    <StoreProducts
                        list={data?.list}
                        isStoreLoading={isStoreLoading}
                        loadMoreItems={loadMoreItems}
                        totalRecords={data?.totalRecords}
                        totalCartItem={data?.totalCartItem}
                    />
                </ReferralLayout>
            </ReferralStoreWrapper>
        </React.Fragment>
    );
};

export default ReferralStore;
