import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ProductDetailsWrapper } from './ProductDetails.style';
import { Box, Button, Typography } from '@mui/material';
import CartBottom from '../CartBottom/CartBottom';
import { useDispatch } from 'react-redux';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import { ReactComponent as ShoppingCartSimple } from 'Assets/images/Profile/shopping-cart-simple.svg';
import { ReactComponent as InShoppingCart } from 'Assets/images/Profile/in-shopping-cart.svg';
import { ReactComponent as Share } from 'Assets/images/Profile/share.svg';
import { getWindowDimensions } from 'Helpers';
import ShareSocial from 'Components/common/Share/Share';
import { ReferralLayout } from '../Referral.style';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import { useLocation } from 'react-router-dom';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import DOMPurify from 'dompurify';
import ContentLoader from 'react-content-loader';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';

const ImageLoading = (props) => {
    return (
        <ContentLoader
            speed={2}
            width="100%"
            height={250}
            backgroundColor="#dfdfdf"
            foregroundColor="#ecebeb"
            preserveAspectRatio="none"
            {...props}>
            <rect x="0" y="0" rx="18" ry="18" width="100%" height="250" />
        </ContentLoader>
    );
};

const TitleLoading = (props) => {
    return (
        <ContentLoader
            speed={2}
            width="100%"
            height={100}
            backgroundColor="#dfdfdf"
            foregroundColor="#ecebeb"
            preserveAspectRatio="none"
            {...props}>
            <rect x="0" y="0" rx="18" ry="18" width="100%" height="70" />
            <rect x="0" y="80" rx="6" ry="6" width="40%" height="20" />
        </ContentLoader>
    );
};

const PointsAndBtnLoading = (props) => {
    return (
        <ContentLoader
            speed={2}
            width="100%"
            height={160}
            backgroundColor="#dfdfdf"
            foregroundColor="#ecebeb"
            preserveAspectRatio="none"
            {...props}>
            <rect x="0" y="0" rx="6" ry="6" width="70%" height="20" />
            <rect x="0" y="30" rx="12" ry="12" width="100%" height="60" />
            <rect x="0" y="100" rx="12" ry="12" width="100%" height="60" />
        </ContentLoader>
    );
};

const DescriptionLoading = (props) => {
    return [...Array(15).keys()].map((item) => (
        <ContentLoader
            key={item}
            speed={2}
            width="100%"
            height={30}
            backgroundColor="#dfdfdf"
            foregroundColor="#ecebeb"
            preserveAspectRatio="none"
            {...props}>
            <rect x="0" y="0" rx="6" ry="6" width="100%" height="20" />
        </ContentLoader>
    ));
};

const ProductDetails = () => {
    const dispatch = useDispatch();
    const [heightOfTopContainer, setTopContainerHeight] = useState(0);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const location = useLocation();
    const API = useMemo(() => new Api(), []);
    const [data, setData] = useState({});
    const [isLoading, setLoading] = useState(false);

    const fetchProductDetails = useCallback(
        async (productId) => {
            setLoading(true);
            let response = await API.get(API_URL.STORE_PRODUCT, {
                params: { productId }
            });

            if (response) {
                setData(response?.data?.data);
                setLoading(false);
            }
        },
        [API]
    );

    const addProductIntoTheCart = async (productId) => {
        setData((prev) => ({
            ...prev,
            isAddedInCart: !prev?.isAddedInCart,
            totalCartItem: prev.totalCartItem + 1
        }));
        await API.post(API_URL.ADD_PRODUCT_INTO_CART, {
            params: {
                productId
            }
        });
    };

    useEffect(() => {
        dispatch(hideSideBar());

        return () => dispatch(showSideBar());
    }, [dispatch]);

    useEffect(() => {
        const getHeightOfTopContainer = document.getElementById('top-container');
        setTopContainerHeight(getHeightOfTopContainer?.offsetHeight);
    }, [windowDimensions?.width, windowDimensions?.height, isLoading]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (location?.state?.productId) fetchProductDetails(location?.state?.productId);
    }, [fetchProductDetails, location?.state?.productId]);

    return (
        <React.Fragment>
            <TaoCalligraphyWatermark />
            <ProductDetailsWrapper $heightOfTopContainer={heightOfTopContainer}>
                <ReferralLayout>
                    <Box className="product-details flex" id="top-container">
                        <Box className="section">
                            {isLoading ? (
                                <ImageLoading />
                            ) : (
                                <Box
                                    className="product-image"
                                    style={{ backgroundImage: `url(${data?.image})` }}>
                                    <Typography className="product-duration">
                                        {data?.availableDays}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <Box className="section right">
                            <Box className="product-info">
                                {isLoading ? (
                                    <TitleLoading />
                                ) : (
                                    <React.Fragment>
                                        <Typography className="product-title">
                                            {data?.name}
                                        </Typography>
                                        <Box className="inline-info flex f-v-center">
                                            <Typography className="navigate hover">
                                                Store
                                            </Typography>
                                            <Box className="arrow" />
                                            <Typography className="navigate hover">
                                                {data?.categoryName}
                                            </Typography>
                                        </Box>
                                    </React.Fragment>
                                )}
                                {
                                    windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET &&
                                    <Box className="mobile-desc">
                                        {isLoading ? (
                                            <DescriptionLoading />
                                        ) : (
                                            <Typography
                                                className="desc-text"
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(data?.description, {
                                                        FORBID_TAGS: ['p']
                                                    })
                                                }}
                                            />
                                        )}
                                    </Box>
                                }

                                {isLoading ? (
                                    <PointsAndBtnLoading />
                                ) : (
                                    <React.Fragment>
                                        <Box className="inline-info flex f-v-center">
                                            <Typography className="points">
                                                ${data?.price}
                                            </Typography>
                                            <Typography className="or">OR</Typography>
                                            <Typography className="points">
                                                {data?.priceInPoint} Pts
                                            </Typography>
                                        </Box>
                                        <Button
                                            className={`btn ${data?.isAddedInCart && 'present-in-cart'
                                                }`}
                                            disabled={data?.isAddedInCart}
                                            onClick={() => addProductIntoTheCart(data?.id)}
                                            startIcon={
                                                data?.isAddedInCart ? (
                                                    <InShoppingCart />
                                                ) : (
                                                    <ShoppingCartSimple />
                                                )
                                            }>
                                            {data?.isAddedInCart ? 'in your cart' : 'Add to cart'}
                                        </Button>
                                        <ShareSocial
                                            ButtonComponent={
                                                <Box className="btn share flex f-v-center f-h-center">
                                                    <Share />
                                                    Share
                                                </Box>
                                            }
                                            customButtonStyle={{ borderRadius: '0' }}
                                            dynamicDimension="100%"
                                            title={data?.name}
                                            url={data?.referralLink}
                                        />
                                    </React.Fragment>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box className="product-details desc">
                        {isLoading ? (
                            <DescriptionLoading />
                        ) : (
                            <Typography
                                className="desc-text"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(data?.description, {
                                        FORBID_TAGS: ['p']
                                    })
                                }}
                            />
                        )}
                    </Box>
                    <CartBottom totalCartItem={data?.totalCartItem} isLoading={isLoading} />
                </ReferralLayout>
            </ProductDetailsWrapper>
        </React.Fragment>
    );
};

export default ProductDetails;
