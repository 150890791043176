import React, { useEffect, useState } from 'react';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import CategorySeeAllWrapper from './CategorySeeAll.style';
import { Box, Chip, Container, Tooltip, Typography } from '@mui/material';
import SeeAllList from '../SeeAllList/SeeAllList';
import { useMemo } from 'react';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { MEDIA_TYPE, SLIDER_PER_PAGE } from 'Helpers/Constants';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleContentRedirection, uniqueWithTypeAndId } from 'Helpers';
import TaoScrollbar from 'Components/common/Scrollbar';
import { showSubscriptionModal } from 'Redux/App/Actions';
import { useDispatch } from 'react-redux';

const CHIPS_TYPE = [
    { id: 1, title: 'video', apiKey: MEDIA_TYPE.VIDEO },
    { id: 2, title: 'guided audio', apiKey: MEDIA_TYPE.GUIDED_AUDIO },
    { id: 3, title: 'music', apiKey: MEDIA_TYPE.MUSIC }
];

const CategorySeeAll = () => {
    const [activeTab, setActiveTab] = useState({});
    const [items, setItems] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 0,
        totalRecords: 0
    });
    const location = useLocation();
    const { subCategories, type, title, categoryId, selectedTopicId } = location?.state;

    const [selectedSubCategory, setSelectedSubCategory] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const API = useMemo(() => new Api(), []);
    const dispatch = useDispatch();

    const fetchItems = useCallback(
        async (tab, currentPage, subCategory) => {
            setLoading(true);
            let response = await API.post(API_URL.CATEGORY_SEE_ALL, {
                params: {
                    contentType: tab?.apiKey,
                    subCategoryId: subCategory?.id || categoryId
                },
                data: {
                    current_page: currentPage,
                    per_page: SLIDER_PER_PAGE,
                    search: ''
                }
            });
            setItems((prev) =>
                uniqueWithTypeAndId([...prev, ...response?.data?.data?.list], ['id', 'type'])
            );
            setPagination((prev) => ({
                ...prev,
                totalRecords: response?.data?.data?.totalRecords
            }));
            setLoading(false);
        },
        [API, categoryId]
    );

    const changeTab = (chip = {}) => {
        if (loading) return;
        setItems([]);
        setActiveTab(chip);
        setPagination({
            currentPage: 0,
            totalRecords: 0
        });
        fetchItems(chip, 0, selectedSubCategory);
    };

    const favoriteContent = async (value, index) => {
        let contents = [...items];
        contents[index].isFavorite = !contents[index].isFavorite;
        setItems(contents);

        await API.post(API_URL.CONTENT_FAVOURITES, {
            params: {
                contentId: value?.id
            }
        });
    };

    const navigateTo = (url, value, isAccessible) => {
        if (!url) return;

        if (!isAccessible) {
            dispatch(showSubscriptionModal());
            return;
        }

        navigate(url, {
            state: value
        });
    };
    const loadMoreItems = () => {
        setPagination((prev) => ({
            ...prev,
            currentPage: prev.currentPage + 1
        }));
        fetchItems(activeTab, pagination?.currentPage + 1, selectedSubCategory);
    };

    useEffect(() => {
        if (!type) return;
        let currentTab = CHIPS_TYPE.find((item) => item?.apiKey === type);
        setActiveTab(currentTab);
        setSelectedSubCategory(selectedTopicId ? { id: selectedTopicId } : subCategories[0]);
        fetchItems(currentTab, 0, selectedTopicId ? { id: selectedTopicId } : subCategories[0]);
    }, [type, fetchItems, subCategories, selectedTopicId]);

    return (
        <React.Fragment>
            <TaoCalligraphyWatermark />
            <CategorySeeAllWrapper>
                <Box className="container">
                    <Tooltip title={`Meditate & Manifest: ${title}`} placement="top">
                        <Typography className="title elipsis">
                            Meditate & Manifest: {title}
                        </Typography>
                    </Tooltip>
                    <Box className="chips">
                        {CHIPS_TYPE.map((chip) => (
                            <Chip
                                key={chip?.id}
                                onClick={() => changeTab(chip)}
                                label={chip?.title}
                                classes={{
                                    root: `chip-root hover ${
                                        chip?.id === activeTab?.id && 'active'
                                    }`
                                }}
                            />
                        ))}
                    </Box>

                    {!!subCategories?.length && (
                        <Box className="category-list">
                            <TaoScrollbar keyId="category-list" horizontalScroll>
                                {subCategories.map((category, index) => (
                                    <Box
                                        className="icon-container"
                                        onClick={() => {
                                            setItems([]);
                                            setPagination({
                                                currentPage: 0,
                                                totalRecords: 0
                                            });
                                            setSelectedSubCategory(category);
                                            fetchItems(activeTab, 0, category);
                                        }}
                                        key={category?.id}>
                                        <Container
                                            className="category-icon"
                                            style={{
                                                backgroundImage: `url(${
                                                    category?.id === selectedSubCategory?.id
                                                        ? category.selectedIcon
                                                        : category?.icon
                                                })`
                                            }}
                                        />
                                        <Container className="text-container" disableGutters>
                                            <Typography
                                                className={`category-name ${
                                                    category?.id === selectedSubCategory?.id &&
                                                    'selected'
                                                }`}>
                                                {category?.name}
                                            </Typography>
                                        </Container>
                                    </Box>
                                ))}
                            </TaoScrollbar>
                        </Box>
                    )}

                    <SeeAllList
                        items={items}
                        totalRecords={pagination?.totalRecords}
                        favoriteContent={favoriteContent}
                        handleContentRedirection={(value) =>
                            handleContentRedirection(value, navigateTo)
                        }
                        loadMoreItems={loadMoreItems}
                        isLoading={loading}
                        haveSubcategories={subCategories?.length}
                        placeholderMessage="No Meditation Found"
                    />
                </Box>
            </CategorySeeAllWrapper>
        </React.Fragment>
    );
};

export default CategorySeeAll;
