import React, { useState } from 'react';

import { ReactComponent as SendIcon } from "Assets/images/Chat/send-message-icon.svg";
import { ReactComponent as RecordIcon } from "Assets/images/Chat/mic-icon.svg";
import { ReactComponent as PlayIcon } from "Assets/images/Chat/audio-play-icon.svg";
import { ReactComponent as WaveformIcon } from "Assets/images/Chat/waveform-icon.svg";
import { ReactComponent as EditIcon } from 'Assets/images/Chat/edit-icon.svg';
import { ReactComponent as ArrowImage } from "Assets/images/Common/down-arrow.svg"

import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material'

import { Box, IconButton, Input, Tooltip, Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';

import { ChatWindowWrapper } from './Chat.style';
import { MessagePopover } from './Chat.style';

const ChatWindow = React.forwardRef(({ conversationInfo = { members: [], }, messages = [], openAddMember = () => { }, className = "", onClose = () => { }, ...props }, ref) => {

    const [selectedMessage, setSelectedMessage] = useState('');
    const [anchorEle, setAnchorEle] = useState(null);

    const showPopover = (e, id) => {
        setSelectedMessage(id + 1);
        setAnchorEle(e.currentTarget);
    }
    const closePopOver = () => {
        setSelectedMessage('');
        setAnchorEle(null);
    }
    return (
        <ChatWindowWrapper className={className} ref={ref}>

            <Box className="chat-members-container">
                <Box className="back-button-container">
                    <IconButton onClick={onClose} className="arrow-btn">
                        <ArrowImage />
                    </IconButton>
                </Box>
                <Box className="members-list">
                    {
                        conversationInfo.members?.map(m =>
                            <Box className="member-image" style={{ backgroundImage: `url("${m.image}")` }}>
                            </Box>
                        )
                    }
                </Box>
                <IconButton onClick={openAddMember} className="add-btn">
                    <AddIcon />
                </IconButton>
            </Box>
            <Box className="name-list-container">
                <Tooltip title={conversationInfo.members?.reduce((preVal, curVal, index) => conversationInfo.members.length > (index + 1) ? (preVal + curVal.firstName + ", ") : preVal + curVal.firstName, "")}>
                    <Typography className="names">
                        {
                            conversationInfo.members?.reduce((preVal, curVal, index) => conversationInfo.members.length > (index + 1) ? (preVal + curVal.firstName + ", ") : preVal + curVal.firstName, "")
                        }
                    </Typography>
                </Tooltip>
            </Box>
            <MessagePopover
                open={selectedMessage}
                anchorEl={anchorEle}
                onClose={closePopOver}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "center"
                }}
                PaperProps={{
                    classes: {
                        root: "popover-container"
                    }
                }}
            >
                {/* <Box className="popover-container"> */}
                <List>
                    <ListItem disablePadding>
                        <ListItemButton >
                            <ListItemIcon>
                                <EditIcon className="edit-icon" />
                            </ListItemIcon>
                            <ListItemText className="text">Edit</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton >
                            <ListItemIcon>
                                <CloseIcon />
                            </ListItemIcon>
                            <ListItemText className="text">Delete</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
                {/* </Box> */}
            </MessagePopover>
            <Box className="messages-container">
                {
                    messages.map(day =>
                        <>
                            <Box className="date-container">
                                <Box className="line"></Box>
                                <Box className="date">{day[0]}</Box>
                                <Box className="line"></Box>
                            </Box>
                            <Box className="messages">
                                {
                                    day[1]?.map((message, index, msgArray) =>
                                    (<Box className={`message ${message.type === "TO" ? "to" : "from"}`}>
                                        {((index === 0) || ((msgArray[index - 1].name !== message.name) && message.type !== "TO")) ? <Typography className="name">{message.name}</Typography> : null}
                                        {
                                            message.messageType === "TEXT" && <Box onClick={(e) => showPopover(e, index)} className="message-text-container message-container-shape">
                                                <Typography className="text">{message.message} <Typography className="message-time">{message.time.format("hh:mm a")}</Typography></Typography>
                                            </Box>
                                        }

                                        {
                                            message.messageType === "AUDIO" && <Box onClick={(e) => showPopover(e, index)} className="message-audio-container message-container-shape">
                                                <Box className="play-pause-icon-container">
                                                    <PlayIcon />
                                                </Box>
                                                <Box className="audio-container">
                                                    <WaveformIcon className='wave-icon' />
                                                    <Box className="progress-bar">
                                                        <Box style={{ width: `${25}%` }} className="progress"></Box>
                                                    </Box>
                                                    <Box className="timing-container">
                                                        <Typography className="player-time">00:15/1:00</Typography>
                                                        <Typography className="message-time">{message.time.format("hh:mm a")}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        }
                                    </Box>)
                                    )
                                }
                            </Box>

                        </>
                    )
                }
            </Box>
            <Box className="user-message-container">
                <Input endAdornment={<IconButton className="send-btn"><SendIcon className="send-icon" /></IconButton>} disableUnderline className="message-input" variant="filled" />
                <IconButton className="recorder-btn">
                    <RecordIcon />
                </IconButton>
            </Box>
        </ChatWindowWrapper>
    );
});

export default ChatWindow;