import React from 'react';

import { Box, Typography, IconButton, Button, Backdrop, Slide } from '@mui/material';
import moment from 'moment';

import { Close as CloseIcon } from '@mui/icons-material';
import { ReactComponent as XPIcon } from "Assets/images/Reward/golden-heart.svg"

import { SessionSignupModalWrapper } from './SessionSignupModal.style';

const SessionSignupModal = ({ open = true, name = "", startTime = moment(), endTime = moment().add(1, 'h'), onClose = () => { }, ...props }) => {
    return (

        <SessionSignupModalWrapper
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            onClose={onClose}
        >
            <Slide direction="up" in={open}>
                <Box className="dialog-container">
                    <Box className="top-bar-container">
                        <Box className="title-container">
                            <Typography className="name" >{name}</Typography>
                            <Typography className="time">{startTime.format("ddd DD MMM, HH:mm")} – {endTime.format("HH:mm")}</Typography>
                        </Box>
                        <IconButton onClick={onClose} className="close-btn" >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Button variant="text" className="option-container">
                        <Typography className="text">$50 CAD</Typography>
                    </Button>
                    <Button variant="text" className="option-container">
                        <Typography className="text">Unlock with 50 </Typography>
                        {<XPIcon />}
                    </Button>
                    <Box style={{ padding: "20px 0 0 0", border: 'none' }} className="option-container">
                        <Button className="free-btn">
                            FREE with (level) subscription
                        </Button>
                    </Box>
                </Box>
            </Slide>
        </SessionSignupModalWrapper>
    );
};


export default SessionSignupModal;