import React, { useState, useEffect, /* useRef */ } from 'react';
import { Typography, IconButton, Box, MenuItem } from '@mui/material';
// import Lottie from 'lottie-react';

import { BREAKPOINTS_VALUE } from 'Styles/Constants';

import { CustomChatDrawer, CustomChatContextMenu, CustomChatMessageInput } from './SessionChat.style';

// import SmileAnimation from 'Assets/AnimationFiles/smile-animation-loop.json';
// import LoveAnimation from 'Assets/AnimationFiles/love-animation-loop.json';
// import LikeAnimation from 'Assets/AnimationFiles/like-animation-loop.json';

import ChatProfileImage1 from 'Assets/images/Profile/default-user-icon.jpg';
import ChatProfileImage2 from 'Assets/images/Profile/default-user-icon.jpg';
import ChatProfileImage3 from 'Assets/images/Profile/default-user-icon.jpg';

import { CloseChatIconButton } from '../AudioSessionSVG';
import { ClearButtonIcon } from '../../AddEditSession/AddEditSessionSVG';

import { getWindowDimensions } from 'Helpers';

const ChatSection = (props) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // let wavesLottieRef = useRef();

    const [chatContextMenu, setChatContextMenu] = useState(null);
    const handleContextMenu = (event) => {
        event.preventDefault();
        setChatContextMenu(
            chatContextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6,
                  }
                : null
        );
    };

    const handleChatContextMenuClose = () => {
        setChatContextMenu(null);
    };

    const chatDetails = [
        {
            userProfileImage: ChatProfileImage1,
            userFirstName: 'Firstname',
            userLastName: 'Lastname',
            userChatText: 'Lorem ipsum dolor sit amet',
            chatTimeStamp: '12:36',
            isCurrentUsersMessage: false,
        },
        {
            userProfileImage: ChatProfileImage2,
            userFirstName: 'Firstname',
            userLastName: 'Lastname',
            userChatText: 'Lorem ipsum dolor sit amet',
            chatTimeStamp: '12:36',
            isCurrentUsersMessage: false,
        },
        {
            userProfileImage: ChatProfileImage3,
            userFirstName: 'Firstname',
            userLastName: 'Lastname',
            userChatText: 'Lorem ipsum dolor sit amet',
            chatTimeStamp: '12:36',
            isCurrentUsersMessage: false,
        },
        {
            userProfileImage: ChatProfileImage3,
            userFirstName: 'Firstname',
            userLastName: 'Lastname',
            userChatText: 'Lorem ipsum dolor sit amet',
            chatTimeStamp: '12:36',
            isCurrentUsersMessage: true,
        },
        {
            userProfileImage: ChatProfileImage2,
            userFirstName: 'Firstname',
            userLastName: 'Lastname',
            userChatText:
                'This message is going to be 200 characters long. We will see how the chat message sits in the chat window and harmonizes with other messages above and below. This last sentence should take us to 200.',
            chatTimeStamp: '12:36',
            isCurrentUsersMessage: false,
        },
        {
            userProfileImage: ChatProfileImage1,
            userFirstName: 'Firstname',
            userLastName: 'Lastname',
            userChatText: 'Lorem ipsum dolor sit amet',
            chatTimeStamp: '12:36',
            isCurrentUsersMessage: false,
        },
        {
            userProfileImage: ChatProfileImage1,
            userFirstName: 'Firstname',
            userLastName: 'Lastname',
            userChatText: 'Lorem ipsum dolor sit amet',
            chatTimeStamp: '12:36',
            isCurrentUsersMessage: false,
        },
        {
            userProfileImage: ChatProfileImage2,
            userFirstName: 'Firstname',
            userLastName: 'Lastname',
            userChatText:
                'This message is going to be 200 characters long. We will see how the chat message sits in the chat window and harmonizes with other messages above and below. This last sentence should take us to 200.',
            chatTimeStamp: '12:36',
            isCurrentUsersMessage: false,
        },
    ];

    return (
        <CustomChatDrawer
            anchor={windowDimensions.width <= BREAKPOINTS_VALUE.TABLET ? 'bottom' : 'right'}
            open={props.openChatModal}
            classes={{ root: 'session-chat-section' }}
            variant={'temporary'}
            hideBackdrop={true}
        >
            <Box className="session-chats">
                {chatDetails.map((chats) => {
                    return (
                        <>
                            <Box className="chat-section">
                                <Box className="chat-details">
                                    {!chats.isCurrentUsersMessage ? (
                                        <>
                                            <Box className="user-profile-image">
                                                <img src={chats.userProfileImage} alt="" />
                                            </Box>

                                            <Box className="username-message-details">
                                                <Typography className="user-name">
                                                    {chats.userFirstName} {chats.userLastName}
                                                </Typography>
                                                <Typography className="user-message">{chats.userChatText}</Typography>
                                            </Box>

                                            <Typography className="chat-time">{chats.chatTimeStamp}</Typography>
                                        </>
                                    ) : (
                                        <>
                                            <Box className="current-user-message" onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
                                                <Typography className="current-user-message-time">{chats.chatTimeStamp}</Typography>
                                                <Typography className="current-user-message-text">{chats.userChatText}</Typography>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </>
                    );
                })}

                <CustomChatContextMenu
                    open={chatContextMenu !== null}
                    onClose={handleChatContextMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={chatContextMenu !== null ? { top: chatContextMenu.mouseY, left: chatContextMenu.mouseX } : undefined}
                >
                    <MenuItem onClick={handleChatContextMenuClose}>
                        <ClearButtonIcon fill={'#BC1218'} />
                        <Typography>Remove</Typography>
                    </MenuItem>
                </CustomChatContextMenu>
            </Box>

            <Box className="chat-message-section">
                <IconButton onClick={() => props.closeModal(false)}>
                    <CloseChatIconButton />
                </IconButton>
                <CustomChatMessageInput placeholder="Type a message" id="chat-message-input" />
                {/* <Lottie loop autoplay animationData={LoveAnimation} /> */}
            </Box>
        </CustomChatDrawer>
    );
};

export default ChatSection;
