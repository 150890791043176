import React, { useState, useMemo, useEffect } from 'react';
import { Box, Typography, /*Button,*/ Chip } from '@mui/material';

// import MasterFranciscoProfileImage from 'Assets/images/BookAConsultation/master-francisco-profile-image.png';
import DefaultProfileImagePlaceholder from 'Assets/images/Profile/default-user-icon.jpg';
import { ProfileViewWrapper } from './ProfileView.style';
import { StarFilled, StarOutlined } from '../ProfileMain/ProfileSVG';

// import MapPinIcon from 'Assets/images/Profile/map-pin.png'; PHASE 2
import { numberFormatterWithoutSuffixValue } from 'Helpers';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { useCallback } from 'react';
import moment from 'moment';

//icon
import { useLocation } from 'react-router-dom';
import TaoScrollbar from 'Components/common/Scrollbar';

const TABs = {
    ABOUT: 'About',
    REVIEWS: 'Reviews'
};

const ProfileView = (props) => {
    const API = useMemo(() => new Api(), []);
    const [selectedTab, setSelectedTab] = useState(TABs.ABOUT);
    const [profileData, setProfileData] = useState({});
    const location = useLocation();

    const getMyProfileData = useCallback(async () => {
        const queryParams = new URLSearchParams(location?.search);
        const userId = queryParams && queryParams.get('userId');
        let params = {};
        if (userId) params.userId = userId;
        props.setIsLoading(true);
        const response = await API.get(API_URL.PROFILE_PUBLIC, { params });
        if (response) {
            setProfileData(response?.data?.data);
            props.setIsLoading(false);
        }
    }, [API, props, location?.search]);

    useEffect(() => {
        getMyProfileData();
    }, [getMyProfileData]);

    return (
        <React.Fragment>
            <ProfileViewWrapper>
                <TaoScrollbar customStyle={{ borderRadius: '30px 30px 0 0' }} displayScroll>
                    <Box className="user-profile-image" my={3}>
                        <Box
                            className="user-image"
                            style={{
                                backgroundImage: `url(${
                                    profileData?.profilePicture || DefaultProfileImagePlaceholder
                                })`
                            }}
                        />
                    </Box>

                    <Box className="display-mobile-format">
                        <Box className="flex">
                            <Typography className="user-name">{profileData?.username}</Typography>
                        </Box>

                        {/* PHASE 2 */}
                        {/* <Box className="user-reviews-and-add-friend">
                            {profileData?.country && (
                                <Box className="user-location">
                                    <img src={MapPinIcon} alt="" className="location-icon" />
                                    <Typography>{profileData?.country}</Typography>
                                </Box>
                            )}
                        </Box> */}

                        <Box className="profile-tabs">
                            <Chip
                                onClick={() => setSelectedTab(TABs.ABOUT)}
                                key={TABs.ABOUT}
                                classes={{ label: 'chip-label' }}
                                className={
                                    selectedTab === TABs.ABOUT ? 'selected-tab' : 'non-selected-tab'
                                }
                                size="medium"
                                color="primary"
                                variant={selectedTab === TABs.ABOUT ? 'filled' : 'outlined'}
                                label={TABs.ABOUT}
                            />
                            {/* PHASE 2 */}
                            {/* <Chip
                                onClick={() => setSelectedTab(TABs.REVIEWS)}
                                key="reviews"
                                className={
                                    selectedTab === TABs.REVIEWS
                                        ? 'selected-tab'
                                        : 'non-selected-tab'
                                }
                                size="medium"
                                color="primary"
                                variant={selectedTab === TABs.REVIEWS ? 'filled' : 'outlined'}
                                label="Reviews"
                            /> */}
                        </Box>

                        {selectedTab === TABs.ABOUT && (
                            <>
                                {profileData?.isViewAboutMe && (
                                    <Typography className="user-bio">
                                        {profileData?.aboutMe}
                                    </Typography>
                                )}

                                <Box className="flex ">
                                    {profileData?.userLanguages
                                        ? profileData?.userLanguages.length !== 0 && (
                                              <Box className="user-language-section">
                                                  <Typography className="language-title">
                                                      language
                                                  </Typography>
                                                  {profileData?.userLanguages.map((values) => (
                                                      <Typography
                                                          className="user-known-languages"
                                                          key={values.languageId}>
                                                          {values.language}
                                                      </Typography>
                                                  ))}
                                              </Box>
                                          )
                                        : null}
                                    {profileData?.isViewBirthday && profileData?.dateOfBirth && (
                                        <Box className="date-of-birth-section">
                                            <Typography className="date-of-birth-title">
                                                birthday
                                            </Typography>
                                            <Typography className="user-dob">
                                                {moment(
                                                    profileData?.dateOfBirth,
                                                    'YYYY-MM-DD'
                                                ).format('MMM DD')}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>

                                {/* {PHASE 2} */}
                                {/* <Box className="user-live-session-section">
                                    <Typography className="live-session-by-title">
                                        LIVE SESSIONS BY DARRELL
                                    </Typography>
                                    <Typography className="live-session-name">
                                        {`Moving Meditation for Teens ›`}
                                    </Typography>
                                    <Typography className="live-session-name">
                                        {`Creative Visualization to Beat Anxiety ›`}
                                    </Typography>
                                </Box> */}

                                {profileData?.isViewInterest && profileData?.interest !== null && (
                                    <Box className="user-interest-section">
                                        <Typography className="interest-title">
                                            Interests
                                        </Typography>

                                        <Box className="interest-list">
                                            {profileData?.interest &&
                                                profileData?.interest.map((interestValue) => {
                                                    return (
                                                        <Chip
                                                            key={interestValue.id}
                                                            className="user-interest-list"
                                                            classes={{ label: 'interest-label' }}
                                                            size="medium"
                                                            color="primary"
                                                            variant="filled"
                                                            label={interestValue.name}
                                                        />
                                                    );
                                                })}
                                        </Box>
                                    </Box>
                                )}
                            </>
                        )}

                        {selectedTab === TABs.REVIEWS && (
                            <>
                                <Box className="user-reviews-section">
                                    <Box className="review-details">
                                        <Typography className="session-name">
                                            MOVING MEDITATION FOR TEENS
                                        </Typography>

                                        <Box className="session-rating">
                                            <StarFilled width="15" height="15" />
                                            <StarFilled width="15" height="15" />
                                            <StarFilled width="15" height="15" />
                                            <StarFilled width="15" height="15" />
                                            <StarOutlined width="15" height="15" />

                                            <Typography className="session-rating-value">
                                                15 Sessions
                                            </Typography>
                                        </Box>

                                        <Typography className="participant-felt-title">
                                            Participants Felt:
                                        </Typography>

                                        <Box className="participant-felt-feedback">
                                            <Box className="spec-item">
                                                <Typography variant="h5">Clear</Typography>
                                                <Box
                                                    style={{
                                                        width:
                                                            (
                                                                (6000 / 6000) *
                                                                100 *
                                                                0.45
                                                            ).toString() + '%'
                                                    }}
                                                    className="bar"></Box>
                                                <Typography variant="h6">
                                                    {numberFormatterWithoutSuffixValue(6000, 0)}
                                                </Typography>
                                            </Box>
                                            <Box className="spec-item">
                                                <Typography variant="h5">Energized</Typography>
                                                <Box
                                                    style={{
                                                        width:
                                                            (
                                                                (3000 / 6000) *
                                                                100 *
                                                                0.45
                                                            ).toString() + '%'
                                                    }}
                                                    className="bar"></Box>
                                                <Typography variant="h6">
                                                    {numberFormatterWithoutSuffixValue(3000, 0)}
                                                </Typography>
                                            </Box>
                                            <Box className="spec-item">
                                                <Typography variant="h5">Relaxed</Typography>
                                                <Box
                                                    style={{
                                                        width:
                                                            (
                                                                (3000 / 6000) *
                                                                100 *
                                                                0.45
                                                            ).toString() + '%'
                                                    }}
                                                    className="bar"></Box>
                                                <Typography variant="h6">
                                                    {numberFormatterWithoutSuffixValue(3000, 0)}
                                                </Typography>
                                            </Box>
                                            <Box className="spec-item">
                                                <Typography variant="h5">Peaceful</Typography>
                                                <Box
                                                    style={{
                                                        width:
                                                            (
                                                                (1000 / 6000) *
                                                                100 *
                                                                0.45
                                                            ).toString() + '%'
                                                    }}
                                                    className="bar"></Box>
                                                <Typography variant="h6">
                                                    {numberFormatterWithoutSuffixValue(1000, 0)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className="review-details">
                                        <Typography className="session-name">
                                            CREATIVE VISUALIZATION TO BEAT ANXIETY
                                        </Typography>

                                        <Box className="session-rating">
                                            <StarFilled width="15" height="15" />
                                            <StarFilled width="15" height="15" />
                                            <StarFilled width="15" height="15" />
                                            <StarFilled width="15" height="15" />
                                            <StarOutlined width="15" height="15" />

                                            <Typography className="session-rating-value">
                                                15 Sessions
                                            </Typography>
                                        </Box>

                                        <Typography className="participant-felt-title">
                                            Participants Felt:
                                        </Typography>

                                        <Box className="participant-felt-feedback">
                                            <Box className="spec-item">
                                                <Typography variant="h5">Clear</Typography>
                                                <Box
                                                    style={{
                                                        width:
                                                            (
                                                                (6000 / 6000) *
                                                                100 *
                                                                0.45
                                                            ).toString() + '%'
                                                    }}
                                                    className="bar"></Box>
                                                <Typography variant="h6">
                                                    {numberFormatterWithoutSuffixValue(6000, 0)}
                                                </Typography>
                                            </Box>
                                            <Box className="spec-item">
                                                <Typography variant="h5">Energized</Typography>
                                                <Box
                                                    style={{
                                                        width:
                                                            (
                                                                (3000 / 6000) *
                                                                100 *
                                                                0.45
                                                            ).toString() + '%'
                                                    }}
                                                    className="bar"></Box>
                                                <Typography variant="h6">
                                                    {numberFormatterWithoutSuffixValue(3000, 0)}
                                                </Typography>
                                            </Box>
                                            <Box className="spec-item">
                                                <Typography variant="h5">Relaxed</Typography>
                                                <Box
                                                    style={{
                                                        width:
                                                            (
                                                                (3000 / 6000) *
                                                                100 *
                                                                0.45
                                                            ).toString() + '%'
                                                    }}
                                                    className="bar"></Box>
                                                <Typography variant="h6">
                                                    {numberFormatterWithoutSuffixValue(3000, 0)}
                                                </Typography>
                                            </Box>
                                            <Box className="spec-item">
                                                <Typography variant="h5">Peaceful</Typography>
                                                <Box
                                                    style={{
                                                        width:
                                                            (
                                                                (1000 / 6000) *
                                                                100 *
                                                                0.45
                                                            ).toString() + '%'
                                                    }}
                                                    className="bar"></Box>
                                                <Typography variant="h6">
                                                    {numberFormatterWithoutSuffixValue(1000, 0)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                </TaoScrollbar>
            </ProfileViewWrapper>
        </React.Fragment>
    );
};

export default ProfileView;
