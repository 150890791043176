import React, { useRef } from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { PlaylistItemsWrapper } from './Playlist.style';
import { ReactComponent as PlayIcon } from 'Assets/images/PlayList/play-icon.svg';
import { ReactComponent as EditIcon } from 'Assets/images/MeditationTimer/edit.svg';
import { ReactComponent as DeleteIcon } from 'Assets/images/MeditationTimer/delete-icon.svg';
import { URL_PLAY_MEDITATION } from 'Helpers/Paths';
import { useNavigate } from 'react-router-dom';
import { convertHoursMinsSecondsToReadableString } from 'Helpers';
import TaoSlider from 'Components/common/TaoSlider';
import { ACCESS_LEVELS_MODULEs, MEDIA_TYPE, PLAYLIST_MESSAGES } from 'Helpers/Constants';
import TaoCard from 'Components/common/TaoCard';
import { showSubscriptionModal, showToast } from 'Redux/App/Actions';
import { useDispatch } from 'react-redux';
import { contentSubscribedAndAccessible } from './PlaylistCommon';
import usePermissions from 'Components/common/Permissions';
import { SubscriptionDarkLock } from 'Components/common/SubscriptionLock';

const PlaylistItems = ({
    list,
    playListId,
    playlistName,
    deleteHandler,
    setsIsLoading,
    isLoading,
    setResetPlaylist,
    playMeditationHandler,
    setShowPlaylistModal,
    ...props
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const playlistPermissions = usePermissions(ACCESS_LEVELS_MODULEs?.CREATE_PLAYLIST);
    const playListItemsSlide = useRef(null);

    const navigateTo = (url, value, content) => {
        if (!url) return;

        if (contentSubscribedAndAccessible(content)) {
            return navigate(url, {
                state: {
                    playlistName: playlistName,
                    playListId: value?.playListId,
                    playContentId: value?.contentId
                }
            });
        }
        dispatch(showToast(PLAYLIST_MESSAGES?.CONTENT_NOT_PURCHASED, 'warning'));
    };

    return (
        <>
            <PlaylistItemsWrapper>
                <Box className="full-width-section">
                    <Box className="regulation-page-content">
                        <Box className="control-section">
                            <Card className="control-card">
                                <CardContent className="card-content-heading">
                                    <Typography className="text-heading">{playlistName}</Typography>
                                    <Box className="button-container">
                                        <Button
                                            variant="contained"
                                            className="button"
                                            onClick={() => playMeditationHandler(playListId)}>
                                            {<PlayIcon />}
                                            <Typography className="text">PLAY ALL</Typography>
                                        </Button>
                                    </Box>
                                    <Box className="action-button-container">
                                        <Button
                                            className="action-button"
                                            onClick={() =>
                                                playlistPermissions?.canAccess
                                                    ? setShowPlaylistModal((prev) => ({
                                                          ...prev,
                                                          visible: true,
                                                          playListName: playlistName,
                                                          list,
                                                          playlistId: playListId,
                                                          isEdit: true
                                                      }))
                                                    : dispatch(showSubscriptionModal())
                                            }
                                            endIcon={
                                                !playlistPermissions?.canAccess && (
                                                    <SubscriptionDarkLock />
                                                )
                                            }
                                            startIcon={<EditIcon />}>
                                            <Typography className="text">Edit</Typography>
                                        </Button>
                                        <Button
                                            className="action-button"
                                            onClick={() => deleteHandler(playListId)}
                                            startIcon={<DeleteIcon />}>
                                            <Typography className="text">Delete</Typography>
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box className="slider-content">
                            <TaoSlider
                                ref={playListItemsSlide}
                                showArrows={false}
                                cardHeight={90}
                                gap={10}
                                slidesToShowLaptop={1}
                                slidesToShow={2}>
                                {list.map((value, index) => (
                                    <TaoCard
                                        key={index}
                                        type={MEDIA_TYPE.VIDEO}
                                        backgroundImage={value?.thumbnailImage}
                                        title={value?.contentName}
                                        isTextColorDark={true}
                                        textFromStart={true}
                                        badge={value?.subscription?.badge}
                                        isAccessible={
                                            value?.subscription
                                                ? value?.subscription?.isAccessible
                                                : true
                                        }
                                        isPaidContent={value?.isPaidContent}
                                        isPurchased={value?.isPurchased}
                                        onClick={() => {
                                            navigateTo(
                                                URL_PLAY_MEDITATION,
                                                {
                                                    contentId: value?.id,
                                                    playListId: playListId
                                                },
                                                value
                                            );
                                        }}>
                                        {!!value?.categoryDetailsList?.length && (
                                            <Typography
                                                style={{ gap: '5px' }}
                                                className="media-time flex elipsis">
                                                {value?.categoryDetailsList[0]?.mainCategory}
                                                {value?.categoryDetailsList[0] &&
                                                    value?.categoryDetailsList[0]
                                                        .subCategory[0] && (
                                                        <>
                                                            {'> ' +
                                                                value?.categoryDetailsList[0]
                                                                    .subCategory[0]}
                                                        </>
                                                    )}
                                            </Typography>
                                        )}
                                        <Typography className="media-time">
                                            {convertHoursMinsSecondsToReadableString(
                                                value.contentDuration
                                            )}
                                        </Typography>
                                    </TaoCard>
                                ))}
                            </TaoSlider>
                        </Box>
                    </Box>
                </Box>
            </PlaylistItemsWrapper>
        </>
    );
};

export default PlaylistItems;
