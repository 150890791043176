import React, { useState, useRef, useEffect } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import defaultLocal from 'date-fns/locale/en-US';
import moment from 'moment';
import { findIndex } from 'lodash';

import {
    Typography,
    IconButton,
    Box,
    ToggleButton,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Chip,
    FormGroup,
    FormControlLabel,
    Button,
    Checkbox,
    Modal,
    Backdrop,
    Slide
} from '@mui/material';

import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import Footer from 'Components/common/Footer/Footer';

import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { HOURS, MINUTES, TOTAL_MONTH_DAYS } from 'Helpers/Constants';
import {
    MeditationRoomCreateSessionWrapper,
    MainFormContentWrapper,
    SessionTopicSectionWrapper,
    SessionBannerWrapper,
    BasicInfoSessionWrapper,
    SessionDateTimeSectionWrapper,
    SessionRepeatSectionWrapper,
    SessionAdvanceSectionWrapper,
    CustomSwitch,
    CancelSessionModalWrapper
} from './AddEditSession.style';
import {
    ProductivityTopicIcon,
    CreativityTopicIcon,
    AthleticsTopicIcon,
    ClearButtonIcon,
    CancelSessionIcon
} from './AddEditSessionSVG';

import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';

import { BackButton } from 'Helpers/CommonSVGs';

import SessionImage1 from 'Assets/images/MeditationRoomSession/session-image-1.png';
import SessionImage2 from 'Assets/images/MeditationRoomSession/session-image-2.png';
import SessionImage3 from 'Assets/images/MeditationRoomSession/session-image-3.png';
import ThomasImage from 'Assets/images/Profile/user-without-bg.svg';
import { ReactComponent as DownArrow } from 'Assets/images/Common/down-arrow.svg';

import { useDispatch } from 'react-redux';
import { showTopBar, hideTopBar, showFooter, hideFooter } from 'Redux/App/Actions';
import { showSideBar, hideSideBar } from 'Redux/Sidebar/Actions';
import { getWindowDimensions } from 'Helpers';

const theme = createTheme({
    components: {
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    width: '445px',
                    backgroundColor: 'white',
                    borderRadius: 20,
                    height: '490px'
                },
                viewTransitionContainer: {
                    width: '445px',
                    height: '439px',
                    '.PrivatePickersSlideTransition-root': {
                        height: '399px'
                    },
                    '@media only screen and (max-width: 1050px)': {
                        width: '420px'
                    },
                    '@media only screen and (max-width: 870px)': {
                        width: '100%'
                    }
                }
            }
        }
    }
});

const MeditationRoomCreateSession = (props) => {
    let SLIDER_SETTINGS = {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        infinite: false,
        swipeToSlide: true,
        variableWidth: true,
        accessibility: true,
        focusOnSelect: false,
        draggable: true,
        responsive: [
            {
                breakpoint: BREAKPOINTS_VALUE.DESKTOP,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: BREAKPOINTS_VALUE.LAPTOP,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: BREAKPOINTS_VALUE.TABLET,
                settings: {
                    slidesToShow: 2,
                    arrows: false
                }
            },
            {
                breakpoint: BREAKPOINTS_VALUE.PHABLET,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                }
            }
        ]
    };

    const todayDate = useRef(moment());
    const [topic, setTopic] = useState('productivity');
    const [banner, setBanner] = useState('sessionImage1');
    const [currentMonthView, setCurrentMonthView] = useState(moment().startOf('month'));
    const [currentSelectedDate, setCurrentSelectedDate] = useState(moment());
    const [sessionRepeatFrequency, setSessionRepeatFrequency] = useState('one-time');
    const [selectedWeekDays, setSelectedWeekDays] = useState(['Sat', 'Mon']);
    const [coHostList /* setCoHostListDetails */] = useState([
        { hostImage: ThomasImage, hostName: 'Thomas Leimert' },
        { hostImage: ThomasImage, hostName: 'Thomas Leimert' }
    ]);

    const monthChangeHandler = (month) => {
        setCurrentMonthView(moment(month));
    };

    const onWeekDayChange = (weekDay) => {
        const copySelectedWeekDay = [...selectedWeekDays];
        if (copySelectedWeekDay.includes(weekDay)) {
            const findCurrentWeekDayIndex = findIndex(copySelectedWeekDay, (value) => {
                return value === weekDay;
            });
            if (findCurrentWeekDayIndex !== -1) {
                copySelectedWeekDay.splice(findCurrentWeekDayIndex, 1);
            }
        } else {
            copySelectedWeekDay.push(weekDay);
        }

        setSelectedWeekDays(copySelectedWeekDay);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const dispatch = useDispatch();

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);

            dispatch(showSideBar());
            dispatch(showTopBar());
            dispatch(showFooter());
        };
    }, [dispatch]);

    useEffect(() => {
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            dispatch(hideSideBar());
            dispatch(hideTopBar());
            dispatch(hideFooter());
        }
    }, [dispatch, windowDimensions]);

    return (
        <>
            <TaoCalligraphyWatermark />
            <MeditationRoomCreateSessionWrapper>
                <Box className="session-add-edit-title">
                    {windowDimensions.width <= BREAKPOINTS_VALUE.TABLET && <BackButton />}

                    <Typography className="title-text">
                        {props.editMeditationRoomSession ? 'Edit Session' : 'New Session'}
                    </Typography>
                </Box>

                <MainFormContentWrapper>
                    <SessionTopicSectionWrapper>
                        <Box className="topic-title">
                            <Typography className="topic-text">Topic</Typography>
                            <Typography className="choose-one-text">Choose one</Typography>
                        </Box>

                        <Box className="topics">
                            <Box className="topic-value">
                                <ToggleButton
                                    className={`topic-icon ${
                                        topic === 'productivity' ? 'selected-topic' : ''
                                    }`}
                                    value="productivity"
                                    onClick={() => setTopic('productivity')}>
                                    <ProductivityTopicIcon isSelected={topic === 'productivity'} />
                                </ToggleButton>
                                <Typography className="topic-toggle-text">Productivity</Typography>
                            </Box>
                            <Box className="topic-value">
                                <ToggleButton
                                    className={`topic-icon ${
                                        topic === 'creativity' ? 'selected-topic' : ''
                                    }`}
                                    value="creativity"
                                    onClick={() => setTopic('creativity')}>
                                    <CreativityTopicIcon isSelected={topic === 'creativity'} />
                                </ToggleButton>
                                <Typography className="topic-toggle-text">Creativity</Typography>
                            </Box>
                            <Box className="topic-value">
                                <ToggleButton
                                    className={`topic-icon ${
                                        topic === 'athletics' ? 'selected-topic' : ''
                                    }`}
                                    value="athletics"
                                    onClick={() => setTopic('athletics')}>
                                    <AthleticsTopicIcon isSelected={topic === 'athletics'} />
                                </ToggleButton>
                                <Typography className="topic-toggle-text">Athletics</Typography>
                            </Box>
                        </Box>
                    </SessionTopicSectionWrapper>

                    <SessionBannerWrapper>
                        <Typography className="banner-header">Banner</Typography>
                        <Box className="banner-medias">
                            <Slider {...SLIDER_SETTINGS}>
                                <img
                                    src={SessionImage1}
                                    className={
                                        banner === 'sessionImage1' ? 'selected-banner-image' : ''
                                    }
                                    onClick={() => setBanner('sessionImage1')}
                                    alt=""
                                />
                                <img
                                    src={SessionImage2}
                                    className={
                                        banner === 'sessionImage2' ? 'selected-banner-image' : ''
                                    }
                                    onClick={() => setBanner('sessionImage2')}
                                    alt=""
                                />
                                <img
                                    src={SessionImage3}
                                    className={
                                        banner === 'sessionImage3' ? 'selected-banner-image' : ''
                                    }
                                    onClick={() => setBanner('sessionImage3')}
                                    alt=""
                                />
                                <img
                                    src={SessionImage3}
                                    className={
                                        banner === 'sessionImage4' ? 'selected-banner-image' : ''
                                    }
                                    onClick={() => setBanner('sessionImage4')}
                                    alt=""
                                />
                            </Slider>
                        </Box>
                    </SessionBannerWrapper>

                    <BasicInfoSessionWrapper>
                        <Typography className="basic-info-header">Basic Info</Typography>
                        <Box className="basic-info-details">
                            <TextField
                                id="outlined-basic"
                                label="Session Name"
                                variant="outlined"
                            />
                            <TextField
                                id="outlined-basic"
                                label="Description"
                                variant="outlined"
                                minRows={3}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="access-label">Access</InputLabel>
                                <Select
                                    labelId="access-label"
                                    id="access"
                                    label="Access"
                                    IconComponent={(props) => (
                                        <DownArrow className="dropdown-arrow" />
                                    )}>
                                    <MenuItem value={'public'}>Public</MenuItem>
                                    <MenuItem value={'private'}>Private</MenuItem>
                                </Select>
                            </FormControl>
                            <Box className="language-option">
                                <FormControl sx={{ flex: 50 }} fullWidth>
                                    <InputLabel id="main-language-label">Main Language</InputLabel>
                                    <Select
                                        labelId="main-language-label"
                                        id="main-language"
                                        label="Main Language"
                                        IconComponent={(props) => (
                                            <DownArrow className="dropdown-arrow" />
                                        )}>
                                        <MenuItem value={'english'}>English</MenuItem>
                                        <MenuItem value={'spanish'}>Spanish</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ flex: 50 }} fullWidth>
                                    <InputLabel id="translation-select-label">
                                        Translation
                                    </InputLabel>
                                    <Select
                                        labelId="translation-select-label"
                                        id="translation-select"
                                        label="Translation"
                                        IconComponent={(props) => (
                                            <DownArrow className="dropdown-arrow" />
                                        )}>
                                        <MenuItem value={'deutsch'}>Deutsch</MenuItem>
                                        <MenuItem value={'german'}>German</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </BasicInfoSessionWrapper>

                    <SessionDateTimeSectionWrapper
                        currentWeekDay={
                            moment(currentMonthView).startOf('months').format('MM/DD/YYYY') ===
                            moment(todayDate.current).startOf('months').format('MM/DD/YYYY')
                                ? todayDate.current.day()
                                : -1
                        }>
                        <Typography className="date-time-header">{'Date & Time'}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={defaultLocal}>
                            <ThemeProvider theme={theme}>
                                <CalendarPicker
                                    className="static-calendar-root"
                                    onChange={(val) => {
                                        setCurrentSelectedDate(moment(val));
                                    }}
                                    onMonthChange={monthChangeHandler}
                                    renderDay={(day, _value, DayComponentProps) => {
                                        return (
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="center"
                                                mb={1}>
                                                <PickersDay
                                                    {...DayComponentProps}
                                                    disableMargin={false}
                                                    selected={
                                                        moment(currentSelectedDate).isSame(
                                                            moment(day)
                                                        )
                                                            ? true
                                                            : false
                                                    }
                                                    classes={{
                                                        root: 'calendar-day-root',
                                                        today: 'today-date'
                                                    }}
                                                    showDaysOutsideCurrentMonth={false}
                                                />
                                                {/* {!DayComponentProps.outsideCurrentMonth && <Box className={`dot ${DayComponentProps.today && 'primary'} ${DARK_GRAY.includes(moment(day).format("YYYY-MM-DD")) && 'dark-gray'} ${LIGHT_GRAY.includes(moment(day).format("YYYY-MM-DD")) && 'light'} `}></Box>} */}
                                            </Box>
                                        );
                                    }}></CalendarPicker>
                            </ThemeProvider>
                        </LocalizationProvider>

                        <Box className="time-section">
                            <FormControl sx={{ flex: 33 }} fullWidth>
                                <InputLabel id="hour-label">Hour</InputLabel>
                                <Select
                                    labelId="hour-label"
                                    id="hour"
                                    label="Hour"
                                    IconComponent={(props) => (
                                        <DownArrow className="dropdown-arrow" />
                                    )}>
                                    {HOURS.map((hourValue) => {
                                        return (
                                            <MenuItem value={`${hourValue}`}>{hourValue}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ flex: 33 }} fullWidth>
                                <InputLabel id="minutes-label">Minutes</InputLabel>
                                <Select
                                    labelId="minutes-label"
                                    id="minutes"
                                    label="Minutes"
                                    IconComponent={(props) => (
                                        <DownArrow className="dropdown-arrow" />
                                    )}>
                                    {MINUTES.map((minuteValue) => {
                                        return (
                                            <MenuItem value={`${minuteValue}`}>
                                                {minuteValue}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ flex: 33 }} fullWidth>
                                <InputLabel id="AM-PM-label">AM/PM</InputLabel>
                                <Select
                                    labelId="AM-PM-label"
                                    id="am-pm"
                                    label="AM/PM"
                                    IconComponent={(props) => (
                                        <DownArrow className="dropdown-arrow" />
                                    )}>
                                    <MenuItem value={'am'}>AM</MenuItem>
                                    <MenuItem value={'pm'}>PM</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </SessionDateTimeSectionWrapper>

                    <SessionRepeatSectionWrapper>
                        <Typography className="repeat-header" sx={{ marginTop: '5px' }}>
                            Repeat
                        </Typography>
                        <Box className="repeat-section">
                            <Box>
                                <Chip
                                    className={
                                        'repeat-chip large ' +
                                        (sessionRepeatFrequency === 'one-time'
                                            ? 'selected-chip'
                                            : 'not-selected-chip')
                                    }
                                    onClick={() => setSessionRepeatFrequency('one-time')}
                                    key={1}
                                    size="medium"
                                    color="primary"
                                    variant={
                                        sessionRepeatFrequency === 'one-time'
                                            ? 'filled'
                                            : 'outlined'
                                    }
                                    label="one-time"
                                />
                            </Box>
                            <Box className="weekdays-view">
                                <Chip
                                    className={
                                        'repeat-chip large ' +
                                        (sessionRepeatFrequency === 'weekly'
                                            ? 'selected-chip'
                                            : 'not-selected-chip')
                                    }
                                    onClick={() => setSessionRepeatFrequency('weekly')}
                                    key={2}
                                    size="medium"
                                    color="primary"
                                    variant={
                                        sessionRepeatFrequency === 'weekly' ? 'filled' : 'outlined'
                                    }
                                    label="weekly"
                                />

                                <Box>
                                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(
                                        (weekDay) => {
                                            return (
                                                <Chip
                                                    className={
                                                        'repeat-chip large ' +
                                                        (selectedWeekDays.includes(weekDay)
                                                            ? 'selected-chip'
                                                            : 'not-selected-chip')
                                                    }
                                                    onClick={() => onWeekDayChange(weekDay)}
                                                    key={weekDay}
                                                    size="small"
                                                    color="primary"
                                                    disabled={
                                                        sessionRepeatFrequency !== 'weekly'
                                                            ? true
                                                            : false
                                                    }
                                                    variant={
                                                        selectedWeekDays.includes(weekDay)
                                                            ? 'filled'
                                                            : 'outlined'
                                                    }
                                                    label={weekDay}
                                                />
                                            );
                                        }
                                    )}
                                </Box>
                            </Box>
                            <Box className="month-repeat-view">
                                <Chip
                                    className={
                                        'repeat-chip large ' +
                                        (sessionRepeatFrequency === 'monthly'
                                            ? 'selected-chip'
                                            : 'not-selected-chip')
                                    }
                                    onClick={() => setSessionRepeatFrequency('monthly')}
                                    key={3}
                                    size="medium"
                                    color="primary"
                                    variant={
                                        sessionRepeatFrequency === 'monthly' ? 'filled' : 'outlined'
                                    }
                                    label="monthly"
                                />

                                <Box>
                                    <Typography>on day</Typography>
                                    <FormControl>
                                        <Select
                                            disabled={
                                                sessionRepeatFrequency !== 'monthly' ? true : false
                                            }
                                            value={moment().format('DD')}
                                            IconComponent={(props) => (
                                                <DownArrow className="dropdown-arrow" />
                                            )}>
                                            {TOTAL_MONTH_DAYS.map((monthDays) => {
                                                return (
                                                    <MenuItem value={`${monthDays}`}>
                                                        {monthDays}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                    </SessionRepeatSectionWrapper>

                    <SessionAdvanceSectionWrapper>
                        <Typography className="advanced-header">Advanced</Typography>

                        <Box className="advance-section-content">
                            <Box className="interactive-session-text-block">
                                <Typography>Interactive Session</Typography>
                                <Typography className="inteactive-session-subtext">
                                    {'(participants can unmute)'}
                                </Typography>
                            </Box>
                            <Box>
                                <FormGroup>
                                    <FormControlLabel control={<CustomSwitch />} />
                                </FormGroup>
                            </Box>
                        </Box>

                        <Box className="advance-section-content">
                            <Typography>Chat</Typography>
                            <FormGroup>
                                <FormControlLabel control={<CustomSwitch defaultChecked />} />
                            </FormGroup>
                        </Box>

                        <Box className="advance-section-content">
                            <Typography>Max. Participants</Typography>

                            <FormControl className="limit-option">
                                <Select
                                    id="limit"
                                    value={'no limit'}
                                    IconComponent={(props) => (
                                        <DownArrow className="dropdown-arrow" />
                                    )}>
                                    <MenuItem value={`no limit`}>No Limit</MenuItem>
                                    <MenuItem value={`10`}>100</MenuItem>
                                    <MenuItem value={`50`}>50</MenuItem>
                                    <MenuItem value={`20`}>20</MenuItem>
                                    <MenuItem value={`10`}>10</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        <Box className="host-section-content">
                            <Typography>CO-HOSTS</Typography>

                            {coHostList.map((value) => {
                                return (
                                    <Box className="host-details">
                                        <img
                                            className="profile-picture"
                                            src={value.hostImage}
                                            alt=""
                                            height={40}
                                            width={40}
                                        />
                                        <Typography className="host-name">
                                            {value.hostName}
                                        </Typography>
                                        <IconButton>
                                            <ClearButtonIcon />
                                        </IconButton>
                                    </Box>
                                );
                            })}

                            <Button variant="contained" className="add-co-host-button">
                                Add Co-Hosts
                            </Button>
                        </Box>

                        <Box className="terms-section">
                            <Typography className="advanced-header">Terms</Typography>

                            <Box className="terms-checkbox">
                                <Checkbox />
                                <Typography>
                                    I agree to the{' '}
                                    <span className="community-guidelines-link">
                                        Community Guidelines
                                    </span>
                                </Typography>
                            </Box>
                        </Box>

                        <Button variant="contained" className="schedule-button">
                            {props.editMeditationRoomSession ? 'Save' : 'Schedule'}
                        </Button>

                        {props.editMeditationRoomSession ? (
                            <Box className="cancel-session" onClick={handleOpen}>
                                <IconButton>
                                    <CancelSessionIcon
                                        fillColor={'#656565'}
                                        width={35}
                                        height={35}
                                    />
                                </IconButton>
                                <Typography>Cancel Session</Typography>
                            </Box>
                        ) : null}
                    </SessionAdvanceSectionWrapper>
                </MainFormContentWrapper>

                <Footer showWhiteBackground={false} topBorderColor="gray" />
            </MeditationRoomCreateSessionWrapper>

            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}>
                <Slide
                    in={open}
                    direction={windowDimensions.width <= BREAKPOINTS_VALUE.TABLET ? 'up' : 'right'}
                    timeout={{ enter: 300, exit: 300 }}
                    unmountOnExit>
                    <CancelSessionModalWrapper>
                        <Box>
                            <Typography>Are you sure?</Typography>
                            <Typography className="session-details">
                                <strong>Breathwork for a Clear Mind </strong> on{' '}
                                <strong>Nov 18, 2021</strong> will be canceled.
                            </Typography>

                            <FormGroup>
                                <FormControlLabel
                                    control={<CustomSwitch defaultChecked />}
                                    label="Notify Followers"
                                />
                            </FormGroup>

                            <Button
                                variant="contained"
                                className="cancel-session-button"
                                onClick={handleClose}>
                                <IconButton>
                                    <CancelSessionIcon fillColor={'white'} width={35} height={35} />
                                </IconButton>
                                Yes, Cancel IT
                            </Button>

                            <Typography className="continue-editing" onClick={handleClose}>
                                No, Continue Editing
                            </Typography>
                        </Box>
                    </CancelSessionModalWrapper>
                </Slide>
            </Modal>
        </>
    );
};

export default MeditationRoomCreateSession;
