import { Button, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MeditationDetailsCardWrapper, NotificationStyleWrapper } from './MeditationTimer.style';
import { COLORS } from 'Styles/Constants';
import { ReactComponent as PlayIcon } from 'Assets/images/FieldHealing/play.svg';
import { ReactComponent as PauseIcon } from 'Assets/images/FieldHealing/pause.svg';
import { ReactComponent as EndIcon } from 'Assets/images/MeditationTimer/end.svg';
import { ReactComponent as RestartIcon } from 'Assets/images/MeditationTimer/restart.svg';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { floor } from 'lodash';
import { ReactComponent as LeftArrowIcon } from 'Assets/images/Common/previous-arrow.svg';
import { convertHrsMinToMins, convertHrsMinToSeconds, getTotalTimeInMins } from 'Helpers';
import { useSnackbar } from 'notistack';
import { withSnackbar } from 'notistack';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';

//icon
import { ReactComponent as CloseIcon } from 'Assets/images/Common/close-icon.svg';
import { useSelector } from 'react-redux';

const MeditationDetailsCard = (props) => {
    const API = useMemo(() => new Api(), []);

    const [propsState] = useState(props);
    const [play, setPlay] = useState(false);
    const [remainingTime, setRemainingTime] = useState('00:00');
    const [showZenModePopup, setZenModePopup] = useState(true);
    const [key, setKey] = useState(0);
    const [currentPlayer, setCurrentPlayer] = useState({
        start: false,
        end: false,
        ambient: false
    });
    const [onComplete, setOnComplete] = useState(false);
    const [dummyState, setDummyState] = useState(null);
    const userDetails = useSelector((state) => state.Auth.userInfo);

    let startAudioRef = useRef(null);
    let ambientAudioRef = useRef(null);
    let endAudioRef = useRef(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const elapsedTimeRef = useRef();

    // this is use to update state to intial values on props change

    const playAudio = useCallback(() => {
        if (currentPlayer.start) {
            startPlayer();
        } else if (currentPlayer.ambient) {
            ambientPlayer();
        } else if (currentPlayer.end) {
            endPlayer();
        }
    }, [currentPlayer.ambient, currentPlayer.start, currentPlayer.end]);

    const pauseAudio = () => {
        if (currentPlayer.start) {
            startAudioRef.current.pause();
        } else if (currentPlayer.ambient) {
            ambientAudioRef.current.pause();
        } else {
            endAudioRef.current.pause();
        }
        setPlay(false);
    };

    const startPlayer = () => {
        if (startAudioRef?.current) {
            startAudioRef.current.play();
            return;
        }
    };

    const ambientPlayer = () => {
        if (ambientAudioRef?.current) {
            startAudioRef.current.pause();
            ambientAudioRef.current.play();
            return;
        }
    };

    const endPlayer = () => {
        if (endAudioRef?.current) {
            startAudioRef.current.pause();
            ambientAudioRef.current.pause();
            endAudioRef.current.play();
            return;
        }
    };

    const getRemainingTime = useCallback(
        (timeInMs) => {
            let time = getTotalTimeInMins(timeInMs);
            setRemainingTime(time);

            if (endAudioRef?.current?.duration >= floor(timeInMs) && !currentPlayer.end) {
                setCurrentPlayer((prev) => {
                    return { ...prev, ambient: false, end: true };
                });
                setDummyState((prev) => !prev);
            }
        },
        [currentPlayer.end]
    );

    const onPlay = () => {
        if (currentPlayer.start || currentPlayer.ambient || currentPlayer.end) {
            playAudio();
        } else {
            setCurrentPlayer((prev) => {
                return { ...prev, start: true };
            });
            setDummyState((prev) => !prev);
        }
        setPlay(true);
    };

    const onEnd = () => {
        setPlay(false);
        setKey((prev) => prev + 1);
        setOnComplete(false);
        updatePlayTime();
    };

    const onRestart = () => {
        setCurrentPlayer((prev) => {
            return { ...prev, start: true, end: false, ambient: false };
        });
        setDummyState((prev) => !prev);
        setPlay(true);
        setOnComplete(false);
        setKey((prev) => prev + 1);
        updatePlayTime();
    };

    const showSnackbar = () => {
        if (!userDetails?.isSilenceDuringMeditation) return;

        const notificationData = (
            <NotificationStyleWrapper className="flex f-v-center">
                <Box className="logo" />
                <Box className="flex f-column">
                    <Typography className="text primary">Zen Mode: ON</Typography>
                    <Typography className="text secondary">
                        We'll snooze notifications while you meditate
                    </Typography>
                </Box>
                <Button
                    className="btn"
                    onClick={() => {
                        closeSnackbar();
                        setZenModePopup(false);
                    }}>
                    DON’T SHOW AGAIN
                </Button>
                <IconButton
                    className="close-icon"
                    onClick={() => {
                        closeSnackbar();
                    }}>
                    <CloseIcon />
                </IconButton>
            </NotificationStyleWrapper>
        );
        showZenModePopup && enqueueSnackbar(notificationData, { autoHideDuration: 5000 });
    };

    const updatePlayTime = useCallback(async () => {
        if (!elapsedTimeRef.current) return;
        let timeSplit = elapsedTimeRef.current.split(':');

        let time = '';

        if (timeSplit.length < 3) time = convertHrsMinToMins(elapsedTimeRef.current);
        else time = convertHrsMinToSeconds(elapsedTimeRef.current);

        let playedTimeInSeconds = parseInt(props?.meditation?.meditationTime) * 60 - parseInt(time);

        if (playedTimeInSeconds < 0) return;

        await API.post(API_URL.MEDITATION_TIMER_PLAY_DETAILS, {
            params: {
                meditationTime: getTotalTimeInMins(playedTimeInSeconds, true),
                totalMeditationTime: getTotalTimeInMins(
                    props?.meditation?.meditationTime * 60,
                    true
                )
            }
        });
        elapsedTimeRef.current = null;
    }, [API, props?.meditation?.meditationTime]);

    useEffect(() => {
        if (propsState?.meditation?.meditationId !== props?.meditation?.meditationId) {
            setPlay(false);
            setRemainingTime(props?.meditation?.meditationTime);
            setKey((prev) => prev + 1);
            setCurrentPlayer({ start: false, end: false, ambient: false });
            setOnComplete(false);
            setDummyState(null);
        }
    }, [
        props?.meditation?.meditationId,
        propsState?.meditation?.meditationId,
        props?.meditation?.meditationTime
    ]);

    useEffect(() => {
        const { updateLayer } = propsState;
        updateLayer && updateLayer(play);
    }, [play, propsState]);

    useEffect(() => {
        if (dummyState != null) {
            playAudio();
        }
    }, [dummyState, playAudio]);

    useEffect(() => {
        return () => updatePlayTime();
    }, [updatePlayTime]);

    useEffect(() => {
        elapsedTimeRef.current = remainingTime;
    }, [remainingTime]);

    return (
        <MeditationDetailsCardWrapper
            image={props?.meditation?.backgroundImage?.backgroundImageOriginal}>
            <Box className="container">
                <Box className="flex full-width f-v-center">
                    <Box className="details-screen-arrow">
                        <LeftArrowIcon onClick={() => props.setSelectedMeditationCard({})} />
                    </Box>

                    <Box className="flex f-h-center full-width">
                        <Typography className="timer">{remainingTime}</Typography>
                    </Box>
                </Box>

                <Box className="player-container">
                    <div style={{ display: 'none' }}>
                        <audio
                            ref={startAudioRef}
                            loop={false}
                            src={props?.meditation?.startSound?.sound || ''}
                            controls
                            onEnded={() => {
                                setCurrentPlayer((prev) => {
                                    return { ...prev, start: false, ambient: true };
                                });
                                setDummyState((prev) => !prev);
                            }}
                        />

                        <audio
                            ref={ambientAudioRef}
                            loop={true}
                            src={props?.meditation?.ambientSound?.sound || ''}
                            controls
                        />

                        <audio
                            ref={endAudioRef}
                            loop={false}
                            src={props?.meditation?.endSound?.sound || ''}
                            controls
                            onEnded={() => {
                                setCurrentPlayer((prev) => {
                                    endAudioRef?.current?.pause();
                                    return {
                                        ...prev,
                                        end: false,
                                        start: false,
                                        ambient: false
                                    };
                                });
                            }}
                        />
                    </div>

                    <Box className="circular-progress-bar-container">
                        <CountdownCircleTimer
                            key={key}
                            isPlaying={play}
                            duration={
                                props?.meditation?.meditationTime
                                    ? props.meditation.meditationTime * 60
                                    : 0
                            }
                            colors={[COLORS.PRIMARY_DARK, '#E8E8E8']}
                            colorsTime={[
                                props?.meditation?.meditationTime
                                    ? props.meditation.meditationTime * 60
                                    : 0
                            ]}
                            strokeWidth={5}
                            onUpdate={(remainingTime) => getRemainingTime(remainingTime)}
                            onComplete={() => {
                                setOnComplete(true);
                            }}>
                            {() => (
                                <>
                                    {!onComplete ? (
                                        <Box
                                            className="pointer"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                !play ? onPlay() : pauseAudio();
                                            }}>
                                            {!play ? (
                                                <PlayIcon onClick={() => showSnackbar()} />
                                            ) : (
                                                <PauseIcon />
                                            )}
                                        </Box>
                                    ) : (
                                        <Box className="complete">
                                            <Typography className="done-fonts">done</Typography>

                                            <Box className="flex f-h-center f-v-center">
                                                <EndIcon
                                                    onClick={() => onEnd()}
                                                    className="pointer"
                                                />
                                                <RestartIcon
                                                    onClick={() => onRestart()}
                                                    className="pointer"
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                </>
                            )}
                        </CountdownCircleTimer>
                    </Box>

                    <Typography className="name">{props.meditation?.name}</Typography>
                </Box>
            </Box>
        </MeditationDetailsCardWrapper>
    );
};

export default withSnackbar(MeditationDetailsCard);
