import styled from 'styled-components';
import { Box } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const RoomFilterWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px;

    .top-filter-container {
        display: flex;
        margin: 0 0 10px;

        ${responsive.TABLET`
            flex-direction: column;
        `}

        .common-text-container {
            display: flex;
            align-items: center;
            gap: 10px;

            .filter-text {
                font-size: 20px;
                color: ${COLORS.TEXT_DARK};
                font-family: ${FONTS.PRIMARY_MEDIUM};

                &.primary {
                    color: ${COLORS.PRIMARY};
                }
            }
            .select-text {
                color: ${COLORS.MEDIUM_GREY};
                font-size: 16px;
                font-family: ${FONTS.PRIMARY};
            }
        }

        .topic-filter-container {
            display: flex;
            flex-direction: column;
            width: 50%;

            ${responsive.TABLET`
                width: 100%;
            `}

            .category-list {
                height: 110px;
                width: 100%;
                display: flex;
                gap: 10px;
                margin-top: 10px;

                ${responsive.MOBILE`
                    overflow: auto;
                `}

                .icon-container {
                    max-width: max-content;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    overflow: hidden;
                    cursor: pointer;
                    min-width: 71px;
                    margin: 0 5px 0 0;

                    .category-icon {
                        width: 70px;
                        height: 70px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 100%;
                    }

                    .text-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 30px;
                        overflow: hidden;
                        margin: 5px 0 0;

                        .category-name {
                            font-size: 13px;
                            line-height: 15px;
                            color: ${COLORS?.TEXT_DARK};
                            text-align: center;
                            width: 100%;
                            max-width: 80px;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }

                .wellness-list-loader-wrapper {
                    height: 133px;
                    width: 71px;
                    min-width: 71px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 0px 10px 0px 0px;
                    gap: 6px;

                    .loader-circle {
                        padding: 8px;
                        height: 70px;
                        width: 70px;
                    }
                }
            }
        }

        .filter-sort-container {
            display: flex;
            gap: 12px;
            margin: 0 0 0 auto;

            ${responsive.TABLET`
                margin: 10px 0 0 0;
            `}

            .filter-container {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }
    }

    .title-container {
        display: flex;
        align-items: center;
        .logo-img {
            background-color: ${COLORS.WHITE};
            width: 35px;
            height: 35px;
            border-radius: 50%;
        }
        .title-text {
            color: ${COLORS.BLACK};
            font-size: 24px;
            text-transform: capitalize;
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            margin-left: 8px;
        }
        .match-text {
            font-size: 16px;
            color: ${COLORS.MEDIUM_GREY};
            margin-left: 20px;
        }
    }

    .container {
        z-index: 10;
        position: relative;
        margin: 20px 0;
    }

    .community-rooms-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
`;
