import { Box, Dialog } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import ReferralPoints from 'Assets/images/Profile/referral-points.svg';
import Referrals from 'Assets/images/Profile/referrals.svg';
import InviteFriend from 'Assets/images/Profile/invite-friend.svg';

export const ReferralWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: ${COLORS.WHITE};
    position: relative;
    height: 100%;
    border-radius: 30px 30px 0 0;
    padding: 20px 0 0;

    ${responsive.TABLET`
        height: calc(100% - 80px);
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        border-radius: 30px 30px 0px 0px;
        background: #FFFFFF;
        padding: 0;
    `}

    ${responsive.PHABLET`
        background: none;
        box-shadow: none;
        border-radius: none;
    `}

    .content {
        padding: 0 0 0 20px;

        ${responsive.TABLET`
            padding: 20px 0 0 20px;
        `}

        &.referral-history-content {
            height: calc(100% - 210px);
            overflow: auto;

            ::-webkit-scrollbar {
                display: none;
            }
        }
        .title {
            font-size: 18px;
            color: ${COLORS.DARK_GOLD};
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            margin: 15px 0;
        }

        .see-more {
            margin: 0 10px 0 auto;
        }
        .top-container {
            display: flex;
            justify-content: space-between;
            padding: 0 20px 0 0;

            .content-box-loader {
            }

            .content-box {
                background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                width: 32%;
                padding: 10px;
                border-radius: 12px;
                height: 160px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                ${responsive.PHABLET`
                    height: 130px;
                `}

                .icon {
                    width: 50px;
                    height: 50px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;

                    ${responsive.PHABLET`
                        height: 30px;
                        width: 30px;
                    `}

                    &.referral-points {
                        background-image: url(${ReferralPoints});
                    }
                    &.referrals {
                        background-image: url(${Referrals});
                    }
                    &.invite-friend {
                        width: 70px;
                        height: 70px;
                        background-image: url(${InviteFriend});
                        margin: 15px auto;

                        ${responsive.PHABLET`
                            height: 40px;
                            width: 40px;
                        `}
                    }
                }
                .points {
                    font-size: 45px;
                    font-family: ${FONTS.PRIMARY_BOLD};
                    color: ${COLORS.WHITE};

                    ${responsive.PHABLET`
                        font-size: 24px;
                    `}
                }
                .text {
                    color: ${COLORS.WHITE};
                    font-size: 14px;
                    font-family: ${FONTS.PRIMARY_BOLD};

                    &.invite {
                        color: ${COLORS.WHITE};
                    }
                }
            }
        }

        .history-item {
            background-color: ${COLORS.LIGHT_GREY_SECONDARY};
            width: calc(100% - 20px);
            height: 80px;
            border: 1px solid ${COLORS.LIGHT_GREY};
            border-radius: 12px;
            margin: 0 0 10px;
            padding: 10px;

            .date-container {
                background-color: ${COLORS.WHITE};
                height: 100%;
                width: max-content;
                padding: 0 5px;
                border-radius: 8px;

                .day {
                    font-size: 38px;
                    color: ${COLORS.TEXT_DARK};
                    font-family: ${FONTS.PRIMARY_BOLD};
                    line-height: 1;
                }
                .month-year {
                    font-size: 10px;
                    color: ${COLORS.TEXT_DARK};
                }
            }
            .earned-points {
                color: #4f9f1d;
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};

                .points {
                    font-size: 36px;
                    margin: 0 5px 0 0;
                }
            }

            .analytics {
                height: 100%;
                width: 150px;

                .border {
                    border: 1px solid ${COLORS.MEDIUM_GREY};
                    height: 90%;
                    margin: 0 10px 0 0;
                    opacity: 25%;
                }

                .totals {
                    font-size: 15px;
                    text-transform: capitalize;
                }
            }
        }

        .no-referral-history {
            background-color: ${COLORS.LIGHT_GREY_SECONDARY};
            border-radius: 12px;
            border: 1px solid ${COLORS.LIGHT_GREY};
            height: 80px;
            padding: 10px;
            width: calc(100% - 20px);

            .calendar {
                width: max-content;
                padding: 5px;
                background: linear-gradient(135deg, #cacaca 0%, #a3a3a3 100%);
                border-radius: 8px;
                margin: 0 10px 0 0;
                min-height: 60px;
                min-width: 60px;
            }

            .no-referrals-text {
                line-height: 20px;
                color: ${COLORS.TEXT_DARK};
                font-size: 15px;

                &.bold {
                    font-family: ${FONTS.PRIMARY_BOLD};
                }

                &.subtitle {
                    color: #000;
                }
                &.invite {
                    color: ${COLORS.PRIMARY_DARK};
                    font-size: 14px;
                    text-decoration: underline;
                    font-family: ${FONTS.PRIMARY_MEDIUM};
                }
            }
        }

        .bottom-collapse-section {
            width: calc(100% - 60px);
            margin: -10px 20px 0;
            height: 20px;
            background-color: #dfdfdf;
            border-radius: 12px;
            display: inherit;

            &.hide {
                display: none;
            }
        }
    }

    .referral-link-container {
        position: relative;
        margin: 20px 0 0;

        ${responsive.TABLET`
            margin: 0px;
        `}

        .color-container {
            background: ${COLORS.LIGHT_GREY_SECONDARY};
            opacity: 0.4;
            position: absolute;
            height: 100%;
            width: 100%;
        }

        .share-content-wrapper {
            padding: 20px;
            position: relative;

            .referral-link-header {
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                font-size: 18px;
                text-transform: capitalize;
                color: ${COLORS.DARK_GOLD};
                position: relative;
            }

            .share-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;

                .share-link-input {
                    width: 70%;
                    height: 40px;
                    background: rgba(255, 255, 255, 0.66);
                    border: 2px solid ${COLORS.PRIMARY_DARK};
                    border-radius: 6px;
                }

                .input {
                    padding: 10px 8px;
                    font-size: 18px;
                    color: ${COLORS.DARK_GOLD};
                }

                .disabled-input {
                    -webkit-text-fill-color: ${COLORS.DARK_GOLD};
                }

                .extra-feature-icon {
                    width: max-content;
                    margin: 0 0 0 auto;

                    .share-btn-container {
                        height: 40px;
                        background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                        border-radius: 12px;
                        box-shadow: none;
                        min-width: 120px;
                        display: flex;
                        justify-content: center;

                        .share-btn-text {
                            font-size: 16px;
                            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                            color: ${COLORS.WHITE};
                            padding: 0 10px;
                        }
                    }
                }

                .list-index {
                    font-size: 48px;
                    font-family: ${FONTS.PRIMARY_BOLD};
                    color: ${COLORS.PRIMARY_DARK};

                    ${responsive.PHABLET`
                        font-size: 40px;
                    `}
                    ${responsive.MOBILE`
                        font-size: 32px;
                    `}
                }
                .description {
                    font-size: 18px;
                    color: ${COLORS.BLACK};
                    margin: 0 0 0 20px;

                    ${responsive.PHABLET`
                        font-size: 16px;
                        margin: 0 0 0 10px;
                    `}

                    ${responsive.MOBILE`
                        font-size: 14px;
                        margin: 0 0 0 10px;
                    `}
                }
            }
        }
    }
`;

export const ReferralLayout = styled(Box)`
    width: 50%;
    margin: 100px auto 0;
    min-height: 700px;
    height: calc(100vh - 100px);

    ${responsive.LAPTOP`
        width: 70%;
    `}

    ${responsive.TABLET`
        width: 90%;
    `}
    ${responsive.MOBILE`
        width: 95%;
    `}
`;

export const ReferralShadowBoxLayout = styled(Box)`
    width: 30%;
    margin: 100px auto 0;
    min-height: 700px;
    height: calc(100vh - 100px);

    ${responsive.DISPLAY`
        width: 40%;
    `}
    ${responsive.LAPTOP`
        width: 60%;
    `}
    ${responsive.TABLET`
        width: 70%;
    `}
    ${responsive.PHABLET`
        width: 90%;
    `}
    ${responsive.MOBILE`
        width: 95%;
    `}
`;

export const StoreItemWrapper = styled(Box)`
    .store-item-title {
        font-size: 18px;
        color: ${COLORS.DARK_GOLD};
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        margin: 15px 0 10px;
    }

    .item {
        .store-item {
            position: relative;
            border-radius: 20px;
            overflow: hidden;

            .image {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 190px;
                width: 190px;

                ${responsive.PHABLET`
                    height: 140px;
                    width: 140px;
                `}
            }
            .bottom-bar {
                background-color: ${COLORS.PRIMARY_LIGHT};
                position: absolute;
                bottom: 0;
                width: 100%;

                .feature-text {
                    color: ${COLORS.BLACK};
                    font-size: 18px;
                    font-family: ${FONTS.PRIMARY_BOLD};
                    text-align: center;
                    text-transform: uppercase;

                    ${responsive.PHABLET`
                        font-size: 14px;
                    `}
                }
            }
        }
        .type {
            font-size: 20px;
            color: ${COLORS.TEXT_DARK};
            text-transform: uppercase;

            ${responsive.PHABLET`
                font-size: 16px;
            `}
        }
    }

    .button {
        width: calc(100% - 20px);
        height: 60px;
        font-size: 16px;
        font-family: ${FONTS.PRIMARY_BOLD};
        border-radius: 12px;
        margin: 20px 0;
    }
`;

export const InviteFriendWrapper = styled(Dialog)`
    .paper {
        padding: 20px;
        box-shadow: 0px 4px 12px 0px #00000040;
        background-color: ${COLORS.LIGHT_GREY_SECONDARY};
        width: 100%;
        max-width: 550px;

        .invite-friend-dialog-title {
            font-size: 18px;
            color: ${COLORS.BLACK};
            margin: 0 0 20px 0;
        }

        .invite-friend-share-btn {
            width: 120px;
            margin: 0 0 0 auto;

            .share-btn-container {
                height: 40px;
                background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                border-radius: 12px;
                box-shadow: none;
                min-width: 120px;
                display: flex;
                justify-content: center;

                .share-btn-text {
                    font-size: 16px;
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    color: ${COLORS.WHITE};
                    padding: 0 10px;
                }
            }
        }
        .share-link-input {
            width: 350px;
            height: 40px;
            background: rgba(255, 255, 255, 0.66);
            border: 2px solid ${COLORS.PRIMARY_DARK};
            border-radius: 6px;
        }
        .input {
            padding: 10px 8px;
            font-size: 18px;
            color: ${COLORS.DARK_GOLD};
        }

        .disabled-input {
            -webkit-text-fill-color: ${COLORS.DARK_GOLD};
        }
    }
`;
