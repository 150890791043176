import styled from 'styled-components';
import { Box } from '@mui/material';

import { COLORS, FONTS, BREAKPOINTS_VALUE } from 'Styles/Constants';

export const RoomDetailsWrapper = styled(Box)`
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    background-size: cover;
    padding-top: 120px;
    /* padding-bottom: 80px; */
    background: linear-gradient(180deg, #F9F0DB 0%, #E8D7B2 100%);
    margin-bottom: 2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
        ::-webkit-scrollbar {
            display: none;
        }
    }
    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_860}px) {
        /* padding-bottom: 2rem; */
    }

    .back-button-container {
        display: none;
        transform: rotate(90deg);
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
            display: flex;
        }
        position: absolute;
        top: 1.1rem;
        left: 1rem;
        z-index: 7;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 52px;
        overflow: hidden;
        border-radius: 50%;
        .arrow-btn {
            width: 100%;
            height: 100%;
            svg {
                width: 23px;
                height: 23px;
                margin-top: 4px;
                path {
                    fill: ${COLORS.PRIMARY_DARK};
                }
            }
        }
    }

    .mobile-title-actions-container {
        display: none;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
            display: flex;
        }
        margin-left: auto;
        gap: 8px;
        z-index: 7;
        position: absolute;
        top: 16px;
        right: 1rem;
        .btn-container {
            background: #FFFFFF;
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
            border-radius: 26px;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            &.message-counter-container {
                box-shadow: none;
                background: none;
                margin-top: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .notification-count {
                    color: #BC1218;
                    font-weight: 600;
                    margin-right: 2px;
                }
                .notification-img {
                    position: absolute;
                    z-index: -1;
                    /* width: 133%; */
                    /* height: 141%; */
                }
            }
            .action-btn {
                width: 100%;
                height: 100%;
                .fav-icon {
                    path {
                        fill: rgb(101, 101, 101);
                    }
                }
            }
            
        }
    }

    .page-content {
        width: 85%;
        max-width: 938px;
        margin-left: 55px;
        display: flex;
        flex-direction: column;
        z-index: 8;
        position: relative;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.DESKTOP}px) {
            /* min-width: 915px; */
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1200}px) {
            /* min-width: 880px; */
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1100}px) {
            margin-left: 95px;
        }

        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
            margin-left: 102px;
            /* min-width: 835px; */
        }

        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
            margin-left: auto;
            margin-right: auto;
            width: 90%;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_860}px) {
            width: 100%;
            min-width: auto;
        }

        .page-title-container {
            padding: 10px 25px;
            display: flex;
            align-items: center;
            position: relative;
            min-height: 81px;
            z-index: 18;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.DESKTOP}px) {
                /* padding: 0 2px; */
            }
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_860}px) {
                padding: 0 16px;
            }
            .title {
                font-weight: 500;
                font-size: 40px;
                line-height: 40px;
                letter-spacing: -0.02em;
                text-transform: capitalize;
                font-family: ${FONTS.SECONDARY_SEMI_BOLD};
                margin-bottom: 8px;
            }
            .images-container {
                display: flex;
                position: absolute;
                height: 100%;
                top: 25px;
                left: 22px;
                
               .tcf-avatar {
                   width: 80px;
                   height: 80px;
                   border: 3px solid ${COLORS.PRIMARY};
                   border-radius: 50%;
                   box-sizing: border-box;
                }

                .fr-avatar {
                    position: relative;
                    width: 80px;
                    height: 80px;
                    overflow: hidden;
                    border-radius: 35%;
                    border: 3px solid ${COLORS.PRIMARY};
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
                }

                .cr-avatar {
                    position: relative;
                    border-radius: 10px;
                    width: 80px;
                    height: 80px;
                    overflow: hidden;
                    border: 1px solid #E8E8E8;
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;

                    }
                }
            }

            .title-actions-container {
                margin-left: auto;
                display: flex;
                gap: 8px;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
                    display: none;
                }
                .btn-container {
                    background-color: #FFFFFF;
                    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
                    border-radius: 26px;
                    width: 52px;
                    height: 52px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    &.message-counter-container {
                        box-shadow: none;
                        background: none;
                        margin-top: 2px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        .notification-count {
                            color: #BC1218;
                            font-weight: 600;
                            margin-right: 2px;
                        }
                        .notification-img {
                            position: absolute;
                            z-index: -1;
                            /* width: 133%; */
                            /* height: 141%; */
                        }
                    }
                    .action-btn {
                        width: 100%;
                        height: 100%;
                        .fav-icon {
                            path {
                                fill: rgb(101, 101, 101);
                            }
                        }
                    }
                    
                }
            }
        }


        .page-content-container {
            width: 100%;
            height: 100%;
            border-radius: 36px;
            background-repeat: no-repeat;
            background-size: cover;
            border-bottom: 0;
            position: relative;
            /* padding: 1rem; */
            /* margin-top: 1rem; */
            z-index: 15;
            background-color: white;
            box-shadow: 0px 0px 24px rgba(196, 196, 196, 0.25);
            overflow: hidden;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
                border-radius: 36px 36px 0 0;
            }
            .room-info-container {
                margin-top: 3rem;
                margin-left: 30px;
                display: flex;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                    flex-direction: column;
                }
                .room-title-container {
                    max-width: 55%;
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                        display: flex;
                        min-width: 100%;
                        justify-content: space-between;
                    }
                    h4 {
                        font-weight: 500;
                        font-family: ${FONTS.PRIMARY_MEDIUM};
                        font-size: 36px;
                        line-height: 36px;
                        text-transform: capitalize;
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                            font-size: 30px;
                        }
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
                            font-size: 24px;
                        }
                    }
                    .more-icon {
                        display: none;
                        max-height: 40px;
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                            display: flex;
                        }
                    }
                }

                .room-specs-container {
                    margin-left: auto;
                    display: flex;
                    gap: 12px;
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                        margin-left: 0;
                        flex-wrap: wrap;
                    }
                    .count {
                        color: #848484;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 20px;
                    }
                    .spec-details-container {
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        .filled-start-icon {
                            width: 20px;
                            height: 20px;
                        }
                        .heart-icon {
                            width: 28px;
                            height: 26px;
                            path {
                                fill: ${COLORS.PRIMARY_DARK};
                            }
                            g {
                                filter: none;
                            }
                        }
                        .person-icon {
                            /* fill: ${COLORS.PRIMARY_DARK}; */
                        }
                    }

                    .btn-container {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        margin-right: 22px;
                        min-height: 40px;
                        /* @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                            flex-grow: 1;
                        } */
                        .btn {
                            height: 90%;
                            border-radius: 8px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 16px;
                            box-shadow: none;
                            max-height: 36px;
                            &:hover {
                                box-shadow: none;
                            }
                            &.following {
                                border: 1px solid ${COLORS.PRIMARY_DARK};
                                color: ${COLORS.PRIMARY_DARK};
                            }   
                            &.non-following {
                                color: white;
                            }
                        }
                        .more-icon {
                            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                               /* margin-left: auto; */
                               display: none;
                            }    
                        }
                    }
                }
            }

            .filter-text {
                margin-left: 30px;
                margin-top: 10px;
                h6 {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    font-family: ${FONTS.PRIMARY_MEDIUM};
                    color: #848484;
                }
            }
            .tag-container {
                margin-top: 1rem;
                margin-left: 30px;
                padding-right: 12px;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                    width: 94%;
                    white-space: nowrap;
                    overflow-x: auto;
                    &::-webkit-scrollbar-thumb {
                        display: none;
                    }
                }

                    
                    .chip {
                        padding: 10px 8px;
                        margin: 3px 4px;
                        height: 46px;
                        border-radius: 22px;
                        text-transform: capitalize;
                        border: 2px solid transparent;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22px;
                        z-index: 5;
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                        &:first-child {
                            margin-left: 0;
                        }
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_860}px) {
                            font-size: 14px;
                        }
                        /* &:first-child {
                            margin-left: 0;
                        } */
                        &.non-selected-chip {
                            color: #848484;
                            font-weight: 600;
                        }

                        &.selected-chip {
                            border: 2px solid ${COLORS.PRIMARY_DARK};
                            color:  ${COLORS.PRIMARY_DARK};
                            background-color: white;
                            font-weight: 600;
                        }

                    }
                }


            .tab-content-container {
                position: relative;
                .tab-content {
                    position: absolute;
                }
                .tab-enter {
                    opacity: 0;
                }

                .tab-enter-active, .tab-enter-active {
                    opacity: 1;
                    transition: opacity 450ms;
                }
                .tab-exit, .tab-exit {
                    opacity: 1;
                }
                .tab-exit-active, .tab-exit-active {
                    opacity: 0;
                    transition: opacity 450ms;
                }
            }

            .topic-filter-container {
                padding: 20px 30px;
                margin-top: 24px;
                display: flex;
                flex-direction: column;
                background-color: rgba(176, 140, 61, 0.1);
                .title-txt-container {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    h5 {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 29px;
                        color: #656565;
                        font-family: ${FONTS.PRIMARY_MEDIUM};
                    }
                    h6 {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 23px;
                        color: #848484;
                    }
                }
                
            }
        }
    }

    footer {
        margin-top: 70px;
        margin-left: 55px;
    }

    .room-image {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 5;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
            margin-left: auto;
            margin-right: auto;
        }
    }
`;


export const ProgramListWrapper = styled(Box)`
    .category-list {
        display: flex;
        gap: 13px;
        margin-top: 8px;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
            gap: 7px;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
            /* min-width: 455px; */
            white-space: nowrap;
            overflow-x: auto;
            &::-webkit-scrollbar-thumb {
                visibility: hidden;
            }
        }
        .icon-container {
            max-width: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            cursor: pointer;
            min-width: 100px;
            gap: 11px;
            -webkit-tap-highlight-color: transparent;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                min-width: 66px;
            }
                svg {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                        width: 51px;
                        height: 51px;
                    }
                    circle {
                        transition: fill 400ms, filter 400ms;
                    }
                    path {
                        transition: fill 400ms;
                    }
                    &.selected-category {
                        circle {
                            fill: ${COLORS.PRIMARY};
                            /* filter: drop-shadow(0 0 0.75rem black); */
                        }
                        path[fill="#231F20"] {
                            fill: white;
                        }

                        path[fill="#B08C3D"] {
                            filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.65));
                        }
                    }
                }
                p {
                    font-size: 15.7831px;
                    line-height: 19px;
                    color: #656565;
                    font-weight: 400;
                    text-align: center;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    /* display: -webkit-box; */
                    width: 100%;
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
                        font-size: 14.7831px;
                    }
                }
            }
    }

    .result-container {
        display: flex;
        flex-direction: column;
        .filter-container {
            display: flex;
            margin: 1rem 2rem;
            justify-content: space-between;
            position: relative;
            .result-count {
                color: #656565;
                font-weight: 500;
                font-size: 20px;
                line-height: 29px;
                .counter {

                }
                .selected-category {
                    text-transform: capitalize;
                }
            }
            .filter-drop-down {
                background-color: #FFFFFF;
                position: absolute;
                border: 1px solid #E8E8E8;
                border-radius: 24px;
                padding: 0.5rem;
                cursor: pointer;
                max-height: 40px;
                transition-property: max-height;
                transition-duration: 600ms;
                transition-timing-function: linear;
                overflow: hidden;
                right: 0;
                &.open {
                    max-height: 300px;
                }
                .selected-val {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 1rem;
                    h5 {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;
                        text-transform: capitalize;
                        color: #31290E;
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    }
                    .icon {
                        transition: transform 600ms;
                        &.open {
                            transform: rotate(180deg);
                        }
                    }
                }
                .drop-down-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-top: 2px solid #e6e6e6;
                    .drop-down-item {
                        padding-top: 0.8rem;
                    }
                }
            }

        }
        .program-list-container {
            display: flex;
            flex-direction: column;
            .program-container {
                display: flex;
                width: 100%;
                gap: 5%;
                border-bottom: 1px solid #cbcaca;
                padding: 1rem 2rem;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
                    padding-left: 0.8rem;
                }
                &:nth-child(2n) {
                    background-color: rgba(232, 232, 232, 0.3);
                }
                &:last-child {
                    border: none;
                }
                .program-card {
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
                        gap: 18px;
                    }
                    .playlist-card {
                        background-size: cover;
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
                            height: 91.75px;
                            width: 158.8px;
                        }
                    }
                    .playlist-borders {
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
                            position: absolute;
                            top: -2px;
                            margin-left: 14px;
                            width: 100%;
                            height: 100%;
                            max-height: 109.75px;
                        }
                    }
                    .play-button {
                        /* mix-blend-mode: multiply; */
                        background-color: rgba(0,0,0,0.3);
                        border-radius: 4px;
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        /* padding-bottom: 10px; */
                        width: 48px;
                        height: 48px;
                        .playlist-icon {
                            /* z-index: 38; */
                            /* padding-bottom: 2px; */
                            margin-top: -5px;
                            width: 38px;
                            height: 38px;
                        }
                    }
                }

                .program-info-container {
                    margin: auto 0;
                    display: flex;
                    gap: 6px;
                    flex-direction: column;
                    .program-name {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 20px;
                        color: #31290E;
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET_500}px) {
                            font-size: 16px;
                            /* white-space: nowrap;
                            max-width: 100%; */
                            -webkit-box-orient: vertical;
                            width: 100%;
                            overflow: hidden;
                            height: 40px;
                            text-overflow: ellipsis;
                        }
                    }
                    .program-spec-container {
                        display: flex;
                        gap: 1rem;
                        align-items: center;
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
                            flex-wrap: wrap;
                            gap: 4px;
                        }
                        .count {
                            color: #848484;
                            margin-left: 5px;
                        }
                        .follower-count {
                            margin-left: 10px;
                        }
                        .spec-container {
                            display: flex;
                            align-items: center;
                            .filled-star {
                                path {
                                    fill: #848484;
                                }
                            }
                            .person-icon {
                                width: 15px;
                                height: 17px;
                                path {
                                    fill: #848484;
                                }
                            }
                            .outlined-star {
                                path {
                                    fill: #848484;
                                }
                            }
                            
                        }
                    }
                }
            }
        }

    } 
`;

export const SessionListingWrapper = styled(Box)`

    .signup-dialog {
        .back-drop {
            background-color: rgba(255, 255, 255, 0.6);
        }
    }

    .mobile-live-session-list-container {
        display: none;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
            display: flex;
            width: 100%;
            height: 195px;
            position: relative;
            .session-image-container {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                height: 195px;
                position: absolute;
            }
            .blue-layer {
                position: absolute;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                height: 50%;
                width: 100%;
                bottom: 0;
            }
            .session-info-container {
                position: absolute;
                width: 100%;
                height: 50%;
                display: flex;
                bottom: 0;
                flex-direction: column;
                justify-content: center;
                /* align-items: center; */
                h4 {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 21px;
                    color: #FFFFFF;
                    padding-left: 12px;
                    z-index: 4;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }
                h5 {
                    padding-left: 12px;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 21px;
                    color: #FFFFFF;
                    z-index: 4;
                    overflow: hidden;
                    overflow: ellipsis;
                    width: 100%;
                }
            }
            .btn-container {
                height: 40px;
                position: absolute;
                width: 100%;
                bottom: -20px;
                z-index: 4;
                display: flex;
                justify-content: center;
            }
            .join-btn {
                height: 40px;
                /* position: absolute; */
                background-color: #BC1218;
                border-radius: 18px;
                color: white;
                width: 80%;
            }
        }
    }

    .live-session-list-container {
        margin-top: 23px;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
            display: none;
        }
        .session-container  {
            background-color: rgba(176, 140, 61, 0.1);
            .session-image {
                border: 2px solid #BC1218;
            }
            .session-info {
                .title-container {
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
                        max-width: 330px;
                    }
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_900}px) {
                        max-width: 290px;
                        /* min-width: 270px; */
                        margin-right: auto;
                    }
                }
                .host-details {
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
                        display: block;
                    }
                }
            }
            .join-btn {
                height: 40px;
                background-color: #BC1218;
                border-radius: 18px;
                color: white;
                width: 26%;
                min-width: 200px;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_900}px) {
                        min-width: 170px;
                }
            }
        }
        
    }

    .upcoming-title-container {
            margin: 20px 0;
            margin-left: 30px;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                margin-top: 35px;
            }
            .upcoming-title {
                font-weight: 600;
                font-size: 24px;
                line-height: 35px;
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            }
            
    }

    .past-title-container {
        margin: 20px 0;
        margin-left: 30px;
        .past-title {
            font-weight: 600;
            font-size: 24px;
            line-height: 35px;
            color: #656565;
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        }
    }
    
    .schedule-new-session-container {
        max-width: 80%;
        margin: 10px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 4px dashed #E8E8E8;
        border-radius: 36px;
        padding: 40px 0;
        h5 {
            color: #656565;
        }
        .schedule-btn {
            margin-top: 20px;
            max-width: 370px;
            width: 90%;;
        }
    }

    .upcoming-session-list-container {
        display: flex;
        flex-direction: column;
        
        .session-container  {
            border-bottom: 1px solid #cbcaca;
            &:nth-child(2n) {
                background-color: rgba(232, 232, 232, 0.3);
            }
            &:last-child {
                border: none;
            }
            .session-image {
                border: 2px solid #E8E8E8;
            }
            .session-info {
                display: flex;
                align-items: center;
                gap: 20px;
                flex-grow: 1;
                justify-content: space-between;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 2px;
                }
                .title-container {
                    flex-grow: 1;
                    .session-name {
                        color: #31290E;
                    }
                }
                .program-actions {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    .signup-btn {
                        width: 150px;
                        height: 30px;
                        background-color: #FFFFFF;
                        border: 1px solid #E8E8E8;
                        border-radius: 6px;
                        display: flex;
                        gap: 8px;
                        justify-content: center;
                        align-items: center;
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE_376}px) {
                            width: 120px;
                        }
                        .text {
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 18px;
                            font-family: ${FONTS.PRIMARY_MEDIUM};
                            color: black;
                        }
                        .icon {
                            margin-left: 2px;
                            path {
                                fill: ${COLORS.PRIMARY_DARK};
                            }
                            margin-top: 2px;
                        }
                        .add-fav-icon {
                            width: 23px;
                            height: 23px;
                            @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE_376}px) {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                }
            }
        }
    }

    .past-session-list-container {
        display: flex;
        flex-direction: column;
        
        .session-container  {
            border-bottom: 1px solid #cbcaca;
            &:nth-child(2n) {
                background-color: rgba(232, 232, 232, 0.3);
            }
            &:last-child {
                border: none;
            }
            .session-image {
                border: 2px solid #E8E8E8;
            }
            .session-info {
                display: flex;
                align-items: center;
                gap: 20px;
                flex-grow: 1;
                justify-content: space-between;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 2px;
                }
                .title-container {
                    flex-grow: 1;
                    margin-right: auto;
                    .session-name {
                        color: #31290E;
                    }
                }
                .program-actions {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    margin-right: 1rem;
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
                        width: 100%;
                        margin-right: 4px;
                    }
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE_376}px) {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0;
                    }
                    .mobile-host-container {
                        display: none;
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
                            display: flex;
                            color: #848484;
                            font-size: 12px;
                            line-height: 17px;
                        }
                    }
                    .spec-details-container {
                        display: flex;
                        flex-grow: 1;
                        align-items: center;
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
                            margin-left: auto;
                            flex-grow: 0;
                        }
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE_376}px) {
                            margin-left: 0;
                        }
                        .count {
                            color: #656565;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }

    .session-container {
        padding: 20px 10px 20px 30px;
        display: flex;
        gap: 28px;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
            gap: 18px;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
            gap: 8px;
        }
        .session-image {
            min-width: 200px;
            height: 112px;
            border-radius: 12px;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                min-width: 170px;
                height: 97px;
                /* margin-right: auto; */
            }
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
                min-width: 119px;
                height: 67px;
            }
        }
        .session-info {
            .title-container {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 370px;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
                    max-width: 250px;
                    min-width: 250px;
                    margin-right: auto;
                }
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_900}px) {
                    max-width: 200px;
                    min-width: 200px;
                }
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
                    max-width: 300px;
                    /* min-width: 300px; */
                }

                @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE}px) {
                    max-width: 200px;
                }

                @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE_376}px) {
                    max-width: 150px;
                    min-width: 150px;
                }
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE_340}px) {
                    max-width: 130px;
                    min-width: 130px;
                }
               
                .live {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 21px;
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    color: #BC1218;

                }
                .session-name {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 21px;
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    color: #656565;
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_900}px) {
                        font-size: 18px;
                    }
                }
            }
            .mobile-date-container {
                display: none;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
                    display: flex;
                }
                .date-text {
                    color: #848484;
                    gap: 2px;
                }
            }
            .session-date-container {
                min-width: 65px;
                height: 78px;
                border: 1px solid #E8E8E8;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 8px;
                align-items: center;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_900}px) {
                    font-size: 18px;
                }
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
                    display: none;
                }
                .date {
                    margin-top: 14px;
                    font-weight: 400;
                    font-size: 42px;
                    line-height: 20px;
                }
                .month {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 20px;
                    color: #848484;
                }
            }
            .host-details {
                color: #848484;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
                    display: none;
                }
            }
        }
    }
`;

export const AboutWrapper = styled(Box)`
    margin-top: 35px;
    .my-room-info-container {
        display: flex;
        flex-direction: column;
        .description-container {
            background-color: rgba(232, 232, 232, 0.4);
            padding: 30px 20px 30px 40px;
            h5 {
                color: ${COLORS.PRIMARY_DARK};
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            }
            h6 {
                color: #31290E;
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
            }
        }
        .visitor-feeling-container {
            padding: 30px 20px 30px 40px;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
                .spec-container {
                    max-width: 40%;
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
                        max-width: 64%;
                        padding-right: 0;
                    }
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.PHABLET}px) {
                        max-width: 74%;
                        padding-left: 0;
                    }
                    .specs {
                        .spec-item {
                            h5 {
                                @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE}px) {
                                    width: 35%;
                                    min-width: 84px;
                                }
                                @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE_340}px) {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            .edit-btn {
                text-transform: none;
                height: max-content;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: #7D5416;
            }
        }
    }
    .spec-container {
            min-width: 35%;
            padding: 30px 40px;
            padding-left: 0;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
                padding-left: 30px;
            }
            .title {
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 35px;
                text-transform: capitalize;
                color: ${COLORS.PRIMARY_DARK};
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE}px) {
                    font-size: 20px;
                }
            }
            .specs {
                display: flex;
                flex-direction: column;
                .spec-item {
                    display: flex;
                    /* justify-content: space-between; */
                    width: 100%;
                    align-items: center;
                    gap: 5px;
                    padding: 6px 0;
                    .bar {
                        /* flex-grow: 1; */
                        height: 12px;
                        background-color: #C4C4C4;
                        border-radius: 5px;
                        margin-right: 0.8rem;
                    }
                    h5 {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 26px;
                        text-align: right;
                        text-transform: capitalize;
                        color: #B08C3D;
                        width: 30%;
                        min-width: 84px;
                        margin-right: 1rem;
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
                            width: 14%;
                        }
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE}px) {
                            width: 28%;
                        }
                    }
                    h6 {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 26px;
                        text-transform: capitalize;
                        color: #848484;
                    }
                }
            }
        }
    .session-info-container {
        /* padding: 30px 40px; */
        background-color: rgba(232, 232, 232, 0.4);
        width: 100%;
        display: flex;
        /* justify-content: space-between; */
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
            flex-direction: column;
            background-color: transparent;
        }
            .history-container {
                max-width: 55%;
                padding: 30px 40px;
                padding-right: 0;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
                    background: rgba(232, 232, 232, 0.4);
                    min-width: 100%;
                    margin-bottom: 12px;
                }
                h5 {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 35px;
                    color: ${COLORS.PRIMARY_DARK};
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE}px) {
                        font-size: 22px;
                    }
                }
                h6 {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 27px;
                    color: #31290E;
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE}px) {
                        font-size: 16px;
                    }
                }
            }
        .divider {
            /* height: 100%; */
            width: 0;
            border-right-width: 0.5px;
            border-left-width: 0.5px;
            border-top-width: 0px;
            border-bottom-width: 0px;
            border-style: solid;
            border-color: #E8E8E8;
            /* border: 1px solid black; */
            margin: 30px;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
                display: none;
            }
        }
        
    }

    .hosts-container {
        .title {
            margin-top: 27px;
            margin-left: 38px;
            font-weight: 600;
            font-size: 24px;
            line-height: 35px;
            color: ${COLORS.PRIMARY_DARK};
            text-transform: capitalize;
        }

        .TCF-host-list {
            max-width: 90%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                flex-direction: column;
                max-width: 100%;
            }
            .host-container {
                margin-top: 2rem;
                display: flex;
                /* margin-top: 8px; */
                min-width: 48%;
                max-width: 48%;
                gap: 20px;
                padding-bottom: 1rem;
                border-bottom: 1px solid #E8E8E8;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                    min-width: 100%;
                    max-width: 100%;
                    padding: 0.8rem;
                }
                &:nth-child(2n) {
                    margin-left: auto;
                }
                &:last-child {
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                        margin-bottom: 1rem;
                    }
                }
                .profile-section {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    /* max-width: 80px; */
                    .tcf-avatar {
                       width: 80px;
                       height: 80px;
                       border: 3px solid ${COLORS.PRIMARY};
                       border-radius: 50%;
                       box-sizing: border-box;
                    }
                    .profile-btn {
                        width: 86px;
                        border-radius: 8px;
                        border: 1px solid ${COLORS.PRIMARY_DARK};
                        padding: 5px 8px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 18px;
                        color: ${COLORS.PRIMARY_DARK};
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                        border-radius: 8px;
                        text-transform: none;
                    }

                }

                .description-section {
                    .name {
                        color: ${COLORS.PRIMARY_DARK};
                        text-transform: uppercase;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    }
                    .description {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 27px;
                        color: #31290E;
                    }
                }


            }
        }
    }

    .single-host-container {
        .title {
            margin-top: 27px;
            margin-left: 38px;
            font-weight: 600;
            font-size: 24px;
            line-height: 35px;
            color: ${COLORS.PRIMARY_DARK};
            text-transform: capitalize;
        }
        .description {
            color: #31290E;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            margin: 0 38px;
            margin-bottom: 21px;
        }
    }

    .followers-container {
        width: 100%;
        background-color: rgba(176, 140, 61, 0.15);
        padding: 30px;
        border-radius: 24px 24px 0px 0px;
        .section-title {
            font-weight: 600;
            font-size: 24px;
            line-height: 35px;
            color: ${COLORS.PRIMARY_DARK};
            text-transform: capitalize;
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            
        }
        .filter-input {
            width: 100%;
            margin: 30px 0;
            background-color: white;
            border-radius: 35px;
            height: 50px;
            padding-left: 20px;
            input {
                margin-left: 1rem;
                margin-top: 2px;
                font-weight: 400;
                font-size: 18px;
                /* line-height: 26px; */
                color: #656565;
                &::placeholder {
                    color: #656565;
                }
            }
        }
        .followers-list {
            display: flex;
            gap: 25px;
            flex-wrap: wrap;
            /* width: 95%; */
            margin: auto;
            align-items: center;
            justify-content: center;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE_376}px) {
                justify-content: space-evenly;
            }
            .follower-container {
                max-width: 80px;
                /* @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                    max-width: 45px;
                } */
                .follower-pic {
                    width: 80px;
                    height: 80px;
                    border-radius: 8px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    border-radius: 8px;
                    /* @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                        width: 45px;
                        height: 45px;
                    } */
                }
                .name {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    text-align: center;
                    color: #31290E;
                    margin-top: 4px;
                }
            }
        }
    }
`;

export const AnnouncementWrapper = styled(Box)`
    margin-top: 28px;
    display: flex;
    .post-content-container {
        width: 90%;
        margin: auto;
        .title-container {
            display: inline-block;
            width: calc(100% - 342px);
            margin-bottom: 8px;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
               display: block;
               width: 100%;
            }
            p {
                font-weight: 600;
                font-size: 24px;
                line-height: 35px;
                text-transform: capitalize;
                color: ${COLORS.PRIMARY_DARK};
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            }
        }
        #toolbar {
            width: 342px;
            display: inline-flex;
            font-weight: 700;
            font-size: 24px;
            line-height: 35px;
            text-transform: capitalize;
            border: none;
            margin-left: auto;
            padding: 0;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
                padding-bottom: 5px;
            }
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE_376}px) {
                width: 100%;
                overflow-x: auto;
                white-space: nowrap;
                &::-webkit-scrollbar-thumb {
                    display: none;
                }
            }
            .quill-btn {
                /* font-weight: 700; */
                font-size: 21px;
                line-height: 27px;
                height: 36px;
                width: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    float: none;
                }
                &:hover {
                    color: ${COLORS.PRIMARY};
                    .ql-stroke {
                        stroke: ${COLORS.PRIMARY};
                    }
                    .ql-fill {
                        fill: ${COLORS.PRIMARY};
                    }
                }
                &.ql-active {
                    color: ${COLORS.PRIMARY};
                    path {
                        stroke: ${COLORS.PRIMARY};
                    }
                    .ql-stroke {
                        stroke: ${COLORS.PRIMARY};
                    }
                    rect {
                        fill: ${COLORS.PRIMARY};
                    }
                }
            }
            .ql-bold {
                border: 1px solid #E8E8E8;
                border-radius: 18px 0 0 18px;
                svg {
                    height: 75%;
                }
            }
            .ql-italic {
                border: 1px solid #E8E8E8;
                border-left: none;
                width: 32px;
                font-style: italic;
                font-weight: 400;
                font-size: 21px;
                line-height: 27px;
                color: #656565;
                padding-right: 7px;
                svg {
                    height: 75%;
                }
            }
            .ql-underline {
                border: 1px solid #E8E8E8;
                color: ${COLORS.PRIMARY};
                border-left: none;
                width: 36px;
                padding-top: 4px;
                text-decoration: underline;
                text-decoration-color: ${COLORS.PRIMARY};
                font-weight: 300;
                font-size: 20px;
                line-height: 27px;
                svg {
                    height: 75%;  
                }
            }
            .ql-strike {
                border: 1px solid #E8E8E8;
                border-radius: 0 18px 18px 0;
                /* color: ${COLORS.PRIMARY}; */
                border-left: none;
                width: 36px;
                font-weight: 400;
                font-size: 21px;
                line-height: 27px;
                text-decoration-line: line-through;
                svg {
                    height: 75%;
                    rect {
                        fill: ${COLORS.PRIMARY};
                    }
                }
                
                &.ql-active {
                    path {
                        fill: ${COLORS.PRIMARY};
                    }
                }
            }

            .ql-unOrderList {
                margin-left: 24px;
                border: 1px solid #E8E8E8;
                border-radius: 18px 0 0 18px;
            }

            .ql-orderList {
                border: 1px solid #E8E8E8;
                border-radius: 0 18px 18px 0;
            }

            .ql-outdent {
                border: 1px solid #E8E8E8;
                border-radius: 0 18px 18px 0;
            }

            .ql-customIndent {
                margin-left: 24px;
                border: 1px solid #E8E8E8;
                border-radius: 18px 0 0 18px;
            }
        }

        #new-post-quill-content {
            border: 1px solid rgba(132, 132, 132, 0.4);
            border-radius: 8px;
            min-height: 120px;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE_376}px) {
                margin-top: 0.5rem;
            }
            .ql-container.ql-snow {
                border: none;
            }
        }
        .post-btn {
            width: 100%;
            max-width: 370px;
            margin-top: 1rem;
        }
        .posts-list-container {
            display: flex;
            flex-direction: column;

            .post-container {
                display: flex;
                padding: 30px;
                gap: 10px;
                align-items: center;
                &:nth-child(2n) {
                    background-color: rgba(232, 232, 232, 0.3);
                }
                .date-container {
                    /* width: 30%; */
                    min-width: 51px;
                    .month {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 23px;
                        text-align: center;
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                        color: ${COLORS.PRIMARY_DARK};
                    }
                    .date {
                        text-align: center;
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                        color: ${COLORS.PRIMARY_DARK};
                        font-size: 36px;
                    }
                    .time {
                        text-align: center;
                        /* font-family: ${FONTS.PRIMARY_SEMI_BOLD}; */
                        color: ${COLORS.PRIMARY_DARK};
                        font-weight: 400;
                        font-size: 12px;
                    }
                }
                .post-text-container {
                    flex-grow: 1;
                    align-items: center;
                    display: flex;
                    /* max-height: 60px; */
                    overflow: hidden;
                    flex-direction: column;
                    align-items: flex-end;
                    .text {
                        text-overflow: ellipsis;
                        /* text-align: justify; */
                        overflow: hidden;
                        line-height: 20px;
                        font-size: 14px;
                        max-height: 40px;
                    }
                    .more-btn {
                        font-family: ${FONTS.PRIMARY_MEDIUM};
                        color: ${COLORS.PRIMARY_DARK};
                        padding-left: 0;
                    }
                }
            }
        }
    }

`;