import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { TopbarWrapper } from './Topbar.style';

import TaoLogo from 'Assets/Logo/heart-in-circle.svg';
// import HeartLogo from 'Assets/images/Header/oneness-heart-logo.svg';
import { ReactComponent as BackIcon } from 'Assets/images/Common/gray-left-arrow.svg';
import { ReactComponent as ThumbsSVG } from 'Assets/images/MeditationContent/thumbs.svg';
import { ReactComponent as ThumbsSelectedSVG } from 'Assets/images/MeditationContent/thumbs-selected.svg';

import FavoriteIconAnimation from 'Components/common/FavoriteIconAnimation';
import ShareSocial from 'Components/common/Share/Share';

import DefaultProfileImagePlaceholder from 'Assets/images/User/profile-pic-placeholder.svg';

import { AppBar, Box, IconButton, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
    URL_LOGIN,
    SHOW_LOGIN_PAGE_HEADER,
    SHOW_QUESTIONNAIRE_PAGE_HEADER,
    SHOW_NORMAL_PAGE_HEADER,
    HIDE_PAGE_HEADERS,
    URL_SIGN_UP,
    URL_SEARCH,
    URL_MEDITATION_CONTENT,
    URL_MEDITATION_MEDIA_PLAYER,
    URL_POST_ASSESSMENT,
    URL_WELLNESS_CATEGORY,
    URL_HOW_TO_MEDITATE,
    URL_HOW_TO_MEDITATE_CONTENT,
    URL_HOW_TO_MEDITATE_MEDIA_PLAYER,
    URL_MEDITATION_READ,
    URL_PROFILE,
    URL_NOTIFICATIONS,
    URL_HISTORY,
    URL_FAVORITES,
    URL_JOURNAL,
    URL_MEDITATION_TIMER,
    URL_PLAY_MEDITATION,
    URL_PROGRAMS,
    URL_PROGRAMS_DETAILS,
    URL_PLAYLIST,
    URL_CONTENT_SEE_ALL,
    URL_CATEGORY_SEE_ALL,
    URL_STATISTICS,
    URL_PROFILE_VIEW,
    URL_PROGRAMS_SUGGESTIONS,
    URL_SALES_PAGE_ONE,
    URL_SALES_PAGE_TWO,
    URL_BEGIN_EXPERIENCE,
    URL_EXPERIENCE_MORE_VIDEO,
    URL_EXPERIENCE_MORE_GUIDED_AUDIO,
    URL_EXPERIENCE_MORE_MUSIC,
    URL_LANDING,
    URL_REFERRAL_STORE,
    URL_REFERRAL_PRODUCT_DETAILS,
    URL_ORDER_HISTORY,
    URL_YOUR_CART,
    URL_ORDER_DETAILS,
    URL_CHOOSE_PAYMENT_METHOD,
    URL_ORDER_SUCCESS
} from 'Helpers/Paths';
import MenuDrawer from './MenuDrawer';
import { updateMenu } from 'Redux/Sidebar/Actions';
import { handleTopBarInteractionDetail } from 'Redux/App/Actions';

import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { LIKE_DISLIKE_TYPE, ACCESS_LEVELS_MODULEs } from 'Helpers/Constants';
import usePermissions from 'Components/common/Permissions';

const SHOW_INTERACTION_TOOLBAR = [
    URL_SEARCH,
    URL_MEDITATION_CONTENT,
    URL_MEDITATION_MEDIA_PLAYER,
    URL_POST_ASSESSMENT,
    URL_WELLNESS_CATEGORY,
    URL_HOW_TO_MEDITATE,
    URL_HOW_TO_MEDITATE_CONTENT,
    URL_HOW_TO_MEDITATE_MEDIA_PLAYER,
    URL_MEDITATION_READ,
    URL_PROFILE,
    URL_NOTIFICATIONS,
    URL_HISTORY,
    URL_FAVORITES,
    URL_JOURNAL,
    URL_MEDITATION_TIMER,
    URL_PLAY_MEDITATION,
    URL_PROGRAMS,
    URL_PROGRAMS_DETAILS,
    URL_PLAYLIST,
    URL_CONTENT_SEE_ALL,
    URL_CATEGORY_SEE_ALL,
    URL_STATISTICS,
    URL_PROFILE_VIEW,
    URL_PROGRAMS_SUGGESTIONS,
    URL_REFERRAL_STORE,
    URL_REFERRAL_PRODUCT_DETAILS,
    URL_ORDER_HISTORY,
    URL_ORDER_DETAILS,
    URL_YOUR_CART,
    URL_CHOOSE_PAYMENT_METHOD,
    URL_ORDER_SUCCESS
];
const SHOW_PROFILE = [
    URL_SEARCH,
    URL_POST_ASSESSMENT,
    URL_WELLNESS_CATEGORY,
    URL_HOW_TO_MEDITATE,
    URL_PROFILE,
    URL_NOTIFICATIONS,
    URL_HISTORY,
    URL_FAVORITES,
    URL_JOURNAL,
    URL_MEDITATION_TIMER,
    URL_PROGRAMS,
    URL_PLAYLIST,
    URL_CONTENT_SEE_ALL,
    URL_CATEGORY_SEE_ALL,
    URL_STATISTICS,
    URL_PROFILE_VIEW,
    URL_PROGRAMS_SUGGESTIONS,
    URL_REFERRAL_STORE,
    URL_REFERRAL_PRODUCT_DETAILS,
    URL_ORDER_HISTORY,
    URL_ORDER_DETAILS,
    URL_YOUR_CART,
    URL_CHOOSE_PAYMENT_METHOD,
    URL_ORDER_SUCCESS
];
const SHOW_LIKE = [
    URL_MEDITATION_MEDIA_PLAYER,
    URL_PLAY_MEDITATION,
    URL_MEDITATION_CONTENT,
    URL_HOW_TO_MEDITATE_CONTENT
];
const SHOW_SHARE = [
    URL_MEDITATION_CONTENT,
    URL_MEDITATION_MEDIA_PLAYER,
    URL_HOW_TO_MEDITATE_CONTENT,
    URL_HOW_TO_MEDITATE_MEDIA_PLAYER,
    URL_MEDITATION_READ,
    URL_PLAY_MEDITATION,
    URL_SALES_PAGE_ONE,
    URL_SALES_PAGE_TWO,
    URL_BEGIN_EXPERIENCE,
    URL_EXPERIENCE_MORE_VIDEO,
    URL_EXPERIENCE_MORE_GUIDED_AUDIO,
    URL_EXPERIENCE_MORE_MUSIC
];
const SHOW_FAVORITE = [
    URL_MEDITATION_CONTENT,
    URL_MEDITATION_MEDIA_PLAYER,
    URL_PLAY_MEDITATION,
    URL_PROGRAMS_DETAILS,
    URL_HOW_TO_MEDITATE_CONTENT
];
const SHOW_BACK = [
    URL_SEARCH,
    URL_MEDITATION_CONTENT,
    URL_MEDITATION_MEDIA_PLAYER,
    URL_POST_ASSESSMENT,
    URL_WELLNESS_CATEGORY,
    URL_HOW_TO_MEDITATE,
    URL_HOW_TO_MEDITATE_CONTENT,
    URL_HOW_TO_MEDITATE_MEDIA_PLAYER,
    URL_MEDITATION_READ,
    URL_NOTIFICATIONS,
    URL_HISTORY,
    URL_FAVORITES,
    URL_JOURNAL,
    URL_MEDITATION_TIMER,
    URL_PLAY_MEDITATION,
    URL_PROGRAMS,
    URL_PROGRAMS_DETAILS,
    URL_PLAYLIST,
    URL_CONTENT_SEE_ALL,
    URL_CATEGORY_SEE_ALL,
    URL_STATISTICS,
    URL_PROFILE_VIEW,
    URL_REFERRAL_STORE,
    URL_REFERRAL_PRODUCT_DETAILS,
    URL_ORDER_HISTORY,
    URL_ORDER_DETAILS,
    URL_YOUR_CART,
    URL_CHOOSE_PAYMENT_METHOD,
    URL_ORDER_SUCCESS
];

const Topbar = () => {
    const favoritePermission = usePermissions(ACCESS_LEVELS_MODULEs?.ADD_FAVOURITE);
    const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
    const userDetails = useSelector((state) => state.Auth.userInfo);
    const showTopBarWithTransition = useSelector((state) => state.App.showTopBarWithTransition);
    const programOrContentDetail = useSelector((state) => state.App.interactionDetail);
    const hideInteractionButton = useSelector((state) => state.App.hideInteractionButton);
    const showTopBar = useSelector((state) => state.App.showTopBar);
    const dispatch = useDispatch();
    const [userMenuToggleAnchorEl, setUserMenuToggleAnchorEl] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [shareUrl, setShareUrl] = useState('/');
    const API = useMemo(() => isLoggedIn && new Api(), [isLoggedIn]);

    const contentSharePath = useCallback(() => {
        if (
            [URL_MEDITATION_CONTENT, URL_MEDITATION_MEDIA_PLAYER, URL_PLAY_MEDITATION].includes(
                location?.pathname
            )
        )
            return setShareUrl(`${URL_MEDITATION_CONTENT}?contentId=${programOrContentDetail?.id}`);

        if (
            [URL_HOW_TO_MEDITATE_CONTENT, URL_HOW_TO_MEDITATE_MEDIA_PLAYER].includes(
                location?.pathname
            )
        )
            return setShareUrl(
                `${URL_HOW_TO_MEDITATE_CONTENT}?contentId=${programOrContentDetail?.id}`
            );

        if (location?.pathname === URL_MEDITATION_READ)
            return setShareUrl(`${URL_MEDITATION_READ}?contentId=${programOrContentDetail?.id}`);

        return setShareUrl(window.location.pathname);
    }, [location?.pathname, programOrContentDetail?.id]);

    const favorite = async (isFavorite) => {
        let newProgramOrContentDetail = { ...programOrContentDetail };
        if (newProgramOrContentDetail.isFavorite === isFavorite) return;
        newProgramOrContentDetail.isFavorite = isFavorite;
        dispatch(handleTopBarInteractionDetail(newProgramOrContentDetail || {}));

        if (location?.pathname === URL_PROGRAMS_DETAILS) {
            await API.post(API_URL.PROGRAM_FAVORITE, {
                params: {
                    programId: programOrContentDetail?.programId
                }
            });
        } else {
            await API.post(API_URL.CONTENT_FAVOURITES, {
                params: {
                    contentId: programOrContentDetail?.id
                }
            });
        }
    };

    const likeDislike = async (isLike) => {
        if (!programOrContentDetail?.id) return;
        let newProgramOrContentDetail = { ...programOrContentDetail };
        if (newProgramOrContentDetail.isLiked === isLike) return;
        newProgramOrContentDetail.isLiked = isLike;
        dispatch(handleTopBarInteractionDetail(newProgramOrContentDetail || {}));

        await API.post(API_URL.CONTENT_LIKE_DISLIKE, {
            params: {
                contentId: programOrContentDetail?.id,
                type: isLike ? LIKE_DISLIKE_TYPE?.LIKE : LIKE_DISLIKE_TYPE?.DISLIKE
            }
        });
    };

    useEffect(() => {
        contentSharePath();
    }, [contentSharePath]);

    const redirectToHomePage = () => {
        dispatch(updateMenu('/'));
        navigate(URL_LANDING);
    };

    const toggleUserMenu = () => {
        setUserMenuToggleAnchorEl(!userMenuToggleAnchorEl);
    };

    return (
        <TopbarWrapper
            showTopBar={showTopBar}
            className={!showTopBarWithTransition && 'hide-topbar'}>
            {SHOW_LOGIN_PAGE_HEADER.includes(location.pathname) && !isLoggedIn ? (
                <AppBar position="static" classes={{ root: 'app-bar-root' }}>
                    <IconButton
                        onClick={redirectToHomePage}
                        className="header-logo-section tao-logo-icon">
                        <img src={TaoLogo} alt="" className="tao-logo" />
                        {/* <img src={HeartLogo} alt="" className="heart-logo" /> */}
                    </IconButton>

                    <Box className="buttons">
                        <Button
                            className="btn login"
                            onClick={() => {
                                navigate(URL_LOGIN);
                            }}>
                            login
                        </Button>
                        <Button
                            className="btn sign-up elipsis"
                            onClick={() => navigate(URL_SIGN_UP)}>
                            START FREE TRIAL
                        </Button>
                    </Box>
                </AppBar>
            ) : SHOW_QUESTIONNAIRE_PAGE_HEADER.includes(location.pathname) ? (
                <AppBar position="static" classes={{ root: 'app-bar-root' }}>
                    <IconButton
                        onClick={() => {
                            if (userDetails?.isQuestionnaireCompleted) redirectToHomePage();
                        }}
                        className="header-logo-section">
                        <img src={TaoLogo} alt="" className="tao-logo" />
                        {/* <img src={HeartLogo} alt="" className="heart-logo" /> */}
                    </IconButton>
                </AppBar>
            ) : SHOW_NORMAL_PAGE_HEADER.includes(location?.pathname) ? (
                <AppBar position="static" classes={{ root: 'app-bar-root' }}>
                    {!SHOW_INTERACTION_TOOLBAR?.includes(location?.pathname) && (
                        <>
                            <Box
                                onClick={redirectToHomePage}
                                className="header-logo-section flex f-v-center hover">
                                <img src={TaoLogo} alt="" className="tao-logo" />
                                {/* <img src={HeartLogo} alt="" className="heart-logo" /> */}
                            </Box>

                            <div className="second-section">
                                {isLoggedIn ? (
                                    <>
                                        <Box className="profile-overlay">
                                            <IconButton
                                                className="profile-container"
                                                onClick={toggleUserMenu}
                                                style={{
                                                    backgroundImage: `url(${
                                                        userDetails?.originalProfilePicUrl ||
                                                        DefaultProfileImagePlaceholder
                                                    })`
                                                }}
                                            />
                                        </Box>

                                        <MenuDrawer
                                            onClose={toggleUserMenu}
                                            open={userMenuToggleAnchorEl}
                                            userImage={
                                                userDetails?.originalProfilePicUrl ||
                                                DefaultProfileImagePlaceholder
                                            }
                                        />
                                    </>
                                ) : (
                                    <IconButton className="profile-container">
                                        <img src={DefaultProfileImagePlaceholder} alt="" />
                                    </IconButton>
                                )}
                            </div>
                        </>
                    )}
                    {/* TOOLBAR WITH BACK, LIKE, SHARE BUTTON */}
                    {SHOW_INTERACTION_TOOLBAR?.includes(location?.pathname) && (
                        <>
                            {SHOW_BACK?.includes(location?.pathname) && (
                                <IconButton
                                    onClick={() => {
                                        if ([URL_POST_ASSESSMENT].includes(location?.pathname)) {
                                            return navigate(-2);
                                        }
                                        if ([URL_PLAY_MEDITATION].includes(location?.pathname)) {
                                            return navigate(URL_PLAYLIST);
                                        }
                                        navigate(-1);
                                    }}
                                    className="header-logo-section back-arrow-icon">
                                    <Box className="white-circle-wrapper">
                                        <BackIcon className="back-icon" />
                                    </Box>
                                </IconButton>
                            )}

                            <div className="second-section">
                                <Box className="interaction-icons-wrapper">
                                    {SHOW_LIKE?.includes(location?.pathname) &&
                                        !location.state?.disableHeaderButton && (
                                            <Box className="white-circle-wrapper" id="like-icon-id">
                                                <IconButton
                                                    disabled={hideInteractionButton}
                                                    onClick={() =>
                                                        likeDislike(
                                                            !programOrContentDetail?.isLiked
                                                        )
                                                    }>
                                                    {programOrContentDetail?.isLiked ? (
                                                        <ThumbsSelectedSVG />
                                                    ) : (
                                                        <ThumbsSVG />
                                                    )}
                                                </IconButton>
                                            </Box>
                                        )}
                                    {SHOW_SHARE?.includes(location?.pathname) &&
                                        !hideInteractionButton && (
                                            <Box
                                                className="white-circle-wrapper"
                                                id="top-bar-share-id"
                                                height={48}
                                                width={48}>
                                                <ShareSocial
                                                    title={programOrContentDetail?.title}
                                                    url={`${window?.location?.protocol}//${window.location.host}${shareUrl}`}
                                                    details={programOrContentDetail}
                                                />
                                            </Box>
                                        )}
                                    {SHOW_FAVORITE?.includes(location?.pathname) &&
                                        !location.state?.disableHeaderButton && (
                                            <Box
                                                className={`white-circle-wrapper ${
                                                    !favoritePermission?.canAccess && 'disabled'
                                                }`}
                                                id="fav-icon-id"
                                                height={48}
                                                width={48}>
                                                <IconButton
                                                    disabled={
                                                        !favoritePermission?.canAccess ||
                                                        hideInteractionButton ||
                                                        !isLoggedIn
                                                    }
                                                    onClick={() =>
                                                        favorite(
                                                            !programOrContentDetail?.isFavorite
                                                        )
                                                    }>
                                                    <FavoriteIconAnimation
                                                        isFavorite={
                                                            programOrContentDetail?.isFavorite
                                                        }
                                                    />
                                                </IconButton>
                                            </Box>
                                        )}
                                </Box>
                                {isLoggedIn && SHOW_PROFILE?.includes(location?.pathname) && (
                                    <>
                                        <Box className="profile-overlay">
                                            <IconButton
                                                className="profile-container"
                                                onClick={toggleUserMenu}
                                                style={{
                                                    backgroundImage: `url(${
                                                        userDetails?.originalProfilePicUrl ||
                                                        DefaultProfileImagePlaceholder
                                                    })`
                                                }}
                                            />
                                        </Box>

                                        <MenuDrawer
                                            onClose={toggleUserMenu}
                                            open={userMenuToggleAnchorEl}
                                            userImage={
                                                userDetails?.originalProfilePicUrl ||
                                                DefaultProfileImagePlaceholder
                                            }
                                        />
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </AppBar>
            ) : HIDE_PAGE_HEADERS.includes(location.pathname) ? null : null}
        </TopbarWrapper>
    );
};

export default Topbar;
