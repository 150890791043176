import styled from 'styled-components';

import { FONTS, COLORS } from 'Styles/Constants';

export const TaoRoomCardWrapper = styled.div`
    width: 175px;
    height: 275px;
    background-color: ${COLORS.WHITE};
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    overflow: hidden;
    border: ${(props) => (props.$isLive ? `2px solid #BC1218` : `2px solid ${COLORS.LIGHT_GREY}`)};
    position: relative;

    &:hover {
        cursor: pointer;
        border-color: ${(props) => (props?.$isLive ? '#BC1218' : `${COLORS.PRIMARY}`)};
    }

    .badge {
        position: absolute;
        border-radius: 0 0 14px 0;
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        top: 0;
        left: 0;
        color: ${COLORS.WHITE};
        font-size: 14px;
        width: 55px;
        height: 22px;
        z-index: 5;

        &.live {
            background-color: ${COLORS.BADGE};
        }
        &.new {
            background-color: ${COLORS.PRIMARY_DARK};
        }
    }

    .fav-btn {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 6px;
        z-index: 5;
    }

    .room-image-container {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .black-shadow {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
            position: absolute;
            height: 45px;
            width: 100%;
            top: 0;

            &.bottom {
                height: 70%;
                bottom: 0;
                top: auto;
                transform: rotate(180deg);
            }
        }
    }

    .content-container {
        width: 100%;
        padding: 9px;
        gap: 5px;
        position: absolute;
        bottom: 0;

        .title {
            font-size: 16px;
            color: ${COLORS.WHITE};
            font-family: ${FONTS.PRIMARY_BOLD};
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            display: -webkit-box;
        }

        .follower-content {
            display: flex;
            justify-content: flex-start;
            gap: 2px;
            align-items: center;

            .person-icon {
                width: 20px;
                height: 20px;
            }
            .follow-text {
                font-size: 14px;
                color: ${COLORS.WHITE};
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            }
        }

        .rating-container {
            display: flex;
            align-items: center;
            height: 20px;
            margin: 10px 0 0;

            .rating-count {
                font-size: 14px;
                color: ${COLORS.WHITE};
                margin-left: 4px;
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            }
        }

        .status-container {
            display: flex;
            margin-top: 4px;
            align-items: center;
            .icon {
                width: 30px;
                height: 30px;
            }
            .text {
                margin-left: 5px;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                font-weight: 600;
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            }
        }
    }

    .not-approved-room-container {
        background-color: #bc1218;
        .title {
            color: white;
        }
        .status-container {
            .text {
                color: white;
            }
        }
    }

    .pending-room-container {
        background-color: #ffd26d;
        .title {
            color: #31290e;
        }
        .status-container {
            .text {
                color: #7d5416;
            }
        }
    }
`;
