import styled from 'styled-components';
import LoginBackground from 'Assets/images/Signup/login-background.webp';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const LoginWrapper = styled.div`
    background: url(${LoginBackground});
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;

    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        z-index: 10;
        align-items: center;
        height: 100%;
        overflow: auto;
        min-height: 600px;
    }

    .login-container {
        display: flex;
        flex-direction: column;
        width: 25%;
        min-height: 55%;

        ${responsive.DESKTOP`
            width: 35%;
        `}

        ${responsive.LAPTOP`
            width: 37%;
        `}

        ${responsive.TABLET`
            width: 55%;
        `}

        ${responsive.PHABLET`
            width: 70%;
        `}

        
        ${responsive.MOBILE`
            width: 90%;
            justify-content: space-between;
            height: calc(100vh - 90px);
        `}
    }

    .title {
        font-size: 40px;
        font-weight: 700;
        font-family: ${FONTS.SECONDARY};
        color: ${COLORS.PRIMARY_DARK};
    }

    .policy-div {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #656565;
    }

    .margin-bottom-spacing {
        margin-bottom: 10px;
    }

    .button {
        width: 100%;

        &:hover {
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
        }
    }

    .arrow-icon {
        margin-left: -35px;
        margin-right: 10px;
        margin-top: 7px;

        ${responsive.MOBILE`
            margin-left: 0;
        `}
    }

    .divider-container {
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        align-items: center;
        justify-content: center;
        color: #656565;
        ${responsive.MOBILE`
        color: #fff;
        `}
    }

    .login-options-container {
        background: rgba(232, 232, 232, 0.4);
        backdrop-filter: blur(10px);
        border-radius: 18px;
        padding: 5px;
        margin: 12px 0 0 0;
    }

    .divider {
        height: 2px;
        width: 30%;
        background: linear-gradient(to right, rgba(80, 85, 92, 0), rgba(80, 85, 92, 1)); //#8195b1

        ${responsive.MOBILE`
            background: linear-gradient(to right, rgba(255, 255, 255, 0) , rgba(255, 255, 255, 1));
        `}
    }

    .social-icon {
        padding: 12px 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .social-background {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 0 10px;

            ${responsive.PHABLET`
                margin: 0 5px;
            `}

            &.google-background {
                background-color: #fff;
            }
            &.facebook-background {
                background-color: #4267b2;
                overflow: hidden;
            }
            &.apple-background {
                background-color: #000;
            }
        }
    }

    .top-margin {
        ${responsive.MOBILE`
            margin-top: 20px;
        `}
    }

    .forget-password {
        color: ${COLORS.TEXT_DARK};
        cursor: pointer;
        margin: 30px 0 0 0;
        font-family: ${FONTS.PRIMARY};
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        text-decoration-line: underline;

        ${responsive.MOBILE`
            color: #fff;
        `}
    }

    .signup {
        color: ${COLORS.TEXT_DARK};
        margin: 0 0 0 5px;

        &.bold {
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        }

        &:hover {
            cursor: pointer;
        }

        ${responsive.MOBILE`
            color: #fff;
        `}
    }

    .signup-container {
        margin-top: min(34px, 5%);
    }

    .button-link {
        text-decoration: underline;
        text-transform: capitalize;
        padding: 0px;
        margin-left: -5px;
    }

    .button-color {
        background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
        border-radius: 12px;
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        color: ${COLORS.WHITE};
        margin: 5px 0 0;
        height: 60px;
        font-size: 16px;
    }

    .remember-wrapper {
        padding: 10px 0;

        .checkbox-root {
            color: ${COLORS.TEXT_DARK};
            height: 24px;
            width: 24px;
            padding: 0;
        }

        .remember-text {
            color: ${COLORS.TEXT_DARK};
            margin: 0 0 0 8px;
        }
    }

    .link {
        text-decoration: underline;
    }
`;
