import { Box } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

const ContentSeeAllWrapper = styled(Box)`
    padding: 60px 0 10px;
    height: 100vh;
    display: flex;
    justify-content: center;

    ${responsive.TABLET`
        padding: 60px 0 0;
    `}

    .container {
        width: 45%;

        ${responsive.LAPTOP`
            width: 60%;
        `}

        ${responsive.TABLET`
            width: 80%;
        `}

        .title {
            font-size: 40px;
            color: ${COLORS.PRIMARY_DARK};
            text-transform: capitalize;
            font-family: ${FONTS.SECONDARY_BOLD};
        }

        .update-text {
            color: ${COLORS.TEXT_DARK};
            margin: 0 0 0 auto;

            &:hover {
                cursor: pointer;
            }

            .indicator {
                margin: 0 0 0 2px;
                transition: all 0.5s;
            }
        }

        .chips {
            margin: 30px 0 0;
            display: flex;
            align-items: center;
            gap: 8px;

            .chip-root {
                height: 45px;
                border: 1.5px solid transparent;
                color: ${COLORS.MEDIUM_GREY};
                border-radius: 22px;
                font-size: 16px;
                text-transform: uppercase;
                background-color: ${COLORS.LIGHT_GREY_SECONDARY};
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};

                &.active {
                    border-color: ${COLORS.PRIMARY_DARK};
                    color: ${COLORS.PRIMARY_DARK};
                    background-color: ${COLORS.WHITE};
                }
            }
        }
    }
`;

export default ContentSeeAllWrapper;
