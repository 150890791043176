import React, { useState, useEffect } from 'react';

import {
    Typography,
    IconButton,
    Box,
    Button,
    Backdrop,
    TextField,
    Chip,
    Slide,
    Fade
} from '@mui/material';
import Lottie from 'lottie-react';
import Rating from 'react-rating';

import CountDownTimer from '../../../../common/CountTimers/CountdownTimer';
import CountUpTimer from '../../../../common/CountTimers/CountupTimer';
import Footer from 'Components/common/Footer/Footer';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import TaoCard from 'Components/common/TaoCard';

import { BREAKPOINTS_VALUE } from 'Styles/Constants';

import { ChatSection } from '../AudioSessionChat';
import { CustomParticipantsView } from '../AudioSessionParticipantView';
import { AddParticipantToSessionModal } from '../AddParticipantToSessionModalView';

import {
    AudioSessionParticipantViewWrapper,
    LeaveChatModalWrapper,
    FooterContentWrapper,
    FeedbackSectionWrapper,
    PostSessionSuggestionsWrapper
} from './ParticipantView.style';

import { BackButton } from 'Helpers/CommonSVGs';

// import ProfileImageUser from 'Assets/images/Header/profile-user-image.svg';
import DefaultProfileImagePlaceholder from 'Assets/images/User/profile-pic-placeholder.svg';
import AudioSessionHostImage from 'Assets/images/Profile/default-user-icon.jpg';
import UsersIcon from 'Assets/images/MeditationRoomSession/AudioSession/users-icon.png';

import YellowWaveFigure from 'Assets/images/Questionnaire/yellow-wave-figure.png';

import ReleaseHeadacheSliderImage from 'Assets/images/HomePage/release-headache-slider-image.png';
import ReleaseBackpainSliderImage from 'Assets/images/Questionnaire/sun-bg.png';
import PlayListCoverImage from 'Assets/images/HomePage/playlist-cover-image.png';

import HeartWaveform from 'Assets/AnimationFiles/heart-waveform-animated-loop.json';
import {
    AddToCalendarButton,
    AddToFavorite,
    AddUserIcon,
    ShareIcon,
    RightArrowButton,
    RemoveFromCalendarIcon,
    PencilIcon,
    StarOutlined,
    StarFilled
} from '../AudioSessionSVG';
import { ClearButtonIcon } from '../../AddEditSession/AddEditSessionSVG';
import { cloneDeep } from 'lodash';

import { useDispatch } from 'react-redux';
import { showTopBar, hideTopBar, showFooter, hideFooter } from 'Redux/App/Actions';
import { showSideBar, hideSideBar } from 'Redux/Sidebar/Actions';

import { getWindowDimensions } from 'Helpers';

const AudioSessionParticipantView = (props) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const dispatch = useDispatch();

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);

            dispatch(showSideBar());
            dispatch(showTopBar());
            dispatch(showFooter());
        };
    }, [dispatch]);

    useEffect(() => {
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            dispatch(hideSideBar());
            dispatch(hideTopBar());
            dispatch(hideFooter());
        }
    }, [dispatch, windowDimensions]);

    // const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
    const THREE_DAYS_IN_MS = 20 * 1000;
    const NOW_IN_MS = new Date().getTime();

    const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
    const [targetTime, setTargetTime] = useState(new Date(dateTimeAfterThreeDays));

    const [sessionState, setSessionState] = useState('yet-to-start');
    const [showCountdownTimer, setShowCountdownTimer] = useState(true);
    const [showJoinAsSection, setShowJoinAsSection] = useState(false);
    const [joinedSessionWith, setJoinedSessionWith] = useState(null);
    const [openChatModal, setOpenChatModal] = useState(false);
    const [openLeaveSessionModal, setOpenLeaveSessionModal] = useState(false);
    const [openAddParticipantToSessionModal, setOpenAddParticipantToSessionModal] = useState(false);
    const [openParticipantListModal, setOpenParticipantListModal] = useState(false);
    const [mainSessionState, setMainSessionState] = useState('in-session');

    const joinSessionNow = (sessionStateToUpdate) => {
        setSessionState(sessionStateToUpdate);

        if (sessionStateToUpdate === 'join-meeting-now') {
            setShowCountdownTimer(false);
            setShowJoinAsSection(true);
        } else {
            setShowCountdownTimer(sessionStateToUpdate === 'session-started' ? true : false);
            setShowJoinAsSection(false);
        }
    };

    const joinSessionWith = (joinSessionWith, sessionStateToUpdate) => {
        setShowJoinAsSection(false);
        setJoinedSessionWith(joinSessionWith);
        setSessionState(sessionStateToUpdate);

        const THREE_DAYS_IN_MS = 20 * 1000;
        const NOW_IN_MS = new Date().getTime();
        const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
        setTargetTime(new Date(dateTimeAfterThreeDays));

        setShowCountdownTimer(true);
    };

    const toggleOpenChatModal = (value) => {
        setOpenChatModal(value);
    };

    const toggleLeaveChatModalHandler = (value, mainState) => {
        setOpenLeaveSessionModal(value);

        if (mainState && mainState === 'session-end-feedback') {
            setMainSessionState(mainState);
        }
    };

    const toggleAddParticipantToSessionModalHandler = (value) => {
        setOpenAddParticipantToSessionModal(value);
    };

    const toggleParticipantListModal = (value) => {
        setOpenParticipantListModal(value);
    };

    const sessionFeedbackSave = (mainState) => {
        setMainSessionState(mainState);
    };

    const postSessionStateSave = (mainState) => {
        setMainSessionState(mainState);
    };

    return (
        <>
            <TaoCalligraphyWatermark />
            <AudioSessionParticipantViewWrapper>
                <Box className="left-side-gradient" />
                {mainSessionState === 'in-session' && (
                    <Box className="host-and-session-details">
                        {windowDimensions.width <= BREAKPOINTS_VALUE.TABLET && (
                            <Box className="back-button">
                                <BackButton />
                            </Box>
                        )}

                        <Box className="session-details-section">
                            <Box className="host-details">
                                <img
                                    className="host-image"
                                    src={AudioSessionHostImage}
                                    alt=""
                                    height={
                                        windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET_500
                                            ? 120
                                            : 165
                                    }
                                    width={
                                        windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET_500
                                            ? 120
                                            : 165
                                    }
                                />
                                <Box className="session-details">
                                    <Typography className="session-title">
                                        Breathwork for a Clear Mind
                                    </Typography>
                                    <Typography className="session-by">WITH SUNIL</Typography>

                                    {sessionState === 'joined-meeting' ||
                                    sessionState === 'after-timer-completed' ||
                                    sessionState === 'session-joined' ? (
                                        <Box
                                            className="joined-user-details"
                                            onClick={() =>
                                                toggleParticipantListModal(
                                                    !openParticipantListModal
                                                )
                                            }>
                                            <img src={UsersIcon} alt="" />
                                            <Typography className="total-count">15</Typography>
                                            <Typography className="view-text">VIEW</Typography>
                                        </Box>
                                    ) : (
                                        <>
                                            <Typography className="session-time">
                                                <span className="session-date">Nov 27</span> 7:30 -
                                                8:30 AM
                                            </Typography>
                                            <Typography className="session-language">
                                                English with German Translation
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            </Box>

                            {((!openChatModal && !openParticipantListModal) ||
                                windowDimensions.width <= BREAKPOINTS_VALUE.LAPTOP) && (
                                <Box className="session-description">
                                    <Box className="session-breadcrumb">
                                        <Typography>PEAK PERFORMANCE</Typography>
                                        <span> {`>`} </span>
                                        <Typography>PRODUCTIVITY</Typography>
                                    </Box>
                                    <Typography className="session-description-text">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Elementum lacinia luctus risus pharetra dui elementum
                                        ornare. Arcu ut interdum ullamcorper potenti. Sed tellus
                                        egestas et nunc lacus.
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        {showCountdownTimer && (
                            <>
                                <Box className="session-timer">
                                    <Typography>
                                        {sessionState === 'session-started'
                                            ? 'Session Started'
                                            : 'Starts IN'}
                                    </Typography>

                                    {(sessionState === 'yet-to-start' ||
                                        sessionState === 'joined-meeting') && (
                                        <CountDownTimer
                                            targetDate={targetTime}
                                            completeCountDownTimer={() =>
                                                joinSessionNow(
                                                    sessionState === 'yet-to-start'
                                                        ? 'session-started'
                                                        : 'after-timer-completed'
                                                )
                                            }
                                        />
                                    )}
                                </Box>

                                {(sessionState === 'yet-to-start' ||
                                    sessionState === 'session-started') && (
                                    <Button
                                        variant="contained"
                                        className="join-now-button"
                                        onClick={() => joinSessionNow('join-meeting-now')}>
                                        JOIN NOW
                                    </Button>
                                )}
                            </>
                        )}

                        {showJoinAsSection && (
                            <>
                                <Box className="join-as-section">
                                    <Button
                                        className="join-as-actual"
                                        onClick={() => joinSessionWith('actual', 'joined-meeting')}>
                                        <img src={DefaultProfileImagePlaceholder} alt="" width={'50px'} height={"51px"} />
                                        <Box className="name-section">
                                            <Typography className="join-as-text">
                                                Join as
                                            </Typography>
                                            <Typography className="profile-to-join">
                                                Francisco
                                            </Typography>
                                        </Box>
                                        <IconButton>
                                            <RightArrowButton />
                                        </IconButton>
                                    </Button>
                                    <Button
                                        className="join-as-guest"
                                        onClick={() => joinSessionWith('guest', 'joined-meeting')}>
                                        <img src={DefaultProfileImagePlaceholder} alt="" />
                                        <Box className="name-section">
                                            <Typography className="join-as-text">
                                                Join as
                                            </Typography>
                                            <Typography className="profile-to-join">
                                                Guest
                                            </Typography>
                                        </Box>
                                        <IconButton>
                                            <RightArrowButton />
                                        </IconButton>
                                    </Button>
                                </Box>
                            </>
                        )}

                        {sessionState === 'after-timer-completed' && (
                            <Box
                                className="waiting-for-host-text"
                                onClick={() => setSessionState('session-joined')}>
                                <Typography>WAITING FOR HOST</Typography>
                            </Box>
                        )}

                        {sessionState === 'session-joined' && (
                            <Box className="live-session-details">
                                <Lottie
                                    className="heart-wave-animation"
                                    loop
                                    autoplay
                                    animationData={HeartWaveform}
                                />
                                <Typography className="live-session-text">LIVE</Typography>

                                <CountUpTimer />
                            </Box>
                        )}

                        {(sessionState === 'joined-meeting' ||
                            sessionState === 'after-timer-completed' ||
                            sessionState === 'session-joined') && (
                            <Box className="session-waiting-to-start-section">
                                <Button
                                    className="user-details"
                                    onClick={() => {
                                        openChatModal
                                            ? toggleOpenChatModal(false)
                                            : toggleOpenChatModal(true);
                                    }}>
                                    <img
                                        src={
                                            joinedSessionWith === 'actual'
                                                ? DefaultProfileImagePlaceholder
                                                : DefaultProfileImagePlaceholder
                                        }
                                        alt=""
                                    />

                                    <Typography
                                        className="open-chat-button"
                                        sx={{ marginRight: openChatModal ? '' : '20px' }}>
                                        {openChatModal ? 'CLOSE CHAT' : `CHAT (13)`}
                                    </Typography>

                                    {openChatModal && (
                                        <Box
                                            className="close-chat-icon"
                                            onClick={() => toggleOpenChatModal(false)}>
                                            <ClearButtonIcon />
                                        </Box>
                                    )}
                                </Button>
                                <Button
                                    className="leave-session-button"
                                    onClick={() => toggleLeaveChatModalHandler(true)}>
                                    <Typography>Leave</Typography>
                                </Button>
                            </Box>
                        )}

                        <Box className="actions-section">
                            {sessionState === 'joined-meeting' ||
                            sessionState === 'after-timer-completed' ||
                            sessionState === 'session-joined' ? (
                                <Box className="action-icon">
                                    <IconButton>
                                        <RemoveFromCalendarIcon />
                                    </IconButton>
                                </Box>
                            ) : (
                                <Box className="action-icon">
                                    <IconButton>
                                        <AddToCalendarButton />
                                    </IconButton>
                                </Box>
                            )}

                            <Box className="action-icon">
                                <IconButton>
                                    <AddToFavorite />
                                </IconButton>
                            </Box>

                            <Box className="action-icon">
                                <IconButton>
                                    <ShareIcon />
                                </IconButton>
                            </Box>

                            <Box className="action-icon">
                                <IconButton
                                    onClick={() => toggleAddParticipantToSessionModalHandler(true)}>
                                    <AddUserIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                )}

                {mainSessionState === 'session-end-feedback' && (
                    <SessionEndFeedbackSection
                        mainSessionState={mainSessionState}
                        sessionFeedbackSave={(event) => sessionFeedbackSave(event)}
                    />
                )}

                {mainSessionState === 'post-session-suggestions' && (
                    <PostSessionSuggestions
                        mainSessionState={mainSessionState}
                        postSessionSuggestionClose={(event) => postSessionStateSave(event)}
                    />
                )}

                <Box className="bottom-side-gradient" />
            </AudioSessionParticipantViewWrapper>
            <FooterContentWrapper>
                <Footer showWhiteBackground={false} topBorderColor="gray" />
            </FooterContentWrapper>

            <LeaveSessionModal
                openLeaveSessionModal={openLeaveSessionModal}
                toggleLeaveChatModalHandler={(event, mainState) =>
                    toggleLeaveChatModalHandler(event, mainState)
                }
            />

            <AddParticipantToSessionModal
                openAddParticipantToSessionModal={openAddParticipantToSessionModal}
                toggleAddParticipantToSessionModalHandler={(event) =>
                    toggleAddParticipantToSessionModalHandler(event)
                }
            />

            <CustomParticipantsView
                openParticipantListModal={openParticipantListModal}
                closeModal={(event) => toggleParticipantListModal(event)}
                hostView={false}
            />

            <ChatSection
                openChatModal={openChatModal}
                closeModal={(event) => toggleOpenChatModal(event)}
            />
        </>
    );
};

const LeaveSessionModal = (props) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <LeaveChatModalWrapper
            open={props.openLeaveSessionModal}
            onClose={() => props.toggleLeaveChatModalHandler(false)}
            aria-labelledby="leave-session-modal-title"
            aria-describedby="leave-session-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}>
            <Slide
                in={props.openLeaveSessionModal}
                direction={windowDimensions.width <= BREAKPOINTS_VALUE.TABLET ? 'up' : 'right'}
                timeout={{ enter: 300, exit: 300 }}>
                <Box className="leave-chat-modal">
                    <Box className="close-modal-button">
                        <IconButton onClick={() => props.toggleLeaveChatModalHandler(false)}>
                            <ClearButtonIcon />
                        </IconButton>
                    </Box>
                    <Box className={`leave-session-content`}>
                        <Typography className="session-title">
                            Breathwork for a clear mind
                        </Typography>
                        <Typography className="confirmation">Leave Session?</Typography>
                    </Box>
                    <Box className="leave-session-action-buttons">
                        <Button
                            variant="contained"
                            onClick={() =>
                                props.toggleLeaveChatModalHandler(false, 'session-end-feedback')
                            }>
                            YES
                        </Button>
                        <Button
                            className="no-button"
                            variant="outlined"
                            onClick={() => props.toggleLeaveChatModalHandler(false)}>
                            NO
                        </Button>
                    </Box>
                </Box>
            </Slide>
        </LeaveChatModalWrapper>
    );
};

const SessionEndFeedbackSection = (props) => {
    const [feelingChips, setFeelingsChips] = useState([
        { key: 1, value: 'Relaxed', selected: false },
        { key: 2, value: 'Energized', selected: false },
        { key: 3, value: 'Happier', selected: false },
        { key: 4, value: 'Calmer', selected: false },
        { key: 5, value: 'Lighter', selected: false },
        { key: 6, value: 'Less Pain', selected: false }
    ]);

    const selectFeelingsChip = (selectedChip) => {
        const copyData = cloneDeep(feelingChips);

        const index = copyData.findIndex((chip) => chip.key === selectedChip.key);
        copyData[index].selected = !copyData[index].selected;

        setFeelingsChips(copyData);
    };

    return (
        <Fade
            in={props.mainSessionState === 'session-end-feedback'}
            timeout={{ enter: 300, exit: 300 }}>
            <FeedbackSectionWrapper>
                <img className="yellow-wave-figure" src={YellowWaveFigure} alt="" />

                <Typography className="thanks-for-joining">Thanks for Joining!</Typography>
                <Typography className="your-experience">
                    You and your experience matter to us.
                    <Box className="star-rating">
                        <Rating emptySymbol={<StarOutlined />} fullSymbol={<StarFilled />} />
                    </Box>
                </Typography>
                <Typography className="rate-experience">Please rate your expeirence</Typography>

                <Typography className="i-feel-text">I Feel...</Typography>
                <Box className="feeling-chips">
                    {feelingChips.map((feelings) => {
                        return (
                            <Chip
                                className={
                                    'feeling-chip-value large ' +
                                    (feelings.selected ? 'selected-chip' : 'not-selected-chip')
                                }
                                key={feelings.key}
                                size="medium"
                                color="primary"
                                variant={feelings.selected ? 'filled' : 'outlined'}
                                label={feelings.value}
                                onClick={() => selectFeelingsChip(feelings)}
                            />
                        );
                    })}
                </Box>

                <TextField
                    className="other-feeling-input"
                    id="outlined-basic"
                    label="Or type in a word"
                    variant="outlined"
                />
                <Box className="action-buttons">
                    <Button
                        variant="contained"
                        className="save-and-close-button"
                        onClick={() => props.sessionFeedbackSave('post-session-suggestions')}>
                        {'Save & Close'}
                    </Button>
                    <Button
                        variant="outlined"
                        className="save-and-journal-button"
                        onClick={() => props.sessionFeedbackSave('post-session-suggestions')}>
                        {'Save & Journal'}
                        <IconButton>
                            <PencilIcon />
                        </IconButton>
                    </Button>
                </Box>

                <Typography
                    className="skip-for-now"
                    onClick={() => props.sessionFeedbackSave('post-session-suggestions')}>
                    Skip for Now
                </Typography>
            </FeedbackSectionWrapper>
        </Fade>
    );
};

const PostSessionSuggestions = (props) => {
    const [PostSessionSuggestionTopicChips, setPostSessionSuggestionTopicChips] = useState([
        { key: 2, value: 'Meditations', selected: true },
        { key: 1, value: 'Sessions', selected: false },
        { key: 3, value: 'Rooms', selected: false }
    ]);

    const selectTopicChip = (selectedChip) => {
        const copyData = cloneDeep(PostSessionSuggestionTopicChips);

        copyData.map((value) => {
            if (value.key === selectedChip.key) {
                value.selected = !value.selected;
            } else {
                value.selected = false;
            }
            return value;
        });

        setPostSessionSuggestionTopicChips(copyData);
    };

    const SuggestionContent = [
        { backgroundImage: ReleaseHeadacheSliderImage, type: 'video', title: 'Release Headache' },
        { backgroundImage: ReleaseBackpainSliderImage, type: 'video', title: 'Release Back Pain' },
        { backgroundImage: PlayListCoverImage, type: 'video', title: 'Morning Freshen Up' },
        { backgroundImage: PlayListCoverImage, type: 'video', title: 'Sound Sleep' },
        { backgroundImage: ReleaseHeadacheSliderImage, type: 'video', title: 'Release Headache' }
    ];

    return (
        <Slide
            in={props.mainSessionState === 'post-session-suggestions'}
            direction="up"
            timeout={{ enter: 300, exit: 300 }}>
            {/* <Backdrop
                sx={{ backgroundColor: "rgb(255 255 255 / 50%)", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={props.mainSessionState === 'post-session-suggestions'}
            > */}
            <PostSessionSuggestionsWrapper>
                <Box
                    className="close-modal-button"
                    onClick={() => props.postSessionSuggestionClose('in-session')}>
                    <IconButton>
                        <ClearButtonIcon />
                    </IconButton>
                </Box>

                <Box className="suggestions-content">
                    <Typography className="continue-journey-text">
                        Continue your journey into
                    </Typography>
                    <Typography className="category-name">Peak Performance</Typography>

                    <Box className="topics">
                        {PostSessionSuggestionTopicChips.map((value) => {
                            return (
                                <Chip
                                    className={
                                        'topic-chip-value large ' +
                                        (value.selected ? 'selected-chip' : 'not-selected-chip')
                                    }
                                    key={value.key}
                                    size="medium"
                                    color="primary"
                                    variant={value.selected ? 'outlined' : 'filled'}
                                    label={value.value}
                                    onClick={() => selectTopicChip(value)}
                                />
                            );
                        })}
                    </Box>

                    <Box className="suggestion-content-data">
                        {SuggestionContent.map((value) => {
                            return (
                                <TaoCard
                                    cardRootClass="tao-card"
                                    backgroundImage={value.backgroundImage}
                                    mediaTextType="primary"
                                    type={value.type}
                                    title={value.title}
                                />
                            );
                        })}
                    </Box>

                    <Button
                        variant="contained"
                        className="return-home-button"
                        onClick={() => props.postSessionSuggestionClose('in-session')}>
                        Return Home
                    </Button>
                </Box>
            </PostSessionSuggestionsWrapper>
            {/* </Backdrop> */}
        </Slide>
    );
};

export default AudioSessionParticipantView;
