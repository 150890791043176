import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { ResetPasswordWrapper } from '../ResetPassword.style';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ReactComponent as Email } from 'Assets/images/Signup/email.svg';
import { useLocation, useNavigate } from 'react-router-dom';

import { getWindowDimensions } from 'Helpers';
import { URL_LOGIN } from 'Helpers/Paths';

const ResetPasswordEmailSent = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <ResetPasswordWrapper screen={windowDimensions} component="email">
            <div className="container">
                <div className="reset-password-container">
                    <div className="margin-bottom-spacing flex f-v-center">
                        <div className="arrow-icon">
                            <ArrowBackIosIcon
                                onClick={() => navigate(URL_LOGIN)}
                                style={{ color: '#848484' }}
                            />
                        </div>
                        <div>
                            <Typography className="reset-password-text hide">
                                Reset Password
                            </Typography>
                        </div>
                    </div>

                    <div className="icon-text">
                        <Email />

                        <div className="title2">Email sent</div>
                    </div>

                    <div
                        className="description flex f-v-center align-center"
                        style={{ marginTop: '20px', flexDirection: 'column' }}>
                        <div>
                            <Typography className="text-center">
                                We have sent reset instructions to{' '}
                                <Typography className="email">{location?.state?.email}</Typography>
                            </Typography>
                        </div>

                        <div style={{ marginTop: '10px' }}>
                            <Typography>Check your email!</Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image" />
        </ResetPasswordWrapper>
    );
};

export default ResetPasswordEmailSent;
