import { Box } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS } from 'Styles/Constants';

export const TopicWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 15px;

    .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .heading {
            font-family: ${FONTS.PRIMARY};
            font-weight: 600;
            font-size: 24px;
            line-height: 35px;
            text-transform: capitalize;
            color: ${COLORS.PRIMARY_DARK};
        }
    }

    .selected-category-section {
        display: flex;
        align-items: center;

        .heading {
            font-family: ${FONTS.PRIMARY};
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: ${COLORS.MEDIUM_GREY};
        }

        .chip-container {
            display: flex;
            flex-direction: row;

            .chip {
                font-family: ${FONTS.PRIMARY};
                padding: 5px 15px;
                font-weight: 400;
                border-radius: 15px;
                margin-left: 10px;
                background: ${COLORS.MEDIUM_GREY};
                font-size: 14px;
                line-height: 20px;
                text-transform: capitalize;
                color: ${COLORS.WHITE};
            }
        }
    }

    .topic-count-container {
        display: flex;
        align-items: center;

        .count {
            font-family: ${FONTS.PRIMARY};
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: ${COLORS.MEDIUM_GREY};
        }

        .text {
            font-family: ${FONTS.PRIMARY};
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            text-transform: lowercase;
            color: ${COLORS.MEDIUM_GREY};
            margin-left: 10px;
        }
    }

    .topic-selection-container {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        align-items: center;

        .categories {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: max-content;
            height: 130px;
            /* gap: 10px; */

            .icon-button {
                .icon {
                    width: 80px;
                    height: 80px;
                }
            }

            .text {
                width: 80px;
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                text-align: center;
                color: ${COLORS.MEDIUM_GREY};
            }
        }
    }

    .topic-button-container {
        display: flex;
        justify-content: center;
        margin-top: auto;

        .submit-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 0px;
            gap: 10px;
            width: 100%;
            background: ${COLORS.WHITE};
            box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
            border-radius: 12px;

            .text {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
                text-align: right;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: ${COLORS.TEXT_DARK};
            }
        }
    }
`;
