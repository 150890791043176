import { Box } from '@mui/system';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import MeditationShadeBackground from 'Assets/images/Common/shade-pic.svg';

export const HistoryWrapper = styled(Box)`
    height: 100%;
    padding: 100px 0 15px;
    min-height: 700px;

    ${responsive.TABLET`
        padding: 60px 0 0;
    `}

    .content-wrapper {
        width: 55%;
        margin: 0 auto;
        gap: 18px;
        height: 100%;

        ${responsive.TABLET`
            width: 100%;
        `}
        .title-wrapper {
            gap: 10px;

            ${responsive.TABLET`
                margin: 0 0 0 13px;
            `}
            .history-title {
                font-family: ${FONTS.SECONDARY_SEMI_BOLD};
                font-size: 40px;
                text-transform: capitalize;
                color: ${COLORS.PRIMARY_DARK};
            }
        }

        .chip-section {
            height: 50px;
            width: calc(100% + 100px);
            position: relative;

            ${responsive.TABLET`
                width: 100%;
            `}

            .light-shadow {
                position: absolute;
                right: 101px;
                top: -102px;
                height: 250px;
                width: 45px;
                background-image: url(${MeditationShadeBackground});
                background-repeat: no-repeat;
                background-size: 100% 100%;
                mix-blend-mode: multiply;
                user-select: none;
                /* opacity: 0.8; */
                transform: rotate(90deg);
                pointer-events: none;
                ${responsive.TABLET`
                    display: none;
                `}
            }
            .history-chip {
                margin: 0 10px 0 0;
                padding: 10px 20px;
                height: 43px;
                border-radius: 22px;
                &:last-child {
                    margin: 0;
                }
                ${responsive.TABLET`
                    &:first-child {
                        margin: 0 10px 0 21px;
                    }
                `}
            }
            .chip-unselected {
                background: ${COLORS.LIGHT_GREY_SECONDARY};
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                text-transform: uppercase;
                color: ${COLORS.MEDIUM_GREY};
            }
            .chip-selected {
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                text-transform: uppercase;
                color: ${COLORS.PRIMARY_DARK};
                border-color: ${COLORS.PRIMARY_DARK};
                background: #fff;
            }
        }

        .card-section {
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            min-height: 400px;
            padding: 15px 0 0 20px;
            height: 100%;
            overflow: hidden;

            ${responsive.TABLET`
                box-shadow: none;
                padding: 0;
            `}
        }
    }
`;
