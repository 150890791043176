import styled from 'styled-components';
import LoginBackground from 'Assets/images/Signup/login-background.webp';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const VerifyEmailWrapper = styled.div`
    &.theme {
        position: relative;

        &::before {
            z-index: 1;
            content: '';
            position: absolute;
            opacity: 0.6;
            background: url(${LoginBackground});
            width: 100%;
            height: 100vh;
            overflow: hidden;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        align-items: center;
        height: 100vh;
        overflow: auto;
        z-index: 10;

        .content {
            width: max-content;
            height: 50%;
            display: flex;
            align-items: flex-start;

            .arrow-parent {
                display: flex;
                align-items: center;
                height: 60px;
                width: max-content;

                ${responsive.PHABLET`
                    position: absolute;
                `}
            }
            .data {
                margin: 0 0 0 48px;

                ${responsive.PHABLET`
                    margin: 0;
                    padding: 0 20px;
                `}

                .title {
                    font-size: 40px;
                    font-family: ${FONTS.SECONDARY_SEMI_BOLD};
                    color: ${COLORS.PRIMARY_DARK};
                    height: 60px;
                }

                .email {
                    color: ${COLORS.TEXT_DARK};
                    font-family: ${FONTS.PRIMARY};
                    font-size: 20px;
                    margin: 50px 0 0;
                }
            }

            .fields {
                margin: 50px 0 0;
                display: flex;
                justify-content: space-between;

                .root {
                    width: 50px;
                    border-bottom: 1px solid ${COLORS.PRIMARY};
                    border-color: transparent;
                    margin: 0 10px;

                    ${responsive.PHABLET`
                        width: 25px;
                    `}

                    &.error {
                        border-bottom: 1px solid ${COLORS.BADGE};
                    }
                    input {
                        text-align: center;
                        font-size: 24px;

                        ${responsive.PHABLET`
                            font-size: 18px;
                        `}
                    }
                }

                .input-root {
                    border-bottom: none;
                    color: ${COLORS.BADGE};

                    ::before {
                        border-bottom: none;
                    }
                }
            }
            .invalid-otp {
                color: ${COLORS.BADGE};
                text-align: center;
                margin: 20px 0 0;
                font-size: 20px;

                ${responsive.PHABLET`
                    font-size: 16px;
                `}
            }

            .links {
                display: flex;
                justify-content: center;
                margin: 70px 0 0;

                .divider {
                    height: 27px;
                    margin: 0 20px;
                    background-color: ${COLORS.TEXT_DARK};
                }

                .link {
                    text-decoration: underline;
                    font-family: ${FONTS.PRIMARY};
                    font-size: 20px;
                    color: ${COLORS.TEXT_DARK};
                    cursor: pointer;
                }
            }
        }
    }
`;
