import styled from 'styled-components';
import { Box, Modal } from '@mui/material';
import { COLORS, FONTS, responsive, BREAKPOINTS_VALUE, } from 'Styles/Constants';

export const MyRoomsWrapper = styled(Box)`
    position: relative;
    margin-top: 1rem;
    margin-left: 1rem;
    display: flex;
    min-height: 375px;
    @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
        margin-left: 0;
    }

    .image-container {
        background-image: ${(props) => `url(${props.profilePic})`};
        background-repeat: no-repeat;
        background-size: cover;
        width: 40%;
        height: 112%;
        max-width: 425px;
        max-height: 425px;
        position: absolute;
        display: flex;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.DESKTOP}px) {
            width: 35%;
            height: 100%;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP}px) {
            /* width: 35%; */
            height: 95%;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP}px) {
            /* width: 35%; */
            height: 95%;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
            display: none;
        }
        .blur-layer {
            background: linear-gradient(90deg,transparent,transparent, transparent, transparent,#FFFFFF);
            width: 100%;
            height: 100%;
            pointer-events: none;
        }
    }

    .room-list-container {
        position: absolute;
        width: 78%;
        right: -4.5%;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.DESKTOP}px) {
            width: 73%;
            right: -6%;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP}px) {
            /* width: 35%; */
            width: 73%;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
            width: 70%;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
            position: static;
            width: 100%;
        }

        .list-title {
            display: flex;
            flex-direction: column;
            gap: 13px;
            min-height: 375px;
            .welcome-txt {
                font-weight: 500;
                font-size: 24px;
                line-height: 35px;
                text-transform: capitalize;
                font-family: 'Jost Medium';
                color: #31290E;
                padding-left: 2.75rem;
            }
            .suggestion-text {
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                color: #848484;
                padding-left: 2.75rem;
            }
            .suggestion-box {
                width: 180px;
                height: 285px;
                display: flex;
                flex-direction: row;
                gap: 10px;
                filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
                border-radius: 16px;
                padding: 0.9rem;
                padding-bottom: 2rem;
                background-color: ${COLORS.PRIMARY};
                margin-left: 2.8rem;
                .box-text {
                    margin-top: auto;
                    color: white;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 25px;
                    font-family: ${FONTS.PRIMARY};
                    strong {
                        display: block;
                        font-weight: 600;
                        font-family: ${FONTS.PRIMARY_BOLD};
                    }
                }
            }

            .rooms-listing-container {
                display: flex;
                gap: 12px;
                min-width: 100%;
                max-height: 290px;
                min-height: 290px;
                .icon-container {
                    opacity: 0.7;
                    display: flex;
                    align-items: center;
                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
                        display: none;
                        right: 0;
                    }
                    .icon {
                        cursor: pointer;
                    }
                }

                .room-list {
                    max-width: 100%;
                    overflow: hidden;
                    display: flex;
                    .tao-room-card {
                        display: flex;
                        flex-direction: column;
                        min-height: 285px;
                        .content-container {
                            flex-grow: 1;
                        }
                    }

                    .slider-content {
                    position: relative;
                    margin-bottom: 50px;

                    ${responsive.PHABLET`
                        margin-bottom: 30px;
                    `}
                    }

                    .slick-slider {
                    overflow: hidden;
                    .slick-list {
                        min-height: 287px;
                    }

                    ${responsive.PHABLET`
                        margin-left: 15px;
                    `}
                    }

                    .slick-center {
                    transform: scale(1.2);
                    }

                    .slick-slide {
                    display: none;
                    float: left;
                    height: 100%;
                    min-height: 1px;
                    margin-left: 0.7rem;
                    }

                    .slick-initialized .slick-slide {
                    display: block;
                    }
                }
            }
        }
    }
`;

export const RoomStatusModalWrapper = styled(Modal)`
    &.MuiModal-root {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .MuiBackdrop-root {
        background-color: rgba(255, 255, 255, 0.4);
    }
    .dialog-container {
        width: 70%;
        position: relative;
        max-width: 916px;
        min-height: 75%;
        padding-bottom: 1rem;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
        border-radius: 24px;
        z-index: 20;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET}px) {
            width: 85%;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
            width: 95%;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE}px) {
            width: 100%;
        }
        .close-btn {
            right: 35px;
            top: 30px;
            position: absolute;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_700}px) {
                right: 6px;
                top: 20px;
            }
        }
        .content-container {
            width: 45%;
            max-width: 370px;
            min-width: 325px;
            height: 80%;
            display: flex;
            flex-direction: column;
            padding: 0 8px;
            @media only screen and (max-width: ${BREAKPOINTS_VALUE.MOBILE_376}px) {
                padding: 0 8px;
            }
            &.not-app-modal {
                width: 65%;
                max-width: 445px;
            }
            .title {
                /* max-width: 60%; */
                font-size: 30px;
                letter-spacing: -0.01em;
                color: #31290E;
                margin-top: 32px;
                line-height: 1;
            }
            /* pending modal only */
            .request-text-container {
                margin-top: 32px;
            }
            /* pending modal only */
            .tao-back-btn {
                margin-top: 32px;
            }

            /* pending modal only */
            .dates-container {
                margin-top: 10px;
                .date-text {
                    margin-top: 4px;
                    color: #656565;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 23px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    p {
                        font-size: 14px;
                    }
                }
            }

            .text-container {
                margin-top: 18px;
                display: flex;
                align-items: center;
                gap: 4px;
                margin-top: 19px;
                .not-approved-title {
                    color: #31290E;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                }
                .danger-text {
                    position: relative;
                    color: #31290E;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 30px;
                    .red-line {
                        width: 100%;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        border: 2px solid #bc1218c9;
                    }
                }
            }

            .message-container {
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin-top: 19px;
                .title-text {
                    color: #31290E;
                    font-family: ${FONTS.PRIMARY_MEDIUM};
                    font-size: 18px;
                    line-height: 26px;
                }
                .message {
                    color: #31290E;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    max-height: 110px;
                    overflow-y: auto;
                    overflow-x: hidden;
                }
            }

            .resubmit-container {
                margin-top: 29px;
                .title-text {
                    color: #31290E;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                }
                .resubmit-date {
                    /* margin-top: 2px; */
                    color: #31290E;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 30px;
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    .time {
                        font-weight: 400;
                        font-size: 18px;
                    }
                }
                .edit-room-btn {
                    margin-top: 8px;
                }
                .option-text {
                    color: #31290E;
                    font-size: 18px;
                    line-height: 26px;
                    margin-top: 32px;
                }
                .contact-btn {
                    background: white;
                    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
                    color: #31290E;
                    margin-top: 10px;
                }
            }
        }
    }
`;