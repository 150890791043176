import React from 'react';
import TaoCalligraphyWatermarkImage from 'Assets/Logo/calligraphy-watermark.png';
import { TaoCalligraphyWatermarkWrapper } from './TaoCalligraphyWatermark.style';

const TaoCalligraphyWatermark = (props) => {
    return (
        <TaoCalligraphyWatermarkWrapper {...props}>
            <img style={props.imageStyles} src={TaoCalligraphyWatermarkImage} alt="" />
        </TaoCalligraphyWatermarkWrapper>
    );
};

export default TaoCalligraphyWatermark;
