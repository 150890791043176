import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import TransparentBackgroundImage from 'Assets/images/Common/rating-background.png';

export const PostAssessmentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;

    .content-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .image-container {
            width: 100%;
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;

            .text-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                height: 60%;
                width: 40%;
                text-align: left;
                position: relative;
                margin: 0 auto;
                min-height: 400px;
                align-items: center;

                ${responsive.TABLET`
                    width: 60%;
                `};

                ${responsive.PHABLET`
                    width: 90%;
                `}

                .header-title {
                    font-family: ${FONTS.SECONDARY};
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 42px;
                    text-transform: capitalize;
                    color: ${COLORS.PRIMARY_DARK};
                    width: max-content;
                    height: max-content;
                    position: absolute;
                    transform: translate(0, -60px);
                }

                .mascot {
                    position: absolute;
                    bottom: 0;

                    &.hide {
                        display: none;
                    }

                    .lottie-animation {
                        position: relative;
                        z-index: 30;
                        transform: translate(0%, 38%);

                        /* FORCE UPDATE FOR MASCOT LOTTIE FILE */
                        svg {
                            width: 400px !important;
                            height: 400px !important;

                            ${responsive.PHABLET`
                                width: 300px !important;
                                height: 300px !important;
                            `}
                        }
                    }
                }
            }

            .experience-details-container {
                width: 100%;
                height: 40%;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                min-height: 500px;

                .gold-shadow {
                    background-image: url(${TransparentBackgroundImage});
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-position: top center;
                    z-index: 10;
                }

                .content {
                    z-index: 40;
                    position: initial;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &.apply-padding {
                        padding: 5% 0 20px;

                        ${responsive.PHABLET`
                            padding: 10% 0 20px;
                        `}

                        ${responsive.MOBILE`
                            padding: 15% 0 20px;
                        `}
                    }

                    .init-tab {
                        display: block;

                        &.hide {
                            display: none;
                        }

                        .content-description {
                            width: 250px;
                            margin: 0 auto;

                            .level-description {
                                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                                font-size: 16px;
                                text-align: center;
                                color: ${COLORS.BLACK};
                                text-transform: uppercase;

                                ${responsive.PHABLET`
                                    align-self: flex-start;
                                `}
                            }

                            .program-description {
                                font-family: ${FONTS.PRIMARY};
                                font-size: 24px;
                                text-align: center;
                                color: ${COLORS.PRIMARY_WARM};
                                text-transform: uppercase;
                            }

                            .program-name {
                                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                                font-size: 24px;
                                text-align: center;
                                color: ${COLORS.PRIMARY_WARM};
                                text-transform: uppercase;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }
                        }

                        .mood-container {
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            z-index: 10;
                            margin: 30px 0 0;

                            ${responsive.MOBILE`
                                max-width: 300px;
                            `}

                            .rating-margin {
                                margin: 10px 5px;

                                &.down-opacity {
                                    opacity: 0.5;
                                }

                                ${responsive.PHABLET`
                                    margin: 10px 3px;
                                `}
                            }

                            .pain-text {
                                font-family: ${FONTS.PRIMARY};
                                font-size: 16px;
                                text-align: center;
                                color: ${COLORS.MEDIUM_GREY};
                                text-transform: capitalize;
                            }

                            .program-pain-text {
                                font-family: ${FONTS.TERTIARY};
                                font-size: 16px;
                                text-align: center;
                                color: ${COLORS.PRIMARY_WARM};
                                text-transform: capitalize;
                            }
                        }
                        .continue-btn {
                            width: 380px;
                            margin: 20px 0 0;

                            ${responsive.PHABLET`
                                width: 100%;
                            `}
                        }
                    }

                    .assessment-content {
                        width: max-content;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        transform: translate(0px, -30%);

                        &.hide {
                            display: none;
                        }

                        .exp-title {
                            font-size: 24px;
                            color: ${COLORS.PRIMARY_DARK};
                            font-weight: 600;
                        }

                        .mobile-filter-input {
                            width: 80%;
                            height: 45px;
                            background: rgba(255, 255, 255, 0.66);
                            border: 0.1px solid rgba(132, 132, 132, 0.4);
                            backdrop-filter: blur(24.973px);
                            border-radius: 6px;
                        }
                        .input {
                            padding: 10px 8px;
                            font-size: 18px;
                            color: ${COLORS.TEXT_DARK};
                        }
                        .chips-box {
                            margin: 20px 0;
                            width: 380px;
                            height: 165px;

                            ${responsive.PHABLET`
                                width: 90%;
                            `}

                            .goal-chip {
                                padding: 4px 8px;
                                font-family: ${FONTS.PRIMARY};
                                font-size: 16px;
                                height: 46px;
                                border-radius: 21px;
                                text-transform: capitalize;
                                border: 2px solid ${COLORS.PRIMARY};
                                margin: 5px;
                                width: 180px;

                                ${responsive.PHABLET`
                                    width: 120px;
                                `}
                                &.non-selected-goal {
                                    background-color: ${COLORS.WHITE};
                                    color: ${COLORS.TEXT_DARK};
                                }

                                &.selected-goal {
                                    border: 2px solid ${COLORS.PRIMARY};
                                    background-color: ${COLORS.PRIMARY};
                                    color: ${COLORS.WHITE};
                                }
                            }
                        }
                        .assessment-btn-container {
                            margin: 20px 0 0;
                            width: 100%;

                            .save-close-btn {
                                background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                                color: ${COLORS.WHITE};
                                height: 60px;
                                border-radius: 12px;
                                width: 100%;
                            }

                            .save-journal-btn {
                                width: 100%;
                                height: 60px;
                                background: ${COLORS.WHITE};
                                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
                                border-radius: 12px;
                                margin: 15px 0 0;
                            }

                            .btn-text {
                                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                                font-size: 16px;
                                line-height: initial;
                            }
                        }
                    }
                    .bottom-section {
                        position: absolute;
                        bottom: 0;
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        .dot {
                            border-radius: 50%;
                            width: 12px;
                            height: 12px;
                            background-color: #d9d9d9;
                            margin: 0 5px;

                            &.active {
                                background-color: ${COLORS.PRIMARY_DARK};
                            }
                        }

                        .skip-text {
                            font-family: ${FONTS.PRIMARY};
                            font-size: 16px;
                            text-align: center;
                            color: ${COLORS.MEDIUM_GREY};
                            margin: 20px 0;
                        }
                    }
                }
            }
        }
    }
`;
