import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { YourCartWrapper } from './YourCart.style';
import { useDispatch } from 'react-redux';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import { ReferralShadowBoxLayout } from '../Referral.style';
import { Box } from '@mui/system';
import { Button, Checkbox, IconButton, Typography } from '@mui/material';
import { ReactComponent as SelectedIcon } from 'Assets/images/Profile/filter-selected-icon.svg';
import { ReactComponent as DeleteIcon } from 'Assets/images/Journal/delete.svg';
import { motion, useAnimation, useMotionValue } from 'framer-motion';
import StoreItems from '../StoreItems';
import { ReactComponent as DeleteWhiteIcon } from 'Assets/images/Journal/delete-white-color.svg';
import { useNavigate } from 'react-router-dom';
import { API_URL, URL_CHOOSE_PAYMENT_METHOD } from 'Helpers/Paths';
import Api from 'Helpers/ApiHandler';
import ContentLoader from 'react-content-loader';
import { DeleteConfirmation } from 'Components/common/Modal/Delete';

const EnoughPoints = ({ availableRewardPoints = 0 }) => (
    <Typography className="reward-points">
        Yes! You have <b>{availableRewardPoints} Reward Points</b> available. See the order total on
        the next screen.
    </Typography>
);

const NotEnoughPoints = ({ availableRewardPoints = 0 }) => (
    <Typography className="reward-points not-enough-points">
        You have <b>{availableRewardPoints ?? 0} Reward Points</b> available. You may modify your
        order or pay by credit/debit card.
    </Typography>
);

const CartItem = ({ item, cartItems, index, setAllSelected, setData, onDelete }) => {
    const x = useMotionValue(0);
    const controls = useAnimation();

    const handleDragEnd = async (event, info) => {
        if (info.offset.x < -100) {
            await controls.start({
                x: '-100px',
                transition: { duration: 0.3 }
            });
            // onDelete(item);
        }
    };

    return (
        <motion.div
            drag="x"
            style={{ x }}
            dragConstraints={{ left: 0, right: 0 }}
            onDragEnd={handleDragEnd}
            animate={controls}
            initial={{ x: 0 }}
            className="swipe-to-delete">
            <Box className="flex cart-item f-v-center">
                <Box className="delete-swipe-content">
                    <Box className="delete-icon flex f-v-center f-h-center">
                        <DeleteWhiteIcon
                            className="hover"
                            onClick={async () => {
                                await controls.start({
                                    x: '-100%',
                                    transition: { duration: 0.3 }
                                });
                                onDelete(item);
                            }}
                        />
                    </Box>
                </Box>
                <Checkbox
                    classes={{ root: 'checkbox-root' }}
                    checked={item?.isSelected ? true : false}
                    onChange={() => {
                        let newCartItems = [...cartItems];
                        newCartItems[index].isSelected = !newCartItems[index].isSelected;
                        let allSelectedOrNot = newCartItems.filter(
                            (cartItem) => cartItem?.isSelected
                        );
                        setAllSelected(cartItems?.length === allSelectedOrNot?.length);
                        setData((prev) => ({ ...prev, cartItemList: newCartItems }));
                    }}
                />
                <Box className="item flex f-v-center">
                    <Box className="image" style={{ backgroundImage: `url(${item?.itemImage})` }}>
                        <Typography className="duration">{item?.availableDays}</Typography>
                    </Box>
                    <Box className="item-info flex f-column">
                        <Typography className="cart-item-title">{item?.itemName}</Typography>
                        <Typography className="price">
                            {item?.currencySymbol}
                            {item?.price}
                        </Typography>
                        <Typography className="delivery elipsis">{item?.deliveryStatus}</Typography>
                    </Box>
                    <IconButton className="delete-icon" onClick={() => onDelete(item)}>
                        <DeleteIcon />
                    </IconButton>
                    {cartItems?.length !== index + 1 && <Box className="divider" />}
                </Box>
            </Box>
        </motion.div>
    );
};

const PointsLoading = (props) => {
    return (
        <ContentLoader
            speed={2}
            height={140}
            width="100%"
            backgroundColor="#dfdfdf"
            foregroundColor="#ecebeb"
            preserveAspectRatio="none"
            {...props}>
            <rect x="0" y="0" rx="4" ry="4" width="45%" height="36" />
            <rect x="55%" y="0" rx="4" ry="4" width="45%" height="36" />
            <rect x="0" y="56" rx="8" ry="8" width="100%" height="64" />
        </ContentLoader>
    );
};

const CartItemLoading = (props) => {
    return [...Array(2).keys()].map((item) => (
        <Box className="flex" key={item}>
            <ContentLoader
                speed={2}
                height={160}
                backgroundColor="#dfdfdf"
                foregroundColor="#ecebeb"
                preserveAspectRatio="none"
                {...props}>
                <rect x="0" y="68" rx="4" ry="4" width="24" height="24" />
                <rect x="44" y="15" rx="6" ry="6" width="130" height="130" />
            </ContentLoader>
            <ContentLoader
                speed={2}
                width="100%"
                height={160}
                backgroundColor="#dfdfdf"
                foregroundColor="#ecebeb"
                preserveAspectRatio="none"
                {...props}>
                <rect x="10" y="15" rx="4" ry="4" width="90%" height="24" />
                <rect x="10" y="49" rx="6" ry="6" width="90%" height="24" />
                <rect x="10" y="83" rx="6" ry="6" width="90%" height="24" />
                <rect x="10" y="117" rx="6" ry="6" width="90%" height="24" />
            </ContentLoader>
        </Box>
    ));
};

const ButtonLoading = () => {
    return (
        <ContentLoader
            speed={2}
            height={60}
            width="100%"
            backgroundColor="#dfdfdf"
            foregroundColor="#ecebeb"
            preserveAspectRatio="none">
            <rect x="0" y="0" rx="8" ry="8" width="100%" height="60" />
        </ContentLoader>
    );
};

const DeleteCartItemConfirmation = ({
    onClose,
    onDeleteConfirm,
    isMultipleSelection,
    ...props
}) => {
    return (
        <DeleteConfirmation
            title={
                isMultipleSelection
                    ? 'Are you sure you want to remove selected items from cart?'
                    : 'Are you sure you want to remove this item from cart?'
            }
            onClose={() => onClose()}
            onConfirm={() => onDeleteConfirm()}
        />
    );
};

const YourCart = () => {
    const dispatch = useDispatch();
    const [allSelected, setAllSelected] = useState(false);
    const navigate = useNavigate();
    const API = useMemo(() => new Api(), []);
    const [data, setData] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [deleteCartItemConfirmation, setDeleteCartItemConfirmation] = useState(false);
    const [deleteItems, setDeleteItems] = useState([]);

    const fetchCartDetails = useCallback(async () => {
        setLoading(true);
        let response = await API.get(API_URL.CART_LIST);

        if (response) {
            setData(response?.data?.data);
            setLoading(false);
        }
    }, [API]);

    const selectOrUnSelectCartItem = () => {
        let newCartItems = [...data?.cartItemList];

        newCartItems = newCartItems.map((item) => ({
            ...item,
            isSelected: allSelected ? false : true
        }));

        setData((prev) => ({ ...prev, cartItemList: newCartItems }));
        setAllSelected(!allSelected);
    };

    const deleteCartItem = async (ids) => {
        setLoading(true);
        await API.delete(API_URL.ADD_PRODUCT_INTO_CART, {
            data: {
                cartItemIds: ids
            }
        });
        fetchCartDetails();
    };

    const onDelete = (item) => {
        setDeleteItems((prev) => [...prev, item]);
        setDeleteCartItemConfirmation(true);
    };

    const deleteSelected = () => {
        let deleteIds = [];

        [...data?.cartItemList].forEach((item) => {
            if (item?.isSelected || allSelected) deleteIds.push(item);
        });
        if (!deleteIds?.length) return;
        setDeleteItems(deleteIds);
        setDeleteCartItemConfirmation(true);
    };

    const onDeleteConfirm = () => {
        setDeleteCartItemConfirmation(false);
        let newCartItems = [...data?.cartItemList];
        let deleteIds = [];
        deleteItems.forEach((item) => {
            let removedItem = newCartItems.findIndex(
                (cartItem) => cartItem?.cartItemId === item?.cartItemId
            );
            deleteIds.push(newCartItems[removedItem]?.cartItemId);
            newCartItems.splice(removedItem, 1);
        });
        setData((prev) => ({ ...prev, cartItemList: newCartItems }));
        setDeleteItems([]);
        deleteCartItem(deleteIds);
    };

    useEffect(() => {
        dispatch(hideSideBar());
        fetchCartDetails();
        return () => dispatch(showSideBar());
    }, [dispatch, fetchCartDetails]);

    return (
        <React.Fragment>
            {deleteCartItemConfirmation && (
                <DeleteCartItemConfirmation
                    onClose={() => {
                        setDeleteItems([]);
                        setDeleteCartItemConfirmation(false);
                    }}
                    onDeleteConfirm={onDeleteConfirm}
                    isMultipleSelection={deleteItems?.length > 1}
                />
            )}
            <TaoCalligraphyWatermark />
            <YourCartWrapper
                $cartHasItem={isLoading ? true : data?.cartItemList?.length ? true : false}>
                <ReferralShadowBoxLayout>
                    <Box className="title">Your Cart</Box>
                    <Box className="container">
                        <Typography className="sub-title">Items</Typography>
                        <Box className="filters flex">
                            <Button
                                className="filter-btn"
                                startIcon={<SelectedIcon />}
                                disabled={!data?.cartItemList?.length}
                                classes={{ disabled: 'btn-disabled' }}
                                onClick={() => selectOrUnSelectCartItem()}>
                                {allSelected && data?.cartItemList?.length ? 'Deselect' : 'Select'}{' '}
                                All
                            </Button>
                            <Button
                                className="filter-btn"
                                startIcon={<DeleteIcon />}
                                disabled={!data?.cartItemList?.length}
                                classes={{ disabled: 'btn-disabled' }}
                                onClick={() => deleteSelected()}>
                                Delete Selected
                            </Button>
                        </Box>
                        <Box className="scrollable-area">
                            {isLoading ? (
                                <CartItemLoading />
                            ) : (
                                data?.cartItemList?.map((item, index) => (
                                    <React.Fragment key={item?.cartItemId}>
                                        <CartItem
                                            item={item}
                                            index={index}
                                            cartItems={data?.cartItemList || []}
                                            setAllSelected={setAllSelected}
                                            setData={setData}
                                            onDelete={onDelete}
                                        />
                                    </React.Fragment>
                                ))
                            )}

                            {isLoading ? (
                                <Box className="item-total">
                                    <PointsLoading />
                                </Box>
                            ) : (
                                !!data?.cartItemList?.length && (
                                    <Box className="item-total">
                                        <Box className="flex f-h-space-between f-v-center">
                                            <Typography className="key">Subtotal</Typography>
                                            <Typography className="value">
                                                ${data?.subTotalPrice}{' '}
                                                <span className="or">OR</span>{' '}
                                                {data?.totalPriceInPoint} Pts
                                            </Typography>
                                        </Box>

                                        {data?.availableRewardPoints < data?.totalPriceInPoint ? (
                                            <NotEnoughPoints
                                                availableRewardPoints={data?.availableRewardPoints}
                                            />
                                        ) : (
                                            <EnoughPoints
                                                availableRewardPoints={data?.availableRewardPoints}
                                            />
                                        )}
                                    </Box>
                                )
                            )}

                            {!data?.cartItemList?.length && !isLoading && (
                                <React.Fragment>
                                    <Box className="empty-cart">
                                        <Typography className="empty-cart-title">
                                            Your Cart is Empty
                                        </Typography>
                                        <Box className="divider" />
                                    </Box>

                                    <StoreItems
                                        title="Find What You’re Looking For"
                                        data={data?.storeProductResponseList || []}
                                    />
                                </React.Fragment>
                            )}
                        </Box>

                        {isLoading ? (
                            <ButtonLoading />
                        ) : (
                            !!data?.cartItemList?.length && (
                                <Button
                                    className="checkout-button"
                                    endIcon={<Box className="arrow" />}
                                    onClick={() =>
                                        navigate(URL_CHOOSE_PAYMENT_METHOD, {
                                            state: {
                                                data
                                            }
                                        })
                                    }>
                                    Checkout
                                </Button>
                            )
                        )}
                    </Box>
                </ReferralShadowBoxLayout>
            </YourCartWrapper>
        </React.Fragment>
    );
};

export default YourCart;
