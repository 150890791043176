import React, { useEffect, useMemo, useState } from 'react';
import { PlaylistManipulationModalWrapper } from './PlaylistManipulationModal.style';
import {
    Box,
    IconButton,
    Typography,
    TextField,
    Divider,
    Button,
    FormHelperText
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import PlusIcon from 'Assets/images/PlayList/filled-plus-icon.svg';
import GripLines from 'Assets/images/PlayList/grip-lines.svg';
import CrossIcon from 'Assets/images/PlayList/cross-icon.svg';
import PlayButton from 'Assets/images/Common/play-button.svg';
import SliderPreviousButton from 'Assets/images/Common/previous-arrow.svg';
import SelectionPage from 'Components/Pages/PlayList/PlaylistManipulationModal/MeditationSelection/SelectionPage.jsx';
import TaoScrollbar from 'Components/common/Scrollbar';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { cloneDeep } from 'lodash';
import { convertHoursMinsSecondsToReadableString, getWindowDimensions } from 'Helpers';
import { useDispatch } from 'react-redux';
import { showSideBar, hideSideBar } from 'Redux/Sidebar/Actions';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { showToast } from 'Redux/App/Actions';
import { PlayListMediaImg } from '../Playlist.style';
import { SubscriptionLightLock } from 'Components/common/SubscriptionLock';

const PLAYLIST_ADD_EDIT_ERROR_MSG = 'Please select at least one playlist';
const PLAYLIST_ADD_EDIT_TITLE_MSG = 'Please enter your list name';

const PlaylistManipulationModal = ({
    visible,
    onClose,
    isEdit,
    details,
    playListId,
    setsIsLoading,
    isLoading,
    setResetPlaylist,
    ...props
}) => {
    const dispatch = useDispatch();
    const API = useMemo(() => new Api(), []);
    const [showAddModal, setShowAddModal] = useState(false);
    const [playListName, setPlayListName] = useState('');
    const [deletedContentIds, setDeletedContentIds] = useState([]);
    const [requestType, setRequestType] = useState('');
    const [selectedPlaylistDetails, setSelectedPlaylistDetails] = useState([]);
    const [playlistError, setPlaylistError] = useState({ name: false });
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [selectedPlaylistItem, setSelectedPlaylistItem] = useState([]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        if (windowDimensions.width < BREAKPOINTS_VALUE.TABLET) {
            dispatch(hideSideBar());
        }
        return () => {
            window.removeEventListener('resize', handleResize);
            dispatch(showSideBar());
        };
    }, [dispatch, windowDimensions]);

    const inputHandler = (event) => {
        setPlaylistError((prev) => {
            return { ...prev, name: false };
        });
        setPlayListName(event.target.value);
    };

    const onDrag = (result) => {
        if (result?.destination?.index || result?.destination?.index === 0) {
            if (result?.destination?.index === result?.source?.index) {
                return;
            }
            const playlistItem = [...selectedPlaylistItem];
            const reOrderedItem = playlistItem.splice(result?.source?.index, 1)[0];
            playlistItem.splice(result?.destination?.index, 0, reOrderedItem);
            playlistItem.map((item, index) => (item.contentOrder = index + 1));

            setSelectedPlaylistItem(playlistItem);
        } else {
            return;
        }
    };

    const onDragEnd = (result) => {
        if (result?.destination?.index || result?.destination?.index === 0) {
            if (result?.destination?.index === result?.source?.index) {
                return;
            }
            const playlistItem = [...selectedPlaylistDetails];
            const reOrderedItem = playlistItem.splice(result?.source?.index, 1)[0];
            playlistItem.splice(result?.destination?.index, 0, reOrderedItem);

            setSelectedPlaylistDetails(playlistItem);
        } else {
            return;
        }
    };

    const openAddModal = (type) => {
        setRequestType(type);
        setShowAddModal(true);
    };

    const closeAddModal = () => {
        setRequestType('');
        setShowAddModal(false);
    };

    const submitHandler = async () => {
        if (isEdit) {
            let data = {
                id: playListId,
                title: playListName,
                contentDetails: selectedPlaylistItem.map((item) => {
                    return { contentId: item.id, contentOrder: item.contentOrder };
                }),
                deletedContentIds: deletedContentIds
            };
            if (!playListName || !data.contentDetails.length) {
                if (!data.title.replace(/\s+/g, '')) {
                    setPlaylistError((prev) => {
                        return { ...prev, name: true };
                    });
                    return;
                }
                if (!data.contentDetails.length) {
                    dispatch(showToast(PLAYLIST_ADD_EDIT_ERROR_MSG, 'info'));
                }
                return;
            }
            onClose();
            setsIsLoading(true);
            const response = await API.put(API_URL.EDIT_PLAYLIST, { data });

            if (response) {
                dispatch(showToast(response?.data?.message, 'success'));
                setResetPlaylist((prev) => !prev);
                setsIsLoading(false);
                return;
            }
        } else {
            let requestData = selectedPlaylistDetails.map((value) => {
                return {
                    contentId: value?.id,
                    contentOrder: value?.order
                };
            });
            let data = {
                title: playListName,
                contentDetails: requestData
            };
            if (!data.title || !data.contentDetails.length) {
                if (!data.title.replace(/\s+/g, '')) {
                    setPlaylistError((prev) => {
                        return { ...prev, name: true };
                    });
                    return;
                }
                if (!data.contentDetails.length) {
                    dispatch(showToast(PLAYLIST_ADD_EDIT_ERROR_MSG, 'info'));
                }
                return;
            }
            onClose();
            setsIsLoading(true);

            let response = await API.post(API_URL.ADD_PLAYLIST, { data: data });

            if (response) {
                setSelectedPlaylistDetails([]);
                setResetPlaylist((prev) => !prev);
                dispatch(showToast(response?.data?.message, 'success'));
                setsIsLoading(false);
                setPlayListName('');
            }
        }
    };

    const deleteHandler = (contentId, index) => {
        if (isEdit) {
            let tempDeleteContentIds = [...deletedContentIds];
            tempDeleteContentIds.push(contentId);
            setDeletedContentIds(tempDeleteContentIds);

            let tempDetails = cloneDeep(selectedPlaylistItem);
            tempDetails.splice(index, 1);
            setSelectedPlaylistItem(tempDetails);
        } else {
            let tempSelectedPlaylistDetails = [...selectedPlaylistDetails];
            tempSelectedPlaylistDetails.splice(index, 1);
            setSelectedPlaylistDetails(tempSelectedPlaylistDetails);
        }
    };

    useEffect(() => {
        details?.playListName && setPlayListName(details?.playListName);
        details?.playListName &&
            setPlaylistError((prev) => {
                return { ...prev, name: false };
            });
        details?.list?.length && setSelectedPlaylistItem(details?.list);
    }, [details?.list, details?.playListName]);

    const handleModelClose = () => {
        onClose();
        if (isEdit) {
            setSelectedPlaylistItem(details?.list);
        }
    };

    const handleNewDataInEdit = (data) => {
        if (isEdit) {
            let tempPlaylistItem = [...selectedPlaylistItem];
            tempPlaylistItem = tempPlaylistItem.concat(data);
            tempPlaylistItem = [
                ...new Map(tempPlaylistItem.map((item) => [item['id'], item])).values()
            ];
            tempPlaylistItem.map((item, index) => {
                if (item?.contentOrder) {
                    return (item.contentOrder = index + 1);
                } else {
                    return (item['contentOrder'] = index + 1);
                }
            });
            setSelectedPlaylistItem([
                ...new Map(tempPlaylistItem.map((item) => [item['id'], item])).values()
            ]);
        }
    };

    return (
        <>
            <PlaylistManipulationModalWrapper
                fullScreen={windowDimensions.width < BREAKPOINTS_VALUE.TABLET ? true : false}
                open={visible}
                maxWidth="md"
                PaperProps={{
                    classes: {
                        rounded: `${
                            windowDimensions.width >= BREAKPOINTS_VALUE.TABLET && 'rounded-popup'
                        } `
                    }
                }}
                onClose={handleModelClose}>
                {!isLoading && (
                    <Box className="main-container">
                        <Box className="heading-container">
                            <IconButton
                                onClick={handleModelClose}
                                sx={{ width: '40px', height: '40px' }}>
                                <img src={SliderPreviousButton} alt="" />
                            </IconButton>
                            <Typography className="heading">
                                {isEdit && isEdit ? 'Edit Playlist' : 'Create Playlist'}
                            </Typography>
                        </Box>
                        <Box className="input-container">
                            <TextField
                                variant="standard"
                                className="input"
                                placeholder="Name Your List"
                                label={isEdit ? 'List Name' : ''}
                                value={playListName}
                                InputProps={{ disableUnderline: true }}
                                onChange={inputHandler}
                            />
                        </Box>
                        {playlistError.name && (
                            <FormHelperText error>{PLAYLIST_ADD_EDIT_TITLE_MSG}</FormHelperText>
                        )}
                        <Box className="playlist-container">
                            <Typography className="heading">Meditate & Manifest</Typography>
                            <Box className="scroll-container">
                                {!isEdit && (
                                    <TaoScrollbar displayScroll>
                                        <Box className="playlist-item">
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable droppableId={'droppable'}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}>
                                                            {selectedPlaylistDetails.map(
                                                                (value, index) => (
                                                                    <Draggable
                                                                        key={value.id}
                                                                        draggableId={
                                                                            value.contentName
                                                                        }
                                                                        index={index}>
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={
                                                                                    provided.innerRef
                                                                                }
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                key={index}
                                                                                className="list">
                                                                                <Box className="list-item">
                                                                                    <Box className="list-sub-item">
                                                                                        <IconButton
                                                                                            disabled>
                                                                                            <img
                                                                                                className="grip-icon"
                                                                                                src={
                                                                                                    GripLines
                                                                                                }
                                                                                                alt=""
                                                                                            />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                    <Box className="image-container">
                                                                                        {/* <img
                                                                                            src={
                                                                                                value?.thumbnailImage
                                                                                            }
                                                                                            className="list-image"
                                                                                            alt=""
                                                                                        /> */}
                                                                                        {!value
                                                                                            ?.subscription
                                                                                            ?.isAccessible &&
                                                                                            value
                                                                                                ?.subscription
                                                                                                ?.badge && (
                                                                                                <Box className="lock-content">
                                                                                                    <SubscriptionLightLock
                                                                                                        height={
                                                                                                            14
                                                                                                        }
                                                                                                    />
                                                                                                </Box>
                                                                                            )}
                                                                                        <PlayListMediaImg
                                                                                            img={
                                                                                                value?.thumbnailImage
                                                                                            }
                                                                                            height={
                                                                                                '60px'
                                                                                            }
                                                                                            width={
                                                                                                '100px'
                                                                                            }
                                                                                            radius={
                                                                                                '12px 4px'
                                                                                            }
                                                                                        />
                                                                                        <IconButton
                                                                                            sx={{
                                                                                                position:
                                                                                                    'absolute'
                                                                                            }}>
                                                                                            <img
                                                                                                src={
                                                                                                    PlayButton
                                                                                                }
                                                                                                alt=""
                                                                                                className="play-icon"
                                                                                            />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                    <Box className="play-description">
                                                                                        <Typography className="title">
                                                                                            {
                                                                                                value?.contentName
                                                                                            }
                                                                                        </Typography>
                                                                                        {!!value
                                                                                            ?.categoryDetailsList
                                                                                            .length && (
                                                                                            <Typography className="affected-part">
                                                                                                {value
                                                                                                    ?.categoryDetailsList[0]
                                                                                                    ?.mainCategory +
                                                                                                    ' > ' +
                                                                                                    value
                                                                                                        ?.categoryDetailsList[0]
                                                                                                        ?.subCategory}
                                                                                            </Typography>
                                                                                        )}
                                                                                        <Typography className="time">
                                                                                            {convertHoursMinsSecondsToReadableString(
                                                                                                value?.contentDuration
                                                                                            )}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box className="button-container flex f-h-center">
                                                                                        <IconButton
                                                                                            className="icon-button"
                                                                                            onClick={() =>
                                                                                                deleteHandler(
                                                                                                    value.id,
                                                                                                    index
                                                                                                )
                                                                                            }>
                                                                                            <img
                                                                                                className="grip-icon"
                                                                                                src={
                                                                                                    CrossIcon
                                                                                                }
                                                                                                alt=""
                                                                                            />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                </Box>
                                                                                <Divider />
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            )}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                            {!isEdit && (
                                                <Box
                                                    className="list-item add-item hover"
                                                    onClick={() => openAddModal('Add')}>
                                                    {
                                                        <IconButton>
                                                            <img
                                                                style={{ width: '20px' }}
                                                                src={GripLines}
                                                                alt=""
                                                            />
                                                        </IconButton>
                                                    }

                                                    <Box className="add-item-container">
                                                        <IconButton>
                                                            <img
                                                                className="grip-icon"
                                                                src={PlusIcon}
                                                                alt=""
                                                            />
                                                        </IconButton>
                                                    </Box>
                                                    <Box className="play-description">
                                                        <Typography className="title">
                                                            Add New
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </TaoScrollbar>
                                )}
                                {isEdit && (
                                    <TaoScrollbar displayScroll>
                                        <Box className="playlist-item">
                                            {/* <Box sx={{border: '1px solid red'}}> */}
                                            <DragDropContext
                                                onDragEnd={onDrag}
                                                nodeSelector="li"
                                                handleSelector="li">
                                                <Droppable droppableId={'droppableEdit'}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            // style={getListStyle(
                                                            //     snapshot.isDraggingOver
                                                            // )}
                                                            ref={provided.innerRef}>
                                                            {selectedPlaylistItem.map(
                                                                (value, index) => (
                                                                    <Draggable
                                                                        key={value.id}
                                                                        draggableId={
                                                                            value.contentName
                                                                        }
                                                                        index={index}>
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={
                                                                                    provided.innerRef
                                                                                }
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                key={index}
                                                                                className="list">
                                                                                <Box className="list-item">
                                                                                    <Box className="list-sub-item">
                                                                                        <IconButton
                                                                                            disabled>
                                                                                            <img
                                                                                                className="grip-icon"
                                                                                                src={
                                                                                                    GripLines
                                                                                                }
                                                                                                alt=""
                                                                                            />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                    <Box className="image-container">
                                                                                        {/* <img
                                                                                            src={
                                                                                                value?.thumbnailImage
                                                                                            }
                                                                                            className="list-image"
                                                                                            alt="" /> */}
                                                                                        {!value
                                                                                            ?.subscription
                                                                                            ?.isAccessible &&
                                                                                            value
                                                                                                ?.subscription
                                                                                                ?.badge && (
                                                                                                <Box className="lock-content">
                                                                                                    <SubscriptionLightLock
                                                                                                        height={
                                                                                                            14
                                                                                                        }
                                                                                                    />
                                                                                                </Box>
                                                                                            )}
                                                                                        <PlayListMediaImg
                                                                                            img={
                                                                                                value?.thumbnailImage
                                                                                            }
                                                                                            height={
                                                                                                '60px'
                                                                                            }
                                                                                            width={
                                                                                                '100px'
                                                                                            }
                                                                                            radius={
                                                                                                '12px 4px'
                                                                                            }
                                                                                        />
                                                                                        <IconButton
                                                                                            sx={{
                                                                                                position:
                                                                                                    'absolute'
                                                                                            }}>
                                                                                            <img
                                                                                                src={
                                                                                                    PlayButton
                                                                                                }
                                                                                                alt=""
                                                                                                className="play-icon"
                                                                                            />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                    <Box className="play-description">
                                                                                        <Typography className="title">
                                                                                            {
                                                                                                value?.contentName
                                                                                            }
                                                                                        </Typography>
                                                                                        {!!value
                                                                                            ?.categoryDetailsList
                                                                                            ?.length && (
                                                                                            <Typography className="affected-part">
                                                                                                {value
                                                                                                    ?.categoryDetailsList[0]
                                                                                                    ?.mainCategory +
                                                                                                    ' > ' +
                                                                                                    value
                                                                                                        ?.categoryDetailsList[0]
                                                                                                        ?.subCategory}
                                                                                            </Typography>
                                                                                        )}
                                                                                        <Typography className="time">
                                                                                            {convertHoursMinsSecondsToReadableString(
                                                                                                value?.contentDuration
                                                                                            )}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box className="button-container">
                                                                                        <IconButton
                                                                                            className="icon-button"
                                                                                            onClick={() =>
                                                                                                deleteHandler(
                                                                                                    value.id,
                                                                                                    index
                                                                                                )
                                                                                            }>
                                                                                            <img
                                                                                                className="grip-icon"
                                                                                                src={
                                                                                                    CrossIcon
                                                                                                }
                                                                                                alt=""
                                                                                            />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                </Box>
                                                                                <Divider />
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            )}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>

                                            {isEdit && (
                                                <Box
                                                    className="list-item add-item hover"
                                                    onClick={() => openAddModal('Edit')}>
                                                    {
                                                        <IconButton>
                                                            <img
                                                                style={{ width: '20px' }}
                                                                src={GripLines}
                                                                alt=""
                                                            />
                                                        </IconButton>
                                                    }

                                                    <Box className="add-item-container">
                                                        <IconButton>
                                                            <img
                                                                className="grip-icon"
                                                                src={PlusIcon}
                                                                alt=""
                                                            />
                                                        </IconButton>
                                                    </Box>
                                                    <Box className="play-description">
                                                        <Typography className="title">
                                                            Add New
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </TaoScrollbar>
                                )}
                            </Box>
                        </Box>
                        <Box className="button-container">
                            <Button variant="contained" className="button" onClick={submitHandler}>
                                <Typography className="text">SAVE</Typography>
                            </Button>
                        </Box>
                    </Box>
                )}
            </PlaylistManipulationModalWrapper>
            {showAddModal && (
                <SelectionPage
                    visible={showAddModal}
                    onClose={closeAddModal}
                    requestType={requestType}
                    selectedPlaylistDetails={
                        isEdit ? selectedPlaylistItem : selectedPlaylistDetails
                    }
                    setSelectedPlaylistDetails={setSelectedPlaylistDetails}
                    handleNewDataInEdit={handleNewDataInEdit}
                />
            )}
        </>
    );
};

export default PlaylistManipulationModal;
