import React from 'react';
import { PurchaseModalWrapper } from './purchase.style';
import { Box, Backdrop, Slide, Typography, Button, Divider } from '@mui/material';
import { ReactComponent as Cart } from 'Assets/images/Reward/shopping-cart.svg';
import { ReactComponent as GoldenHeart } from 'Assets/images/Reward/golden-heart.svg';
// import { ReactComponent as SilverHeart } from 'Assets/images/Reward/silver-heart-icon.svg';

const PurchaseContentModal = ({ ...props }) => {
    let currency = props?.currencies?.[0];
    let heart = props?.heartDetails;

    return (
        <PurchaseModalWrapper
            open={props.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                classes: {
                    root: 'backdrop-root'
                }
            }}
            classes={{
                root: 'modal-root'
            }}
            onClose={props.onClose}>
            <Slide direction="up" in={props.open}>
                <Box className="dialog-container">
                    <Button className="list-item flex f-v-center hover">
                        <Cart height={24} width={24} />
                        <Typography className="text">
                            {currency?.symbol}
                            {currency?.price} {currency?.value}
                        </Typography>
                    </Button>
                    <Button className="list-item flex f-v-center hover">
                        <GoldenHeart height={24} width={24} />
                        <Typography className="text">
                            {heart?.requiredGoldenHearts} Golden Hearts
                        </Typography>
                        <Divider className="divider" />
                    </Button>
                </Box>
            </Slide>
        </PurchaseModalWrapper>
    );
};

export default PurchaseContentModal;
