import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { OrderHistoryWrapper } from './OrderHistory.style';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import { SearchBar } from '../Store/SearchBar';
import moment from 'moment';
import { ReferralShadowBoxLayout } from '../Referral.style';
import { useNavigate } from 'react-router-dom';
import { API_URL, URL_ORDER_DETAILS } from 'Helpers/Paths';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import Api from 'Helpers/ApiHandler';
import InfiniteScroll from 'react-infinite-scroll-component';
import ContentLoader from 'react-content-loader';

const HistoryItemLoader = () => {
    return [...Array(10).keys()].map((item) => (
        <Box className="ordered-item loading" key={item}>
            <ContentLoader
                speed={2}
                width="100%"
                height={60}
                backgroundColor="#dfdfdf"
                foregroundColor="#ecebeb"
                preserveAspectRatio="none">
                <rect x="0" y="0" rx="4" ry="4" width="50" height="60" />
                <rect x="80" y="0" rx="6" ry="6" width="80%" height="60" />
            </ContentLoader>
        </Box>
    ));
};

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}

const OrderHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const API = useMemo(() => new Api(), []);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({ list: [], totalRecords: 0 });
    const [payLoad, setPayload] = useState({
        current_page: 0,
        per_page: 10,
        search: ''
    });

    const fetchOrderHistory = useCallback(async () => {
        setLoading(true);
        let response = await API.post(API_URL.ORDER_LIST, {
            data: { ...payLoad }
        });

        if (response) {
            setData((prev) => ({
                ...prev,
                totalRecords: response?.data?.data?.totalRecords,
                list: prev?.list.concat(response?.data?.data?.list)
            }));
            setLoading(false);
        }
    }, [API, payLoad]);

    const loadMoreItems = () => {
        setPayload({ ...payLoad, current_page: payLoad.current_page + 1 });
    };

    const debouncedHandleChange = useMemo(
        () =>
            debounce((value) => {
                setData({ list: [], totalRecords: [] });
                setPayload((prev) => ({ ...prev, current_page: 0, search: value }));
            }, 300),
        []
    );

    useEffect(() => {
        dispatch(hideSideBar());
        fetchOrderHistory();
        return () => dispatch(showSideBar());
    }, [dispatch, fetchOrderHistory]);

    return (
        <React.Fragment>
            <TaoCalligraphyWatermark />
            <OrderHistoryWrapper>
                <ReferralShadowBoxLayout>
                    <Box className="title">Order History</Box>

                    <Box className="container">
                        <Box className="search-bar">
                            <SearchBar debouncedHandleChange={debouncedHandleChange} />
                        </Box>
                        <Box className="items" id="historyScrollableArea">
                            {!data?.list?.length && isLoading && <HistoryItemLoader />}
                            <InfiniteScroll
                                dataLength={data?.list?.length}
                                next={loadMoreItems}
                                hasMore={data?.list?.length !== data?.totalRecords}
                                loader={isLoading && <HistoryItemLoader />}
                                scrollableTarget="historyScrollableArea">
                                {data?.list?.map((item, index) => (
                                    <Box
                                        key={item?.orderId}
                                        className="ordered-item flex f-v-center hover"
                                        onClick={() =>
                                            navigate(URL_ORDER_DETAILS, {
                                                state: { orderId: item?.orderId }
                                            })
                                        }>
                                        <Box className="flex f-column f-v-center f-h-space-between full-height">
                                            <Typography className="month">
                                                {moment(item?.orderDate, 'DD-MM-YYYY').format(
                                                    'MMM'
                                                )}
                                            </Typography>
                                            <Typography className="day">
                                                {moment(item?.orderDate, 'DD-MM-YYYY').format('DD')}
                                            </Typography>
                                        </Box>
                                        <Box className="flex f-column f-h-space-between full-height content">
                                            <Typography className="item-title elipsis">
                                                {item?.itemNames}
                                            </Typography>
                                            <Box className="flex">
                                                <Typography className="item-inventory">
                                                    {item?.totalItems} Items
                                                </Typography>
                                                <Typography className="item-inventory separator">
                                                    |
                                                </Typography>
                                                <Typography className="item-inventory">
                                                    {item?.currencySymbol}
                                                    {item?.totalPaidPrice}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box className="arrow" />
                                    </Box>
                                ))}
                            </InfiniteScroll>
                        </Box>
                    </Box>
                </ReferralShadowBoxLayout>
            </OrderHistoryWrapper>
        </React.Fragment>
    );
};

export default OrderHistory;
