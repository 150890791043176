import { Popover } from '@mui/material';
import { Box } from '@mui/system';
import styled from 'styled-components';
import { COLORS, FONTS } from 'Styles/Constants';

export const PopOverWrapper = styled(Popover)`
    .sorting-box {
        width: ${(props) => (props?.$customSize ? `${props.$customSize}px` : '120px')};
        border: 1px solid #e8e8e8;
        border-radius: 24px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .sorting-text {
            text-align: center;
            padding: 10px 5px;
            font-size: 18px;
            color: ${COLORS.PRIMARY_WARM};
            font-family: ${FONTS.PRIMARY_MEDIUM};
            width: 80%;
            border-bottom: 0.5px solid rgb(132 132 132 / 0.5);
            justify-content: center;

            &:hover {
                cursor: pointer;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
`;

export const SortFilterWrapper = styled(Box)`
    .filter-sort-container {
        display: flex;
        gap: 1rem;
        margin-left: auto;

        .filter-container {
            display: flex;
            flex-direction: column;
            gap: 13px;

            .sorting-btn {
                height: 46px;
                width: ${(props) => (props?.$customSize ? `${props.$customSize}px` : '120px')};
                border-radius: 24px;
                background-color: ${COLORS.WHITE};
                border: 1px solid ${COLORS.LIGHT_GREY};
                color: ${COLORS.PRIMARY_WARM};
                font-family: ${FONTS.PRIMARY_MEDIUM};
                text-transform: none;
                font-size: 18px;
            }

            .down-arrow-icon {
                transition: 0.2s;
                transform: rotate(180deg);
            }
        }
    }
`;
