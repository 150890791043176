// src/hooks/useTimezoneWatcher.js
import { useEffect, useState } from 'react';

const useTimezoneWatcher = (checkInterval = 10000) => {
    const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        const checkTimezoneChange = () => {
            const newTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (newTimezone !== timezone) {
                setTimezone(newTimezone);
                setHasChanged(true); // Set hasChanged to true
            } else {
                setHasChanged(false); // Reset hasChanged if no change
            }
        };

        // Set up interval to check timezone changes
        const intervalId = setInterval(checkTimezoneChange, checkInterval);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, [timezone, checkInterval]);

    return { timezone, hasChanged };
};

export default useTimezoneWatcher;
