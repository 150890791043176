import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { IconButton, Typography, Box, Button, Slide } from '@mui/material';
import CrossIcon from 'Assets/images/PlayList/cross-icon.svg';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { CategoryWrapper } from './Category.style';
import { PlayListMediaImg } from '../../Playlist.style';

const CategorySelection = ({
    setPage,
    page,
    selectedCategoryTag,
    setSelectedCategoryTag,
    selectedCategoryId,
    setSelectedCategoryId,
    ...props
}) => {
    const API = useMemo(() => new Api(), []);
    const [categoryList, setCategoryList] = useState({
        total: 0,
        list: []
    });
    const [isLoading, setsIsLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([...selectedCategoryId]);

    const onClose = () => {
        setPage(null);
    };

    const selectCategory = (categoryId, index) => {
        let newSelectedCategories = [...selectedCategory];

        if (!newSelectedCategories.length) {
            if (!newSelectedCategories.includes(categoryId)) newSelectedCategories.push(categoryId);
            setSelectedCategory([categoryId]);
        } else {
            if (newSelectedCategories.includes(categoryId)) setSelectedCategory([]);

            if (!newSelectedCategories.includes(categoryId)) setSelectedCategory([categoryId]);

            // CODE FOR MULTIPLE SELECTION OF CATEGORY

            // let findIndex = newSelectedCategories.findIndex((item) => item === categoryId);
            // findIndex > -1 && newSelectedCategories.splice(findIndex, 1);

            // setSelectedCategory(newSelectedCategories);
        }
    };

    const getCategoryList = useCallback(async () => {
        try {
            setsIsLoading(true);
            let response = await API.get(API_URL.GET_CATEGORY);

            if (response) {
                setCategoryList({
                    total: response?.data?.data?.total,
                    list: response?.data?.data?.list
                });
                setsIsLoading(false);
            }
        } catch (error) {
            setsIsLoading(true);
        }
    }, [API]);

    useEffect(() => {
        getCategoryList();
    }, [getCategoryList]);

    const onDone = () => {
        const selectedCategories = [...selectedCategory];
        let selectedCategoryData = [];
        let selectedCategoryIds = [];

        selectedCategories.forEach((categoryId) => {
            let foundedData = categoryList?.list?.find((value) => value?.categoryId === categoryId);

            if (foundedData) {
                selectedCategoryData.push(foundedData);
                selectedCategoryIds.push(foundedData.categoryId);
            }
        });

        setSelectedCategoryTag(selectedCategoryData);
        setSelectedCategoryId(selectedCategoryIds);
    };

    return (
        <>
            <TaoLoader isLoading={isLoading} />
            <Slide in={Boolean(page)} direction="up" timeout={{ enter: 500, exit: 300 }}>
                <CategoryWrapper>
                    <Box className="top-bar">
                        <Typography className="heading">Choose Category</Typography>
                        <IconButton className="cross-button" onClick={onClose}>
                            <img className="cross-icon" src={CrossIcon} alt="" />
                        </IconButton>
                    </Box>
                    <Box className="category-count-container">
                        <Typography className="count">
                            {categoryList.total} Categories Available
                        </Typography>
                        <Typography className="text">choose one</Typography>
                    </Box>
                    {/* <TaoScrollbar displayScroll> */}
                    <Box className="category-selection-container">
                        {categoryList?.list.map((value, index) => (
                            <Box className="categories" key={index}>
                                <IconButton
                                    className="icon-button"
                                    onClick={() => {
                                        selectCategory(value.categoryId, index);
                                    }}>
                                    <PlayListMediaImg
                                        img={
                                            selectedCategory.includes(value.categoryId)
                                                ? value.selectedIcon
                                                : value.icon
                                        }
                                        height={'80px'}
                                        width={'80px'}
                                        radius={'none'}
                                    />
                                </IconButton>
                                <Typography className="text">{value.categoryName}</Typography>
                            </Box>
                        ))}
                    </Box>
                    {/* </TaoScrollbar> */}
                    <Box className="category-button-container">
                        <Button
                            className="submit-button"
                            onClick={() => {
                                onDone();
                                onClose();
                            }}>
                            <Typography className="text">Done</Typography>
                        </Button>
                    </Box>
                </CategoryWrapper>
            </Slide>
        </>
    );
};
export default CategorySelection;
