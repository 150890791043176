import { Box } from '@mui/material';
import styled from 'styled-components';
import { COLORS, responsive } from 'Styles/Constants';

export const ProgramSuggestionWrapper = styled(Box)`
    padding: 70px 0 0;
    width: 45%;
    display: flex;
    margin: 0 auto;
    flex-direction: column;

    ${responsive.DESKTOP`
        width: 60%;
    `}

    ${responsive.TABLET`
        width: 85%;
    `}

    ${responsive.PHABLET`
        width: 100%;
        padding: 100px 10px 0;
    `}



    .program-category {
        font-size: 24px;
        color: ${COLORS.PRIMARY};
        text-align: center;

        &.like {
            margin: 50px 0 20px;
        }
    }

    .suggested-programs {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-start;
        max-width: 700px;
        margin: 0 auto;

        ${responsive.LAPTOP`
            max-width: 500px;
        `}

        ${responsive.TABLET`
            max-width: 90%;
        `}

        ${responsive.MOBILE`
            justify-content: center;
        `}
    }

    .button {
        width: 30%;
        margin: 20px auto;

        ${responsive.TABLET`
            width: 50%;
        `}
    }
`;
