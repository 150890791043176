import React, { useState } from 'react';

import { Box, Typography, Button, IconButton } from '@mui/material';

import FeaturedSessionImage from "Assets/images/Common/ms-transformative-art-of-flourishing.png";
import { ReactComponent as HeartAddIcon } from 'Assets/images/Common/mark-as-favorite.svg';
import { ReactComponent as DownArrowIcon } from "Assets/images/Common/down-arrow.svg";
import { ReactComponent as FilledStarIcon } from "Assets/images/Common/filled-star.svg";
import { ReactComponent as OutlinedStarIcon } from "Assets/images/Common/outlined-star.svg";

import { numberFormatter } from 'Helpers';
import { SessionListingWrapper } from './RoomDetails.style';
import SessionSignupModal from 'Components/common/SessionSignupModal/SessionSignupModal';
import LargeSessionImage from "Assets/images/MeditationRooms/full-session-image.png"
import moment from 'moment';
import { TaoButton } from 'Components/common/Button.style';

const SessionList = ({ myRoom = false, ...props }) => {
    const [signUpModalDetail, setSignupModalDetail] = useState({});
    const [showSignupModal, setShowSignupModal] = useState(false);

    const openSignupModal = (modalDetails = {}) => {
        setSignupModalDetail({ ...modalDetails, startTime: modalDetails.sessionTime, endTime: modalDetails.sessionEndTime })
        setShowSignupModal(true);
    }
    const closeSignupModal = () => {
        setShowSignupModal(false)
        // setSignupModalDetail({})
    }
    return (
        <SessionListingWrapper>
            <SessionSignupModal open={showSignupModal} {...signUpModalDetail} onClose={closeSignupModal} />

            <Box className="mobile-live-session-list-container">
                {
                    liveSessionList.map(s => <Box className="live-session-container">
                        <Box style={{ backgroundImage: `url("${s.largeSessionImage}")` }} className="session-image-container">

                        </Box>
                        <Box className="session-info-container">
                            <Typography variant='h4'>NOW:&nbsp; {s.name}</Typography>
                            <Typography variant='h5'>with&nbsp;{s.host}</Typography>
                        </Box>
                        <Box className="blue-layer"></Box>
                        <Box className="btn-container">
                            <Button className="join-btn">Join Live</Button>
                        </Box>
                    </Box>)
                }
            </Box>

            <Box className="live-session-list-container">
                {
                    liveSessionList.map(s => <Box className="session-container">
                        <Box style={{ backgroundImage: `${`url(${s.sessionImage})`}`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className="session-image"></Box>
                        <Box className="session-info">
                            <Box className="title-container">
                                <Typography component="span" className="live">NOW:&nbsp;</Typography>
                                <Typography component="span" className="session-name">{s.name}</Typography>
                            </Box>
                            <Typography className="host-details">with&nbsp;{s.host}</Typography>
                        </Box>
                        <Button className="join-btn">Join Live</Button>

                    </Box>)
                }

            </Box>
            <Box className="upcoming-title-container">
                <Typography color="primary.dark" className="upcoming-title">Upcoming</Typography>
            </Box>
            {
                myRoom ? <Box className="schedule-new-session-container">
                    <Typography variant="h5">No scheduled sessions</Typography>
                    <Typography variant="h5">at this time</Typography>
                    <TaoButton className="schedule-btn">Schedule a Session</TaoButton>
                </Box> : (
                    <Box className="upcoming-session-list-container">
                        {
                            upcomingSession.map(s => <Box className="session-container">
                                <Box style={{ backgroundImage: `${`url(${s.sessionImage})`}`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className="session-image"></Box>
                                <Box className="session-info">
                                    <Box className="session-date-container">
                                        <Typography className="date">{s?.sessionTime?.format("DD")}</Typography>
                                        <Typography className="month">{s?.sessionTime?.format("MMM")}</Typography>
                                    </Box>
                                    <Box className="mobile-date-container">
                                        <Typography className="date-text"><b>{s?.sessionTime?.format("MMM")}</b>&nbsp;</Typography>
                                        <Typography className="date-text"><b>{s?.sessionTime?.format("DD")}</b>&nbsp;&nbsp;</Typography>
                                        <Typography className="date-text">{s.sessionTime.format("H")}{"–"}{s.sessionEndTime.format("H")}</Typography>
                                    </Box>
                                    <Box className="title-container">
                                        <Typography component="span" className="session-name">{s.name}</Typography>
                                        <Typography className="host-details">{s.sessionTime.format("H")}{"–"}{s.sessionEndTime.format("H")} with&nbsp;{s.host}</Typography>
                                    </Box>
                                    <Box className="program-actions">
                                        <Button onClick={() => openSignupModal(s)} variant="text" className="signup-btn">
                                            <Typography className="text">SIGN UP</Typography>
                                            <IconButton style={{ padding: 0 }}>
                                                <DownArrowIcon className="icon" />
                                            </IconButton>
                                        </Button>
                                        <IconButton>
                                            <HeartAddIcon className="add-fav-icon" />
                                        </IconButton>
                                    </Box>
                                </Box>

                            </Box>)
                        }
                        {/* <Box className="session-container"></Box> */}
                    </Box>
                )
            }
            <Box className="past-title-container">
                <Typography className="past-title">Past</Typography>
            </Box>
            <Box className="past-session-list-container">
                {
                    pastSession.map(s => <Box className="session-container">
                        <Box style={{ backgroundImage: `${`url(${s.sessionImage})`}`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className="session-image"></Box>
                        <Box className="session-info">
                            <Box className="session-date-container">
                                <Typography className="date">{s.sessionTime.format("DD")}</Typography>
                                <Typography className="month">{s.sessionTime.format("MMM")}</Typography>
                            </Box>
                            <Box className="mobile-date-container">
                                <Typography className="date-text"><b>{s?.sessionTime?.format("MMM")}</b>&nbsp;</Typography>
                                <Typography className="date-text"><b>{s?.sessionTime?.format("DD")}</b>&nbsp;&nbsp;</Typography>
                                <Typography className="date-text">{s.sessionTime.format("H")}{"–"}{s.sessionEndTime.format("H")}</Typography>
                            </Box>
                            <Box className="title-container">
                                <Typography component="span" className="session-name">{s.name}</Typography>
                                <Typography className="host-details">{s.sessionTime.format("H")}{"–"}{s.sessionEndTime.format("H")} with&nbsp;{s.host}</Typography>
                            </Box>
                            <Box className="program-actions">
                                {/* <Button onClick={() => openSignupModal(s)} variant="text" className="signup-btn">
                                    <Typography className="text">SIGN UP</Typography>
                                    <IconButton style={{ padding: 0 }}>
                                        <DownArrowIcon className="icon" />
                                    </IconButton>
                                </Button> */}
                                <Box className="mobile-host-container">
                                    <Typography className="host-name">with Sunil</Typography>
                                </Box>
                                <Box className="spec-details-container">
                                    <FilledStarIcon className="filled-start-icon" />
                                    <FilledStarIcon className="filled-start-icon" />
                                    <FilledStarIcon className="filled-start-icon" />
                                    <FilledStarIcon className="filled-start-icon" />
                                    <OutlinedStarIcon className="outlined-start-icon" />
                                    <Typography className="count">{numberFormatter(25)}</Typography>
                                </Box>
                            </Box>
                        </Box>

                    </Box>)
                }
            </Box>

        </SessionListingWrapper>
    );
};

export default SessionList;


const liveSessionList = [
    { name: "Transformative Art for Flourishing", host: "Dr. & Master Sha", sessionImage: FeaturedSessionImage, largeSessionImage: LargeSessionImage },

];

const upcomingSession = [
    { name: "Transformative Art for Flourishing", host: "Dr. & Master Sha", sessionImage: FeaturedSessionImage, sessionTime: moment(), sessionEndTime: moment().add(1, 'h') },
    { name: "Transformative Art for Flourishing", host: "Dr. & Master Sha", sessionImage: FeaturedSessionImage, sessionTime: moment().add(1, 'd'), sessionEndTime: moment().add(1, 'd').add(1, 'h') },
    { name: "Wellness Boost", host: "Dr. & Master Sha", sessionImage: FeaturedSessionImage, sessionTime: moment().add(3, 'd'), sessionEndTime: moment().add(3, 'd').add(1, 'h') },
    { name: "Transformative Art for Flourishing", host: "Dr. & Master Sha", sessionImage: FeaturedSessionImage, sessionTime: moment().add(5, 'd'), sessionEndTime: moment().add(5, 'd').add(1, 'h') },
]

const pastSession = [
    { name: "Transformative Art for Flourishing", host: "Dr. & Master Sha", sessionImage: FeaturedSessionImage, sessionTime: moment(), sessionEndTime: moment().add(1, 'h') },
    { name: "Transformative Art for Flourishing", host: "Dr. & Master Sha", sessionImage: FeaturedSessionImage, sessionTime: moment().add(1, 'd'), sessionEndTime: moment().add(1, 'd').add(1, 'h') },
    { name: "Wellness Boost", host: "Dr. & Master Sha", sessionImage: FeaturedSessionImage, sessionTime: moment().add(3, 'd'), sessionEndTime: moment().add(3, 'd').add(1, 'h') },
]