import styled from '@emotion/styled';
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { COLORS, FONTS } from 'Styles/Constants';

export const TaoButton = styled(Button)`
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
    border-radius: 12px;
    border-radius: 12px;
    font-family: ${FONTS.PRIMARY_MEDIUM};
    padding: 10px 0;
    box-shadow: none;
    color: ${COLORS.WHITE};
    font-size: ${(props) => props?.fontSize || '16px'};

    &:hover {
        background: linear-gradient(180deg, #bfa163 0%, #9c8043 100%);
        box-shadow: none;
    }
    &:disabled {
        color: ${COLORS.WHITE};
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
    }
    &.back-btn {
        background: #eeeeee;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        color: ${COLORS.PRIMARY_WARM};
        opacity: 0.5;
    }
    &.white {
        background: rgba(255, 255, 255, 0.9);
        color: #31290e;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(20px);
    }
`;

export const TaoLoadingButton = styled(LoadingButton)`
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
    border-radius: 12px;
    border-radius: 12px;
    font-family: ${FONTS.PRIMARY_MEDIUM};
    padding: 10px 0;
    box-shadow: none;
    color: white;
    &:hover {
        background: linear-gradient(180deg, #bfa163 0%, #9c8043 100%);
        box-shadow: none;
    }
    &:disabled {
        background-color: #eeeeee;
        pointer-events: none;
        cursor: not-allowed;
    }
`;

export const TaoLinkButton = styled(Button)``;
