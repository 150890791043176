import styled from 'styled-components';
import { Box, Switch } from '@mui/material';
import { responsive, FONTS, COLORS } from 'Styles/Constants';

export const MeditationRoomCreateSessionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;

    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    .MuiOutlinedInput-root {
        border-radius: 8px;
    }

    .dropdown-arrow {
        position: absolute;
        right: 15px;
        cursor: pointer;
    }

    .session-add-edit-title {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 32%;

        ${responsive.DESKTOP`
            width: 42%;
        `}

        ${responsive.LAPTOP_1050`
            width: 58%;
        `}

        ${responsive.TABLET`
            width: 58%;
        `}

        ${responsive.TABLET_800`
            width: 62%;
        `}

        ${responsive.TABLET_700`
            width: 68%;
        `}

        ${responsive.PHABLET`
            width: 94%;
            margin-bottom: 0px;
        `}

        .title-text {
            width: 100%;
            font-family: ${FONTS.SECONDARY};
            font-weight: 700;
            font-size: 40px;
            line-height: 40px;
            letter-spacing: -0.02em;
            color: ${COLORS.PRIMARY_DARK};
            /* margin: 20px 0px 25px 35px; */
            margin-left: 25px;

            /* ${responsive.DISPLAY_1550`
                width: 35%;
            `}

            ${responsive.DESKTOP`
                width: 40%;
            `}

            ${responsive.LAPTOP`
                width: 45%;
            `}

            ${responsive.LAPTOP_1200`
                width: 50%;
            `}

            ${responsive.LAPTOP_1050`
                width: 55%;
            `}

            ${responsive.TABLET`
                width: 60%;
            `}

            ${responsive.TABLET_800`
                width: 65%;
            `}

            ${responsive.TABLET_725`
                width: 70%;
            `} */

            /* ${responsive.PHABLET`
                // width: 100%;
                box-shadow: none;
                border-radius: none;
                margin: 15% 0px 0px 35px;
            `} */

            ${responsive.MOBILE_325`
                font-size: 30px;
                margin-left: 20px;
            `}
        }
    }

    footer {
        bottom: 0;
    }
`;

export const MainFormContentWrapper = styled(Box)`
    display: flex;
    flex-direction: column;

    width: 32%;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px 15px 15px 15px;
    padding: 30px;
    margin-bottom: 30px;

    ${responsive.DISPLAY_1550`
        width: 35%;
    `}

    ${responsive.DESKTOP`
        width: 40%;
    `}

    ${responsive.LAPTOP`
        width: 45%;
    `}

    ${responsive.LAPTOP_1200`
        width: 50%;
    `}

    ${responsive.LAPTOP_1050`
        width: 55%;
        margin-bottom: 90px;
    `}

    ${responsive.TABLET`
        width: 60%;
    `}

    ${responsive.TABLET_800`
        width: 65%;
    `}

    ${responsive.TABLET_725`
        width: 70%;
    `}

    ${responsive.PHABLET`
        width: 100%;
        box-shadow: none;
        border-radius: none;
        padding: 20px;
    `}

    ${responsive.MOBILE_325`
        padding: 10px;
    `}

    .topic-text,
    .banner-header,
    .basic-info-header,
    .date-time-header,
    .repeat-header,
    .advanced-header {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 35px;
        color: ${COLORS.PRIMARY_DARK};

        ${responsive.MOBILE_325`
            font-size: 20px;
        `}
    }
`;

export const SessionTopicSectionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;

    .topic-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .choose-one-text {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;
            color: ${COLORS.MEDIUM_GREY};
            margin-top: 10px;
        }
    }

    .topics {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .topic-value {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .topic-icon {
                border-radius: 40px;
                height: 78px;
                width: 78px;
                border: 1px solid ${COLORS.PRIMARY_EXTRA_DARK};
                background-color: ${COLORS.LIGHT_GREY_SECONDARY};
            }

            .selected-topic {
                background-color: ${COLORS.PRIMARY};
                box-shadow: inset 0px 3px 8px rgba(0, 0, 0, 0.35);
            }

            .topic-toggle-text {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: ${COLORS.TEXT_DARK};
            }
        }
    }
`;

export const SessionBannerWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;

    .banner-medias {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .selected-banner-image {
            border: 2px solid ${COLORS.PRIMARY_DARK} !important;
        }

        .slick-slider {
            overflow: hidden;

            ${responsive.PHABLET`
                        margin-left: 15px;
                    `}
        }

        .slick-slide {
            display: none;
            float: left;
            height: 100%;
            min-height: 1px;
            width: 240px !important;
            margin-right: 10px;

            img {
                border: 2px solid ${COLORS.LIGHT_GREY};
                border-radius: 12px;
                width: 245px;
                height: 136px;
            }
        }

        .slick-initialized .slick-slide {
            display: block;
        }

        .slick-track {
            width: 1300px !important;
        }
    }
`;

export const BasicInfoSessionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;

    .basic-info-details {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .language-option {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }
`;

export const SessionDateTimeSectionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;

    .static-calendar-root {
        width: 100%;
        height: 100%;
        /* background-color: rgb(232 232 232 / 50%); */
        max-height: 100%;
        max-width: 100%;
        z-index: 25;
        margin: 0;
        border: 1px solid rgba(132, 132, 132, 0.4);
        border-radius: 11px;

        @media only screen and (max-width: 1200px) {
            max-width: 100%;
        }

        @media only screen and (max-width: 870px) {
            max-width: 100%;
        }

        /* @media only screen and (max-width: 800px) {
            display: none;
        } */

        .PrivatePickersFadeTransitionGroup-root {
            font-family: ${FONTS.PRIMARY};
            font-weight: 500;
            text-transform: uppercase;
            width: 100%;
        }

        .PrivatePickersYear-root > button {
            font-family: ${FONTS.PRIMARY};
            font-weight: 500;
        }

        .PrivatePickersSlideTransition-root {
            height: 430px;

            ${responsive.MOBILE`
                height: 360px;
            `}

            ${responsive.MOBILE_376`
                height: 300px;
            `}
        }

        .PrivatePickersFadeTransitionGroup-root.MuiCalendarPicker-viewTransitionContainer {
            height: 470px;

            ${responsive.MOBILE`
                height: 400px;
            `}

            ${responsive.MOBILE_376`
                height: 340px;
            `}
        }

        .PrivatePickersSlideTransition-root > div > div {
            justify-content: space-evenly !important;
        }

        .MuiCalendarPicker-viewTransitionContainer > div > div:first-child {
            justify-content: space-evenly;

            & > span {
                margin: 0px 8px;
                font-weight: 500;
                font-size: 12.5312px;
                line-height: 18px;
                text-align: center;
                font-family: ${FONTS.PRIMARY_MEDIUM};
                color: ${COLORS.TEXT_DARK};

                @media only screen and (max-width: 1050px) {
                    margin: 0px -9px;
                }

                @media only screen and (max-width: 870px) {
                    margin: 0px 6px;
                }

                &:nth-child(1) {
                    &::after {
                        content: 'UN';
                    }
                    color: ${(props) => (props.currentWeekDay === 0 ? COLORS.PRIMARY_DARK : '#656565')};
                }

                &:nth-child(2) {
                    &::after {
                        content: 'ON';
                    }
                    color: ${(props) => (props.currentWeekDay === 1 ? COLORS.PRIMARY_DARK : '#656565')};
                }

                &:nth-child(3) {
                    &::after {
                        content: 'UE';
                    }
                    color: ${(props) => (props.currentWeekDay === 2 ? COLORS.PRIMARY_DARK : '#656565')};
                }

                &:nth-child(4) {
                    &::after {
                        content: 'ED';
                    }
                    color: ${(props) => (props.currentWeekDay === 3 ? COLORS.PRIMARY_DARK : '#656565')};
                }

                &:nth-child(5) {
                    &::after {
                        content: 'HU';
                    }
                    color: ${(props) => (props.currentWeekDay === 4 ? COLORS.PRIMARY_DARK : '#656565')};
                }

                &:nth-child(6) {
                    &::after {
                        content: 'RI';
                    }
                    color: ${(props) => (props.currentWeekDay === 5 ? COLORS.PRIMARY_DARK : '#656565')};
                }

                &:nth-child(7) {
                    &::after {
                        content: 'AT';
                    }
                    color: ${(props) => (props.currentWeekDay === 6 ? COLORS.PRIMARY_DARK : '#656565')};
                }
            }
        }
    }

    .Mui-selected {
        background-color: ${COLORS.PRIMARY_DARK} !important;
        color: #ffffff !important;
    }

    .calendar-day-root {
        border: 1.5px solid rgb(132 132 132 / 50%);
        margin: 10px 11px;
        width: 40px;
        height: 40px;
        font-weight: 500;
        font-size: 21.482px;
        line-height: 31px;
        background-color: transparent;
        font-family: ${FONTS.PRIMARY};
        color: ${COLORS.TEXT_DARK};

        @media only screen and (max-width: 1050px) {
            margin: 10px 7px;
        }

        @media only screen and (max-width: 850px) {
            margin: 10px 5px;
        }

        ${responsive.MOBILE`
            font-size: 18px;
            margin: 5px 5px;
        `}

        ${responsive.MOBILE_376`
            border: none;
            margin: 0;
        `}
    }

    .today-date {
        border: 2px solid ${COLORS.PRIMARY} !important;
        color: ${COLORS.PRIMARY};
        background-color: #ffffff;

        &.dark-gray {
            background-color: #848484;
        }
    }

    div.calendar-day-root {
        border: 0;
    }

    .time-section {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
`;

export const SessionRepeatSectionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;

    .repeat-section {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .weekdays-view {
            display: flex;
            flex-direction: row;

            .MuiBox-root {
                .repeat-chip {
                    padding: 3px;
                }
            }
        }

        .month-repeat-view {
            display: flex;
            flex-direction: row;

            .MuiTypography-root {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                color: ${COLORS.MEDIUM_GREY};
            }

            .MuiBox-root {
                display: flex;
                gap: 10px;
                align-items: center;
                margin-left: 10px;

                .MuiOutlinedInput-root {
                    width: 80px;
                    height: 40px;
                    border-radius: 50px;
                }
            }
        }

        .repeat-chip {
            padding: 10px 20px;
            margin: 3px 5px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            height: 40px;
            border-radius: 26px;
            text-transform: uppercase;
            border: 2px solid transparent;

            &.not-selected-chip {
                border: 2px solid #c3a157;
                background-color: rgba(255, 255, 255, 0.6);
                /* color: #656565; */
                &:hover {
                    background-color: transparent;
                }

                .MuiChip-label {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }

            &.selected-chip {
                color: white;

                .MuiChip-label {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }

            ${responsive.DISPLAY`
                font-size: 16px;
            `}

            ${responsive.MOBILE`
                font-size: 14px;
                padding: 5px 5px;
                margin: 5px 5px;
                height: 35px;
            `}
        }
    }
`;

export const SessionAdvanceSectionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

    padding: 30px;
    border-radius: 24px;
    background-color: ${COLORS.LIGHT_GREY_SECONDARY};

    ${responsive.MOBILE`
        padding: 20px;
    `}

    .advance-section-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .MuiTypography-root {
            font-family: ${FONTS.PRIMARY};
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #000000;
            text-transform: uppercase;
        }

        .interactive-session-text-block {
            display: flex;
            flex-direction: column;

            .inteactive-session-subtext {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: ${COLORS.MEDIUM_GREY};
                text-transform: lowercase;
            }
        }

        .limit-option > .MuiOutlinedInput-root {
            height: 35px;
            width: 120px;
            border-radius: 40px;
        }
    }

    .host-section-content {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .host-details {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-left: 15px;
            width: 50%;

            ${responsive.MOBILE`
                width: 70%;
            `}

            ${responsive.MOBILE_325`
                width: 90%;
            `}

            .profile-picture {
                border: 1px solid ${COLORS.MEDIUM_GREY};
                border-radius: 8px;
            }

            .host-name {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                color: ${COLORS.PRIMARY_WARM};
            }

            .MuiIconButton-root {
                padding: 0;
                margin-bottom: 5px;
            }
        }

        .add-co-host-button {
            margin-left: 15px;
            font-family: ${FONTS.PRIMARY};
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            text-align: center;
            width: 50%;
            color: #ffffff;
            text-transform: capitalize;

            ${responsive.MOBILE_325`
                width: 70%;
            `}
        }
    }

    .terms-section {
        display: flex;
        flex-direction: column;

        .terms-checkbox {
            display: flex;
            flex-direction: row;
            align-items: center;

            .MuiTypography-root {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: ${COLORS.TEXT_DARK};
            }

            .MuiCheckbox-root.Mui-checked {
                color: ${COLORS.TEXT_DARK};
            }

            .community-guidelines-link {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .schedule-button {
        font-family: ${FONTS.PRIMARY};
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        color: #ffffff;
        width: 100%;
        border-radius: 12px;
        height: 50px;
    }

    .cancel-session {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .MuiTypography-root {
            font-family: ${FONTS.PRIMARY};
            font-weight: 700;
            font-size: 16px;
            line-height: 23px;
            color: ${COLORS.TEXT_DARK};
        }
    }
`;

export const CustomSwitch = styled(Switch)`
    padding: 8px !important;

    .MuiSwitch-track {
        border-radius: 11px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 20;
            height: 20;
        }
    }
    .MuiSwitch-thumb {
        box-shadow: none;
        width: 20;
        height: 20;
        margin: 0;
    }

    .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
        background: linear-gradient(180deg, #d3ab43 0%, #ad8724 100%);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    }

    .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: rgba(217, 167, 52, 0.6);
        mix-blend-mode: multiply;
    }

    .MuiSwitch-switchBase .MuiSwitch-thumb {
        background: linear-gradient(180deg, #c4c4c4 0%, #8d8d8d 100%);
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    }

    .MuiSwitch-switchBase + .MuiSwitch-track {
        background-color: rgba(164, 164, 164, 0.6);
        mix-blend-mode: multiply;
    }
`;

export const CancelSessionModalWrapper = styled(Box)`
    position: absolute;
    top: 20%;
    left: 26%;
    z-index: 100;
    background-color: #fff;
    height: 500px;
    width: 900px;
    border-radius: 25px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);

    ${responsive.DISPLAY_1650`
        left: 30%;
        height: 450px;
        width: 650px;
    `}

    ${responsive.DESKTOP`
        left: 27%;
    `}

    ${responsive.LAPTOP`
        left: 24%;
    `}

    ${responsive.LAPTOP_1200`
        left: 22.5%;
    `}

    ${responsive.LAPTOP_1050`
        left: 18%;
    `}

    ${responsive.TABLET`
        top: unset;
        left: 0;
        right: 0;
        bottom: 71px;
        width: 100%;
    `}

    .MuiBox-root {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 40%;
        gap: 30px;

        ${responsive.PHABLET_500`
            width: 60%;
        `}

        ${responsive.MOBILE`
            width: 70%;
        `}
        
        ${responsive.MOBILE_376`
            width: 80%;
        `}

        ${responsive.MOBILE_325`
            width: 100%;
        `}

        .MuiTypography-root {
            font-family: ${FONTS.PRIMARY};
            font-weight: 500;
            font-size: 24px;
            line-height: 18px;
            /* color: ${COLORS.NEUTRAL_GREY}; */
            text-transform: uppercase;
        }

        .session-details {
            font-family: ${FONTS.PRIMARY};
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            /* color: ${COLORS.PRIMARY_WARM}; */
            text-transform: capitalize;

            strong {
                font-weight: 600;
                font-size: 20px;
                color: ${COLORS.PRIMARY_WARM};
            }
        }

        .cancel-session-button {
            font-family: ${FONTS.PRIMARY};
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            color: #ffffff;
            width: 100%;
        }

        .continue-editing {
            font-size: 16px;
            color: ${COLORS.PRIMARY_WARM};
            text-transform: capitalize;
            cursor: pointer;
        }

        .MuiFormControlLabel-root .MuiTypography-root {
            text-transform: capitalize;
            color: ${COLORS.PRIMARY_WARM};
        }
    }
`;
