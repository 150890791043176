import React from 'react';
import { /* BREAKPOINTS_VALUE, */ COLORS } from 'Styles/Constants';

const primary = COLORS.PRIMARY;

export const HomeMenu = (props) => {
    const { fill } = props;

    return (
        <svg
            width="26"
            height="22"
            viewBox="0 0 26 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7670_74064)">
                <path
                    d="M22.6608 6.16924L13.3645 0.180914C12.9298 -0.109362 12.3503 -0.036793 11.964 0.301863L2.8125 6.19412V8.98596V18.5167C2.8125 20.4519 4.38202 22 6.28959 22H10.4209V19.6294V17.5975V13.8947H15.0525V17.5975V19.6294V22H19.1838C21.0913 22 22.6367 20.4277 22.6608 18.5167V8.96176V6.16924Z"
                    fill={fill ? primary : '#A6A6A6'}
                />
            </g>
            <defs>
                <clipPath id="clip0_7670_74064">
                    <rect width="25.587" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const WellnessIcon = (props) => {
    const { fill } = props;

    return (
        <svg
            width="25"
            height="22"
            viewBox="0 0 25 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.2503 22C12.0749 22 11.8996 21.9663 11.7377 21.8921L11.6837 21.8719C8.83764 20.6511 3.6445 16.7462 1.28399 12.5782C0.461181 11.1214 0.0295441 9.72532 0.00256677 8.43041C-0.0648765 5.13243 1.2098 3.18332 2.28889 2.13121C3.57032 0.876763 5.33733 0.128142 7.26621 0.0134883C7.4011 0.00674394 7.52249 0 7.63715 0C9.17485 0 10.7193 0.539546 12.2435 1.61189C13.2349 0.910484 14.8603 0 16.8566 0C16.9713 0 17.0927 0.00674394 17.2276 0.0134883C19.1565 0.128142 20.9235 0.876763 22.2116 2.13121C23.2907 3.18332 24.5654 5.13243 24.498 8.43041C24.4305 11.7284 21.807 14.9387 19.6218 17.0497C17.4704 19.1269 14.7996 21.0221 12.8168 21.8719C12.8168 21.8719 12.81 21.8719 12.81 21.8786L12.7561 21.8988C12.5875 21.9663 12.4189 22 12.2503 22Z"
                fill={fill ? primary : '#A6A6A6'}
            />
        </svg>
    );
};

export const SearchMenu = (props) => {
    const { fill } = props;

    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.833 2.33398C18.629 2.33398 23.333 7.03798 23.333 12.834C23.333 18.63 18.629 23.334 12.833 23.334C7.03701 23.334 2.33301 18.63 2.33301 12.834C2.33301 7.03798 7.03701 2.33398 12.833 2.33398ZM12.833 21.0007C17.3445 21.0007 20.9997 17.3455 20.9997 12.834C20.9997 8.32132 17.3445 4.66732 12.833 4.66732C8.32034 4.66732 4.66634 8.32132 4.66634 12.834C4.66634 17.3455 8.32034 21.0007 12.833 21.0007ZM22.7322 21.0835L26.0327 24.3828L24.3818 26.0336L21.0825 22.7332L22.7322 21.0835V21.0835Z"
                fill={fill ? primary : '#A6A6A6'}
            />
            <ellipse
                cx="12.7532"
                cy="12.8335"
                rx="7.04035"
                ry="7.11667"
                fill={fill ? primary : '#A6A6A6'}
            />
            <mask id="path-3-inside-1_7670_73989" fill="white">
                <path d="M12.6957 8.53944C12.6957 8.01176 12.2643 7.57501 11.7457 7.67254C10.8356 7.84371 9.98119 8.25892 9.27581 8.88275C8.56478 9.51157 8.04146 10.3202 7.75416 11.2178C7.5953 11.7141 7.96581 12.1931 8.48216 12.263V12.263C9.0006 12.3333 9.46581 11.9593 9.67601 11.4803C9.8693 11.0397 10.1562 10.6432 10.5206 10.3209C10.8808 10.0024 11.3021 9.76935 11.7537 9.63352C12.259 9.48154 12.6957 9.06712 12.6957 8.53944V8.53944Z" />
            </mask>
            <path
                d="M12.6957 8.53944C12.6957 8.01176 12.2643 7.57501 11.7457 7.67254C10.8356 7.84371 9.98119 8.25892 9.27581 8.88275C8.56478 9.51157 8.04146 10.3202 7.75416 11.2178C7.5953 11.7141 7.96581 12.1931 8.48216 12.263V12.263C9.0006 12.3333 9.46581 11.9593 9.67601 11.4803C9.8693 11.0397 10.1562 10.6432 10.5206 10.3209C10.8808 10.0024 11.3021 9.76935 11.7537 9.63352C12.259 9.48154 12.6957 9.06712 12.6957 8.53944V8.53944Z"
                stroke="white"
                strokeWidth="2"
                mask="url(#path-3-inside-1_7670_73989)"
            />
        </svg>
    );
};

export const RoomsMenu = (props) => {
    const { fill } = props;

    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.794 26.3446H9.65543C8.74219 26.3446 8 25.6025 8 24.6892V7.30717C8 6.39392 8.74219 5.65173 9.65543 5.65173H13.794V7.30717H10.1521C9.87891 7.30717 9.65543 7.53065 9.65543 7.80379V24.1926C9.65543 24.4657 9.87891 24.6892 10.1521 24.6892H13.794V26.3446ZM24.5543 7.06161V24.9375C24.5543 25.4755 24.2094 25.9529 23.6962 26.1294L16.3103 27.9311C15.9212 28.0635 15.4908 28.0028 15.1542 27.7683C14.8176 27.531 14.619 27.1503 14.619 26.7419V26.3474V24.692V7.30992V5.65449V5.25994C14.619 4.8516 14.8176 4.47085 15.1542 4.23357C15.4908 3.9963 15.9185 3.9356 16.3103 4.07079L23.6962 5.87245C24.2094 6.04627 24.5543 6.52359 24.5543 7.06161ZM18.5975 16.5776C18.5975 15.7554 18.0843 15.0877 17.4553 15.0877C16.8262 15.0877 16.313 15.7554 16.313 16.5776C16.313 17.3998 16.8234 18.0675 17.4553 18.0675C18.0816 18.0675 18.5975 17.4025 18.5975 16.5776Z"
                fill={fill ? primary : '#848484'}
            />
        </svg>
    );
};

export const NotificationMenu = (props) => {
    const { fill } = props;

    return (
        <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.1646 17.709H21.0587V10.4173C21.0587 8.20718 20.1712 6.08756 18.5915 4.52476C17.0117 2.96196 14.8692 2.08398 12.6351 2.08398C10.401 2.08398 8.25837 2.96196 6.67862 4.52476C5.09888 6.08756 4.21139 8.20718 4.21139 10.4173V17.709H2.10547V19.7923H23.1646V17.709Z"
                fill={fill ? primary : '#848484'}
            />
            <path
                d="M15.7939 21.8757H9.47618V23.959H15.7939V21.8757Z"
                fill={fill ? primary : '#848484'}
            />
        </svg>
    );
};

export const SubMenuOpenIcon = (props) => {
    const { fill } = props;

    return (
        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.60509 1.39453L4.78707 4.57651M4.78711 4.60519L1.60513 7.78717"
                stroke="#909090"
                strokeWidth="1.5"
                strokeLinecap="round"
                fill={fill ? primary : '#848484'}
            />
        </svg>
    );
};
