import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Box, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';

//CUSTOM
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { ActivityAndWisdomWrapper } from './ActivityAndWisdom.style';
import { convertHrsMinToMins, digitsFormatter, dateFormatter, getWindowDimensions } from 'Helpers';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import Footer from 'Components/common/Footer/Footer';
import { WaveAnimation } from '../WaveAnimation/WaveAnimation';
import { URL_SHARE_PAGE, URL_STATISTICS } from 'Helpers/Paths';
import { useDispatch } from 'react-redux';
import { handleScrollToViewId } from 'Redux/App/Actions';
import { useCallback } from 'react';
import SeeAllTypography from 'Components/common/SeeAll/Typography/SeeAllTypography';
import TaoSlider from 'Components/common/TaoSlider';
import { useSearchParams } from 'react-router-dom';
import usePermissions from 'Components/common/Permissions';
import { BoxLoader } from 'Components/common/ShimmerEffectLoader/ShimmerEffectLoader';
import { ACCESS_LEVELS_MODULEs, PAGE_SHARING } from 'Helpers/Constants';
import ShareSocial from 'Components/common/Share/Share';
import DefaultThumbnail from 'Assets/Logo/whiteLogo.png';

const SCROLL_ID = 'daily-wisdom';

export const ActivityAndWisdom = ({
    dailyActivityData,
    wavesLottieMobileRef,
    wavesAnimation,
    scrollIntoViewId,
    isDailyWisdomLoading
}) => {
    const dispatch = useDispatch();
    const dailyActivityRef = useRef();
    const [searchParams] = useSearchParams();
    const wisdomRef = useRef();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [currentSlide, setCurrentSlide] = useState(0);

    const wisdomScrollId = searchParams?.get?.('quoteId');
    const dailyActivityPermissions = usePermissions(ACCESS_LEVELS_MODULEs.SEE_PERSONAL_STATS);

    const handleSwipe = (index) => {
        setCurrentSlide(index);
    };

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const executeScroll = useCallback(
        (scrollId = '') => {
            const dailyActivityById = document.getElementById(scrollIntoViewId || scrollId);

            if (dailyActivityById) {
                dailyActivityById?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            dispatch(handleScrollToViewId(''));
        },
        [scrollIntoViewId, dispatch]
    );

    useEffect(() => {
        if (scrollIntoViewId) executeScroll(SCROLL_ID);
    }, [scrollIntoViewId, executeScroll]);

    useEffect(() => {
        if (wisdomScrollId && dailyActivityData?.dailyWisdomList?.length) {
            const quoteIndex = dailyActivityData?.dailyWisdomList.findIndex(
                (wisdom) => wisdom.id === Number(wisdomScrollId)
            );

            if (quoteIndex >= 0) {
                executeScroll(SCROLL_ID);
                setCurrentSlide(quoteIndex);
                wisdomRef.current.slickGoTo(quoteIndex);
            }
        }
    }, [dailyActivityData?.dailyWisdomList, executeScroll, wisdomScrollId]);

    return (
        <React.Fragment>
            <ActivityAndWisdomWrapper>
                <Box
                    className="activity-parent flex f-h-space-between"
                    ref={dailyActivityRef}
                    id="daily-wisdom">
                    <Box className="daily-activity-section">
                        <Box className="daily-activity width-max-content">
                            <Typography color="primary" className="daily-activity-text">
                                Daily Activity
                            </Typography>

                            {dailyActivityPermissions?.permissionLoaded && (
                                <SeeAllTypography
                                    redirectionURL={URL_STATISTICS}
                                    disableArrow={!dailyActivityPermissions?.canAccess}
                                    reduxAction={() =>
                                        dispatch(handleScrollToViewId('daily-wisdom'))
                                    }
                                />
                            )}
                        </Box>

                        <Box className="daily-activity-details">
                            <Box className="daily-progress">
                                <CircularProgressbarWithChildren
                                    value={dailyActivityData?.goalPercentage}
                                    maxValue={100}
                                    styles={buildStyles({
                                        background: {
                                            fill: 'conic-gradient(from 0deg at 50% 50%, #fff6e1 360deg, #ffd26d 258.14deg, #fff6e1 0deg)'
                                        }
                                    })}>
                                    <Box className="meditation-progress-inner-details">
                                        <span className="current-week-day">
                                            {moment().format('ddd')}
                                        </span>
                                        <span className="total-meditation-time">
                                            {convertHrsMinToMins(
                                                dailyActivityData?.meditationTime || '00:00'
                                            )}
                                            m
                                        </span>
                                        <span className="goal-in-percentage">
                                            {dailyActivityData?.goalPercentage}% of goal
                                        </span>
                                    </Box>
                                </CircularProgressbarWithChildren>
                            </Box>

                            <Box className="total-meditations-live-sessions">
                                <Box>
                                    <Typography className="total-meditations">
                                        {digitsFormatter(dailyActivityData?.numberOfMeditations)}
                                    </Typography>
                                    <Typography className="meditation-text">Meditations</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {windowDimensions.width <= BREAKPOINTS_VALUE.TABLET && (
                        <WaveAnimation
                            refComp={wavesLottieMobileRef}
                            animation={wavesAnimation}
                            customStyle={{
                                marginBottom: '10px',
                                position: 'relative'
                            }}
                        />
                    )}

                    <Box className="wisdom-slider">
                        <TaoSlider
                            ref={wisdomRef}
                            cardHeight={365}
                            slidesToShow={1}
                            slidesToScroll={1}
                            showArrows={false}
                            slidesToShowLaptop={1}
                            slidesToShowDesktop={1}
                            isLeftSideShadeVisible={true}
                            shadeWidths={{ laptop: 20, tablet: 20, phablet: 20, mobile: 20 }}
                            centerMode={true}
                            currentSlide={currentSlide}
                            totalSlides={
                                dailyActivityData?.dailyWisdomList?.length
                                    ? dailyActivityData?.dailyWisdomList.length - 1
                                    : 0
                            }
                            loading={isDailyWisdomLoading}
                            slickTrackPadding="0px"
                            onSwipe={handleSwipe}>
                            {isDailyWisdomLoading
                                ? [1, 2, 3, 4, 5, 6]?.map((value, index) => (
                                      <Box key={value}>
                                          <Box className="box-loader">
                                              <BoxLoader isFullWidth isFullHeight />
                                          </Box>
                                      </Box>
                                  ))
                                : dailyActivityData?.dailyWisdomList?.map((wisdom, index) => (
                                      <Box key={index}>
                                          <Card className="wisdom-section">
                                              {wisdom?.thumbnailImage ? (
                                                  <CardMedia
                                                      component="img"
                                                      alt="thumbnail"
                                                      image={wisdom?.thumbnailImage}
                                                      className="card-media"
                                                  />
                                              ) : (
                                                  <Box className="card-media flex f-v-center f-h-center">
                                                      <Box
                                                          className="default-image"
                                                          style={{
                                                              backgroundImage: `url(${DefaultThumbnail})`
                                                          }}
                                                      />
                                                  </Box>
                                              )}
                                              <CardContent className="card-content">
                                                  <Typography className="quote-date">
                                                      {dateFormatter(wisdom?.date)}
                                                  </Typography>
                                                  <Typography
                                                      variant="body2"
                                                      className="wisdom-quote">
                                                      {wisdom?.text}
                                                  </Typography>
                                                  <Typography className="quote-by">
                                                      {wisdom?.personName}
                                                  </Typography>
                                              </CardContent>
                                              <CardActions className="card-action">
                                                  <ShareSocial
                                                      ButtonComponent={
                                                          <Typography
                                                              variant="body2"
                                                              className="card-action-button">
                                                              <span className="button-text">
                                                                  Share
                                                              </span>
                                                          </Typography>
                                                      }
                                                      title={`By ${wisdom.personName}`}
                                                      details={{ description: wisdom.text }}
                                                      url={`${window?.location?.protocol}//${window.location.host}${URL_SHARE_PAGE}?page=${PAGE_SHARING.HOME}&quoteId=${wisdom.id}`}
                                                      disableRipple={true}
                                                      dynamicDimension
                                                  />
                                              </CardActions>
                                          </Card>
                                      </Box>
                                  ))}
                        </TaoSlider>
                    </Box>
                    <Typography color="primary" className="wisdom-title">
                        Daily Wisdom
                    </Typography>
                </Box>

                <Footer showWhiteBackground={true} isBottomZero />
            </ActivityAndWisdomWrapper>
        </React.Fragment>
    );
};
