import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { API_URL } from 'Helpers/Paths';
import Api from 'Helpers/ApiHandler';

import { MeditationFilterPopoverWrapper } from '../Category.style';
import RefreshIcon from 'Assets/images/Common/refresh-icon.svg';
import CloseIcon from 'Assets/images/Common/close-icon.svg';
import { cloneDeep } from 'lodash';

const MeditationContentFilter = (props) => {
    const {
        id,
        open,
        meditationFilterAnchorEl,
        setMeditationFilterAnchorEl,
        wellnessCategoryDetails,
        setWellnessContent,
        getWellnessCategoryContents,
        setUserSelectedFilterOptions,
        userSelectedFilterOptions,
        setSelectedFilterTitle
    } = props;

    const API = useMemo(() => new Api(), []);

    const [filterOption, setFilterOption] = useState({
        tagList: []
    });

    const [userFilterOption, setUserFilterOption] = useState({
        tags: []
    });

    const [loading, setLoading] = useState(false);

    const userSelection = () => {
        if (userFilterOption) {
            let tagDetailsList = [];

            userFilterOption?.tags?.forEach((id) => {
                let tagDetails = filterOption?.tagList?.find((value) => value?.id === id);

                if (tagDetails) {
                    tagDetailsList.push(tagDetails?.name);
                }
            });

            let count = 0;

            if (userFilterOption?.tags?.length > 0) {
                count = userFilterOption?.tags?.length;
            }

            setSelectedFilterTitle((prev) => ({
                ...prev,
                tag: tagDetailsList.join(', '),
                filterOptionSelectionCount: count || 0
            }));
        }
    };

    const handleRefreshOptions = () => {
        let copySubCategoryList = cloneDeep(filterOption?.topicList);
        let copyWellnessCategoryTagsList = cloneDeep(filterOption?.tagList);

        copySubCategoryList = copySubCategoryList?.map((value) => {
            value.isSelected = false;
            return value;
        });

        copyWellnessCategoryTagsList = copyWellnessCategoryTagsList?.map((value) => {
            value.isSelected = false;
            return value;
        });

        setFilterOption((prev) => ({
            ...prev,
            topicList: copySubCategoryList,
            tagList: copyWellnessCategoryTagsList
        }));

        getWellnessCategoryTags([wellnessCategoryDetails?.categoryId]);
        setUserFilterOption({ subCategory: null, tags: [] });
        // setSelectedFilterTitle({ subCategory: '', tag: '', filterOptionSelectionCount: 0 });
        // setUserSelectedFilterOptions({ topicId: null, tagId: null });
    };

    const handleTagSelection = (tag) => {
        let tempTagList = cloneDeep(filterOption?.tagList);

        let tagIndex = filterOption?.tagList?.findIndex((value) => value?.id === tag?.id);
        if (tagIndex !== -1) {
            tempTagList[tagIndex].isSelected = !tempTagList[tagIndex].isSelected;
        }
        let selectedTagIds = tempTagList
            ?.filter((value) => value?.isSelected)
            .map((item) => item?.id);

        setFilterOption((prev) => ({
            ...prev,
            tagList: tempTagList
        }));

        setUserFilterOption((prev) => ({
            ...prev,
            tags: selectedTagIds
        }));
    };

    const handleFilterApply = () => {
        setWellnessContent((prev) => ({
            guidedMeditationAudioDetail: {
                ...prev?.guidedMeditationAudioDetail,
                list: [],
                totalRecords: 0,
                currentPage: 0
            },
            musicDetail: {
                ...prev?.musicDetail,
                list: [],
                totalRecords: 0,
                currentPage: 0
            },
            videoDetail: {
                ...prev?.videoDetail,
                list: [],
                totalRecords: 0,
                currentPage: 0
            }
        }));

        setUserSelectedFilterOptions((prev) => ({
            ...prev,
            tagId: userFilterOption?.tags
        }));

        getWellnessCategoryContents(
            userSelectedFilterOptions?.topicId || wellnessCategoryDetails?.categoryId,
            userFilterOption?.tags || []
        );
        userSelection();
        setMeditationFilterAnchorEl(null);
    };

    const getWellnessCategoryTags = useCallback(
        async (categoryIds = [], updateTagIsSelected = false, mutateLoading = true) => {
            if (mutateLoading) setLoading(true);
            let response = await API.post(API_URL.WELLNESS_CATEGORY_TAGS, {
                data: {
                    categoryIds
                }
            });
            if (response?.data?.data?.tagsDetails?.length > 0) {
                let tagList = response?.data?.data?.tagsDetails?.map((value) => ({
                    ...value,
                    isSelected: false
                }));

                if (
                    userSelectedFilterOptions &&
                    userSelectedFilterOptions?.tagId?.length &&
                    updateTagIsSelected
                ) {
                    userSelectedFilterOptions?.tagId?.forEach((value) => {
                        let tagByIndex = tagList?.findIndex((tag) => tag?.id === value);
                        if (tagByIndex !== -1) tagList[tagByIndex].isSelected = true;
                    });
                }

                setFilterOption((prev) => ({
                    ...prev,
                    tagList: tagList
                }));
            }
            setLoading(false);
        },
        [API, userSelectedFilterOptions]
    );

    useEffect(() => {
        // WELLNESS CATEGORY PROGRAM
        if (wellnessCategoryDetails?.categoryId) {
            let idList = [wellnessCategoryDetails?.categoryId] || [];

            if (userSelectedFilterOptions?.topicId) {
                idList.push(userSelectedFilterOptions?.topicId);
                setUserFilterOption({ subCategory: userSelectedFilterOptions?.topicId });
            }

            getWellnessCategoryTags(idList, true);
        }

        if (wellnessCategoryDetails?.subCategoryDetails?.length > 0) {
            let initTopicList = wellnessCategoryDetails?.subCategoryDetails;
            initTopicList = initTopicList.map((value) => {
                if (userSelectedFilterOptions?.topicId === value?.id) {
                    value.isSelected = true;
                } else {
                    value.isSelected = false;
                }

                return value;
            });

            setFilterOption((prev) => ({
                ...prev,
                topicList: initTopicList
            }));
        }
    }, [
        wellnessCategoryDetails?.categoryId,
        wellnessCategoryDetails?.subCategoryDetails,
        getWellnessCategoryTags,
        userSelectedFilterOptions
    ]);

    return (
        <>
            <MeditationFilterPopoverWrapper
                id={id}
                open={open}
                anchorEl={meditationFilterAnchorEl}
                placement="top"
                onClose={() => setMeditationFilterAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                }}
                classes={{ paper: 'paper' }}
                PaperProps={{
                    style: {
                        borderRadius: '14px'
                    }
                }}>
                <Box className="popover-container">
                    {/* HEADER */}
                    <Box className="popover-header-wrapper">
                        <Box className="icon-wrapper">
                            <img
                                alt=""
                                className="refresh-icon"
                                src={RefreshIcon}
                                onClick={() => {
                                    handleRefreshOptions();
                                }}
                            />
                        </Box>
                        <Box className="header-title-wrapper">
                            <Typography color="primary" className="header-title-text">
                                Filters
                            </Typography>
                        </Box>
                        <Box className="icon-wrapper">
                            <img
                                alt=""
                                className="close-icon"
                                src={CloseIcon}
                                onClick={() => {
                                    setMeditationFilterAnchorEl(null);
                                }}
                            />
                        </Box>
                    </Box>

                    {/* TAG */}
                    {!loading && filterOption?.tagList?.length > 0 && (
                        <>
                            <Box className="tags-main-container">
                                <Box className="tags-title-wrapper">
                                    <Typography color="primary" className="tags-title">
                                        Tags{' '}
                                        <span className="tags-title light">choose one or more</span>
                                    </Typography>
                                </Box>
                                <Box className="tags-chips-wrapper">
                                    {filterOption?.tagList?.map((value, index) => (
                                        <Box
                                            className={`tag-chip ${
                                                value?.isSelected && `isSelected`
                                            }`}
                                            key={index}
                                            onClick={() => handleTagSelection(value)}>
                                            <Typography
                                                className={`tag ${
                                                    value?.isSelected && `isSelected`
                                                }`}>
                                                {value?.name}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            {/* FOOTER */}
                            <Box className="footer-btn-container">
                                <Button className="footer-btn" onClick={() => handleFilterApply()}>
                                    <Typography className="footer-btn-text">Apply</Typography>
                                </Button>
                            </Box>
                        </>
                    )}

                    {!loading &&
                        filterOption?.topicList?.length === 0 &&
                        filterOption?.tagList?.length === 0 && (
                            <Box className="sub-category-main-container">
                                <Box className="placeholder-header-wrapper">
                                    <Typography color="primary" className="placeholder-header">
                                        No filter options are available at this moment.
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                </Box>
            </MeditationFilterPopoverWrapper>
        </>
    );
};

export default MeditationContentFilter;
