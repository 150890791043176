import React, { useState, useEffect } from 'react';
import { Typography, IconButton, Box, Button, Modal, Backdrop, TextField, Slide } from '@mui/material';

import { AddParticipantToChatModalWrapper } from './AddParticipantToSessionModalView.style';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { ClearButtonIcon } from '../../AddEditSession/AddEditSessionSVG';

import { getWindowDimensions } from 'Helpers';

const AddParticipantToSessionModal = (props) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [userEmailAddress, setUserEmailAddress] = useState('');
    const [sessionInviteSent, setSessionInviteSent] = useState(false);

    const toggleSessionInvitation = (value) => {
        setSessionInviteSent(value);

        if (!value) {
            setUserEmailAddress('');
        }
    };

    const handleUserEmailAddressChange = (event) => {
        setUserEmailAddress(event.target.value);
    };

    return (
        <Modal
            open={props.openAddParticipantToSessionModal}
            onClose={() => props.toggleAddParticipantToSessionModalHandler(false)}
            aria-labelledby="add-participant-session-modal-title"
            aria-describedby="add-participant-session-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Slide in={props.openAddParticipantToSessionModal} direction={windowDimensions.width <= BREAKPOINTS_VALUE.TABLET ? 'up' : 'right'} timeout={{ enter: 300, exit: 300 }} unmountOnExit>
                <AddParticipantToChatModalWrapper>
                    <Box className="close-modal-button">
                        <IconButton onClick={() => props.toggleAddParticipantToSessionModalHandler(false)}>
                            <ClearButtonIcon />
                        </IconButton>
                    </Box>
                    <Box className="add-participant-to-session-content">
                        <Box className="session-details">
                            <Typography className="session-title">Breathwork for a clear mind</Typography>
                            <Typography className="host-name">With Sunil</Typography>
                            <Typography className="invite-user-text">{sessionInviteSent ? 'Invite successfully sent to' : 'Invite users to join'}</Typography>
                            {sessionInviteSent && <Typography className="user-email-address">{userEmailAddress}</Typography>}

                            {!sessionInviteSent && (
                                <TextField className="email-address" id="user-email-address-id" label="Email address" variant="outlined" onChange={(event) => handleUserEmailAddressChange(event)} />
                            )}
                        </Box>

                        {!sessionInviteSent && (
                            <Button className="add-participant-to-session-send-invite-button" variant="contained" onClick={() => toggleSessionInvitation(true)}>
                                Send Invite
                            </Button>
                        )}

                        {sessionInviteSent && (
                            <Box className="add-participant-to-session-action-buttons">
                                <Button variant="contained" onClick={() => toggleSessionInvitation(false)}>
                                    Send Another
                                </Button>
                                <Button className="close-button" variant="outlined" onClick={() => props.toggleAddParticipantToSessionModalHandler(false)}>
                                    Close
                                </Button>
                            </Box>
                        )}
                    </Box>
                </AddParticipantToChatModalWrapper>
            </Slide>
        </Modal>
    );
};

export default AddParticipantToSessionModal;
