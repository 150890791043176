import { Box } from '@mui/material';
import styled from 'styled-components';
import { FONTS, COLORS, responsive } from 'Styles/Constants';

export const TaoCardWrapper = styled.div`
    align-items: center;
    display: flex !important;
    flex-direction: column;
    gap: ${(props) => (!props?.$titleAvailable ? '0px' : '5px')};
    position: relative;
    width: ${(props) => `${props?.taoCardWidth}`};

    .card-root {
        box-shadow: none;
        background-color: transparent;
        height: 120px;
        width: 210px;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 0;

        ${responsive.PHABLET`
            height: ${(props) => (props?.$supportedResponsiveView ? '70px' : '120px')};
            width: ${(props) => (props?.$supportedResponsiveView ? '120px' : '210px')};
        `}
    }

    .content-bg-image {
        border-radius: 24px 8px;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 10;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: ${COLORS.WHITE};

        .image {
            position: absolute;
            height: 100%;
            width: 100%;
            border-radius: inherit;
            z-index: 11;

            &.gold-border {
                border: 3px solid ${COLORS.PRIMARY_DARK};
            }
        }

        &.apply-border {
            height: 110px;
            width: 200px;
            top: 0;
            left: 0;
            border-radius: 24px 8px 15px;

            ${responsive.PHABLET`
                height: ${(props) => (props?.$supportedResponsiveView ? '63px' : '110px')};
                width: ${(props) => (props?.$supportedResponsiveView ? '113px' : '200px')};
                border-radius : ${(props) =>
                    props?.$supportedResponsiveView ? '8px' : '24px 8px 15px'}
            `}
        }

        .black-top-shadow {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
            position: absolute;
            height: 35%;
            width: 100%;
            border-radius: 24px 8px 0 0;
            top: 0;
        }

        .card-content-class {
            padding: 0;
        }
    }

    .playlist-borders {
        position: absolute;
        top: 2px;
        left: -1px;
        width: 210px;

        &.gold-playlist-border {
            top: 2px;
            left: -2px;

            ${responsive.PHABLET`
                top: ${(props) => (props?.$supportedResponsiveView ? '1px' : '2px')}
            `}
        }

        ${responsive.PHABLET`
            width: ${(props) => (props?.$supportedResponsiveView ? '120px' : '210px')};
            left: ${(props) => (props?.$supportedResponsiveView ? '-2px' : '-1px')}
        `}
    }

    .play-button {
        z-index: 12;
    }

    .card-chip {
        position: absolute;
        bottom: 5px;
        left: 0;
        bottom: 51px;
    }

    .media-text {
        max-width: 200px;
        text-align: center;
        font-family: ${FONTS.PRIMARY};
        font-size: 16px;
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        height: ${(props) => (props?.$titleAvailable ? '25px' : 'auto')};

        &.text-from-start {
            align-self: flex-start;
        }

        &.dark {
            color: ${COLORS.PRIMARY_WARM};
            font-family: ${FONTS.PRIMARY};
        }

        &.children {
            height: auto;
        }
    }

    .play-list-button {
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 12;

        &:hover {
            background-color: rgba(0, 0, 0, 0.4);
        }

        .playlist-icon {
            max-width: 25px;
            max-height: 25px;

            ${responsive.PHABLET`
                max-width: ${(props) => (props?.$supportedResponsiveView ? '15px' : '25px')};
                max-height: ${(props) => (props?.$supportedResponsiveView ? '15px' : '25px')};
            `}
        }
    }

    .tao-card-heart-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 2%;
        right: 0;
        height: 40px;
        width: 40px;
        cursor: pointer;
        z-index: 20;
    }

    .lock-content-container {
        position: absolute;
        width: 210px;
        height: 120px;
        border-radius: 12px;
        z-index: 15;

        .lock-icon {
            /* position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 70px;
            width: 70px; */

            position: absolute;
            left: 10px;
            bottom: 15px;
            height: 20px;
            width: 20px;
            background: gray;
            padding: 5px;
            border-radius: 50%;

            &.decrease-bottom {
                bottom: 8px;
            }
        }
    }

    .unlock-container {
        max-width: 160px;
        background: ${COLORS.PRIMARY_DARK};
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: absolute;
        top: 0%;
        left: 0%;
        z-index: 12;

        ${responsive.PHABLET`
            max-width: ${(props) => (props?.$supportedResponsiveView ? '100px' : '160px')};
        `}

        &.badge {
            top: 70%;
            border-radius: 50px;
            background: rgba(101, 101, 101, 0.7);
            padding: 5px;
            left: 3%;

            ${responsive.PHABLET`
                top: ${(props) => (props?.$supportedResponsiveView ? '55%' : '70%')};
            `}
        }

        &.get {
            right: 0%;
            left: auto;
            border-radius: 0 6px 0 6px;
        }

        .unlock-text {
            font-family: ${FONTS.TERTIARY_BOLD};
            font-size: 12px;
            text-transform: uppercase;
            color: ${COLORS.WHITE};
            padding: 2px 8px;
            width: 100%;
        }
    }

    .ribbon-icon {
        position: absolute;
        top: -3%;
        left: 6%;
        z-index: 20;
    }
`;

export const TaoCardLoaderWrapper = styled(Box)`
    height: 150px;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
`;
