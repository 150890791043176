//core
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import {
    Box,
    Input,
    Typography,
    Button,
    IconButton,
    TextField,
    InputAdornment
} from '@mui/material';
import ReactQuill, { Quill } from 'react-quill';
import moment from 'moment';
import html2canvas from 'html2canvas';
//icon
import { ReactComponent as EditIconImg } from 'Assets/images/Journal/edit.svg';
import { ReactComponent as SearchIconImg } from 'Assets/images/Common/outlined-search-icon.svg';
import { ReactComponent as DeleteIconImg } from 'Assets/images/Journal/delete.svg';
// import { ReactComponent as ShareIconImg } from 'Assets/images/Journal/Share.svg';
import { ReactComponent as IndentIcon } from 'Assets/images/MeditationRooms/indent.svg';
import { ReactComponent as OutdentIcon } from 'Assets/images/MeditationRooms/outdent.svg';
import { ReactComponent as OrderListIcon } from 'Assets/images/MeditationRooms/list-ordered.svg';
import { ReactComponent as UnorderedListIcon } from 'Assets/images/MeditationRooms/list.svg';
import { ReactComponent as BackArrowIconImg } from 'Assets/images/Common/gray-left-arrow.svg';
import { ReactComponent as SaveIcon } from 'Assets/images/Journal/save.svg';
import { ReactComponent as CrossIcon } from 'Assets/images/MeditationTimer/cross-icon.svg';

//custom components
import { JournalMainWrapper } from './Journal.styles';
import JournalCard from './JournalCard';
import TaoCalligraphyWatermark from '../../common/TaoCalligraphyWatermark';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { getWindowDimensions } from 'Helpers';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import TaoScrollbar from 'Components/common/Scrollbar';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import { DeleteConfirmation } from 'Components/common/Modal/Delete';
import { useLocation } from 'react-router-dom';
import { SurveyFormClipboard } from './PlainClipboard';
import { showToast, hideTopBar, showTopBar } from 'Redux/App/Actions';
import { store } from 'Redux/store';
import { useDispatch } from 'react-redux';
import ShareSocial from 'Components/common/Share/Share';

Quill.register('modules/clipboard', SurveyFormClipboard, true);

function Journal() {
    const API = useMemo(() => new Api(), []);
    const journalTitle = useRef(null);
    const journalNote = useRef(null);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [titleValue, setTitleValue] = useState('');
    const [quillValues, setQuillValues] = useState(``);
    const [dateValue, setDateValue] = useState(moment(new Date()).format('MMM D, YYYY'));

    const [cardsData, setCardsData] = useState([]);
    const [cardSelect, setCardSelect] = useState(null);
    const [latestNote, setLatestNote] = useState(null);
    const [refreshData, setRefreshData] = useState(false);
    const [searchCardData, setSearchCardData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const location = useLocation();
    const [saveBoxShadowFlag, setSaveBoxShadowFlag] = useState(false);

    const EmptyNoteHandler = () => {
        setQuillValues('');
        setTitleValue('');
        setCardSelect(null);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowDimensions.width]);

    const handleResponsiveView = () => {
        if (titleValue || quillValues) {
            addEditNewJournal('back');
        }

        EmptyNoteHandler();
        setLatestNote(false);
    };

    const fetchContentById = useCallback(async () => {
        if (!location?.state?.journalTitle) {
            journalTitle.current.focus();
            EmptyNoteHandler();
            setDateValue(moment(new Date()).format('MMM D, YYYY'));
            setLatestNote(windowDimensions.width > BREAKPOINTS_VALUE.TABLET);
            return;
        }

        setLatestNote(true);
        setTitleValue(location?.state?.journalTitle);
    }, [location?.state?.journalTitle, windowDimensions.width]);

    useEffect(() => {
        fetchContentById();
    }, [fetchContentById]);

    const getAllJournalCard = useCallback(async () => {
        const response = await API.post(API_URL.JOURNAL_LIST, {
            params: { search: '' }
        });

        if (response) {
            setCardsData(response?.data?.data?.journal);
            setIsLoading(false);
        }
    }, [API]);

    const getDataById = useCallback(async () => {
        if (cardSelect) {
            const response = await API.get(API_URL.JOURNAL_API + `/${cardSelect}`);

            if (response) {
                setTitleValue(response?.data?.data?.title);

                response.data.data.journalEntry = response.data.data.journalEntry
                    .replace(/<strike[^>]*>/g, '<s>')
                    .replace(/<\/strike>/g, '</s>');

                setQuillValues(response?.data?.data?.journalEntry);
                setDateValue(
                    moment(response?.data?.data?.date, 'YYYY MM DD').format('MMM D, YYYY')
                );
                setLatestNote(false);
                setIsLoading(false);
            }
        }
    }, [API, cardSelect]);

    useEffect(() => {
        if (windowDimensions.width < BREAKPOINTS_VALUE.TABLET && (cardSelect || latestNote)) {
            dispatch(hideTopBar());
        } else {
            dispatch(showTopBar());
        }
    }, [windowDimensions.width, cardSelect, latestNote, dispatch]);

    useEffect(() => {
        setIsLoading(true);
        getAllJournalCard();
    }, [refreshData, getAllJournalCard]);

    useEffect(() => {
        getDataById();
    }, [getDataById]);

    const handleNewNote = () => {
        journalTitle.current.focus();
        EmptyNoteHandler();
        setDateValue(moment(new Date()).format('MMM D, YYYY'));
        setLatestNote(true);
    };

    const addEditNewJournal = async (taskName = 'back') => {
        if (!titleValue && !quillValues) {
            return null;
        } else {
            setIsLoading(true);
            if (cardSelect !== null) {
                //if cardSelect is not null than we will put data
                let response = await API.put(API_URL.JOURNAL_API, {
                    data: {
                        journalId: cardSelect,
                        title: titleValue,
                        journalEntry: quillValues
                    }
                });
                if (response) {
                    setIsLoading(false);
                    // EmptyNoteHandler()
                    dispatch(showToast(response?.data?.message));
                    setRefreshData((prevState) => !prevState);
                }
            } else {
                // if cardSelect is null than we will post data
                let response = await API.post(API_URL.JOURNAL_API, {
                    data: {
                        title: titleValue,
                        journalEntry: quillValues
                    }
                });
                if (response) {
                    setRefreshData((prevState) => !prevState);
                    dispatch(showToast(response?.data?.message));
                    // EmptyNoteHandler();
                    if (windowDimensions.width >= BREAKPOINTS_VALUE.TABLET || taskName === 'save') {
                        setCardSelect(response?.data?.data?.journalId);
                        return response?.data?.data?.journalId;
                    }
                }
            }
        }
    };

    // const handleBlurEvent = () => {
    //     if(windowDimensions.width > BREAKPOINTS_VALUE.TABLET && titleValue){
    //         addEditNewJournal()
    //     }
    // }

    const collapseAndUnCollapse = (collapse = true) => {
        let journalShareDiv = document.getElementById('journal-share');
        let newPostQuillContentDiv = document.getElementById('new-post-quill-content');

        if (collapse) {
            journalShareDiv.style.overflow = '';
            journalShareDiv.style.height = '';
            newPostQuillContentDiv.style.overflow = '';
            newPostQuillContentDiv.style.height = 'calc(100% - 100px)';
            return;
        }

        journalShareDiv.style.overflow = 'hidden';
        journalShareDiv.style.height = '100%';
        newPostQuillContentDiv.style.overflow = 'scroll';

        if (windowDimensions.width < BREAKPOINTS_VALUE.TABLET) {
            newPostQuillContentDiv.style.height = 'calc(100vh - 350px)';
        }
        return;
    };

    const convertBase64ToObject = (journalShareElement, inputElement) => {
        const replaceElement = document.getElementById('replace-element-to-snapshot');
        const rmChildList = document.getElementById('replace-element-to-snapshot').childNodes;

        const newElement = document.createElement('p');
        newElement.className = 'title-input-root';
        newElement.style = 'margin: 0 0 5px';
        newElement.innerHTML = `${titleValue}`;

        replaceElement.replaceChild(newElement, rmChildList[0]);

        let fileData = html2canvas(journalShareElement).then((canvas) => {
            const data = canvas.toDataURL('image/jpeg');
            return fetch(data)
                .then((res) => res.blob())
                .then((blob) => {
                    let file = new File([blob], 'journal-test.jpeg', {
                        lastModified: new Date().getTime(),
                        type: blob.type
                    });
                    return file;
                });
        });

        let oldData = document.getElementById('replace-element-to-snapshot').childNodes;
        replaceElement.replaceChild(inputElement, oldData[0]);

        return fileData;
    };

    const handleHtml2Canvas = async () => {
        let imageTestUrl = '',
            recentlySaveJournalId;

        if (!cardSelect) recentlySaveJournalId = await addEditNewJournal('save');

        if (!titleValue && !quillValues) {
            dispatch(showToast('Please write something in journal to share.', 'error'));
            return imageTestUrl;
        }

        setIsLoading(true);

        collapseAndUnCollapse();

        let journalShareElement = document.getElementById('journal-share');
        const inputElement = document.getElementById('replace-element-to-snapshot').childNodes[0];

        const fd = new FormData();

        let convertResponse = await convertBase64ToObject(journalShareElement, inputElement);

        collapseAndUnCollapse(false);

        if (!convertResponse) return;

        fd.append('multipartFile', convertResponse);

        let response = await API.post(API_URL?.JOURNAL_SHARE, {
            params: {
                journalId: cardSelect ? cardSelect : recentlySaveJournalId
            },
            data: fd,
            isMultiPart: true
        });

        setIsLoading(false);

        if (response) {
            imageTestUrl = response?.data?.data;
            return imageTestUrl;
        }

        return imageTestUrl;
    };

    const handleDeleteNote = async () => {
        setDeleteDialogOpen(false);
        if (cardSelect) {
            let response = await API.delete(API_URL.JOURNAL_API, {
                params: { journalId: cardSelect }
            });
            if (response) {
                EmptyNoteHandler();
                dispatch(showToast(response?.data?.message));
                setRefreshData((prevState) => !prevState);
            }
        } else {
            // alert('Please select the note you want to delete');
            return null;
        }
    };

    const openDeleteDialog = () => {
        if (cardSelect) {
            setDeleteDialogOpen(true);
            setDateValue(moment(new Date()).format('MMM D, YYYY'));
        }
    };

    const handleJournalSearch = useCallback(() => {
        let tempSearchCardData = cardsData.filter(
            (card) =>
                card.title.toLowerCase().match(searchKeyword.toLowerCase()) ||
                card.journalEntry.toLowerCase().match(searchKeyword.toLowerCase())
        );
        setSearchCardData(tempSearchCardData);
    }, [cardsData, searchKeyword]);

    useEffect(() => {
        handleJournalSearch();
    }, [handleJournalSearch]);

    const handleNextLine = (e) => {
        if (e.keyCode === 13) {
            journalNote.current.focus();
        }
    };

    const handleReactQuill = (value) => {
        if (value === '<p><br></p>' || value === '<p>&nbsp;</p>') {
            setQuillValues('');
            return null;
        }
        let modifiedValue = null;

        if (value.indexOf('<p><br></p>') > -1) {
            modifiedValue = value.replace(/<p><br><\/p>/gi, '<p>&nbsp;</p>');
        }

        if (value.indexOf('<s>')) {
            value.replace(/<s>/g, '<strike>');
        }

        if (value.indexOf('</s>')) {
            value.replace(/<\/s>/g, '</strike>');
        }

        let finalValue = modifiedValue || value;

        setQuillValues(finalValue);
    };

    const handleSearchReset = () => {
        setSearchKeyword('');
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (titleValue || quillValues) {
                setSaveBoxShadowFlag(true);
            } else {
                setSaveBoxShadowFlag(false);
            }
        }, 100);

        return () => clearTimeout(delayDebounceFn);
    }, [titleValue, quillValues]);

    useEffect(() => {
        journalTitle?.current?.focus();
        return () => {
            setLatestNote(false);
        };
    }, []);

    return (
        <>
            <TaoCalligraphyWatermark />
            <JournalMainWrapper id="wrapper">
                <TaoLoader isLoading={isLoading} />

                <Box className="container">
                    <Box className={`left ${(latestNote || cardSelect) && 'hidden'}`}>
                        <Box className="heading flex f-v-center f-h-space-between left-right-padding">
                            <Typography className="title">Journal</Typography>
                            <Button
                                variant="contained"
                                className="add-new-note"
                                onClick={handleNewNote}
                                endIcon={<EditIconImg />}
                                disabled={
                                    windowDimensions.width <= BREAKPOINTS_VALUE.TABLET
                                        ? false
                                        : latestNote
                                }>
                                New
                            </Button>
                            {/* <IconButton
                                className="add-new-note-icon flex f-v-center f-h-center"
                                onClick={handleNewNote}
                                classes={{ disabled: 'disable-color-btn' }}
                                disabled={
                                    windowDimensions.width <= BREAKPOINTS_VALUE.TABLET
                                        ? false
                                        : !cardSelect
                                }>
                                <EditIconImg />
                            </IconButton> */}
                        </Box>
                        <Box className="search-box left-right-padding">
                            <Input
                                className="search"
                                placeholder="Search"
                                disableUnderline
                                value={searchKeyword}
                                classes={{ root: 'input-root' }}
                                onChange={(e) => setSearchKeyword(e?.target?.value)}
                                startAdornment={<SearchIconImg className="start-icon" />}
                                endAdornment={
                                    <InputAdornment
                                        position="end"
                                        className="search-input-text-icon hover">
                                        {!!searchKeyword && (
                                            <IconButton
                                                className="search-clear-icon"
                                                onClick={handleSearchReset}>
                                                <CrossIcon />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                }
                            />
                        </Box>
                        <TaoScrollbar displayScroll customStyle={{ padding: '0 5px 0 0' }}>
                            {searchCardData?.length > 0 ? (
                                <Box className="note-box">
                                    {!!searchCardData.filter((card) => card.isPinned).length && (
                                        <Box className="pinned-box">
                                            <Box className="pinned-text flex f-v-center f-h-space-between left-right-padding">
                                                <Typography className="secondary-title">
                                                    Pinned
                                                </Typography>
                                                <Typography className="subtitle">
                                                    Swipe right to pin/unpin
                                                </Typography>
                                            </Box>
                                            {!!searchCardData.filter((card) => card.isPinned)
                                                .length && (
                                                <Box className="pinned-cards">
                                                    {searchCardData
                                                        .filter((card) => card.isPinned === true)
                                                        .map((item) => (
                                                            <JournalCard
                                                                key={item?.journalId}
                                                                isPinned={item?.isPinned}
                                                                title={item?.title}
                                                                body={item?.journalEntry}
                                                                date={item?.date}
                                                                id={item?.journalId}
                                                                setRefreshData={setRefreshData}
                                                                cardSelect={cardSelect}
                                                                setCardSelect={setCardSelect}
                                                                EmptyNoteHandler={EmptyNoteHandler}
                                                                searchKeyword={searchKeyword}
                                                                setDateValue={setDateValue}
                                                            />
                                                        ))}
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                    {!!searchCardData.filter((card) => !card.isPinned).length && (
                                        <Box className="date-box">
                                            <Box className="left-right-padding">
                                                <Typography className="secondary-title">
                                                    {' '}
                                                    By Date
                                                </Typography>
                                            </Box>
                                            {!!searchCardData.filter((card) => !card.isPinned)
                                                .length && (
                                                <Box>
                                                    {searchCardData
                                                        .filter((card) => !card.isPinned)
                                                        .map((item) => (
                                                            <JournalCard
                                                                key={item?.journalId}
                                                                isPinned={item?.isPinned}
                                                                title={item?.title}
                                                                body={item?.journalEntry}
                                                                date={item?.date}
                                                                id={item?.journalId}
                                                                setRefreshData={setRefreshData}
                                                                cardSelect={cardSelect}
                                                                setCardSelect={setCardSelect}
                                                                EmptyNoteHandler={EmptyNoteHandler}
                                                                searchKeyword={searchKeyword}
                                                                setDateValue={setDateValue}
                                                            />
                                                        ))}
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            ) : (
                                <Box className="empty-journal flex f-v-center f-h-center">
                                    <Typography className="text">No Entries Found </Typography>
                                    {searchKeyword && !searchCardData?.length && (
                                        <>
                                            <Typography className="no-searched-data normal elipsis">
                                                For
                                            </Typography>
                                            <Typography className="no-searched-data elipsis">
                                                "{searchKeyword}"
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            )}
                        </TaoScrollbar>
                    </Box>
                    <Box className={`right ${!latestNote && !cardSelect && 'hidden'}`}>
                        <Box className="tablet-display custom-icon-section ">
                            <IconButton
                                className="extra-feture-icon flex f-h-center f-v-center"
                                size="small"
                                onClick={handleResponsiveView}>
                                <BackArrowIconImg />
                            </IconButton>
                            <Box className="flex">
                                {/* <IconButton
                                        className="extra-feture-icon flex f-h-center f-v-center"
                                        size="small">
                                        <ShareIconImg />
                                    </IconButton> */}
                                <IconButton
                                    className={`extra-feture-icon save-icon flex f-h-center f-v-center desktop-display ${
                                        saveBoxShadowFlag && 'save-box-shadow'
                                    }`}
                                    disabled={!saveBoxShadowFlag}
                                    onClick={() => addEditNewJournal('save')}>
                                    <SaveIcon />
                                </IconButton>
                                {/* <IconButton>
                                        <Button onClick={() => console.log('HERE')}>Share</Button>
                                    </IconButton> */}
                                {cardSelect && (
                                    <IconButton
                                        className="extra-feture-icon delete-icon flex f-h-center f-v-center"
                                        size="small"
                                        onClick={openDeleteDialog}>
                                        <DeleteIconImg />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                        <Box className="buttonSection flex f-v-center ">
                            <CustomToolBar />
                            <IconButton
                                className={`extra-feture-icon save-icon flex f-h-center f-v-center desktop-display ${
                                    saveBoxShadowFlag && 'save-box-shadow'
                                }`}
                                disabled={!saveBoxShadowFlag}
                                onClick={addEditNewJournal}>
                                <SaveIcon />
                            </IconButton>
                            {cardSelect && (
                                <IconButton
                                    className="extra-feture-icon delete-icon flex f-h-center f-v-center desktop-display"
                                    size="small"
                                    onClick={openDeleteDialog}>
                                    <DeleteIconImg />
                                </IconButton>
                            )}
                            <ShareSocial
                                title={'Journal Title'}
                                callFetchShorterLink={false}
                                handleCustomCall={() => handleHtml2Canvas()}
                                details={'Journal Description'}
                            />
                        </Box>
                        <Box
                            id="journal-share"
                            style={{
                                background: '#fdfaf1',
                                borderRadius: '30px',
                                height: '100%',
                                overflow: 'hidden'
                            }}>
                            <Box className="selectedNote" id="replace-element-to-snapshot">
                                <TextField
                                    className="title"
                                    autoFocus
                                    autoComplete="off"
                                    InputProps={{
                                        classes: {
                                            root: 'title-input-root'
                                        },
                                        disableUnderline: true
                                    }}
                                    variant="standard"
                                    placeholder="Untitled"
                                    value={titleValue}
                                    inputRef={journalTitle}
                                    onKeyUp={(e) => handleNextLine(e)}
                                    onChange={(e) => setTitleValue(e.target.value)}
                                />
                                <Typography className="selectNoteData" mb={2}>
                                    {dateValue}
                                </Typography>
                            </Box>
                            <ReactQuill
                                id="new-post-quill-content"
                                rows="5"
                                modules={modules}
                                formats={formats}
                                value={quillValues}
                                onChange={handleReactQuill}
                                placeholder="Note"
                                ref={journalNote}
                            />
                        </Box>
                    </Box>
                </Box>
            </JournalMainWrapper>
            {deleteDialogOpen && (
                <DeleteConfirmation
                    title="Delete this journal entry?"
                    subTitle="(This cannot be undone)"
                    onClose={() => setDeleteDialogOpen(false)}
                    onConfirm={handleDeleteNote}
                />
            )}
            {/* 
            {open && (
                <ShareSocial
                    title={'Journal Title'}
                    callFetchShorterLink={false}
                    url={imageUrl}
                    details={'This this journal description'}
                />
            )} */}
        </>
    );
}

const CustomToolBar = ({ ...props }) => {
    return (
        <>
            <Box className="toolbar">
                <Box className="formatIcons">
                    <button className="ql-bold quill-btn">B</button>
                    <button className="ql-italic quill-btn">I</button>
                    <button className="ql-underline quill-btn">U</button>
                    <button className="ql-strike quill-btn">S</button>
                </Box>

                <Box className="orderListIcons">
                    <button className="ql-unOrderList quill-btn">
                        <UnorderedListIcon />
                    </button>
                    <button className="ql-orderList quill-btn">
                        <OrderListIcon />
                    </button>
                </Box>

                <Box className="indentIcons">
                    <button className="ql-customIndent quill-btn">
                        <IndentIcon />
                    </button>
                    <button className="ql-outdent quill-btn">
                        <OutdentIcon />
                    </button>
                </Box>
            </Box>
        </>
    );
};

const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'align'
];

const modules = {
    toolbar: {
        container: '.toolbar',
        handlers: {
            unOrderList: function (params) {
                const listFormat = this.quill.getFormat()?.list;
                if (listFormat === 'bullet') {
                    this.quill.format('list', false);
                } else {
                    this.quill.format('list', 'bullet');
                }
            },

            orderList: function () {
                const listFormat = this.quill.getFormat()?.list;
                if (listFormat === 'ordered') {
                    this.quill.format('list', false);
                } else {
                    this.quill.format('list', 'ordered');
                }
            },

            outdent: function () {
                this.quill.format('indent', '-1');
            },
            customIndent: function () {
                this.quill.format('indent', '+1');
            }
        }
    },
    clipboard: {
        matchVisual: false
    },
    keyboard: {
        bindings: {
            tab: {
                key: 9,
                handler: () => {
                    store?.dispatch(
                        showToast(
                            'Tab is disabled please use indentation from above toolbar',
                            'info'
                        )
                    );
                }
            }
        }
    }
};

export default Journal;
