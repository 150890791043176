import styled from 'styled-components';
import { Box } from '@mui/material';
import { responsive, FONTS, COLORS } from 'Styles/Constants';

export const AddParticipantToChatModalWrapper = styled(Box)`
    position: absolute;
    /* top: 15%; */
    top: 25%;
    left: 33%;
    z-index: 100;
    background-color: #fff;
    width: 555px;
    height: auto;
    border-radius: 25px;
    padding: 15px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(40px);
    gap: 30px;

    ${responsive.LAPTOP_1050`
        top: 25%;
        left: 30%;
    `}

    ${responsive.TABLET`
        top: unset;
        right: unset;
        left: unset;
        width: 100%;
        height: 100%;
        border-radius: 0px;
    `}

    .close-modal-button {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
    }

    .add-participant-to-session-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .session-details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .session-title {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 24px;
                line-height: 108.1%;
                letter-spacing: -0.01em;
                color: ${COLORS.PRIMARY_WARM};
            }

            .host-name {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 12px;
                line-height: 108.1%;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: ${COLORS.PRIMARY_DARK};
                mix-blend-mode: normal;
            }

            .invite-user-text {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                color: ${COLORS.TEXT_DARK};
                mix-blend-mode: normal;
            }

            .user-email-address {
                font-family: ${FONTS.PRIMARY};
                font-weight: 700;
                font-size: 20px;
                line-height: 29px;
                color: ${COLORS.TEXT_DARK};
                mix-blend-mode: normal;
            }

            .confirmation {
                color: ${COLORS.TEXT_DARK};
            }

            .email-address {
                width: 400px;
                border-radius: 8px;
            }
        }

        .add-participant-to-session-send-invite-button {
            font-family: ${FONTS.PRIMARY};
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            border-radius: 12px;
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
            color: #ffffff;
            width: 400px;
            height: 50px;
            margin-bottom: 50px;

            ${responsive.TABLET`
                width: 360px;
            `}
        }

        .add-participant-to-session-action-buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            width: 400px;
            margin-bottom: 50px;

            ${responsive.TABLET`
                width: 360px;
            `}

            .MuiButton-root {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.05em;
                text-transform: uppercase;

                border-radius: 12px;
                background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                color: #ffffff;
                width: 100%;
                height: 50px;
            }

            .close-button {
                background-color: #ffffff;
                color: ${COLORS.PRIMARY_DARK};
            }
        }
    }
`;
