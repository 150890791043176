//core
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import {
    Box,
    Typography,
    Divider,
    MenuItem,
    RadioGroup,
    Radio,
    FormControl,
    Button,
    InputLabel,
    Select,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    FormHelperText
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';

//custom component
import {
    ProfileSettingSectionWrapper,
    PrivacyPolicyWrapper,
    DialogWrapper,
    CustomRadioCheckedIcon,
    CustomRadioIcon
} from './Settings.style';
import Api from 'Helpers/ApiHandler';
import { API_URL, URL_HOME_PAGE } from 'Helpers/Paths';
import { getWindowDimensions } from 'Helpers';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
// import { TIME_MENU } from 'Components/common/MeditationTarget/MeditationTimerValues';

//icons
import { ReactComponent as RightArrowIcon } from 'Assets/images/Common/next-arrow.svg';
import { ReactComponent as BellOffIcon } from 'Assets/images/Profile/bell-off.svg';
import { ReactComponent as ThumbsUpIcon } from 'Assets/images/Profile/thumbs-up.svg';
import { ReactComponent as CloseIcon } from 'Assets/images/Common/close-icon.svg';
import { ReactComponent as WindowsPc } from 'Assets/images/Profile/windows.svg';
import { ReactComponent as OtherDevice } from 'Assets/images/Profile/other.svg';
import { ReactComponent as MacPc } from 'Assets/images/Profile/mac.svg';
import { ReactComponent as AndroidPhone } from 'Assets/images/Profile/android-phone.svg';
import { ReactComponent as IPhone } from 'Assets/images/Profile/i-phone.svg';
import { ReactComponent as SelectDropDownIcon } from 'Assets/images/Common/down-arrow.svg';

import { logoutUser, updateUserInfo } from 'Redux/Auth/Actions';
import { useDispatch } from 'react-redux';
import CODES from 'Helpers/StatusCodes';
import { DeleteConfirmation } from 'Components/common/Modal/Delete';
import { showToast } from 'Redux/App/Actions';
import { CommunityGuidelines, PrivacyPolicy, TermsCondition } from '../../Policies';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { CustomSwitch } from 'Components/common/Switch.style';
import TaoScrollbar from 'Components/common/Scrollbar';
import usePermissions from '../../../common/Permissions';
import { ACCESS_LEVELS_MODULEs } from 'Helpers/Constants';
import { SubscriptionLightLock } from 'Components/common/SubscriptionLock';

import { showSubscriptionModal } from 'Redux/App/Actions';
import { useNavigate } from 'react-router-dom';
const initialValues = {
    natureOfFeedback: '',
    feedbackMessage: ''
};

const userFeedBackValidation = Yup.object({
    natureOfFeedback: Yup.string().required('Please select your feedback nature'),
    feedbackMessage: Yup.string().required('Please enter your feedback message')
});

const PAGE = {
    PRIVACY_POLICY: 'PRIVACY_POLICY',
    TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
    COMMUNITY_GUIDELINES: 'COMMUNITY_GUIDELINES'
};

const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 50
};

const CustomRadioButton = (props) => {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent'
                }
            }}
            className="radio-button"
            disableRipple
            color="default"
            checkedIcon={<CustomRadioCheckedIcon />}
            icon={<CustomRadioIcon />}
            {...props}
        />
    );
};

const DeleteAccountDialogUI = ({ handleDeleteAccount, setOpenDeleteAccountDialog }) => {
    return (
        <DeleteConfirmation
            title="Are you sure you want to delete your account?"
            onClose={() => {
                setOpenDeleteAccountDialog(false);
            }}
            onConfirm={() => {
                handleDeleteAccount();
            }}
        />
    );
};

const CustomSwitches = ({ checked, setSwitchValues, target, ...props }) => {
    return (
        <CustomSwitch
            checked={checked}
            classes={{
                root: 'switch-root',
                track: `switch-track ${checked && 'active'}`,
                thumb: `switch-thumb ${checked && 'active'}`
            }}
            onChange={(e) =>
                setSwitchValues((prev) => {
                    return {
                        ...prev,
                        [target]: e.target.checked
                    };
                })
            }
        />
    );
};

const ProfileSettings = (props) => {
    const navigate = useNavigate();
    const modulePermission = usePermissions(ACCESS_LEVELS_MODULEs?.SEND_APP_FEEDBACK);
    const API = useMemo(() => new Api(), []);
    const feedbackFormRef = useRef(null);

    const [relaunchTutorialModelShow, setRelaunchTutorialModelShow] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [appLanguage, setAppLanguage] = useState([]);
    const [sessionReminderTimeInMinute, setSessionReminderTimeInMinute] = useState(0);
    const [switchValues, setSwitchValues] = useState({
        isInAppNotificationEnabled: false,
        isEmailNotificationEnabled: false,
        isZenModeEnabled: false,
        isSilenceDuringMeditation: false,
        isSilenceDuringSession: false,
        isSilenceDuringOtherApps: false,
        is12HourFormat: false
    });

    const [feedBackDialog, setFeedBackDialog] = useState(false);
    const [openPage, setOpenPage] = useState(false);
    const [policyTitle, setPolicyTitle] = useState('');
    const [pageTitle, setPageTitle] = useState('');

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [openDeleteAccountDialog, setOpenDeleteAccountDialog] = useState(false);
    const dispatch = useDispatch();
    const [userEmailID, setUserEmailID] = useState('');
    const userDetails = useSelector((state) => state.Auth.userInfo);
    const [settingData, setSettingData] = useState([]);
    const [reload, setReload] = useState(true);

    const getSettingApi = useCallback(async () => {
        props.setIsLoading(true);
        let response = await API.get(API_URL.PROFILE_SETTINGS);
        if (response) {
            setSettingData(response?.data?.data);
            setSwitchValues({
                isEmailNotificationEnabled: response?.data?.data?.isEmailNotificationEnabled,
                isInAppNotificationEnabled: response?.data?.data?.isInAppNotificationEnabled,
                isSilenceDuringMeditation: response?.data?.data?.isSilenceDuringMeditation,
                isSilenceDuringOtherApps: response?.data?.data?.isSilenceDuringOtherApps,
                isSilenceDuringSession: response?.data?.data?.isSilenceDuringSession,
                isZenModeEnabled: response?.data?.data?.isZenModeEnabled,
                is12HourFormat: response?.data?.data?.is12HourFormat
            });
            setAppLanguage(response?.data?.data?.appLanguage);
            setSessionReminderTimeInMinute(response?.data?.data?.sessionReminderTime);
            props.setIsLoading(false);
            setUserEmailID(JSON.parse(localStorage.getItem('userInfo'))?.email);
        }
    }, [API, props]);

    const getLang = useCallback(async () => {
        let response = await API.get(API_URL.LANGUAGES);

        if (response) {
            setLanguages(response?.data?.data);
        }
    }, [API]);

    const handleProfileSettingSubmit = async () => {
        props.setIsLoading(true);
        const allSwitchValues = {
            isInAppNotificationEnabled: switchValues.isInAppNotificationEnabled,
            isEmailNotificationEnabled: switchValues.isEmailNotificationEnabled,
            isZenModeEnabled: switchValues.isZenModeEnabled,
            isSilenceDuringMeditation: switchValues.isZenModeEnabled
                ? switchValues.isSilenceDuringMeditation
                : false,
            isSilenceDuringSession: switchValues.isZenModeEnabled
                ? switchValues.isSilenceDuringSession
                : false,
            isSilenceDuringOtherApps: switchValues.isZenModeEnabled
                ? switchValues.isSilenceDuringOtherApps
                : false,
            is12HourFormat: switchValues.is12HourFormat
        };
        let data = {
            ...allSwitchValues,
            sessionReminderTimeInMinute: sessionReminderTimeInMinute,
            appLanguage: appLanguage
        };
        let response = await API.put(API_URL.PROFILE_SETTINGS, { data });

        if (response) {
            props.setIsLoading(false);
            dispatch(updateUserInfo({ ...userDetails, ...allSwitchValues }));
            dispatch(showToast('Settings edited successfully', 'success'));
            if (!switchValues.isZenModeEnabled) {
                setSwitchValues((prev) => {
                    return {
                        ...prev,
                        isSilenceDuringMeditation: false,
                        isSilenceDuringSession: false,
                        isSilenceDuringOtherApps: false
                    };
                });
            }
        }
    };

    const openFeedBackDialog = () => {
        feedbackFormRef?.current?.setFieldValue('natureOfFeedback', '');
        feedbackFormRef?.current?.setFieldValue('feedbackMessage', '');
        setFeedBackDialog(true);
    };

    const sendFeedbackHandler = async (values) => {
        setFeedBackDialog(false);
        props.setIsLoading(true);
        let data = {
            feedbackNature: values?.natureOfFeedback,
            message: values?.feedbackMessage
        };
        let response = await API.post(API_URL.FEEDBACK, {
            data
        });

        if (response) {
            props.setIsLoading(false);
            dispatch(showToast('Thank you for sharing your heart with us!', 'success'));
        }
    };

    const handlePolicyDialog = (pageType) => {
        setPolicyTitle(pageType);
        if (pageType === PAGE.PRIVACY_POLICY) {
            setPageTitle('Privacy Policy');
        }
        if (pageType === PAGE.TERMS_AND_CONDITIONS) {
            setPageTitle('Term & Conditions');
        }
        if (pageType === PAGE.COMMUNITY_GUIDELINES) {
            setPageTitle('Community Guidelines');
        }

        // setPolicyDescription(policyData[policyNo].description);
        setOpenPage(true);
    };

    const handleDeleteAccount = async () => {
        let response = await API.delete(API_URL.DELETE_ACCOUNT);
        if (response?.status === CODES.SUCCESS) {
            setOpenDeleteAccountDialog(false);
            dispatch(logoutUser());
        }
    };

    const devicesPicHandler = (systemType) => {
        switch (systemType.toLowerCase()) {
            case 'mac/ios':
                return <MacPc />;
            case 'windows 10':
                return <WindowsPc />;
            case 'android':
                return <AndroidPhone />;
            case 'ios':
                return <IPhone />;
            // case 'Linux':
            //     return <LinuxIcon />;
            default:
                return <OtherDevice />;
        }
    };

    const handleLoginTimePeriod = (values) => {
        let timePeriod = moment(values.lastUsedDateTime, 'YYYY-MM-DD HH:mm:ss').fromNow();

        if (timePeriod === 'a month ago') {
            return '1 month ago';
        }

        return timePeriod;
    };

    const handleLogoutDevices = async (sessionId) => {
        props.setIsLoading(true);
        const response = await API.post(API_URL.LOGOUT_DEVICES, {
            params: {
                sessionId
            }
        });

        if (response) {
            setReload((prev) => !prev);
        }
    };

    const handleMascotTour = async () => {
        dispatch(
            updateUserInfo({
                ...userDetails,
                isMascotTutorialCompleted: false
            })
        );
        navigate(URL_HOME_PAGE);
        setRelaunchTutorialModelShow(false);
    };

    const handleRelauchTutorialModel = () =>
        setRelaunchTutorialModelShow(!relaunchTutorialModelShow);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        getSettingApi();
    }, [getSettingApi, reload]);

    useEffect(() => {
        getLang();
    }, [getLang]);

    return (
        <ProfileSettingSectionWrapper>
            <TaoScrollbar
                customStyle={{ borderRadius: '30px 30px 0 0', margin: '20px 0 0' }}
                displayScroll>
                <Box className="container">
                    <Box className="notification">
                        <Typography className="sub-title"> notifications</Typography>
                        <Box className="flex f-h-space-between f-v-center" mb={'10px'}>
                            <Typography className="body-text"> in-app notifications</Typography>

                            <CustomSwitches
                                checked={switchValues.isInAppNotificationEnabled}
                                target="isInAppNotificationEnabled"
                                setSwitchValues={setSwitchValues}
                            />
                        </Box>
                        <Box className="flex f-column">
                            <Box mb={'10px'}>
                                <Box className="flex f-h-space-between f-v-center">
                                    <Typography className="body-text">
                                        {' '}
                                        email notifications
                                    </Typography>

                                    <CustomSwitches
                                        checked={switchValues.isEmailNotificationEnabled}
                                        target="isEmailNotificationEnabled"
                                        setSwitchValues={setSwitchValues}
                                    />
                                </Box>
                                <Typography className="sub-title user-email">
                                    {userEmailID}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="flex f-h-space-between f-v-center">
                            <Typography className="body-text"> zen mode</Typography>

                            <CustomSwitches
                                checked={switchValues.isZenModeEnabled}
                                target="isZenModeEnabled"
                                setSwitchValues={setSwitchValues}
                            />
                        </Box>
                        <Box className="flex f-v-center">
                            <RightArrowIcon
                                className={`hover rightArrowIcon ${
                                    switchValues?.isZenModeEnabled && 'open-options'
                                }`}
                                onClick={() =>
                                    setSwitchValues((prev) => {
                                        return {
                                            ...prev,
                                            isZenModeEnabled: !prev.isZenModeEnabled
                                        };
                                    })
                                }
                            />
                            <Typography
                                className="sub-title silence-notification"
                                onClick={() =>
                                    setSwitchValues((prev) => {
                                        return {
                                            ...prev,
                                            isZenModeEnabled: !prev.isZenModeEnabled
                                        };
                                    })
                                }>
                                {!switchValues?.isZenModeEnabled &&
                                    'Silences notifications while you meditate'}
                            </Typography>
                        </Box>
                        <Box
                            className={`zenMode-options ${
                                !switchValues?.isZenModeEnabled && 'options-closed'
                            }`}>
                            <Divider orientation="vertical" className="divider" />
                            <Box className="zen-notification-options">
                                <Typography className="sub-title silence-notification flex f-v-center">
                                    <BellOffIcon className="bell-icon" /> When to silence:
                                </Typography>
                                <Box className="flex f-h-space-between f-v-center">
                                    <Typography className="body-text">
                                        During meditations
                                    </Typography>

                                    <CustomSwitches
                                        checked={switchValues.isSilenceDuringMeditation}
                                        target="isSilenceDuringMeditation"
                                        setSwitchValues={setSwitchValues}
                                    />
                                </Box>
                                {/* <Box className="flex f-h-space-between f-v-center">
                                    <Typography className="body-text">
                                        During live sessions
                                    </Typography>

                                    <CustomSwitches
                                        checked={switchValues.isSilenceDuringSession}
                                        target="isSilenceDuringSession"
                                        setSwitchValues={setSwitchValues}
                                    />
                                </Box> */}
                                {/* <Box className="flex f-h-space-between f-v-center">
                                    <Typography className="body-text">from other apps</Typography>

                                    <CustomSwitches
                                        checked={switchValues.isSilenceDuringOtherApps}
                                        target="isSilenceDuringOtherApps"
                                        setSwitchValues={setSwitchValues}
                                    />
                                </Box> */}
                            </Box>
                        </Box>

                        {/* <Typography className="sub-title reminder-notification">
                            session reminder notification
                        </Typography>
                        <Box className="flex f-v-center remainder">
                            <FormControl
                                variant="filled"
                                className="remainder-selector"
                                >
                                <InputLabel id="time">Time</InputLabel>
                                <Select
                                    IconComponent={SelectDropDownIcon}
                                    labelId="time"
                                    variant="filled"
                                    className="selector"
                                    disableUnderline
                                    inputProps={{
                                        className: 'filled-select'
                                    }}
                                    value={
                                        sessionReminderTimeInMinute === null
                                            ? 0
                                            : sessionReminderTimeInMinute
                                    }
                                    onChange={(e) =>
                                        setSessionReminderTimeInMinute(e.target.value)
                                    }>
                                    <MenuItem value={0}>Select</MenuItem>
                                    {TIME_MENU.map((min) => (
                                        <MenuItem
                                            key={min.id}
                                            className="menu-items"
                                            value={min.time}>
                                            {min.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography className="body-text before-session">
                                before session
                            </Typography>
                        </Box> */}
                        <Box className="time-format-switch flex f-column">
                            <Typography className="time-format-switch-label">
                                Time Display
                            </Typography>
                            <Box
                                className={`flex f-v-center farmer-motion-switch ${
                                    switchValues?.is12HourFormat ? 'start' : 'end'
                                }`}
                                onClick={() =>
                                    setSwitchValues((prev) => {
                                        return { ...prev, is12HourFormat: !prev.is12HourFormat };
                                    })
                                }>
                                <Typography
                                    className={`${
                                        switchValues?.is12HourFormat ? 'twenty-four' : 'twelve'
                                    } hour-text-style`}>
                                    {switchValues?.is12HourFormat ? '24 hour' : '12 hour'}
                                </Typography>
                                <motion.div
                                    className="switch-handle flex f-v-center f-h-center"
                                    layout
                                    transition={spring}>
                                    <Typography className="active-hour-text-style ">
                                        {switchValues?.is12HourFormat ? '12 hour' : '24 hour'}
                                    </Typography>
                                </motion.div>
                            </Box>
                        </Box>

                        <Box className="languages-section">
                            <Typography className="sub-title lang-title">App Language</Typography>
                            <RadioGroup
                                value={appLanguage}
                                className="radio-button-group"
                                onChange={(e) => setAppLanguage(e.target.value)}>
                                {languages.map((language) => (
                                    <Box
                                        key={language.languageId}
                                        sx={{ display: 'block', width: '100%' }}>
                                        <Divider className="lang-divider" />
                                        <Box
                                            className="flex f-h-space-between f-v-center"
                                            my={'6px'}>
                                            <Typography className="body-text language-font">
                                                {language.language}
                                            </Typography>

                                            <CustomRadioButton value={language.languageId} />
                                        </Box>
                                    </Box>
                                ))}
                            </RadioGroup>
                            <Box className="room-section-lang-section">
                                <Button
                                    className="tutorial-button"
                                    variant="contained"
                                    onClick={handleProfileSettingSubmit}>
                                    save
                                </Button>
                            </Box>

                            <Box className="devices-section">
                                <Typography className="devices-title">your devices</Typography>
                                <Typography className="devices-subtitle">
                                    You’re logged in to Master Sha on the following devices:
                                </Typography>

                                <Box className="devices-list flex f-column">
                                    {!!settingData?.deviceData?.length &&
                                        settingData?.deviceData.map((values, index) => (
                                            <Box
                                                key={values.sessionId}
                                                className="whole-devices-card">
                                                <Box
                                                    key={values.sessionId}
                                                    className="devices-card">
                                                    <Box className="devices-type flex f-column f-v-center">
                                                        {devicesPicHandler(values.systemType)}
                                                        <Typography className="device-name elipsis">
                                                            {values?.systemType === 'other'
                                                                ? 'Other'
                                                                : values?.systemType}
                                                        </Typography>
                                                    </Box>
                                                    <Box className="devices-details">
                                                        <Typography className="devices-location">
                                                            {values.location}
                                                        </Typography>
                                                        <Typography className="devices-location">
                                                            {values.browserType}
                                                        </Typography>
                                                        {values?.isThisDevice ? (
                                                            <Typography className="this-device">
                                                                this device
                                                            </Typography>
                                                        ) : (
                                                            <Box className="flex f-wrap" gap={1}>
                                                                <Typography className="devices-last-used static">
                                                                    Last Used :
                                                                </Typography>
                                                                <Typography className="devices-last-used">
                                                                    {handleLoginTimePeriod(values)}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                    <Box className="logout flex f-v-center f-h-center">
                                                        <Button
                                                            className="logout-btn"
                                                            classes={{
                                                                text: 'logout-btn-text'
                                                            }}
                                                            onClick={() =>
                                                                handleLogoutDevices(
                                                                    values?.sessionId
                                                                )
                                                            }>
                                                            logout
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                </Box>
                            </Box>
                        </Box>
                        <Box className="app-details">
                            {/* <Box className="tutorial">
                                <Typography className="sub-title">interactive tutorial</Typography>
                                <Button className="tutorial-button" variant="contained">
                                    relaunch tutorial
                                </Button>
                            </Box> */}
                            <Box className="information">
                                <Typography className="sub-title">Interactive tutorial</Typography>
                                <Button
                                    className="feedback-button"
                                    variant="contained"
                                    onClick={handleRelauchTutorialModel}>
                                    relaunch tutorial
                                </Button>
                            </Box>
                            <Box className="information">
                                <Typography className="sub-title extra-margin">
                                    important info
                                </Typography>
                                <Box
                                    className="flex f-h-space-between extra-margin hover"
                                    onClick={() => handlePolicyDialog(PAGE.PRIVACY_POLICY)}>
                                    <Typography className="body-text">privacy policy</Typography>
                                    <RightArrowIcon />
                                </Box>
                                <Box
                                    className="flex f-h-space-between extra-margin hover"
                                    onClick={() => handlePolicyDialog(PAGE.TERMS_AND_CONDITIONS)}>
                                    <Typography className="body-text">
                                        Terms & conditions
                                    </Typography>
                                    <RightArrowIcon />
                                </Box>
                                <Box
                                    className="flex f-h-space-between extra-margin hover"
                                    onClick={() => handlePolicyDialog(PAGE.COMMUNITY_GUIDELINES)}>
                                    <Typography className="body-text">
                                        community guidelines
                                    </Typography>
                                    <RightArrowIcon />
                                </Box>
                            </Box>
                            <Box className="information"></Box>
                            <Button
                                className="feedback-button"
                                variant="contained"
                                startIcon={
                                    modulePermission?.canAccess && (
                                        <ThumbsUpIcon className="btn-icon" />
                                    )
                                }
                                endIcon={!modulePermission?.canAccess && <SubscriptionLightLock />}
                                onClick={() => {
                                    if (!modulePermission?.canAccess) {
                                        return dispatch(showSubscriptionModal());
                                    }
                                    openFeedBackDialog();
                                }}>
                                send us feedback
                            </Button>
                            <Button
                                sx={{
                                    '&:hover': {
                                        backgroundColor: '#BC1218',
                                        borderColor: '#BC1218'
                                    }
                                }}
                                className="delete-account-button"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setOpenDeleteAccountDialog(true);
                                }}>
                                Delete Account
                            </Button>
                        </Box>
                    </Box>
                </Box>

                <Dialog
                    open={feedBackDialog}
                    onClose={() => setFeedBackDialog(false)}
                    classes={{
                        container: 'dialog-container'
                    }}
                    sx={{
                        '& .MuiDialog-container': {
                            '& .MuiPaper-root': {
                                width: '100%',
                                borderRadius: '15px'
                            }
                        }
                    }}>
                    <Formik
                        innerRef={feedbackFormRef}
                        initialValues={initialValues}
                        onSubmit={sendFeedbackHandler}
                        validationSchema={userFeedBackValidation}>
                        {({ values, errors, touched, handleChange, handleSubmit }) => (
                            <form
                                action="post"
                                onSubmit={(e) => {
                                    e.preventDefault();

                                    if (errors.feedbackMessage && touched.feedbackMessage) {
                                        dispatch(
                                            showToast("You haven't typed a message yet :)", 'error')
                                        );
                                    } else {
                                        handleSubmit(e);
                                    }
                                }}>
                                <DialogWrapper>
                                    <DialogTitle className="flex f-v-center">
                                        <Box width={'90%'}>
                                            <Typography className="feedback-title">
                                                send us feedback
                                            </Typography>
                                        </Box>
                                        <Box width={'10%'}>
                                            <CloseIcon
                                                className="hover close-icon"
                                                onClick={() => setFeedBackDialog(false)}
                                            />
                                        </Box>
                                    </DialogTitle>
                                    <DialogContent className="flex f-column dialog-content">
                                        <FormControl
                                            variant="filled"
                                            className="remainder-selector">
                                            <InputLabel id="nature-of-feedback">
                                                Nature of Feedback
                                            </InputLabel>
                                            <Select
                                                IconComponent={SelectDropDownIcon}
                                                labelId="nature-of-feedback"
                                                variant="filled"
                                                disableUnderline
                                                className="selector"
                                                name="natureOfFeedback"
                                                value={values?.natureOfFeedback}
                                                onChange={handleChange}
                                                inputProps={{
                                                    className: 'filled-select'
                                                }}>
                                                <MenuItem value={'SuggestionForImprovement'}>
                                                    Suggestion For Improvement
                                                </MenuItem>
                                                <MenuItem value={'Compliment'}>Compliment</MenuItem>
                                            </Select>
                                            <FormHelperText error>
                                                {touched?.natureOfFeedback &&
                                                    errors?.natureOfFeedback}
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl>
                                            <TextField
                                                variant="filled"
                                                label="Your Message"
                                                className="text-field"
                                                autoComplete="off"
                                                name="feedbackMessage"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    className: 'filled-input'
                                                }}
                                                multiline
                                                maxRows={
                                                    windowDimensions.width >
                                                    BREAKPOINTS_VALUE.TABLET
                                                        ? 14
                                                        : 10
                                                }
                                                minRows={
                                                    windowDimensions.width >
                                                    BREAKPOINTS_VALUE.TABLET
                                                        ? 14
                                                        : 10
                                                }
                                                value={values?.feedbackMessage}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                        <Button className="submit-btn" type="submit">
                                            send
                                        </Button>
                                    </DialogContent>
                                </DialogWrapper>
                            </form>
                        )}
                    </Formik>
                </Dialog>
                <Dialog
                    open={openPage}
                    onClose={() => setOpenPage(false)}
                    sx={{
                        '& .MuiDialog-container': {
                            '& .MuiPaper-root': {
                                width: '100%',
                                borderRadius: '15px',
                                height: '90%'
                            }
                        }
                    }}>
                    <PrivacyPolicyWrapper>
                        <DialogTitle className="flex f-v-center">
                            <Box width={'90%'}>
                                {/* <Typography
                                    className="policy-title"
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(policyTitle)
                                    }}></Typography> */}
                                <Typography className="policy-title">{pageTitle}</Typography>
                            </Box>
                            <Box width={'10%'}>
                                <CloseIcon
                                    className="hover close-icon"
                                    onClick={() => setOpenPage(false)}
                                />
                            </Box>
                        </DialogTitle>
                        <DialogContent className="dialog-content">
                            {PAGE.PRIVACY_POLICY === policyTitle && (
                                <PrivacyPolicy customHeight={'100%'} />
                            )}
                            {PAGE.TERMS_AND_CONDITIONS === policyTitle && (
                                <TermsCondition customHeight={'100%'} />
                            )}
                            {PAGE.COMMUNITY_GUIDELINES === policyTitle && (
                                <CommunityGuidelines customHeight={'100%'} />
                            )}
                        </DialogContent>
                    </PrivacyPolicyWrapper>
                </Dialog>
                {openDeleteAccountDialog && (
                    <DeleteAccountDialogUI
                        handleDeleteAccount={handleDeleteAccount}
                        setOpenDeleteAccountDialog={setOpenDeleteAccountDialog}
                    />
                )}
            </TaoScrollbar>
            {relaunchTutorialModelShow && (
                <DeleteConfirmation
                    title="Do you want to relaunch the tutorial?"
                    onClose={handleRelauchTutorialModel}
                    onConfirm={async () => await handleMascotTour()}
                />
            )}
        </ProfileSettingSectionWrapper>
    );
};

export default ProfileSettings;
