import React, { useCallback, useMemo, useState } from 'react';
import { SidebarWrapper } from './Sidebar.style';
import ProgramIcon from 'Assets/images/Sidebar/program-icon.svg';
import HowToMeditateIcon from 'Assets/images/Sidebar/how-to-meditate.svg';

import {
    HomeMenu,
    WellnessIcon,
    SearchMenu,
    NotificationMenu,
    SubMenuOpenIcon
} from './SidebarSVG';
import { Typography, Badge, Box, ClickAwayListener } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
    URL_HOME_PAGE,
    URL_NOTIFICATIONS,
    URL_PROGRAMS,
    URL_SEARCH,
    API_URL,
    URL_WELLNESS_CATEGORY,
    URL_HOW_TO_MEDITATE
} from 'Helpers/Paths';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Api from 'Helpers/ApiHandler';
import { TitleLoader, WellnessCategoryLoader } from '../ShimmerEffectLoader/ShimmerEffectLoader';
import usePermissions from '../Permissions';
import { ACCESS_LEVELS_MODULEs } from 'Helpers/Constants';
import { showSubscriptionModal } from 'Redux/App/Actions';
import { useDispatch } from 'react-redux';
import { SubscriptionDarkLock } from 'Components/common/SubscriptionLock';

const Items = ({ setSubListState, isLoading, list, ...props }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <>
            {list?.map((child, index) => (
                <React.Fragment key={index}>
                    {isLoading && (
                        <>
                            <Box key={index} className="sub-menu-list-loader-wrapper">
                                <Box className="loader-circle">
                                    <WellnessCategoryLoader
                                        width={24}
                                        height={24}
                                        fillWidth={24}
                                        fillHeight={24}
                                        customStyle={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    />
                                </Box>
                                <Box className="loader-title">
                                    <TitleLoader
                                        width={200}
                                        height={10}
                                        fillWidth={200}
                                        fillHeight={10}
                                        marginLeft={0}
                                    />
                                </Box>
                            </Box>
                        </>
                    )}
                    {!isLoading && child?.childIcon && child?.childName && (
                        <Box
                            key={index}
                            onClick={() => {
                                setSubListState(false);
                                if (!child?.canAccess) return dispatch(showSubscriptionModal());

                                child?.urlToNavigate && navigate(child.urlToNavigate);
                                child?.categoryId &&
                                    navigate(URL_WELLNESS_CATEGORY, {
                                        state: {
                                            wellnessCategoryDetails: child
                                        }
                                    });
                            }}
                            style={{
                                cursor: 'pointer'
                            }}
                            className="flex f-v-center item-padding">
                            <Box
                                className="item-svg"
                                style={{
                                    backgroundImage: `url(${child.childIcon})`
                                }}
                            />

                            <Box className="item-text flex f-v-center">
                                <Typography className="child-text">{child.childName}</Typography>
                                {!child?.canAccess && (
                                    <SubscriptionDarkLock customStyle={{ margin: '0 0 0 5px' }} />
                                )}
                            </Box>
                        </Box>
                    )}
                </React.Fragment>
            ))}
        </>
    );
};

const WELLNESS_MENU_URLs = [URL_PROGRAMS, URL_HOW_TO_MEDITATE, URL_WELLNESS_CATEGORY];

const Sidebar = () => {
    const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
    const userInfo = useSelector((state) => state.Auth.userInfo);
    const showSideBar = useSelector((state) => state.Sidebar.showSideBar);
    const navigate = useNavigate();
    const [subListState, setSubListState] = useState(false);
    const [wellNessChildren, setWellnessChildren] = useState([]);
    const [loading, setLoading] = useState(false);
    const API = useMemo(() => new Api(), []);
    const dispatch = useDispatch();
    const wellNessPermissions = usePermissions(ACCESS_LEVELS_MODULEs.CONTENT_LIBRARY);

    const menuList = [
        {
            menuIcon: <HomeMenu fill={window.location.pathname === URL_HOME_PAGE} />,
            menuName: 'Home',
            urlToNavigate: URL_HOME_PAGE,
            children: [],
            canAccess: true
        },
        {
            id: "wellness-menu-id",
            menuIcon: <WellnessIcon fill={WELLNESS_MENU_URLs.includes(window.location.pathname)} />,
            menuName: 'Wellness',
            canAccess: true,
            children: [
                {
                    childIcon: ProgramIcon,
                    childName: 'Programs',
                    urlToNavigate: URL_PROGRAMS,
                    canAccess: usePermissions(ACCESS_LEVELS_MODULEs.SEE_PROGRAMS)?.canAccess
                },
                {
                    childIcon: HowToMeditateIcon,
                    childName: 'How-To Guides',
                    urlToNavigate: URL_HOW_TO_MEDITATE,
                    canAccess: true
                }
            ]
        },
        {
            id: "search-menu-id",
            menuIcon: <SearchMenu fill={window.location.pathname === URL_SEARCH} />,
            menuName: 'Search',
            urlToNavigate: URL_SEARCH,
            children: [],
            canAccess: usePermissions(ACCESS_LEVELS_MODULEs?.SEARCH)?.canAccess
        },
        {
            menuIcon: <NotificationMenu fill={window.location.pathname === URL_NOTIFICATIONS} />,
            menuName: 'Notifications',
            urlToNavigate: URL_NOTIFICATIONS,
            canAccess: usePermissions(ACCESS_LEVELS_MODULEs?.NOTIFICATION)?.canAccess,
            children: []
        }
    ];

    const wellnessCategory = useCallback(async () => {
        setLoading(true);
        let response = await API.get(API_URL.WELLNESS_CATEGORY);
        if (!response?.data?.data?.list?.length) return;
        let categories = [];

        response?.data?.data?.list.forEach((category) => {
            categories.push({
                categoryId: category?.categoryId,
                childIcon: category.smallIcon,
                childName: category?.categoryName,
                canAccess: wellNessPermissions?.canAccess,
                ...category
            });
        });

        setWellnessChildren(categories);
        setLoading(false);
    }, [API, wellNessPermissions?.canAccess]);

    const handleClickAway = () => {
        setSubListState(false);
    };

    useEffect(() => {
        if (wellNessPermissions?.permissionLoaded) wellnessCategory();
    }, [wellnessCategory, wellNessPermissions?.permissionLoaded]);

    return (
        <>
            <ClickAwayListener onClickAway={() => handleClickAway()}>
                {isLoggedIn && (
                    <SidebarWrapper showSideBar={showSideBar}>
                        {menuList.map((value, index) =>
                            value.children && value.children.length ? (
                                <React.Fragment key={index}>
                                    <div
                                        onClick={() => setSubListState((prevState) => !prevState)}
                                        className="menu" id={value?.id}>
                                        <Box className="menu-icon flex f-v-center f-h-center">
                                            {value?.menuIcon}
                                        </Box>

                                        <Typography
                                            className={
                                                'menu-text has-children' +
                                                (WELLNESS_MENU_URLs.includes(
                                                    window.location.pathname
                                                )
                                                    ? ' selected-menu-text'
                                                    : '')
                                            }>
                                            {value.menuName}
                                            <SubMenuOpenIcon />
                                        </Typography>
                                    </div>

                                    {subListState && (
                                        <>
                                            <div className="sub-menu-list">
                                                <Items
                                                    menu="wellness"
                                                    list={
                                                        loading
                                                            ? [1, 2, 3, 4, 5, 6]
                                                            : [...wellNessChildren]
                                                    }
                                                    setSubListState={setSubListState}
                                                    isLoading={loading}
                                                />

                                                {!!(
                                                    loading ||
                                                    (!loading && wellNessChildren.length)
                                                ) && (
                                                    <Box
                                                        className="flex f-v-center item-padding"
                                                        key={index}>
                                                        <Box className="divider" />
                                                    </Box>
                                                )}

                                                <Items
                                                    list={[...value.children]}
                                                    setSubListState={setSubListState}
                                                    menu="wellness"
                                                />
                                            </div>
                                            <div className="float-div-arrow" />
                                        </>
                                    )}
                                </React.Fragment>
                            ) : (
                                <div
                                    key={index}
                                    onClick={() => {
                                        if (!wellNessPermissions?.permissionLoaded) return;
                                        if (!value?.canAccess)
                                            return dispatch(showSubscriptionModal());
                                        if (value.urlToNavigate && value.urlToNavigate !== null) {
                                            navigate(`${value.urlToNavigate}`);
                                        }
                                        setSubListState(false);
                                    }}
                                    className="menu" id={value?.id}>
                                    {!value?.canAccess && wellNessPermissions?.permissionLoaded && (
                                        <SubscriptionDarkLock
                                            height={17}
                                            customStyle={{
                                                top: '-10%',
                                                right: 0,
                                                position: 'absolute'
                                            }}
                                        />
                                    )}
                                    {value.menuName === 'Notifications' &&
                                    value?.canAccess &&
                                    wellNessPermissions?.permissionLoaded ? (
                                        <Badge
                                            className="menu-icon notifications-badge"
                                            color="badge"
                                            overlap="circular"
                                            badgeContent={
                                                userInfo?.totalUnReadNotificationCount || 0
                                            }
                                            max={9}>
                                            {value.menuIcon}
                                        </Badge>
                                    ) : (
                                        <Box className="menu-icon flex f-v-center f-h-center">
                                            {value.menuIcon}
                                        </Box>
                                    )}

                                    <Typography
                                        className={
                                            'menu-text' +
                                            (window.location.pathname === value.urlToNavigate
                                                ? ' selected-menu-text'
                                                : '')
                                        }>
                                        {value.menuName}
                                    </Typography>
                                </div>
                            )
                        )}
                    </SidebarWrapper>
                )}
            </ClickAwayListener>
        </>
    );
};

export default Sidebar;
