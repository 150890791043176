import React from 'react';
import OrderDetails from '../OrderDetails/OrderDetails';
import { useLocation } from 'react-router-dom';

const OrderSuccess = () => {
    const location = useLocation();

    return (
        <React.Fragment>
            <OrderDetails title="Success!" isOrderSuccess orderedData={location?.state?.data} />
        </React.Fragment>
    );
};

export default OrderSuccess;
