import React, { useState, useImperativeHandle, useRef } from 'react';
import { UserChoicesWrapper } from './UserChoices.style';
import Lottie from 'lottie-react';
import { Box, Typography, Chip } from '@mui/material';
import { cloneDeep } from 'lodash';

const UserChoices = React.forwardRef(({ userDetail, data, ...props }, ref) => {
    const rootRef = useRef();
    const [userSelection, setUserSelection] = useState(cloneDeep(data?.keywords));
    const [isCompletedAnimation, setIsCompletedAnimation] = useState(false);
    const [loop, setLoop] = useState(false);

    useImperativeHandle(ref, () => ({
        btnWidth: rootRef.current.clientWidth,
        list: userSelection,
        scrollHeight: rootRef.current.clientHeight,
        ref: rootRef.current
    }));

    const onUserSelectionClick = (goalData) => {
        let userSelectionCopy = [...userSelection];
        let userSelect = userSelectionCopy.findIndex((gl) => gl.keywordId === goalData.keywordId);
        if (!data?.canSelectMultipleOption) {
            userSelectionCopy.forEach((item) => {
                item.isSelected = false;
            });
        }
        userSelectionCopy[userSelect].isSelected = !userSelectionCopy[userSelect].isSelected;
        setUserSelection(userSelectionCopy);
    };

    return (
        <>
            <UserChoicesWrapper ref={rootRef}>
                <Box
                    className="message-container"
                    display="flex"
                    justifyContent="space-between"
                    mb={1}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap={1}
                        className="box-root">
                        <Typography classes={{ root: 'input-root' }}>{data?.question}</Typography>
                    </Box>

                    <Lottie
                        loop={props?.repeatAnimation ? loop : true}
                        autoplay
                        onComplete={() => {
                            if (!props?.repeatAnimation) return;
                            setIsCompletedAnimation(true);
                            setLoop(true);
                        }}
                        animationData={
                            isCompletedAnimation ? props?.repeatAnimation : props?.initAnimation
                        }
                        className="lottie-animation"
                    />
                </Box>

                <Box className={`goals ${props?.className}`}>
                    {userSelection?.map(
                        (gl) =>
                            gl && (
                                <Chip
                                    key={gl.keywordId}
                                    sx={{ color: gl.isSelected ? 'common.white' : 'text.dark' }}
                                    onClick={() => onUserSelectionClick(gl)}
                                    className={`goal-chip ${props?.largeChip && `large`} ${
                                        gl?.isSelected ? `selected-goal` : `non-selected-goal`
                                    }`}
                                    size="medium"
                                    color="primary"
                                    variant={gl.isSelected ? 'filled' : 'outlined'}
                                    label={gl.name}
                                />
                            )
                    )}
                </Box>
            </UserChoicesWrapper>
        </>
    );
});

export default UserChoices;
