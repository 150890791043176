import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { PageDialogWrapper } from './FooterDialog.style';
import { ReactComponent as CloseIcon } from 'Assets/images/Common/close-icon.svg';
import { PAGE } from 'Helpers/Constants';
import { PrivacyPolicy, TermsCondition } from 'Components/Pages/Policies';

const Page = ({ openPageDialog, setOpenPageDialog, selectedPageTitle, selectedPageType }) => {
    return (
        <>
            <Dialog
                open={openPageDialog}
                onClose={() => setOpenPageDialog(false)}
                sx={{
                    '& .MuiDialog-container': {
                        '& .MuiPaper-root': {
                            width: '100%',
                            borderRadius: '15px',
                            height: '90%'
                        }
                    }
                }}>
                <PageDialogWrapper>
                    <DialogTitle className="flex f-v-center">
                        <Box width={'90%'}>
                            <Typography className="title">{selectedPageTitle}</Typography>
                        </Box>
                        <Box width={'10%'}>
                            <CloseIcon
                                className="hover close-icon"
                                onClick={() => setOpenPageDialog(false)}
                            />
                        </Box>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        {PAGE.PRIVACY_POLICY === selectedPageType && (
                            <PrivacyPolicy customHeight={'100%'} />
                        )}
                        {PAGE.TERMS_AND_CONDITIONS === selectedPageType && (
                            <TermsCondition customHeight={'100%'} />
                        )}
                    </DialogContent>
                </PageDialogWrapper>
            </Dialog>
        </>
    );
};

export default Page;
