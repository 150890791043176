import { Typography, TextField, FormHelperText } from '@mui/material';
import { ReactComponent as SelectDropDownIcon } from 'Assets/images/Common/down-arrow.svg';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { CustomSwitch } from 'Components/common/Switch.style';
import { ProfileAccountInfoCustomSwitchWrapper } from './AccountInfo.style';

export const ProfileAccountInfoTextField = ({
    label = '',
    variant = 'filled',
    value = '',
    handleChange = () => {},
    touched = '',
    name = '',
    className = 'text-field',
    InputProps = {
        disableUnderline: true,
        className: 'filled-input'
    },
    error = '',
    disabled = false,
    multiline = false,
    rows = 1
}) => {
    return (
        <>
            <TextField
                variant={variant}
                label={label}
                name={name}
                className={className}
                autoComplete="off"
                InputProps={InputProps}
                value={value}
                onChange={handleChange}
                disabled={disabled}
                multiline={multiline}
                rows={rows}
            />
            <FormHelperText error>{touched && error}</FormHelperText>
        </>
    );
};

export const ProfileAccountInfoDatePicker = ({
    components = {
        OpenPickerIcon: SelectDropDownIcon
    },
    InputProps = {
        disableUnderline: true,
        className: 'filled-input'
    },
    className = 'text-field',
    datePickerIconStatus = {},
    inputFormat = 'DD',
    label = 'Day',
    views = ['day'],
    value = null,
    onChange = () => {},
    onOpen = () => {},
    onClose = () => {}
}) => {
    return (
        <>
            <MuiDatePicker
                components={components}
                InputProps={InputProps}
                InputAdornmentProps={{
                    classes: {
                        positionEnd: `${datePickerIconStatus?.day && 'open-picker-icon'}`
                    }
                }}
                className={className}
                inputFormat={inputFormat}
                label={label}
                views={views}
                value={value}
                maxDate={new Date()}
                onChange={onChange}
                onOpen={onOpen}
                onClose={onClose}
                renderInput={(params) => {
                    return <TextField {...params} variant="filled" />;
                }}
            />
        </>
    );
};

export const ProfileAccountInfoCustomSwitch = ({
    label = ' ',
    checked = '',
    onChange = () => {}
}) => {
    return (
        <ProfileAccountInfoCustomSwitchWrapper>
            {label && <Typography className="switch-label">{label}</Typography>}
            <CustomSwitch
                checked={checked}
                classes={{
                    root: 'switch-root',
                    track: `switch-track ${checked && 'active'}`,
                    thumb: `switch-thumb ${checked && 'active'}`
                }}
                onChange={onChange}
            />
        </ProfileAccountInfoCustomSwitchWrapper>
    );
};
