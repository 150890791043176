import styled from 'styled-components';

import { Modal } from '@mui/material';

import { FONTS, COLORS } from 'Styles/Constants'

export const SessionSignupModalWrapper = styled(Modal)`
    /* background-color: rgba(255, 255, 255, 0.6); */
    &.MuiModal-root {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .MuiBackdrop-root {
        background-color: rgba(255, 255, 255, 0.4);
    }
    .dialog-container {
        width: 386px;
        background-color: #FFFFFF;
        border: 1px solid #E8E8E8;
        /* box-shadow: 0px 12px 20px 8px rgb(0 0 0 / 55%); */
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
        border-radius: 24px;
        padding: 20px 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .top-bar-container {
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            padding-bottom: 25px;
            border-bottom: 1px solid #E8E8E8;
            .title-container {
                flex-grow: 1;
                .name {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 18px;
                    font-family: ${FONTS.PRIMARY_MEDIUM};
                    margin-bottom: 5px;
                    text-align: center;
                }
                .time {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;
                }
            }
            .close-btn {
                position: absolute;
                right: -7px;
                top: -10px;
            }
        }
        .option-container {
            padding: 25px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-bottom: 1px solid #E8E8E8;
            width: 100%;
            cursor: pointer;
            .text {
                color: #848484;
                text-align: center;
                text-transform: initial;
                font-family: ${FONTS.PRIMARY_MEDIUM};
                font-weight: 500;
                font-size: 20px;
                line-height: 18px;
            }
            .free-btn {
                color: white;
                border-radius: 29px;
                width: 100%;
                background-color: ${COLORS.PRIMARY_DARK};
                text-transform: initial;
                margin-top: auto;
                padding: 20px 24px;
                line-height: 1;
                font-family: ${FONTS.PRIMARY_MEDIUM};
                font-size: 20px;
            }
        }
    }
`;