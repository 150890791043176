import styled from "styled-components";
import { COLORS, FONTS, responsive, BREAKPOINTS_VALUE } from "Styles/Constants";
import HeartSVG from "Assets/images/FieldHealing/heart.svg";
// import PlayerImage from "Assets/images/FieldHealing/player_image.svg";
import HeartSVGMobile from "Assets/images/FieldHealing/heart-mobile.svg"

export const FieldHealingWrapper = styled.div`
    width: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: center;

    /* ${responsive.MOBILE`
        height: 100vh;
        flex-direction: row;
        align-items: center;
    `} */

    .container{
        height: calc(100vh - 107px);
        overflow-y: auto;
        display: flex;
        flex-direction: row;
        /* justify-content: center; */
        position: absolute;
        top: 10%;
        /* overflow: hidden; */
        width: 45%;
       
        ${responsive.DESKTOP`
            width: 60%;
        `}

        ${responsive.LAPTOP`
            width: 78%;
        `}

        ${responsive.TABLET`
            width: 90%;
        `}

        @media only screen and (max-width: 768px) {
            width: 100%;
        }

        ${responsive.PHABLET`
            flex-direction: column;
            top: 3%;
        `}
    }

    .chips-div{
        width: 63%;
        
        @media only screen and (max-width: 2000px) {
            width: 90%;
        }

        /* ${responsive.DISPLAY`
            width: 90%;
        `} */
    }

    .left-side{
        width: 100%;
        padding: 24px 12px;
        display: flex;
        flex-direction: column;

        ${responsive.PHABLET`
            padding: 0;
        `}

        .leftArrow{
            padding: 0 20px;
            display: none;

            ${responsive.PHABLET`
                display: flex;
            `}
        }

        .title{
            font-family: ${FONTS.SECONDARY};
            text-transform: capitalize;
            color: ${COLORS.PRIMARY};
            font-size: 40px;
            font-weight: 700;
        }

        .description{
            font-family: ${FONTS.PRIMARY};
            font-size: 14px;
            color: #31290E;
            width: 70%;
            margin: 22px 0;

            ${responsive.PHABLET`
                display: none;
            `}
        }

        .goal-chip {
            padding: 10px 20px;
            /* margin: 3px 5px; */
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            height: 46px;
            border-radius: 26px;
            text-transform: uppercase;
            border: 2px solid transparent;
            margin: 3px 0;

            ${responsive.PHABLET`
                width: 80% !important;
            `}
            

            @media only screen and (max-width: ${BREAKPOINTS_VALUE.DISPLAY}px) {
                margin: 10px;
            }

            &.non-selected-goal {
                border: 2px solid ${COLORS.PRIMARY};
                background-color: rgba(255, 255, 255, 0.6);
                &:hover {
                    background-color: transparent;
                }
            }

            &.selected-goal {
                color: white;
            }

            ${responsive.DISPLAY`
                font-size: 16px;
            `}

            ${responsive.PHABLET`
                display: none;
            `}
        }

        .slider-content {
            position: relative;
            margin-bottom: 50px;

            ${responsive.PHABLET`
                margin-bottom: 30px;
            `}
        }

        .slick-slider {
            overflow: hidden;

            ${responsive.PHABLET`
                margin-left: 15px;
            `}
        }

        .slick-center {
            transform: scale(1.2);
        }

        .slick-slide {
            display: none;
            float: left;
            height: 100%;
            min-height: 1px;
            width: 240px !important;
        }

        .slick-initialized .slick-slide {
            display: block;
        }
    }

    .right-side{
        width: 100%;
        padding: 24px 12px;
        display: flex;
        flex-direction: row;

        ${responsive.PHABLET`
            padding: 0;
            justify-content: center;
        `}

        .selection-count{
            color: ${COLORS.GREY_TEXT_COLOR};
            font-size: 14PX;
            padding-bottom: 20px;
        }

        .svg-div{
            margin-top: -21px;
            /* margin-top: -55px; */
            cursor: pointer;
        }
    }

    .selection-div{
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        overflow: hidden;
    }

    .p-t{
        padding-top: 30px;
    }



    .selection-item{
        font-size: 16px;
        color: ${COLORS.PRIMARY};
        padding: 12px 0;
        cursor: pointer;
    }

    .selection-container{
        display: flex;
        flex-direction: column;
        margin-left: -111px;
    }
`
export const BodyOrgansDetailsWrapperMobile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    .arrow-div{
        padding: 40px 20px;
        cursor: pointer;
    }

    .title{
        font-size: 40px;
        text-transform: capitalize;
        font-family: ${FONTS.SECONDARY};
        font-weight: 700;
        color: ${COLORS.PRIMARY_DARK};
    }

    .image-container{
        width: 100%;

        .image{
            background-image: url(${HeartSVGMobile});    
            background-size: contain;
            background-repeat: no-repeat;
            height: ${props => `calc(${props.screen.height-476}px)`}; //320px;
            width: 90%;
        }
    }

    .sub-title{
        font-size: 18px;
        font-weight: 500;
        color: ${COLORS.PRIMARY_DARK};
        font-family: ${FONTS.PRIMARY};
    }

    .brief-organ{
        height: 200px;
        width: 100%;
        overflow: auto;
        padding: 12px 24px;
        /* border-bottom: 1px solid #848484; */
    }

    .detail-description{
        font-size: 14px;
        font-family: ${FONTS.PRIMARY};
    }

    .seperator{
        width: 93%;
        height: 1px;
        background-color: ${COLORS.GREY_TEXT_COLOR};
        margin: 16px 8px;
    }

    .button{
        width: 90%;
        background: linear-gradient(180deg, #CBAC69 0%, #B6954F 100%);
        border-radius: 12px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        padding: 10px 0;
        color: #fff;
        box-shadow: none;

        &:hover{
            background: linear-gradient(180deg, #CBAC69 0%, #B6954F 100%);
            box-shadow: none;
        }
    }

    .button-wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export const BodyOrgansDetailsWrapper = styled.div`
    
    .wave{
        position: relative; 
        /* bottom: 35px;  */
        width: 560px; 
        /* left: -65%; */

        ${responsive.PHABLET`
            display: none;
        `}
    }

    .heart-container{
        position: absolute;
        right: 5%;
        background-image: url(${HeartSVG});
        background-size: contain;
        background-repeat: no-repeat;
        width: 50%;
        height: 785.94px;
        z-index: 1;
    }

    .container{
        width: 36%; 
        position: absolute;
        top: 15%;
        left: 15%;
        display: flex;
        flex-direction: row;
        height: 520px;
        z-index: 100;

        ${responsive.DESKTOP`
            width: 47%;
        `}

        ${responsive.LAPTOP`
            width: 57%;
        `}

        ${responsive.TABLET`
            width: 77%;
        `}

        ${responsive.MOBILE`
            width: 100%;
        `}
    }

    .arrow-div{
        padding: 40px 20px;
        cursor: pointer;
    }

    .wrapper{
        display: flex; 
        flex-direction: column; 
        width: 100%; 
        height: 100%;
    }

    .details-container{
        width: 90%;
        background-color: rgba(242, 242, 242, 0.7);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 40px;
        gap: 27px;
        border-radius: 50px;
        height: 80%;
        margin-bottom: 10px;
    }

    .button{
        width: 90%;
        background: linear-gradient(180deg, #CBAC69 0%, #B6954F 100%);
        border-radius: 12px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        padding: 10px 0;
        color: #fff;
        box-shadow: none;

        &:hover{
            background: linear-gradient(180deg, #CBAC69 0%, #B6954F 100%);
            box-shadow: none;
        }
    }

    .title{
        font-size: 40px;
        text-transform: capitalize;
        font-family: ${FONTS.SECONDARY};
        font-weight: 700;
        color: ${COLORS.PRIMARY};
    }

    .brief-organ{
        height: 100%;
        width: 100%;
        overflow: auto;
    }

    .brief-organ::-webkit-scrollbar {
        width: 6px;
    }

    .brief-organ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    .brief-organ::-webkit-scrollbar-thumb {
        background-color: rgba(162, 162, 162, 0.5); 
        border-radius   : 4px;
    }

    .sub-title{
        font-size: 18px;
        font-weight: 500;
        color: ${COLORS.PRIMARY_DARK};
        font-family: ${FONTS.PRIMARY};
    }

    .detail-description{
        font-size: 14px;
        font-family: ${FONTS.PRIMARY};
    }
`

export const FieldHealingPlayerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 5px);
    overflow: hidden;

    ${responsive.PHABLET`
        height: unset;
    `}

    .player-custom-controller{
        position: relative;
        top: 38%;
        z-index: 99999;
        /* right: 50%; */
    }

    .left-arrow{
        display: none;
        padding: 24px;
        ${responsive.PHABLET`
            display: flex;
        `}
    }

    .heart-container{
        position: absolute;
        right: 0%;
        background-image: url(${HeartSVG});
        background-size: contain;
        background-repeat: no-repeat;
        width: 50%;
        height: 100%;
        z-index: 1;

        ${responsive.PHABLET`
            width: 100%;
        `}
    }

    .container{
        width: 50%; 
        display: flex;
        flex-direction: column;
        z-index: 100;

        ${responsive.DESKTOP`
            width: 60%;
        `}

        ${responsive.LAPTOP`
            width: 80%;
        `}

        ${responsive.TABLET`
            width: 95%;
        `}

        ${responsive.MOBILE`
            width: 100%;
        `}
    }

    .player{
        border-top-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    .player-width{
        width: 100%;

        video{
            object-fit: fill;
            border-top-left-radius: 25px;
            border-bottom-right-radius: 25px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        ${responsive.PHABLET`
            width: 90%
        `}
    }

    .boostHealingButton{
        display: flex;

        ${responsive.PHABLET`
            display: none;
        `}
    }

    .step{
        font-family: ${FONTS.PRIMARY};
        font-size: 20px;
        font-weight: 400;
        color: ${COLORS.GREY_TEXT_COLOR};
        padding: 0 12px;
        cursor: pointer;
    }

    .selected-step{
        font-weight: 700 !important;
    }

    .playerDetailsWrapper{
        padding: 12px;

        ${responsive.PHABLET`
            overflow: auto;
            height: ${props => `calc(${props.screen.height-476}px)`}
        `}    
    }
    
    .titleWrapper{
        width: 80%;

        ${responsive.PHABLET`
            width: 100%;
            text-align: center;
        `}
    }

    .boostButton{
        width: 50%;
    }

    .title{
        font-family: ${FONTS.SECONDARY};
        font-weight: 700;
        font-size: 45px;
        color: ${COLORS.PRIMARY};
        text-transform: capitalize;

        ${responsive.TABLET`
            font-size: 36px;
        `}
    }

    .button{
        width: 200px;
        background: linear-gradient(180deg, #CBAC69 0%, #B6954F 100%);
        border-radius: 12px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        padding: 10px 0;
        color: #fff;
        box-shadow: none;

        &:hover{
            background: linear-gradient(180deg, #CBAC69 0%, #B6954F 100%);
            box-shadow: none;
        }
    }

    .playerManagement{
        padding-top: 12px;

        ${responsive.PHABLET`
            flex-direction: column;
        `}
    }

    .player-buttons{
        ${responsive.PHABLET`
            height: 110px;
            width: 100%;
        `}
    }

    .pause{
        padding: 0 40px;
        cursor: pointer;
    }

    .time-fullscreen{
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: ${COLORS.PRIMARY};
    }

    .time{
        color: ${COLORS.GREY_TEXT_COLOR};
        font-family: ${FONTS.PRIMARY};
        font-size: 16px;
        padding-left: 20px;
        width: 15%;

        @media only screen and (min-width: 2000px) {
            width: 10%;
        }

        ${responsive.LAPTOP`
            width: 18%;
        `}

        ${responsive.TABLET`
            width: 23%;
        `}

        ${responsive.PHABLET`
            padding-left: 0;
            width: 98%;
            display: flex;
            justify-content: space-between;
        `}
    }

    .previous, .forward{
        cursor: pointer;
    }

    .progressBar-fullscreen{
        width: 100%;
        /* padding-left: 30px; */
    }

    .progressBar{
        width: 60%;
        padding-left: 30px;

        ${responsive.PHABLET`
            width: 95%;
            padding-left: 0;
        `}
    }

    .videoOptionContainer{
        display: flex; 
        flex-direction: column;
        justify-content: space-between; 
        width: 100%; 
        position: relative; 
        top: -10%; 
        z-index: 9999;
        padding: 0 20px;
    }

    .healingPopupContainer{
        height: 182px;
        width: 50%;
        background-color: #fff;
        box-shadow: 0px 5px 25px -5px rgba(0, 0, 0, 0.15);
        border-radius: 24px;
        position: absolute;
        bottom: 20%;
        z-index: 200;
        padding: 24px;

        @media only screen and (min-width: 2000px) {
            bottom: 9%;
        }

        ${responsive.DESKTOP`
            bottom: 9%;
            width: 60%;
        `}

        ${responsive.LAPTOP`
            width: 80%;
        `}

        ${responsive.LAPTOP`
            width: 95%;
        `}

        ${responsive.PHABLET`
            height: 230px;
            padding: 12px;
            width: 100%;
            bottom: 0;
        `}
    }

    .clearNotification{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .boostMessage{
        padding: 10px 24px;

        ${responsive.PHABLET`
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        `}
    }

    .boostPopupContent{
        width: 70%;

        ${responsive.PHABLET`
            width: 100%;
        `}
    }

    .boostPopupButton{
        width: 30%;

        ${responsive.PHABLET`
            width: 100%;
            margin-bottom: 24px;
        `}
    }

    .boostPopupTitle{
        color: ${COLORS.PRIMARY};
        font-size: 20px;
        font-family: ${FONTS.PRIMARY}; 
        font-weight: 700px;

        ${responsive.PHABLET`
            text-align: center;
        `}
    }

    .boostPopupDescription{
        color: #656565;
        font-size: 16px;
        font-family: ${FONTS.PRIMARY};
        padding-top: 5px;
    }

    .collapse-root{
        width: 100%;
    }
`