import styled from 'styled-components';
import { Box, Popover } from '@mui/material';
import { responsive, FONTS, COLORS, BREAKPOINTS_VALUE } from 'Styles/Constants';

export const FavoritesWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;

    .full-width-section {
        width: 100%;
        background-color: white;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        /* min-height: calc(100vh - 50px); */
        display: flex;
        flex-direction: column;
        align-items: center;

        .favorites-content {
            width: 60%;
            margin: 100px 0;
            display: flex;
            flex-direction: column;
            gap: 26px;

            ${responsive.TABLET`
                width: 80%;
            `}
            ${responsive.PHABLET`
                width: 90%;
            `}
            .header {
                .header-text {
                    font-family: ${FONTS.SECONDARY};
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 40px;
                    text-transform: capitalize;
                }
            }
        }

        .meditation-content-slider-container {
            display: flex;
            flex-direction: column;
            gap: 6px;
            .meditation-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: end;
                align-content: end;
                min-height: 46px;

                .meditation-header-text {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 35px;
                    text-transform: capitalize;
                }
            }

            .filter-sort-container {
                display: flex;
                gap: 1rem;
                margin-left: auto;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
                    gap: 0.4rem;
                }
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
                    margin-left: 1rem;
                }
                .filter-container {
                    display: flex;
                    flex-direction: column;
                    gap: 13px;

                    &.language {
                        width: 211px;
                        height: 45px;
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP}px) {
                            width: 175px;
                        }

                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_860}px) {
                            width: 145px;
                        }
                    }

                    &.sort-select {
                        width: 150px;
                        height: 45px;
                        @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP}px) {
                            width: 125px;
                        }
                    }

                    .sorting-btn {
                        height: 46px;
                        width: 120px;
                        border-radius: 24px;
                        background-color: #fff;
                        border: 1px solid #e8e8e8;
                        color: ${COLORS.PRIMARY_WARM};
                        font-family: ${FONTS.PRIMARY_MEDIUM};
                        text-transform: none;
                        font-size: 18px;
                    }

                    .down-arrow-icon {
                        transition: 0.2s;
                        transform: rotate(180deg);
                    }
                }
            }

            .favorite-content-loader-wrapper {
                display: flex;
                flex-direction: column;
                border-radius: 12px;
                margin: 5px;
                position: relative;
                width: 165px;
                height: 240px;
            }
        }

        .program-content-slider-container {
            display: flex;
            flex-direction: column;
            gap: 6px;
            .program-header {
                .program-header-text {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 35px;
                    text-transform: capitalize;
                }
            }
            .program-wrapper {
                .load-more-button-container {
                    height: 120px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    .load-more {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 400;
                        font-size: 16px;
                        margin-left: 20px;
                        color: ${COLORS.PRIMARY};
                        margin-top: 5px;
                        cursor: pointer;
                        text-transform: capitalize;

                        ${responsive.PHABLET`
                        width: 22%;
                    `}
                    }
                }

                .program-card-loader-wrapper {
                    display: flex;
                    flex-direction: column;
                    min-width: 215px;
                    min-height: 170px;
                    align-items: flex-start;
                    justify-content: space-between;

                    .program-card-loader {
                        height: 120px;
                        width: 210px;
                    }

                    .program-card-title-loader-wrapper {
                        height: 40px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: 210px;
                    }
                }
            }
        }

        .live-session-slider-container {
            display: flex;
            flex-direction: column;
            gap: 6px;
            .live-session-header {
                .live-session-header-text {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 35px;
                    text-transform: capitalize;
                }
            }
        }

        .meditation-room-container {
            display: flex;
            flex-direction: column;
            gap: 6px;
            .meditation-room-header {
                p.MuiTypography-root.MuiTypography-body1.meditation-room-header.css-1r9u15t-MuiTypography-root {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 35px;
                    text-transform: capitalize;
                }
            }

            .color-border {
                display: flex;
                flex-direction: column;
                gap: 26px;
                background: radial-gradient(
                            circle at 100% 100%,
                            #ffffff 0,
                            #ffffff 11px,
                            transparent 11px
                        )
                        0% 0%/13px 13px no-repeat,
                    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 11px, transparent 11px)
                        100% 0%/13px 13px no-repeat,
                    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 11px, transparent 11px) 0%
                        100%/13px 13px no-repeat,
                    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 11px, transparent 11px) 100%
                        100%/13px 13px no-repeat,
                    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 4px) calc(100% - 26px)
                        no-repeat,
                    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 26px) calc(100% - 4px)
                        no-repeat,
                    linear-gradient(0deg, transparent 29%, #b08c3d 100%);
                border-radius: 11px;
                padding: 22px;
                box-sizing: border-box;

                .oneness-field-slider-container {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                }

                .my-wellness-slider-container {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                }

                .community-room-slider-container {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                }

                .meditation-content-header {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    align-content: end;

                    ${responsive.MOBILE`
                        align-items: baseline;
                    `}

                    .teacher-indicator {
                        display: flex;
                        flex-direction: row;
                        align-items: end;
                    }

                    .meditation-header-text-container {
                        display: flex;
                        flex-direction: row;
                        align-items: baseline;

                        ${responsive.MOBILE`
                            flex-direction: column;
                        `}
                        /* ${responsive.LAPTOP`
                            flex-direction: column;
                        `}

                        ${responsive.LAPTOP_1050`
                            flex-direction: column;
                        `}

                        ${responsive.TABLET`
                            flex-direction: column;
                        `} */

                        .meditation-header-text {
                            font-family: ${FONTS.PRIMARY};
                            font-style: normal;
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 35px;
                            text-transform: capitalize;
                            color: ${COLORS.PRIMARY_WARM};
                            margin: 0px 6px 0px 6px;
                            max-width: 329px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            ${responsive.LAPTOP`
                                max-width: 200px;
                            `}

                            ${responsive.LAPTOP_1050`
                                max-width: 200px;
                            `}

                            ${responsive.TABLET`
                                max-width: 150px;
                            `}

                            ${responsive.MOBILE`
                                max-width: 200px;  
                            `}

                            &.light {
                                font-family: ${FONTS.PRIMARY};
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 23px;
                                text-transform: capitalize;
                                color: ${COLORS.TEXT_DARK};
                                margin: 0px 6px 0px 6px;
                            }
                        }

                        .matches-text {
                            font-family: ${FONTS.PRIMARY};
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 23px;
                            text-align: right;
                            color: ${COLORS.MEDIUM_GREY};

                            ${responsive.DESKTOP`
                                max-width: 100px;
                            `}

                            ${responsive.LAPTOP`
                                max-width: 100px;
                            `}
                            
                            ${responsive.MOBILE`
                                max-width: 200px;
                                margin: 0px 6px 0px 6px;
                            `}
                        }
                    }
                }
            }
        }
    }
`;

export const ContentCountDetailsWrapper = styled(Box)`
    .slider-record {
        max-width: 200px;
        margin-left: 15px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .slide-title {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: ${COLORS.PRIMARY_DARK};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .slide-description {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: ${COLORS.PRIMARY_DARK};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .slider-record-browse {
        margin-left: 15px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 90%;

        @media only screen and (max-width: 650px) {
            width: 40%;
            margin-left: 30px;
        }

        @media only screen and (max-width: 420px) {
            margin-left: 15px;
        }

        @media only screen and (max-width: 370px) {
            width: 60%;
        }
    }

    .rating-count {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        color: ${COLORS.PRIMARY_DARK};
        padding-top: 2px;
        padding-left: 5px;
    }

    .user-count {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: ${COLORS.PRIMARY_DARK};
        padding-left: 6px;
    }
`;

export const PopOverWrapper = styled(Popover)`
    .sorting-box {
        width: 120px;
        border: 1px solid #e8e8e8;
        border-radius: 24px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .sorting-text {
            padding: 10px 5px;
            font-size: 18px;
            color: ${COLORS.PRIMARY_WARM};
            font-family: ${FONTS.PRIMARY_MEDIUM};
            width: 80%;
            border-bottom: 0.5px solid rgb(132 132 132 / 0.5);
            justify-content: center;

            &:hover {
                cursor: pointer;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
`;

export const NoContentWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    gap: 10px;

    .no-content-text {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 35px;
        text-align: center;
        color: ${COLORS.TEXT_DARK};

        ${responsive.MOBILE`          
            width: 100%;
        `}
    }

    .recommendations-btn {
        width: 370px;
        height: 60px;
        background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
        border-radius: 12px;
        color: ${COLORS.WHITE};
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        font-size: 16px;

        ${responsive.MOBILE`          
            width: 100%;
        `}
    }
`;
