import { Box } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const WellnessLibraryWrapper = styled(Box)`
    max-width: 80%;
    margin: 30px auto 20px;

    ${responsive.LAPTOP`
        max-width: 100%;
    `}

    .wellness-title {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        width: 100%;

        ${responsive.PHABLET`
            justify-content: space-between;
        `}

        .subscription-badge {
            position: relative;
            width: 100px;
        }

        .wellness-title-text {
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            font-weight: 600;
            font-size: 28px;

            ${responsive.TABLET`
                font-size: 24px;
            `}
        }
    }

    .wellness-list {
        height: 140px;

        ${responsive.TABLET`
            flex-wrap: wrap;
        `}

        ${responsive.PHABLET`
            gap: 5px;
        `}
        
        &.not-accessible {
            opacity: 0.5;
            pointer-events: none;
        }

        .wellness-list-loader-wrapper {
            height: 150px;
            width: 100px;
            min-width: 100px;
            display: flex !important;
            flex-direction: column;
            align-items: center;

            .loader-circle {
                padding: 8px;
                height: 90px;
            }
        }

        .wellness-content {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            height: 150px;
            width: 100px;
            text-align: center;

            .wellness-icon-button {
                height: 90px;
                width: 90px;

                &:hover {
                    .wellness-icon,
                    .wellness-ribbon-icon {
                        transition: all 0.7s ease-in-out;
                        transform: scale(1.1);
                    }
                }
                .wellness-icon {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    height: 70px;
                    width: 70px;

                    ${responsive.DISPLAY`
                        height: 60px;
                        width: 60px;
                    `}
                }

                .wellness-ribbon-icon {
                    position: absolute;
                    top: 14%;
                    width: 40%;
                    height: 40%;
                    left: 5%;
                    z-index: 10;
                    background-position: center;
                    background-repeat: no-repeat;

                    ${responsive.DISPLAY`
                        left: 10%;
                    `}
                }
            }

            .wellness-category-name {
                color: ${COLORS.MEDIUM_GREY};
                width: 100px;
                font-size: 16px;
                line-height: 18px;
                overflow: hidden;
                word-wrap: break-word;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                ${responsive.PHABLET`
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18px;
                `}
            }
        }
    }
`;
