import { Box, Dialog } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import { styled as materialStyled } from '@mui/material/styles';
import ProfileGuideline from 'Assets/images/Profile/profile-guideline.svg';
import User_Img from 'Assets/images/Profile/user-without-bg.svg';

export const ProfileAccountInfoSectionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #fff;
    border-radius: 30px 30px 0px 0px;
    position: relative;
    height: 100%;

    ${responsive.TABLET`
        height: calc(100% - 80px);
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        border-radius: 30px 30px 0px 0px;
        background: #FFFFFF;
    `}

    ${responsive.PHABLET`
        background: none;
        box-shadow: none;
        border-radius: none;
    `}
    
    .fields-container {
        padding: 100px 20px 0;

        ${responsive.TABLET`
            padding: 20px 20px 0;
        `}
        .grid-container {
            width: 100%;
            display: grid;
            grid-template-columns: 0.8fr 1fr 1fr;
            grid-template-rows: repeat(2, 1fr);
            grid-gap: 20px;

            ${responsive.PHABLET`
                grid-template-columns: 0.5fr 1.5fr;
                grid-template-rows: repeat(3, 1fr);
            `}

            /* ${responsive.PHABLET`
                grid-template-columns: 0.6fr 1.4fr;
            `} */

            .profile-img-bg {
                display: flex;
                position: relative;
                height: inherit;
                width: inherit;
                border-radius: 50%;
                padding: 0;

                .user-profile-img {
                    border: 4px solid ${COLORS.PRIMARY_DARK};
                    border-radius: 50%;
                    height: 120px;
                    width: 120px;
                    position: relative;
                }

                .new-pic-bg {
                    height: 116px;
                    width: 116px;
                    padding: 10px;
                    position: absolute;
                    background: #00000080;
                    border-radius: 50%;
                    color: ${COLORS.WHITE};

                    .click-area {
                        position: absolute;
                        top: 0;
                        height: 116px;
                        width: 116px;
                        border-radius: 50%;
                    }

                    .change-pic-para {
                        padding: 5px 0 0 0;
                        font-weight: 700;
                        font-size: 19px;
                        line-height: 25px;
                        text-align: center;
                    }

                    .camera-icon {
                        margin: -15px;
                    }

                    .cross-icon {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }

        .user-address {
            justify-content: space-between;

            ${responsive.PHABLET`
                flex-direction: column;
                justify-content: center;
            `}
        }
        .default-margin {
            margin: 0 0 20px 0;
        }
    }

    .item-1 {
        grid-column: 1/2;
        grid-row: 1/3;
    }

    .item-2 {
        grid-column: 2/3;
        grid-row: 1/2;
    }

    .item-3 {
        grid-column: 3/4;
        grid-row: 1/2;

        ${responsive.PHABLET`
            grid-column: 2/3;
            grid-row: 2/3;
        `}
    }

    .item-4 {
        grid-column: 2/4;
        grid-row: 2/3;

        ${responsive.PHABLET`
            grid-column: 1/3;
            grid-row: 3/4;
        `}
    }

    .text-field,
    .form-error {
        width: 100%;
        background: ${COLORS.WHITE};
        border: 1px solid rgba(132, 132, 132, 0.4);
        border-radius: 8px;
        .filled-input {
            background-color: transparent;
        }
    }

    .auto-complete-root-bg {
        width: 100%;
        background: ${COLORS.WHITE};
        border: 1px solid rgba(132, 132, 132, 0.4);
        border-radius: 8px;

        &.filled-input-bg {
            background: ${COLORS.WHITE};
        }
    }

    .form-error {
        border: 3px solid #f4941b;
        border-radius: 8px;
    }

    .selector {
        background-color: white;
        border: 1px solid rgba(132, 132, 132, 0.4);
        border-radius: 8px;
        height: 60px;
        width: 100%;
        .filled-select {
            background-color: #fff;
            border-radius: 8px;
        }
        .down-arrow-icon {
            position: absolute;
            cursor: pointer;
            right: 20px;
        }
    }

    .mobile-box {
        width: 33%;

        ${responsive.PHABLET`
            width: 100%;
        `}
    }

    .location-box {
        width: 25%;

        ${responsive.PHABLET`
            width: 100%;
        `}
    }

    .region-box {
        width: 33%;

        ${responsive.PHABLET`
            width: 100%;
        `}
    }

    .disable-field-color {
        background: ${COLORS.LIGHT_GREY};
        .MuiSelect-select {
            background: ${COLORS.LIGHT_GREY};
        }

        &.share-profile {
            border: 1px solid ${COLORS.PRIMARY};
            background: #fff;
            pointer-events: none;
        }
    }

    .form-control {
        width: 100%;
    }

    .select-box {
        width: 100%;
    }

    .select-filed {
        width: 100%;
    }

    .switch-label {
        width: 100%;
        margin: 0;
        padding: 0;
        flex-direction: row-reverse;

        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 30px;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_WARM};
    }

    .radio-button-group {
        ${responsive.TABLET`
            display: flex;
            flex-direction: column;
            gap: 10px;
        `}

        .radio-button.Mui-checked span {
            border: 2px solid ${COLORS.PRIMARY_DARK};
        }
    }

    .birthday-title,
    .public-section-title,
    .body-text,
    .body-para,
    .change-pass,
    .session-lang {
        font-family: ${FONTS.PRIMARY};
        text-transform: capitalize;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: ${COLORS.MEDIUM_GREY};
    }

    .lang-show-session {
        display: flex;
        flex-direction: row;
        width: 80%;
        margin: 0;
        padding: 0;
    }

    .lang-show-session-radio {
        margin: 0;
        padding: 0;
        justify-content: left;
        flex-direction: row-reverse;
        width: 45%;
        font-family: ${FONTS.PRIMARY};
        text-transform: capitalize;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 18px;
        line-height: 22px;
        color: ${COLORS.PRIMARY_WARM};
    }

    .session-lang {
        line-height: 24px;
    }

    .birthday-para {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: ${COLORS.PRIMARY_WARM};
    }

    .submit-btn,
    .share-btn,
    .save-btn,
    .preview-btn,
    .change-btn {
        background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
        border-radius: 12px;
        height: 60px;
        width: 100%;
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        font-size: 16px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: ${COLORS.WHITE};
        line-height: initial;

        ${responsive.TABLET`
            height: 55px;
        `}

        ${responsive.PHABLET`
            height: 50px;
        `}
    }

    .public-profile-section {
        padding: 30px;
        margin: 40px 0 -30px 0px;
        background: #f8f8f8;
        border: 0.5px solid ${COLORS.LIGHT_GREY};
        border-radius: 35px 35px 0px 0px;
        flex-direction: column;

        ${responsive.PHABLET`
            margin: 20px 0 -30px 0px;
        `}

        form {
            margin: 0;
        }

        .extra-feature-icon {
            width: 100px;
            height: 60px;

            .share-btn-container {
                height: 60px;
                background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                border-radius: 12px;
                padding: 10px;
                box-shadow: none;
                width: 100px;
                display: flex;
                justify-content: center;
                align-items: center;

                .share-btn-text {
                    font-size: 16px;
                    font-family: ${FONTS.PRIMARY};
                    color: ${COLORS.WHITE};
                }
            }
        }

        .switch-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 20px 0 0 0;
        }
    }

    .public-section-title {
        margin: 0 0 33px 0;
    }

    .body-text {
        font-size: 21px;
        font-weight: 500;
        line-height: 30px;
        color: ${COLORS.PRIMARY_WARM};
    }

    .body-para {
        text-transform: none;
        font-size: 14px;
        color: #31290e;
        color: ${COLORS.PRIMARY_WARM};
    }

    #not-searchable,
    #searchable {
        transition: visibility 0.5s;
    }

    .private-link-box {
        margin: 17px 0 0 0;
    }

    .email-notification-box {
        margin: 0 0 12px 0;
    }

    .share-btn {
        width: 30%;
        margin: 0 0 0 15px;

        ${responsive.TABLET`
            height: 60px;
        `}
    }

    .divider {
        margin: 28px 0 33px 0;
    }

    .profile-options {
        width: 40%;

        ${responsive.TABLET`
            width: 100%;
        `}
    }

    .save-btn {
        margin: 40px 0 20px 0;
    }

    .preview-btn {
        background: ${COLORS.WHITE};
        border: 1px solid ${COLORS.PRIMARY_DARK};
        color: ${COLORS.PRIMARY_WARM};
        margin: 0 0 30px 0;
    }

    .password-section {
        padding: 38px 20px 61px 20px;
        background: #f2e9d8;
        border: 0.5px solid ${COLORS.PRIMARY};
        border-radius: 35px 35px 0px 0px;
    }

    .change-pass {
        margin: 0 0 30px 0;
    }

    .password-input {
        margin: 0 0 15px 0;
    }

    .date-picker-box {
        border: 1px solid rgba(132, 132, 132, 0.5);
        border-radius: 8px;
        height: 100px;
        position: relative;
        overflow: hidden;
        margin: 19px 0 0 0;

        .date-picker {
            height: inherit;
            width: 100%;
            position: absolute;
            top: 57%;
        }
    }

    .date-wrapper {
        margin: 10px 0 0 0;
        gap: 10px;
        height: 60px;
        transition: all 0.5s;
        overflow: hidden;

        &.hide-date-picker {
            height: 0;
        }
        .day-picker {
            width: 20%;
        }

        .month-picker {
            width: 45%;
        }

        .year-picker {
            width: 35%;
        }

        .open-picker-icon {
            transform: rotate(180deg) scaleX(-1);
        }
    }

    .scroll-input {
        height: inherit;
        width: 33%;
        appearance: none;
        text-align: center;
        border: none;
        outline: none;
        font-size: 21px;
    }

    .confirm-password-container {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        align-content: center;
        justify-content: end;
        margin-top: 10px;

        .text {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: ${COLORS.MEDIUM_GREY};

            &.success {
                color: #83d841;
            }

            &.danger {
                color: #d32f2f;
            }
        }
    }
`;

export const CustomRadioIcon = materialStyled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 25,
    height: 25,
    border: '2px solid #989898',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2
    }
}));

export const CustomRadioCheckedIcon = materialStyled(CustomRadioIcon)({
    '&:before': {
        display: 'block',
        width: 25,
        height: 25,
        backgroundImage: 'radial-gradient(#9C7F3C 45%, #CAA44D 48%, transparent 52%)',
        content: '""',
        position: 'absolute',
        left: '9px',
        top: '9px'
    }
});

export const ResizeWrapper = styled(Dialog)`
    .title {
        font-size: 40px;
        color: ${COLORS.PRIMARY_DARK};
        font-family: ${FONTS.SECONDARY_SEMI_BOLD};
    }

    .paper {
        padding: 40px;
        border-radius: 16px;
    }
    .crop-container {
        position: relative;
        height: 300px;
        width: 300px;
        border-radius: 10px;
    }
    .crop-area {
        position: absolute;
        background-image: url(${ProfileGuideline});
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .zoom-container {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        .user-image {
            background-image: url(${User_Img});
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            &.small {
                height: 24px;
                width: 20px;
            }
            &.large {
                height: 48px;
                width: 40px;
            }
        }

        .slider-track {
            background-color: ${COLORS.LIGHT_GREY};
            border: none;
        }
        .slider-rail {
            background-color: ${COLORS.LIGHT_GREY};
        }
        .slider-thumb {
            background-color: ${COLORS.PRIMARY_DARK};
        }
        .slider-root {
            margin: 0 20px;
        }
    }
`;
export const ProfileAccountInfoCustomSwitchWrapper = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;

    .switch-label {
        width: 100%;
        margin: 0;
        padding: 0;
        flex-direction: row-reverse;

        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 30px;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_WARM};
    }
`;
