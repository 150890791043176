import { Box } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

const CategorySeeAllWrapper = styled(Box)`
    padding: 60px 0 10px;
    height: 100vh;
    display: flex;
    justify-content: center;

    ${responsive.TABLET`
        padding: 60px 0 0;
    `}

    .container {
        width: 45%;

        ${responsive.LAPTOP`
            width: 60%;
        `}

        ${responsive.TABLET`
            width: 80%;
        `}

        .title {
            font-size: 40px;
            color: ${COLORS.PRIMARY_DARK};
            text-transform: capitalize;
            font-family: ${FONTS.SECONDARY_BOLD};
        }

        .chips {
            margin: 30px 0 0;
            display: flex;
            align-items: center;
            gap: 8px;

            .chip-root {
                height: 45px;
                border: 1.5px solid transparent;
                color: ${COLORS.MEDIUM_GREY};
                border-radius: 22px;
                font-size: 16px;
                text-transform: uppercase;
                background-color: ${COLORS.LIGHT_GREY_SECONDARY};
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};

                &.active {
                    border-color: ${COLORS.PRIMARY_DARK};
                    color: ${COLORS.PRIMARY_DARK};
                    background-color: ${COLORS.WHITE};
                }
            }
        }

        .category-list {
            height: 110px;
            width: 100%;
            display: flex;
            gap: 13px;
            margin-top: 30px;

            ${responsive.MOBILE`
                overflow: auto;
            `}

            .icon-container {
                max-width: 75px;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: hidden;
                cursor: pointer;
                min-width: 75px;
                margin: 0 5px 0 0;

                .category-icon {
                    width: 70px;
                    height: 70px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                }
                .text-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 30px;
                    overflow: hidden;
                    margin: 5px 0 0;

                    .category-name {
                        font-size: 13px;
                        line-height: 15px;
                        color: ${COLORS?.TEXT_DARK};
                        text-align: center;
                        width: 100%;
                        max-width: 80px;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }

            .wellness-list-loader-wrapper {
                height: 133px;
                width: 71px;
                min-width: 71px;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 0px 10px 0px 0px;
                gap: 6px;

                .loader-circle {
                    padding: 8px;
                    height: 70px;
                    width: 70px;
                }
            }
        }
    }
`;

export default CategorySeeAllWrapper;
