import React, { useState, useEffect } from 'react';
import { Typography, IconButton, Box, MenuItem, InputAdornment, Button, Divider } from '@mui/material';

import { CustomChatContextMenu, CustomParticipantsViewDrawer, CustomParticipantsViewSearchInput, ExpandParticipantViewButtonWrapper } from './AudioSessionParticipantListVew.style';

import ParticipantProfileImage1 from 'Assets/images/Profile/default-user-icon.jpg';
import ParticipantProfileImage2 from 'Assets/images/Profile/default-user-icon.jpg';
import ParticipantProfileImage3 from 'Assets/images/Profile/default-user-icon.jpg';
// import ExpandParticipantViewIcon from 'Assets/images/MeditationRoomSession/AudioSession/expand-participant-view-icon.png';

import { BREAKPOINTS_VALUE } from 'Styles/Constants';

import {
    CloseChatIconButton,
    PeopleGroupIcon,
    AddFriendIcon,
    SearchIcon,
    UserSpeakingLeftArrows,
    UserSpeakingRightArrows,
    MuteAllIcon,
    UnMuteAllIcon,
    ParticipantListHostIcon,
    SendMessageIcon,
    ExitIcon,
    ExpandParticipantViewIcon,
} from '../AudioSessionSVG';
import { ClearButtonIcon } from '../../AddEditSession/AddEditSessionSVG';
import { getWindowDimensions } from 'Helpers';

const CustomParticipantsView = (props) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [participantsContextMenu, setParticipantsContextMenu] = useState(null);
    const [isParticipantModalExpanded, setIsParticipantModalExpanded] = useState(false);
    const handleContextMenu = (event) => {
        event.preventDefault();
        setParticipantsContextMenu(
            participantsContextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6,
                  }
                : null
        );
    };

    const handleParticipantsContextMenuClose = () => {
        setParticipantsContextMenu(null);
    };

    const toggleParticipantViewModalExpand = (value) => {
        setIsParticipantModalExpanded(value);
    };

    const participantsList = [
        { profileImage: ParticipantProfileImage1, firstname: 'Firstname', lastname: 'Lastname', isHost: false, isSpeaking: false },
        { profileImage: ParticipantProfileImage2, firstname: 'Firstname', lastname: 'Lastname', isHost: true, isSpeaking: true },
        { profileImage: ParticipantProfileImage3, firstname: 'Firstname', lastname: 'Lastname', isHost: false, isSpeaking: false },
        { profileImage: ParticipantProfileImage3, firstname: 'Firstname', lastname: 'Lastname', isHost: false, isSpeaking: false },
        { profileImage: ParticipantProfileImage1, firstname: 'Firstname', lastname: 'Lastname', isHost: false, isSpeaking: false },
        { profileImage: ParticipantProfileImage2, firstname: 'Firstname', lastname: 'Lastname', isHost: false, isSpeaking: false },
        { profileImage: ParticipantProfileImage1, firstname: 'Firstname', lastname: 'Lastname', isHost: false, isSpeaking: false },
        { profileImage: ParticipantProfileImage3, firstname: 'Firstname', lastname: 'Lastname', isHost: false, isSpeaking: false },
        { profileImage: ParticipantProfileImage2, firstname: 'Firstname', lastname: 'Lastname', isHost: false, isSpeaking: false },
        { profileImage: ParticipantProfileImage3, firstname: 'Firstname', lastname: 'Lastname', isHost: false, isSpeaking: false },
        { profileImage: ParticipantProfileImage3, firstname: 'Firstname', lastname: 'Lastname', isHost: false, isSpeaking: false },
        { profileImage: ParticipantProfileImage1, firstname: 'Firstname', lastname: 'Lastname', isHost: false, isSpeaking: false },
        { profileImage: ParticipantProfileImage2, firstname: 'Firstname', lastname: 'Lastname', isHost: false, isSpeaking: false },
        { profileImage: ParticipantProfileImage1, firstname: 'Firstname', lastname: 'Lastname', isHost: false, isSpeaking: false },
    ];

    return (
        <>
            {props.openParticipantListModal && props.hostView && windowDimensions.width > BREAKPOINTS_VALUE.TABLET && (
                <ExpandParticipantViewButtonWrapper onClick={() => toggleParticipantViewModalExpand(!isParticipantModalExpanded)} isParticipantModalExpanded={isParticipantModalExpanded}>
                    <ExpandParticipantViewIcon height="14" width="14" />
                </ExpandParticipantViewButtonWrapper>
            )}

            <CustomParticipantsViewDrawer
                anchor={windowDimensions.width <= BREAKPOINTS_VALUE.TABLET ? 'bottom' : 'right'}
                open={props.openParticipantListModal}
                classes={{ root: 'view-participant-section' }}
                variant={'temporary'}
                hideBackdrop={true}
                hostView={props.hostView}
                isParticipantModalExpanded={isParticipantModalExpanded}
            >
                <Box className="participants-list">
                    <Box className="view-participants-title">
                        <PeopleGroupIcon hostView={props.hostView} />
                        <Typography>View Participants</Typography>
                    </Box>

                    <Box className="participants-list-details">
                        {participantsList.map((participants) => {
                            return (
                                <>
                                    <Box className="user-details" onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
                                        <Box className="participant-image-section">
                                            <img className="participant-image" src={participants.profileImage} alt="" />
                                            {participants.isHost && (
                                                <Box className="host-icon">
                                                    <ParticipantListHostIcon />
                                                </Box>
                                            )}
                                        </Box>
                                        <Box className="participant-name-details">
                                            {participants.isSpeaking && <UserSpeakingLeftArrows hostView={props.hostView} />}
                                            <Typography className="participant-name">
                                                {participants.firstname} {participants.lastname}
                                            </Typography>
                                            {participants.isSpeaking && <UserSpeakingRightArrows hostView={props.hostView} />}
                                        </Box>
                                    </Box>
                                </>
                            );
                        })}

                        <CustomChatContextMenu
                            open={participantsContextMenu !== null}
                            onClose={handleParticipantsContextMenuClose}
                            anchorReference="anchorPosition"
                            anchorPosition={participantsContextMenu !== null ? { top: participantsContextMenu.mouseY, left: participantsContextMenu.mouseX } : undefined}
                        >
                            {props.hostView ? (
                                <>
                                    <MenuItem onClick={handleParticipantsContextMenuClose}>
                                        <SendMessageIcon height="22" width="22" />
                                        <Typography>Send Message</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleParticipantsContextMenuClose}>
                                        <ParticipantListHostIcon height="22" width="22" />
                                        <Typography>Make Co-host</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleParticipantsContextMenuClose}>
                                        <MuteAllIcon fill="#B08C3D" height="22" width="22" />
                                        <Typography>Mute</Typography>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem onClick={handleParticipantsContextMenuClose}>
                                        <ExitIcon height="22" width="22" />
                                        <Typography>Remove</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleParticipantsContextMenuClose}>
                                        <ClearButtonIcon fill="#BC1218" height="22" width="22" />
                                        <Typography>Ban</Typography>
                                    </MenuItem>
                                </>
                            ) : (
                                <MenuItem onClick={handleParticipantsContextMenuClose}>
                                    <AddFriendIcon />
                                    <Typography>Add Friend</Typography>
                                </MenuItem>
                            )}
                        </CustomChatContextMenu>
                    </Box>
                </Box>

                <Box className="participant-search-section">
                    {props.hostView && (
                        <Box className="mute-section">
                            <Button variant="contained" className="mute-buttons">
                                <MuteAllIcon />
                                <Typography className="mute-all">MUTE ALL</Typography>
                            </Button>
                            <Button variant="contained" className="mute-buttons">
                                <UnMuteAllIcon />
                                <Typography className="unmute-all">UNMUTE ALL</Typography>
                            </Button>
                        </Box>
                    )}

                    <Box className="search-input-container">
                        <IconButton onClick={() => props.closeModal(false)}>
                            <CloseChatIconButton fill={props.hostView ? '#FFFFFF' : '#7D5416'} />
                        </IconButton>
                        <CustomParticipantsViewSearchInput
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Search"
                            id="chat-message-input"
                        />
                    </Box>
                </Box>
            </CustomParticipantsViewDrawer>
        </>
    );
};

export default CustomParticipantsView;
