import { Box, InputAdornment } from '@mui/material';
import { CustomSearchInputBox } from './Store.style';
import { useState } from 'react';
import { ReactComponent as CrossIcon } from 'Assets/images/MeditationTimer/cross-icon.svg';

const SearchIcon = () => {
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.39518 0C14.4387 0 18.5321 4.032 18.5321 9C18.5321 13.968 14.4387 18 9.39518 18C4.35162 18 0.258301 13.968 0.258301 9C0.258301 4.032 4.35162 0 9.39518 0ZM9.39518 16C13.321 16 16.5016 12.867 16.5016 9C16.5016 5.132 13.321 2 9.39518 2C5.46835 2 2.28872 5.132 2.28872 9C2.28872 12.867 5.46835 16 9.39518 16ZM18.0092 16.071L20.8813 18.899L19.4447 20.314L16.5737 17.485L18.0092 16.071Z"
                fill="#656565"
            />
        </svg>
    );
};

export const SearchBar = ({ debouncedHandleChange = () => {} }) => {
    const [searchedKeyword, setSearchKeyword] = useState('');

    const handleSearchOnEnter = (e) => {
        if (e.keyCode === 13) {
        }
    };
    return (
        <Box className="flex search-input-button f-v-center">
            <CustomSearchInputBox
                InputProps={{
                    classes: {
                        notchedOutline: 'notchedOutline',
                        root: 'base-root',
                        focused: 'focused',
                        adornedStart: 'start'
                    },
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end" className="search-input-text-icon hover">
                            {searchedKeyword && (
                                <CrossIcon
                                    onClick={() => {
                                        setSearchKeyword('');
                                        debouncedHandleChange('');
                                    }}
                                />
                            )}
                        </InputAdornment>
                    )
                }}
                value={searchedKeyword}
                placeholder="Search"
                onChange={(e) => {
                    if (!e.target.value) {
                        setSearchKeyword('');
                        debouncedHandleChange('');
                        return;
                    }
                    setSearchKeyword(e.target.value);
                    debouncedHandleChange(e.target.value);
                }}
                onKeyDown={handleSearchOnEnter}
            />
        </Box>
    );
};
