import React, { useEffect, useState } from 'react';
import { ResetPasswordWrapper } from '../ResetPassword.style';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, OutlinedInput, Typography, FormHelperText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { API_URL, URL_RESET_PASSWORD_EMAIL_SENT } from 'Helpers/Paths';

// FORM VALIDATION
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { EMAIL_REGEX, getWindowDimensions } from 'Helpers';
import Api from 'Helpers/ApiHandler';
import CODES from 'Helpers/StatusCodes';
import { useDispatch } from 'react-redux';
import { showToast } from 'Redux/App/Actions';

const ResetPassword = (props) => {
    const API = new Api();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .required('Please enter your email address')
                .email('Please enter a valid email address')
                .matches(EMAIL_REGEX, 'Please enter a valid email address')
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            const response = await API.post(API_URL?.FORGOT_PASSWORD, {
                params: {
                    emailId: values?.email
                }
            });

            if (response.status === CODES.SUCCESS) {
                dispatch(showToast(response?.data?.message));
            }
            setIsLoading(false);
            navigate(URL_RESET_PASSWORD_EMAIL_SENT, { state: { email: values?.email } });
        } catch (error) {
            setIsLoading(false);
        }
    };

    return (
        <ResetPasswordWrapper screen={windowDimensions}>
            <div className="container">
                <div className="reset-password-container">
                    <div className="margin-bottom-spacing flex f-v-center">
                        <div className="arrow-icon">
                            <ArrowBackIosIcon
                                onClick={() => navigate(-1)}
                                style={{ color: '#848484' }}
                            />
                        </div>
                        <div>
                            <Typography className="title">Reset Password</Typography>
                        </div>
                    </div>

                    <div className="margin-bottom-spacing flex">
                        <Typography className="description">
                            Enter your email address and we’ll send you a reset link.
                        </Typography>
                    </div>

                    <form onSubmit={formik.handleSubmit}>
                        <div className="margin-bottom-spacing">
                            <OutlinedInput
                                style={{ width: '100%', backgroundColor: 'white' }}
                                placeholder="Email address"
                                onChange={formik.handleChange}
                                key={'email'}
                                name="email"
                                value={formik.values.email}
                            />
                            {formik?.touched?.email && formik.errors.email && (
                                <FormHelperText error={true}>{formik.errors.email}</FormHelperText>
                            )}
                        </div>

                        <div>
                            <Button
                                type="submit"
                                className="button button-color"
                                disabled={isLoading}
                                variant="contained">
                                Continue
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="image" />
        </ResetPasswordWrapper>
    );
};

export default ResetPassword;
