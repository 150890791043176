import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { Box, Button, Typography } from '@mui/material';
import { getWindowDimensions } from 'Helpers';
import {
    URL_HOW_TO_MEDITATE_CONTENT,
    URL_PROGRAMS_DETAILS,
    URL_SEARCH,
    URL_HOW_TO_MEDITATE
} from 'Helpers/Paths';

import Lottie from 'lottie-react';

// MASCOT
import PointingDownLeft from 'Assets/AnimationFiles/TourAnimation/points-down-and-left.json';
import PointingUpRight from 'Assets/AnimationFiles/TourAnimation/points-up-and-right.json';
// import PointingDownRight from 'Assets/AnimationFiles/TourAnimation/points-down-and-right.json';
import PointingUpLeft from 'Assets/AnimationFiles/TourAnimation/points-up-and-left.json';
import PointingLeft from 'Assets/AnimationFiles/TourAnimation/pointing-left.json';

import StandingDollAnimation from 'Assets/AnimationFiles/Questionnaire/MascotStandingIdleLoop.json';
import { ReactComponent as CloseIcon } from 'Assets/images/Common/close-icon.svg';

import { WebsiteTourWrapper } from './WebsiteTour.style';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import DOMPurify from 'dompurify';
import Slider from 'react-slick';
import { useDispatch } from 'react-redux';
import { tourDisable, tourEnable } from 'Redux/App/Actions';
import { INIT_SPOT_ANIMATION } from 'Helpers/Constants';

const SIDE_BAR_WHITE_CIRCLE_DIMENSION = {
    WIDTH: '90px',
    HEIGHT: '90px',
    WIDTH_BY_TWO: '45px',
    HEIGHT_BY_TWO: '45px'
};

const CONTENT_DIMENSION = {
    WIDTH: '500px',
    HEIGHT: '200px',
    WIDTH_BY_TWO: '250px',
    HEIGHT_BY_TWO: '100px'
};

const MASCOT_WIDTH = 300;
const MASCOT_TIMEOUT = 500;

const STEPs_INDEX = [
    {
        step: 'INIT',
        content: 'Recommendations <b>For You</b>, based on the goals in your profile.'
    },
    { step: 'WELLNESS_LIBRARY', content: 'Browse the <b>Wellness Library</b> here...' },
    { step: 'WELLNESS_MENU_ICON', content: '...or in the <b>Menu</b> here...' },
    { step: 'SEARCH_MENU_ICON', content: '...or <b>Search</b> here!' },

    // OTHERS
    { step: 'OTHER', content: 'What would you like to check out next, <First Name>?' }
];

// 6 STEPS
const LOTTIE_ANIMATION = [
    PointingUpLeft,
    PointingLeft,
    PointingLeft,
    PointingLeft,
    StandingDollAnimation,
    PointingUpRight,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const IDLE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const TABLET_LOTTIE_ANIMATION = [
    PointingUpLeft,
    PointingDownLeft,
    PointingDownLeft,
    PointingDownLeft,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const TABLET_IDLE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const MOBILE_LOTTIE_ANIMATION = [
    PointingUpLeft,
    PointingLeft,
    PointingDownLeft,
    PointingDownLeft,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const MOBILE_IDLE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    touchMove: false,
    swipeToSlide: false
};

const ListButtons = forwardRef(
    (
        {
            backButtonTitle = '',
            nextButtonTitle = '',
            currentStep,
            setCurrentStep,
            updateButtons,
            isFinalStep = true,
            contentId,
            programId,
            ...props
        },
        ref
    ) => {
        const navigate = useNavigate();
        return (
            <Box className="button-parent-wrapper flex f-column gap">
                <Button
                    onClick={() => {
                        if (isFinalStep && contentId) {
                            return navigate(URL_HOW_TO_MEDITATE_CONTENT, {
                                state: { contentId, programId }
                            });
                        }
                    }}
                    className={`btn primary full-width`}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize('Experience a Meditation')
                        }}
                        className="font"
                    />
                </Button>
                {programId !== -1 && (
                    <Button
                        onClick={() => {
                            if (isFinalStep) {
                                return navigate(URL_PROGRAMS_DETAILS, {
                                    state: { contentId, programId, isRedirectedFromHistory: false }
                                });
                            }
                        }}
                        className={`btn primary full-width`}>
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize('Join a Program')
                            }}
                            className="font"
                        />
                    </Button>
                )}
                <Button
                    onClick={() => {
                        if (isFinalStep) {
                            return navigate(URL_SEARCH, {
                                state: { programId, contentId }
                            });
                        }
                    }}
                    className={`btn primary full-width`}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize('Search for More')
                        }}
                        className="font"
                    />
                </Button>
                <Button
                    onClick={() => {
                        if (isFinalStep) {
                            return navigate(URL_HOW_TO_MEDITATE, {
                                state: { programId, contentId }
                            });
                        }
                    }}
                    className={`btn primary full-width`}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize('Learn How This Works')
                        }}
                        className="font"
                    />
                </Button>
            </Box>
        );
    }
);

const FooterButtons = forwardRef(
    (
        {
            backButtonTitle = '',
            nextButtonTitle = '',
            currentStep,
            setCurrentStep,
            updateButtons,
            isFinalStep = false,
            ...props
        },
        ref
    ) => (
        <Box className="button-parent-wrapper">
            <Button
                onClick={() => {
                    if (currentStep - 1 === 0) props?.setStartingTourId(null);
                    if (isFinalStep) return props?.startFormInit();
                    ref.current.slickPrev();
                    setCurrentStep(currentStep - 1);
                }}
                className={`btn back ${!currentStep && 'hide'}`}>
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(backButtonTitle)
                    }}
                    className={`font ${isFinalStep && 'text-transform'}`}
                />
            </Button>
            <Button
                onClick={() => {
                    if (currentStep + 1 === 1) props?.setStartingTourId(null);
                    if (isFinalStep) return props?.closeTutorial();
                    if (currentStep + 1 === STEPs_INDEX?.length - 1) updateButtons();
                    ref.current.slickNext();
                    setCurrentStep(currentStep + 1);
                }}
                className={`btn font primary`}>
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(nextButtonTitle)
                    }}
                    className={`font ${isFinalStep && 'text-transform'}`}
                />
            </Button>
        </Box>
    )
);

const WebsiteTour = ({
    isLoading,
    contentId,
    programId,
    handleMascotTour,
    userDetails,
    ...props
}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isCompletedAnimation, setIsCompletedAnimation] = useState(false);
    const [loop, setLoop] = useState(false);
    const animationRef = useRef(null);
    const slickRef = useRef(null);
    const buttonRef = useRef(null);
    const [mascotPosition, setMascotPosition] = useState({
        transform: undefined
    });
    const [initAnimationSpot, setInitAnimationSpot] = useState('');

    const dispatch = useDispatch();

    const [tourStaringId, setStartingTourId] = useState({
        width: undefined,
        height: undefined,
        transform: undefined
    });
    const [contentDimension, setContentDimension] = useState({
        width: CONTENT_DIMENSION.WIDTH,
        height: CONTENT_DIMENSION.HEIGHT,
        halfWidth: CONTENT_DIMENSION.WIDTH_BY_TWO,
        halfHeight: CONTENT_DIMENSION.HEIGHT_BY_TWO
    });
    const [polygonDimension, setPolygonDimension] = useState('');

    const [boxPosition, setBoxPosition] = useState('');
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const scroll = (id, position) => {
        const section = document.getElementById(id);
        section.scrollIntoView({ behavior: 'smooth', block: position });
    };

    const setWellnessLibraryDimensions = useCallback((id) => {
        const element = document.getElementById(id);
        const elementRect = element.getBoundingClientRect();
        let animationStartingSpot = (INIT_SPOT_ANIMATION - elementRect?.width + 20) / 2;
        console.log('call from here setWellnessLibraryDimensions');
        setInitAnimationSpot(
            `translate(calc(${elementRect.left}px - 10px - ${animationStartingSpot}px), calc(${
                elementRect.top
            }px - ${elementRect.width / 2}px + ${
                elementRect.height / 2
            }px - ${animationStartingSpot}px))`
        );

        setStartingTourId({
            transform: `translate(calc(${elementRect.left}px - 10px), calc(${elementRect.top}px - ${
                elementRect.width / 2
            }px + ${elementRect.height / 2}px))`,
            width: `${elementRect.width + 20}px`,
            height: `${elementRect.width + 20}px`
        });
        return;
    }, []);

    const setSidebarMenuDimensions = useCallback((animationDimension, id) => {
        const element = document.getElementById(id);
        const elementRect = element.getBoundingClientRect();

        //  transform: `translate(calc(${elementRect.left}px - 20px), calc(${elementRect.top}px - ${
        //      SIDE_BAR_WHITE_CIRCLE_DIMENSION.HEIGHT_BY_TWO
        //  } + ${elementRect.height / 2}px - 5px))`,
        setStartingTourId({
            // transform: `translate(calc(${elementRect.left}px - 20px), calc(${elementRect.top}px - ${SIDE_BAR_WHITE_CIRCLE_DIMENSION.HEIGHT_BY_TWO} + 7px))`,
            transform: `translate(calc(${elementRect.left}px - 20px), calc(${elementRect.top}px - ${
                SIDE_BAR_WHITE_CIRCLE_DIMENSION.HEIGHT_BY_TWO
            } + ${elementRect.height / 2}px - 5px))`,
            width: SIDE_BAR_WHITE_CIRCLE_DIMENSION.WIDTH,
            height: SIDE_BAR_WHITE_CIRCLE_DIMENSION.HEIGHT
        });
        return;
    }, []);

    const setMascotDimensions = useCallback(
        (animationDimension, id) => {
            const element = document.getElementById(id);
            const elementRect = element.getBoundingClientRect();

            if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                let initTransform = `translate(calc(${windowDimensions.width}px - 200px), 415px)`;

                setMascotPosition({
                    transform: `translate(calc(${windowDimensions.width}px - 200px), 415px)`
                });
                setBoxPosition(
                    `translate(calc(${windowDimensions.width}px - ${contentDimension.width} - 10px), 20px)`
                );
                setPolygonDimension(`translate(0px, 360px) rotate(45deg)`);
                setStartingTourId({
                    transform: initTransform,
                    width: 200,
                    height: 200
                });

                if (currentStep === 4) {
                    setPolygonDimension(`translate(-190px, 363px) rotate(45deg)`);
                } else {
                    setPolygonDimension(`translate(0px, 360px) rotate(45deg)`);
                }
                return;
            }

            if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
                setStartingTourId({
                    transform: `translate(calc(${elementRect.left}px + 50px), calc(${elementRect.top}px + 40px))`,
                    width: CONTENT_DIMENSION.HEIGHT,
                    height: CONTENT_DIMENSION.HEIGHT
                });
            }

            if (windowDimensions.width >= BREAKPOINTS_VALUE.TABLET) {
                setStartingTourId({
                    transform: `translate(calc(${elementRect.left}px + 50px), calc(${elementRect.top}px + 40px))`,
                    width: CONTENT_DIMENSION.HEIGHT,
                    height: CONTENT_DIMENSION.HEIGHT
                });

                if (currentStep === 4) {
                    const initBox = `translate(calc(${elementRect.left}px - 490px), calc(${elementRect.top}px + 40px + 10px))`;
                    setBoxPosition(initBox);
                    setPolygonDimension(`translate(0px, 90px) rotate(45deg)`);
                }
            }
        },
        [windowDimensions.width, currentStep, contentDimension.width]
    );

    const wellnessLibraryContent = useCallback(
        (id) => {
            let animationDimension = document?.getElementById?.(id)?.getBoundingClientRect?.();

            if (animationDimension) {
                setTimeout(() => {
                    setWellnessLibraryDimensions(id);
                }, MASCOT_TIMEOUT);
            }
        },
        [setWellnessLibraryDimensions]
    );

    const sidebarMenuContent = useCallback(
        (id) => {
            let animationDimension = document?.getElementById?.(id)?.getBoundingClientRect?.();

            if (animationDimension)
                setTimeout(() => setSidebarMenuDimensions(animationDimension, id), MASCOT_TIMEOUT);
        },
        [setSidebarMenuDimensions]
    );

    const mascotContent = useCallback(
        (id) => {
            let animationDimension = document?.getElementById?.(id)?.getBoundingClientRect?.();

            if (animationDimension) {
                setTimeout(() => setMascotDimensions(animationDimension, id), MASCOT_TIMEOUT);
            }
        },
        [setMascotDimensions]
    );

    const initAnimation = useCallback(() => {
        // Cache elements based on step
        const elementIdMap = {
            INIT: 'tour-home-name',
            WELLNESS_LIBRARY: 'wellness-title-id',
            WELLNESS_MENU_ICON: 'wellness-menu-id',
            SEARCH_MENU_ICON: 'search-menu-id',
            OTHER: 'mascot-id'
        };

        // Get the element ID based on current step
        const elementId = elementIdMap[STEPs_INDEX?.[currentStep]?.step] || 'tour-home-name';
        const element = document.getElementById(elementId);
        const animationDimension = element?.getBoundingClientRect();

        if (!animationDimension) return; // Early exit if dimension is not available

        const { width, height, left, top } = animationDimension;
        const animationStartingSpot = (INIT_SPOT_ANIMATION - width + 20) / 2;
        console.log('call from here initAnimation');
        let initTransform = `translate(calc(${left}px - 10px), calc(${top}px - ${height}px))`;
        let initAnimationSpot = `translate(calc(${left}px - 10px - ${animationStartingSpot}px), calc(${top}px - ${height}px - ${animationStartingSpot}px))`;
        let boxPosition;
        let polygonDimension;
        let mascotPosition;
        let startingTourId = {
            transform: initTransform,
            width: `${width + 20}px`,
            height: `${width + 20}px`
        };

        if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
            console.log('call 1');

            initAnimationSpot = `translate(calc(${left}px - 10px - ${animationStartingSpot}px), calc(${top}px - ${height}px - ${animationStartingSpot}px))`;
            mascotPosition = {
                transform: `translate(calc(${windowDimensions.width}px - 200px), calc(325px))`
            };
            boxPosition = `translate(calc(${windowDimensions.width}px - ${contentDimension.width} - 10px), calc(140px))`;
            polygonDimension = `translate(-60px, 180px) rotate(45deg)`;
        } else if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            console.log('call 2');

            mascotPosition = {
                transform: `translate(calc(${windowDimensions.width}px - ${MASCOT_WIDTH}px), 100px)`
            };
            boxPosition = `translate(calc(${windowDimensions.width}px - ${contentDimension.width} - 50px), 340px)`;
            polygonDimension = `translate(0px, calc(-35px)) rotate(45deg)`;
        } else {
            console.log(windowDimensions.width, contentDimension.halfWidth, contentDimension.width);
            const initMascotTransform = `translate(calc(${windowDimensions.width}px - ${
                contentDimension.halfWidth
            } - ${MASCOT_WIDTH / 2}px), calc(${windowDimensions.height / 3}px))`;

            mascotPosition = { transform: initMascotTransform };

            boxPosition = `translate(calc(${windowDimensions.width}px - ${
                contentDimension.width
            } - ${MASCOT_WIDTH / 2}px), calc(${windowDimensions.height / 3}px + ${
                CONTENT_DIMENSION.HEIGHT
            } + ${MASCOT_WIDTH / 4}px))`;

            polygonDimension = `translate(-100px, -30px) rotate(45deg)`;
        }

        setInitAnimationSpot(initAnimationSpot);
        setStartingTourId(startingTourId);
        setBoxPosition(boxPosition);
        setPolygonDimension(polygonDimension);
        setMascotPosition(mascotPosition);
    }, [
        currentStep,
        windowDimensions.width,
        windowDimensions.height,
        contentDimension.width,
        contentDimension.halfWidth
    ]);

    // const initAnimation = useCallback(() => {
    //     let animationDimension;
    //     switch (STEPs_INDEX?.[currentStep]?.step) {
    //         case 'INIT':
    //             animationDimension = document
    //                 ?.getElementById?.('tour-home-name')
    //                 ?.getBoundingClientRect?.();
    //             break;
    //         case 'WELLNESS_LIBRARY':
    //             animationDimension = document
    //                 ?.getElementById?.('wellness-title-id')
    //                 ?.getBoundingClientRect?.();
    //             break;
    //         case 'WELLNESS_MENU_ICON':
    //             animationDimension = document
    //                 ?.getElementById?.('wellness-menu-id')
    //                 ?.getBoundingClientRect?.();
    //             break;
    //         case 'SEARCH_MENU_ICON':
    //             animationDimension = document
    //                 ?.getElementById?.('search-menu-id')
    //                 ?.getBoundingClientRect?.();
    //             break;

    //         case 'OTHER':
    //             animationDimension = document
    //                 ?.getElementById?.('mascot-id')
    //                 ?.getBoundingClientRect?.();
    //             break;

    //         default:
    //             animationDimension = document
    //                 ?.getElementById?.('tour-home-name')
    //                 ?.getBoundingClientRect?.();
    //             break;
    //     }

    //     let animationStartingSpot = (INIT_SPOT_ANIMATION - animationDimension?.width + 20) / 2;

    //     if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
    //         let initTransform = `translate(calc(${animationDimension?.left}px - 10px), calc(${animationDimension?.top}px - ${animationDimension?.height}px))`;

    //         setInitAnimationSpot(
    //             `translate(calc(${animationDimension?.left}px - 10px - ${animationStartingSpot}px), calc(${animationDimension?.top}px - ${animationDimension?.height}px - ${animationStartingSpot}px))`
    //         );

    //         setStartingTourId({
    //             transform: initTransform,
    //             width: `${animationDimension?.width + 20}px`,
    //             height: `${animationDimension?.width + 20}px`
    //         });
    //         setMascotPosition({
    //             transform: `translate(calc(${windowDimensions.width}px - 200px), calc(325px))`
    //         });
    //         setBoxPosition(
    //             `translate(calc(${windowDimensions.width}px - ${contentDimension.width} - 10px), calc(140px))`
    //         );
    //         setPolygonDimension(`translate(-60px, 180px) rotate(45deg)`);
    //         return;
    //     }
    //     if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
    //         let initTransform = `translate(calc(${animationDimension?.left}px - 10px), calc(${animationDimension?.top}px - ${animationDimension?.height}px))`;
    //         setInitAnimationSpot(
    //             `translate(calc(${animationDimension?.left}px - 10px - ${animationStartingSpot}px), calc(${animationDimension?.top}px - ${animationDimension?.height}px - ${animationStartingSpot}px))`
    //         );
    //         setStartingTourId({
    //             transform: initTransform,
    //             width: `${animationDimension?.width + 20}px`,
    //             height: `${animationDimension?.width + 20}px`
    //         });
    //         setBoxPosition(
    //             `translate(calc(${windowDimensions.width}px - ${contentDimension.width} - 50px), 340px)`
    //         );
    //         setPolygonDimension(`translate(0px, calc(-35px)) rotate(45deg)`);
    //         setMascotPosition({
    //             transform: `translate(calc(${windowDimensions.width}px - ${MASCOT_WIDTH}px), 100px)`
    //         });
    //         return;
    //     }
    //     if (animationDimension && windowDimensions.width >= BREAKPOINTS_VALUE.TABLET) {
    //         // let MINUS_TOP_FOR_TEXT = 150;
    //         let initTransform = `translate(calc(${animationDimension?.left}px - 10px), calc(${animationDimension?.top}px - ${animationDimension?.height}px))`;
    //         let initMascotTransform = `translate(calc(${windowDimensions.width}px - ${
    //             contentDimension.halfWidth
    //         } - ${MASCOT_WIDTH / 2}px), calc(${windowDimensions?.height / 3}px))`;

    //         setInitAnimationSpot(
    //             `translate(calc(${animationDimension?.left}px - 10px - ${animationStartingSpot}px), calc(${animationDimension?.top}px - ${animationDimension?.height}px - ${animationStartingSpot}px))`
    //         );
    //         setStartingTourId({
    //             transform: initTransform,
    //             width: `${animationDimension?.width + 20}px`,
    //             height: `${animationDimension?.width + 20}px`
    //         });

    //         setBoxPosition(
    //             `translate(calc(${windowDimensions.width}px - ${contentDimension.width} - ${
    //                 MASCOT_WIDTH / 2
    //             }px), calc(${windowDimensions?.height / 3}px + ${CONTENT_DIMENSION.HEIGHT} + ${
    //                 MASCOT_WIDTH / 4
    //             }px))`
    //         );
    //         setPolygonDimension(`translate(-100px, -30px) rotate(45deg)`);

    //         setMascotPosition({ transform: initMascotTransform });
    //         return;
    //     }
    // }, [
    //     currentStep,
    //     windowDimensions.width,
    //     windowDimensions?.height,
    //     contentDimension.width,
    //     contentDimension.halfWidth
    // ]);

    const replayAnimation = () => {
        animationRef.current.stop();
        animationRef.current.play();
    };

    const updateAnimationStatus = () => {
        setIsCompletedAnimation(false);
        setLoop(false);
    };

    const jumpToStep = useCallback(() => {
        switch (STEPs_INDEX?.[currentStep]?.step) {
            case 'INIT':
                updateAnimationStatus();
                scroll('tour-home-name', 'center');
                setTimeout(() => initAnimation(), MASCOT_TIMEOUT);
                break;

            case 'WELLNESS_LIBRARY':
                updateAnimationStatus();
                replayAnimation();
                scroll('wellness-title-id', 'center');
                wellnessLibraryContent('wellness-title-id');
                break;
            case 'WELLNESS_MENU_ICON':
                updateAnimationStatus();
                replayAnimation();
                sidebarMenuContent('wellness-menu-id');
                break;
            case 'SEARCH_MENU_ICON':
                updateAnimationStatus();
                replayAnimation();
                sidebarMenuContent('search-menu-id');
                break;
            case 'OTHER':
                updateAnimationStatus();
                mascotContent('mascot-id');
                break;
            default:
                updateAnimationStatus();
                initAnimation();
                return;
        }
    }, [initAnimation, currentStep, wellnessLibraryContent, sidebarMenuContent, mascotContent]);

    const updateButtons = () => {
        buttonRef.current.slickNext();
    };

    const mascotAnimation = useCallback(
        (currentStep, isCompletedAnimation) => {
            if (windowDimensions.width >= BREAKPOINTS_VALUE.TABLET) {
                if (isCompletedAnimation) {
                    return IDLE_LOTTIE_ANIMATION[currentStep];
                }
                return LOTTIE_ANIMATION[currentStep];
            }

            if (
                windowDimensions.width >= BREAKPOINTS_VALUE.PHABLET &&
                windowDimensions.width <= BREAKPOINTS_VALUE.TABLET
            ) {
                if (isCompletedAnimation) {
                    return TABLET_IDLE_LOTTIE_ANIMATION[currentStep];
                }
                return TABLET_LOTTIE_ANIMATION[currentStep];
            }

            if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                if (isCompletedAnimation) {
                    return MOBILE_IDLE_LOTTIE_ANIMATION[currentStep];
                }
                return MOBILE_LOTTIE_ANIMATION[currentStep];
            }
        },
        [windowDimensions.width]
    );

    const boxClass = useCallback(
        (currentStep) => {
            if (currentStep === 4) {
                if (
                    windowDimensions.width >= BREAKPOINTS_VALUE.PHABLET &&
                    windowDimensions.width <= BREAKPOINTS_VALUE.TABLET
                ) {
                    return 'tablet';
                }

                if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                    return 'phablet';
                }
            }
            return '';
        },
        [windowDimensions.width]
    );

    // const startFormInit = () => {
    //     slickRef?.current?.slickGoTo(0);
    //     buttonRef?.current?.slickPrev();
    //     setCurrentStep(0);
    // };

    useEffect(() => {
        jumpToStep(currentStep);
        if (windowDimensions.width <= BREAKPOINTS_VALUE.MOBILE) {
            setContentDimension((prev) => ({ ...prev, width: '300px', height: '220px' }));
            return;
        }
        if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
            setContentDimension((prev) => ({ ...prev, width: '400px', height: '220px' }));
            return;
        }
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            setContentDimension((prev) => ({ ...prev, width: '300px', height: '250px' }));
            return;
        }
        if (windowDimensions.width > BREAKPOINTS_VALUE.TABLET) {
            setContentDimension((prev) => ({
                ...prev,
                width: CONTENT_DIMENSION.WIDTH,
                height: CONTENT_DIMENSION.HEIGHT
            }));
            return;
        }
    }, [
        windowDimensions.height,
        windowDimensions.width,
        isLoading,
        jumpToStep,
        currentStep,
        windowDimensions
    ]);

    useEffect(() => {
        initAnimation();
    }, [windowDimensions]);

    useEffect(() => {
        const handleResize = () => {
            initAnimation();
            setWindowDimensions(getWindowDimensions());
        };
        dispatch(tourEnable());
        window.addEventListener('resize', handleResize);
        return () => {
            dispatch(tourDisable());
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch, windowDimensions]);

    return (
        <React.Fragment>
            {!isLoading &&
                createPortal(
                    <WebsiteTourWrapper $initAnimationSpot={initAnimationSpot}>
                        <Box className="tour">
                            {console.log(tourStaringId)}
                            {tourStaringId?.transform && (
                                <Box
                                    className="tour-starting-box"
                                    style={{
                                        transform: tourStaringId?.transform,
                                        width: tourStaringId?.width,
                                        height: tourStaringId?.width
                                    }}
                                />
                            )}
                        </Box>
                        <Box className="content-container">
                            {mascotPosition?.transform && (
                                <Lottie
                                    className="mascot"
                                    id="mascot-id"
                                    animationData={mascotAnimation(
                                        currentStep,
                                        isCompletedAnimation
                                    )}
                                    style={{
                                        transform: mascotPosition?.transform
                                    }}
                                    onComplete={() => {
                                        setIsCompletedAnimation(true);
                                        setLoop(true);
                                    }}
                                    lottieRef={animationRef}
                                    loop={loop}
                                />
                            )}

                            {boxPosition && (
                                <Box
                                    className="tour-content"
                                    style={{
                                        transform: boxPosition,
                                        width: contentDimension?.width,
                                        height: currentStep === 4 ? 400 : contentDimension?.height
                                    }}>
                                    <Box
                                        className="polygon"
                                        style={{ transform: polygonDimension }}
                                    />
                                    <Box
                                        className="close-icon-btn hover"
                                        onClick={() => {
                                            handleMascotTour();
                                            props?.setTourEnable(false);
                                        }}>
                                        <CloseIcon className="close-svg" />
                                    </Box>
                                    <Box className="content">
                                        <Slider {...settings} ref={slickRef}>
                                            {STEPs_INDEX.map((item, index) => (
                                                <Box
                                                    className="text"
                                                    key={index}
                                                    dangerouslySetInnerHTML={{
                                                        __html: item?.content?.includes(
                                                            '<First Name>'
                                                        )
                                                            ? DOMPurify.sanitize(
                                                                  item?.content?.replace(
                                                                      '<First Name>',
                                                                      userDetails?.firstName
                                                                  )
                                                              )
                                                            : DOMPurify.sanitize(item?.content)
                                                    }}
                                                />
                                            ))}
                                        </Slider>

                                        <Box className={`footer-buttons ${boxClass(currentStep)}`}>
                                            <Slider {...settings} ref={buttonRef}>
                                                <FooterButtons
                                                    ref={slickRef}
                                                    currentStep={currentStep}
                                                    setCurrentStep={setCurrentStep}
                                                    backButtonTitle="back"
                                                    nextButtonTitle="next"
                                                    updateButtons={updateButtons}
                                                    setStartingTourId={setStartingTourId}
                                                />
                                                <ListButtons
                                                    ref={slickRef}
                                                    currentStep={currentStep}
                                                    setCurrentStep={setCurrentStep}
                                                    backButtonTitle="back"
                                                    nextButtonTitle="next"
                                                    updateButtons={updateButtons}
                                                    contentId={contentId}
                                                    programId={programId}
                                                />
                                            </Slider>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </WebsiteTourWrapper>,
                    document.body
                )}
        </React.Fragment>
    );
};

export default WebsiteTour;
