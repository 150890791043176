//core
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useDispatch } from 'react-redux';

//custom components
import { ProfileInterestSectionWrapper } from './Interest.style';
import TaoChip from 'Components/common/TaoChip/TaoChip';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { showSubscriptionModal, showToast } from 'Redux/App/Actions';
import { CustomSwitch } from 'Components/common/Switch.style';
import TaoScrollbar from 'Components/common/Scrollbar';
import { SubscriptionLightLock } from 'Components/common/SubscriptionLock';
import usePermissions from 'Components/common/Permissions';
import { useSelector } from 'react-redux';

const ProfileInterest = ({ setIsLoading, ...props }) => {
    const API = useMemo(() => new Api(), []);
    const dispatch = useDispatch();
    const [showPublicProfile, setPublicProfile] = useState(false);
    const [previousSelectedChips, setPreviousSelectedChips] = useState([]);
    const [interests, setInterests] = useState([]);
    const profilePermissions = usePermissions();
    const userInfo = useSelector((state) => state.Auth.userInfo);

    const getInterestApi = useCallback(async () => {
        setIsLoading(true);
        let response = await API.get(API_URL.PROFILE_INTEREST);
        if (response) {
            setInterests(response?.data?.data?.list);
            setPreviousSelectedChips(
                response?.data?.data?.list
                    .filter((item) => item?.isSelected)
                    .map((item) => item?.id)
            );
            setPublicProfile(response?.data?.data?.isViewInterest);
            setIsLoading(false);
        }
    }, [API, setIsLoading]);

    const saveProfileInterest = async () => {
        setIsLoading(true);
        let newSelectedInterests = [];
        let deletedInterests = [];

        let currentStateOfSelection = interests
            .filter((item) => item?.isSelected)
            .map((item) => item?.id);

        previousSelectedChips.forEach((item) => {
            if (!currentStateOfSelection.includes(item)) deletedInterests.push(item);
        });
        currentStateOfSelection.forEach((item) => {
            if (!previousSelectedChips.includes(item)) newSelectedInterests.push(item);
        });

        if (!currentStateOfSelection.length && showPublicProfile) {
            setIsLoading(false);
            dispatch(
                showToast(
                    'Your interest content is shown to other users on your profile. Please add some data in interest, or please turn off the option for ("Show on Public Profile").',
                    'error'
                )
            );
            return;
        }

        const data = {
            keywordIds: newSelectedInterests,
            deletedKeywordIds: deletedInterests,
            isViewInterest: showPublicProfile
        };
        let response = await API.post(API_URL.PROFILE_INTEREST, {
            data
        });
        if (response) {
            setIsLoading(false);
            dispatch(showToast('Successfully added your interests', 'success'));
            getInterestApi();
            props.resetLeftMenuHandler();
        }
    };

    const selectChip = (chipId, index = 0) => {
        if (!chipId) return;
        const newInterests = [...interests];
        newInterests[index].isSelected = !newInterests[index].isSelected;
        setInterests(newInterests);
    };

    useEffect(() => {
        getInterestApi();
    }, [getInterestApi]);

    return (
        <ProfileInterestSectionWrapper>
            <TaoScrollbar displayScroll>
                <Box className="interest-container">
                    <Typography className="interest-instruction-text">
                        {userInfo?.firstName}, update your interest here to help us support you with
                        the right content!
                    </Typography>
                    <Typography className="title">interests</Typography>
                    {interests.map((data, index) => (
                        <React.Fragment key={data?.id || index}>
                            <TaoChip
                                id={data.id}
                                index={index}
                                title={data.name}
                                selected={data.isSelected}
                                selectChip={selectChip}
                            />
                        </React.Fragment>
                    ))}

                    <Box className="privacy">
                        <Typography className="title">privacy settings</Typography>
                        <Box className="flex f-h-space-between">
                            <Typography className="show-on-public-profile">
                                Show on public profile
                            </Typography>

                            <CustomSwitch
                                checked={showPublicProfile}
                                classes={{
                                    root: 'switch-root',
                                    track: `switch-track ${showPublicProfile && 'active'}`,
                                    thumb: `switch-thumb ${showPublicProfile && 'active'}`
                                }}
                                onChange={(e) => setPublicProfile(e?.target?.checked)}
                            />
                        </Box>
                    </Box>
                    <Button
                        className="interest-submit-btn"
                        onClick={() =>
                            !profilePermissions?.canAccess
                                ? dispatch(showSubscriptionModal())
                                : saveProfileInterest()
                        }
                        endIcon={!profilePermissions?.canAccess && <SubscriptionLightLock />}>
                        save
                    </Button>
                </Box>
            </TaoScrollbar>
        </ProfileInterestSectionWrapper>
    );
};

export default ProfileInterest;
