import { Box, Typography } from '@mui/material';
import { NotificationStyleWrapper } from './PushNotification.style';
import { ReactComponent as ClearNotificationIcon } from 'Assets/images/Notifications/clear-notification-icon.svg';

const PushNotification = (data, closeSnackbar, image, redirectionUrl = '/') => (
    <NotificationStyleWrapper
        className="flex f-v-center hover"
        onClick={() => window.open(redirectionUrl)}>
        <Box className="logo" style={{ backgroundImage: `url(${image})` }} />
        <Box className="flex f-column" width={300}>
            <Typography className="text primary elipsis">{data?.title}</Typography>
            <Typography className="text secondary">{data?.body}</Typography>
        </Box>

        <ClearNotificationIcon
            style={{ alignSelf: 'flex-start' }}
            className="hover"
            onClick={(e) => {
                e.stopPropagation();
                closeSnackbar();
            }}
        />
    </NotificationStyleWrapper>
);

export default PushNotification;
