import React from 'react';
import { MeditationCardWrapper } from './MeditationCommonCard.style';
import GreenEnergyImage from 'Assets/images/MeditationContent/green-energy.png';
import { Typography } from '@mui/material';
import { ReadMore } from 'Components/common/ReadMore';
import { ReadNow } from 'Components/common/ReadNow';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { convertHoursMinsSecondsToReadableString } from 'Helpers';

const MeditationCommonCard = ({
    image,
    title,
    mins,
    description,
    value,
    url,
    max,
    readNow = false,
    ...props
}) => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        if (url) {
            navigate(url, {
                state: {
                    contentId: value?.contentId
                }
            });
        }
    };

    return (
        <MeditationCardWrapper onClick={() => handleNavigate()} className="hover">
            <img className="image hover" src={image || GreenEnergyImage} alt="" />

            <Box className="content">
                <Typography className="card-title">{title}</Typography>

                {!readNow && (
                    <ReadMore max={max || 35} isFirstTwoWordBold={false}>
                        {description}
                    </ReadMore>
                )}

                {readNow && (
                    <ReadNow max={max || 35} isFirstTwoWordBold={false} url={url} value={value}>
                        {description}
                    </ReadNow>
                )}

                <Typography className="mins">
                    {convertHoursMinsSecondsToReadableString(mins || '00:00')} read
                </Typography>
            </Box>
        </MeditationCardWrapper>
    );
};

export default MeditationCommonCard;
