import { Box } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import styled from 'styled-components';
import Flower from 'Assets/images/Signup/sales-page-flower-bg.svg';
import QuoteIcon from 'Assets/images/Common/quote.svg';

export const SalesPageOneWrapper = styled(Box)`
    height: 100%;
    // min-height: 700px;
    overflow: auto;
    // background: red;

    .bg-color {
        background: linear-gradient(180deg, rgba(75, 161, 188, 0) 0%, rgba(75, 161, 188, 0.7) 100%);
        height: 400px;
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .container {
        width: 50%;
        position: absolute;
        left: 50%;
        top: 70%;
        transform: translate(-50%, -50%);
        height: 100%;

        ${responsive.TABLET`
            width: 80%;
            transform: translate(-50%, -70%);
        `}

        .title {
            color: ${COLORS.PRIMARY};
            font-family: ${FONTS.SECONDARY_BOLD};
            font-size: 40px;

            ${responsive.MOBILE`
                font-size: 24px;    
            `}
        }
        .quote-image {
            background-image: url(${QuoteIcon});
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            height: 50px;
            width: 50px;
            margin: 0 auto 20px;
        }
        .description {
            color: ${COLORS.TEXT_DARK};
            font-family: ${FONTS.PRIMARY};
            font-weight: 500;
            font-size: 24px;

            ${responsive.MOBILE`
                font-size: 18px;    
            `}
        }

        .author-by-dash {
            width: 10px;
            height: 2px;
            background-color: ${COLORS.TEXT_DARK};
            margin: 0 2px 0 0;
        }
    }

    .footer-container {
        position: fixed;
        width: 100%;
        bottom: 0;
        height: 200px;
        z-index: 200;
    }
    .redirection-links {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        bottom: 70px;
        transform: translate(-50%, -0%);
        left: 50%;
        z-index: 210;

        ${responsive.DESKTOP`
            width: 60%;
        `}

        ${responsive.LAPTOP`
            width: 80%;
        `}

        ${responsive.MOBILE`
            width: 90%;
        `}

        .redirection-btn {
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(10px);
            border-radius: 12px;
            height: 60px;
            width: 370px;
            color: ${COLORS.PRIMARY_WARM};
            font-size: 16px;
            text-transform: uppercase;
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};

            ${responsive.PHABLET`
                width: 100%;
            `}
        }

        .footer-text {
            color: ${COLORS.WHITE};
            font-family: ${FONTS.PRIMARY};
            font-weight: 700;
            font-size: 18px;
            margin: 10px 0 0;
            text-decoration: underline;

            &:hover {
                cursor: pointer;
            }

            &.divider {
                padding: 0 5px;
            }
        }
    }
    .flower-image {
        background-image: url(${Flower});
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: fixed;
        bottom: 0;
        right: 0;
        height: 250px;
        width: 450px;
        z-index: 201;

        ${responsive.TABLET`
            height: 220px;
            width: 350px;
        `}
        ${responsive.PHABLET`
            height: 180px;
            width: 300px;
        `}
    }
    .bottom-gradient {
        background: linear-gradient(180deg, rgba(75, 161, 188, 0) 0%, rgba(75, 161, 188, 0.7) 100%);
        height: 35%;
        position: fixed;
        width: 100%;
        z-index: 200;
        bottom: 0;

        ${responsive.LAPTOP`
            height: 40%;
        `}
    }
`;
