import { Popover } from '@mui/material';
import { Box } from '@mui/system';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const MeditationMediaPlayerWrapper = styled(Box)`
    /* height: 100vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: ${(props) => (props?.$isLoading || props?.$isMediaFullScreen ? 'hidden' : 'auto')};
    z-index: ${(props) => (props?.$isInitialExperience ? 201 : 2)};
    position: relative;

    .image-background {
        position: absolute;
        height: 70%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: 1;

        ${responsive.TABLET`
            height: 85%;
        `}

        &.full-screen-mode {
            background: #000;
        }
    }

    .slider-track {
        background-color: #ffd26d;
        border: none;
    }
    .slider-rail {
        background-color: ${COLORS.MEDIUM_GREY};
    }
    .slider-thumb {
        background-color: ${COLORS.PRIMARY_DARK};
    }

    .white-background {
        height: 70%;
        width: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 45.31%);
        position: absolute;
        bottom: 0;
        z-index: 1;

        &.full-screen-mode {
            background: #000;
        }
    }

    .video-player-content {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;

        ${responsive.DESKTOP`
            width: 60%;
        `}

        ${responsive.TABLET`
            width: 80%;
        `}
        
        ${responsive.PHABLET`
            width: 90%;
        `}

        &.full-screen-mode {
            width: 100%;
            padding: 0;
        }

        .playerDetailsWrapper {
            padding: 20px 0 0;
            width: 100%;
            /* overflow: hidden; */

            .bottom-analytics {
                display: flex;
                align-items: center;

                ${responsive.TABLET`
                    flex-direction: column-reverse;
                    gap: 20px;
                `}

                .titleWrapper {
                    width: 100%;

                    ${responsive.TABLET`
                        text-align: center;
                    `}

                    .title {
                        font-family: ${FONTS.SECONDARY_BOLD};
                        font-size: 45px;
                        color: ${COLORS.PRIMARY};
                        text-transform: capitalize;

                        ${responsive.TABLET`
                            font-size: 36px;
                        `}
                    }
                }
                .chapters-repeat-container {
                    max-width: 25%;
                    min-width: 150px;

                    ${responsive.TABLET`
                        max-width: 50%;
                    `}

                    .step {
                        font-family: ${FONTS.PRIMARY};
                        font-size: 20px;
                        color: ${COLORS.MEDIUM_GREY};
                        padding: 0 7px;
                        cursor: pointer;
                        min-width: max-content;
                    }

                    .selected-step {
                        font-family: ${FONTS.PRIMARY_MEDIUM};
                    }
                }
            }
        }

        .player {
            height: 100%;

            .player-width {
                width: 100%;
                /* border-radius: 8px; */
                overflow: hidden;
                box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.15);

                .video-player-radius {
                    /* border-radius: 24px 8px 24px 8px; */

                    &.full-screen-video {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: black;
                        border-radius: 0;
                    }
                }

                .bunny-video {
                    position: relative;
                    padding-top: 56.25%;
                    /* border-radius: 24px 8px 24px 8px; */
                    overflow: hidden;
                    box-shadow: 0px 9px 18px 0px #00000026;
                }

                .audio-player-content {
                    width: 100%;
                    height: 100%;

                    .audio-image-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        background: ${COLORS.WHITE};
                        box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 20px 0px;
                        position: relative;
                        overflow: hidden;

                        padding-top: 56.25%;

                        &.remove-border {
                            border-radius: 0;
                        }

                        .audio-image {
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            height: calc(100% - 40px);
                            width: 100%;
                            position: absolute;
                            top: 0;

                            &.full-audio-height {
                                height: 100%;
                            }
                        }

                        .subtitle {
                            position: absolute;
                            height: max-content;
                            width: 70%;
                            color: black;
                            bottom: 51px;
                            background-color: transparent;
                            text-align: center;
                            font-size: 24px;
                            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
                        }

                        .audio-text-background {
                            width: 100%;
                            background-color: ${COLORS.PRIMARY};
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            bottom: 0;
                            padding: 0 140px 0;

                            ${responsive.TABLET`
                                padding: 0 140px 0 60px;
                            `}
                        }

                        .middle-action-icon {
                            top: 50%;
                            right: 50%;
                            transform: translate(50%, -50%);
                            background-color: ${COLORS.PRIMARY};
                            position: absolute;
                            border-radius: 50%;
                            padding: 15px;
                            display: flex;
                            visibility: visible;
                            z-index: 20;

                            &.invisible {
                                visibility: hidden;
                            }

                            .player-middle-play-pause-icon {
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }

                    .audio-controls {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        height: 40px;
                        z-index: 10;
                        padding: 0 20px;
                        background-color: ${COLORS.PRIMARY};

                        ${responsive.TABLET`
                            padding: 0 10px;
                        `}

                        ${responsive.PHABLET`
                            padding: 0 5px;
                        `}

                        .left-icons {
                            gap: 10px;
                            z-index: 20;
                            visibility: visible;
                            &.invisible {
                                visibility: hidden;
                            }

                            ${responsive.PHABLET`
                                gap: 5px;
                            `}

                            .play-pause-icon {
                                height: 15px;
                                width: 15px;
                            }

                            .music-forward {
                                transition: 0.5s transform;
                                height: 15px;
                                width: 15px;
                                &:hover {
                                    cursor: pointer;
                                    transform: rotate(45deg);
                                }
                            }
                            .music-backward {
                                transition: 0.5s transform;
                                height: 15px;
                                width: 15px;
                                &:hover {
                                    cursor: pointer;
                                    transform: rotate(-45deg);
                                }
                            }

                            .time-span {
                                color: ${COLORS.WHITE};
                                font-size: 14px;
                                &.separator {
                                    margin: 0 2px;
                                }
                            }
                        }

                        .right-end {
                            margin: 0 0 0 auto;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            gap: 10px;
                            z-index: 20;
                            visibility: visible;

                            &.invisible {
                                visibility: hidden;
                            }

                            ${responsive.PHABLET`
                                gap: 5px;
                            `}
                            .setting-icon {
                                transition: transform 0.5s;
                                height: 15px;
                                width: 15px;

                                &.active {
                                    transform: rotate(90deg);
                                }
                            }
                            .fullscreen-icon {
                                height: 15px;
                                width: 15px;
                            }

                            .sound-control-icon {
                                z-index: 20;
                                position: relative;
                                height: 15px;
                                width: 15px;
                                margin: 0 6px 0 0;
                            }
                            .volume-slider-root {
                                width: 70px;
                                padding: 0;
                                margin: 0 10px 0 0;

                                ${responsive.PHABLET`
                                    width: 40px;
                                `}
                            }
                            .volume-slider-track {
                                background-color: ${COLORS.PRIMARY_DARK};
                                border: none;
                            }
                            .volume-slider-rail {
                                background-color: ${COLORS.WHITE};
                                opacity: 1;
                            }
                            .volume-slider-thumb {
                                background-color: ${COLORS.WHITE};
                                height: 10px;
                                width: 10px;
                            }
                        }
                        .bottom-shadow {
                            background: linear-gradient(
                                360deg,
                                rgba(0, 0, 0, 0) 19.19%,
                                rgba(0, 0, 0, 0.31) 94.87%
                            );
                            position: absolute;
                            transform: rotate(180deg);
                            bottom: 0;
                            left: 0;
                            height: 100px;
                            width: 100%;
                            z-index: 10;
                        }
                        .slider-root {
                            position: absolute;
                            width: 95%;
                            padding: 10px 0;
                            z-index: 20;
                            top: -30%;
                            right: 50%;
                            transform: translate(50%, -50%);
                            visibility: visible;

                            &.invisible {
                                visibility: hidden;
                            }
                        }
                        .slider-track {
                            background-color: ${COLORS.PRIMARY_LIGHT};
                        }
                        .slider-rail {
                            box-shadow: 0px 0px 1.19px 0px #00000040;
                            background-color: ${COLORS.WHITE};
                        }
                        .slider-thumb {
                            background-color: ${COLORS.PRIMARY_LIGHT};
                            height: 16px;
                            width: 16px;
                        }

                        .audio-image-text {
                            font-family: ${FONTS.PRIMARY};
                            font-size: 21px;
                            text-align: center;
                            letter-spacing: 0.01em;
                            text-transform: capitalize;
                            color: ${COLORS.WHITE};
                            max-width: 40%;
                            top: 50%;
                            right: 50%;
                            transform: translate(50%, -50%);
                            position: absolute;
                            z-index: 20;

                            ${responsive.TABLET`
                                max-width: 50%
                            `};

                            ${responsive.PHABLET`
                                max-width: 30%;
                                font-size: 14px;
                            `}
                        }
                    }
                }
            }
        }

        .title {
            font-family: ${FONTS.SECONDARY};
            font-weight: 700;
            font-size: 45px;
            color: ${COLORS.PRIMARY};
            text-transform: capitalize;
            padding: 0 40px 0 0;

            ${responsive.TABLET`
                font-size: 36px;
            `}
        }
    }
`;

export const PopOverWrapper = styled(Popover)`
    .captions-text-root {
        padding: 5px 10px;
        color: ${COLORS.TEXT_DARK};

        &:hover {
            color: ${COLORS.PRIMARY};
        }

        &.selected {
            color: ${COLORS.PRIMARY};
            background-color: ${COLORS.LIGHT_GREY};
        }
    }
`;

export const PlaybackSpeedPopOverWrapper = styled(Popover)`
    .root {
        height: 120px;
        width: 350px;
        background: rgba(242, 242, 242, 0.95);
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        overflow: hidden;
        margin: -10px 0 0 70px;

        ${responsive.PHABLET`
            width: 300px;
            margin: -10px 0 0 0;
        `}
    }

    .playback-box {
        overflow: hidden;
        padding: 8px 16px;

        .adjust-speed-title {
            color: ${COLORS.BLACK};
            font-size: 16px;
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            text-align: center;
            margin: 0 auto;
        }

        .speed-values {
            display: flex;
            justify-content: space-between;
            margin: 8px 0 0;

            .speed-btn {
                background-color: ${COLORS.WHITE};
                box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
                border-radius: 15px;
                width: max-content;
                height: 30px;
                min-width: 50px;
                text-transform: capitalize;
                color: ${COLORS.BLACK};

                &.active-speed {
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    background-color: ${COLORS.PRIMARY_DARK};
                    color: ${COLORS.WHITE};
                }
            }
        }
        .slower,
        .faster {
            text-transform: uppercase;
            color: ${COLORS.TEXT_DARK};
            font-size: 16px;

            &.end {
                margin: 8px 0 0 auto;
            }
        }

        .arrow {
            height: 10px;
            width: 10px;
            border-top: 1px solid ${COLORS.TEXT_DARK};
            border-right: 1px solid ${COLORS.TEXT_DARK};
            transform: rotate(45deg);

            &.previous {
                transform: rotate(225deg);
            }
        }
    }
`;
