import { Typography } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const SeeAllTypographyWrapper = styled(Typography)`
    &.root {
        font-family: ${FONTS.PRIMARY};
        font-size: 18px;
        color: ${COLORS.MEDIUM_GREY};
        cursor: pointer;
        margin: ${(props) => (props?.$spaceAuto ? '0 0 0 auto' : '0 0 0 10px')};
        z-index: 30;
        position: relative;

        ${responsive.TABLET`
            margin: ${(props) => (props?.$spaceAuto ? '0 5px 0 auto' : '0 0 0 10px')};
        `}

        &:hover {
            .indicator {
                transform: translate(5px, 0);
            }
        }

        .indicator {
            margin: 0 0 0 2px;
            transition: all 0.5s;
        }

        .arrow {
            transform: rotate(90deg);
            height: 8px;
            width: 4px;
            font-size: initial;
            position: relative;
            margin: 0 0 0 5px;
            color: ${COLORS.MEDIUM_GREY};
            transition: all 0.5s;

            &.show {
                transform: rotate(270deg);
            }
        }
    }
`;
