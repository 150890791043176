import { Dialog } from '@mui/material';
import styled from 'styled-components';
import { COLORS, responsive } from 'Styles/Constants';

export const SubscriptionModalWrapper = styled(Dialog)`
    .root {
        height: max-content;
        padding: 20px 20px 50px;
        background: #ffffff;
        box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.15);
        border-radius: 24px;
        align-items: center;
        max-width: 600px;
    }

    .cross-icon {
        margin: 0 0 0 auto;
    }

    .text {
        color: ${COLORS.PRIMARY_WARM};
        font-size: 24px;
        text-align: center;
        max-width: 80%;
        ${responsive.PHABLET`
            font-size: 18px;
        `}
    }
`;
