import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ReactComponent as DownArrowIcon } from 'Assets/images/Common/down-arrow.svg';
import { PopOverWrapper, SortFilterWrapper } from './SortFilter.style';

const SortFilter = ({ onClick, sortBy, sortObject, disabled = false, ...props }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <SortFilterWrapper $customSize={props?.customSize || 0}>
            <Box className="filter-sort-container">
                <Box className="filter-container">
                    <Button
                        aria-describedby={id}
                        variant="outlined"
                        disabled={disabled}
                        className="sorting-btn"
                        startIcon={<DownArrowIcon className={`${open && 'down-arrow-icon'}`} />}
                        onClick={handleClick}>
                        {sortBy}
                    </Button>
                    <PopOverWrapper
                        $customSize={props?.customSize || 0}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        classes={{ paper: 'sorting-box' }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}>
                        {Object.values(sortObject).map((item, index) => (
                            <Typography
                                className="sorting-text"
                                key={index}
                                onClick={() => {
                                    handleClose();
                                    onClick(item);
                                }}>
                                {item}
                            </Typography>
                        ))}
                    </PopOverWrapper>
                </Box>
            </Box>
        </SortFilterWrapper>
    );
};

export default SortFilter;
