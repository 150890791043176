import { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';

import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { getWindowDimensions } from 'Helpers';
const ProfileItemManipulation = ({ component, ...props }) => {
    const profileItemManipulation = useRef(null);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    function handleResize() {
        setWindowDimensions(getWindowDimensions());
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            if (props.menuDisplay) {
                profileItemManipulation.current.style.display = 'inline-block';
            } else {
                profileItemManipulation.current.style.display = 'none';
            }
        }
    }, [props.menuDisplay, windowDimensions]);

    return (
        <>
            <Box
                ref={profileItemManipulation}
                className="profile-right-side-details"
                style={{
                    display: `${
                        windowDimensions.width <= BREAKPOINTS_VALUE.TABLET ? 'none' : 'inline-block'
                    }`
                }}>
                
                {windowDimensions.width <= BREAKPOINTS_VALUE.TABLET &&
                    props.profileMenuName !== 'View Profile' && (
                        <Box className="content-wrapper flex f-v-center">
                            <Typography
                                className="content"
                                onClick={() => props.setMenuDisplay((prevState) => !prevState)}>
                                {props.profileMenuName}
                            </Typography>
                        </Box>
                    )}
                {component}
            </Box>
        </>
    );
};

export default ProfileItemManipulation;
