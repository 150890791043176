import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { Box, Button, Typography } from '@mui/material';
import { getWindowDimensions } from 'Helpers';
import {
    URL_HOW_TO_MEDITATE_CONTENT,
    URL_PROGRAMS_DETAILS,
    URL_SEARCH,
    URL_HOME_PAGE
} from 'Helpers/Paths';

import Lottie from 'lottie-react';

// MASCOT
import PointingUpToStanding from 'Assets/AnimationFiles/TourAnimation/pointing-up-to-standing.json';
import PointingDownLeft from 'Assets/AnimationFiles/TourAnimation/points-down-and-left.json';
import PointingUpLeft from 'Assets/AnimationFiles/TourAnimation/points-up-and-left.json';

import StandingDollAnimation from 'Assets/AnimationFiles/Questionnaire/MascotStandingIdleLoop.json';
import PointingLeft from 'Assets/AnimationFiles/TourAnimation/pointing-left.json';

import { ReactComponent as CloseIcon } from 'Assets/images/Common/close-icon.svg';

import { WebsiteTourWrapper } from './WebsiteTour.style';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import DOMPurify from 'dompurify';
import Slider from 'react-slick';
import { useDispatch } from 'react-redux';
import { tourDisable, tourEnable } from 'Redux/App/Actions';
import { INIT_SPOT_ANIMATION } from 'Helpers/Constants';

const CONTENT_DIMENSION = {
    WIDTH: '500px',
    HEIGHT: '230px',
    WIDTH_BY_TWO: '250px',
    HEIGHT_BY_TWO: '100px'
};

const MASCOT_WIDTH = 300;
const MASCOT_TIMEOUT = 500;

const STEPs_INDEX = [
    {
        step: 'INIT',
        content: 'Learn what makes <b>Master Sha</b> meditations unique. '
    },
    {
        step: 'WATCH',
        content: '<b>Watch</b> animated videos to get familiar with the techniques and foundations.'
    },
    { step: 'READ', content: 'Or, <b>Read</b> the guides in article form.' },

    // OTHERS
    { step: 'OTHER', content: 'Ready to dive in?' },
    { step: 'OTHER', content: 'What would you like to check out next, <First Name>?' }
];

// 6 STEPS
const LOTTIE_ANIMATION = [
    StandingDollAnimation,
    PointingLeft,
    PointingDownLeft,
    StandingDollAnimation,
    StandingDollAnimation
    // StandingDollAnimation,
    // StandingDollAnimation,
    // StandingDollAnimation,
    // StandingDollAnimation
];

const IDLE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
    // StandingDollAnimation,
    // StandingDollAnimation
];

const TABLET_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    PointingDownLeft,
    PointingDownLeft,
    StandingDollAnimation,
    StandingDollAnimation
    // StandingDollAnimation,
    // StandingDollAnimation
];

const TABLET_IDLE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
    // StandingDollAnimation,
    // StandingDollAnimation,
    // StandingDollAnimation,
    // StandingDollAnimation
];

const MOBILE_LOTTIE_ANIMATION = [
    PointingUpToStanding,
    PointingUpLeft,
    PointingUpLeft,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const MOBILE_IDLE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    touchMove: false
};

const ListButtons = forwardRef(
    (
        {
            backButtonTitle = '',
            nextButtonTitle = '',
            currentStep,
            setCurrentStep,
            updateButtons,
            isFinalStep = true,
            contentId,
            programId,
            ...props
        },
        ref
    ) => {
        const navigate = useNavigate();
        return (
            <Box className="button-parent-wrapper flex f-column gap">
                <Button
                    onClick={() => {
                        if (isFinalStep) {
                            return navigate(URL_HOME_PAGE);
                        }
                    }}
                    className={`btn primary full-width`}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize('Go Home')
                        }}
                        className="font"
                    />
                </Button>
                <Button
                    onClick={() => {
                        if (isFinalStep && contentId) {
                            return navigate(URL_HOW_TO_MEDITATE_CONTENT, {
                                state: { contentId, programId }
                            });
                        }
                    }}
                    className={`btn primary full-width`}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize('Experience a Meditation')
                        }}
                        className="font"
                    />
                </Button>
                {programId !== -1 && (
                    <Button
                        onClick={() => {
                            if (isFinalStep) {
                                return navigate(URL_PROGRAMS_DETAILS, {
                                    state: { contentId, programId, isRedirectedFromHistory: false }
                                });
                            }
                        }}
                        className={`btn primary full-width`}>
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize('Join a Program')
                            }}
                            className="font"
                        />
                    </Button>
                )}
                <Button
                    onClick={() => {
                        if (isFinalStep) {
                            return navigate(URL_SEARCH, {
                                state: { programId, contentId }
                            });
                        }
                    }}
                    className={`btn primary full-width`}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize('Search for More')
                        }}
                        className="font"
                    />
                </Button>
            </Box>
        );
    }
);

const FooterButtons = forwardRef(
    (
        {
            backButtonTitle = '',
            nextButtonTitle = '',
            currentStep,
            setCurrentStep,
            updateButtons,
            isFinalStep = false,
            ...props
        },
        ref
    ) => {
        return (
            <Box className="button-parent-wrapper">
                <Button
                    onClick={() => {
                        if (currentStep + 1 === STEPs_INDEX?.length - 1) {
                            updateButtons();
                            ref.current.slickNext();
                            setCurrentStep(currentStep + 1);
                            return;
                        }
                        ref.current.slickPrev();
                        setCurrentStep(currentStep - 1);
                    }}
                    className={`btn back ${!currentStep && 'hide'}`}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(backButtonTitle)
                        }}
                        className={`font ${isFinalStep && 'text-transform'}`}
                    />
                </Button>
                <Button
                    onClick={() => {
                        if (isFinalStep) return props?.closeTutorial();
                        if (currentStep + 1 === STEPs_INDEX?.length - 2) {
                            updateButtons();
                            ref.current.slickNext();
                            setCurrentStep(currentStep + 1);
                            return;
                        }
                        ref.current.slickNext();
                        setCurrentStep(currentStep + 1);
                    }}
                    className={`btn font primary`}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(nextButtonTitle)
                        }}
                        className={`font ${isFinalStep && 'text-transform'}`}
                    />
                </Button>
            </Box>
        );
    }
);

const WebsiteTour = ({
    isLoading,
    contentId,
    programId,
    handleMascotTour,
    userDetails,
    scrollToTop,
    ...props
}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isCompletedAnimation, setIsCompletedAnimation] = useState(false);
    const [loop, setLoop] = useState(false);
    const animationRef = useRef(null);
    const slickRef = useRef(null);
    const buttonRef = useRef(null);
    const [mascotPosition, setMascotPosition] = useState({
        transform: undefined
    });
    const dispatch = useDispatch();

    const [tourStaringId, setStartingTourId] = useState({
        width: undefined,
        height: undefined,
        transform: undefined
    });
    const [contentDimension, setContentDimension] = useState({
        width: CONTENT_DIMENSION.WIDTH,
        height: CONTENT_DIMENSION.HEIGHT,
        halfWidth: CONTENT_DIMENSION.WIDTH_BY_TWO,
        halfHeight: CONTENT_DIMENSION.HEIGHT_BY_TWO
    });
    const [polygonDimension, setPolygonDimension] = useState('');

    const [boxPosition, setBoxPosition] = useState('');
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [initAnimationSpot, setInitAnimationSpot] = useState('');

    const scroll = (id, position) => {
        const section = document.getElementById(id);
        section.scrollIntoView({ behavior: 'smooth', block: position });
    };

    const handleCloseMascotTour = () => {
        handleMascotTour();
        props?.setTourEnable(false);
    };

    const setChipDimensions = useCallback(
        (animationDimension, id) => {
            let element = document?.getElementById?.(id)?.getBoundingClientRect?.();

            if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
                setStartingTourId({
                    transform: `translate(calc(${element.left}px - 10px), calc(${element.top}px - ${element.height}px))`,
                    width: element.width + 20,
                    height: element.width + 20
                });
                return;
            }

            if (windowDimensions.width >= BREAKPOINTS_VALUE.TABLET) {
                setStartingTourId({
                    transform: `translate(calc(${element.left}px - 10px), calc(${element.top}px - ${element.height}px))`,
                    width: element.width / 5,
                    height: element.width / 5
                });
                return;
            }
        },
        [windowDimensions.width]
    );

    const setMascotDimensions = useCallback(
        (animationDimension, id) => {
            const element = document.getElementById(id);
            const elementRect = element.getBoundingClientRect();

            if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                let initTransform = `translate(calc(${windowDimensions.width}px - 175px), calc(${windowDimensions.height}px - 190px))`;

                setStartingTourId({
                    transform: initTransform,
                    width: animationDimension.width - 50,
                    height: animationDimension.width - 50
                });

                setMascotPosition({
                    transform: `translate(calc(${windowDimensions.width}px - 200px), calc(${windowDimensions.height}px - 210px))`
                });

                const initBox =
                    currentStep === 4
                        ? `translate(20px, calc(${windowDimensions.height}px - ${
                              (MASCOT_WIDTH - 100) * 2
                          }px - 200px))`
                        : `translate(20px, calc(${windowDimensions.height}px - ${
                              MASCOT_WIDTH - 100
                          }px - 200px))`;

                setBoxPosition(initBox);

                if (currentStep === 3) {
                    setPolygonDimension(
                        `translate(${windowDimensions.width - 150}px, 160px) rotate(45deg)`
                    );
                }
                if (currentStep === 4) {
                    setPolygonDimension(
                        `translate(${windowDimensions.width - 150}px, 360px) rotate(45deg)`
                    );
                }
                return;
            }

            if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
                let initTransform = `translate(calc(${
                    windowDimensions.width
                }px - ${MASCOT_WIDTH}px - 100px), calc(${windowDimensions.height / 6}px - ${
                    (MASCOT_WIDTH - 100) / 2
                }px))`;

                setStartingTourId({
                    transform: initTransform,
                    width: animationDimension.width - 100,
                    height: animationDimension.width - 100
                });

                setBoxPosition(
                    `translate(calc(${windowDimensions.width}px - ${contentDimension.width} - ${
                        MASCOT_WIDTH / 2
                    }px), calc(${windowDimensions.height / 6}px + ${(MASCOT_WIDTH - 100) / 2}px))`
                );
                return;
            }

            if (animationDimension && windowDimensions.width >= BREAKPOINTS_VALUE.TABLET) {
                let initTransform = `translate(calc(${
                    windowDimensions.width / 2
                }px + 100px), calc(${windowDimensions.height / 2}px - ${MASCOT_WIDTH - 100}px))`;

                setStartingTourId({
                    transform: initTransform,
                    width: animationDimension.width - 90,
                    height: animationDimension.width - 90
                });

                if (currentStep === 4) {
                    const initBox = `translate(calc(${elementRect.left}px - 470px), calc(${elementRect.top}px + 40px + 10px))`;
                    setBoxPosition(initBox);
                    setPolygonDimension(`translate(460px, 90px) rotate(45deg)`);
                }

                return;
            }
        },
        [windowDimensions.width, windowDimensions.height, currentStep, contentDimension.width]
    );

    const chipContent = useCallback(
        (id) => {
            // if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
            //     scroll(id, 'start');
            // }

            let animationDimension = document?.getElementById?.(id)?.getBoundingClientRect?.();

            if (animationDimension) {
                setTimeout(() => setChipDimensions(animationDimension, id), MASCOT_TIMEOUT);
            }
        },
        [setChipDimensions]
    );

    const mascotContent = useCallback(
        (id) => {
            let animationDimension = document?.getElementById?.(id)?.getBoundingClientRect?.();

            if (animationDimension) {
                setTimeout(() => setMascotDimensions(animationDimension, id), MASCOT_TIMEOUT);
            }
        },
        [setMascotDimensions]
    );

    const initAnimation = useCallback(() => {
        let animationDimension = document?.getElementById?.('mascot-id')?.getBoundingClientRect?.();
        if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
            let initTransform = `translate(calc(${windowDimensions.width}px - 175px), calc(${windowDimensions.height}px - ${contentDimension.height} - ${animationDimension?.height}px))`;

            let animationStartingSpot = (INIT_SPOT_ANIMATION - animationDimension?.width - 50) / 2;

            setInitAnimationSpot(
                `translate(calc(${windowDimensions.width}px - 175px - ${animationStartingSpot}px), calc(${windowDimensions.height}px - ${contentDimension.height} - ${animationDimension?.height}px - ${animationStartingSpot}px))`
            );
            setStartingTourId({
                transform: initTransform,
                width: animationDimension?.width - 50,
                height: animationDimension?.width - 50
            });
            setMascotPosition({
                transform: `translate(calc(${windowDimensions.width}px - 200px), calc(${windowDimensions.height}px -  ${contentDimension.height} - ${animationDimension?.height}px - 25px))`
            });
            setBoxPosition(
                `translate(20px, calc(${windowDimensions.height}px - ${contentDimension.height} - 25px))`
            );
            setPolygonDimension(
                `translate(${windowDimensions.width - 150}px, -35px) rotate(45deg)`
            );
            return;
        }

        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            let initTransform = `translate(calc(${
                windowDimensions.width
            }px - ${MASCOT_WIDTH}px - 100px), calc(${windowDimensions.height / 6}px - ${
                (MASCOT_WIDTH - 100) / 2
            }px))`;

            let animationStartingSpot = (INIT_SPOT_ANIMATION - MASCOT_WIDTH - 100) / 2;

            setInitAnimationSpot(
                `translate(calc(${
                    windowDimensions.width
                }px - ${MASCOT_WIDTH}px - 100px - ${animationStartingSpot}px), calc(${
                    windowDimensions.height / 6
                }px - ${(MASCOT_WIDTH - 100) / 2}px - ${animationStartingSpot}px))`
            );

            setStartingTourId({
                transform: initTransform,
                width: MASCOT_WIDTH - 100,
                height: MASCOT_WIDTH - 100
            });

            setBoxPosition(
                `translate(calc(${windowDimensions.width}px - ${contentDimension.width} - ${
                    MASCOT_WIDTH / 2
                }px), calc(${windowDimensions.height / 6}px + ${(MASCOT_WIDTH - 100) / 2}px))`
            );

            setPolygonDimension(`translate(130px, calc(-35px)) rotate(45deg)`);

            setMascotPosition({
                transform: `translate(calc(${
                    windowDimensions.width
                }px - ${MASCOT_WIDTH}px - 150px), calc(${windowDimensions.height / 6}px - ${
                    MASCOT_WIDTH / 2
                }px))`
            });
            return;
        }

        if (animationDimension && windowDimensions.width >= BREAKPOINTS_VALUE.TABLET) {
            let initTransform = `translate(calc(${windowDimensions.width / 2}px + 100px), calc(${
                windowDimensions.height / 2
            }px - ${MASCOT_WIDTH - 100}px))`;
            let animationStartingSpot = (INIT_SPOT_ANIMATION - MASCOT_WIDTH - 100) / 2;

            setInitAnimationSpot(
                `translate(calc(${
                    windowDimensions.width / 2
                }px + 100px - ${animationStartingSpot}px), calc(${
                    windowDimensions.height / 2
                }px - ${MASCOT_WIDTH - 100}px - ${animationStartingSpot}px))`
            );
            setStartingTourId({
                transform: initTransform,
                width: MASCOT_WIDTH - 100,
                height: MASCOT_WIDTH - 100
            });

            setBoxPosition(
                `translate(calc(${windowDimensions.width / 2}px - 100px), calc(${
                    windowDimensions.height / 2
                }px - ${MASCOT_WIDTH - 100}px + 200px))`
            );

            setPolygonDimension(
                `translate(calc(${contentDimension.width} - ${MASCOT_WIDTH}px + 50px), calc(-35px)) rotate(45deg)`
            );

            setMascotPosition({
                transform: `translate(calc(${windowDimensions.width / 2}px + 50px), calc(${
                    windowDimensions.height / 2
                }px - ${MASCOT_WIDTH}px + 50px))`
            });
            return;
        }
    }, [
        windowDimensions.width,
        contentDimension.width,
        windowDimensions.height,
        contentDimension?.height
    ]);

    const replayAnimation = () => {
        animationRef.current.stop();
        animationRef.current.play();
    };

    const updateAnimationStatus = () => {
        setIsCompletedAnimation(false);
        setLoop(false);
    };

    const jumpToStep = useCallback(() => {
        switch (STEPs_INDEX?.[currentStep]?.step) {
            case 'INIT':
                updateAnimationStatus();
                initAnimation();
                break;
            case 'WATCH':
                updateAnimationStatus();
                replayAnimation();
                chipContent('watch-id');
                break;
            case 'READ':
                updateAnimationStatus();
                replayAnimation();
                chipContent('read-id');
                break;
            case 'OTHER':
                updateAnimationStatus();
                mascotContent('mascot-id');
                break;
            default:
                updateAnimationStatus();
                initAnimation();
                return;
        }
    }, [initAnimation, currentStep, chipContent, mascotContent]);

    const updateButtons = () => {
        buttonRef.current.slickNext();
    };

    const boxClass = useCallback(
        (currentStep) => {
            if (currentStep === 4) {
                if (windowDimensions.width >= BREAKPOINTS_VALUE.TABLET) {
                    return 'display';
                }

                if (
                    windowDimensions.width >= BREAKPOINTS_VALUE.PHABLET &&
                    windowDimensions.width <= BREAKPOINTS_VALUE.TABLET
                ) {
                    return 'tablet';
                }

                if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                    return 'phablet';
                }
            }
            return '';
        },
        [windowDimensions.width]
    );

    const mascotAnimation = useCallback(
        (currentStep, isCompletedAnimation) => {
            if (windowDimensions.width >= BREAKPOINTS_VALUE.TABLET) {
                if (isCompletedAnimation) {
                    return IDLE_LOTTIE_ANIMATION[currentStep];
                }
                return LOTTIE_ANIMATION[currentStep];
            }

            if (
                windowDimensions.width >= BREAKPOINTS_VALUE.PHABLET &&
                windowDimensions.width <= BREAKPOINTS_VALUE.TABLET
            ) {
                if (isCompletedAnimation) {
                    return TABLET_IDLE_LOTTIE_ANIMATION[currentStep];
                }
                return TABLET_LOTTIE_ANIMATION[currentStep];
            }

            if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                if (isCompletedAnimation) {
                    return MOBILE_IDLE_LOTTIE_ANIMATION[currentStep];
                }
                return MOBILE_LOTTIE_ANIMATION[currentStep];
            }
        },
        [windowDimensions.width]
    );

    const startFormInit = () => {
        slickRef?.current?.slickGoTo(0);
        buttonRef?.current?.slickPrev();
        setCurrentStep(0);
    };

    useEffect(() => {
        jumpToStep();
        if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
            setContentDimension((prev) => ({
                ...prev,
                width: `${windowDimensions.width - 40}px`,
                height: '200px'
            }));
            return;
        }
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            setContentDimension((prev) => ({ ...prev, width: '300px', height: '250px' }));
            return;
        }
    }, [windowDimensions?.height, windowDimensions?.width, isLoading, jumpToStep]);

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };
        scrollToTop();
        dispatch(tourEnable());
        window.addEventListener('resize', handleResize);
        return () => {
            dispatch(tourDisable());
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch, scrollToTop]);

    return (
        <React.Fragment>
            {!isLoading &&
                createPortal(
                    <WebsiteTourWrapper $initAnimationSpot={initAnimationSpot}>
                        <Box className="tour">
                            {tourStaringId?.transform && (
                                <Box
                                    className="tour-starting-box"
                                    style={{
                                        transform: tourStaringId?.transform,
                                        width: tourStaringId?.width,
                                        height: tourStaringId?.width
                                    }}
                                />
                            )}
                        </Box>
                        <Box className="content-container">
                            <Lottie
                                className="mascot"
                                id="mascot-id"
                                animationData={mascotAnimation(currentStep, isCompletedAnimation)}
                                style={{
                                    transform: mascotPosition?.transform
                                }}
                                onComplete={() => {
                                    setIsCompletedAnimation(true);
                                    setLoop(true);
                                }}
                                lottieRef={animationRef}
                                loop={loop}
                            />
                            {boxPosition && (
                                <Box
                                    className="tour-content"
                                    style={{
                                        transform: boxPosition,
                                        width: contentDimension?.width,
                                        height: currentStep === 4 ? 400 : contentDimension?.height
                                    }}>
                                    <Box
                                        className="polygon"
                                        style={{ transform: polygonDimension }}
                                    />
                                    <Box
                                        className="close-icon-btn hover"
                                        onClick={() => handleCloseMascotTour()}>
                                        <CloseIcon className="close-svg" />
                                    </Box>
                                    <Box className="content">
                                        <Slider {...settings} ref={slickRef}>
                                            {STEPs_INDEX.map((item, index) => (
                                                <Box
                                                    className="text"
                                                    key={index}
                                                    dangerouslySetInnerHTML={{
                                                        __html: item?.content?.includes(
                                                            '<First Name>'
                                                        )
                                                            ? DOMPurify.sanitize(
                                                                  item?.content?.replace(
                                                                      '<First Name>',
                                                                      userDetails?.firstName
                                                                  )
                                                              )
                                                            : DOMPurify.sanitize(item?.content)
                                                    }}
                                                />
                                            ))}
                                        </Slider>

                                        <Box className={`footer-buttons ${boxClass(currentStep)}`}>
                                            <Slider {...settings} ref={buttonRef}>
                                                <FooterButtons
                                                    ref={slickRef}
                                                    currentStep={currentStep}
                                                    setCurrentStep={setCurrentStep}
                                                    backButtonTitle="back"
                                                    nextButtonTitle="next"
                                                    updateButtons={updateButtons}
                                                />
                                                <FooterButtons
                                                    ref={slickRef}
                                                    currentStep={currentStep}
                                                    setCurrentStep={setCurrentStep}
                                                    backButtonTitle="NO <br/> Continue Tutorial"
                                                    nextButtonTitle="YES <br/> Close Tutorial"
                                                    updateButtons={updateButtons}
                                                    startFormInit={startFormInit}
                                                    closeTutorial={() => handleCloseMascotTour()}
                                                    isFinalStep={true}
                                                />
                                                <ListButtons
                                                    ref={slickRef}
                                                    currentStep={currentStep}
                                                    setCurrentStep={setCurrentStep}
                                                    backButtonTitle="back"
                                                    nextButtonTitle="next"
                                                    updateButtons={updateButtons}
                                                    contentId={contentId}
                                                    programId={programId}
                                                    isFinalStep={true}
                                                />
                                            </Slider>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </WebsiteTourWrapper>,
                    document.body
                )}
        </React.Fragment>
    );
};

export default WebsiteTour;
