import { Box } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import styled from 'styled-components';
import TickIcon from 'Assets/images/Profile/white-tick-icon.svg';

export const ChoosePaymentWrapper = styled(Box)`
    .title {
        font-size: 40px;
        font-family: ${FONTS.SECONDARY_BOLD};
        color: ${COLORS.PRIMARY_DARK};

        &.order-failed {
            color: ${COLORS.BADGE};
        }

        ${responsive.TABLET`
            font-size: 38px;
        `}
        ${responsive.PHABLET`
            font-size: 32px;
        `}
        ${responsive.MOBILE`
            font-size: 28px;
        `}
    }

    .container {
        margin: 20px 0 0;
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 16px 0px #00000026;
        background-color: ${COLORS.WHITE};
        border-radius: 30px 30px 0 0;
        padding: 20px 0 20px 20px;

        ${responsive.PHABLET`
            box-shadow: none;
            padding: 20px 0 20px 10px;
            margin: 0;
        `}

        .scrollable-area {
            height: calc(100% - 130px);
            padding: 0 0 30px 0;
            overflow: auto;
            ::-webkit-scrollbar {
                display: none;
            }

            .order-failed-info {
                font-size: 20px;
                .danger {
                    color: ${COLORS.BADGE};
                }
            }

            .sub-title {
                font-size: 24px;
                color: ${COLORS.MEDIUM_GREY};
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};

                ${responsive.PHABLET`
                    font-size: 22px;
                `}

                &.decrease-font-size {
                    font-size: 18px;
                    padding: 0 20px 0 0;
                    margin: 20px 0;

                    ${responsive.PHABLET`
                        font-size: 16px;
                    `}
                }
            }

            .list-item {
                padding: 10px 20px 10px 0;
                gap: 20px;

                ${responsive.PHABLET`
                    gap: 10px;
                `}

                .image {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 60px;
                    width: 60px;
                    position: relative;
                    border-radius: 6px;
                    overflow: hidden;
                    min-width: 60px;

                    .duration {
                        background-color: ${COLORS.PRIMARY_LIGHT};
                        font-size: 8px;
                        font-family: ${FONTS.PRIMARY_MEDIUM};
                        color: ${COLORS.BLACK};
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        text-transform: uppercase;
                        text-align: center;
                        padding: 0 5px;
                    }
                }

                .item-right-container {
                    width: calc(100% - 80px);

                    .item-title {
                        font-size: 18px;
                        color: ${COLORS.BLACK};
                        font-family: ${FONTS.PRIMARY_MEDIUM};
                        width: 85%;

                        ${responsive.PHABLET`
                            font-size: 16px;
                        `}
                    }

                    .price {
                        font-size: 18px;
                        color: ${COLORS.MEDIUM_GREY};
                        margin: 0 0 0 auto;
                    }

                    .delivery-tag {
                        background: #e4f5fb;
                        padding: 0 10px;
                        border-radius: 22px;
                        width: max-content;
                        font-size: 12px;
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                        color: ${COLORS.BLACK};
                        text-transform: uppercase;
                    }
                }
            }

            .divider-border {
                border: 1px solid ${COLORS.MEDIUM_GREY};
                opacity: 40%;

                &.last-child {
                    opacity: 100%;
                }

                &.mt-20 {
                    margin: 20px 0 0;
                }
            }

            .order-tax-and-shipping-value {
                font-size: 18px;
                padding: 0 20px 0 0;
                color: ${COLORS.MEDIUM_GREY};
                margin: 5px 0;

                &.key {
                    min-width: max-content;
                }

                &.value {
                    color: ${COLORS.TEXT_DARK};
                    font-family: ${FONTS.PRIMARY_MEDIUM};
                }
            }
            .change-shipping-address {
                padding: 0 20px 0 0;
                font-size: 18px;
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                color: ${COLORS.PRIMARY_DARK};
                text-decoration: underline;
            }

            .total-order {
                padding: 0 20px 0 0;
                font-size: 24px;
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                color: ${COLORS.PRIMARY_DARK};
                margin: 20px 0;

                ${responsive.PHABLET`
                    font-size: 20px;
                `}

                &.key {
                    font-size: 18px;

                    ${responsive.PHABLET`
                        font-size: 16px;
                    `}
                }
                .or {
                    font-size: 20px;
                    color: ${COLORS.MEDIUM_GREY};
                    font-family: ${FONTS.PRIMARY};

                    ${responsive.PHABLET`
                        font-size: 18px;
                    `}
                }
            }

            .payment-methods {
                background-color: ${COLORS.LIGHT_GREY_SECONDARY};
                padding: 20px;
                border-radius: 16px;
                margin: 0 20px 0 0;

                ${responsive.PHABLET`
                    padding: 10px;
                    margin: 0 10px 0 0;
                `}

                .payment-method-title {
                    color: ${COLORS.MEDIUM_GREY};
                    font-size: 24px;
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};

                    ${responsive.PHABLET`
                        font-size: 20px;
                    `}
                }

                .method-item {
                    margin: 20px 0;
                    gap: 10px;

                    ${responsive.PHABLET`
                        margin: 10px 0;
                    `}

                    .radio-root {
                        padding: 0;
                        width: max-content;

                        .radio {
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                            border: 2px solid ${COLORS.PRIMARY};

                            ${responsive.PHABLET`
                                width: 20px;
                                height: 20px;
                            `}

                            &.active {
                                border: none;
                            }

                            .selected {
                                width: 100%;
                                height: 100%;
                                background: radial-gradient(
                                    140% 140% at 15% -10%,
                                    #9c7f3c 20.49%,
                                    #caa44d 84.44%
                                );

                                border-radius: 50%;
                                padding: 2px;

                                .icon {
                                    height: 80%;
                                    width: 80%;
                                    background-image: url(${TickIcon});
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                }
                            }
                        }
                    }
                    .reward-points {
                        font-size: 18px;
                        color: ${COLORS.BLACK};

                        ${responsive.PHABLET`
                            font-size: 16px;
                        `}
                    }
                    .available-points {
                        background-color: #ffd7d7;
                        color: ${COLORS.BLACK};
                        font-size: 18px;
                        border-radius: 12px;
                        padding: 5px 10px;
                        font-family: ${FONTS.PRIMARY_BOLD};

                        ${responsive.PHABLET`
                            font-size: 16px;
                        `}
                        &.enough-points {
                            background-color: #d4fac7;
                        }
                        .text {
                            font-family: ${FONTS.PRIMARY};
                        }
                    }

                    .cash {
                        font-size: 18px;
                        color: ${COLORS.BLACK};
                    }
                }
            }
        }

        .payment-declined {
            color: ${COLORS.BADGE};
            font-size: 20px;
            margin: 20px 0;
        }
        .place-order-button {
            width: calc(100% - 20px);
            background-color: ${COLORS.PRIMARY};
            height: 60px;
            color: ${COLORS.WHITE};
            border-radius: 12px;
            font-size: 16px;
            font-family: ${FONTS.PRIMARY_BOLD};
            box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 16px 0px;
        }

        .arrow {
            height: 10px;
            width: 10px;
            border-top: 2px solid ${COLORS.WHITE};
            border-right: 2px solid ${COLORS.WHITE};
            transform: rotate(45deg);
        }
    }
`;
