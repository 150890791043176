import { Box, Typography } from '@mui/material';
import Api from 'Helpers/ApiHandler';
import { TAO_CARD_TYPE } from 'Helpers/Constants';
import { API_URL, URL_HOME_PAGE, URL_PROGRAMS_DETAILS } from 'Helpers/Paths';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { showSubscriptionModal } from 'Redux/App/Actions';
import { TaoButton } from '../Button.style';
import TaoCard from '../TaoCard';
import { ProgramSuggestionWrapper } from './ProgramSuggestion.style';
import { handleContentRedirection } from 'Helpers';
import TaoCardLoader from 'Components/common/TaoCard/TaoCardLoader';

const ProgramSuggestion = ({ ...props }) => {
    const [suggestionPrograms, setSuggestedPrograms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const API = useMemo(() => new Api(), []);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fetchSuggestionPrograms = useCallback(async () => {
        setIsLoading(true);
        let response = await API.get(API_URL.PROGRAM_LINKED_PROGRAMS, {
            params: {
                programId: location?.state?.contentId,
                isRedirectedFromHistory: location?.state?.isRedirectedFromHistory
            }
        });
        if (response) {
            if (!response?.data?.data?.extraLinkedProgramList?.length)
                return navigate(URL_PROGRAMS_DETAILS, {
                    state: {
                        programId: location?.state?.contentId,
                        isRedirectedFromHistory: location?.state?.isRedirectedFromHistory
                    }
                });
            setSuggestedPrograms(response?.data?.data?.extraLinkedProgramList);
            setIsLoading(false);
        }
    }, [API, location?.state?.contentId, location?.state?.isRedirectedFromHistory, navigate]);

    const onFavProgram = async (id, index) => {
        const newSuggestionPrograms = [...suggestionPrograms];
        newSuggestionPrograms[index].isFavorite = !newSuggestionPrograms[index].isFavorite;

        setSuggestedPrograms(newSuggestionPrograms);

        await API.post(API_URL.PROGRAM_FAVORITE, {
            params: {
                programId: id
            }
        });
    };

    const navigateTo = (url, value, isAccessible) => {
        if (!url) return;

        if (!isAccessible) {
            dispatch(showSubscriptionModal());
            return;
        }

        navigate(url, {
            state: value
        });
    };

    useEffect(() => {
        fetchSuggestionPrograms();
    }, [fetchSuggestionPrograms]);

    return (
        <ProgramSuggestionWrapper>
            <Typography className="program-category like">Programs you might like</Typography>

            <Box className="suggested-programs">
                {isLoading &&
                    [1, 2, 3, 4, 5, 6]?.map((value, index) => <TaoCardLoader key={index} />)}
                {!isLoading &&
                    [...suggestionPrograms].map((data, index) => (
                        <Box key={index}>
                            <TaoCard
                                supportedResponsiveView
                                backgroundImage={data.thumbnailImage}
                                type={TAO_CARD_TYPE.PLAYLIST}
                                isFav={data?.isFavorite}
                                favoriteIconVisible={true}
                                onFavClick={() => onFavProgram(data?.id, index)}
                                isAccessible={
                                    data?.subscription ? data?.subscription?.isAccessible : true
                                }
                                isPaidContent={data?.isPaidContent}
                                isPurchased={data?.isPurchased}
                                onClick={() => {
                                    handleContentRedirection(data, navigateTo);
                                }}
                            />
                        </Box>
                    ))}
            </Box>

            {!suggestionPrograms?.length && !isLoading && (
                <Typography classes={{ root: 'no-data-text' }}>No Programs Found</Typography>
            )}

            <Box className="button">
                <TaoButton onClick={() => navigate(URL_HOME_PAGE)}>Return Home</TaoButton>
            </Box>
        </ProgramSuggestionWrapper>
    );
};

export default ProgramSuggestion;
