import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, Typography, Box, Button, Chip, Slide } from '@mui/material';
import CrossIcon from 'Assets/images/PlayList/cross-icon.svg';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import TaoScrollbar from 'Components/common/Scrollbar';
import { TopicWrapper } from './Topic.style';
import { PlayListMediaImg } from '../../Playlist.style';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';

const TopicSelection = ({
    setPage,
    page,
    selectedCategoryTag,
    selectedTopicTag,
    setSelectedTopicTag,
    selectedTopicId,
    setSelectedTopicId,
    ...props
}) => {
    const onClose = () => {
        setPage(null);
    };
    const API = useMemo(() => new Api(), []);

    const [isLoading, setsIsLoading] = useState(false);

    const [selectedTopic, setSelectedTopic] = useState([...selectedTopicId]);
    const [allCategoryTag, setAllCategoryTag] = useState([]);
    const [subCategoryTagLength, setSubCategoryTagLength] = useState(0);
    const [dataToDisplay, setDataToDisplay] = useState([]);

    const selectTopic = (topicId) => {
        const newSelectedTopic = [...selectedTopic];
        if (!newSelectedTopic.includes(topicId)) newSelectedTopic.push(topicId);
        else {
            let findIndex = newSelectedTopic.findIndex((item) => item === topicId);
            findIndex > -1 && newSelectedTopic.splice(findIndex, 1);
        }
        setSelectedTopic(newSelectedTopic);
    };

    const onDone = () => {
        setsIsLoading(true);
        let selectedTopics = [...selectedTopic];
        let selectedTopicData = [];
        let selectedTopicIds = [];
        let copy = [...allCategoryTag];

        copy.forEach((category) => {
            selectedTopics.forEach((topicId) => {
                let foundedData = category?.subCategoryDetails?.find((value) => {
                    return value?.id === topicId;
                });
                if (foundedData) {
                    selectedTopicData.push(foundedData);
                    selectedTopicIds.push(foundedData?.id);
                }
            });
        });
        setSelectedTopicTag(selectedTopicData);
        setSelectedTopicId(selectedTopicIds);
        setsIsLoading(false);
    };

    const getCategoryList = useCallback(async () => {
        try {
            setsIsLoading(true);
            let response = await API.get(API_URL.GET_CATEGORY);

            if (response) {
                let mergedSubCategories = [];
                setAllCategoryTag(response?.data?.data?.list);

                response?.data?.data?.list?.forEach((item) => {
                    mergedSubCategories = mergedSubCategories.concat([...item?.subCategoryDetails]);
                });
                mergedSubCategories.sort(
                    (categoryFirst, categorySecond) =>
                        categorySecond.sortOrder - categoryFirst.sortOrder
                );
                setDataToDisplay(mergedSubCategories);
                setsIsLoading(false);
            }
        } catch (error) {
            setsIsLoading(false);
        }
    }, [API]);

    useEffect(() => {
        if (!selectedCategoryTag?.length) {
            getCategoryList();
        } else {
            setAllCategoryTag(selectedCategoryTag);
            let mergedSubCategories = [];

            selectedCategoryTag?.forEach((item) => {
                mergedSubCategories = mergedSubCategories.concat([...item?.subCategoryDetails]);
            });
            mergedSubCategories.sort(
                (categoryFirst, categorySecond) =>
                    categorySecond.sortOrder - categoryFirst.sortOrder
            );
            setDataToDisplay(mergedSubCategories);
        }
    }, [getCategoryList, selectedCategoryTag]);

    useEffect(() => {
        allCategoryTag.forEach((tag) =>
            setSubCategoryTagLength((prev) => prev + tag?.subCategoryDetails?.length)
        );
    }, [allCategoryTag]);

    return (
        <>
            <TaoLoader isLoading={isLoading} />
            <Slide in={Boolean(page)} direction="up" timeout={{ enter: 500, exit: 300 }}>
                <TopicWrapper>
                    <Box className="top-bar">
                        <Typography className="heading">Topics</Typography>
                        <IconButton className="cross-button" onClick={onClose}>
                            <img className="cross-icon" src={CrossIcon} alt="" />
                        </IconButton>
                    </Box>
                    <Box className="selected-category-section">
                        <Typography className="heading">Category:</Typography>
                        <Box className="chip-container">
                            {!!selectedCategoryTag?.length &&
                                selectedCategoryTag?.map((value, index) => {
                                    return (
                                        <Chip
                                            key={index}
                                            className="chip"
                                            label={value?.categoryName}
                                        />
                                    );
                                })}
                            {!selectedCategoryTag.length && (
                                <Chip
                                    className="chip"
                                    classes={{ label: 'chip-label' }}
                                    label={'ALL'}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box className="topic-count-container">
                        <Typography className="count">
                            {subCategoryTagLength} Topics Available
                        </Typography>
                        <Typography className="text">choose one or more</Typography>
                    </Box>
                    <TaoScrollbar displayScroll>
                        <Box className="topic-selection-container">
                            {!!dataToDisplay?.length &&
                                dataToDisplay.map((element, secondIndex) => {
                                    return (
                                        <Box key={secondIndex} className="categories">
                                            <IconButton
                                                value="topic-name"
                                                className="icon-button"
                                                onClick={() => {
                                                    selectTopic(element?.id);
                                                }}>
                                                <PlayListMediaImg
                                                    img={
                                                        selectedTopic.includes(element?.id)
                                                            ? element.selectedIcon
                                                            : element.icon
                                                    }
                                                    height={'80px'}
                                                    width={'80px'}
                                                    radius={'none'}
                                                />
                                            </IconButton>
                                            <Typography className="text">
                                                {element?.name}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                        </Box>
                    </TaoScrollbar>
                    <Box className="topic-button-container">
                        <Button
                            className="submit-button"
                            onClick={() => {
                                onDone();
                                onClose();
                            }}>
                            <Typography className="text">Done</Typography>
                        </Button>
                    </Box>
                </TopicWrapper>
            </Slide>
        </>
    );
};
export default TopicSelection;
