import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as HealthIcon } from 'Assets/images/Wellness/health-icon.svg';
import { ReactComponent as RelationshipIcon } from 'Assets/images/Wellness/relationship-icon.svg';
import { ReactComponent as BusinessAndFinanceIcon } from 'Assets/images/Wellness/business-and-finance-icon.svg';
import { ReactComponent as PeakPerformanceIcon } from 'Assets/images/Wellness/peak-performance-icon.svg';
import { ReactComponent as PregnancyIcon } from 'Assets/images/Wellness/pregnancy-icon.svg';
import { ReactComponent as ChildrenAndEducationIcon } from 'Assets/images/Wellness/children-and-education-icon.svg';
import { ReactComponent as DownArrowIcon } from 'Assets/images/Common/down-arrow.svg';
import { ReactComponent as FilledStarIcon } from 'Assets/images/Common/filled-star.svg';
import { ReactComponent as OutlinedStarIcon } from 'Assets/images/Common/outlined-star.svg';
import { ReactComponent as FollowerProfileIcon } from 'Assets/images/MeditationRooms/follower-profile-icon.svg';
import Program_suggestion_pic from 'Assets/images/Common/program-suggestion.png';
import ReleaseBackpainSliderImage from 'Assets/images/Questionnaire/sun-bg.png';

import { ProgramListWrapper } from './RoomDetails.style';
import TaoCard from 'Components/common/TaoCard';

const ProgramList = ({ ...props }) => {
    const [selectedCategory, setSelectedCategory] = useState({ name: 'All', value: 'ALL' });
    const [dropDownOpen, setDropDownOpen] = useState(false);

    const categorySelectHandler = (catType) => {
        setSelectedCategory((preState) => {
            if (preState.value === catType.value) {
                return { name: 'All', value: 'ALL' };
            } else {
                return catType;
            }
        });
    };
    return (
        <ProgramListWrapper>
            <Box className="topic-filter-container">
                <Box className="title-txt-container">
                    <Typography variant="h5">Filter by Topic</Typography>
                    <Typography variant="h6">choose one</Typography>
                </Box>
                <Box className="category-list">
                    {ICON_LIST.map((i) => (
                        <Box className="icon-container">
                            <i.Icon
                                onClick={() =>
                                    categorySelectHandler({ name: i.name, value: i.value })
                                }
                                className={`category-icon ${
                                    selectedCategory.value === i.value && 'selected-category'
                                }`}
                            />
                            <Typography>{i.name}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box className="result-container">
                <Box className="filter-container">
                    <Typography className="result-count">
                        <b className="counter">24&nbsp;</b>
                        in&nbsp;
                        <b className="selected-category">{selectedCategory.name}</b>
                    </Typography>
                    <Box
                        className={`filter-drop-down ${dropDownOpen && 'open'}`}
                        onClick={() => setDropDownOpen((preState) => !preState)}>
                        <Box className="selected-val">
                            <DownArrowIcon className={`icon ${dropDownOpen && 'open'}`} />
                            <Typography variant="h5">Rating</Typography>
                        </Box>
                        <Box className="drop-down-content">
                            <Typography className="drop-down-item">A - Z</Typography>
                            {/* <Typography className="drop-down-item">A - Z</Typography> */}
                        </Box>
                    </Box>
                </Box>
                <Box className="program-list-container">
                    {PROGRAM_LIST.map((pr) => (
                        <Box className="program-container">
                            <TaoCard
                                cardRootClass={pr.cardRootClass}
                                type="playlist"
                                backgroundImage={pr.backgroundImage}
                            />
                            <Box className="program-info-container">
                                <Typography className="program-name">{pr.programName}</Typography>
                                <Box className="program-spec-container">
                                    <Box className="spec-container">
                                        <FilledStarIcon className="filled-star" />
                                        <FilledStarIcon className="filled-star" />
                                        <FilledStarIcon className="filled-star" />
                                        <FilledStarIcon className="filled-star" />
                                        <OutlinedStarIcon className="outlined-star" />
                                        <Typography className="count">{pr.reviewCount}</Typography>
                                    </Box>
                                    <Box className="spec-container">
                                        <FollowerProfileIcon className="person-icon" />
                                        <Typography className="count follower-count">
                                            {pr.followerCount}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </ProgramListWrapper>
    );
};

export default ProgramList;

const ICON_LIST = [
    { name: 'Health', value: 'HEALTH_ICON', Icon: HealthIcon },
    { name: 'Relationship', value: 'RELATION_ICON', Icon: RelationshipIcon },
    { name: 'Performance', value: 'PERFORMANCE_ICON', Icon: BusinessAndFinanceIcon },
    { name: 'Finance', value: 'FINANCE_ICON', Icon: PeakPerformanceIcon },
    { name: 'Pregnancy', value: 'PREGNANCY_ICON', Icon: PregnancyIcon },
    { name: 'Education', value: 'EDUCATION_ICON', Icon: ChildrenAndEducationIcon }
];

const PROGRAM_LIST = [
    {
        backgroundImage: Program_suggestion_pic,
        cardRootClass: 'program-card',
        programName: '12-Day Self-Love Journey',
        reviewCount: 27,
        followerCount: 45
    },
    {
        backgroundImage: ReleaseBackpainSliderImage,
        cardRootClass: 'program-card',
        programName: '21-Day Wellness Boost for Working Mothers',
        reviewCount: 27,
        followerCount: 45
    },
    {
        backgroundImage: Program_suggestion_pic,
        cardRootClass: 'program-card',
        programName: '12-Day Self-Love Journey',
        reviewCount: 27,
        followerCount: 45
    },
    {
        backgroundImage: ReleaseBackpainSliderImage,
        cardRootClass: 'program-card',
        programName: '12-Day Self-Love Journey',
        reviewCount: 27,
        followerCount: 45
    }
];
