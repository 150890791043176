//origin
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

//custom
import { Footer } from 'Components/common';
import { getWindowDimensions } from 'Helpers';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { MeditationReadWrapper } from './MeditationRead.style';

import { useDispatch } from 'react-redux';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import { handleTopBarInteractionDetail, hideTopBarInteractionButton } from 'Redux/App/Actions';

// import ShareSocial from 'Components/common/Share/Share';

const MeditationRead = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const API = useMemo(() => new Api(), []);
    const [contentDetails, setContentDetails] = useState({});
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const queryParams = new URLSearchParams(location?.search);
    const linkContentId = queryParams && queryParams?.get?.('contentId');

    const fetchContentById = useCallback(async () => {
        if (!location?.state?.contentId && !linkContentId) return;
        setIsLoading(true);
        let response = await API.get(API_URL.CONTENT_LIST, {
            params: {
                contentId: location?.state?.contentId || linkContentId
            }
        });
        if (response) {
            setContentDetails(response?.data?.data);
            dispatch(handleTopBarInteractionDetail(response?.data?.data || {}));
        }
        setIsLoading(false);
    }, [API, location?.state?.contentId, linkContentId, dispatch]);

    useEffect(() => {
        fetchContentById();
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);

            dispatch(handleTopBarInteractionDetail({}));
            dispatch(hideTopBarInteractionButton(false));
        };
    }, [dispatch, fetchContentById]);

    return (
        <React.Fragment>
            <MeditationReadWrapper className="flex f-h-center">
                <TaoLoader isLoading={isLoading} />

                <Box
                    className="top-image"
                    style={{
                        backgroundImage: `url(${contentDetails?.backgroundImageWeb})`
                    }}
                />
                <Box className="bottom-image" />
                <Box className="read-container flex f-h-space-between">
                    <Box className="content">
                        <Typography className="title">{contentDetails?.title}</Typography>
                        <Box className="content-overflow">
                            <Typography
                                className="body-text"
                                dangerouslySetInnerHTML={{
                                    __html: contentDetails?.description
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </MeditationReadWrapper>
            {windowDimensions.width >= BREAKPOINTS_VALUE.TABLET && (
                <Footer isBottomZero={true} showWhiteBackground={false} topBorderColor="gray" />
            )}
        </React.Fragment>
    );
};

export default MeditationRead;
