import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { IconButton, Typography, Box, Button, Divider } from '@mui/material';
import { PlayListWrapper, NoPlaylistWrapper } from './Playlist.style';
import { useNavigate } from 'react-router-dom';
import PlaylistBorders from 'Assets/images/Common/playlist-borders-old.svg';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import { ReactComponent as PlusIcon } from 'Assets/images/PlayList/plus-icon.svg';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import PlaylistItems from './PlaylistItems';
import PlaylistManipulationModal from './PlaylistManipulationModal/PlaylistManipulationModal';
import Api from 'Helpers/ApiHandler';
import { API_URL, URL_PLAY_MEDITATION } from 'Helpers/Paths';
import { useDispatch } from 'react-redux';
import { getWindowDimensions } from 'Helpers';

//custom
import { DeleteConfirmation } from 'Components/common/Modal/Delete/index';
import { SortFilter } from 'Components/common/SortFilter';

//icon
import { ReactComponent as EditIcon } from 'Assets/images/MeditationTimer/edit.svg';
import { ReactComponent as DeleteIcon } from 'Assets/images/MeditationTimer/delete-icon.svg';

import TaoSlider from 'Components/common/TaoSlider';
import { showToast } from 'Redux/App/Actions';
import { PLAYLIST_MESSAGES, SLIDER_PER_PAGE, TAO_CARD_TYPE } from 'Helpers/Constants';
import TaoCard from 'Components/common/TaoCard';
import usePermissions from '../../common/Permissions';
import { ACCESS_LEVELS_MODULEs } from 'Helpers/Constants';
import { SubscriptionDarkLock } from '../../common/SubscriptionLock';
import { showSubscriptionModal } from 'Redux/App/Actions';
import { isSubscribedAndAccessible } from './PlaylistCommon';

const SORT_BY_VALUE = {
    LATEST: 'Latest',
    'A-Z': 'A-Z'
};

const SORT_BY_API_VALUE = {
    Latest: 'latest',
    'A-Z': 'A-Z'
};

const SORT_BY_API_ORDER = {
    Latest: 'desc',
    'A-Z': 'asc'
};

const PlaylistModalInitState = {
    visible: false,
    totalRecords: 0,
    list: [],
    playListName: '',
    playlistId: null,
    isEdit: false
};

const Playlist = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const API = useMemo(() => new Api(), []);
    const playListContentSlider = useRef(null);
    const [showPlaylistModal, setShowPlaylistModal] = useState({ ...PlaylistModalInitState });
    const [isLoading, setsIsLoading] = useState(true);
    const [playList, setPlayList] = useState({
        totalRecords: 0,
        list: []
    });
    const [playlistItems, setPlaylistItems] = useState([]);

    const modulePermission = usePermissions(ACCESS_LEVELS_MODULEs?.CREATE_PLAYLIST);
    const [paginationInfo, setPaginationInfo] = useState({
        perPage: SLIDER_PER_PAGE,
        currentPage: 0,
        sort: { field: SORT_BY_API_VALUE.Latest, order: SORT_BY_API_ORDER.Latest },
        search: ''
    });
    const [sortBy, setSortBy] = useState(SORT_BY_VALUE?.LATEST);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deletePlaylistID, setDeletePlaylistID] = useState('');
    const [resetPlaylist, setResetPlaylist] = useState(true);

    const playMeditationHandler = (id) => {
        const playlistItem = playlistItems.find((item) => item?.playlistId === id);
        navigateToPlayList(id, playlistItem?.playlistName, playlistItem?.list);
    };

    const navigateToPlayList = (id, playListTitle, data) => {
        let isPlaylistAccessible = isSubscribedAndAccessible(data);

        if (isPlaylistAccessible?.length) {
            return navigate(URL_PLAY_MEDITATION, {
                state: { playListId: id, playlistName: playListTitle }
            });
        }
        dispatch(showToast(PLAYLIST_MESSAGES?.NONE_PURCHASED, 'warning'));
    };

    const deleteHandler = (playListId) => {
        setDeletePlaylistID(playListId);
        setOpenDeleteDialog(true);
    };

    const deletePlaylist = async () => {
        setsIsLoading(true);
        setOpenDeleteDialog(false);

        let response = await API.delete(API_URL.DELETE_PLAYLIST_ITEM, {
            params: { id: deletePlaylistID }
        });

        if (response) {
            setResetPlaylist((prev) => !prev);
            dispatch(showToast(response?.data?.message, 'success'));
        }
        setsIsLoading(false);
    };

    const handleFilterChange = (item) => {
        if (sortBy !== item) {
            setPaginationInfo((prev) => ({
                ...prev,
                current_page: 0,
                sort: {
                    field: SORT_BY_API_VALUE[item],
                    order: SORT_BY_API_ORDER[item]
                }
            }));
        }
        setSortBy(item);
    };

    const handleRedirection = () => {
        if (!modulePermission?.canAccess) {
            return dispatch(showSubscriptionModal());
        }
        setShowPlaylistModal((prev) => ({ ...prev, visible: true, isEdit: false }));
    };

    const fetchPlaylistItems = useCallback(async () => {
        if (!playList?.list?.length) return;
        setsIsLoading(true);
        let promises = [];
        playList?.list?.forEach((item) => {
            promises.push(
                API.get(API_URL.GET_PLAYLIST_CONTENT, {
                    params: {
                        programId: item?.id
                    }
                })
            );
        });

        let result = await Promise.all(promises);
        if (result) {
            setPlaylistItems(
                result.map((res, index) => ({
                    ...res?.data?.data,
                    playlistId: playList?.list[index]?.id
                }))
            );
            setsIsLoading(false);
        }
    }, [API, playList?.list]);

    const getPlaylist = useCallback(async () => {
        setsIsLoading(true);

        const { perPage, currentPage, sort, search } = paginationInfo;

        let response = await API.post(API_URL.GET_PLAYLIST, {
            data: {
                current_page: currentPage,
                per_page: perPage,
                sort: sort,
                search: search
            }
        });

        if (response?.data?.data) {
            setPlayList((prev) => {
                let arr =
                    paginationInfo?.currentPage === 0
                        ? response?.data?.data?.list
                        : prev.list.concat(response?.data?.data?.list);
                return {
                    totalRecords: response?.data?.data?.totalRecords,
                    list: [...new Map(arr.map((item) => [item['id'], item])).values()]
                };
            });
            setsIsLoading(false);
        }
    }, [paginationInfo, API]);

    useEffect(() => {
        fetchPlaylistItems();
    }, [playList?.list?.length, fetchPlaylistItems]);

    useEffect(() => {
        getPlaylist();
    }, [getPlaylist, resetPlaylist]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowDimensions]);

    return (
        <>
            <TaoCalligraphyWatermark />
            <TaoLoader isLoading={isLoading} />
            <PlayListWrapper>
                {windowDimensions.width >= BREAKPOINTS_VALUE.TABLET && (
                    <Box
                        className={`full-width-section ${
                            playList.totalRecords === 0 && 'flex f-v-center'
                        }`}>
                        {playList?.totalRecords > 0 && (
                            <>
                                <Box className="playlist-page-content">
                                    <Box className="button-container">
                                        <Button
                                            variant="text"
                                            className="add-button"
                                            onClick={() => handleRedirection()}
                                            endIcon={
                                                modulePermission?.canAccess ? (
                                                    <PlusIcon />
                                                ) : (
                                                    <SubscriptionDarkLock />
                                                )
                                            }>
                                            <Typography className="text">New Playlist</Typography>
                                        </Button>
                                    </Box>
                                    <Box className="slider-content">
                                        <Typography color="primary" className="slider-header">
                                            Playlists
                                        </Typography>
                                        <TaoSlider
                                            ref={playListContentSlider}
                                            cardHeight={90}
                                            gap={10}>
                                            {playList.list.map((value, index) => (
                                                <TaoCard
                                                    key={index}
                                                    backgroundImage={value?.playlistImage}
                                                    type={TAO_CARD_TYPE.PROGRAM}
                                                    title={value.title}
                                                    isTextColorDark
                                                    textFromStart
                                                    isAccessible
                                                    isProgramFromPlaylist
                                                    onClick={() =>
                                                        playMeditationHandler(
                                                            value?.id,
                                                            value?.title
                                                        )
                                                    }>
                                                    <Box className="description-container">
                                                        <Typography className="media-time">
                                                            {value?.meditations +
                                                                ' Meditations' +
                                                                ' | ' +
                                                                value?.timeDurationInMinutes +
                                                                ' mins'}
                                                        </Typography>
                                                    </Box>
                                                </TaoCard>
                                            ))}
                                        </TaoSlider>
                                    </Box>
                                </Box>
                                <Box className="desktop-search-filter">
                                    <SortFilter
                                        sortBy={sortBy}
                                        sortObject={SORT_BY_VALUE}
                                        onClick={(item) => handleFilterChange(item)}
                                    />
                                </Box>
                                <Box className="energy-playlist-container">
                                    {playlistItems.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <PlaylistItems
                                                keyId={item?.playlistName}
                                                playListId={item?.playlistId}
                                                playlistName={item?.playlistName}
                                                list={item?.list}
                                                deleteHandler={deleteHandler}
                                                setsIsLoading={setsIsLoading}
                                                isLoading={isLoading}
                                                setResetPlaylist={setResetPlaylist}
                                                playMeditationHandler={playMeditationHandler}
                                                setShowPlaylistModal={setShowPlaylistModal}
                                            />
                                            {playList?.list?.length - 1 !== index && <Divider />}
                                        </React.Fragment>
                                    ))}
                                </Box>
                            </>
                        )}
                        {!playList.totalRecords && !isLoading && (
                            <NoPlaylistWrapper>
                                <Box className="container">
                                    <Typography className="heading-message">
                                        {'You have no playlist'}
                                    </Typography>

                                    <Button
                                        variant="text"
                                        className="add-button full-width"
                                        onClick={() => handleRedirection()}
                                        endIcon={
                                            modulePermission?.canAccess ? (
                                                <PlusIcon />
                                            ) : (
                                                <SubscriptionDarkLock height={18} />
                                            )
                                        }>
                                        <Typography className="text">Create Playlist</Typography>
                                    </Button>
                                </Box>
                            </NoPlaylistWrapper>
                        )}
                    </Box>
                )}
                <Box className="playlist-tablet-view flex f-column">
                    <Box className="heading flex f-v-center">
                        <Typography className="title">playlists</Typography>
                    </Box>
                    <Box className="search-filter">
                        <SortFilter
                            sortBy={sortBy}
                            sortObject={SORT_BY_VALUE}
                            onClick={(item) => handleFilterChange(item)}
                        />
                    </Box>
                    <Box className="playlist-list">
                        <Box
                            className="flex f-v-center add-item-wrapper hover"
                            onClick={() => handleRedirection()}>
                            <Box className="add-item flex f-v-center f-h-center">
                                <Box
                                    className="add-item-container"
                                    sx={{ opacity: modulePermission?.canAccess ? 1 : 0.5 }}>
                                    <IconButton className="add-new-playlist-icon">
                                        <PlusIcon />
                                    </IconButton>
                                </Box>
                                {!modulePermission?.canAccess && (
                                    <Box className="lock-icon">
                                        <SubscriptionDarkLock height={20} />
                                    </Box>
                                )}
                                <img className="playlist-borders" src={PlaylistBorders} alt="" />
                            </Box>
                            <Typography className="new-playlist-title">New Playlist</Typography>
                        </Box>
                        {playList.list.map((value, index) => {
                            return (
                                <Box key={index} className="flex f-v-center play-list-item-wrapper">
                                    <Box className="flex f-v-center play-list-content">
                                        <TaoCard
                                            backgroundImage={value?.playlistImage}
                                            type={TAO_CARD_TYPE.PROGRAM}
                                            isProgramFromPlaylist
                                            isAccessible
                                            cardRootClass="mobile-tao-card"
                                            supportedResponsiveView
                                            onClick={() =>
                                                playMeditationHandler(value?.id, value?.title)
                                            }
                                        />
                                        <Box className="playlist-description flex f-column">
                                            <Typography className="title">{value.title}</Typography>
                                            <Typography className="no-of-meditation">
                                                {value.meditations} meditations
                                            </Typography>
                                            <Typography className="meditation-time">
                                                {value.timeDurationInMinutes} mins
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className="icon-section flex f-column f-h-space-between">
                                        <IconButton
                                            onClick={() => {
                                                setShowPlaylistModal((prev) => ({
                                                    ...prev,
                                                    visible: true,
                                                    playListName: value?.title,
                                                    playlistId: value?.id,
                                                    isEdit: true,
                                                    list: playlistItems?.find(
                                                        (item) => item?.playlistId === value?.id
                                                    )?.list
                                                }));
                                            }}
                                            sx={{ width: '40px', height: '40px' }}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => deleteHandler(value.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>

                {showPlaylistModal?.visible && (
                    <PlaylistManipulationModal
                        visible
                        onClose={() => setShowPlaylistModal(PlaylistModalInitState)}
                        isEdit={showPlaylistModal?.isEdit}
                        details={showPlaylistModal}
                        playListId={showPlaylistModal?.playlistId}
                        isLoading={isLoading}
                        setsIsLoading={setsIsLoading}
                        setResetPlaylist={setResetPlaylist}
                    />
                )}
            </PlayListWrapper>
            {openDeleteDialog && (
                <DeleteConfirmation
                    title="Are you sure you want to delete the playlist? You won't able to revert the changes."
                    onClose={() => setOpenDeleteDialog(false)}
                    onConfirm={() => {
                        deletePlaylist();
                    }}
                />
            )}
        </>
    );
};

export default Playlist;
