import styled from 'styled-components';
import { Drawer, Menu, TextField, IconButton } from '@mui/material';
import { responsive, FONTS, COLORS } from 'Styles/Constants';

export const CustomParticipantsViewDrawer = styled(Drawer)`
    z-index: 200 !important;
    left: unset !important;
    /* width: 400px; */
    width: ${(props) => (props.isParticipantModalExpanded ? '800px' : '400px')};

    .MuiPaper-root {
        background-color: ${(props) => (props.hostView ? COLORS.TEXT_DARK : '#ffffff')};
        backdrop-filter: blur(30px);
        height: calc(100vh - 50px);

        ${responsive.TABLET`
            height: 100%;
        `}

        .participants-list {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            padding: 110px 20px 20px 20px;
            /* width: 384px; */
            width: ${(props) => (props.isParticipantModalExpanded ? '768px' : '384px')};
            height: calc(100vh - 260px);
            background-color: ${(props) => (props.hostView ? COLORS.TEXT_DARK : '#ffffff')};
            backdrop-filter: blur(30px);
            border-radius: 0px;
            gap: 30px;
            border-bottom: ${(props) => (props.hostView ? '1px solid #ffffff' : `1px solid ${COLORS.LIGHT_GREY}`)};

            ${responsive.TABLET`
                width: 100%;
                height: ${(props) => (props.hostView ? 'calc(100vh - 310px)' : 'calc(100vh - 160px)')};
                padding: 40px 20px 20px 20px;
            `}

            .view-participants-title {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 15px;

                .MuiTypography-root {
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: center;
                    color: ${(props) => (props.hostView ? '#ffffff' : COLORS.PRIMARY_WARM)};
                }
            }

            .participants-list-details {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 40px;
                margin-left: 10px;

                ${responsive.MOBILE`
                    gap: 30px;
                `}

                .user-details {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    // flex: 33%;
                    width: 80px;
                    height: 80px;

                    .participant-image-section {
                        position: relative;

                        .participant-image {
                            width: 80px;
                            height: 80px;
                            border: ${(props) => (props.hostView ? '1px solid #ffffff' : `1px solid ${COLORS.LIGHT_GREY}`)};
                            border-radius: 8px;
                        }

                        .host-icon {
                            position: absolute;
                            right: -8px;
                            top: 15px;
                        }
                    }

                    .participant-name-details {
                        display: flex;
                        flex-direction: row;
                        gap: 5px;
                        align-items: center;

                        .participant-name {
                            width: 80px;
                            min-width: 80px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            font-family: ${FONTS.PRIMARY};
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 17px;
                            text-align: center;
                            color: ${(props) => (props.hostView ? '#ffffff' : COLORS.PRIMARY_WARM)};
                        }
                    }
                }
            }
        }

        .participant-search-section {
            display: flex;
            flex-direction: column;
            gap: 30px;
            background-color: ${(props) => (props.hostView ? COLORS.TEXT_DARK : '#ffffff')};

            .mute-section {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 30px 10px 0px 25px;
                gap: 20px;

                .mute-buttons {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    flex: 50%;

                    background-color: #ffffff;
                    border: 1px solid ${COLORS.LIGHT_GREY};
                    border-radius: 19px;
                    gap: 15px;

                    .mute-all {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 23px;
                        color: ${COLORS.TEXT_DARK};
                    }

                    .unmute-all {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 23px;
                        color: ${COLORS.PRIMARY_DARK};
                    }
                }
            }

            .search-input-container {
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;
                gap: 20px;
                background-color: ${(props) => (props.hostView ? COLORS.TEXT_DARK : '#ffffff')};
                backdrop-filter: blur(30px);
                border-radius: 0px;
                padding: ${(props) => (props.hostView ? '0px 10px 10px 25px' : '30px 10px 10px 25px')};

                ${responsive.TABLET`
                    flex-direction: column-reverse;
                `}

                svg {
                    ${responsive.TABLET`
                        transform: rotate(90deg);
                    `}
                }
            }
        }
    }
`;

export const CustomChatContextMenu = styled(Menu)`
    .MuiPaper-root {
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
        border-radius: 0px 14px 14px 14px;

        .MuiList-root {
            padding-top: 3px;
            padding-bottom: 3px;

            .MuiDivider-root {
                margin-left: 10px;
                margin-right: 10px;
                border: 1px solid ${COLORS.LIGHT_GREY};
            }

            .MuiMenuItem-root {
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;
                gap: 10px;

                svg {
                    margin-bottom: 5px;
                    flex: 20%;
                }

                .MuiTypography-root {
                    flex: 80%;
                }
            }
        }
    }
`;

export const CustomParticipantsViewSearchInput = styled(TextField)`
    width: 100%;
    padding: 0px 20px 0px 0px !important;

    .MuiOutlinedInput-root {
        border-radius: 30px;
        background-color: ${COLORS.LIGHT_GREY_SECONDARY};

        .MuiOutlinedInput-input {
            font-family: ${FONTS.PRIMARY};
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: ${COLORS.TEXT_DARK};
            padding: 14px 14px 14px 10px;
        }

        .MuiOutlinedInput-notchedOutline {
            border: none;
        }
    }
`;

export const ExpandParticipantViewButtonWrapper = styled(IconButton)`
    background-color: #ffffff !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 18px;
    position: relative;
    /* left: calc(100vw - 400px); */
    left: ${(props) => (props.isParticipantModalExpanded ? 'calc(100vw - 783px)' : 'calc(100vw - 400px)')};
    top: -850px;
    z-index: 300;
    transform: ${(props) => (props.isParticipantModalExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
