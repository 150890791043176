import React, { useMemo } from 'react';

import { Box, Typography, Avatar, Button, Input } from '@mui/material';
import { ReactComponent as SearchIcon } from 'Assets/images/Common/outlined-search-icon.svg'
import Follower1Img from "Assets/images/Profile/default-user-icon.jpg";
import Follower2Img from "Assets/images/Profile/default-user-icon.jpg";
import Follower3Img from "Assets/images/Profile/default-user-icon.jpg";
import Follower4Img from "Assets/images/Profile/default-user-icon.jpg";

import { AboutWrapper } from './RoomDetails.style';
import { numberFormatter } from 'Helpers';
import { ROOM_TYPES } from 'Helpers/Constants';

import moment from 'moment';

const About = ({ roomType = "", hosts = [], singleHost = "", myRoom = false, ...props }) => {

    const followerList = useMemo(() => {
        const results = [];
        for (let index = 1; index < 17; index++) {
            const remainder = index % 4;
            switch (remainder) {
                case 0:
                    results.push({ img: Follower2Img, name: "FirstName LastName" })
                    break;

                case 1:
                    results.push({ img: Follower1Img, name: "FirstName LastName" })
                    break;

                case 2:
                    results.push({ img: Follower3Img, name: "FirstName LastName" })
                    break;

                case 3:
                    results.push({ img: Follower4Img, name: "FirstName LastName" })
                    break;

                default:
                    results.push({ img: Follower3Img, name: "FirstName LastName" })
                    break;
            }
        }
        return results.map(f =>
            <Box className="follower-container">
                <Box style={{ backgroundImage: `url("${f.img}")` }} className="follower-pic" >

                </Box>
                <Typography className="full-width elipsis name">{f.name}</Typography>
            </Box>
        );

    }, []);
    return (
        <AboutWrapper>
            {
                myRoom ? (
                    <Box className="my-room-info-container">
                        <Box className="description-container">
                            <Typography variant="h5" >Description</Typography>
                            <Typography variant="h6" ><b>Lorem ipsum</b> dolor sit amet, consectetur adipiscing elit. Elementum lacinia luctus risus pharetra dui elementum ornare. Arcu ut interdum ullamcorper. (Room Description)</Typography>
                        </Box>
                        <Box className="visitor-feeling-container">
                            <Box className="rating-container spec-container">
                                <Typography className="title">How Others Visitors Feel</Typography>
                                <Box className="specs">
                                    <Box className="spec-item">
                                        <Typography variant="h5" >Less Pain</Typography>
                                        <Box style={{ width: ((18 / 18) * 100 * 0.45).toString() + "%" }} className="bar"></Box>
                                        <Typography variant="h6">{numberFormatter(18)}</Typography>
                                    </Box>
                                    <Box className="spec-item">
                                        <Typography variant="h5" >Energized</Typography>
                                        <Box style={{ width: ((11 / 18) * 100 * 0.45).toString() + "%" }} className="bar"></Box>
                                        <Typography variant="h6">{numberFormatter(11)}</Typography>
                                    </Box>
                                    <Box className="spec-item">
                                        <Typography variant="h5" >Peaceful</Typography>
                                        <Box style={{ width: ((10 / 18) * 100 * 0.45).toString() + "%" }} className="bar"></Box>
                                        <Typography variant="h6">{numberFormatter(10)}</Typography>
                                    </Box>
                                    <Box className="spec-item">
                                        <Typography variant="h5" >Happier</Typography>
                                        <Box style={{ width: ((3 / 18) * 100 * 0.45).toString() + "%" }} className="bar"></Box>
                                        <Typography variant="h6">{numberFormatter(3)}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Button className="edit-btn" type="text">Edit {">"}</Button>
                        </Box>
                    </Box>
                ) : (
                    <Box className="session-info-container">
                        <Box className="history-container">
                            <Typography variant="h5">Open since: {moment().add(-1, 'year').format("MMM DD, ‘YY")}</Typography>
                            <Typography variant="h6"><b style={{ color: "#31290E" }}>Lorem ipsum</b> dolor sit amet, consectetur adipiscing elit. Elementum lacinia luctus risus pharetra dui elementum ornare. Arcu ut interdum ullamcorper. (Room Description)</Typography>
                        </Box>
                        <Box className="divider"></Box>
                        <Box className="spec-container">
                            <Typography className="title">What Others Experienced</Typography>
                            <Box className="specs">
                                <Box className="spec-item">
                                    <Typography variant="h5" >Less Pain</Typography>
                                    <Box style={{ width: ((37000 / 40000) * 100 * 0.45).toString() + "%" }} className="bar"></Box>
                                    <Typography variant="h6">{numberFormatter(37000)}</Typography>
                                </Box>
                                <Box className="spec-item">
                                    <Typography variant="h5" >Energized</Typography>
                                    <Box style={{ width: ((17000 / 40000) * 100 * 0.45).toString() + "%" }} className="bar"></Box>
                                    <Typography variant="h6">{numberFormatter(17000)}</Typography>
                                </Box>
                                <Box className="spec-item">
                                    <Typography variant="h5" >Peaceful</Typography>
                                    <Box style={{ width: ((24000 / 40000) * 100 * 0.45).toString() + "%" }} className="bar"></Box>
                                    <Typography variant="h6">{numberFormatter(24000)}</Typography>
                                </Box>
                                <Box className="spec-item">
                                    <Typography variant="h5" >Happier</Typography>
                                    <Box style={{ width: ((20000 / 40000) * 100 * 0.45).toString() + "%" }} className="bar"></Box>
                                    <Typography variant="h6">{numberFormatter(20000)}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )
            }
            {
                roomType === ROOM_TYPES.TAO_CALLIGRAPHY_FIELD && <Box className="hosts-container">
                    <Typography variant="h6" className="title">Your Hosts</Typography>
                    {
                        roomType === ROOM_TYPES.TAO_CALLIGRAPHY_FIELD && <Box className="TCF-host-list">
                            {
                                hosts.map(h => <Box className="host-container">
                                    <Box className="profile-section">
                                        <Avatar className="tcf-avatar" sx={{ width: 80, height: 80 }} src={h.img} />
                                        <Button className="profile-btn" variant="text">Full Profile</Button>
                                    </Box>
                                    <Box className="description-section">
                                        <Typography className="name">{h.name}</Typography>
                                        <Typography className="description"><b>Lorem ipsum</b> dolor sit amet, consectetur adipiscing elit. Elementum lacinia luctus risus pharetra dui elementum ornare. Arcu ut interdum ullamcorper.</Typography>
                                    </Box>
                                </Box>)
                            }
                        </Box>
                    }
                </Box>

            }
            {
                ((roomType === ROOM_TYPES.FEATURED_ROOM) || (roomType === ROOM_TYPES.COMMUNITY_ROOM)) && <Box className="single-host-container">
                    <Typography variant="h6" className="title">Your Host, {singleHost}</Typography>
                    <Typography className="description"><b>Lorem ipsum</b> dolor sit amet, consectetur adipiscing elit. Elementum lacinia luctus risus pharetra dui elementum ornare. Arcu ut interdum ullamcorper.</Typography>
                </Box>
            }
            <Box className="followers-container">
                <Typography className="section-title">Followers</Typography>
                <Input startAdornment={<SearchIcon />} disableUnderline className="filter-input" variant="filled" placeholder="Search" />
                <Box className="followers-list">
                    {
                        followerList
                    }
                </Box>
            </Box>
        </AboutWrapper>
    );
};



export default About;

// const FOLLOWER_LIST = [
//     {img: }
// ]