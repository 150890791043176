//core
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { CircularProgress, IconButton, Typography, Box } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

//custom
import { CardImageWrapper, ProgramsWrapper } from './Programs.style';
import Api from 'Helpers/ApiHandler';
import { API_URL, URL_PROGRAMS_DETAILS } from 'Helpers/Paths';

//image
import PlaylistBorders from 'Assets/images/Common/playlist-borders.svg';
import PlaylistButton from 'Assets/images/Common/playlist-icon.svg';
import { PROGRAM_HISTORY_TYPE } from 'Helpers/Constants';

const Programs = (props) => {
    const API = useMemo(() => new Api(), []);
    const navigate = useNavigate();

    const [inProgressPagination, setInProgressPagination] = useState({
        current_page: 0,
        per_page: 5,
        search: ''
    });
    const [pagination, setPagination] = useState({ current_page: 0, per_page: 5, search: '' });
    const [programList, setProgramList] = useState({ totalRecords: 0, list: [] });
    const [inProgressProgramList, setInProgressProgramList] = useState({
        totalRecords: 0,
        list: []
    });

    const getProgramHistory = useCallback(async () => {
        props.setLoading(true);
        const response = await API.post(API_URL.PROGRAM_HISTORY, {
            params: { programType: PROGRAM_HISTORY_TYPE?.COMPLETED },
            data: {
                current_page: pagination.current_page,
                per_page: pagination.per_page,
                search: pagination.search
            }
        });
        if (response) {
            setProgramList((prev) => {
                let arr =
                    pagination?.current_page === 0
                        ? response?.data?.data?.forYouProgramList?.list
                        : prev.list.concat(response?.data?.data?.forYouProgramList?.list);
                return {
                    totalRecords: response?.data?.data?.forYouProgramList?.totalRecords,
                    list: [...new Map(arr.map((item) => [item['id'], item])).values()]
                };
            });
            props.setLoading(false);
        }
    }, [API, props, pagination]);

    const getInProgressProgramHistory = useCallback(async () => {
        props.setLoading(true);
        const response = await API.post(API_URL.PROGRAM_HISTORY, {
            params: { programType: PROGRAM_HISTORY_TYPE.IN_PROGRESS },
            data: {
                current_page: inProgressPagination.current_page,
                per_page: inProgressPagination.per_page,
                search: inProgressPagination.search
            }
        });
        if (response) {
            setInProgressProgramList((prev) => {
                let arr =
                    inProgressPagination?.current_page === 0
                        ? response?.data?.data?.inProgressProgramsList?.list
                        : prev.list.concat(response?.data?.data?.inProgressProgramsList?.list);
                return {
                    totalRecords: response?.data?.data?.inProgressProgramsList?.totalRecords,
                    list: [...new Map(arr.map((item) => [item['id'], item])).values()]
                };
            });
            props.setLoading(false);
        }
    }, [API, props, inProgressPagination]);

    const handleNavigation = (id, type = PROGRAM_HISTORY_TYPE.IN_PROGRESS) => {
        navigate(URL_PROGRAMS_DETAILS, {
            state: {
                programId: id,
                isRedirectedFromHistory: type === PROGRAM_HISTORY_TYPE.COMPLETED ? true : false
            }
        });
    };

    const loadMoreData = () => {
        setPagination((prev) => {
            return { ...prev, current_page: prev.current_page + 1 };
        });
    };

    const inProgressLoadMoreData = () => {
        setInProgressPagination((prev) => {
            return { ...prev, current_page: prev.current_page + 1 };
        });
    };

    useEffect(() => {
        getProgramHistory();
    }, [getProgramHistory]);

    useEffect(() => {
        getInProgressProgramHistory();
    }, [getInProgressProgramHistory]);

    return (
        <React.Fragment>
            <ProgramsWrapper className="flex f-column">
                <Typography className="program-title">Programs</Typography>
                <Box
                    className={`in-progress-section flex f-h-space-between ${
                        inProgressProgramList.list.length > 0 ? null : 'f-column'
                    }`}>
                    <Typography className="in-progress-title">In Progress</Typography>
                    {inProgressProgramList.list.length > 0 ? (
                        <Box className="in-progress-data flex f-column">
                            {inProgressProgramList.list.map((values) => {
                                return (
                                    <Box className="in-progress-card flex" key={values.id}>
                                        <Box className="img-section">
                                            <CardImageWrapper
                                                programImage={values?.thumbnailImage}
                                            />
                                            <img
                                                className="img-borders"
                                                src={PlaylistBorders}
                                                alt=""
                                            />
                                            <IconButton
                                                className="play-button"
                                                onClick={() => handleNavigation(values.id)}>
                                                <img
                                                    src={PlaylistButton}
                                                    alt="play icon"
                                                    className="playlist-icon"
                                                />
                                            </IconButton>
                                            <Box className="circular-progress-icon">
                                                <CircularProgress
                                                    className="circular-progress"
                                                    variant="determinate"
                                                    value={
                                                        values?.completedPercentage
                                                    }></CircularProgress>
                                                <Box className="circular-progress-bg">
                                                    {values?.completedPercentage}%
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="description-section flex f-column ">
                                            <Typography className="title">
                                                {values?.title}
                                            </Typography>
                                            <Typography className="started-on">
                                                Started on:
                                            </Typography>
                                            <Typography className="started-on date">
                                                {values?.programJoinDate
                                                    ? moment(values.programJoinDate).format(
                                                          'MMM DD YY'
                                                      )
                                                    : ''}
                                            </Typography>
                                        </Box>
                                    </Box>
                                );
                            })}
                            {inProgressProgramList?.totalRecords !==
                                inProgressProgramList?.list.length && (
                                <Box
                                    className="load-more flex f-column f-v-center f-h-center hover"
                                    onClick={() => inProgressLoadMoreData()}>
                                    <Typography className="load-more-text">Load More</Typography>
                                    <Box className="load-more-arrow" />
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box className="empty flex f-v-center f-h-center">
                            <Typography>No Program Found</Typography>
                        </Box>
                    )}
                </Box>
                <Box
                    className={`completed-section flex f-h-space-between ${
                        programList.list.length > 0 ? null : 'f-column'
                    }`}>
                    <Typography className="completed-title">Completed</Typography>
                    {programList.list.length > 0 ? (
                        <Box className="in-progress-data flex f-column">
                            {programList.list.map((values) => {
                                return (
                                    <Box className="in-progress-card flex" key={values?.id}>
                                        <Box className="img-section">
                                            <CardImageWrapper
                                                programImage={values?.thumbnailImage}
                                            />
                                            <img
                                                className="img-borders"
                                                src={PlaylistBorders}
                                                alt=""
                                            />
                                            <IconButton
                                                className="play-button"
                                                onClick={() =>
                                                    handleNavigation(
                                                        values.id,
                                                        PROGRAM_HISTORY_TYPE.COMPLETED
                                                    )
                                                }>
                                                <img
                                                    src={PlaylistButton}
                                                    alt="play icon"
                                                    className="playlist-icon"
                                                />
                                            </IconButton>
                                            <Box className="circular-progress-icon">
                                                <CircularProgress
                                                    className="circular-progress"
                                                    variant="determinate"
                                                    value={
                                                        values?.completedPercentage
                                                    }></CircularProgress>
                                                <Box className="circular-progress-bg">
                                                    {values?.completedPercentage}%
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="description-section flex f-column ">
                                            <Typography className="title">
                                                {values?.title}
                                            </Typography>
                                            <Typography className="started-on">
                                                Started on:
                                            </Typography>
                                            <Typography className="started-on date">
                                                {values?.programCompletedDate
                                                    ? moment(values.programCompletedDate).format(
                                                          'MMM DD YY'
                                                      )
                                                    : ''}
                                            </Typography>
                                        </Box>
                                    </Box>
                                );
                            })}
                            {programList?.totalRecords !== programList?.list.length && (
                                <Box
                                    className="load-more flex f-column f-v-center f-h-center hover"
                                    onClick={() => loadMoreData()}>
                                    <Typography className="load-more-text">Load More</Typography>
                                    <Box className="load-more-arrow" />
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box className="empty flex f-v-center f-h-center">
                            <Typography>No Program Found</Typography>
                        </Box>
                    )}
                </Box>
            </ProgramsWrapper>
        </React.Fragment>
    );
};

export default Programs;
