import { Box } from '@mui/system';
import styled from 'styled-components';
import HowToMeditate from 'Assets/images/MeditationContent/how-to-meditate.jpg';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const HowToMeditateWrapper = styled(Box)`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${responsive.TABLET`
        align-items: center;
    `}

    ${responsive.MOBILE`
        background-image: unset;
    `}

    .bg-image {
        background-image: url(${HowToMeditate});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left;
        height: 100%;
        width: 100%;
        position: absolute;

        ${responsive.TABLET`
            height: 60%;
            background-position: top;
        `}
    }
    .no-content {
        color: ${COLORS.TEXT_DARK};
        font-size: 24px;
        height: 160px;
    }

    .mobileContainer {
        position: absolute;
        height: 60%;
        bottom: 0;

        .load-more {
            padding: 20px 0;
            width: max-content;
            margin: 0 auto;

            &:hover {
                .load-more-arrow {
                    transition: all 0.3s ease-in-out;
                    margin: 5px 0 0;
                }
            }

            .load-more-text {
                color: ${COLORS.PRIMARY};
            }
            .load-more-arrow {
                width: 10px;
                height: 10px;
                border-right: 1px solid ${COLORS.PRIMARY};
                border-bottom: 1px solid ${COLORS.PRIMARY};
                transform: rotate(45deg);
            }
        }

        .detailsContainer {
            background: ${COLORS.WHITE};
            border-radius: 35px 35px 0px 0px;
            height: 100%;
            width: 100%;
            position: relative;
            bottom: 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
            overflow: auto;
            padding-bottom: 10px;

            .spacing {
                padding: 0 25px;
            }

            .title {
                font-family: ${FONTS.SECONDARY};
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 40px;
                letter-spacing: -0.02em;
                text-transform: capitalize;
                color: ${COLORS.PRIMARY_DARK};
                padding-left: 0;
                padding-top: 15px;
            }

            .goal-chip {
                padding: 10px 20px;
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                height: 46px;
                border-radius: 26px;
                text-transform: uppercase;
                border: 2px solid transparent;
                margin-right: 7px;

                &.non-selected-goal {
                    background-color: ${COLORS.LIGHT_GREY_SECONDARY};
                    color: ${COLORS.MEDIUM_GREY};
                }

                &.selected-goal {
                    border: 2px solid ${COLORS.PRIMARY};
                    color: ${COLORS.PRIMARY};
                }
            }

            .section-color {
                background-color: rgba(232, 232, 232, 0.5);
            }

            .section {
                display: flex;
                flex-direction: row;
                padding: 10px 15px;

                .image-section {
                    position: relative;
                    .image {
                        height: 100px;
                        width: 160px;
                        border-top-left-radius: 25px;
                        border-bottom-right-radius: 25px;
                        margin-right: 10px;

                        @media only screen and (max-width: 400px) {
                            width: 125px;
                            margin-right: 5px;
                        }
                    }
                    .play-icon-button {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .content-section {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    ${responsive.TABLET`
                        width: 80%;
                    `}

                    ${responsive.PHABLET`
                        width: 70%;
                    `}

                    ${responsive.MOBILE`
                        width: 60%;
                    `}

                    .media-text {
                        width: 90%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .description-media-text {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    .title-text {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 500;
                        font-size: 21px;
                        line-height: 22px;
                        color: ${COLORS.PRIMARY_DARK};
                    }

                    .description-text {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 21px;
                        color: ${COLORS.NEUTRAL_GREY};
                    }

                    .min-text {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13.4303px;
                        line-height: 23px;
                        color: ${COLORS.MEDIUM_GREY};
                    }
                }
            }

            .mobile-read-section {
                /* border: 1px solid #000; */
                width: 100%;
                padding: 0 25px;
                flex-wrap: wrap;
                gap: 20px;
            }
        }
    }

    .container {
        margin: 100px 0 0 200px;
        gap: 20px;
        display: flex;
        flex-direction: column;
        height: 100%;
        z-index: 10;
        position: relative;

        ${responsive.TABLET`
            display: none;
        `}

        .header-text {
            font-family: ${FONTS.SECONDARY_BOLD};
            font-size: 60px;
            text-transform: capitalize;
            color: ${COLORS.PRIMARY_DARK};
            margin: 0 0 7px 40px;
        }

        .content-slider-wrapper {
            width: 550px;
            margin: 0 20px;

            .watch-empty-content {
                color: ${COLORS.TEXT_DARK};
                font-size: 24px;
                height: 240px;
            }
        }

        .watch-box {
            position: relative;
            margin-bottom: 25px;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.3) 0%,
                rgba(255, 255, 255, 0.132) 100%
            );
            border: 2px solid #ffffff;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(30px);
            border-radius: 30px 15px;
            width: 670px;
            height: 365px;
            padding: 20px 30px;

            .title {
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                line-height: 40px;
                text-transform: uppercase;
                color: ${COLORS.PRIMARY_DARK};
                mix-blend-mode: multiply;
                margin: 0 0 7px 16px;
            }

            .card-content-container {
                align-items: center;
                width: 270px !important;
                display: flex !important;
                flex-direction: column;
                gap: 15px;

                .meditation-card {
                    position: relative;
                    height: 140px;
                    width: 270px;
                    border-radius: 42.977px 14.3257px 42.977px 14.3257px;

                    .watch-content-image {
                        height: 140px;
                        width: 270px;
                    }
                }

                .media-text {
                    width: 90%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .description-media-text {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .title-text {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 23.503px;
                    line-height: 28px;
                    color: ${COLORS.PRIMARY_WARM};
                }

                .description-text {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 500;
                    font-size: 17.9071px;
                    line-height: 27px;
                    color: ${COLORS.TEXT_DARK};
                    /* padding-left: 10px; */
                }

                .min-text {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13.4303px;
                    line-height: 23px;
                    color: ${COLORS.MEDIUM_GREY};
                }
            }
        }

        .read-box {
            width: 670px;
            display: flex;
            flex-direction: column;
            padding: 0 30px;
            margin-bottom: 8%;

            .title {
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                line-height: 40px;
                text-transform: uppercase;
                color: ${COLORS.PRIMARY_DARK};
                margin: 0 0 7px 16px;
            }
        }
    }

    .play-icon-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .footer-wrapper {
        position: sticky;
        bottom: 0;
        width: 100%;

        &.hide {
            display: none;
        }
    }
`;
