import styled from 'styled-components';
import { Box } from '@mui/material';
import { responsive, FONTS, COLORS } from 'Styles/Constants';

import HomePageBackgroundImage from 'Assets/images/HomePage/home-page-top-background-image.svg';

export const HomePageWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;

    /* max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden; */

    .full-width-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .top-bg-image {
            background-image: linear-gradient(0deg, #fff 1%, rgba(255, 255, 255, 0) 100%),
                url(${HomePageBackgroundImage});
            position: absolute;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 250px;

            ${responsive.TABLET`
                height: 180px;
            `}
        }

        .home-page-content {
            width: 45%;
            margin-top: 190px;

            ${responsive.DESKTOP`
                width: 60%;
            `}

            ${responsive.TABLET`
                width: 85%;
                margin-top: 100px;
            `}

            ${responsive.PHABLET`
                width: 100%;
                padding: 0 0 0 10px;
            `}

            .slider-content {
                position: relative;
                margin-bottom: 25px;

                &.how-to-meditate {
                    width: 80%;
                    margin: 0 auto;

                    ${responsive.LAPTOP`
                        width: 100%;
                    `}
                }

                .meditate-section {
                    height: 170px;
                }

                .no-content {
                    color: ${COLORS.TEXT_DARK};
                    font-size: 24px;
                    height: 149px;
                }
            }

            .slider-header {
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                font-size: 28px;

                ${responsive.PHABLET`
                    font-size: 24px;
                `}
            }
        }
    }

    .arrow {
        position: absolute;
        color: #000000;
        font-size: 2.5em;
        font-weight: bold;
        cursor: pointer;
        height: 50px;
        width: 50px;
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .nextArrow {
        top: 20%;
        right: -7%;

        ${responsive.LAPTOP`
            top: 20%;
            right: -9%;
        `}
    }

    .prevArrow {
        top: 30px;
        left: -7%;

        ${responsive.LAPTOP`
            top: 20%;
            left: -9%;
        `}
    }
`;

export const MyUpcomingSessionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
    /* width: 65%; */
    margin-bottom: 30px;

    ${responsive.DESKTOP`
        width: 60%;
        padding: 0px 10px 0px 10px;
    `}

    ${responsive.TABLET`
        width: 100%;
        padding: 0px 20px 0px 20px;
    `}

    .upcoming-sessions-featured-sessions-section {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        width: 100%;

        ${responsive.TABLET`
            flex-direction: column-reverse;
            justify-content: start;
            align-items: center;
        `}

        .calendar-content {
            width: 60%;

            ${responsive.TABLET`
                padding: 10px;
                width: 100%;
            `}

            .upcoming-session-title {
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;
                width: 100%;

                ${responsive.TABLET`
                    justify-content: space-between;
                `}

                .upcoming-session-text {
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    font-weight: 600;
                    font-size: 28px;

                    ${responsive.TABLET`
                        font-size: 24px;
                    `}
                }

                .upcoming-session-see-all {
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 400;
                    font-size: 16px;
                    margin-left: 20px;
                    color: #848484;
                    cursor: pointer;
                }
            }

            .upcoming-session-list {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .upcoming-session-detail {
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    align-items: center;
                    gap: 20px;

                    .upcoming-session-image {
                        position: relative;
                        display: inline-block;
                        align-self: flex-start;

                        .live-indicator {
                            position: absolute;
                            background-color: #bc1218;
                            color: #ffffff;
                            font-family: ${FONTS.PRIMARY_MEDIUM};
                            font-size: 12px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 98%;
                            top: 58px;
                            border-radius: 0px 0px 10px 10px;
                            margin-left: 1px;
                        }

                        .playlist-session {
                            position: absolute;
                            top: 5px;
                            left: 10px;
                        }
                    }

                    .upcoming-session-date-details {
                        flex: 60%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: start;

                        .MuiTypography-root {
                            font-family: ${FONTS.PRIMARY};
                            font-size: 14px;
                        }

                        .MuiTypography-root + .session-title {
                            font-family: ${FONTS.PRIMARY_MEDIUM};
                            font-size: 18px;
                        }
                    }

                    .upcoming-session-navigate {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        gap: 15px;
                    }
                }

                .view-all-sessions {
                    ${responsive.TABLET`
                        width: 100%;
                        display: flex;
                        justify-content: center;
                    `}

                    .MuiTypography-root {
                        font-family: ${FONTS.PRIMARY_MEDIUM};
                        font-size: 18px;
                        color: ${COLORS.PRIMARY_DARK};
                    }
                }
            }
        }

        .featured-sessions-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 40%;
            max-width: 265px;
            margin: 0 0 0 15px;

            ${responsive.TABLET`
                align-items: start;
                margin-bottom: 15px;
                max-width: 100%;
                width: 100%;
            `}

            .featured-session-header {
                display: flex;
                align-items: center;

                .featured-session-text {
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    font-weight: 600;
                    font-size: 28px;

                    ${responsive.TABLET`
                        margin-left: 0;
                        font-size: 24px;
                    `}
                }
                .featured-session-see-all {
                    margin: 0 0 0 auto;
                    font-size: 18px;
                    color: ${COLORS.MEDIUM_GREY};
                    font-weight: 400;
                    display: none;

                    ${responsive.TABLET`
                        display: block;
                    `}
                }
            }

            .featured-sessions-list {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                ${responsive.TABLET`
                    flex-direction: row;
                    justify-content: start;
                    align-items: center;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    overflow: scroll;
                `}

                .more-session {
                    margin: 0 0 0 auto;
                    font-size: 18px;
                    color: ${COLORS.MEDIUM_GREY};
                    font-weight: 400;

                    ${responsive.TABLET`
                        display: none;
                    `}
                }

                .featured-sessions-card {
                    height: 150px;
                    width: 100%;
                    margin: 0 0 10px 0;
                    position: relative;

                    ${responsive.TABLET`
                        max-width: 265px;
                        min-width: 265px;
                        margin: 0 10px 0 0;
                    `}

                    .featured-session-image {
                        border-radius: 10px;
                        background-position: 100% 100%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        height: 100%;
                        width: 100%;
                        border: 1px solid ${COLORS.LIGHT_GREY};
                    }

                    .session-sub-details {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        height: 56px;
                        width: 50px;
                        border-radius: 12px;
                        border: 2px solid #fff;
                        overflow: hidden;
                        position: absolute;
                        top: 4px;
                        right: 4px;

                        .week-day {
                            background-color: #ffffff;
                            font-weight: 400;
                            font-size: 13px;
                            width: 100%;
                            justify-content: center;
                            display: flex;
                            align-items: center;
                            color: ${COLORS.PRIMARY_DARK};
                            height: 30%;
                        }

                        .session-date-white {
                            background-color: #ffffff;
                            width: 100%;
                            justify-content: center;
                            display: flex;
                            align-items: center;
                            font-family: ${FONTS.PRIMARY_MEDIUM};
                            font-size: 21px;
                            font-weight: 500;
                            color: ${COLORS.PRIMARY_DARK};
                            height: 70%;
                        }

                        .live-text {
                            background-color: #bc1218;
                            color: #ffffff;
                            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                            font-size: 10px;
                            width: 100%;
                            justify-content: center;
                            display: flex;
                            align-items: center;
                            height: 30%;
                        }

                        .session-date {
                            background-color: #c3a157;
                            color: #ffffff;
                            width: 100%;
                            justify-content: center;
                            display: flex;
                            align-items: center;
                            font-family: ${FONTS.PRIMARY_MEDIUM};
                            font-size: 21px;
                            height: 70%;
                        }
                    }
                }
            }
        }
    }
`;
