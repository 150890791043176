import { Box, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS } from 'Styles/Constants';

export const PasswordStrengthWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .progress-bar-wrapper {
        width: 70%;
    }
    .password-strength-text-wrapper {
        display: flex;
        align-items: center;
        min-width: max-content;
        margin: 0 0 0 10px;

        .password-strength-text {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: ${COLORS.MEDIUM_GREY};
        }
    }
`;

export const LinearProgressWrapper = styled(LinearProgress)`
    .bg-color {
        background-color: ${(props) => props?.background};
    }
`;
