import styled from 'styled-components';
import { Box } from '@mui/system';
import { Dialog, Paper, TextField } from '@mui/material';

import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const QuestionnaireResultWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;

    .cloud {
        width: 50%;
        height: 50vh;
        position: absolute;
        z-index: 10;
        background-repeat: no-repeat;
        background-size: contain;

        &.cloud1 {
            left: 0;
            top: 10%;
            background-position: left;
        }

        &.cloud2 {
            left: 0;
            bottom: -15%;
            background-position: left;
        }

        &.cloud3 {
            right: 0;
            top: 30%;
            background-position: right;
        }
    }

    .result-page-container {
        position: relative;
        width: 550px;
        margin-top: 260px;
        background-color: transparent;
        z-index: 20;

        ${responsive.TABLET`
            margin: 200px 0 0 auto;
            width: 95%;
        `}

        ${responsive.PHABLET`
            margin: 180px 0 0 auto;
        `}

        .result-header-container {
            display: flex;
            margin: 0 0 50px 0;
            width: 100%;

            ${responsive.TABLET`
                margin: 0 0 30px 0;
            `}

            &.page-item {
                ${responsive.PHABLET`
                    margin-left: 0px;
                `}

                .subscription-info {
                    font-size: 19px;
                    color: #31290e;
                    ${responsive.PHABLET`
                        margin: auto;
                        max-width: 423px;
                    `}
                }
            }

            .header-text {
                margin-top: auto;
                font-size: 33px;
                font-family: ${FONTS.SECONDARY_BOLD};
                color: ${COLORS.PRIMARY_DARK};

                ${responsive.PHABLET`
                    font-size: 30px;
                `}
            }

            .lottie-animation {
                position: absolute;
                transform: translate(30%, -75%);
                right: 0;

                ${responsive.TABLET`
                    left:0;
                    transform: translate(-10%,-80%);
                `}

                ${responsive.PHABLET`
                    transform: translate(-14%, -70%);
                `}

                /* FORCE UPDATE FOR MASCOT LOTTIE FILE */
                svg {
                    width: 400px !important;
                    height: 400px !important;

                    ${responsive.TABLET`
                        width: 280px !important;
                        height: 280px !important;
                    `}

                    ${responsive.PHABLET`
                        width: 220px !important;
                        height: 220px !important;
                    `}
                }
            }
        }

        .container {
            background: rgba(242, 242, 242, 0.6);
            border-radius: 20px 0px 0px 20px;
            padding: 20px 0 20px 20px;
            margin: 20px 0 0;
            position: relative;

            .sub-title {
                font-size: 20px;
                color: ${COLORS.PRIMARY_WARM};
                margin: 0 0 15px 0;
                position: relative;
                z-index: 20;

                .bold {
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                }
            }

            .earth-wrapper {
                width: 100%;
                height: 224px;

                .earth-image-wrapper {
                    width: 100%;
                    height: 120px;
                    margin: 15px 0;

                    .earth-image {
                        display: flex;
                        justify-content: center;
                    }
                }

                .redirection-btn-wrapper {
                    margin: 0 0 20px 0;

                    .redirection-btn {
                        background: rgba(255, 255, 255, 0.9);
                        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
                        backdrop-filter: blur(10px);
                        border-radius: 12px;
                        height: 60px;
                        width: 350px;
                        color: ${COLORS.PRIMARY_WARM};
                        font-size: 16px;
                        text-transform: uppercase;
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};

                        ${responsive.MOBILE`
                            width: 200px;
                        `}
                    }
                }
            }
        }

        .free-trial-btn-container {
            display: flex;
            justify-content: center;
            margin: 20px 0 60px 0;
            padding: 0 0 0 20px;

            .free-trial-btn {
                width: 350px;

                ${responsive.MOBILE`
                    width: 200px;
                `}

                .free-trial-text {
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    font-size: 16px;
                }
            }
        }
    }
`;

export const HearAboutUsContentWrapper = styled(Box)`
    /* height: 100%; */
    width: 100%;
    padding: 70px 20px 20px 20px;
    overflow: hidden;
    position: relative;
    background: linear-gradient(180deg, #d6b978 0%, #987e43 100%);
    /* border: 1px solid red; */
    height: 800px;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 8px;
        background: rgba(101, 101, 101, 0.15);
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: rgba(162, 162, 162, 0.5);
    }

    .calligraphy-logo {
        z-index: 200;
        position: absolute;
        top: 100px;
        right: -75px;
        mix-blend-mode: multiply;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        opacity: 0.6;
        pointer-events: none;
    }

    .icon-button {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 8px;
        right: 8px;

        .cancel-icon {
            width: 40px;
            height: 40px;
        }
    }

    .text-box {
        font-family: ${FONTS.SECONDARY_BOLD};
        letter-spacing: -0.02em;
        color: ${COLORS.WHITE};
        font-size: 40px;
        margin-bottom: 27px;

        ${responsive.PHABLET`
            font-size: 30px;
        `}
        ${responsive.MOBILE`
            font-size: 24px;
        `}
    }

    .content-wrapper {
        /* width: 90%;
        margin-inline: auto;
        padding: 0 0 58px 0;
        min-height: 640px; */

        /* height: 65%; */
        overflow: auto;
        padding: 0 20px 20px 0;
        height: calc(100% - 150px);
        /* margin: 0 0 100px 0; */

        .radio-button-wrapper {
            display: flex;
            margin-bottom: 21px;

            .radio-button-group {
                .option {
                    display: block;
                    width: 100%;

                    .custom-radio {
                        padding-left: 0;
                    }

                    .radio-label {
                        color: ${COLORS.WHITE};
                        font-size: 22px;
                        line-height: 30px;

                        ${responsive.PHABLET`
                            font-size: 18px;
                        `}
                        ${responsive.MOBILE`
                            font-size: 16px;
                        `}
                    }
                }

                .radio-button.Mui-checked span {
                    border: 2px solid #bc9847;
                }
            }
        }
    }
    .continue-btn {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(10px);
        border-radius: 12px;
        height: 60px;
        width: 100%;
        color: ${COLORS.PRIMARY_WARM};
        font-size: 16px;
        text-transform: uppercase;
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        margin: 10px 0;
        /* position: absolute; */
        /* bottom: 20px; */
        /* left: 20px; */

        &:hover {
            background: rgba(255, 255, 255, 0.9);
        }
    }
`;
export const HearAboutUsDialog = styled(Dialog)`
    .paper {
        border-radius: 30px;

        ${responsive.PHABLET`
            border-radius: 0;
            width: 100%;
            height: 100%;
            max-height: 100%;
            margin: 0;
        `}
    }

    /* position: relative;
    overflow: hidden; */

    /* .continue-btn {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(10px);
        border-radius: 12px;
        height: 60px;
        width: calc(100% - 40px);
        color: ${COLORS.PRIMARY_WARM};
        font-size: 16px;
        text-transform: uppercase;
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        position: absolute;
        bottom: 20px;
        left: 20px;

        &:hover {
            background: rgba(255, 255, 255, 0.9);
        }
    } */
`;
export const CustomTextField = styled(TextField)`
    width: 100%;

    .outlined-root {
        background: rgba(255, 255, 255, 0.66);
        border: 1px solid rgba(132, 132, 132, 0.4);

        & fieldset {
            display: none;
        }

        &.Mui-focused {
            border: 1px solid ${COLORS.PRIMARY};
        }
    }

    .outlined-input {
        ::placeholder {
            color: rgba(49, 41, 14, 1);
        }
    }
`;

export const CustomRadioIcon = styled('span')(() => ({
    borderRadius: '50%',
    width: 24,
    height: 24,
    background: 'white',
    border: `2px solid ${COLORS.PRIMARY}`,

    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2
    }
}));

export const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
    '&:before': {
        display: 'block',
        width: 24,
        height: 24,
        backgroundImage: 'radial-gradient(#9C7F3C 45%, #CAA44D 48%, transparent 52%)',
        content: '""',
        position: 'absolute',
        left: '0px',
        top: '9px'
    }
});

export const CustomPaperComponent = styled(Paper)`
    width: 35%;
    background: linear-gradient(180deg, #d6b978 0%, #987e43 100%);
    border-radius: 30px !important;

    ${responsive.LAPTOP`
       width:45%
    `}
    ${responsive.TABLET`
       width:60%
    `}
    ${responsive.PHABLET`
       width:80%
    `}
    ${responsive.MOBILE`
       width:100%;
       height:100%;
       border-radius:0px !important;
       margin:0 !important;
       max-height:none !important;
       max-width:none !important;
    `}
`;
