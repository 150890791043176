import { TextField } from '@mui/material';
import React from 'react';
import { TextFieldWrapper } from './index.style';

const TaoTextField = ({ ...props }) => {
    return (
        <TextFieldWrapper>
            <TextField className="text-field" {...props} />
        </TextFieldWrapper>
    );
};

export default TaoTextField;
