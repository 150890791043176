import styled from 'styled-components';
import { Box, Popover } from '@mui/material';
import { responsive, FONTS, COLORS, BREAKPOINTS_VALUE } from 'Styles/Constants';

export const WellnessCategoryWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 5%;

    .black-background {
        bottom: 0;
    }

    ${responsive.PHABLET`
        padding: 20px;
    `}
    ${responsive.MOBILE`
        padding: 10px;
    `}
`;

export const TopGirlImageWrapper = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    width: 45%;
    /* padding: 0 5% 0 0; */
    z-index: 10;

    ${responsive.DISPLAY`
        width: 60%;
    `}

    ${responsive.DESKTOP`
        width: 70%;
    `}

    ${responsive.LAPTOP`
        width: 80%;
    `}

    ${responsive.TABLET`
        flex-direction: column-reverse;
        align-items: start;
        width: 95%;
        padding: 50px 0;
    `}

    .girl-image {
        background-position: 100% 100%;
        background-repeat: no-repeat;
        min-height: 250px;
        min-width: 380px;
        transform: translate(0, 9%);
        background-size: contain;

        ${responsive.TABLET`
            min-width: 400px;
            margin: 0 0 0 auto;
            min-height: 300px;
            transform: translate(0,-8%);
        `}

        ${responsive.PHABLET`
            min-width: 100%;
            height: 350px;
            background-position: center;
            transform: translate(0,0);
        `}
        ${responsive.MOBILE`
            height: 320px;
        `}
    }

    .category-title {
        width: 500px;
        gap: 5px;
        display: flex;
        justify-content: end;
        align-items: center;
        overflow: hidden;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 0 3px 0;

        ${responsive.TABLET`
            width: 300px;
            justify-content: start;
        `}

        .welcome-to-text {
            font-family: ${FONTS.SECONDARY_BOLD};
            font-size: 36px;
            line-height: initial;
            color: ${COLORS.PRIMARY_DARK};

            ${responsive.PHABLET`
                font-size: 24px;
            `}
        }

        .health-text {
            font-family: ${FONTS.SECONDARY_BOLD};
            color: ${COLORS.PRIMARY_DARK};
            font-size: 48px;
            line-height: initial;
            text-transform: capitalize;
            text-align: end;

            ${responsive.PHABLET`
                font-size: 38px;
                text-align: start;
            `}
        }
    }
`;

export const MainContentWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    z-index: 20;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px 15px 15px 15px;
    height: auto;
    width: 45%;
    /* width: 60%; */
    padding: 5%;
    margin-bottom: 20px;

    ${responsive.DISPLAY`
        width: 60%;
    `}

    ${responsive.DESKTOP`
        width: 70%;
    `}

    ${responsive.LAPTOP`
        width: 80%;
    `}

    ${responsive.TABLET`
        background-color: transparent;
        width: 90%;
        box-shadow: none;
        border-radius: none;
        padding: 0;
    `}

    ${responsive.PHABLET`
        width: 95%;
    `}

    .arrow {
        position: absolute;
        color: #000000;
        font-size: 2.5em;
        font-weight: bold;
        cursor: pointer;
        height: 50px;
        width: 50px;
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .nextArrow {
        top: 20%;
        right: -9%;

        ${responsive.LAPTOP`
            top: 20%;
            right: -7%;
        `}
    }

    .prevArrow {
        top: 20%;
        left: -9%;

        ${responsive.LAPTOP`
            top: 20%;
            left: -7%;
        `}
    }

    .program-list-loader-wrapper {
        width: 240px;
        height: 149px;
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
    }
`;

export const ProgramsSectionWrapper = styled(Box)`
    width: 100%;
    position: relative;
    gap: 10px;
    margin: 0 0 50px;
    display: ${(props) => (props?.$programAvailable ? 'initial' : 'none')};
`;

export const ProgramSectionTitleWrapper = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    /* padding-left: 15px; */

    ${responsive.PHABLET`
        justify-content: space-between;
    `}

    .programs-text {
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        font-weight: 600;
        font-size: 28px;

        ${responsive.TABLET`
            font-size: 24px;
        `}
    }

    .programs-see-all {
        font-family: ${FONTS.PRIMARY};
        font-size: 16px;
        margin: 0 0 0 auto;
        color: ${COLORS.MEDIUM_GREY};
        cursor: pointer;
        z-index: 20;
        position: relative;
    }
`;

export const TodaysAndFeaturedSessionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    margin: 30px 0;

    ${responsive.DISPLAY`
        width: 100%;
    `}
`;

export const UpcomingSessionFeaturedSessionWrapper = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 60px;

    ${responsive.DESKTOP`
        width: 100%;
        gap: 20px;
    `}

    ${responsive.PHABLET`
        flex-direction: column-reverse;
        justify-content: start;
        align-items: center;
        gap: 0px;
    `}
`;

export const CalendarContentWrapper = styled(Box)`
    flex: 70%;

    ${responsive.PHABLET`
        width: 90%;
    `}

    .upcoming-session-title {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        width: 100%;

        /* ${responsive.DISPLAY`
            width: 100%;
        `} */

        ${responsive.PHABLET`
            justify-content: space-between;
        `}

        .upcoming-session-text {
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            font-weight: 600;
            font-size: 28px;

            ${responsive.TABLET`
                font-size: 24px;
            `}
        }

        .upcoming-session-see-all {
            font-family: ${FONTS.PRIMARY};
            font-weight: 400;
            font-size: 16px;
            margin-left: 20px;
            color: #848484;
            margin-top: 5px;
            cursor: pointer;

            ${responsive.PHABLET`
                width: 22%;
            `}
        }
    }
`;

export const UpcomingSessionListWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;

    .upcoming-session-detail {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        gap: 20px;

        padding-bottom: 8px;
        border-bottom: 1px solid #e8e8e8;

        ${responsive.PHABLET`
           border-bottom: none;
        `}

        .upcoming-session-image {
            position: relative;
            display: inline-block;

            .live-indicator {
                position: absolute;
                background-color: #bc1218;
                color: #ffffff;
                font-family: ${FONTS.PRIMARY_MEDIUM};
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 98%;
                top: 58px;
                border-radius: 0px 0px 10px 10px;
                margin-left: 1px;
            }

            .dark-background {
                /* background-color: #c4c4c4;
                mix-blend-mode: multiply; */
                background-color: rgba(0, 0, 0, 0.3);
                border-radius: 12px;
                height: 75px;
                width: 75px;
                left: 0;
                top: 0;
                position: absolute;

                .playlist-session {
                    position: absolute;
                    top: 4px;
                    left: 12px;
                    height: 55px;
                    width: 55px;
                }
            }
        }

        .upcoming-session-date-details {
            flex: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;

            .MuiTypography-root {
                font-family: ${FONTS.PRIMARY};
                font-size: 14px;
            }

            .session-title {
                font-family: ${FONTS.PRIMARY_MEDIUM};
                font-size: 18px;
            }
        }

        .upcoming-session-navigate {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 15px;
        }
    }

    /* .view-all-sessions {
        ${responsive.PHABLET`
            width: 100%;
            display: flex;
            justify-content: center;
        `}

        .MuiTypography-root {
            font-family: ${FONTS.PRIMARY_MEDIUM};
            font-size: 18px;
            color: ${COLORS.PRIMARY_DARK};
        }
    } */
`;

export const FeaturedSessionContentWrapper = styled(Box)`
    flex: 30%;
    /* border: 2px solid #000; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    ${responsive.TABLET`
        align-items: start;
        margin-bottom: 15px;
    `}

    ${responsive.PHABLET`
        max-width: 100%;
        margin-bottom: 0;
    `}

    .featured-session-text {
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        font-weight: 600;
        font-size: 28px;
        /* margin-left: -25px; */

        ${responsive.TABLET`
            margin-left: 0;
            font-size: 24px;
        `}
    }
`;

export const FeaturedSessionListWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    /* ${responsive.TABLET`
        flex-direction: row;
        justify-content: start;
        align-items: center;

        margin-top: 10px;
        margin-bottom: 10px;
    `} */

    ${responsive.PHABLET`
        max-width: inherit;
        overflow-x: scroll;

        flex-direction: row;
        justify-content: start;
        align-items: center;

        margin-top: 10px;
        margin-bottom: 10px;
    `}

    .featured-sessions-card {
        border-radius: 10px;
        min-width: 265px;

        ${responsive.PHABLET`
            // margin-top: 10px;
            margin-bottom: 10px;
        `}

        .MuiCardContent-root {
            padding: 0;
            position: relative;
        }

        .featured-session-image {
            position: relative;
            display: inline-block;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: inline-block;
                /* background: linear-gradient(237.84deg, #c4c4c4 0%, rgba(196, 196, 196, 0) 69.3%); */
                background: linear-gradient(237.84deg, #c4c4c4 0%, rgba(196, 196, 196, 0) 49.3%);
            }

            img {
                display: block;
            }
        }

        .session-sub-details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 56px;
            width: 50px;
            border-radius: 12px;
            border: 2px solid #fff;
            overflow: hidden;

            position: absolute;
            top: 4px;
            right: 4px;

            .week-day {
                flex: 50%;
                background-color: #ffffff;
                font-weight: 400;
                font-size: 13px;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
                color: ${COLORS.PRIMARY_DARK};
            }

            .session-date-white {
                flex: 50%;
                background-color: #ffffff;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
                font-family: ${FONTS.PRIMARY_MEDIUM};
                font-size: 21px;
                font-weight: 500;
                color: ${COLORS.PRIMARY_DARK};
            }

            .live-text {
                flex: 30%;
                background-color: #bc1218;
                color: #ffffff;
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                font-size: 10px;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
            }

            .session-date {
                flex: 70%;
                background-color: #c3a157;
                color: #ffffff;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
                font-family: ${FONTS.PRIMARY_MEDIUM};
                font-size: 21px;
            }
        }
    }
`;

export const MeditationSessionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;

    .meditations-header-section {
        display: flex;
        flex-direction: column;

        .meditation-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 8px;

            .meditations-text {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 24px;
                color: ${COLORS.PRIMARY_DARK};

                ${responsive.PHABLET`
                    font-size: 18px;
                `}
            }

            .filters-button {
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                font-size: 18px;
                border-radius: 23px;
                width: max-content;
                height: 46px;
                padding: 5px 24px;
                text-transform: capitalize;
                background: ${COLORS.WHITE};
                border: 1px solid ${COLORS.LIGHT_GREY};
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                color: ${COLORS.BLACK};

                &.apply-filter-color {
                    color: ${COLORS.WHITE};
                    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.25);
                    background: ${COLORS.PRIMARY};
                }
            }
        }

        .sub-category-main-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;
            margin: 0px 0px 10px 0px;

            &.hide-section {
                display: none;
            }

            .sub-category-header-wrapper {
                .sub-category-header {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 40px;
                    text-transform: capitalize;
                    color: ${COLORS.TEXT_DARK};

                    &.light {
                        font-weight: 400;
                        font-style: normal;
                        font-size: 14px;
                        line-height: 20px;
                        margin-left: 6px;
                        text-transform: lowercase;
                    }
                }
            }
            .sub-category-wrapper {
                display: flex;
                flex-direction: row;
                gap: 10px;
                width: 100%;

                .sub-category-image-with-title {
                    width: 76px;
                    cursor: pointer;
                    margin: 0 6px;

                    img.sub-category-image {
                        height: 74px;
                        width: 74px;

                        &.isSelected {
                            filter: opacity(0.5) drop-shadow(0 0 0 red);
                        }
                    }

                    .sub-category-title {
                        width: 80px;
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        text-transform: capitalize;
                        text-align: center;
                        color: ${COLORS.TEXT_DARK};
                        overflow: hidden;
                        word-wrap: break-word;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;

                        /* &.isSelected {
                            font-family         : ${FONTS.QUATERNARY};
                        } */
                    }
                }
            }
        }

        .total-results {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            margin: 5px 0;
            align-items: flex-start;

            ${responsive.PHABLET`
                width: 70%;
                flex-wrap: wrap;
            `}

            .meditations-result-text {
                font-family: ${FONTS.PRIMARY};
                font-size: 18px;
                line-height: 26px;
                color: ${COLORS.MEDIUM_GREY};

                .total-count {
                    font-size: 20px;
                    font-weight: 700;
                    color: ${COLORS.MEDIUM_GREY};
                }

                .text {
                    font-weight: 400;
                    font-size: 18px;
                    color: ${COLORS.MEDIUM_GREY};
                }

                .category-text {
                    font-weight: 500;
                    font-size: 18px;
                    color: ${COLORS.MEDIUM_GREY};
                }
            }

            .min-width-max-content {
                min-width: max-content;
            }

            span {
                color: ${COLORS.PRIMARY};
                margin: 2px 0;
            }

            .meditations-result-breadcrumb-text {
                font-family: ${FONTS.PRIMARY};
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
                color: ${COLORS.PRIMARY};
            }
        }

        .clear-filters-button-section {
            margin-top: 15px;

            .clear-filters-button {
                font-family: ${FONTS.PRIMARY};
                font-size: 16px;
                line-height: 23px;
                color: ${COLORS.PRIMARY_WARM};
                letter-spacing: 0.8px;
                width: 369px;
                height: 60px;
                background-color: rgba(255, 255, 255, 0.9);
                box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
                backdrop-filter: blur(20px);
                border-radius: 12px;
            }
        }
    }

    .meditation-contents {
        display: flex;
        flex-direction: column;
        position: relative;

        .category-see-all {
            font-family: ${FONTS.PRIMARY};
            font-size: 16px;
            margin: 0 0 0 auto;
            color: ${COLORS.MEDIUM_GREY};
            cursor: pointer;
            z-index: 30;
            position: relative;
        }

        .content-type-container {
            display: flex;
            align-items: center;

            .content-type-text {
                font-family: ${FONTS.PRIMARY};
                font-size: 18px;
                line-height: 26px;
                color: ${COLORS.MEDIUM_GREY};
                font-weight: 500;

                ${responsive.PHABLET`
                    font-size: 18px;
                `}
            }
        }

        .card-list-container {
            .meditation-contents-list-loader-wrapper {
                width: 240px;
                height: 149px;
                display: flex !important;
                flex-direction: column;
                justify-content: space-between;
            }

            .placeholder-box-wrapper {
                display: flex !important;
                justify-content: center;
                align-items: center;
                width: 210px !important;
                height: 120px !important;
                border-radius: 16px 8px;
                background: #f6f6f6;

                .placeholder-box-text {
                    font-family: ${FONTS.PRIMARY};
                    font-size: 16px;
                    line-height: 18px;
                    text-align: center;
                    color: ${COLORS.MEDIUM_GREY};
                }
            }
        }

        .load-more-button-container {
            height: 120px;
            display: flex;
            padding: 0 15px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            z-index: 30;

            &:hover {
                .next-arrow-icon {
                    transition: all 0.2s ease-in-out;
                    margin: 0 0 0 7px;
                }
            }

            .load-more {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 16px;
                color: ${COLORS.PRIMARY};
                cursor: pointer;
                width: max-content;

                ${responsive.PHABLET`
                    width: 22%;
                `}
            }
            .next-arrow-icon {
                margin: 0 0 0 5px;
            }
        }
    }
`;

export const FieldHealingSectionWrapper = styled(Box)`
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    gap: 20px;

    ${responsive.TABLET`
        flex-direction: column;
        background-color: transparent;
        border-radius: 25px;
    `}

    ${responsive.PHABLET`
        flex-direction: column;
        background-color: transparent;
        border-radius: 25px;
    `}

    .field-healing-image-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .field-healing-image-details {
            display: flex;
            flex-direction: column;

            width: 350px;
            background: linear-gradient(180deg, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%);
            border: 1.69384px solid #ffffff;
            filter: drop-shadow(0px 2.95072px 25px rgba(0, 0, 0, 0.15));
            border-radius: 25px;
            overflow: hidden;

            ${responsive.PHABLET`
                width: 100%;
                background-color: none;
                border: none;
                filter: none;
                border-radius: none;
            `}

            .field-healing-text {
                display: flex;
                align-items: start;
                width: 100%;

                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 20px;
                color: ${COLORS.PRIMARY_DARK};
                margin-left: 15px;
                margin-top: 15px;
            }

            .slider-content {
                position: relative;
                /* margin-bottom: 50px; */

                ${responsive.PHABLET`
                    margin-bottom: 30px;
                `}

                .goal-chip {
                    padding: 8px;
                    /* margin: 3px 5px; */
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    /* line-height: 26px; */
                    height: 36px;
                    border-radius: 26px;
                    text-transform: uppercase;
                    border: 2px solid transparent;
                    margin: 10px 0;

                    ${responsive.PHABLET`
                        width: 90% !important;
                    `}

                    @media only screen and (max-width: ${BREAKPOINTS_VALUE.DISPLAY}px) {
                        margin: 10px;
                    }

                    &.non-selected-goal {
                        border: 2px solid ${COLORS.PRIMARY};
                        background-color: rgba(255, 255, 255, 0.6);
                        &:hover {
                            background-color: transparent;
                        }

                        .MuiChip-label {
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }

                    &.selected-goal {
                        color: white;

                        .MuiChip-label {
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }

                    ${responsive.DISPLAY`
                        font-size: 16px;
                    `}

                    ${responsive.PHABLET`
                        display: none;
                    `}
                }
            }

            /* .slick-track {
                ${responsive.DESKTOP`
                    width: 1025px !important;
                `}
            } */

            /* .slick-slider {
                overflow: hidden;

                ${responsive.PHABLET`
                    margin-left: 15px;
                `}
            } */

            /* .slick-center {
                transform: scale(1.2);
            } */

            /* .slick-slide {
                display: none;
                float: left;
                height: 100%;
                min-height: 1px;
                width: auto !important;
                padding-right: 35px;
            } */

            /* .slick-initialized .slick-slide {
                display: block;
            } */

            .body-section {
                width: 100%;
                padding-top: 10px;
                display: flex;
                flex-direction: row;
                position: relative;

                ${responsive.PHABLET`
                    padding: 0;
                    justify-content: center;
                `}

                .body-section-parent-div {
                    ${responsive.PHABLET`
                        width: 100%;
                    `}

                    svg {
                        ${responsive.PHABLET`
                            width: 100%;
                        `}
                    }
                }

                .selection-count {
                    color: ${COLORS.GREY_TEXT_COLOR};
                    font-size: 14px;
                    padding-bottom: 20px;
                }

                .svg-div {
                    margin-top: -5px;
                    cursor: pointer;

                    ${responsive.PHABLET`
                        margin-top: 15px;
                    `}
                }

                /* .slick-list {
                    max-height: 300px !important;
                }

                .slick-slide {
                    height: auto;
                } */
            }

            .selection-div {
                width: 100px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                overflow: hidden;

                /* .slick-track {
                    width: 100px !important;
                    display: flex;
                    flex-direction: column;

                    .slick-slide {
                        width: 100px !important;
                        padding: 0;
                    }
                } */
            }

            .selection-div.slick-vertical > .slick-list {
                height: 100% !important;
            }

            .selection-item {
                font-size: 16px;
                color: ${COLORS.PRIMARY};
                padding: 12px 0;
                cursor: pointer;
            }

            .p-t {
                padding-top: 30px;
            }

            .selection-container {
                display: flex;
                flex-direction: column;
                position: absolute;
                /* left: -85px; */
                left: 240px;
                top: 50px;

                ${responsive.PHABLET`
                   left: 380px;
                `}

                ${responsive.PHABLET_500`
                   left: 340px;
                `}

                ${responsive.PHABLET_500`
                   left: 300px;
                `}
            }
        }
    }

    .field-healing-text-section {
        flex: 50%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;

        ${responsive.PHABLET`
            margin-top: 20px;
            margin-bottom: 20px;
        `}

        .field-healing-text {
            display: flex;
            flex-direction: column;
            align-items: start;

            /* width: 306px; */
            width: 350px;

            ${responsive.LAPTOP`
                width: 100%;
            `}

            ${responsive.PHABLET`
                width: 85%;
            `}

            .need-support-text {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 22px;
                color: ${COLORS.TEXT_DARK};
            }

            .you-have-us {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 24px;
                color: ${COLORS.PRIMARY_DARK};
            }

            .text-details {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 18px;
                color: ${COLORS.PRIMARY_WARM};
            }

            .book-consultation-button {
                background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                color: #ffffff;
                border-radius: 12px;
                width: 100%;
                margin: 30px 0 0 0;
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                letter-spacing: 0.05em;
                text-transform: uppercase;
            }
        }
    }
`;

export const MeditationFilterPopoverWrapper = styled(Popover)`
    .paper {
        border-radius: 0px 14px 14px 14px;
        width: 380px;
        padding: 20px;
        height: max-content;
        background: rgba(255, 255, 255, 0.9);

        ${responsive.MOBILE`
            width: 90%;
        `}
    }
    .popover-container {
        .popover-header-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 0px 0px 20px 0px;

            .icon-wrapper {
                .refresh-icon {
                    height: 22px;
                    width: 22px;
                    cursor: pointer;
                }

                .close-icon {
                    height: 22px;
                    width: 22px;
                    cursor: pointer;
                }
            }

            .header-title-wrapper {
                .header-title-text {
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    font-size: 28px;
                    text-transform: capitalize;
                }
            }
        }

        .tags-main-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;

            .tags-title-wrapper {
                .tags-title {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 40px;
                    text-transform: capitalize;
                    color: ${COLORS.TEXT_DARK};

                    &.light {
                        font-weight: 400;
                        font-style: normal;
                        font-size: 14px;
                        line-height: 20px;
                        margin-left: 6px;
                        text-transform: lowercase;
                    }
                }
            }

            .tags-chips-wrapper {
                width: 350px;
                display: flex;
                max-height: 250px;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;
                overflow-x: auto;
                padding: 0 0 10px;

                ${responsive.MOBILE`
                    width: 90%;
                `}

                .tag-chip {
                    height: 46px;
                    background: #ffffff;
                    border: 2px solid ${COLORS.PRIMARY};
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 20px;
                    gap: 10px;
                    border-radius: 26px;
                    cursor: pointer;

                    &.isSelected {
                        background: ${COLORS.PRIMARY};
                        border: 2px solid white;
                        cursor: pointer;
                    }

                    .tag {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 26px;
                        text-transform: capitalize;
                        color: ${COLORS.TEXT_DARK};

                        &.isSelected {
                            color: white;
                        }
                    }
                }
            }
        }

        .placeholder-header-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;

            .placeholder-header {
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 40px;
                text-transform: capitalize;
                color: ${COLORS.TEXT_DARK};
            }
        }

        .footer-btn-container {
            border-top: 1px solid ${COLORS.LIGHT_GREY};
            padding: 20px 0px 0px 0px;
            .footer-btn {
                background: ${COLORS.PRIMARY};
                border-radius: 8px;
                color: white;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px 30px;
                width: 100%;
                gap: 10px;

                .footer-btn-text {
                    font-family: ${FONTS.TERTIARY};
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 18px;
                    text-align: center;
                    color: #ffffff;
                }
            }
        }
    }
`;
