import { Box } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import styled from 'styled-components';

const PADDING_DELETE_ICON = '20px';

export const YourCartWrapper = styled(Box)`
    .title {
        font-size: 40px;
        font-family: ${FONTS.SECONDARY_BOLD};
        color: ${COLORS.PRIMARY_DARK};

        ${responsive.TABLET`
            font-size: 38px;
        `}
        ${responsive.PHABLET`
            font-size: 32px;
        `}
        ${responsive.MOBILE`
            font-size: 28px;
        `}
    }

    .container {
        margin: 20px 0 0;
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 16px 0px #00000026;
        background-color: ${COLORS.WHITE};
        border-radius: 30px 30px 0 0;
        padding: ${(props) => (props?.$cartHasItem ? '20px' : '20px 0 20px 20px')};

        ${responsive.PHABLET`
            box-shadow: none;
            padding: ${(props) => (props?.$cartHasItem ? '20px 0' : '20px 0 20px 20px')};
            margin: 0;
        `}

        .sub-title {
            font-size: 24px;
            color: ${COLORS.MEDIUM_GREY};
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};

            ${responsive.PHABLET`
                font-size: 22px;
            `}
        }

        .filters {
            margin: 10px 0 20px;
            gap: 10px;

            .filter-btn {
                height: 37px;
                border: 2px solid ${COLORS.LIGHT_GREY};
                border-radius: 25px;
                text-transform: capitalize;
                color: ${COLORS.TEXT_DARK};
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                padding: 0 20px;

                ${responsive.PHABLET`
                    padding: 0 15px;
                `}
            }

            .btn-disabled {
                opacity: 50%;
            }
        }

        .scrollable-area {
            height: calc(100% - 225px);
            overflow: auto;
            ::-webkit-scrollbar {
                display: none;
            }

            .swipe-to-delete {
                width: 100%;
            }

            .cart-item {
                gap: 20px;
                position: relative;

                ${responsive.PHABLET`
                    gap: 10px;
                `}

                .checkbox-root {
                    padding: 0;
                }

                .item {
                    width: 100%;
                    gap: 20px;
                    overflow: hidden;
                    padding: 15px 0;
                    position: relative;

                    ${responsive.PHABLET`
                        gap: 10px;
                    `}

                    .divider {
                        height: 1px;
                        width: 100%;
                        background-color: ${COLORS.MEDIUM_GREY};
                        position: absolute;
                        bottom: 0;
                        opacity: 40%;
                    }
                    .image {
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 130px;
                        height: 130px;
                        border-radius: 10px;
                        overflow: hidden;
                        position: relative;
                        min-width: 130px;

                        ${responsive.TABLET`
                            width: 100px;
                            height: 100px;
                            min-width: 100px;
                        `}

                        ${responsive.PHABLET`
                            width: 80px;
                            height: 80px;
                            min-width: 80px;
                        `}

                        .duration {
                            background-color: ${COLORS.PRIMARY_LIGHT};
                            color: ${COLORS.BLACK};
                            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                            font-size: 14px;
                            position: absolute;
                            width: 100%;
                            text-transform: uppercase;
                            text-align: center;
                            bottom: 0;
                        }
                    }

                    .item-info {
                        width: 60%;
                        .cart-item-title {
                            font-size: 18px;
                            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                            color: ${COLORS.BLACK};
                            max-width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            text-transform: capitalize;

                            ${responsive.TABLET`
                                font-size: 16px;
                            `}
                            ${responsive.PHABLET`
                                font-size: 14px;
                            `}
                        }

                        .price {
                            font-size: 24px;
                            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                            color: ${COLORS.MEDIUM_GREY};

                            ${responsive.TABLET`
                                font-size: 22px;
                            `}
                            ${responsive.PHABLET`
                                font-size: 18px;
                            `}
                        }

                        .delivery {
                            font-size: 18px;
                            color: ${COLORS.MEDIUM_GREY};

                            ${responsive.TABLET`
                                font-size: 16px;
                            `}
                            ${responsive.PHABLET`
                                font-size: 14px;
                            `}
                        }
                    }

                    .delete-icon {
                        margin: 0 0 0 auto;
                        background-color: ${COLORS.LIGHT_GREY_SECONDARY};
                        height: 37px;
                        width: 37px;
                        border-radius: 50%;
                        padding: 8px;
                    }
                }

                .delete-swipe-content {
                    width: 100%;
                    position: absolute;
                    background-color: red;
                    height: 100%;
                    right: calc(-100% - ${PADDING_DELETE_ICON});

                    .delete-icon {
                        width: calc(100px - ${PADDING_DELETE_ICON});
                        height: 100%;
                    }
                }
            }

            .item-total {
                width: 80%;
                border-top: 1px solid ${COLORS.MEDIUM_GREY};
                margin: 20px 45px 0px;
                padding: 20px 0;

                ${responsive.PHABLET`
                    margin: 20px 0;
                    width: 100%;
                `}

                .key {
                    color: ${COLORS.MEDIUM_GREY};
                    font-size: 18px;
                    text-transform: uppercase;
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};

                    ${responsive.TABLET`
                        font-size: 16px;
                    `}
                    ${responsive.PHABLET`
                        font-size: 14px;
                    `}
                }
                .value {
                    color: ${COLORS.PRIMARY_DARK};
                    font-size: 24px;
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};

                    ${responsive.TABLET`
                        font-size: 22px;
                    `}
                    ${responsive.PHABLET`
                        font-size: 18px;
                    `}

                    .or {
                        font-size: 20px;
                        color: ${COLORS.MEDIUM_GREY};
                        font-family: ${FONTS.PRIMARY};

                        ${responsive.TABLET`
                            font-size: 18px;
                        `}
                        ${responsive.PHABLET`
                            font-size: 16px;
                        `}
                    }
                }

                .reward-points {
                    background-color: #d4fac7;
                    padding: 5px 20px;
                    font-size: 18px;
                    border-radius: 12px;
                    margin: 20px 0;

                    ${responsive.TABLET`
                        font-size: 16px;
                    `}

                    &.not-enough-points {
                        background: #ffd7d7;
                    }
                }
            }

            .empty-cart {
                .empty-cart-title {
                    font-size: 18px;
                    color: ${COLORS.BLACK};
                }
                .divider {
                    height: 2px;
                    background-color: ${COLORS.MEDIUM_GREY};
                    width: 100%;
                    opacity: 40%;
                    margin: 20px 0;
                }
            }
        }

        .checkout-button {
            width: 100%;
            background-color: ${COLORS.PRIMARY};
            height: 60px;
            color: ${COLORS.WHITE};
            border-radius: 12px;
            font-size: 16px;
            font-family: ${FONTS.PRIMARY_BOLD};
        }

        .arrow {
            height: 10px;
            width: 10px;
            border-top: 2px solid ${COLORS.WHITE};
            border-right: 2px solid ${COLORS.WHITE};
            transform: rotate(45deg);
        }
    }
`;
