import React, { useState, useEffect } from 'react';

import { Typography, IconButton, Box, Button, Backdrop, Slide } from '@mui/material';
import Lottie from 'lottie-react';

import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { BackButton } from 'Helpers/CommonSVGs';

import CountDownTimer from '../../../../common/CountTimers/CountdownTimer';
import CountUpTimer from '../../../../common/CountTimers/CountupTimer';
import Footer from 'Components/common/Footer/Footer';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';

import { ChatSection } from '../AudioSessionChat';
import { CustomParticipantsView } from '../AudioSessionParticipantView';
import { AddParticipantToSessionModal } from '../AddParticipantToSessionModalView';

import {
    AudioSessionHostViewWrapper,
    EndSessionModalWrapper,
    FooterContentWrapper
} from './HostView.style';

import AudioSessionHostImage from 'Assets/images/Profile/default-user-icon.jpg';
import UsersIcon from 'Assets/images/MeditationRoomSession/AudioSession/users-icon.png';
import StopSessionIcon from 'Assets/images/MeditationRoomSession/AudioSession/stop-session-icon.svg';
import PlaySessionIcon from 'Assets/images/MeditationRoomSession/AudioSession/play-session-icon.svg';

import HeartWaveform from 'Assets/AnimationFiles/heart-waveform-animated-loop.json';
import {
    AddToFavorite,
    AddUserIcon,
    EditSessionIcon,
    ShareIcon,
    BlurredBigMuteIcon,
    BigMuteIcon,
    ParticipantListHostIcon
} from '../AudioSessionSVG';
import { ClearButtonIcon } from '../../AddEditSession/AddEditSessionSVG';

import { useDispatch } from 'react-redux';
import { showTopBar, hideTopBar, showFooter, hideFooter } from 'Redux/App/Actions';
import { showSideBar, hideSideBar } from 'Redux/Sidebar/Actions';

import { getWindowDimensions } from 'Helpers';

const AudioSessionHostView = (props) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const dispatch = useDispatch();

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);

            dispatch(showSideBar());
            dispatch(showTopBar());
            dispatch(showFooter());
        };
    }, [dispatch]);

    useEffect(() => {
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            dispatch(hideSideBar());
            dispatch(hideTopBar());
            dispatch(hideFooter());
        }
    }, [dispatch, windowDimensions]);

    // const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
    const THREE_DAYS_IN_MS = 20 * 1000;
    const NOW_IN_MS = new Date().getTime();

    const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
    const [targetTime] = useState(new Date(dateTimeAfterThreeDays));

    const [sessionMainState, setSessionMainState] = useState('in-sesssion');
    const [sessionState, setSessionState] = useState('not-prepared-to-start');
    const [showCountdownTimer, setShowCountdownTimer] = useState(true);
    const [openChatModal, setOpenChatModal] = useState(false);
    const [openEndSessionModal, setOpenEndSessionModal] = useState(false);
    const [openAddParticipantToSessionModal, setOpenAddParticipantToSessionModal] = useState(false);
    const [openParticipantListModal, setOpenParticipantListModal] = useState(false);

    const joinSessionNow = (sessionStateToUpdate) => {
        setSessionState(sessionStateToUpdate);

        if (sessionStateToUpdate === 'ready-to-start-session') {
            setShowCountdownTimer(false);
        }
    };

    const toggleOpenChatModal = (value) => {
        setOpenChatModal(value);
    };

    const toggleEndSessionModalHandler = (value, mainState) => {
        setOpenEndSessionModal(value);

        if (mainState && mainState === 'session-end-view') {
            setSessionMainState(mainState);
        }
    };

    const toggleAddParticipantToSessionModalHandler = (value) => {
        setOpenAddParticipantToSessionModal(value);
    };

    const toggleParticipantListModal = (value) => {
        setOpenParticipantListModal(value);
    };

    return (
        <>
            <TaoCalligraphyWatermark />
            <AudioSessionHostViewWrapper>
                <Box className="left-side-gradient" />
                {sessionMainState === 'in-sesssion' && (
                    <Box className="host-and-session-details">
                        {windowDimensions.width <= BREAKPOINTS_VALUE.TABLET && (
                            <Box className="back-button">
                                <BackButton />
                            </Box>
                        )}

                        <Box className="session-details-section">
                            <Box className="host-details">
                                {sessionState === 'not-prepared-to-start' && (
                                    <img
                                        className="host-image"
                                        src={AudioSessionHostImage}
                                        alt=""
                                        height={
                                            windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET_500
                                                ? 120
                                                : 165
                                        }
                                        width={
                                            windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET_500
                                                ? 120
                                                : 165
                                        }
                                    />
                                )}

                                {(sessionState === 'prepared-to-start' ||
                                    sessionState === 'ready-to-start-session') && (
                                    <Button>
                                        <BlurredBigMuteIcon />
                                    </Button>
                                )}

                                {sessionState === 'start-session' && (
                                    <Button>
                                        <BigMuteIcon />
                                    </Button>
                                )}

                                <Box className="session-details">
                                    <Typography className="session-title">
                                        Breathwork for a Clear Mind
                                    </Typography>

                                    {sessionState === 'not-prepared-to-start' ? (
                                        <>
                                            <Box className="self-host">
                                                <ParticipantListHostIcon />
                                                <Typography className="hosting-text">
                                                    You're Hosting
                                                </Typography>
                                            </Box>
                                            <Typography className="session-time">
                                                <span className="session-date">Nov 27</span> 7:30 -
                                                8:30 AM
                                            </Typography>
                                            <Typography className="session-language">
                                                English with German Translation
                                            </Typography>
                                        </>
                                    ) : (
                                        <Box
                                            className="joined-user-details"
                                            onClick={() =>
                                                toggleParticipantListModal(
                                                    !openParticipantListModal
                                                )
                                            }>
                                            <img src={UsersIcon} alt="" />
                                            <Typography className="total-count">15</Typography>
                                            <Typography className="manage-text">MANAGE</Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                            {!openChatModal && !openParticipantListModal && (
                                <Box className="session-description">
                                    <Box className="session-breadcrumb">
                                        <Typography>PEAK PERFORMANCE</Typography>
                                        <span> {`>`} </span>
                                        <Typography>PRODUCTIVITY</Typography>
                                    </Box>
                                    <Typography className="session-description-text">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Elementum lacinia luctus risus pharetra dui elementum
                                        ornare. Arcu ut interdum ullamcorper potenti. Sed tellus
                                        egestas et nunc lacus.
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        {showCountdownTimer && (
                            <>
                                <Box className="session-timer">
                                    <Typography>Starts IN</Typography>

                                    {(sessionState === 'not-prepared-to-start' ||
                                        sessionState === 'prepared-to-start') && (
                                        <CountDownTimer
                                            targetDate={targetTime}
                                            completeCountDownTimer={() =>
                                                joinSessionNow('ready-to-start-session')
                                            }
                                        />
                                    )}
                                </Box>

                                {sessionState === 'not-prepared-to-start' && (
                                    <Button
                                        variant="contained"
                                        className="join-now-button"
                                        onClick={() => joinSessionNow('prepared-to-start')}>
                                        PREPARE TO START
                                    </Button>
                                )}
                            </>
                        )}

                        {sessionState === 'ready-to-start-session' && (
                            <Button
                                variant="contained"
                                className="start-session-button"
                                onClick={() => joinSessionNow('start-session')}>
                                <Typography className="start-text">Start</Typography>
                                <img src={PlaySessionIcon} alt="" />
                            </Button>
                        )}

                        {sessionState === 'start-session' && (
                            <Box className="live-session-details">
                                <Lottie
                                    className="heart-wave-animation"
                                    loop
                                    autoplay
                                    animationData={HeartWaveform}
                                />

                                <CountUpTimer />

                                <Button onClick={() => toggleEndSessionModalHandler(true)}>
                                    <img src={StopSessionIcon} alt="" />
                                    <Typography className="end-session-text">END</Typography>
                                </Button>
                            </Box>
                        )}

                        {(sessionState === 'prepared-to-start' ||
                            sessionState === 'ready-to-start-session' ||
                            sessionState === 'start-session') && (
                            <Box className="session-waiting-to-start-section">
                                <Button
                                    className="user-details"
                                    onClick={() => {
                                        openChatModal
                                            ? toggleOpenChatModal(false)
                                            : toggleOpenChatModal(true);
                                    }}>
                                    <img src={AudioSessionHostImage} alt="" />

                                    <Typography
                                        className="open-chat-button"
                                        sx={{ marginRight: openChatModal ? '' : '20px' }}>
                                        {openChatModal ? 'CLOSE CHAT' : `CHAT (13)`}
                                    </Typography>

                                    {openChatModal && (
                                        <Box
                                            className="close-chat-icon"
                                            onClick={() => toggleOpenChatModal(false)}>
                                            <ClearButtonIcon />
                                        </Box>
                                    )}
                                </Button>
                                <Button
                                    className="manage-participant-button"
                                    onClick={() =>
                                        toggleParticipantListModal(!openParticipantListModal)
                                    }>
                                    <Typography>Manage Participants</Typography>
                                </Button>
                            </Box>
                        )}

                        <Box className="actions-section">
                            <Box className="action-icon">
                                <IconButton>
                                    <AddToFavorite />
                                </IconButton>
                            </Box>

                            <Box className="action-icon">
                                <IconButton>
                                    <ShareIcon />
                                </IconButton>
                            </Box>

                            <Box className="action-icon">
                                <IconButton
                                    onClick={() => toggleAddParticipantToSessionModalHandler(true)}>
                                    <AddUserIcon />
                                </IconButton>
                            </Box>

                            {sessionState === 'not-prepared-to-start' && (
                                <Box className="action-icon">
                                    <IconButton>
                                        <EditSessionIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}

                {sessionMainState === 'session-end-view' && (
                    <Box className="session-ended-view">
                        <Typography className="session-title">
                            Breathwork for a Clear Mind
                        </Typography>
                        <Typography className="session-ended-text">Session Ended</Typography>
                        <Button variant="contained" className="schedule-another-session-button">
                            <Typography>Schedule Another</Typography>
                        </Button>
                        <Button variant="contained" className="close-without-scheduling-button">
                            <Typography>Close Without Scheduling</Typography>
                        </Button>
                    </Box>
                )}

                <Box className="bottom-side-gradient" />
            </AudioSessionHostViewWrapper>
            <FooterContentWrapper>
                <Footer showWhiteBackground={false} topBorderColor="gray" />
            </FooterContentWrapper>

            <EndSessionModal
                openEndSessionModal={openEndSessionModal}
                toggleEndSessionModalHandler={(event, mainState) =>
                    toggleEndSessionModalHandler(event, mainState)
                }
            />

            <AddParticipantToSessionModal
                openAddParticipantToSessionModal={openAddParticipantToSessionModal}
                toggleAddParticipantToSessionModalHandler={(event) =>
                    toggleAddParticipantToSessionModalHandler(event)
                }
            />

            <CustomParticipantsView
                openParticipantListModal={openParticipantListModal}
                closeModal={(event) => toggleParticipantListModal(event)}
                hostView={true}
            />

            <ChatSection
                openChatModal={openChatModal}
                closeModal={(event) => toggleOpenChatModal(event)}
            />
        </>
    );
};

const EndSessionModal = (props) => {
    return (
        <EndSessionModalWrapper
            open={props.openEndSessionModal}
            onClose={() => props.toggleEndSessionModalHandler(false)}
            aria-labelledby="end-session-modal-title"
            aria-describedby="end-session-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}>
            <Slide
                in={props.openEndSessionModal}
                direction="right"
                timeout={{ enter: 300, exit: 300 }}>
                <Box className="end-session-modal">
                    <Box className="end-session-content">
                        <Typography className="confirmation">End Session?</Typography>
                    </Box>
                    <Box className="end-session-action-buttons">
                        <Button
                            variant="contained"
                            onClick={() =>
                                props.toggleEndSessionModalHandler(false, 'session-end-view')
                            }>
                            YES
                        </Button>
                        <Button
                            className="no-button"
                            variant="outlined"
                            onClick={() => props.toggleEndSessionModalHandler(false)}>
                            NO
                        </Button>
                    </Box>
                </Box>
            </Slide>
        </EndSessionModalWrapper>
    );
};

export default AudioSessionHostView;
