import React from 'react';
import { Box, Card, CardContent, IconButton, Typography, Tooltip } from '@mui/material';
import PlayButton from 'Assets/images/Common/play-button.svg';
import Ribbon from 'Assets/images/Common/ribbon.svg';
import PlaylistButton from 'Assets/images/Common/playlist-icon.svg';
import PlaylistBorders from 'Assets/images/Common/playlist-borders.svg';
import PlaylistGoldBorders from 'Assets/images/Common/gold-border.svg';
import LockIcon from 'Assets/images/Common/lock.svg';
import { TaoCardWrapper } from './TaoCard.style';
import FavoriteIconAnimation from '../FavoriteIconAnimation';
import { MEDIA_TYPE, TAO_CARD_TYPE } from 'Helpers/Constants';
import DefaultContent from 'Assets/images/Common/content-default-placeholder.jpg';
import usePermissions from '../../common/Permissions';
import { ACCESS_LEVELS_MODULEs } from 'Helpers/Constants';
import { SubscriptionLightLock } from '../SubscriptionLock';

const SHOW_PLAY_ICON = [MEDIA_TYPE.VIDEO, MEDIA_TYPE.MUSIC, MEDIA_TYPE.GUIDED_AUDIO];
const SHOW_PLAY_LIST_ICON = [
    TAO_CARD_TYPE.PLAYLIST,
    TAO_CARD_TYPE.PROGRAM_PLAYLIST,
    TAO_CARD_TYPE.PAID_PROGRAM_PLAYLIST,
    TAO_CARD_TYPE.PROGRAM
];

const TaoCard = ({
    type = MEDIA_TYPE.VIDEO,
    backgroundImage = '',
    title = '',
    cardStyles = {},
    cardRootClass,
    mediaTextType = 'primary',
    cardChipMessage = '',
    favoriteIconVisible = false,
    isTextColorDark = false,
    ratingCount = 0,
    isPaidContent = false,
    isPurchased = false,
    isFeatured = false,
    isAccessible = false,
    isProgramFromPlaylist = false,
    badge = '',
    supportedResponsiveView = false,
    forceDisplayPlayButton = false,
    ...props
}) => {
    const favoritePermission = usePermissions(ACCESS_LEVELS_MODULEs?.ADD_FAVOURITE);

    let favIconVisible =
        ((favoriteIconVisible && isAccessible && !isPaidContent) ||
            (favoriteIconVisible && isAccessible && isPaidContent && isPurchased)) &&
        favoritePermission?.canAccess;

    let actionButtonVisible =
        forceDisplayPlayButton ||
        (isAccessible && !isPaidContent) ||
        (isAccessible && isPaidContent && isPurchased);

    return (
        <TaoCardWrapper
            $titleAvailable={title ? true : false}
            className={cardRootClass}
            backgroundImage={backgroundImage}
            $supportedResponsiveView={supportedResponsiveView}
            taoCardWidth={props?.taoCardWidth || '100%'}>
            {isFeatured && <img src={Ribbon} alt="" className="ribbon-icon" />}
            <Card
                style={{
                    ...cardStyles,
                    cursor: type === TAO_CARD_TYPE.TEXT && 'pointer'
                }}
                classes={{ root: 'card-root' }}
                onClick={() => props?.onClick()}
                className="hover">
                <Box
                    className={`content-bg-image ${
                        !SHOW_PLAY_ICON?.includes(type) && 'apply-border'
                    } `}>
                    <Box
                        className={`image ${isFeatured && `gold-border`} `}
                        style={{
                            backgroundImage: `url(${backgroundImage || DefaultContent})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                    />
                    {favIconVisible && <Box className="black-top-shadow" />}

                    <CardContent className="card-content-class">
                        {(isProgramFromPlaylist || SHOW_PLAY_ICON?.includes(type)) &&
                            actionButtonVisible && (
                                <IconButton
                                    className="play-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props?.onClick();
                                    }}>
                                    <img src={PlayButton} alt="" />
                                </IconButton>
                            )}

                        {!isProgramFromPlaylist && SHOW_PLAY_LIST_ICON?.includes(type) && (
                            <IconButton
                                className="play-list-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props?.onClick();
                                }}>
                                <img src={PlaylistButton} alt="" className="playlist-icon" />
                            </IconButton>
                        )}
                    </CardContent>

                    {favIconVisible && (
                        <Box
                            className="tao-card-heart-icon"
                            onClick={(e) => {
                                e.stopPropagation();
                                props?.onFavClick();
                            }}>
                            <FavoriteIconAnimation isWhiteIcon isFavorite={props?.isFav} />
                        </Box>
                    )}

                    {!isAccessible && badge && (
                        <Box className="unlock-container badge text-uppercase">
                            <SubscriptionLightLock />
                        </Box>
                    )}
                    {/* {isAccessible && isPaidContent && !isPurchased && (
                        <Box className="unlock-container get text-uppercase">
                            <Typography className="unlock-text">{'GET'}</Typography>
                        </Box>
                    )} */}
                    {SHOW_PLAY_LIST_ICON?.includes(type) && (
                        <img
                            className={`playlist-borders ${isFeatured && 'gold-playlist-border'} `}
                            src={isFeatured ? PlaylistGoldBorders : PlaylistBorders}
                            alt=""
                        />
                    )}
                </Box>
            </Card>

            <Tooltip title={title} placement="top">
                <Typography
                    color={mediaTextType}
                    className={` media-text ${props?.textFromStart && 'text-from-start'} elipsis ${
                        isTextColorDark && `dark`
                    }`}>
                    {title}
                </Typography>
            </Tooltip>

            {isAccessible && isPaidContent && !isPurchased && (
                <Box className="lock-content-container">
                    <img
                        src={LockIcon}
                        alt=""
                        className={`lock-icon ${
                            !SHOW_PLAY_LIST_ICON?.includes(type) && 'decrease-bottom'
                        }`}
                    />
                </Box>
            )}

            {props?.children && (
                <Box
                    className={`media-text children ${props?.textFromStart && 'text-from-start'} `}>
                    {props?.children}
                </Box>
            )}
        </TaoCardWrapper>
    );
};

export default TaoCard;
