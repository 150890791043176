import { Box } from '@mui/system';
import styled from 'styled-components';
import { COLORS, FONTS } from 'Styles/Constants';

export const ReadNowWrapper = styled(Box)`
    .text {
        margin: 5px 0;
        font-family: ${FONTS.PRIMARY};
        font-size: 14px;
        line-height: 20px;
        color: ${COLORS.TEXT_DARK};
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .read-hide-text {
        font-family: ${FONTS.PRIMARY};
        font-size: 14px;
        line-height: 20px;
        color: ${COLORS.PRIMARY_DARK};
        cursor: pointer;
        text-transform: capitalize;
    }
`;
