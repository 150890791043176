import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BodyOrgansDetailsWrapper, BodyOrgansDetailsWrapperMobile } from "./FieldHealing.style";
import { ReactComponent as LeftArrow } from "Assets/images/Common/gray-left-arrow.svg";
import Lottie from 'lottie-react';
import * as wavesAnimation from 'Assets/AnimationFiles/wavy-lines-animation.json'
import { BREAKPOINTS_VALUE } from "Styles/Constants";
import { URL_FIELD_HEALING_PLAYER } from "Helpers/Paths";
import { useNavigate } from "react-router-dom"
import { getWindowDimensions } from 'Helpers';

const BodyOrgansDetails = (props) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const navigate = useNavigate();

    useEffect(() => {

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return (
        <>
            {windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET ? (
                <BodyOrgansDetailsWrapperMobile screen={windowDimensions}>
                    <Box className="full-width flex f-v-center">
                        <Box className="arrow-div">
                            <LeftArrow onClick={() => navigate(-1)} />
                        </Box>

                        <Box>
                            <Typography className="title">
                                Heart
                            </Typography>
                        </Box>
                    </Box>

                    <Box className="image-container flex f-h-center f-v-center">
                        <Box className="image" />
                    </Box>

                    <Box className="brief-organ">
                        <Typography className="sub-title">
                            Gently relax your body.
                        </Typography>

                        <p className="detail-description">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            <br />
                            <br />
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            <br />
                            <br />
                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                            <br />
                            <br />
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            <br />
                            <br />
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                        </p>
                    </Box>

                    <div className="seperator" />

                    <Box className="button-wrapper">
                        <Button className="button" variant="contained">BEGIN</Button>
                    </Box>
                </BodyOrgansDetailsWrapperMobile>
            ) : (
                <BodyOrgansDetailsWrapper>
                    <Box className="container">
                        <Box className="arrow-div">
                            <LeftArrow onClick={() => navigate(-1)} />
                        </Box>

                        <Box className="wrapper">
                            <Box className="details-container">
                                <Typography className="title">
                                    Heart
                                </Typography>

                                <Box className="brief-organ">
                                    <Typography className="sub-title">
                                        Gently relax your body.
                                    </Typography>

                                    <p className="detail-description">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        <br />
                                        <br />
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        <br />
                                        <br />
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                        <br />
                                        <br />
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        <br />
                                        <br />
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                                    </p>
                                </Box>
                            </Box>

                            <Box style={{ zIndex: '99999' }}>
                                <Button onClick={() => navigate(URL_FIELD_HEALING_PLAYER)} className="button" variant="contained">BEGIN</Button>
                            </Box>

                            <div className="wave">
                                <Lottie
                                    loop
                                    autoplay
                                    animationData={wavesAnimation} />
                            </div>
                        </Box>

                    </Box>

                    <Box className="heart-container" />
                </BodyOrgansDetailsWrapper>
            )}

        </>
    )
}

export default BodyOrgansDetails