import React from 'react';
import { TaoChipWrapper } from './TaoChip.style';

const TaoChip = ({ id, title, selected, isDisabled, selectChip, ...props }) => {
    return (
        <TaoChipWrapper
            selected={selected}
            classes={{ root: 'chip-root' }}
            label={title}
            disabled={isDisabled}
            size="medium"
            color="primary"
            variant={selected ? 'filled' : 'outlined'}
            onClick={() => selectChip(id, props?.index)}
        />
    );
};

export default TaoChip;
