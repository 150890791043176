import { Box } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const CategoryWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 15px;

    .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .heading {
            font-family: ${FONTS.PRIMARY};
            font-weight: 600;
            font-size: 24px;
            line-height: 35px;
            text-transform: capitalize;
            color: ${COLORS.PRIMARY_DARK};
        }
    }

    .category-count-container {
        display: flex;

        .count {
            font-family: ${FONTS.PRIMARY};
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: ${COLORS.MEDIUM_GREY};
        }

        .text {
            margin-left: 10px;
            font-family: ${FONTS.PRIMARY};
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            text-transform: lowercase;
            color: ${COLORS.MEDIUM_GREY};
        }
    }

    .category-selection-container {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: center;
        width: 90%;
        margin: 0 auto;
        max-width: 400px;

        ${responsive.TABLET`
            width: 60%;
        `}
        ${responsive.PHABLET`
            width: 90%;
        `}

        .categories {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: max-content;

            .icon-button {
                .icon {
                    width: 80px;
                    height: 80px;
                }
            }

            .text {
                width: 80px;
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                text-align: center;
                color: ${COLORS.MEDIUM_GREY};
            }
        }
    }

    .category-button-container {
        display: flex;
        justify-content: center;
        margin-top: auto;

        .submit-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 0px;
            gap: 10px;
            width: 100%;
            background: ${COLORS.WHITE};
            box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
            border-radius: 12px;

            .text {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
                text-align: right;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: ${COLORS.TEXT_DARK};
            }
        }
    }
`;
