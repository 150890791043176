import { SnackbarContent } from 'notistack';
import { COLORS } from 'Styles/Constants';
import styled from 'styled-components';

export const NotificationStyleWrapper = styled(SnackbarContent)`
    height: auto;
    width: 100%;
    padding: 20px;
    margin: 0;
    background-color: #fff;
    border-radius: 12px;

    .logo {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 60px;
        width: 60px;
        border-radius: 8px;
    }
    .text {
        margin: 0 20px 0 10px;
        &.primary {
            color: #7d5416;
        }
        &.secondary {
            color: ${COLORS.PRIMARY_WARM};
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
`;
