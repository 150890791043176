import { Box } from '@mui/system';
import styled from 'styled-components';
import { COLORS, FONTS } from 'Styles/Constants';

export const MeditationCardWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    width: 175px;
    height: 280px;

    .image {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        width: 100%;
        height: 100px;
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: 5px;
        overflow: auto;

        .card-title {
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            font-size: 20px;
            line-height: 25px;
            color: ${COLORS.PRIMARY_WARM};
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .description {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: ${COLORS.TEXT_DARK};
        }

        .mins {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: ${COLORS.MEDIUM_GREY};
            padding-top: 5px;
        }
    }
`;
