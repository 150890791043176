import React from 'react';
import { Box, Typography } from '@mui/material';
import ContentLoader from 'react-content-loader';

const FilterLoader = (props) => {
    return [1, 2, 3].map((item) => (
        <ContentLoader
            key={item}
            speed={2}
            width="100"
            height={45}
            backgroundColor="#dfdfdf"
            foregroundColor="#ecebeb"
            preserveAspectRatio="none"
            {...props}>
            <rect x="0" y="0" rx="22" ry="22" width="100" height="45" />
        </ContentLoader>
    ));
};

export const FilterTabs = ({
    categories,
    updateCategory,
    activeCategory,
    isCategoryLoading,
    ...props
}) => {
    return (
        <Box className="history-filter-tab flex">
            {isCategoryLoading && <FilterLoader />}
            {!isCategoryLoading &&
                categories.map((item, index) => (
                    <Box
                        key={index}
                        className={`filter-tab hover flex f-v-center ${
                            activeCategory === item?.id && 'active'
                        }`}
                        onClick={() => updateCategory(item?.id)}>
                        <Typography
                            className={`filter-text ${activeCategory === item?.id && 'active'}`}>
                            {item?.name}
                        </Typography>
                    </Box>
                ))}
        </Box>
    );
};
