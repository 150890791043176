import styled from 'styled-components';
import { Box } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const ProductDetailsWrapper = styled(Box)`
    .product-details {
        gap: 30px;
        ${responsive.PHABLET`
            display: flex;
            flex-direction: column;
            width: 90%;
            margin: 0 auto;
            height: calc(100% - 120px);
            overflow: auto;
            padding: 0 20px 20px;

            ::-webkit-scrollbar {
                display: none;
            }
        `}
        ${responsive.MOBILE`
            width: 100%;
        `}

        &.desc {
            width: 50%;
            height: ${(props) => `calc(100% - ${props?.$heightOfTopContainer}px - 120px)`};
            overflow-x: auto;
            margin: 10px 0 0;

            ::-webkit-scrollbar {
                display: none;
            }

            ${responsive.PHABLET`
                display: none;
            `}
        }

        .desc-text {
            font-size: 16px;
            color: ${COLORS.BLACK};
        }
        .section {
            width: 50%;

            ${responsive.TABLET`
                width: 40%;
            `}

            ${responsive.PHABLET`
                width: 100%;
            `}

            &.right {
                ${responsive.TABLET`
                    width: 60%;
                `}
                ${responsive.PHABLET`
                    width: 100%;
                `}
            }
            .product-image {
                position: relative;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 250px;
                width: 100%;
                border-radius: 24px;
                overflow: hidden;
                box-shadow: 4.95px 4.95px 9.9px 0px #00000026;

                ${responsive.TABLET`
                    height: 220px;
                `}

                .product-duration {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    background-color: ${COLORS.PRIMARY_LIGHT};
                    font-size: 20px;
                    font-family: ${FONTS.PRIMARY_BOLD};
                    text-align: center;
                    text-transform: uppercase;
                }
            }

            .product-info {
                .mobile-desc {
                    width: 100%;
                    max-height: 100px;
                    overflow-x: auto;
                    margin: 10px 0 0;

                    ::-webkit-scrollbar {
                        display: none;
                    }

                }

                .product-title {
                    font-size: 40px;
                    color: ${COLORS.PRIMARY};
                    text-transform: capitalize;

                    ${responsive.TABLET`
                        font-size: 28px;
                    `}
                }

                .inline-info {
                    margin: 5px 0;

                    .arrow {
                        border-left: 1px solid ${COLORS.MEDIUM_GREY};
                        border-top: 1px solid ${COLORS.MEDIUM_GREY};
                        width: 5px;
                        height: 5px;
                        transform: rotate(135deg);
                        margin: 0 5px;
                    }
                    .navigate {
                        color: ${COLORS.MEDIUM_GREY};
                        font-size: 12px;
                        text-decoration: underline;
                        text-transform: uppercase;
                    }
                    .points {
                        color: ${COLORS.PRIMARY_DARK};
                        font-size: 24px;
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};

                        ${responsive.TABLET`
                            font-size: 18px;
                        `}
                    }
                    .or {
                        color: ${COLORS.MEDIUM_GREY};
                        font-size: 20px;
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                        margin: 0 10px;
                    }
                }

                .btn {
                    width: 100%;
                    background-color: ${COLORS.PRIMARY};
                    box-shadow: 0px 0px 16px 0px #00000026;
                    height: 60px;
                    margin: 10px 0;
                    border-radius: 12px;
                    color: ${COLORS.WHITE};
                    font-size: 16px;
                    font-family: ${FONTS.PRIMARY_MEDIUM};

                    &.share {
                        background-color: ${COLORS.WHITE};
                        color: ${COLORS.BLACK};
                        margin: 0;
                        gap: 10px;
                        text-transform: uppercase;
                    }

                    &.present-in-cart {
                        background-color: ${COLORS.WHITE};
                        border: 3px solid ${COLORS.PRIMARY};
                        color: ${COLORS.BLACK};
                    }
                }
            }
        }
    }
`;
