import styled from 'styled-components';
import { BREAKPOINTS_VALUE, COLORS, FONTS, responsive } from 'Styles/Constants';
// import CurveForRight from 'Assets/images/Programs/curve-for-right.png';
// import CurveForLeft from 'Assets/images/Programs/curve-for-left.png';
import { Popover, Dialog } from '@mui/material';

export const ProgramsMainWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 45%;
        margin-top: 90px;

        ${responsive.DESKTOP`
            width: 60%;
        `}
        ${responsive.TABLET`
            width: 85%;        
        `}
        ${responsive.PHABLET`
            width: 90%;        
        `}

        .program-card-loader-wrapper {
            display: flex;
            flex-direction: column;
            min-width: 215px;
            min-height: 170px;
            align-items: flex-start;
            justify-content: space-between;

            .program-card-loader {
                height: 120px;
                width: 210px;
            }

            .program-card-title-loader-wrapper {
                height: 40px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 210px;
            }
        }
    }

    .no-content {
        color: ${COLORS.TEXT_DARK};
        font-size: 24px;
        height: 190px;

        &.in-progress-no-content {
            height: 235px;
        }

        ${responsive.MOBILE`
            font-size: 20px;
        `}
    }

    .title {
        font-family: ${FONTS.SECONDARY};
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: -0.02em;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_DARK};
    }

    .in-progress {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_DARK};
    }

    .slider-header {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_DARK};
        padding-bottom: 5px;

        ${responsive.PHABLET`
            font-size: 24px;
        `}
    }

    .progress-loader-wrapper {
        width: 304px;
        min-width: 304px;
        height: 232px;
        display: flex !important;
        flex-direction: column;
        gap: 15px;
    }

    .rating-count {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        color: ${COLORS.PRIMARY_DARK};
        padding-top: 2px;
        padding-left: 5px;
    }

    .user-count {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: ${COLORS.PRIMARY_DARK};
        padding-left: 6px;
    }

    .slide-title {
        font-family: ${FONTS.PRIMARY};
        font-size: 18px;
        color: ${COLORS.PRIMARY_DARK};
        max-width: 200px;
    }

    .slide-black-title {
        font-family: ${FONTS.PRIMARY};
        font-size: 18px;
        color: #31290e;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .slide-description {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: ${COLORS.MEDIUM_GREY};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .slider-record {
        margin-left: 15px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .slider-record-browse {
        margin-left: 15px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 90%;

        ${responsive.PHABLET`
            margin-left: 6px;
        `}

        @media only screen and (max-width: 370px) {
            width: 60%;
        }
    }

    /* .slider-content {
        position: relative;
        margin-bottom: 25px;
    } */

    /* .slick-slider {
        ${responsive.PHABLET`
            margin-left: 15px;
        `}
    }

    .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;
        width: 369px !important;
    } */

    /* .slick-initialized .slick-slide {
        display: block;
    } */

    .slick-track {
        width: 11935px !important;
    }

    .title-loader {
        padding: 10px 0 0 0;
        height: 66px;
        width: 304px;
    }

    .card-content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        .playlist-card {
            height: 170px;
            min-width: 300px;
            position: relative;
            width: 100%;

            .playlist-card-image {
                background-position: center;
                background-repeat: no-repeat;
                height: 155px;
                width: 285px;
                background-size: cover;
                border-radius: 22px 11px;
                display: flex;
                align-items: center;
                justify-content: center;

                .playlist-button {
                    background-color: rgba(0, 0, 0, 0.3);
                    border-radius: 15px;
                    z-index: 1;
                    position: relative;

                    .playlist-icon {
                        max-width: 35px;
                        max-height: 35px;
                    }
                }
            }
            .card-content-root {
                height: 100%;
                width: 100%;
                padding: 0;
                position: relative;
            }

            .heart-icon {
                z-index: 10;
                position: absolute;
                top: 3px;
                right: 5%;
                border-radius: 15px;

                .playlist-icon {
                    max-width: 35px;
                    max-height: 35px;
                }
            }

            .playlist-borders {
                z-index: 0;
                position: absolute;
                background-position: right;
                background-repeat: no-repeat;
                height: 165px;
                width: 300px;
                left: -2px;
                top: 4px;
                background-size: contain;
            }
        }

        .media-text {
            width: 90%;
            margin: 10px 0;
            font-family: ${FONTS.PRIMARY};
            font-size: 25px;
            color: ${COLORS.PRIMARY_DARK};
            max-width: 300px;
        }
    }

    .MuiLinearProgress-root {
        background-color: #e8e8e8;
        box-shadow: inset 0px 5.59039px 5.59039px rgba(0, 0, 0, 0.1) !important;
        border-radius: 8px;
        height: 10px;
    }

    .MuiLinearProgress-bar {
        background-color: #ffd26d;
        border-radius: 8px;
    }

    .history {
        width: 370px;
        height: 60px;
        /* left: 571px;
        top: 530px; */
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        margin: 25px 0;

        .text {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #31290e;
        }
    }

    .browse-container {
        margin-top: 30px;
    }

    .browse-box {
        margin-top: 20px;
        background-color: #ffffff;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        border-radius: 36px 36px 0px 0px;
        width: 100%;
        padding: 25px;

        ${responsive.TABLET`
            padding: 0;
            background-color: unset;
            box-shadow: unset;
            border-radius: unset;
        `}

        .load-more {
            display: flex;
            margin: 20px auto 0;
        }
    }

    .top-filter-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .text-container {
            display: flex;
            align-items: center;
            gap: 13px;

            .filter-text {
                font-weight: 500;
                font-size: 20px;
                line-height: 29px;
                color: ${COLORS.PRIMARY_DARK};
                font-family: ${FONTS.PRIMARY};
            }

            .select-text {
                color: #848484;
                font-weight: 400;
                font-size: 16px;
                line-height: 23px;
                text-align: right;
                font-family: ${FONTS.PRIMARY};
            }
        }

        .topic-filter-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            .category-list {
                height: 110px;
                width: 100%;
                display: flex;
                gap: 13px;
                margin-top: 8px;

                ${responsive.MOBILE`
                    overflow: auto;
                `}

                .icon-container {
                    max-width: max-content;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    overflow: hidden;
                    cursor: pointer;
                    min-width: 71px;
                    margin: 0 5px 0 0;

                    .category-icon {
                        width: 70px;
                        height: 70px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 100%;
                    }

                    .text-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 30px;
                        overflow: hidden;
                        margin: 5px 0 0;

                        .category-name {
                            font-size: 13px;
                            line-height: 15px;
                            color: ${COLORS?.TEXT_DARK};
                            text-align: center;
                            width: 100%;
                            max-width: 80px;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }

                .wellness-list-loader-wrapper {
                    height: 133px;
                    width: 71px;
                    min-width: 71px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 0px 10px 0px 0px;
                    gap: 6px;

                    .loader-circle {
                        padding: 8px;
                        height: 70px;
                        width: 70px;
                    }
                }
            }
        }
    }

    .browse {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_DARK};

        ${responsive.TABLET`
            display: none;
        `}
    }

    .topic-heading {
        margin: 15px 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .category-name {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 29px;
            color: ${COLORS.PRIMARY_WARM};

            .bold-text {
                font-family: ${FONTS.PRIMARY_BOLD};
                margin: 0 8px;
            }
        }
    }

    .filter-sort-container {
        display: flex;
        gap: 1rem;
        margin-left: auto;
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
            gap: 0.4rem;
        }
        @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_725}px) {
            margin-left: 1rem;
        }
        .filter-container {
            display: flex;
            flex-direction: column;
            gap: 13px;

            &.language {
                width: 211px;
                height: 45px;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP}px) {
                    width: 175px;
                }

                @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_860}px) {
                    width: 145px;
                }
            }

            &.sort-select {
                width: 150px;
                height: 45px;
                @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP}px) {
                    width: 125px;
                }
            }

            .sorting-btn {
                height: 46px;
                width: 120px;
                border-radius: 24px;
                background-color: #fff;
                border: 1px solid #e8e8e8;
                color: ${COLORS.PRIMARY_WARM};
                font-family: ${FONTS.PRIMARY_MEDIUM};
                text-transform: none;
                font-size: 18px;
            }

            .down-arrow-icon {
                transition: 0.2s;
                transform: rotate(180deg);
            }
        }
    }

    .browse-card-list {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;

        ${responsive.TABLET`
            justify-content: stretch;
        `}

        ${responsive.PHABLET`
            flex-direction: column;
            align-items: center;
            align-content: center;
            padding: 0 15px;
        `}

        @media only screen and (max-width: 420px) {
            padding: 0;
        }

        .play-button {
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.3);

            .playlist-icon {
                max-width: 35px;
                max-height: 35px;
            }
        }

        .no-programs {
            color: ${COLORS.TEXT_DARK};
            font-size: 24px;
            height: 100px;
        }

        .browse-card {
            display: flex;
            flex-direction: column;
            padding: 15px 0;
            min-width: 240px;
            max-width: 240px;
            align-items: flex-start;

            ${responsive.PHABLET`
                flex-direction: column;
                width: 100%;
            `}

            ${responsive.MOBILE`
                align-items: center;
                align-content: center;
                padding: 15px 0;
            `}

            @media only screen and (max-width: 370px) {
                flex-direction: column;
                align-items: center;
            }

            .browse-card-loader-wrapper {
                height: 133px;
                width: 220px;
            }

            .browse-card-title-loader-wrapper {
                height: 70px;
                width: 220px;
                padding: 10px 0 0 0;
            }
        }
    }
`;

export const ProgramsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .container-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        position: relative;

        .slide-container {
            position: absolute;
            height: 100%;
            width: 100%;

            div {
                height: 100%;
            }
            .slide-wrapper {
                height: 100%;
                width: 100%;
            }
        }

        .white-fade {
            background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 10;
        }

        .container {
            display: flex;
            flex-direction: row;
            width: 60%;
            min-height: 600px;
            height: 80%;
            justify-content: space-between;
            z-index: 20;
            position: relative;
            padding: 30px 10px;
            overflow: hidden;

            ${responsive.DESKTOP`
                width: 80%;
            `}

            ${responsive.TABLET`
                width: 100%;
                flex-direction: column;
                height: 100%;
                padding: 0;
            `}

            .left-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 40%;

                ${responsive.TABLET`
                    width: 100%;            
                    padding: 50px 0 0;
                    padding-right: unset;
                    transform: translate(0, 10%);
                `}

                .header-container {
                    min-height: 160px;

                    ${responsive.TABLET`
                        margin: 0 0 0 10px;
                    `}

                    .title {
                        font-family: ${FONTS.SECONDARY_SEMI_BOLD};
                        font-size: 50px;
                        text-transform: capitalize;
                        mix-blend-mode: multiply;
                        line-height: normal;
                        color: ${COLORS.PRIMARY_DARK};
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;

                        ${responsive.TABLET`
                            font-size: 45px;
                        `}

                        ${responsive.PHABLET`
                            font-size: 36px;
                        `}
                    }
                    .rating-count {
                        font-family: ${FONTS.PRIMARY};
                        font-size: 12px;
                        text-transform: uppercase;
                        color: ${COLORS.PRIMARY_DARK};
                        padding-top: 2px;
                        padding-left: 5px;
                    }
                    .user-count {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 17px;
                        color: ${COLORS.PRIMARY_DARK};
                        padding-left: 6px;
                    }
                }
            }

            .right-container {
                width: 50%;
                background: rgba(255, 255, 255, 0.8);
                border: 1px solid #e8e8e8;
                border-radius: 36px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                height: initial;
                min-height: 500px;

                ${responsive.TABLET`
                    bottom: 0;
                    max-width: 100%;
                    width: 100%;
                    height: 100%;
                    border-radius: unset;
                    border-top-left-radius: 36px;
                    border-top-right-radius: 36px;
                    z-index: 5;
                    border-bottom: unset;
                    padding: 20px 10px;
                `}

                .no-progress-text {
                    color: #707070;
                    font-size: 20px;
                    text-align: center;
                    width: 100%;
                }
                .info-static-text {
                    font-size: 16px;
                    color: ${COLORS.TEXT_DARK};
                    margin: 0 0 15px;
                }
                .week-calendar-container {
                    height: max-content;
                    padding: 15px 0;
                }

                .program-list-container {
                    height: 100%;

                    ${responsive.TABLET`
                        height: 100%;
                    `}

                    .notify-user {
                        background: ${COLORS.LIGHT_GREY_SECONDARY};
                        border-radius: 16px;
                        border: 1px solid ${COLORS.LIGHT_GREY};
                        padding: 20px;
                        margin: 0 0 15px 0;

                        .text {
                            font-size: 20px;
                            color: ${COLORS.PRIMARY_WARM};

                            .bold {
                                font-size: 20px;
                                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                            }

                            .mutate-case {
                                text-transform: capitalize;
                            }
                        }
                    }

                    .program-list-loader-wrapper {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: flex-end;
                        justify-content: space-between;

                        &.reverse {
                            flex-direction: row-reverse;
                        }

                        .image-with-text-loader-wrapper {
                            width: 47%;
                            display: flex;
                            flex-direction: column;

                            .text-loader-wrapper {
                                width: 100%;
                                height: 43px;
                                ${responsive.TABLET`
                                    display: none;
                                `}
                            }

                            .image-loader-wrapper {
                                width: 100%;
                            }
                        }

                        .program-description-loader-wrapper {
                            width: 47%;
                            height: 143px;

                            .program-description-loader {
                                margin: 40px 0 0 0;
                                width: 100%;
                                height: 100%;

                                ${responsive.TABLET`
                                    margin: 0;
                                `}

                                ${responsive.PHABLET`
                                    margin: 40px 0 0 0;
                                `}
                            }
                        }
                    }
                }

                .goal-chips-overflow {
                    min-height: 50px;

                    .goal-chip {
                        padding: 13px 10px 10px;
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                        text-transform: uppercase;

                        height: 43px;
                        border-radius: 26px;
                        text-transform: uppercase;
                        margin: 3px 5px 3px 0;

                        &.non-selected-goal {
                            background-color: #f2f2f2;
                            color: ${COLORS.MEDIUM_GREY};
                        }

                        &.selected-goal {
                            color: ${COLORS.PRIMARY_DARK};
                            border: 2px solid ${COLORS.PRIMARY};
                        }
                    }
                }
            }
        }
    }

    .title-container {
        display: flex;
        flex-direction: row;
    }

    .timer-container {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
        margin: 30px 0;
        position: relative;

        .progressBar {
            z-index: 15;
            position: relative;
        }

        .timer {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background: rgba(132, 132, 132, 0.3);
            mix-blend-mode: multiply;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #fff;
            gap: 15px;
            z-index: 10;
            position: relative;

            .timer-text {
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 600;
                font-size: 36px;
                line-height: 40px;
                text-align: center;
                text-transform: uppercase;
                color: #ffffff;
                text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
                max-width: 80%;
            }
        }
    }

    .button-container {
        width: 90%;

        ${responsive.TABLET`
            width: 100%;
            position: absolute;
            display: flex;
            justify-content: center;
            padding: 0 100px;
            bottom: 10px;
            z-index: 20;
       `}

        ${responsive.MOBILE`
            padding: 0 10px;
       `}
    }

    .button-text {
        font-family: ${FONTS.TERTIARY};
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: right;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }

    .progress-list-wrapper {
        height: 75%;
        overflow: auto;

        ${responsive.TABLET`
            overflow: unset;
            height: 70%;
        `}
    }
`;

export const ProgramEventWrapper = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.swap ? 'row-reverse' : 'row')};
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    opacity: ${(props) => (props?.canPlay ? '1' : '0.5')};
    pointer-events: ${(props) => (props?.canPlay ? 'all' : 'none')};
    overflow: hidden;

    ${responsive.TABLET`
        padding-bottom: ${(props) => (props.isLastRecord ? '100px' : '')};
        align-items: flex-start;
   `}

    .container {
        display: flex;
        flex-direction: ${(props) => (props.swap ? 'row-reverse' : 'row')};
        justify-content: space-between;
        width: 100%;
    }

    .image-section {
        display: flex;
        flex-direction: column;
        width: auto;
    }

    .time-text {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 43px;
        color: ${COLORS.PRIMARY_DARK};
        padding-left: 5px;

        ${responsive.TABLET`
            margin-bottom: -38px;
            color: #fff;
            z-index: 3;
       `}
    }

    .image-box {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 19px 6px;
        width: 190px;
        height: 100px;
        position: relative;

        ${responsive.LAPTOP`
            width: 170px;
        `}

        ${responsive.TABLET`
            width: 190px;
        `}

        ${responsive.PHABLET`
            width: 160px;
        `}

        .unlock-container {
            max-width: 160px;
            background: ${COLORS.PRIMARY_DARK};
            display: flex;
            flex-direction: row;
            justify-content: center;
            position: absolute;
            top: 0%;
            left: 0%;
            z-index: 12;

            &.badge {
                top: 70%;
                border-radius: 50px;
                background: rgba(101, 101, 101, 0.7);
                padding: 5px;
                left: 3%;

                ${responsive.PHABLET`
                    top: ${(props) => (props?.$supportedResponsiveView ? '55%' : '70%')};
                `}
            }

            &.get {
                right: 0%;
                left: auto;
                border-radius: 0 6px 0 6px;
            }

            .unlock-text {
                font-family: ${FONTS.TERTIARY_BOLD};
                font-size: 12px;
                text-transform: uppercase;
                color: ${COLORS.WHITE};
                padding: 2px 8px;
                width: 100%;
            }
        }
    }

    .text-section {
        display: flex;
        flex-direction: column;
        padding: ${(props) => (!props?.swap ? '0 0 0 10px' : '0 10px 0 0')};
        width: 100%;
        overflow: hidden;

        .event-title {
            font-family: ${FONTS.PRIMARY};
            font-size: 21px;
            line-height: 29px;
            color: #31290e;
        }
    }

    .description {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: #656565;
        overflow: hidden;
        height: 42px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;

        /* P TAG FOR DESCRIPTION TEXT */
        p {
            padding: 0;
            margin: 0;
        }
    }

    .more {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: ${COLORS.PRIMARY_DARK};
    }

    .mins {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: ${COLORS.MEDIUM_GREY};
    }

    .overlay {
        height: 160px;
        width: 422px;
        position: absolute;
        backdrop-filter: grayscale(0.5);
        margin-bottom: -10px;
        margin-left: -5px;
        background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        opacity: 0.95;
    }
`;
export const ProgramAboutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;

    .title {
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 35px;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_DARK};
    }

    .description {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        color: ${COLORS.PRIMARY_WARM};

        p {
            line-height: 27px;
            font-size: 18px;
            /* padding: 0;
            margin: 0; */
        }
    }

    .other-experience {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 35px;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_DARK};
        margin: 15px 0;
    }

    .experience-text-container {
        width: 20%;
        display: flex;
        justify-content: flex-end;
        margin-right: 20px;
        ${responsive.PHABLET`
            justify-content: flex-start;
        `}
    }

    .experience-text {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        text-align: right;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_DARK};
    }

    .count {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        text-transform: capitalize;
        color: #848484;
        margin-left: 10px;
    }

    .bar {
        background-color: #c4c4c4;
        border-radius: 5px;
        height: 10px;
    }

    .no-reviewed {
        color: ${COLORS.TEXT_DARK};
        font-size: 24px;
        margin: 20px 0;
    }
`;

export const PopOverWrapper = styled(Popover)`
    .sorting-box {
        width: 120px;
        border: 1px solid #e8e8e8;
        border-radius: 24px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .sorting-text {
            padding: 10px 5px;
            font-size: 18px;
            color: ${COLORS.PRIMARY_WARM};
            font-family: ${FONTS.PRIMARY_MEDIUM};
            width: 80%;
            border-bottom: 0.5px solid rgb(132 132 132 / 0.5);
            justify-content: center;

            &:hover {
                cursor: pointer;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
`;

export const GetContentDialogWrapper = styled(Dialog)`
    .border {
        border: 1px solid ${COLORS.PRIMARY};
        border-radius: 30px;
    }
    .modal-container {
        /* width: 370px; */
        width: max-content;
        height: 118px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .modal-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            align-items: center;
            align-content: center;
            justify-content: center;
            padding: 20px;

            .purchase {
                text-align: center;
                width: 100%;
                padding: 10px;
                cursor: pointer;

                &.purchase-text {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 23px;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                }

                &.top-border {
                    border-top: 0.5px solid ${COLORS.LIGHT_GREY};
                }
            }
        }
    }
`;

export const ProgramProgressWrapper = styled.div`
    padding: 0 30px;

    ${responsive.DISPLAY_1650`
        padding: 0 10px;
    `}

    ${responsive.TABLET`
        padding: 0 30px;
        display: flex;
        flex-direction: column;
    `}

    ${responsive.MOBILE_376`
        padding: 0 20px;
    `}

    ${responsive.MOBILE_325`
        padding: 0;
    `}

    .progress-tabs-container {
        width: max-content;
        border-radius: 12px;
        border: 2px solid ${COLORS.LIGHT_GREY};
        height: 40px;
        margin: 0 auto;
        overflow: hidden;

        .tab-text {
            font-size: 14px;
            color: ${COLORS.MEDIUM_GREY};
            padding: 0 20px;
            width: 150px;
            height: 100%;
            background-color: ${COLORS.LIGHT_GREY};
            font-family: ${FONTS.PRIMARY_MEDIUM};

            ${responsive.MOBILE`
                width: 130px;
            `}

            &.active-tab {
                background-color: ${COLORS.WHITE};
                color: ${COLORS.PRIMARY};
            }
        }
    }

    .data-container {
        gap: 20px;
        margin: 5px 0;
        height: 35px;

        &.data {
            height: 70px;
            margin: 15px 0;

            ${responsive.MOBILE`
                height: 50px;
            `}
        }

        &.mt-20 {
            margin: 20px 0 0;
        }

        .title {
            font-size: 20px;
            color: ${COLORS.MEDIUM_GREY};

            ${responsive.MOBILE`
                font-size: 14px;
            `}
            &.text-highlighted {
                font-family: ${FONTS.PRIMARY_BOLD};
                text-transform: uppercase;
            }
            &.day {
                font-size: 30px;

                ${responsive.MOBILE`
                    font-size: 18px;
                `}
            }
        }

        .date {
            font-size: 12px;
            color: ${COLORS.MEDIUM_GREY};
            font-family: ${FONTS.PRIMARY_MEDIUM};

            ${responsive.MOBILE`
                font-size: 8px;
            `}
        }

        .col-first-width {
            width: 15%;
            ${responsive.MOBILE`
                width: 10%;
            `}
        }
        .col-second-width {
            width: 90%;
            gap: 20px;
            position: relative;

            .progress-bar {
                height: 70px;
                width: 70px;

                ${responsive.MOBILE`
                    height: 50px;
                    width: 50px;
                `}
            }
            .out-of-count {
                color: ${COLORS.PRIMARY};
                font-size: 24px;
                font-family: ${FONTS.PRIMARY_MEDIUM};

                ${responsive.MOBILE`
                    font-size: 18px;
                `}
            }

            .achievement {
                font-size: 24px;
                color: ${COLORS.TEXT_DARK};

                ${responsive.MOBILE`
                    font-size: 18px;
                `}
                &.completed {
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                }
            }

            .mood-icon {
                height: 35px;
                width: 35px;

                ${responsive.MOBILE`
                    height: 30px;
                    width: 30px;
                `}
            }

            .mood-ring {
                position: absolute;
                height: 55px;

                ${responsive.MOBILE`
                    height: 40px
                `}

                &.in-active {
                    border: 3px solid ${COLORS.WHITE};
                    background: ${COLORS.LIGHT_GREY_SECONDARY};
                }

                .in-active-mood-text {
                    color: ${COLORS.TEXT_DARK};
                    font-size: 20px;

                    ${responsive.MOBILE`
                        font-size: 16px;
                    `}
                }

                .arrow {
                    height: 10px;
                    width: 10px;
                    border-right: 3px solid ${COLORS.WHITE};
                    border-bottom: 3px solid ${COLORS.WHITE};
                    rotate: 320deg;
                    margin: 0 5px 0 0;
                }

                .reverse-arrow {
                    height: 10px;
                    width: 10px;
                    border-right: 3px solid ${COLORS.WHITE};
                    border-bottom: 3px solid ${COLORS.WHITE};
                    rotate: 135deg;
                    margin: 0 0 0 5px;
                }
            }
        }
    }
`;

// export const ProgramProgressWrapper = styled.div`
//     padding: 0 30px;

//     ${responsive.DISPLAY_1650`
//         padding: 0 10px;
//     `}

//     ${responsive.TABLET`
//         padding: 0 30px;
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//     `}

//     ${responsive.MOBILE_376`
//         padding: 0 20px;
//     `}

//     ${responsive.MOBILE_325`
//         padding: 0;
//     `}

//    .progress-wrapper {
//         height: 100%;
//         width: 45%;
//         position: relative;
//         z-index: 3;

//         @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_860}px) {
//             width: 55%;
//         }

//         @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
//             width: 62%;
//         }
//     }

//     .day-progress {
//         display: flex;
//         flex-direction: ${(props) => (props.swap ? 'row-reverse' : 'row')};
//         justify-content: space-evenly;
//         width: 100%;

//         @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_860}px) {
//             height: 190px;
//         }

//         ${responsive.TABLET`
//             height: unset;
//             width: 70%;
//        `}

//         ${responsive.PHABLET`
//             width: 75%;
//        `}

//         ${responsive.PHABLET_500`
//             width: 85%;
//         `}

//         ${responsive.MOBILE`
//             width: 95%;
//         `}

//         ${responsive.MOBILE_376`
//             width: 100%;
//         `}

//        .CircularProgressbar {
//             .CircularProgressbar-trail {
//                 stroke-width: 0.5px;
//                 stroke: #b08c3d;
//             }

//             .CircularProgressbar-path {
//                 stroke: #ffd26d;
//                 background: conic-gradient(
//                     from 0deg at 50% 50%,
//                     #fff6e1 0deg,
//                     #ffd26d 258.14deg,
//                     #fff6e1 360deg
//                 );
//             }
//         }

//         .progress-inner-content {
//             border-radius: 50%;
//             height: 85%;
//             width: 85%;
//             background-color: rgba(242, 242, 242, 0.4);
//             /* border: 0.5px solid ${COLORS.PRIMARY_DARK}; */
//             /* backdrop-filter: blur(10px); */
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             align-items: center;
//             gap: 7px;
//         }

//         .number {
//             width: 69px;
//             height: 69px;
//             border: 2px solid ${COLORS.LIGHT_GREY};
//             border-radius: 50%;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             font-size: 24px;
//             line-height: 35px;
//             color: ${COLORS.TEXT_DARK};
//             background-color: ${COLORS.LIGHT_GREY};
//             position: relative;

//             .number-font {
//                 font-family: ${FONTS.PRIMARY};
//                 font-style: normal;
//                 font-weight: 500;
//                 font-size: 36px;
//                 line-height: 27px;
//                 text-align: center;
//                 color: ${COLORS.MEDIUM_GREY};
//             }

//             .date-font {
//                 position: absolute;
//                 bottom: -50%;
//                 font-family: ${FONTS.PRIMARY};
//                 font-style: normal;
//                 font-weight: 400;
//                 font-size: 14px;
//                 line-height: 27px;
//                 text-align: center;
//                 color: ${COLORS.MEDIUM_GREY};
//                 text-transform: uppercase;
//             }
//         }

//         .days-highlight {
//             font-family: ${FONTS.PRIMARY};
//             font-style: normal;
//             font-weight: 400;
//             font-size: 36px;
//             line-height: 0px;
//             text-align: center;
//             color: ${COLORS.PRIMARY_EXTRA_DARK};
//         }

//         .total-days {
//             font-family: ${FONTS.PRIMARY};
//             font-style: normal;
//             font-weight: 400;
//             font-size: 24px;
//             line-height: 0px;
//             text-align: center;
//             color: #afafaf;
//         }

//         .text {
//             font-family: ${FONTS.TERTIARY};
//             font-style: normal;
//             font-weight: 400;
//             font-size: 14px;
//             line-height: 18px;
//             text-align: center;
//             color: ${COLORS.PRIMARY_EXTRA_DARK};
//             opacity: 0.8;
//         }
//     }

//     .curve-image {
//         background-image: url(${CurveForRight});
//         background-repeat: no-repeat;
//         background-size: cover;
//         position: absolute;
//         width: 231px;
//         height: 215px;
//         opacity: 0.3;
//         top: 34px;
//         right: 77px;
//         z-index: 1;
//         background-position: center;

//         @media only screen and (min-width: 2000px) {
//             width: 295px;
//             height: 199px;
//             top: 93px;
//             right: 96px;
//         }

//         @media only screen and (min-width: 2200px) {
//             width: 315px;
//             height: 218px;
//             top: 93px;
//             right: 96px;
//         }

//         @media only screen and (min-width: 2400px) {
//             width: 336px;
//             height: 229px;
//         }

//         @media only screen and (min-width: 2560px) {
//             width: 367px;
//             height: 257px;
//         }

//         @media only screen and (max-width: 1900px) {
//             width: 231px;
//             height: 192px;
//             top: 42px;
//             right: 67px;
//         }

//         ${responsive.DISPLAY`
//             width: 224px;
//             height: 195px;
//        `}

//         ${responsive.DISPLAY_1650`
//             top: 56px;
//             right: 90px;
//        `}
 
//        ${responsive.DESKTOP`
//            width: 219px;
//            height: 190px;
//        `}

//         @media only screen and (max-width: 1350px) {
//             width: 253px;
//             right: 77px;
//         }

//         ${responsive.LAPTOP`
//             width: 243px;
//             height: 187px;
//        `}

//         @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1100}px) {
//             width: 234px;
//             height: 189px;
//             right: 88px;
//         }

//         ${responsive.TABLET`
//             width: 211px;
//             height: 182px;
//        `}

//         @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_860}px) {
//             right: 56px;
//         }

//         @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
//             width: 160px;
//             right: 71px;
//         }

//         @media only screen and (max-width: 750px) {
//             left: 66px;
//         }

//         ${responsive.TABLET`
//             width: 200px;
//             height: 223px;
//             right: 110px;
//             top: 85px;
//             left: unset;
//         `}

//         @media only screen and (max-width: 650px) {
//             width: 190px;
//             height: 210px;
//             right: 104px;
//             top: 90px;
//         }

//         ${responsive.PHABLET`
//             width: 180px;
//             height: 178px;
//             right: 76px;
//             top: 69px;
//         `}

//         ${responsive.MOBILE`
//             width: 189px;
//             height: 189px;
//             right: 77px;
//             top: 65px;
//         `}

//         ${responsive.MOBILE_376`
//             right: 77px;
//         `}
        
//         @media only screen and (max-width: 330px) {
//             width: 130px;
//             height: 137px;
//             right: 64px;
//             top: 56px;
//         }
//     }

//     .curve-image-left {
//         background-image: url(${CurveForLeft});
//         background-repeat: no-repeat;
//         background-size: cover;
//         position: absolute;
//         opacity: 0.3;
//         width: 274px;
//         height: 188px;
//         top: 55px;
//         left: 67px;
//         z-index: 1;
//         background-position: center;

//         @media only screen and (min-width: 2000px) {
//             width: 264px;
//             height: 203px;
//             top: 73px;
//             left: 92px;
//         }

//         @media only screen and (min-width: 2200px) {
//             width: 302px;
//             height: 230px;
//             top: 73px;
//             left: 92px;
//         }

//         @media only screen and (min-width: 2400px) {
//             width: 316px;
//             height: 240px;
//         }

//         @media only screen and (min-width: 2560px) {
//             width: 357px;
//             height: 247px;
//             top: 91px;
//         }

//         @media only screen and (max-width: 1900px) {
//             width: 255px;
//             height: 177px;
//             top: 45px;
//             left: 63px;
//         }

//         ${responsive.DISPLAY`
//             width: 232px;
//             height: 190px;
//        `}

//         ${responsive.DISPLAY_1650`
//             top: 59px;
//             left: 81px;
//        `}
 
//        ${responsive.DESKTOP`
//            width: 250px;
//            height: 184px;
//        `}
 
//        @media only screen and (max-width: 1350px) {
//             width: 240px;
//             height: 170px;
//             top: 54px;
//             left: 69px;
//         }

//         @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1100}px) {
//             width: 250px;
//             height: 208px;
//             top: 67px;
//             left: 87px;
//         }

//         @media only screen and (max-width: ${BREAKPOINTS_VALUE.LAPTOP_1050}px) {
//             width: 250px;
//             height: 156px;
//             left: 70px;
//         }

//         ${responsive.TABLET`
//             width: 227px;
//             height: 163px;
//        `}

//         @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_860}px) {
//             width: 185px;
//         }

//         @media only screen and (max-width: ${BREAKPOINTS_VALUE.TABLET_800}px) {
//             width: 164px;
//         }

//         @media only screen and (max-width: 750px) {
//             width: 162px;
//             left: 60px;
//         }

//         ${responsive.TABLET`
//             width: 208px;
//             left: 106px;
//             height: 232px;
//             top: 98px;
//         `}

//         @media only screen and (max-width: 650px) {
//             width: 207px;
//             left: 77px;
//             height: 196px;
//             top: 81px;
//         }

//         ${responsive.PHABLET`
//             width: 180px;
//             left: 74px;
//             height: 190px;
//             top: 70px;
//         `}

//         ${responsive.MOBILE`
//             left: 80px;
//             width: 182px;
//             height: 211px;
//             top: 62px;
//         `}

//         ${responsive.MOBILE_325`
//             left: 60px;
//             width: 171px;
//             height: 148px;
//             top: 59px;
//         `}

//         @media only screen and (max-width: 300px) {
//             left: 60px;
//             width: 132px;
//             height: 132px;
//             top: 44px;
//         }
//     }
// `;
