import { Box, Button, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { MenuDrawerWrapper } from './index.style';
import {
    URL_MEDITATION_TIMER,
    URL_PLAYLIST,
    URL_JOURNAL,
    URL_PROFILE,
    URL_STATISTICS,
    URL_HISTORY,
    API_URL,
    URL_FAVORITES,
    URL_PROGRAMS,
    URL_QUESTIONNAIRE_MAIN_RESULT
} from 'Helpers/Paths';
import PlaylistIcon from 'Assets/images/Header/UserMenu/playlist-icon.svg';
import JournalIcon from 'Assets/images/Header/UserMenu/journal-icon.svg';
import ProgramIcon from 'Assets/images/Sidebar/program-icon.svg';
import MeditationTimerIcon from 'Assets/images/Header/UserMenu/meditation-timer-icon.svg';
import SettingsIcon from 'Assets/images/Header/UserMenu/settings-icon.svg';
import StatsIcon from 'Assets/images/Header/UserMenu/stats-icon.svg';
import HistoryIcon from 'Assets/images/Header/UserMenu/history-icon.svg';
import LogoutIcon from 'Assets/images/Header/UserMenu/logout-icon.svg';
import FavoriteIcon from 'Assets/images/Common/favorite-icon-active.svg';

import {
    PROFILE_TAB_TYPE,
    REDIRECTION_LINKS_ITEMS,
    USERs_MENU_ITEMS,
    ACCESS_LEVELS_MODULEs
} from 'Helpers/Constants';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Api from 'Helpers/ApiHandler';
import { logoutUser } from 'Redux/Auth/Actions';
import TaoScrollbar from 'Components/common/Scrollbar';
import { DeleteConfirmation } from 'Components/common/Modal/Delete';
import usePermissions from '../../Permissions';
import { showSubscriptionModal } from 'Redux/App/Actions';
import { SubscriptionDarkLock } from 'Components/common/SubscriptionLock';

const REDIRECTION_LINKS = [
    {
        title: REDIRECTION_LINKS_ITEMS.GETTING_STARTED_GUIDED,
        urlToNavigate: URL_QUESTIONNAIRE_MAIN_RESULT
    }
];

const MenuDrawer = ({ open, userImage, onClose, ...props }) => {
    const API = useMemo(() => new Api(), []);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [openLogoutAccountDialog, setOpenLogoutAccountDialog] = useState(false);

    const USERS_MENU_LIST = [
        {
            menuName: USERs_MENU_ITEMS.PROFILE,
            state: { type: PROFILE_TAB_TYPE.VIEW_PROFILE },
            urlToNavigate: URL_PROFILE,
            canAccess: usePermissions(ACCESS_LEVELS_MODULEs?.PROFILE)?.canAccess
        },
        {
            menuName: USERs_MENU_ITEMS.FAVORITES,
            menuLogo: FavoriteIcon,
            urlToNavigate: URL_FAVORITES,
            canAccess: usePermissions(ACCESS_LEVELS_MODULEs?.ADD_FAVOURITE)?.canAccess
        },
        {
            menuName: USERs_MENU_ITEMS.JOURNAL,
            menuLogo: JournalIcon,
            urlToNavigate: URL_JOURNAL,
            canAccess: usePermissions(ACCESS_LEVELS_MODULEs?.JOURNAL)?.canAccess
        },
        {
            menuName: USERs_MENU_ITEMS.PROGRAMS,
            menuLogo: ProgramIcon,
            urlToNavigate: URL_PROGRAMS,
            canAccess: usePermissions(ACCESS_LEVELS_MODULEs?.SEE_PROGRAMS)?.canAccess
        },
        {
            menuName: USERs_MENU_ITEMS.PLAYLIST,
            menuLogo: PlaylistIcon,
            urlToNavigate: URL_PLAYLIST,
            canAccess: usePermissions()?.canAccess
        },
        {
            menuName: USERs_MENU_ITEMS.MEDITATION_TIMER,
            menuLogo: MeditationTimerIcon,
            urlToNavigate: URL_MEDITATION_TIMER,
            canAccess: usePermissions(ACCESS_LEVELS_MODULEs?.MEDITATION_TIMER)?.canAccess
        },
        {
            menuName: USERs_MENU_ITEMS.HISTORY,
            menuLogo: HistoryIcon,
            urlToNavigate: URL_HISTORY,
            canAccess: usePermissions(ACCESS_LEVELS_MODULEs?.ACCESS_PERSONAL_HISTORY)?.canAccess
        },
        {
            menuName: USERs_MENU_ITEMS.STATS,
            menuLogo: StatsIcon,
            urlToNavigate: URL_STATISTICS,
            canAccess: usePermissions(ACCESS_LEVELS_MODULEs?.SEE_PERSONAL_STATS)?.canAccess
        },
        {
            menuName: USERs_MENU_ITEMS.SETTINGS,
            menuLogo: SettingsIcon,
            state: { type: PROFILE_TAB_TYPE.SETTINGS },
            urlToNavigate: URL_PROFILE,
            canAccess: usePermissions(ACCESS_LEVELS_MODULEs.SETTINGS)?.canAccess
        }
    ];

    const navigateToModule = (url, state) => {
        onClose();
        if (location.pathname !== URL_PROFILE && location.pathname === url) return;
        navigate(url, { state: { ...state } });
    };
    const logoutCurrentUser = async () => {
        let response = await API.post(API_URL.LOGOUT_DEVICES);
        if (response) {
            onClose();
            dispatch(logoutUser());
        }
    };

    const handleRedirect = (value) => {
        if (!value?.canAccess) return dispatch(showSubscriptionModal());

        navigateToModule(value?.urlToNavigate, value?.state || {});
    };

    return (
        <MenuDrawerWrapper
            anchor="right"
            open={open}
            onClose={onClose}
            classes={{ paper: 'paper-root' }}>
            <Box className="cross-icon hover" onClick={onClose} />
            <TaoScrollbar customStyle={{ padding: '0 20px' }}>
                {USERS_MENU_LIST.slice(0, 3).map((item, index) => (
                    <Button key={index} className="init-cards" onClick={() => handleRedirect(item)}>
                        <Box className="flex" margin={item?.canAccess ? '0 auto' : '0 0 0 auto'}>
                            <Box
                                style={{
                                    backgroundImage: `url(${
                                        item?.menuName === USERs_MENU_ITEMS.PROFILE
                                            ? userImage
                                            : item?.menuLogo
                                    })`
                                }}
                                className={`icon ${
                                    item?.menuName === USERs_MENU_ITEMS.PROFILE && 'icon-border'
                                }`}
                            />
                            <Typography className="main-menu-name">{item?.menuName}</Typography>
                        </Box>
                        {!item?.canAccess && (
                            <SubscriptionDarkLock customStyle={{ margin: '0 0 0 auto' }} />
                        )}
                    </Button>
                ))}

                <Box className="divider" />
                <Box className="chips-list">
                    {USERS_MENU_LIST.slice(3).map((item, index) => (
                        <Button className="chip" key={index} onClick={() => handleRedirect(item)}>
                            <Box className="flex">
                                <Box
                                    style={{ backgroundImage: `url(${item?.menuLogo})` }}
                                    className="icon"
                                />

                                <Typography className="menu-name">{item?.menuName}</Typography>
                            </Box>
                            {!item?.canAccess && (
                                <SubscriptionDarkLock customStyle={{ margin: '0 0 0 auto' }} />
                            )}
                        </Button>
                    ))}
                </Box>
                <Box className="divider" />

                {REDIRECTION_LINKS.map((item, index) => (
                    <Box
                        key={index}
                        className="redirection-links hover"
                        onClick={() => navigateToModule(item?.urlToNavigate)}>
                        <Typography className="title">{item?.title}</Typography>
                        <Box className="right-arrow" />
                    </Box>
                ))}
            </TaoScrollbar>

            <Button className="logout-btn" onClick={() => setOpenLogoutAccountDialog(true)}>
                <Box className="logout-icon" style={{ backgroundImage: `url(${LogoutIcon})` }} />
                Logout
            </Button>
            {openLogoutAccountDialog && (
                <DeleteConfirmation
                    title="Are you sure you want to logout?"
                    onClose={() => {
                        setOpenLogoutAccountDialog(false);
                    }}
                    onConfirm={() => {
                        logoutCurrentUser();
                    }}
                />
            )}
        </MenuDrawerWrapper>
    );
};

export default MenuDrawer;
