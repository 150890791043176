import React, { useState } from 'react';

import Friend1 from 'Assets/images/Profile/default-user-icon.jpg';
import Friend2 from 'Assets/images/Profile/default-user-icon.jpg';
import Friend3 from 'Assets/images/Profile/default-user-icon.jpg';
import Friend4 from 'Assets/images/Profile/default-user-icon.jpg';
import { ReactComponent as SearchIcon } from 'Assets/images/Common/outlined-search-icon.svg';
import { ReactComponent as RemoveItemIcon } from 'Assets/images/Common/remove-item-icon.svg';
import { ReactComponent as ArrowImage } from 'Assets/images/Common/down-arrow.svg';

import { Box, Typography, Button, Input, IconButton } from '@mui/material';

import { CSSTransition } from 'react-transition-group';

import { AddMemberModalWrapper } from './Chat.style';
import { cloneDeep } from 'lodash';

const AddMemberModal = React.forwardRef(({ onClose = () => {}, className = '', ...props }, ref) => {
    const [filterVal, setFilterValue] = useState('');
    const [selectedPeople, setSelectedPeople] = useState([]);
    const [friends] = useState(cloneDeep(FRIENDS));

    const onFilterChange = (e) => {
        setFilterValue(e.target.value);
    };

    const selectPerson = (id) => {
        const fIndex = selectedPeople.findIndex((sp) => sp.id === id);
        if (fIndex === -1) {
            const copySelectedPeople = cloneDeep(selectedPeople);
            copySelectedPeople.push(cloneDeep(friends.find((f) => f.id === id)));
            setSelectedPeople(copySelectedPeople);
        }
    };

    const removePerson = (id) => {
        const filteredSelectedPeople = selectedPeople.filter((sp) => sp.id !== id);
        setSelectedPeople(filteredSelectedPeople);
    };
    return (
        <AddMemberModalWrapper className={className} ref={ref}>
            <Box className="add-member-title-container flex">
                <Box className="back-button-container">
                    <IconButton onClick={onClose} className="arrow-btn">
                        <ArrowImage />
                    </IconButton>
                </Box>
                <Typography className="title">Add To Chat</Typography>
                <Button className="btn" onClick={onClose}>
                    Cancel
                </Button>
            </Box>
            <CSSTransition
                in={selectedPeople.length}
                timeout={450}
                classNames="selection-container"
                unmountOnExit>
                <Box className="selected-list-container">
                    <Box className="selected-list">
                        {selectedPeople.map((sp) => (
                            <Box
                                style={{ backgroundImage: `url(${sp.image})` }}
                                className="profile-image">
                                <IconButton
                                    className="remove-icon"
                                    onClick={() => removePerson(sp.id)}>
                                    <RemoveItemIcon />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                    <Box className="add-btn-container">
                        <Button className="add-btn">ADD</Button>
                    </Box>
                </Box>
            </CSSTransition>
            <Box className="filter-input-container">
                <Input
                    startAdornment={<SearchIcon className="search-icon" />}
                    value={filterVal}
                    onChange={onFilterChange}
                    disableUnderline
                    className="filter-input"
                    variant="filled"
                    placeholder="Search"
                />
            </Box>
            <Box className="list-container">
                <Typography variant="h5" className="title">
                    FRIENDS
                </Typography>
                <Box className="list">
                    {friends.map((f, index) => (
                        <Box onClick={() => selectPerson(f.id)} className="list-item">
                            <Box
                                style={{ backgroundImage: `url(${f.image})` }}
                                className="profile-image"></Box>
                            <Typography className="name">{f.name}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </AddMemberModalWrapper>
    );
});

export default AddMemberModal;

const FRIENDS = [
    {
        id: 1,
        image: Friend1,
        name: 'Eleanor'
    },
    {
        id: 2,
        image: Friend2,
        name: 'Thomas'
    },
    {
        id: 3,
        image: Friend3,
        name: 'Eleanor'
    },
    {
        id: 4,
        image: Friend1,
        name: 'Thomas'
    },
    {
        id: 5,
        image: Friend2,
        name: 'Thomas'
    },
    {
        id: 6,
        image: Friend3,
        name: 'Thomas'
    },
    {
        id: 7,
        image: Friend4,
        name: 'Thomas'
    },
    {
        id: 8,
        image: Friend1,
        name: 'Thomas'
    },
    {
        id: 9,
        image: Friend2,
        name: 'Thomas'
    },
    {
        id: 10,
        image: Friend4,
        name: 'Thomas'
    },
    {
        id: 11,
        image: Friend3,
        name: 'Thomas'
    },
    {
        id: 12,
        image: Friend2,
        name: 'Thomas'
    }
];
