import styled from 'styled-components';
import { Box, Card } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const StatisticsWrapper = styled(Box)`
    height: 100%;

    .full-width-section {
        width: calc(100% - 100px);
        margin: 0 0 0 auto;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        ${responsive.TABLET`
            width: 100%;
            margin: 0 auto;
        `}

        .statistic-content {
            width: 60%;
            margin-top: 10%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            ${responsive.DISPLAY`
                width: 70%;
            `}

            ${responsive.DESKTOP`
                width: 80%;
            `}

            ${responsive.LAPTOP`
                width: 90%;
            `}

            ${responsive.TABLET`
                align-items: center;
            `}

            ${responsive.PHABLET`
                align-items: center;
                margin-top: 70px;
            `}
            
            .statistic-header-content {
                ${responsive.TABLET`
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                `}

                .statistic-header {
                    font-family: ${FONTS.SECONDARY};
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 40px;
                    letter-spacing: -0.02em;
                    text-transform: capitalize;
                }
            }

            .statistic-card-container {
                display: flex;
                flex-direction: row;
                margin: 0 0 20px 0;
                justify-content: space-between;

                ${responsive.LAPTOP`
                    justify-content: space-between;
                `}

                ${responsive.TABLET`
                    width: 100%;
                    justify-content: normal;
                `}
            }
        }

        .card-text {
            font-family: ${FONTS.PRIMARY};

            &.dark {
                font-style: normal;
                font-weight: 400;
                font-size: 40px;
                line-height: 66px;
                color: #9c7f3c;
                text-align: center;
            }

            &.light {
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 16px;
                color: ${COLORS.TEXT_DARK};
                text-align: center;
                letter-spacing: 2px;
            }
        }
    }
`;

export const CardMainWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 32%;
    overflow: hidden;
    justify-content: space-between;
    padding: 5px;

    ${responsive.TABLET`
        width: 100%;
    `}

    .card-header {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 35px;
        text-transform: capitalize;

        ${responsive.TABLET`
            display: none;
        `}
    }
`;

export const DayCardWrapper = styled(Card)`
    &.statistics-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 460px;
        gap: 16px;
        border-radius: 24px 12px;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        border: 1.82846px solid #ffffff;
        filter: drop-shadow(0px 3.65692px 14.6277px rgba(0, 0, 0, 0.1));
        backdrop-filter: blur(18.2846px);
        padding: 20px 0 0;
        position: relative;

        ${responsive.TABLET`
            box-shadow: none;
            filter: none;
            height: auto;
        `}
    }

    .statistics-header-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 20px;
        max-height: 100px;
    }

    .statistics-content {
        height: 234px;
        width: 234px;

        .CircularProgressbar {
            .CircularProgressbar-trail {
                stroke-width: 0.5px;
                stroke: #b08c3d;
            }

            .CircularProgressbar-path {
                stroke: #ffd26d;
                background: conic-gradient(
                    from 0deg at 50% 50%,
                    #fff6e1 360deg,
                    #ffd26d 258.14deg,
                    #fff6e1 0deg
                );
            }
        }

        .meditation-progress-inner-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 140px;
            gap: 4px;

            .current-week-day {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 14px;
                color: ${COLORS.TEXT_DARK};
                letter-spacing: 5.5px;
                text-transform: uppercase;
                width: 100%;
                text-align: center;
            }
            .total-meditation-time {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 40px;
                color: ${COLORS.PRIMARY_DARK};
                width: 100%;
                text-align: center;
            }
            .goal-in-percentage {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 14px;
                color: ${COLORS.TEXT_DARK};
                letter-spacing: 3px;
                width: 100%;
                text-align: center;
            }

            .arrow-icon-container {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                position: absolute;
                bottom: 0;
                top: 47%;

                .left-arrow {
                    position: absolute;
                    left: 12%;
                    cursor: pointer;
                }

                .right-arrow {
                    position: absolute;
                    right: 12%;
                    cursor: pointer;

                    &.removeCursor {
                        cursor: not-allowed;
                    }
                }
            }
        }
    }

    .footer-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 40px;
        height: 144px;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0px 20px 20px 20px;

        ${responsive.TABLET`
            position: relative;
            align-items: center;
        `}

        .meditation-count {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .live-session-count {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
`;

export const WeekCardWrapper = styled(Card)`
    &.statistics-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 460px;
        gap: 16px;
        border-radius: 24px 12px;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        border: 1.82846px solid #ffffff;
        filter: drop-shadow(0px 3.65692px 14.6277px rgba(0, 0, 0, 0.1));
        backdrop-filter: blur(18.2846px);
        padding: 20px 0 0;
        position: relative;

        ${responsive.TABLET`
            box-shadow: none;
            filter: none;
            height: auto;
        `}
    }

    .statistics-header-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 20px;
        max-height: 100px;
    }

    .statistics-chart-content {
        height: 180px;
        width: 100%;

        .recharts-cartesian-axis-tick-value {
            font-size: 12px;
            color: ${COLORS.MEDIUM_GREY};
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 13px;
            letter-spacing: 2px;
        }
    }

    .footer-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        height: 144px;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0px 20px 20px 20px;

        ${responsive.TABLET`
            position: relative;
            align-items: center;
        `}

        .count-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 40px;

            .meditation-count {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .live-session-count {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
    .btn-container {
        display: flex;
        flex-direction: row;
        width: 200px;
        height: 29px;

        &.hide-view {
            display: none;
        }

        .daily-average-btn {
            width: 100px;
            border: 2px solid ${COLORS.MEDIUM_GREY};
            border-radius: 10px 0px 0px 10px;
            background: ${COLORS.MEDIUM_GREY};

            .btn-text {
                width: 100px;
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 13px;
                color: #ffffff;

                &.selected-text {
                    color: ${COLORS.PRIMARY};
                }
            }
        }

        .weekly-total-btn {
            width: 100px;
            border: 2px solid ${COLORS.MEDIUM_GREY};
            border-radius: 0px 10px 10px 0px;
            background: ${COLORS.MEDIUM_GREY};

            .btn-text {
                width: 100px;
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 13px;
                color: #ffffff;

                &.selected-text {
                    color: ${COLORS.PRIMARY};
                }
            }
        }

        .selected-btn {
            background: #ffffff;
        }
    }
`;

export const MonthCardWrapper = styled(Card)`
    &.statistics-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 460px;
        gap: 16px;
        border-radius: 24px 12px;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        filter: drop-shadow(0px 3.65692px 14.6277px rgba(0, 0, 0, 0.1));
        backdrop-filter: blur(18.2846px);
        position: relative;
        padding: 20px 0 0;

        ${responsive.TABLET`
            box-shadow: none;
            filter: none;
            height: auto;
        `}
    }

    .statistics-header-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 20px;
        max-height: 100px;
    }

    .statistics-chart-content {
        height: 160px;
        width: 100%;
        margin: 0;

        .chart-date-range-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 0px 2px;

            ${responsive.MOBILE`
                margin: 0px 9px;
            `}
            .date-text {
                margin: 6px 6px;
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 13px;
                letter-spacing: 2px;
                color: ${COLORS.MEDIUM_GREY};
            }
        }
    }

    .footer-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        height: 144px;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0px 20px 20px 20px;

        ${responsive.TABLET`
            position: relative;
            align-items: center;
        `}

        .count-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 40px;

            .meditation-count {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .live-session-count {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
    .btn-container {
        display: flex;
        flex-direction: row;
        width: 200px;
        height: 29px;

        &.hide-view {
            display: none;
        }

        .daily-average-btn {
            width: 100px;
            border: 2px solid ${COLORS.MEDIUM_GREY};
            border-radius: 10px 0px 0px 10px;
            background: ${COLORS.MEDIUM_GREY};

            .btn-text {
                width: 100px;
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 13px;
                color: #ffffff;

                &.selected-text {
                    color: ${COLORS.PRIMARY};
                }
            }
        }

        .monthly-total-btn {
            width: 100px;
            border: 2px solid ${COLORS.MEDIUM_GREY};
            border-radius: 0px 10px 10px 0px;
            background: ${COLORS.MEDIUM_GREY};

            .btn-text {
                width: 100px;
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 13px;
                color: #ffffff;

                &.selected-text {
                    color: ${COLORS.PRIMARY};
                }
            }
        }

        .selected-btn {
            background: #ffffff;
        }
    }
`;

export const StatisticAnalyticsDurationWrapper = styled(Box)`
    width: 270px;
    display: flex;
    flex-direction: row;
    gap: 26px;

    ${responsive.TABLET`
        width: 100%;
    `}

    .duration-btn {
        border: 1.5px solid #f2f2f2;
        border-radius: 22px;
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-transform: uppercase;
        color: ${COLORS.MEDIUM_GREY};
        background: #f2f2f2;

        &.selected {
            background: #fff;
            border: 1.5px solid ${COLORS.PRIMARY_DARK};
            color: ${COLORS.PRIMARY_DARK};
        }
    }
`;
