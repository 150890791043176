import {
    MenuItem,
    Select,
    Typography,
    InputLabel,
    FormControl,
    Collapse,
    FormHelperText,
    TextField
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { MeditationDetailsCardWrapper } from './MeditationTimer.style';
import { ReactComponent as RightArrow } from 'Assets/images/Common/next-arrow.svg';
import { ReactComponent as DownArrow } from 'Assets/images/Common/selected-back-arrow.svg';
import { ReactComponent as DropDownIcon } from 'Assets/images/Common/down-arrow.svg';
import { ReactComponent as UpArrowIcon } from 'Assets/images/MeditationTimer/up-arrow.svg';

// import DatePicker from 'react-mobile-datepicker';
import { TaoButton } from 'Components/common/Button.style';
import moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TaoTextField from 'Components/common/TextField';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { useCallback } from 'react';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import { useRef } from 'react';
import TaoScrollbar from 'Components/common/Scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'Redux/App/Actions';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

let OPTIONS = [
    { value: 1, label: 'Start Sound', name: 'start', soundValue: 'start' },
    { value: 2, label: 'Ambient Sound', name: 'ambient', soundValue: 'ambient' },
    { value: 3, label: 'End Sound', name: 'end', soundValue: 'start' }
];

let TIME_MENU = [
    { id: 1, text: '1 min', time: 1 },
    { id: 2, text: '3 min', time: 3 },
    { id: 3, text: '5 min', time: 5 },
    { id: 4, text: '10 min', time: 10 },
    { id: 5, text: '15 min', time: 15 },
    { id: 6, text: '20 min', time: 20 },
    { id: 7, text: '25 min', time: 25 },
    { id: 8, text: '30 min', time: 30 },
    { id: 9, text: '45 min', time: 45 },
    { id: 10, text: '1 hour', time: 60 },
    { id: 11, text: '2 hour', time: 120 },
    { id: 12, text: '4 hour', time: 240 },
    { id: 13, text: '8 hour', time: 480 }
];

const SOUND_TYPE = {
    START_SOUND: 'START_SOUND',
    AMBIENT_SOUND: 'AMBIENT_SOUND'
};
const initialValues = {
    time: 30,
    name: '',
    start: [],
    ambient: [],
    end: [],
    reminderTime: null,
    image: null
};
const validationSchema = Yup.object({
    name: Yup.string().nullable().required('Please enter meditation name'),
    start: Yup.array().nullable().min(1, 'Please select start timer'),
    ambient: Yup.array().nullable().min(1, 'Please select ambient sound'),
    end: Yup.array().nullable().min(1, 'Please select end timer'),
    // reminderTime: Yup.string().nullable().required('Please select remind time'),
    image: Yup.string().nullable().required('Please select image')
});

const MeditationEditCard = (props) => {
    const userInfo = useSelector((state) => state.Auth.userInfo);
    const [soundsOpen, setSoundsOpen] = useState(false);
    const musicRef = useRef(null);
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const [soundAndImagesList, setList] = useState({
        images: [],
        sounds: { start: [], ambient: [] }
    });
    const API = useMemo(() => new Api(), []);
    // const [dateOpen, setDateOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const onSubmit = async (values) => {
        const { isEditingCard, selectedMeditationCard } = props;
        setLoading(true);
        let reqBody = {
            meditationTime: values?.time,
            name: values?.name,
            startSound: values?.start[0],
            endSound: values?.end[0],
            ambientSound: values?.ambient[0] || null,
            remindTime: values?.reminderTime
                ? moment(values?.reminderTime, 'HH:mm:ss').utc().format('HH:mm') + ':00'
                : null,
            background: values?.image
        };
        if (isEditingCard) {
            reqBody['meditationId'] = selectedMeditationCard?.meditationId;
            reqBody['reminderTime'] = reqBody.remindTime;
        }

        let response = null;
        if (!isEditingCard)
            response = await API.post(API_URL.MEDITATION_TIMER, {
                data: reqBody
            });
        else {
            response = await API.put(API_URL.MEDITATION_TIMER, {
                data: reqBody
            });
        }
        if (response) {
            dispatch(showToast(response?.data?.message));
            setLoading(false);
            props?.updateList();
        }
    };

    const fetchImagesAndSounds = useCallback(async () => {
        setLoading(true);
        let response = await Promise.all([
            API.get(API_URL.MEDITATION_BG_IMAGES),
            API.get(API_URL.SOUND_LIST, { params: { soundType: SOUND_TYPE?.START_SOUND } }),
            API.get(API_URL.SOUND_LIST, { params: { soundType: SOUND_TYPE?.AMBIENT_SOUND } })
        ]);
        if (response?.length) {
            setList({
                images: response[0]?.data?.data,
                sounds: {
                    start: response[1]?.data?.data?.list,
                    ambient: [...response[2]?.data?.data?.list]
                }
            });
            const { setFieldValue } = formRef.current;
            let defaultStartSound = response[1]?.data?.data?.list.filter(
                (sound) => sound.isDefaultForStart
            )[0]?.soundId;
            let defaultEndSound = response[1]?.data?.data?.list.filter(
                (sound) => sound.isDefaultForEnd
            )[0]?.soundId;
            let defaultAmbientSound = response[2]?.data?.data?.list.filter(
                (sound) => sound.isDefaultForAmbient
            )[0]?.soundId;
            let defaultImage = response[0]?.data?.data?.filter((image) => image.isDefault)[0]
                ?.backgroundId;

            defaultStartSound && setFieldValue('start', [defaultStartSound]);
            defaultEndSound && setFieldValue('end', [defaultEndSound]);
            defaultAmbientSound && setFieldValue('ambient', [defaultAmbientSound]);
            defaultImage && !props?.isEditingCard && setFieldValue('image', defaultImage);

            setLoading(false);
        }
    }, [API, setList, props?.isEditingCard]);

    const soundMenu = (soundArray) =>
        soundArray?.map((option, index) => (
            <MenuItem
                key={option?.soundId || index}
                className="menu-items"
                value={option.soundId || ''}>
                {option.name}
            </MenuItem>
        ));

    const setInitData = useCallback(() => {
        if (!props?.isEditingCard) return formRef?.current?.resetForm();

        const { setFieldValue } = formRef?.current;
        const {
            meditationTime,
            name,
            startSound,
            ambientSound,
            endSound,
            backgroundImage,
            reminderTime
        } = props?.selectedMeditationCard;
        if (props?.selectedMeditationCard?.meditationId) {
            let remindTime = moment.utc(reminderTime, 'HH:mm:ss').local().format('HH:mm:ss');
            let time = new Date();
            time.setHours(remindTime?.split(':')?.[0]);
            time.setMinutes(remindTime?.split(':')?.[1]);

            setFieldValue('time', parseInt(meditationTime) || 30);
            setFieldValue('name', name);
            setFieldValue('start', [startSound?.soundId]);
            setFieldValue('end', [endSound?.soundId]);
            setFieldValue('ambient', [ambientSound?.soundId]);
            setFieldValue('image', backgroundImage?.backgroundImageId);
            setFieldValue('reminderTime', reminderTime ? moment(time) : null);
        }
    }, [props?.selectedMeditationCard, props?.isEditingCard]);

    const handleRemindDaily = (reminder) => {
        const { setFieldValue } = formRef.current;

        if (!reminder) return setFieldValue('reminderTime', null);

        let AmPm = moment(reminder?.$d).format('a');

        const eligibleMinutes = [0, 15, 30, 45];

        if (!eligibleMinutes.includes(reminder?.$m) && AmPm && reminder?.$m) {
            return setFieldValue('reminderTime', moment(`${reminder?.$H}:00 ${AmPm}`, 'hh:mm a'));
        }
        setFieldValue('reminderTime', moment(`${reminder?.$H}:${reminder?.$m} ${AmPm}`, 'hh:mm a'));
    };

    const handleSetTime = () => {
        if (!formRef?.current?.values?.reminderTime) {
            let minutes = moment().format('mm');
            let hours = moment().format('hh');
            let AmPm = moment().format('a');
            let setTime;

            if (minutes === 0) {
                setTime = `${hours} : ${minutes}`;
            } else if (minutes <= 15) {
                setTime = `${hours} : 15 ${AmPm}`;
            } else if (minutes <= 30) {
                setTime = `${hours} : 30 ${AmPm}`;
            } else if (minutes <= 45) {
                setTime = `${hours} : 45 ${AmPm}`;
            } else {
                hours++;
                setTime = `${hours} : 00 ${AmPm}`;
            }

            formRef?.current?.setFieldValue('reminderTime', moment(setTime, 'hh:mm a'));
        }
        return;
    };

    useEffect(() => {
        setInitData();
        fetchImagesAndSounds();
    }, [fetchImagesAndSounds, setInitData]);

    return (
        <MeditationDetailsCardWrapper
            isSlideFull={formRef?.current?.values?.time === 60}
            isBottomBorderRequired={true}
            selectedCard={props.meditation}>
            <Box className="container-edit">
                <Box className="flex f-v-center">
                    <Box className="flex f-v-center">
                        <UpArrowIcon
                            className="arrow-icon"
                            onClick={() => props?.updateList({}, false)}
                        />
                        <Typography className="title">
                            {props.isEditingCard ? 'Edit' : 'New'} Timer
                        </Typography>
                    </Box>
                </Box>

                <Box className="timer-container">
                    <TaoLoader
                        isLoading={isLoading}
                        backdropCustomStyle={{ borderRadius: '30px' }}
                    />
                    <TaoScrollbar>
                        <Formik
                            innerRef={formRef}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting }) =>
                                onSubmit(values, setSubmitting)
                            }>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleSubmit,
                                setFieldValue
                            }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    className="full-width"
                                    style={{ padding: '0 20px 20px' }}>
                                    <Box
                                        className="flex f-v-center full-width"
                                        style={{ justifyContent: 'space-between' }}>
                                        <FormControl
                                            className="full-width form-margin"
                                            variant="filled">
                                            <InputLabel>Select Duration</InputLabel>
                                            <Select
                                                value={values?.time}
                                                key="time"
                                                name="time"
                                                variant="filled"
                                                disableUnderline
                                                classes={{
                                                    select: 'selected',
                                                    icon: 'down-arrow-icon'
                                                }}
                                                MenuProps={{
                                                    classes: { paper: 'menuPaper' }
                                                }}
                                                className="selector options"
                                                IconComponent={(props) => (
                                                    <DropDownIcon {...props} />
                                                )}
                                                onChange={handleChange}>
                                                {TIME_MENU?.map((option) => (
                                                    <MenuItem
                                                        key={option?.id}
                                                        className="menu-items"
                                                        value={option.time}>
                                                        {option.text}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <Box className="full-width">
                                        <TaoTextField
                                            id="filled-basic"
                                            label="Name"
                                            key="name"
                                            name="name"
                                            variant="filled"
                                            value={values.name}
                                            onChange={handleChange}
                                        />
                                    </Box>

                                    <Collapse
                                        className="full-width"
                                        orientation="vertical"
                                        in={!!errors?.name}
                                        collapsedSize={'0px'}>
                                        <FormHelperText error style={{ margin: '10px' }}>
                                            {touched?.name && errors?.name}
                                        </FormHelperText>
                                    </Collapse>

                                    <Box className="sounds-container">
                                        <Box
                                            className="hover flex f-v-center"
                                            onClick={() => setSoundsOpen((prev) => !prev)}>
                                            <Box style={{ width: '15px' }}>
                                                {soundsOpen ? (
                                                    <DownArrow
                                                        style={{ width: '14px', height: '9px' }}
                                                    />
                                                ) : (
                                                    <RightArrow
                                                        style={{ width: '8px', height: '14px' }}
                                                    />
                                                )}
                                            </Box>

                                            <Box className="sound-text">
                                                <Typography>Sounds</Typography>
                                            </Box>
                                        </Box>

                                        <Collapse
                                            className="full-width"
                                            orientation="vertical"
                                            in={soundsOpen ? false : true}
                                            collapsedSize={'0px'}>
                                            <Box className="line" />
                                        </Collapse>
                                    </Box>

                                    <Collapse
                                        className="full-width"
                                        orientation="vertical"
                                        in={soundsOpen ? true : false}
                                        collapsedSize={'0px'}>
                                        <Box
                                            style={{ marginBottom: '10px' }}
                                            className="flex full-width">
                                            <Box className="vertical-line" />

                                            {soundsOpen && (
                                                <Box className="full-width drop-down-padding">
                                                    {OPTIONS.map((soundOption) => (
                                                        <FormControl
                                                            key={soundOption?.value}
                                                            className="full-width form-margin"
                                                            variant="filled">
                                                            <InputLabel>
                                                                {soundOption?.label}
                                                            </InputLabel>
                                                            <Select
                                                                value={values?.[soundOption?.name]}
                                                                key={soundOption?.name}
                                                                name={soundOption?.name}
                                                                multiple
                                                                variant="filled"
                                                                disableUnderline
                                                                classes={{
                                                                    select: 'selected',
                                                                    icon: 'down-arrow-icon'
                                                                }}
                                                                MenuProps={{
                                                                    classes: { paper: 'menuPaper' }
                                                                }}
                                                                className="selector options"
                                                                IconComponent={(props) => (
                                                                    <DropDownIcon {...props} />
                                                                )}
                                                                onClose={() =>
                                                                    musicRef?.current?.pause()
                                                                }
                                                                onChange={(e) => {
                                                                    if (!e.target.value.length) {
                                                                        musicRef?.current?.pause?.();
                                                                        let urls =
                                                                            soundAndImagesList
                                                                                .sounds[
                                                                                soundOption
                                                                                    .soundValue
                                                                            ];
                                                                        let url = urls.find(
                                                                            (item) =>
                                                                                item?.soundId ===
                                                                                formRef?.current
                                                                                    ?.values?.[
                                                                                    soundOption
                                                                                        ?.name
                                                                                ]?.[0]
                                                                        );

                                                                        musicRef.current =
                                                                            new Audio(url.sound);
                                                                        musicRef?.current?.play?.();
                                                                        return;
                                                                    }

                                                                    let lastId =
                                                                        e.target.value[
                                                                            e.target.value?.length -
                                                                                1
                                                                        ];
                                                                    if (
                                                                        !lastId &&
                                                                        soundOption?.name !==
                                                                            'start' &&
                                                                        soundOption?.name !== 'end'
                                                                    ) {
                                                                        formRef.current.setFieldValue(
                                                                            [soundOption?.name],
                                                                            ['']
                                                                        );
                                                                        return;
                                                                    }

                                                                    musicRef?.current?.pause?.();
                                                                    let urls =
                                                                        soundAndImagesList.sounds[
                                                                            soundOption.soundValue
                                                                        ];
                                                                    let url = urls.find(
                                                                        (item) =>
                                                                            item?.soundId === lastId
                                                                    );

                                                                    musicRef.current = new Audio(
                                                                        url.sound
                                                                    );
                                                                    musicRef?.current?.play?.();

                                                                    formRef.current.setFieldValue(
                                                                        [soundOption?.name],
                                                                        [lastId]
                                                                    );
                                                                }}>
                                                                {soundMenu(
                                                                    soundOption?.name === 'start' ||
                                                                        soundOption?.name === 'end'
                                                                        ? soundAndImagesList?.sounds
                                                                              ?.start
                                                                        : soundAndImagesList?.sounds
                                                                              ?.ambient
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    ))}
                                                </Box>
                                            )}
                                        </Box>
                                    </Collapse>

                                    <Collapse
                                        className="full-width"
                                        orientation="vertical"
                                        in={!!(errors?.start || errors?.ambient || errors?.end)}
                                        collapsedSize={'0px'}>
                                        <FormHelperText error={true} style={{ margin: '10px' }}>
                                            {(touched?.start && errors?.start) ||
                                                (touched?.ambient && errors?.ambient) ||
                                                (touched?.end && errors?.end)}
                                        </FormHelperText>
                                    </Collapse>

                                    <Box className="time-picker-wrapper">
                                        <Typography className="time-picker-label">
                                            Remind Daily At:
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopTimePicker
                                                value={values?.reminderTime}
                                                onChange={(date) => {
                                                    handleRemindDaily(date);
                                                }}
                                                ampm={userInfo.is12HourFormat}
                                                minutesStep={15}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="text-field"
                                                        autoComplete="off"
                                                        onClick={handleSetTime}
                                                        InputProps={{
                                                            classes: {
                                                                notchedOutline:
                                                                    'time-picker-outline'
                                                            },
                                                            ...params.InputProps
                                                        }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        <FormHelperText error={true} style={{ margin: '10px' }}>
                                            {touched?.reminderTime && errors?.reminderTime}
                                        </FormHelperText>
                                    </Box>

                                    <Box className="background-image-container">
                                        <Box className="flex">
                                            <Typography className="background-image-title">
                                                Background
                                            </Typography>
                                        </Box>

                                        <Box className="flex image-padding flex-wrap">
                                            {soundAndImagesList?.images?.map((image, index) => (
                                                <Box
                                                    key={image?.backgroundId}
                                                    name="image"
                                                    sx={{
                                                        backgroundImage: `url(${image?.backgroundImageOriginal})`
                                                    }}
                                                    className={`image-design ${
                                                        values?.image === image?.backgroundId &&
                                                        'selected-image'
                                                    }`}
                                                    onClick={() =>
                                                        setFieldValue('image', image?.backgroundId)
                                                    }
                                                />
                                            ))}
                                        </Box>
                                        <FormHelperText error style={{ margin: '10px' }}>
                                            {touched?.image && errors?.image}
                                        </FormHelperText>
                                    </Box>

                                    <TaoButton type="submit">Save</TaoButton>
                                    <TaoButton
                                        className="cancel-button"
                                        onClick={() =>
                                            props?.updateList(
                                                props?.isEditingCard
                                                    ? props?.selectedMeditationCard || {}
                                                    : props?.firstTimer,
                                                false
                                            )
                                        }>
                                        Cancel
                                    </TaoButton>
                                </form>
                            )}
                        </Formik>
                    </TaoScrollbar>
                </Box>
            </Box>
        </MeditationDetailsCardWrapper>
    );
};

export default MeditationEditCard;
