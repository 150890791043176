import styled from 'styled-components';

import { COLORS, FONTS, responsive } from 'Styles/Constants';
import WelcomeImage1 from 'Assets/images/WelcomePage/Welcome_Graphic_01.svg';

export const WelcomePageWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;

    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 40px 0 0 0;
        position: relative;

        ${responsive.LAPTOP`
            padding: 30px 0 0 0;
        `}

        ${responsive.MOBILE`
            padding: 5vh 0 0 0;
        `}

        .bg-welcome-image-first {
            background-image: url(${WelcomeImage1});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            position: absolute;
            left: 0;
            width: 25vw;
            height: 50vh;
            top: 15%;
        }

        .welcome-image-1 {
            position: absolute;
            left: 0;
            width: 25vw;
            height: 50vh;
            top: 130px;
            z-index: 0;
            user-select: none;
            pointer-events: none;

            ${responsive.PHABLET`
                width:150px;
                height:225px;
                top:20%;
            `}
        }

        .welcome-image-2 {
            position: absolute;
            top: 110px;
            right: 0;
            width: 34vw;
            height: 42vh;
            opacity: 30%;
            z-index: 1;
            user-select: none;
            pointer-events: none;

            ${responsive.TABLET`
                top: 90px;   
            `};

            ${responsive.PHABLET`
                width:210px;
                height:190px;
                top:13%;
            `};
        }

        .content-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 625px;
            height: 100%;
            position: relative;

            ${responsive.LAPTOP`
                width: 510px;
            `}
            ${responsive.PHABLET`
                width: 410px;
            `}
                ${responsive.MOBILE`
                width: 100%;
            `}
                .content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;

                ${responsive.PHABLET`
                    height:750px;
                `}

                .header-logo-section {
                    padding: 0;
                    z-index: 2;

                    .tao-logo {
                        height: 45px;
                        width: 50px;
                    }

                    .heart-logo {
                        height: 45px;
                        width: 130px;
                    }
                }

                .header-text {
                    color: ${COLORS.PRIMARY_DARK};
                    opacity: 0.9;
                    font-weight: 700;
                    letter-spacing: -0.01em;
                    margin-top: 20px;
                    font-family: ${FONTS.SECONDARY};

                    ${responsive.LAPTOP`
                        margin-top: 10px;
                    `}

                    ${responsive.PHABLET`
                        align-self:start;
                        margin-top: 30px;
                        padding-left:15px;
                    `}

                    .header-text1 {
                        font-size: 33px;
                        line-height: 32px;

                        .header-sub-text1 {
                            ${responsive.PHABLET`
                                display: block;
                            `}
                        }
                    }

                    .header-text2 {
                        font-size: 40px;
                        line-height: 38px;
                    }
                }
            }

            .lottie-animation {
                position: absolute;
                top: -20px;
                z-index: 1;

                ${responsive.LAPTOP`
                    top:-10px;
                `}

                ${responsive.PHABLET`
                    top:110px;
                `}

                ${responsive.MOBILE`
                    left:10px;
                `}

                svg {
                    height: 625px !important;
                    width: 625px !important;

                    ${responsive.LAPTOP`
                        width: 510px !important;
                        height: 510px !important;
                    `}

                    ${responsive.PHABLET`
                        width: 410px !important;
                        height: 410px !important;
                    `}

                    ${responsive.MOBILE`
                        width: 100% !important;
                        height: 100% !important;
                    `}
                }
            }

            .golden-home-container {
                position: absolute;
                top: 370px;
                right: 0;
                z-index: 0;
                user-select: none;

                ${responsive.LAPTOP`
                    top: 290px;
                `}

                ${responsive.PHABLET`
                    top: 360px;
                `}
                
                ${responsive.MOBILE`
                    right: -10px;
                `}

                .golden-home {
                    width: 300px;
                    height: 275px;

                    ${responsive.LAPTOP`
                        width: 240px;
                        height: 220px;
                    `}

                    ${responsive.PHABLET`
                        width: 195px;
                        height: 180px;
                    `}
                }

                .golden-home-text-container {
                    margin: -5px 0 0 16px;
                    color: ${COLORS.PRIMARY_DARK};
                    font-family: ${FONTS.SECONDARY};

                    ${responsive.LAPTOP`
                        margin-left:0;
                    `}

                    ${responsive.PHABLET`
                        margin-left:-80px;
                    `}

                    ${responsive.MOBILE`
                        margin-left:-90px;
                    `}

                    .golden-home-text1 {
                        font-size: 36px;
                        font-weight: 700;
                        line-height: 39px;
                        letter-spacing: -0.01em;
                    }

                    .golden-home-text2 {
                        font-size: 45px;
                        font-weight: 700;
                        line-height: 49px;
                        letter-spacing: -0.01em;
                    }
                }
            }

            .actions-container {
                position: absolute;
                top: 720px;
                width: 370px;
                padding-bottom: 20px;

                ${responsive.LAPTOP`
                    top: 590px;
                `}

                ${responsive.PHABLET`
                    top:auto;
                    bottom:0;
                `}

                ${responsive.MOBILE`
                    width: calc(100% - 30px);
                `}
                
                .link-container {
                    color: ${COLORS.TEXT_DARK};
                    margin-top: min(34px, 5%);

                    .bold {
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    }

                    .link-text {
                        ${responsive.PHABLET`
                            display:none
                        `}
                    }

                    .link-text-mobile {
                        display: none;

                        ${responsive.PHABLET`
                            display:block;
                            color: ${COLORS.TEXT_DARK};
                            margin: 0 5px 0 0;
                        `};
                    }

                    .text-divider {
                        display: none;

                        ${responsive.PHABLET`
                            display:block;
                            border:1px solid ${COLORS.TEXT_DARK};
                        `};
                    }

                    .login-link {
                        margin: 0 0 0 5px;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .link {
                        text-decoration: underline;
                    }
                }

                .button {
                    width: 100%;
                    box-shadow: 0px 4px 12px 0px #00000040;

                    &:hover {
                        background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                    }
                }

                .button-color {
                    background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                    border-radius: 12px;
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    color: ${COLORS.WHITE};
                    margin: 5px 0 0;
                    height: 60px;
                    font-size: 16px;
                }
            }
        }
    }
`;
