import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from '@mui/material';
import Lottie from 'lottie-react';

import { WelcomePageWrapper } from './WelcomePage.style';
import TaoLogo from 'Assets/Logo/heart-in-circle.svg';
// import HeartLogo from 'Assets/images/Header/oneness-heart-logo.svg';
import GoldenHome from 'Assets/images/WelcomePage/Home-Golden-Line-Drawing-1.svg';
import WelcomeAnimation from 'Assets/AnimationFiles/WelcomePage/Mascot-fly-in.json';
import WelcomeImage2 from 'Assets/images/WelcomePage/Welcome_Graphic_02.svg';
import { URL_BEGIN_EXPERIENCE, URL_HOME_PAGE, URL_LOGIN, URL_SIGN_UP } from 'Helpers/Paths';
import { useDispatch } from 'react-redux';
import { hideTopBarWithTransition, showTopBarWithTransition } from 'Redux/App/Actions';

const WelcomePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleNavigate = (url, state = {}) => {
        if (url) {
            navigate(url, {
                state
            });
        }
    };

    useEffect(() => {
        dispatch(hideTopBarWithTransition());
        return () => {
            dispatch(showTopBarWithTransition());
        };
    }, [dispatch]);

    return (
        <>
            <WelcomePageWrapper>
                <Box className="container">
                    <Box className="bg-welcome-image-first" />
                    {/* <img src={WelcomeImage1} alt="welcome 1" className="welcome-image-1" /> */}
                    <img src={WelcomeImage2} alt="welcome 2" className="welcome-image-2" />

                    <Box className="content-container">
                        <Box className="content">
                            <IconButton
                                onClick={() => handleNavigate(URL_HOME_PAGE)}
                                className="header-logo-section">
                                <img src={TaoLogo} alt="" className="tao-logo" />
                                {/* <img src={HeartLogo} alt="" className="heart-logo" /> */}
                            </IconButton>

                            <Box className="header-text">
                                <span className="header-text1">
                                    <span className="header-sub-text1">Home is</span> where the{' '}
                                </span>
                                <span className="header-text2">Heart is.</span>
                            </Box>
                        </Box>

                        <Lottie
                            loop={true}
                            autoplay
                            animationData={WelcomeAnimation}
                            className="lottie-animation"
                        />

                        <Box className="golden-home-container">
                            <img src={GoldenHome} className="golden-home" alt="golder-home" />

                            <Box className="golden-home-text-container">
                                <span className="golden-home-text1">{`Welcome `}</span>
                                <span className="golden-home-text2">Home.</span>
                            </Box>
                        </Box>

                        <Box className="actions-container">
                            <Button
                                type="button"
                                className="button button-color"
                                variant="contained"
                                onClick={() => handleNavigate(URL_BEGIN_EXPERIENCE)}>
                                Begin Your Experience
                            </Button>

                            <Box className="flex f-h-center link-container">
                                <span className="link-text">Already have an account?</span>
                                <span
                                    className="link-text-mobile bold link "
                                    onClick={() => handleNavigate(URL_SIGN_UP)}>
                                    Create Account
                                </span>
                                <Box className="text-divider" />
                                <Box
                                    className="login-link bold link"
                                    onClick={() => handleNavigate(URL_LOGIN)}>
                                    Login
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </WelcomePageWrapper>
        </>
    );
};

export default WelcomePage;
