import { Box } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const ProgramsWrapper = styled(Box)`
    margin: 0;
    padding: 0;
    gap: 20px;
    width: 100%;

    .program-title {
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        font-size: 24px;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_DARK};

        ${responsive.TABLET`
            display: none;
        `}
    }

    .in-progress-section,
    .completed-section {
        ${responsive.TABLET`
            flex-direction: column;
        `}
        .in-progress-title {
            padding: 10px 0 0 0;
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 35px;
            text-transform: capitalize;
            color: ${COLORS.TEXT_DARK};
            ${responsive.TABLET`
                font-weight: 600;
                color: ${COLORS.PRIMARY_DARK};
                margin: 0 0 0 21px;
            `}
        }

        .completed-title {
            padding: 10px 0 0 0;
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 35px;
            text-transform: capitalize;
            color: ${COLORS.MEDIUM_GREY};
            opacity: 0.7;
            ${responsive.TABLET`
                margin: 0 0 0 21px;
                font-weight: 600;
                color: ${COLORS.PRIMARY_DARK};
                opacity: 1;
            `}
        }
        .in-progress-data {
            min-width: 340px;
            width: calc(100% - 120px);

            ${responsive.TABLET`
                width: 100%;
                min-width: 250px;
            `}
            .in-progress-card {
                padding: 15px;
                gap: 15px;
                ${responsive.TABLET`
                    padding: 15px 15px 15px 21px;
                `}
                &:nth-child(2n) {
                    background: rgba(242, 242, 242, 0.8);
                }
                .img-section {
                    position: relative;
                    margin: 0 20px 0 0;
                    .card-img {
                        width: 150px;
                        border-radius: 11px 3px;
                        height: 80px;
                    }
                    .img-borders {
                        position: absolute;
                        top: -19px;
                        left: -25px;
                        transform: scale(0.709);
                    }
                    .play-button {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 10px;
                        background-color: rgba(0, 0, 0, 0.3);
                        .playlist-icon {
                            max-width: 20px;
                            max-height: 20px;
                        }
                    }
                    .circular-progress-icon {
                        position: absolute;
                        top: 18%;
                        right: -15%;
                        display: inline-flex;
                        background-color: #ffffff;
                        border-radius: 28px;
                        .circular-progress {
                            height: 48px !important;
                            width: 48px !important;
                            z-index: 10;
                        }
                        .circular-progress-bg {
                            position: absolute;
                            top: 0;
                            left: 0;
                            /* bottom: 0;
                            right: 0; */
                            position: absolute;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 48px;
                            width: 48px;
                            background: ${COLORS.LIGHT_GREY};
                            border-radius: 50%;
                            /* fonts style */
                            font-family: ${FONTS.PRIMARY};
                            font-style: normal;
                            font-weight: 500;
                            font-size: 13.3333px;
                            line-height: 19px;
                            color: ${COLORS.TEXT_DARK};
                        }
                    }
                }
                .description-section {
                    .title {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 20px;
                        color: ${COLORS.PRIMARY_WARM};
                        white-space: pre-wrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .started-on {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 17px;
                        color: ${COLORS.TEXT_DARK};

                        &.date {
                            font-family: ${FONTS.PRIMARY_BOLD};
                        }
                    }
                }
            }
            .load-more {
                padding: 20px 0;
                width: max-content;
                margin: 0 auto;

                &:hover {
                    .load-more-arrow {
                        transition: all 0.3s ease-in-out;
                        margin: 5px 0 0;
                    }
                }

                .load-more-text {
                    color: ${COLORS.PRIMARY};
                }
                .load-more-arrow {
                    width: 10px;
                    height: 10px;
                    border-right: 1px solid ${COLORS.PRIMARY};
                    border-bottom: 1px solid ${COLORS.PRIMARY};
                    transform: rotate(45deg);
                }
            }
        }
    }
    .empty {
        width: 100%;
        height: 200px;
        .message {
            font-family: ${FONTS.PRIMARY};
            color: ${COLORS.TEXT_DARK};
            font-style: normal;
            font-size: 24px;
        }
    }
`;

export const CardImageWrapper = styled.div`
    width: 150px;
    border-radius: 11px 3px;
    height: 80px;
    margin: 0 0 0 10px;
    background-image: ${(props) => props.programImage && `url(${props.programImage})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;
