import styled from 'styled-components';
import { responsive } from 'Styles/Constants';
import { Box } from '@mui/system';

export const QuestionnaireWrapper = styled.div`
    overflow: hidden;
    position: relative;
    background-color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .questionnaire-screen-enter {
        opacity: 0;
    }

    .questionnaire-screen-enter-active {
        opacity: 1;
        transition: opacity 650ms;
    }

    .questionnaire-screen-exit {
        opacity: 1;
    }

    .questionnaire-screen-exit-active {
        opacity: 0;
        transition: opacity 650ms;
    }

    ${responsive.PHABLET`
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    `}

    .profile-pic-container {
        display: none;
        z-index: 4;
        ${responsive.PHABLET`
            display: block;
            max-width: 95%;
            margin: 35px auto 0 auto;
        `}
        .profile-pic-image {
            ${responsive.PHABLET`
                width: 52px;
                height: 52px;
                border-radius: 50%;
            `}
        }
    }

    .tao-watermark {
        display: none;
        ${responsive.PHABLET`
            display: block;
            position: absolute;
            height: 405px;
            width: 310px;
            right: -69px;
            z-index: 1;
            top: 69px;
        `}
    }

    .clouds {
        position: absolute;
        left: ${(props) => props.cloudPosition + 'px'};
        bottom: 5px;
        transition-property: left;
        transition-duration: 800ms;
        transition-timing-function: linear;
        ${responsive.DISPLAY`
            height: 360px
        `}
        ${responsive.DESKTOP`
            height: 300px
        `}
        ${responsive.PHABLET`
            width: 260px;
            height: 190px;
            bottom: 0px;
        `}
    }

    .birds {
        position: absolute;
        right: ${(props) => props.birdsPosition + 'px'};
        /* bottom: 25vh; */
        bottom: 20vh;
        transition-property: right;
        transition-duration: 800ms;
        transition-timing-function: linear;
        ${responsive.DISPLAY`
            height: 260px
        `}
        ${responsive.DESKTOP`
            height: 220px
        `}
        ${responsive.PHABLET`
            width: 224px;
            height: 145px;
            bottom: 20.5vh;
        `}
    }

    .question-content {
        width: 100%;
        align-items: center;
        overflow-y: auto;
        background-color: transparent;
        z-index: 10;
        margin-top: 114px;
        position: relative;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        overflow-x: hidden;

        ${responsive.DESKTOP`
            margin-top: 90px;
        `}

        ${responsive.LAPTOP`
            margin-top: 80px;
        `}

        ${responsive.TABLET`
            max-width: 90%;
            margin-top: 30px;
        `}

        .question-btn-container {
            display: flex;
            transition: top 750ms;
            position: absolute;

            .personalize-btn {
                width: 370px;
                color: white;
                height: 60px;
                margin-top: 40px;

                ${responsive.MOBILE`
                    width: 90%;
                    margin: 40px auto;
                `}
            }
        }
    }
`;

export const WavesAnimationWrapper = styled.div`
    width: 55%;
    position: absolute;
    bottom: 3vh;
    right: -20px;
    ${responsive.PHABLET`
        bottom: 14.5vh;
        width: 85%;
    `}
`;

export const WelcomeWrapper = styled.div`
    .welcome-header-text {
        font-weight: 700;
        font-size: 40px;
        font-family: 'Cormorant Bold';
    }

    .doll {
        ${responsive.DISPLAY`
            height: 130px;
        `}
        ${responsive.PHABLET`
            width: 116px;
            height: 120px;
        `}
    }

    .message-container {
        position: relative;
        padding: 100px 0 50px;

        ${responsive.TABLET`
            padding: 170px 0 50px;
        `}

        .lottie-animation {
            position: absolute;
            transform: translate(50%, -35%);
            right: 0;

            ${responsive.TABLET`
                left:0;
                transform: translate(-8%,-70%);
            `}

            ${responsive.PHABLET`
                transform: translate(-5%,-70%);
            `}
            /* FORCE UPDATE FOR MASCOT LOTTIE FILE */
            svg {
                width: 400px !important;
                height: 400px !important;

                ${responsive.TABLET`
                    width: 280px !important;
                    height: 280px !important;
                `}

                ${responsive.PHABLET`
                    width: 220px !important;
                    height: 220px !important;
                `}
            }
        }
    }

    .question-list-ul {
        margin: 0;
        li {
            ::marker {
                color: #65656565;
                /* content: "•"; */
            }
            margin: 0.5rem 0;
        }
    }
`;

export const QuestionFooterWrapper = styled(Box)`
    gap: 32px;
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;

    .btn {
        width: 148px;
        height: 60px;
        ${responsive.TABLET`
            width: 128px;
            height: 55px
        `}

        ${responsive.TABLET`
            width: 108px;
            height: 50px
        `}
    }
`;
