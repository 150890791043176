//core
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
    Autocomplete,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Typography,
    FormControlLabel,
    Divider,
    // RadioGroup PHASE 2,
    // Radio PHASE 2,
    FormHelperText,
    Slider
} from '@mui/material';
import moment from 'moment';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

//custom component
import {
    ProfileAccountInfoSectionWrapper,
    // CustomSwitch,
    // CustomRadioCheckedIcon PHASE 2,
    // CustomRadioIcon PHASE 2,
    ResizeWrapper
} from './AccountInfo.style';
// import { CustomSwitch } from 'Components/common/Switch.style';

import Api from 'Helpers/ApiHandler';
import { API_URL, URL_PROFILE, URL_PROFILE_VIEW } from 'Helpers/Paths';
// import ProfileInformationSection from './ProfileInformation'; PHASE 2
import {
    ACCESS_LEVELS_MODULEs,
    PROFILE_TAB_TYPE,
    PROFILE_ACCOUNT_INFO_MESSAGE,
    PREVIEW_PROFILE_MESSAGE,
    PHONE_EXP,
    PROFILE_OPTION
} from 'Helpers/Constants'; //PHASE 2
// import ProfileView from '../ProfileView/ProfileView'; PHASE 2
import { DeleteConfirmation } from 'Components/common/Modal/Delete';

//icons
import { ReactComponent as GiftIcon } from 'Assets/images/Profile/gift.svg';
import { ReactComponent as Camera } from 'Assets/images/Profile/camera1.svg';
import { ReactComponent as CrossIcon } from 'Assets/images/Profile/cross-icon.svg';
import { ReactComponent as SelectDropDownIcon } from 'Assets/images/Common/down-arrow.svg';
import { ReactComponent as TickMark } from 'Assets/images/PlayList/tick-mark.svg';
import { ReactComponent as RestartIcon } from 'Assets/images/Common/refresh-icon.svg';

//images
import User_Img from 'Assets/images/Profile/default-user-icon.jpg';

import { useDispatch, useSelector } from 'react-redux';
import { updateUserInfo } from 'Redux/Auth/Actions';
import { showSubscriptionModal, showToast } from 'Redux/App/Actions';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from 'Helpers';
import { TaoButton } from 'Components/common/Button.style';
import TaoScrollbar from 'Components/common/Scrollbar';
import { useNavigate } from 'react-router-dom';
import ShareSocial from 'Components/common/Share/Share';
import usePermissions from 'Components/common/Permissions';
import { SubscriptionLightLock } from 'Components/common/SubscriptionLock';
import {
    ProfileAccountInfoTextField,
    ProfileAccountInfoDatePicker,
    ProfileAccountInfoCustomSwitch
} from './Common';
import ConfirmPassword from './ConfirmPassword/ConfirmPassword';

const DAY = 'Day',
    MONTH = 'Month',
    YEAR = 'Year';

const pickerInitialValues = {
    day: false,
    month: false,
    year: false
};

const GENDER_TYPE = ['Male', 'Female', 'Other', 'Prefer not to say'];

const userInfoFormValidation = Yup.object({
    firstName: Yup.string()
        .required('Please enter your first name')
        .matches(/(?!^\d+$)^.+$/, 'Please enter a valid first name')
        .max(30, 'First name should not exceed more than 30 characters'),
    lastName: Yup.string()
        .required('Please enter your last name')
        .matches(/(?!^\d+$)^.+$/, 'Please enter a valid last name')
        .max(30, 'Last name should not exceed more than 30 characters'),
    mobile_no: Yup.string()
        .matches(PHONE_EXP, 'Enter your cell phone number')
        .max(14, 'Please enter a valid cell phone number')
});

const ResizeImage = ({ file, onClose, saveImageAfterResize, imageType, ...props }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [cropImage, setCropImage] = useState(false);

    const onCropComplete = async (_, croppedAreaPixels) => {
        const croppedImage = await getCroppedImg(file, croppedAreaPixels, imageType);
        setCropImage(croppedImage);
    };

    return (
        <ResizeWrapper open classes={{ paper: 'paper' }} onClose={onClose}>
            <Typography className="title">Profile Pic</Typography>
            <Box sx={{ padding: '0 20px' }}>
                <Cropper
                    image={file}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    showGrid={false}
                    objectFit="horizontal-cover"
                    classes={{
                        containerClassName: 'crop-container',
                        cropAreaClassName: 'crop-area'
                    }}>
                    <Box className="profile-guideline" />
                </Cropper>
                <Box className="zoom-container">
                    <Box className="user-image small" />
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.01}
                        classes={{
                            track: 'slider-track',
                            rail: 'slider-rail',
                            thumb: 'slider-thumb',
                            root: 'slider-root'
                        }}
                        valueLabelDisplay="off"
                        onChange={async (e, value) => setZoom(value)}
                    />
                    <Box className="user-image large" />
                </Box>

                <TaoButton
                    style={{ margin: '5px 0' }}
                    onClick={() => saveImageAfterResize(cropImage)}>
                    Save <TickMark style={{ margin: '0 5px' }} />
                </TaoButton>
                <TaoButton
                    className="white"
                    style={{ margin: '5px 0' }}
                    onClick={() => {
                        setCrop({ x: 0, y: 0 });
                        setZoom(1);
                    }}>
                    Reset <RestartIcon style={{ margin: '0 5px' }} />
                </TaoButton>
            </Box>
        </ResizeWrapper>
    );
};

// function CustomRadioButton(props) {
//     return (
//         <Radio
//             sx={{
//                 '&:hover': {
//                     bgcolor: 'transparent'
//                 }
//             }}
//             className="radio-button"
//             disableRipple
//             color="default"
//             checkedIcon={<CustomRadioCheckedIcon />}
//             icon={<CustomRadioIcon />}
//             {...props}
//         />
//     );
// }

const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (!value) return;

            const nextKey = prefix ? `${prefix}.${key}` : key;
            if (typeof value === 'object') {
                transformObjectToDotNotation(value, nextKey, result);
            } else {
                result.push(nextKey);
            }
        });

        return result;
    };

    return transformObjectToDotNotation(formikErrors);
};

const ScrollToFieldError = ({ scrollBehavior = { behavior: 'smooth', block: 'center' } }) => {
    const { submitCount, isValid, errors } = useFormikContext();

    useEffect(() => {
        if (isValid) return;

        const fieldErrorNames = getFieldErrorNames(errors);
        if (fieldErrorNames.length <= 0) return;

        const element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);
        if (!element) return;

        element.scrollIntoView(scrollBehavior);
    }, [submitCount, errors, isValid, scrollBehavior]);

    return null;
};

function ProfileAccountInfo(props) {
    const fileUpload = useRef(null);
    const userDetailForm = useRef(null);
    const navigate = useNavigate(); //PHASE 2
    const API = useMemo(() => new Api(), []);

    const [userInfoData, setUserInfoData] = useState({});
    const [regions, setRegions] = useState([]);
    const [country, setCountry] = useState([]);
    const dispatch = useDispatch();
    const [lang, setLang] = useState([]);
    const [date, setDate] = useState(new Date());
    const [showBirthDate, setShowBirthDate] = useState(false);
    const [signUpType, setSignUpType] = useState('');
    const [getRangeState, setGetRangeState] = useState('');
    const userDetails = useSelector((state) => state.Auth.userInfo);
    const [isSearchable, setIsSearchable] = useState(false);
    const [viewAboutMe, setViewAboutMe] = useState(false);
    const [viewBirthday, setViewBirthday] = useState(false);
    const [viewGender, setViewGender] = useState(false);
    const [viewInterest, setViewInterest] = useState(false);
    const [profileLink, setProfileLink] = useState(' ');
    const [deleteImageModel, setDeleteImageModel] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [imageType, setImageType] = useState(null);
    const [resizeFileName, setResizeFileName] = useState('');
    const [languageSelectedId, setLanguageSelectedId] = useState([]);
    const profilePermissions = usePermissions(ACCESS_LEVELS_MODULEs?.EDIT_PROFILE);
    const [datePickerIconStatus, setDatePickerIconStatus] = useState(pickerInitialValues);

    const profileOptionsCheckBox = {
        [PROFILE_OPTION.BIRTHDAY]: {
            label: 'birthday',
            checked: viewBirthday,
            onChange: (e) => {
                handleShowOnMyProfile(
                    e?.target?.checked,
                    userDetails?.dateOfBirth,
                    viewBirthday,
                    PROFILE_OPTION?.BIRTHDAY,
                    PROFILE_ACCOUNT_INFO_MESSAGE?.UPDATE_BIRTHDAY
                );
            }
        },
        [PROFILE_OPTION.ABOUT_ME]: {
            label: 'about me',
            checked: viewAboutMe,
            onChange: (e) => {
                handleShowOnMyProfile(
                    e?.target?.checked,
                    userDetails?.aboutMe,
                    viewAboutMe,
                    PROFILE_OPTION?.ABOUT_ME,
                    PROFILE_ACCOUNT_INFO_MESSAGE?.UPDATE_ABOUT
                );
            }
        },
        [PROFILE_OPTION.GENDER]: {
            label: 'gender',
            checked: viewGender,
            onChange: (e) => {
                handleShowOnMyProfile(
                    e?.target?.checked,
                    userDetails?.gender,
                    viewGender,
                    PROFILE_OPTION?.GENDER,
                    PROFILE_ACCOUNT_INFO_MESSAGE?.UPDATE_GENDER
                );
            }
        },
        [PROFILE_OPTION.INTEREST]: {
            label: 'interests',
            checked: viewInterest,
            onChange: (e) => {
                handleShowOnMyProfile(
                    e?.target?.checked,
                    userDetails?.userInterests?.length,
                    viewInterest,
                    PROFILE_OPTION?.INTEREST,
                    PROFILE_ACCOUNT_INFO_MESSAGE?.UPDATE_INTEREST
                );
            }
        }
    };

    const initialValues = {
        firstName: userInfoData?.firstName || '',
        lastName: userInfoData?.lastName || '',
        email: userInfoData?.email || '',
        mobile_no: userInfoData?.mobile_no || '',
        region: userInfoData?.region || '',
        country: userInfoData?.country || '',
        aboutMe: userInfoData?.aboutMe || '',
        spokenLanguages: userInfoData?.spokenLanguages ? userInfoData?.spokenLanguages : [],
        isShowMeditationRoomSessionInOtherLanguage:
            userInfoData?.isShowMeditationRoomSessionInOtherLanguage,
        gender: userInfoData?.gender || '',
        originalProfilePicUrl: userInfoData?.originalProfilePicUrl || ''
    };

    const handleSubmitAccountInfo = async (values) => {
        if (!values.aboutMe && viewAboutMe) {
            dispatch(
                showToast(
                    'Your about me content is shown to other users on your profile. Please add some data in about me, or please turn of the option for about in the ("Showing the following on my profile") section.',
                    'error'
                )
            );
            return;
        }
        props.setIsLoading(true);
        let data = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            mobileNo: values.mobile_no,
            regionId: values.region,
            countryId: values.country,
            aboutMe: values.aboutMe,
            dateOfBirth: showBirthDate ? moment(date).format('YYYY-MM-DD') : '',
            gender: values.gender,
            isShowSessionRoomInOtherLanguage: values.isShowMeditationRoomSessionInOtherLanguage,
            spokenLanguages: values.spokenLanguages,
            ageRange: getRangeState
        };

        let response = await API.put(API_URL.PROFILE_INFO, {
            data
        });
        if (!showBirthDate) setDate(moment());
        if (response) {
            props.setIsLoading(false);
            dispatch(updateUserInfo({ ...response?.data?.data }));
            dispatch(showToast(response?.data?.message, 'success'));
        }
    };

    const getCountry = useCallback(
        async (region) => {
            userDetailForm?.current?.setFieldValue('region', region);

            if (region !== '') {
                let response = await API.post(API_URL.COUNTRY, {
                    params: {
                        languageId: 0,
                        regionId: region
                    }
                });
                if (response) {
                    setCountry(response?.data?.data);
                }
            }
        },
        [API]
    );

    const getLang = useCallback(async () => {
        let response = await API.get(API_URL.SPOKEN_LANGUAGES);

        const spokenLanguages = userDetailForm?.current?.values?.spokenLanguages || [];
        const newLangData = [...response?.data?.data]?.filter((language) =>
            spokenLanguages?.includes(language?.languageId)
        );

        setLanguageSelectedId(newLangData || []);

        if (response) setLang(response?.data?.data);
    }, [API]);

    const getAccountInfoData = useCallback(async () => {
        props.setIsLoading(true);
        let response = await API.get(API_URL.PROFILE_INFO);

        if (response) {
            setUserInfoData(response?.data?.data);
            dispatch(updateUserInfo({ ...response?.data?.data }));
            userDetailForm?.current?.setFieldValue('firstName', response?.data?.data?.firstName);
            userDetailForm?.current?.setFieldValue('lastName', response?.data?.data?.lastName);
            userDetailForm?.current?.setFieldValue('email', response?.data?.data?.email);
            userDetailForm?.current?.setFieldValue('mobile_no', response?.data?.data?.mobile_no);
            userDetailForm?.current?.setFieldValue('region', response?.data?.data?.region);
            userDetailForm?.current?.setFieldValue('country', response?.data?.data?.country);
            userDetailForm?.current?.setFieldValue('aboutMe', response?.data?.data?.aboutMe);
            userDetailForm?.current?.setFieldValue(
                'spokenLanguages',
                response?.data?.data?.spokenLanguages
            );

            userDetailForm?.current?.setFieldValue(
                'isShowMeditationRoomSessionInOtherLanguage',
                response?.data?.data?.isShowMeditationRoomSessionInOtherLanguage
            );
            userDetailForm?.current?.setFieldValue('gender', response?.data?.data?.gender);
            userDetailForm?.current?.setFieldValue(
                'originalProfilePicUrl',
                response?.data?.data?.originalProfilePicUrl
            );

            setIsSearchable(response?.data?.data?.isSearchable);
            setViewAboutMe(response?.data?.data?.viewAboutMe);
            setViewBirthday(response?.data?.data?.viewBirthday);
            setViewGender(response?.data?.data?.viewGender);
            setViewInterest(response?.data?.data?.viewInterest);
            setProfileLink(response?.data?.data?.profileLink);
            setShowBirthDate(response?.data?.data?.dateOfBirth ? true : false);
            if (!response?.data?.data?.viewBirthday) {
                let tempAgeRange = '';
                response?.data?.data?.ageRangeDetails.forEach((value) => {
                    if (value.isSelected) {
                        tempAgeRange = value.ageRange;
                    }
                });
                setGetRangeState(tempAgeRange);
            }

            const userId = JSON.parse(localStorage.getItem('userInfo')).id;
            const AutoGeneratedProfileLink = `${window?.location?.protocol}//${window.location.host}${URL_PROFILE_VIEW}?userId=${userId}`;
            setProfileLink(AutoGeneratedProfileLink);

            setSignUpType(response?.data?.data?.signupType);

            let tempDate = response?.data?.data?.dateOfBirth
                ? new Date(response?.data?.data?.dateOfBirth)
                : new Date();
            setDate(tempDate);

            if (response?.data?.data?.region) getCountry(response?.data?.data?.region);
            getLang();
            props.setIsLoading(false);
        }
    }, [API, props, getCountry, getLang, dispatch]);

    const getRegions = useCallback(async () => {
        let response = await API.post(API_URL.REGIONS, {
            params: {
                languageId: 0
            }
        });

        if (response) setRegions(response?.data?.data);
    }, [API]);

    // PHASE 2
    const handlePublicProfile = async () => {
        props.setIsLoading(true);
        const data = {
            isSearchable: isSearchable,
            viewAboutMe: viewAboutMe,
            viewBirthday: viewBirthday,
            viewGender: viewGender,
            viewInterest: viewInterest,
            profileLink: !profileLink ? '' : profileLink
        };

        let response = await API.put(API_URL.PROFILE_PUBLIC, {
            data
        });

        if (response) {
            setUserInfoData((prev) => {
                return { ...prev, ...data };
            });
            props.setIsLoading(false);
            dispatch(showToast(response?.data?.message, 'success'));
        }
    };

    const fireFileUploadHandler = () => {
        fileUpload.current.click();
    };

    const handleFileHandler = async (event) => {
        const file = event?.target?.files[0];

        setResizeFileName(file?.name);
        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                setUploadedFile(reader.result);
                setImageType(file?.type);
            },
            false
        );

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const saveImageAfterResize = async (blobImage) => {
        let file = new File([blobImage], resizeFileName, {
            lastModified: new Date().getTime(),
            type: imageType
        });
        setUploadedFile(null);
        const fd = new FormData();
        if (file) {
            fd.append('multipartFile', file, file.name);
            props.setIsLoading(true);
            let response = await API.put(API_URL.PROFILE_PICTURE, {
                isMultipart: true,
                data: fd
            });

            if (response) {
                dispatch(
                    updateUserInfo({
                        ...userDetails,
                        originalProfilePicUrl: response?.data?.data?.originalProfilePicUrl,
                        thumbProfilePicUrl: response?.data?.data?.thumbProfilePicUrl
                    })
                );
                getAccountInfoData();
                dispatch(showToast(response?.data?.message, 'success'));
            }
        }
    };

    const deleteProfileImgHandler = async () => {
        const response = await API.patch(API_URL.PROFILE_PICTURE);
        if (response) {
            setDeleteImageModel(false);
            dispatch(
                updateUserInfo({
                    ...userDetails,
                    originalProfilePicUrl: User_Img,
                    thumbProfilePicUrl: User_Img
                })
            );
            getAccountInfoData();
            dispatch(showToast(response?.data?.message, 'success'));
        }
    };

    const handleOpenAndClosePicker = (picker = '', type = true) => {
        if (picker === DAY) {
            return setDatePickerIconStatus((prev) => {
                return { ...prev, day: type };
            });
        }
        if (picker === MONTH) {
            return setDatePickerIconStatus((prev) => {
                return { ...prev, month: type };
            });
        }
        if (picker === YEAR) {
            return setDatePickerIconStatus((prev) => {
                return { ...prev, year: type };
            });
        }
        setDatePickerIconStatus(pickerInitialValues);
    };

    const handlePreviewProfileBtn = () => {
        if (
            userInfoData?.viewInterest !== viewInterest ||
            userInfoData?.viewGender !== viewGender ||
            userInfoData?.viewAboutMe !== viewAboutMe ||
            userInfoData?.viewBirthday !== viewBirthday
        ) {
            return dispatch(
                showToast(PREVIEW_PROFILE_MESSAGE?.SAVE_DATA_TO_OTHER_SEE_YOUR_PROFILE, 'error')
            );
        }
        if (!viewInterest && !viewGender && !viewAboutMe && !viewBirthday) {
            return dispatch(
                showToast(
                    PREVIEW_PROFILE_MESSAGE?.ENABLE_OPTIONS_TO_OTHER_SEE_YOUR_PROFILE,
                    'error'
                )
            );
        }
        return navigate(URL_PROFILE, {
            state: { type: PROFILE_TAB_TYPE.VIEW_PROFILE }
        });
    };

    const handleShowOnMyProfile = (newValue, previousValue, currentValue, type, message) => {
        if (!previousValue && !currentValue) return dispatch(showToast(message, 'error'));

        if (type === PROFILE_OPTION?.BIRTHDAY) return setViewBirthday(newValue);
        if (type === PROFILE_OPTION?.ABOUT_ME) return setViewAboutMe(newValue);
        if (type === PROFILE_OPTION?.GENDER) return setViewGender(newValue);
        if (type === PROFILE_OPTION?.INTEREST) return setViewInterest(newValue);
    };

    useEffect(() => {
        getAccountInfoData();
        getRegions();
    }, [getRegions, getAccountInfoData]);

    useEffect(() => {
        if (
            userInfoData?.ageRangeDetails &&
            (moment(date).format('MMM D, YYYY') !== moment(new Date()).format('MMM D, YYYY') ||
                !!userInfoData?.dateOfBirth)
        ) {
            let YOB = moment(new Date(date)).format('YYYY');
            let TodayYear = moment(new Date()).format('YYYY');
            let DateRangeValue;
            for (let i = 0; i < userInfoData.ageRangeDetails.length; i++) {
                if (
                    userInfoData.ageRangeDetails[i].startAge <= TodayYear - YOB &&
                    TodayYear - YOB <= userInfoData.ageRangeDetails[i].endAge
                ) {
                    DateRangeValue = userInfoData.ageRangeDetails[i].ageRange;
                }
            }
            if (DateRangeValue) {
                setGetRangeState(DateRangeValue);
            } else {
                setGetRangeState('70+');
            }
        }
    }, [date, userInfoData]);

    return (
        <ProfileAccountInfoSectionWrapper>
            <TaoScrollbar displayScroll customStyle={{ margin: '20px 0 0' }}>
                <Formik
                    innerRef={userDetailForm}
                    initialValues={initialValues}
                    onSubmit={handleSubmitAccountInfo}
                    validationSchema={userInfoFormValidation}
                    enableReinitialize>
                    {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
                        <Box className="fields-container">
                            <ScrollToFieldError />
                            <Box className="grid-container default-margin">
                                <Box className="profile-img-bg item-1 flex f-v-center f-h-center">
                                    {values?.originalProfilePicUrl && (
                                        <img
                                            src={values?.originalProfilePicUrl}
                                            alt="profile pic"
                                            className="user-profile-img"
                                        />
                                    )}
                                    <Box className="new-pic-bg flex f-column f-v-center hover">
                                        <Box
                                            className="click-area"
                                            onClick={(e) => {
                                                if (!profilePermissions?.canAccess)
                                                    return dispatch(showSubscriptionModal());
                                                fireFileUploadHandler(e);
                                            }}
                                        />
                                        <Typography className="change-pic-para">
                                            Tap to <br /> Change <br />
                                            <Camera className="camera-icon" />
                                        </Typography>
                                        <input
                                            ref={fileUpload}
                                            accept="image/png, image/gif, image/jpeg"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleFileHandler}
                                        />
                                        {values?.originalProfilePicUrl &&
                                            profilePermissions?.canAccess && (
                                                <CrossIcon
                                                    className="cross-icon hover"
                                                    onClick={() => setDeleteImageModel(true)}
                                                />
                                            )}
                                    </Box>
                                    {uploadedFile && (
                                        <ResizeImage
                                            file={uploadedFile}
                                            imageType={imageType}
                                            onClose={() => {
                                                setUploadedFile(null);
                                                setResizeFileName('');
                                            }}
                                            saveImageAfterResize={saveImageAfterResize}
                                        />
                                    )}
                                </Box>
                                <Box className="first-name-box item-2">
                                    <ProfileAccountInfoTextField
                                        name="firstName"
                                        label="First name"
                                        value={values?.firstName}
                                        handleChange={handleChange}
                                        touched={touched?.firstName}
                                        error={errors?.firstName}
                                    />
                                </Box>
                                <Box className="last-name-box item-3">
                                    <ProfileAccountInfoTextField
                                        name="lastName"
                                        label="Last name"
                                        value={values?.lastName}
                                        handleChange={handleChange}
                                        touched={touched?.lastName}
                                        error={errors?.lastName}
                                    />
                                </Box>
                                <Box className="email-box item-4">
                                    <ProfileAccountInfoTextField
                                        name="email"
                                        label="Email address"
                                        className="text-field disable-field-color"
                                        value={values?.email}
                                        handleChange={handleChange}
                                        touched={touched?.lastName}
                                        error={errors?.lastName}
                                        disabled
                                    />
                                </Box>
                            </Box>
                            <Box className="flex user-address">
                                <Box className="mobile-box default-margin">
                                    <ProfileAccountInfoTextField
                                        name="mobile_no"
                                        label="Mobile"
                                        className="text-field"
                                        value={values?.mobile_no}
                                        handleChange={(e) =>
                                            e?.target?.value.length <= 14 &&
                                            setFieldValue(
                                                'mobile_no',
                                                e?.target?.value.replace(/[^0-9*+]/g, '')
                                            )
                                        }
                                        touched={touched?.mobile_no}
                                        error={errors?.mobile_no}
                                    />
                                </Box>
                                <Box className="region-box default-margin">
                                    <FormControl variant="filled" className="form-control">
                                        <InputLabel>Region</InputLabel>
                                        <Select
                                            IconComponent={SelectDropDownIcon}
                                            variant="filled"
                                            className="selector"
                                            name="region"
                                            disableUnderline
                                            inputProps={{
                                                className: 'filled-select'
                                            }}
                                            defaultValue=""
                                            value={values?.region || ''}
                                            onChange={(e) => {
                                                handleChange(e);
                                                getCountry(e?.target?.value);
                                            }}>
                                            {!regions?.length && (
                                                <MenuItem key="empty" value={''}>
                                                    <em>None</em>
                                                </MenuItem>
                                            )}
                                            {regions &&
                                                regions.map((region) => (
                                                    <MenuItem
                                                        className="menu-items"
                                                        key={region.id}
                                                        value={region.id}>
                                                        {region.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        <FormHelperText error>
                                            {touched.region && errors.region}
                                        </FormHelperText>
                                    </FormControl>
                                </Box>
                                <Box className="location-box default-margin">
                                    <FormControl variant="filled" className="form-control">
                                        <InputLabel>Country</InputLabel>
                                        <Select
                                            IconComponent={SelectDropDownIcon}
                                            variant="filled"
                                            className="selector"
                                            name="country"
                                            disableUnderline
                                            inputProps={{
                                                className: 'filled-select'
                                            }}
                                            defaultValue=""
                                            value={country?.length ? values?.country : ''}
                                            onChange={handleChange}>
                                            {!country?.length && (
                                                <MenuItem key="empty" value={''}>
                                                    <em>None</em>
                                                </MenuItem>
                                            )}
                                            {country?.length > 0 &&
                                                country?.map((country) => (
                                                    <MenuItem
                                                        className="menu-items"
                                                        key={country?.id}
                                                        value={country?.id}>
                                                        {country?.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box className="default-margin">
                                <ProfileAccountInfoTextField
                                    label="About Me"
                                    name="aboutMe"
                                    multiline
                                    rows={4}
                                    value={values?.aboutMe}
                                    handleChange={handleChange}
                                    touched={touched?.aboutMe}
                                    error={errors?.aboutMe}
                                />
                            </Box>
                            <Box gap={2} className="select-box flex f-column default-margin">
                                <Box className="select-filed">
                                    <FormControl variant="filled" className="form-control">
                                        <Autocomplete
                                            popupIcon={<SelectDropDownIcon />}
                                            disablePortal
                                            disableCloseOnSelect
                                            name="spokenLanguages"
                                            value={languageSelectedId}
                                            renderTags={(values) =>
                                                values.map((value) => value.language).join(', ')
                                            }
                                            classes={{
                                                inputRoot: 'auto-complete-root-bg'
                                            }}
                                            options={lang || []}
                                            onChange={(event, newValue) => {
                                                setLanguageSelectedId([...newValue]);
                                                userDetailForm?.current?.setFieldValue(
                                                    'spokenLanguages',
                                                    newValue.map((item) => item?.languageId)
                                                );
                                            }}
                                            fullWidth
                                            autoComplete
                                            multiple
                                            ListboxProps={{
                                                style: {
                                                    maxHeight: '200px'
                                                }
                                            }}
                                            getOptionLabel={(option) =>
                                                option?.language?.toString()
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Spoken Languages"
                                                    variant="filled"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: 'filled-input',
                                                        classes: {
                                                            root: 'filled-input-bg'
                                                        },
                                                        ...params.InputProps
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormHelperText error>
                                        {touched.spokenLanguages && errors.spokenLanguages}
                                    </FormHelperText>
                                </Box>
                                {/* PHASE 2 */}
                                {/* <Box className="select-filed">
                                    <Typography className="session-lang">
                                        Show rooms and sessions in other languages?
                                    </Typography>
                                    <RadioGroup
                                        row
                                        className="lang-show-session flex f-h-space-between radio-button-group f-v-center"
                                        name="isShowMeditationRoomSessionInOtherLanguage"
                                        value={`${values?.isShowMeditationRoomSessionInOtherLanguage}`}
                                        onChange={handleChange}>
                                        <FormControlLabel
                                            className="lang-show-session-radio flex f-v-center"
                                            control={<CustomRadioButton />}
                                            label="yes"
                                            value={true}
                                        />
                                        <FormControlLabel
                                            className="lang-show-session-radio flex f-v-center"
                                            control={<CustomRadioButton />}
                                            label="no"
                                            value={false}
                                        />
                                    </RadioGroup>
                                </Box> */}
                                <Box className="select-filed">
                                    <FormControl variant="filled" className="form-control">
                                        <InputLabel>Gender</InputLabel>
                                        <Select
                                            IconComponent={SelectDropDownIcon}
                                            variant="filled"
                                            className="selector"
                                            name="gender"
                                            disableUnderline
                                            value={values?.gender}
                                            onChange={handleChange}
                                            inputProps={{
                                                className: 'filled-select'
                                            }}>
                                            {GENDER_TYPE?.map((title, index) => (
                                                <MenuItem
                                                    key={index}
                                                    className="menu-items"
                                                    value={title}>
                                                    {title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box className="default-margin">
                                <Typography className="birthday-title">birthdate</Typography>
                                <Box className="f-h-space-between flex f-v-center">
                                    <Typography className="birthday-para">
                                        We'd like to send you a surprise boost on your birthday!
                                    </Typography>
                                    <Box className="flex f-v-center" gap={1}>
                                        <ProfileAccountInfoCustomSwitch
                                            label="set"
                                            checked={showBirthDate}
                                            onChange={() => setShowBirthDate(!showBirthDate)}
                                        />
                                        <GiftIcon />
                                    </Box>
                                </Box>

                                <Box
                                    className={`flex date-wrapper ${
                                        !showBirthDate && 'hide-date-picker'
                                    }`}>
                                    <Box className="day-picker">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <ProfileAccountInfoDatePicker
                                                datePickerIconStatus={datePickerIconStatus?.day}
                                                inputFormat={'DD'}
                                                label={'Day'}
                                                views={['day']}
                                                value={date}
                                                onChange={(value) => setDate(value?.$d)}
                                                onOpen={() => {
                                                    handleOpenAndClosePicker(DAY);
                                                }}
                                                onClose={() => handleOpenAndClosePicker(DAY, false)}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box className="month-picker">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <ProfileAccountInfoDatePicker
                                                datePickerIconStatus={datePickerIconStatus?.month}
                                                inputFormat={'MMMM'}
                                                label={'Month'}
                                                views={['month']}
                                                value={date}
                                                onChange={(value) => setDate(value?.$d)}
                                                onOpen={() => handleOpenAndClosePicker(MONTH)}
                                                onClose={() =>
                                                    handleOpenAndClosePicker(MONTH, false)
                                                }
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box className="year-picker">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <ProfileAccountInfoDatePicker
                                                datePickerIconStatus={datePickerIconStatus?.year}
                                                inputFormat={'YYYY'}
                                                label={'Year'}
                                                views={['year']}
                                                value={date}
                                                onChange={(value) => setDate(value?.$d)}
                                                onOpen={() => handleOpenAndClosePicker(YEAR)}
                                                onClose={() =>
                                                    handleOpenAndClosePicker(YEAR, false)
                                                }
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="default-margin">
                                <FormControl variant="filled" className="form-control">
                                    <InputLabel>Age Range</InputLabel>
                                    <Select
                                        IconComponent={SelectDropDownIcon}
                                        variant="filled"
                                        className="selector disable-field-color"
                                        name="ageRange"
                                        disableUnderline
                                        inputProps={{
                                            className: 'filled-select'
                                        }}
                                        disabled
                                        value={getRangeState}>
                                        {userInfoData?.ageRangeDetails &&
                                            userInfoData?.ageRangeDetails.map((values, index) => (
                                                <MenuItem
                                                    key={index}
                                                    className="menu-items"
                                                    value={values.ageRange}>
                                                    {values.ageRange}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className="default-margin">
                                <Button
                                    onClick={
                                        !profilePermissions?.canAccess
                                            ? () => dispatch(showSubscriptionModal())
                                            : handleSubmit
                                    }
                                    className="submit-btn"
                                    endIcon={
                                        !profilePermissions?.canAccess && <SubscriptionLightLock />
                                    }>
                                    save
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Formik>
                <Box className="public-profile-section">
                    <Typography className="public-section-title">public profile</Typography>
                    <Box className="flex f-h-space-between email-notification-box">
                        <Typography className="body-text"> Allow Others to Find Me</Typography>
                        <FormControlLabel
                            control={
                                <ProfileAccountInfoCustomSwitch
                                    checked={isSearchable}
                                    onChange={(e) => setIsSearchable(e?.target?.checked)}
                                />
                            }
                        />
                    </Box>
                    <Box
                        id="not-searchable"
                        className="f-column"
                        style={isSearchable ? { display: 'none' } : { display: 'flex' }}>
                        <Typography className="body-para">
                            Your profile will <strong>not</strong> be searchable by others. You can
                            share your private profile link with users if you choose.
                        </Typography>
                        <Box className="flex f-h-space-between private-link-box">
                            <ProfileAccountInfoTextField
                                name="firstName"
                                label="Private Profile Link"
                                className="text-field disable-field-color share-profile"
                                value={profileLink}
                                // disabled
                            />
                            <ShareSocial
                                ButtonComponent={
                                    <Box className="share-btn-container">
                                        <Typography className="share-btn-text">SHARE</Typography>
                                    </Box>
                                }
                                title="Sign up Test with referral"
                                url={profileLink}
                                disableRipple={true}
                            />
                        </Box>
                    </Box>
                    <Box
                        id="searchable"
                        style={isSearchable ? { display: 'flex' } : { display: 'none' }}>
                        <Typography className="body-para">
                            Your profile will be searchable by others on this app
                        </Typography>
                    </Box>
                    <Divider className="divider" />
                    <Typography className="body-para">Show the following on my profile:</Typography>
                    <Box className="switch-container">
                        {Object.keys(profileOptionsCheckBox)?.map((option, index) => (
                            <Box className="profile-options" key={index}>
                                <ProfileAccountInfoCustomSwitch
                                    label={profileOptionsCheckBox?.[option]?.label || ''}
                                    checked={profileOptionsCheckBox?.[option]?.checked || false}
                                    onChange={profileOptionsCheckBox?.[option]?.onChange || null}
                                />
                            </Box>
                        ))}
                    </Box>
                    <Button
                        className="save-btn"
                        onClick={
                            !profilePermissions?.canAccess
                                ? () => dispatch(showSubscriptionModal())
                                : () => handlePublicProfile()
                        }
                        endIcon={!profilePermissions?.canAccess && <SubscriptionLightLock />}>
                        save changes
                    </Button>
                    <Button
                        className="preview-btn"
                        onClick={() => {
                            handlePreviewProfileBtn();
                        }}>
                        {isSearchable ? 'preview my public profile' : 'preview my private profile'}
                    </Button>
                </Box>
                {signUpType === 'NORMAL' && (
                    <Box className="password-section">
                        <ConfirmPassword setIsLoading={props.setIsLoading} />
                    </Box>
                )}
                {deleteImageModel && (
                    <DeleteConfirmation
                        title="Ready to change profile picture?"
                        onClose={() => setDeleteImageModel()}
                        onConfirm={deleteProfileImgHandler}
                    />
                )}
            </TaoScrollbar>
        </ProfileAccountInfoSectionWrapper>
    );
}
export default ProfileAccountInfo;
