import React, { useState, useEffect } from 'react';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    XIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';
import { IconButtonWrapper, ShareWrapper } from './Share.style';
import { ReactComponent as ShareIcon } from 'Assets/images/Common/share-icon.svg';
import { DYNAMIC_LINK_GENERATE_ENDPOINT } from 'Helpers/Paths';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { showToast } from 'Redux/App/Actions';

const ICON_SIZE = 50;

const ShareSocial = ({
    onClose,
    title,
    url,
    details,
    ButtonComponent = <ShareIcon />,
    callFetchShorterLink = true,
    disableRipple = false,
    handleCustomCall = null,
    dynamicDimension = false,
    customButtonStyle = {},
    ...props
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [shareURL, setShareURL] = useState('');
    const dispatch = useDispatch();

    const handleClick = (event) => {
        if (callFetchShorterLink) {
            fetchShorterLink();
        } else {
            setShareURL(url);
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCustomApiCall = async (event) => {
        let eventRef = event;

        let url = await handleCustomCall();

        if (!url) return;

        setAnchorEl(eventRef);
        setShareURL(url);
    };

    const convertToPlain = (html) => {
        var tempDivElement = document.createElement('div');
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || '';
    };

    const handleCopyToClipBoard = () => {
        if (!shareURL) return;

        dispatch(showToast('Copied Successfully', 'info'));
        return navigator?.clipboard?.writeText(shareURL);
    };

    const fetchShorterLink = () => {
        fetch(DYNAMIC_LINK_GENERATE_ENDPOINT.GENERATE_SHORT_LINK, {
            method: 'POST',
            body: JSON.stringify({
                dynamicLinkInfo: {
                    domainUriPrefix: process.env.REACT_APP_DOMAIN_URI_PREFIX,
                    link: url,
                    androidInfo: {
                        androidPackageName: process.env.REACT_APP_PACKAGE_NAME,
                        androidFallbackLink: url
                    },
                    iosInfo: {
                        iosBundleId: process.env.REACT_APP_BUNDLE_ID,
                        iosFallbackLink: url,
                        iosAppStoreId: process.env.REACT_APP_STORE_ID
                    }
                },
                suffix: {
                    option: 'SHORT'
                }
            })
        })
            .then((res) => res.json())
            .then((result) => setShareURL(result.shortLink));
    };

    useEffect(() => {
        return () => {
            setShareURL('');
            setAnchorEl(null);
        };
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <IconButtonWrapper
                className="extra-feature-icon flex f-h-center f-v-center hover"
                classes={{ root: 'root' }}
                style={{ ...customButtonStyle }}
                onClick={handleCustomCall ? handleCustomApiCall : handleClick}
                aria-describedby={id}
                disableRipple={disableRipple}
                $dynamic_dimension={dynamicDimension ? dynamicDimension || 'auto' : undefined}
                size="medium">
                {ButtonComponent}
            </IconButtonWrapper>

            <ShareWrapper id={id} open={open} onClose={handleClose} classes={{ paper: 'paper' }}>
                <Box className="flex f-column full-width">
                    {handleCustomCall && (
                        <Box className="preview-container">
                            <Typography className="preview-text">Preview</Typography>
                            <Box
                                className="preview-image-container"
                                style={{
                                    height: '200px',
                                    overflow: 'scroll'
                                }}>
                                <img className="preview-image" alt="" src={shareURL} />
                            </Box>
                        </Box>
                    )}

                    <Box className="share-button-container">
                        <EmailShareButton
                            subject={title}
                            body={details?.description ? convertToPlain(details?.description) : ''}
                            url={shareURL}
                            openShareDialogOnClick
                            onClick={() => {}}
                            className="icon">
                            <EmailIcon size={ICON_SIZE} round />
                        </EmailShareButton>
                        <FacebookShareButton url={shareURL} className="icon">
                            <FacebookIcon size={ICON_SIZE} round />
                        </FacebookShareButton>
                        <WhatsappShareButton url={shareURL} className="icon">
                            <WhatsappIcon size={ICON_SIZE} round />
                        </WhatsappShareButton>
                        <TwitterShareButton url={shareURL} className="icon" title={title}>
                            <XIcon size={ICON_SIZE} round />
                        </TwitterShareButton>
                        <LinkedinShareButton url={shareURL} className="icon" title={title}>
                            <LinkedinIcon size={ICON_SIZE} round />
                        </LinkedinShareButton>
                    </Box>
                    <Box className="copy-link-container">
                        <Box className="link">
                            <Typography className="link-text">{shareURL}</Typography>
                        </Box>
                        <Box className="copy-icon" onClick={handleCopyToClipBoard} />
                    </Box>
                </Box>
            </ShareWrapper>
        </React.Fragment>
    );
};

export default ShareSocial;
