//CORE
import { IconButton, LinearProgress, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

//ICON
import { ReactComponent as OptionIcon } from 'Assets/images/MeditationTimer/dots.svg';
import { ReactComponent as CloseIcon } from 'Assets/images/Common/close-icon.svg';

//image
import PlaylistButton from 'Assets/images/Common/play-button.svg';

//CUSTOM
import { MeditationsImageWrapper, MeditationsWrapper, PopOverWrapper } from './Meditations.style';
import { getWindowDimensions } from 'Helpers';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import Api from 'Helpers/ApiHandler';
import { API_URL, URL_MEDITATION_CONTENT } from 'Helpers/Paths';
import TaoScrollbar from 'Components/common/Scrollbar';
import { StepIconWrapper, StepperLineConnectorWrapper } from './Meditations.style';
import { SLIDER_PER_PAGE } from 'Helpers/Constants';
import { showToast } from 'Redux/App/Actions';
import { useDispatch } from 'react-redux';

const STEPS = [
    {
        id: 1,
        label: '1 Day',
        value: 1
    },
    {
        id: 2,
        label: '7 Days',
        value: 7
    },
    {
        id: 3,
        label: '30 Days',
        value: 30
    }
];

const MyCustomStepIcon = (props) => {
    const { active, completed } = props;
    return <StepIconWrapper className={`${active && `active`} ${completed && `completed`} `} />;
};

const PopoverFun = ({ removePopId, popupOpen, setPopupOpen, handleRemoveMeditation }) => {
    return (
        <PopOverWrapper
            id={removePopId}
            open={Boolean(popupOpen)}
            anchorEl={popupOpen}
            onClose={() => setPopupOpen(null)}
            classes={{ paper: 'remove-box' }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}>
            <Typography className="popup-text hover" onClick={handleRemoveMeditation}>
                remove
            </Typography>
            <IconButton className="close-icon" onClick={() => setPopupOpen(null)}>
                <CloseIcon />
            </IconButton>
        </PopOverWrapper>
    );
};

const Meditations = (props) => {
    const API = useMemo(() => new Api(), []);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = useState(0);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [pagination, setPagination] = useState({
        current_page: 0,
        per_page: SLIDER_PER_PAGE,
        search: ''
    });
    const [meditationList, setMeditationList] = useState({ list: [], totalRecords: 0 });
    const [days, setDays] = useState(1);

    const [popupOpen, setPopupOpen] = useState(false);
    const [removeId, setRemoveId] = useState(null);
    const [resetData, setResetData] = useState(false);

    const removePopId = popupOpen ? 'simple-popover' : undefined;

    const handlePopOver = (event, id) => {
        setPopupOpen(event?.currentTarget);
        setRemoveId(id);
    };

    const handleRemoveMeditation = async () => {
        let response = await API.put(API_URL.CONTENT_HISTORY, {
            params: {
                id: removeId
            }
        });

        if (response) {
            dispatch(showToast(response?.data?.message));
            setResetData((prev) => !prev);
            setPopupOpen(null);
        }
    };

    const getMeditationsData = useCallback(async () => {
        props.setLoading(true);
        let response = await API.post(API_URL.CONTENT_HISTORY, {
            params: {
                days: days,
                date: moment(new Date()).format('YYYY-MM-DD') // for now we will pass today date. in future it might change
            },
            data: {
                current_page: pagination.current_page,
                per_page: pagination.per_page,
                search: pagination.search
            }
        });
        if (response) {
            setMeditationList((prev) => {
                let arr =
                    pagination?.current_page === 0
                        ? response?.data?.data?.list
                        : prev.list.concat(response?.data?.data?.list);
                return {
                    totalRecords: response?.data?.data?.totalRecords,
                    list: [...new Map(arr.map((item) => [item['id'], item])).values()]
                };
            });
            props.setLoading(false);
        }
    }, [pagination, API, days, props]);

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowDimensions.width]);

    useEffect(() => {
        getMeditationsData();
    }, [getMeditationsData, resetData]);

    const handleNextStep = (index) => {
        setMeditationList({ list: [], totalRecords: 0 });
        setPagination({ current_page: 0, per_page: SLIDER_PER_PAGE, search: '' });
        setActiveStep(index);
        setDays(STEPS[index]?.value);
    };

    const handleTimeDurationValue = (contentDuration, contentLastPlayTime) => {
        let startTime = moment(contentLastPlayTime, 'HH:mm:ss a');
        let endTime = moment(contentDuration, 'HH:mm:ss a');

        // diff in seconds
        let duration = moment(endTime).diff(moment(startTime), 'seconds');
        // end time is seconds
        let endTimeSec = moment(endTime, 'HH:mm:ss: A').diff(moment().startOf('day'), 'seconds');
        // data in percentage
        let secondsLeftInPercentage = 100 - (duration * 100) / endTimeSec;

        return secondsLeftInPercentage ? secondsLeftInPercentage : 0;
    };

    const loadMoreData = () => {
        setPagination((prev) => {
            return { ...prev, current_page: prev.current_page + 1 };
        });
    };

    return (
        <MeditationsWrapper>
            <Typography className="meditations-title">
                {windowDimensions.width < BREAKPOINTS_VALUE.TABLET &&
                    `${meditationList?.totalRecords}`}{' '}
                Meditations
            </Typography>
            <Box className="meditations-data flex">
                <Box className="timeline-section flex f-column">
                    <Typography className="timeline-title">Show Past:</Typography>
                    <Box className="steeper-wrapper " position="relative">
                        <Stepper
                            alternativeLabel={windowDimensions.width < BREAKPOINTS_VALUE.TABLET}
                            connector={
                                <StepperLineConnectorWrapper $windowDimensions={windowDimensions} />
                            }
                            activeStep={activeStep}
                            orientation={
                                windowDimensions.width < BREAKPOINTS_VALUE.TABLET
                                    ? 'horizontal'
                                    : 'vertical'
                            }
                            className="steeper f-v-center">
                            {STEPS.map((step, index) => (
                                <Step
                                    key={step.label}
                                    classes={{
                                        vertical: `vertical-completed ${
                                            step.id === 2 && 'vertical-root'
                                        }`
                                    }}>
                                    <StepLabel
                                        className="hover"
                                        classes={{
                                            labelContainer: 'label-container',
                                            label: 'step-label',
                                            iconContainer: 'icon-container',
                                            active: 'active-container',
                                            completed: 'completed-container',
                                            root: 'label-root'
                                        }}
                                        StepIconComponent={MyCustomStepIcon}
                                        onClick={() => handleNextStep(index)}>
                                        {step.label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </Box>
                {meditationList?.list.length > 0 ? (
                    <Box className="video-section">
                        <TaoScrollbar displayScroll>
                            {meditationList?.list?.map((meditationContent) => (
                                <Box key={meditationContent?.id} className="flex video-card hover">
                                    <Box
                                        className="img-section"
                                        onClick={() => {
                                            navigate(URL_MEDITATION_CONTENT, {
                                                state: {
                                                    contentId: meditationContent?.id
                                                }
                                            });
                                        }}>
                                        <MeditationsImageWrapper
                                            meditationsImage={meditationContent?.thumbnailImage}
                                        />
                                        <IconButton className="play-button">
                                            <img
                                                src={PlaylistButton}
                                                alt="play icon"
                                                className="playlist-icon"
                                            />
                                        </IconButton>
                                        <LinearProgress
                                            className="progress-bar"
                                            color="error"
                                            variant="determinate"
                                            value={handleTimeDurationValue(
                                                meditationContent?.contentDuration,
                                                meditationContent?.contentLastPlayTime
                                            )}
                                            classes={{
                                                determinate: 'bar1',
                                                bar2Indeterminate: 'bar2'
                                            }}
                                        />
                                    </Box>
                                    <Box className="desc-section flex f-h-space-between">
                                        <Box
                                            className="flex f-column meditation-desc"
                                            onClick={() => {
                                                navigate(URL_MEDITATION_CONTENT, {
                                                    state: {
                                                        contentId: meditationContent?.id
                                                    }
                                                });
                                            }}>
                                            <Typography className="desc-title two-line-ellipse">
                                                {meditationContent?.contentName}
                                            </Typography>
                                            <Box className="desc-path two-line-ellipse">
                                                {meditationContent?.categoryDetailsList?.length >
                                                    0 && (
                                                    <Typography style={{ gap: '5px' }} className="">
                                                        {
                                                            meditationContent
                                                                ?.categoryDetailsList[0]
                                                                ?.mainCategory
                                                        }
                                                        {meditationContent
                                                            ?.categoryDetailsList[0] &&
                                                            meditationContent
                                                                ?.categoryDetailsList[0]
                                                                .subCategory[0] && (
                                                                <span className="">
                                                                    {'> ' +
                                                                        meditationContent
                                                                            ?.categoryDetailsList[0]
                                                                            .subCategory[0]}
                                                                </span>
                                                            )}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Typography className="desc-date">
                                                {meditationContent?.contentLastPlayDate
                                                    ? moment(
                                                          new Date(
                                                              meditationContent?.contentLastPlayDate
                                                          )
                                                      ).format('MMM DD')
                                                    : 'NaN'}
                                            </Typography>
                                        </Box>
                                        <Box className="option-section">
                                            <IconButton
                                                className="option-icon-btn hover"
                                                aria-describedby={removePopId}
                                                onClick={(event) =>
                                                    handlePopOver(event, meditationContent?.id)
                                                }>
                                                <OptionIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                            {meditationList?.totalRecords !== meditationList?.list.length && (
                                <Box
                                    className="load-more flex f-column f-v-center f-h-center hover"
                                    onClick={() => loadMoreData()}>
                                    <Typography className="load-more-text">Load More</Typography>
                                    <Box className="load-more-arrow" />
                                </Box>
                            )}
                        </TaoScrollbar>
                    </Box>
                ) : (
                    <Box className="empty flex f-v-center f-h-center">
                        <Typography>No Meditations Found</Typography>
                    </Box>
                )}
            </Box>
            <PopoverFun
                removePopId={removePopId}
                popupOpen={popupOpen}
                setPopupOpen={setPopupOpen}
                handleRemoveMeditation={handleRemoveMeditation}
            />
        </MeditationsWrapper>
    );
};

export default Meditations;
