import React, { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { countries } from 'country-data';

import { useDispatch } from 'react-redux';

import { ReactComponent as HealthIcon } from 'Assets/images/Wellness/health-icon.svg';
import { ReactComponent as RelationshipIcon } from 'Assets/images/Wellness/relationship-icon.svg';
import { ReactComponent as BusinessAndFinanceIcon } from 'Assets/images/Wellness/business-and-finance-icon.svg';
import { ReactComponent as PeakPerformanceIcon } from 'Assets/images/Wellness/peak-performance-icon.svg';
import { ReactComponent as PregnancyIcon } from 'Assets/images/Wellness/pregnancy-icon.svg';
import { ReactComponent as ChildrenAndEducationIcon } from 'Assets/images/Wellness/children-and-education-icon.svg';
import { ReactComponent as DownArrowIcon } from 'Assets/images/Common/down-arrow.svg';
import { ReactComponent as LockIcon } from 'Assets/images/Common/lock.svg';
import { ReactComponent as DeleteIcon } from 'Assets/images/MeditationTimer/delete-icon.svg';
import { ReactComponent as ArrowImage } from 'Assets/images/Common/down-arrow.svg';
import { ReactComponent as SilverHeartIcon } from 'Assets/images/Reward/silver-heart-icon.svg';
import RoomImage1 from 'Assets/images/MeditationRooms/room-image-1.png';
import RoomImage2 from 'Assets/images/MeditationRooms/room-image-2.png';
import RoomImage3 from 'Assets/images/MeditationRooms/room-image-3.png';
import RoomImage4 from 'Assets/images/MeditationRooms/room-image-4.png';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';

import {
    Typography,
    Box,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
    Checkbox,
    IconButton
} from '@mui/material';

import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import Footer from 'Components/common/Footer/Footer';
import UnlockModal from 'Components/common/UnlockModal/UnlockModal';

import { AddUpdateRoomWrapper } from './AddUpdateRoom.style';
import { TaoButton } from 'Components/common/Button.style';

import { getWindowDimensions } from 'Helpers';

import { showSideBar, hideSideBar } from 'Redux/Sidebar/Actions';
import { showTopBar, hideTopBar, showFooter, hideFooter } from 'Redux/App/Actions';

const AddUpdateRoom = ({ ...props }) => {
    const accessRef = useRef();
    const languageRef = useRef();
    const countryRef = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            roomName: '',
            description: '',
            access: '',
            language: '',
            country: 'United States',
            terms: false
        },
        validationSchema: Yup.object({
            roomName: Yup.string().required('Room Name is required'),
            description: Yup.string().required('Description is required'),
            access: Yup.string().required('Access is required'),
            country: Yup.string().required('Country is required'),
            language: Yup.string().required('Language is required'),
            terms: Yup.boolean()
                .required('Please agree the terms and conditions')
                .isTrue('Please agree the terms and conditions')
        }),
        onSubmit: async (values) => {
            console.log(values);
        }
    });

    const [selectedCategory, setSelectedCategory] = useState({ name: 'All', value: 'ALL' });
    const [accessSelect, setAccessSelect] = useState(false);
    const [anchorAccess, setAnchorAccess] = useState(null);

    const [languageSelect, setLanguageSelect] = useState(false);
    const [anchorLanguage, setAnchorLanguage] = useState(null);

    const [countrySelect, setCountrySelect] = useState(false);
    const [anchorCountry, setAnchorCountry] = useState(null);
    const [countryOption] = useState(countries.all.map((c) => ({ label: c.name, value: c.name })));
    const [bgImage, setBgImage] = useState('ROOM_IMAGE_1');
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [openUnlockModal, setOpenUnlockModal] = useState(false);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);

            dispatch(showSideBar());
            dispatch(showTopBar());
            dispatch(showFooter());
        };
    }, [dispatch]);

    useEffect(() => {
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            dispatch(hideSideBar());
            dispatch(hideTopBar());
            dispatch(hideFooter());
        }
    }, [dispatch, windowDimensions]);

    const categorySelectHandler = (catType) => {
        setSelectedCategory((preState) => {
            if (preState.value === catType.value) {
                return { name: 'All', value: 'ALL' };
            } else {
                return catType;
            }
        });
    };
    const openAccessSelect = () => {
        setAccessSelect(true);
        setAnchorAccess(accessRef.current);
    };
    const closeAccessSelect = () => {
        setAccessSelect(false);
        setAnchorAccess(null);
    };

    const openLanguageSelect = () => {
        setLanguageSelect(true);
        setAnchorLanguage(languageRef.current);
    };

    const closeLanguageSelect = () => {
        setLanguageSelect(false);
        setAnchorLanguage(null);
        console.log(languageRef.current);
    };

    const openCountrySelect = () => {
        setCountrySelect(true);
        setAnchorCountry(countryRef.current);
    };

    const closeCountrySelect = () => {
        setCountrySelect(false);
        setAnchorCountry(null);
    };

    const setRoomBGImage = (roomDetails) => {
        if (!roomDetails.lock) {
            setBgImage(roomDetails.value);
        } else {
            showUnlockModal(true);
        }
    };

    const showUnlockModal = () => {
        setOpenUnlockModal(true);
    };
    const closeUnlockModal = () => {
        setOpenUnlockModal(false);
    };
    return (
        <>
            <TaoCalligraphyWatermark />
            <UnlockModal
                RewardIcon={SilverHeartIcon}
                rewardCount={5}
                open={openUnlockModal}
                onClose={closeUnlockModal}
            />
            <AddUpdateRoomWrapper>
                <Box className="back-button-container">
                    <IconButton onClick={() => navigate(-1)} className="arrow-btn">
                        <ArrowImage />
                    </IconButton>
                </Box>
                <Box className="page-container">
                    <Typography className="page-type-title">Edit Room</Typography>
                    <Box className="room-detail-container">
                        <Box className="category-container">
                            <Box className="title-container">
                                <Typography variant="h5">Topic</Typography>
                                <Typography variant="h6">choose one</Typography>
                            </Box>
                            <Box className="category-list">
                                {ICON_LIST.map((i) => (
                                    <Box className="icon-container">
                                        <i.Icon
                                            onClick={() =>
                                                categorySelectHandler({
                                                    name: i.name,
                                                    value: i.value
                                                })
                                            }
                                            className={`category-icon ${
                                                selectedCategory.value === i.value &&
                                                'selected-category'
                                            }`}
                                        />
                                        <Typography>{i.name}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box className="details-container">
                            <Typography className="title">Details</Typography>
                            <form onSubmit={formik.handleSubmit}>
                                <Box className="flex f-column">
                                    <TextField
                                        className="text-field"
                                        variant="filled"
                                        label="Room Name"
                                        InputProps={{
                                            disableUnderline: true,
                                            className: 'filled-input'
                                        }}
                                    />
                                    {formik.touched.roomName && formik.errors.roomName && (
                                        <FormHelperText style={{ marginLeft: '14px' }} error>
                                            {formik.errors.roomName}
                                        </FormHelperText>
                                    )}
                                </Box>
                                <Box className="flex f-column">
                                    <TextField
                                        className="text-field"
                                        variant="filled"
                                        label="Description"
                                        name="roomName"
                                        onChange={formik.handleChange}
                                        value={formik.values.roomName}
                                        multiline
                                        rows={4}
                                        InputProps={{
                                            disableUnderline: true,
                                            className: 'filled-input'
                                        }}
                                    />
                                    {formik.touched.description && formik.errors.description && (
                                        <FormHelperText style={{ marginLeft: '14px' }} error>
                                            {formik.errors.description}
                                        </FormHelperText>
                                    )}
                                </Box>
                                <FormControl className="form-control" variant="filled">
                                    <InputLabel>Access</InputLabel>
                                    <Select
                                        ref={accessRef}
                                        variant="filled"
                                        label="Access"
                                        name="access"
                                        className="selector access"
                                        disableUnderline
                                        onClose={closeAccessSelect}
                                        onOpen={openAccessSelect}
                                        onChange={formik.handleChange}
                                        value={formik.values.access}
                                        open={accessSelect}
                                        inputProps={{
                                            disableUnderline: true,
                                            className: 'filled-select'
                                        }}
                                        IconComponent={(props) => {
                                            return (
                                                <DownArrowIcon
                                                    className="down-arrow-icon"
                                                    onClick={openAccessSelect}
                                                />
                                            );
                                        }}
                                        MenuProps={{
                                            anchorEl: anchorAccess
                                        }}>
                                        <MenuItem className="menu-items" value={0}>
                                            All
                                        </MenuItem>
                                        <MenuItem className="menu-items" value={1}>
                                            selected
                                        </MenuItem>
                                    </Select>
                                    {formik.touched.access && formik.errors.access && (
                                        <FormHelperText error>
                                            {formik.errors.access}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl className="form-control" variant="filled">
                                    <InputLabel>Language</InputLabel>
                                    <Select
                                        ref={languageRef}
                                        label="Language"
                                        variant="filled"
                                        name="language"
                                        className="selector access"
                                        disableUnderline
                                        onClose={closeLanguageSelect}
                                        onOpen={openLanguageSelect}
                                        open={languageSelect}
                                        onChange={formik.handleChange}
                                        value={formik.values.language}
                                        inputProps={{
                                            disableUnderline: true,
                                            className: 'filled-select'
                                        }}
                                        IconComponent={(props) => {
                                            return (
                                                <DownArrowIcon
                                                    className="down-arrow-icon"
                                                    onClick={openLanguageSelect}
                                                />
                                            );
                                        }}
                                        MenuProps={{
                                            anchorEl: anchorLanguage
                                        }}>
                                        <MenuItem className="menu-items" value={0}>
                                            English
                                        </MenuItem>
                                        <MenuItem className="menu-items" value={1}>
                                            French
                                        </MenuItem>
                                        <MenuItem className="menu-items" value={1}>
                                            Hindi
                                        </MenuItem>
                                    </Select>
                                    {formik.touched.language && formik.errors.language && (
                                        <FormHelperText error>
                                            {formik.errors.language}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl className="form-control" variant="filled">
                                    <InputLabel>Country</InputLabel>
                                    <Select
                                        ref={countryRef}
                                        label="Country"
                                        variant="filled"
                                        name="country"
                                        className="selector access"
                                        disableUnderline
                                        onClose={closeCountrySelect}
                                        onOpen={openCountrySelect}
                                        open={countrySelect}
                                        onChange={formik.handleChange}
                                        value={formik.values.country}
                                        inputProps={{
                                            disableUnderline: true,
                                            className: 'filled-select'
                                        }}
                                        IconComponent={(props) => {
                                            return (
                                                <DownArrowIcon
                                                    className="down-arrow-icon"
                                                    onClick={openCountrySelect}
                                                />
                                            );
                                        }}
                                        MenuProps={{
                                            anchorEl: anchorCountry
                                        }}>
                                        {countryOption.map((c) => (
                                            <MenuItem value={c.value}>{c.label}</MenuItem>
                                        ))}
                                    </Select>
                                    {formik.touched.country && formik.errors.country && (
                                        <FormHelperText error>
                                            {formik.errors.country}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <Box className="background-select-container">
                                    <Typography className="title">Background</Typography>
                                    <Box className="image-list">
                                        {ROOM_IMAGE_LIST.map((r) => (
                                            <Box
                                                onClick={() => setRoomBGImage(r)}
                                                variant="text"
                                                style={{ backgroundImage: `url("${r.image}")` }}
                                                className={`image-container ${
                                                    bgImage === r.value && 'selected'
                                                }`}>
                                                {r.lock && (
                                                    <LockIcon
                                                        style={{
                                                            cursor: 'pointer',
                                                            '-webkit-tap-highlight-color':
                                                                'transparent'
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                                <Box className="terms-container">
                                    <Typography className="title">Terms</Typography>
                                    <Typography className="admin-approval-txt">
                                        Changing the name of your room will need admin approval.
                                    </Typography>
                                    <Box className="terms-check-container">
                                        <FormControl className="form-control" variant="filled">
                                            <Checkbox
                                                color="default"
                                                value={formik.values.terms}
                                                onChange={formik.handleChange}
                                                name="terms"
                                            />
                                        </FormControl>
                                        <Typography className="text">
                                            I agree to the{' '}
                                            <span className="link">Community Guidelines</span>
                                        </Typography>
                                    </Box>
                                    {formik.touched.terms && formik.errors.terms && (
                                        <FormHelperText error>{formik.errors.terms}</FormHelperText>
                                    )}
                                </Box>
                                <Box className="btn-container">
                                    <TaoButton className="create-room-btn" type="submit">
                                        Request Approval
                                    </TaoButton>
                                    <TaoButton className="cancel-room-btn">Cancel</TaoButton>
                                </Box>
                                <Box className="delete-room-container">
                                    <IconButton className="icon-btn">
                                        <DeleteIcon
                                            width="52"
                                            height="52"
                                            className="delete-icon"
                                        />
                                    </IconButton>
                                    <Typography className="delete-text">Delete Room</Typography>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </Box>
                <Footer showWhiteBackground={false} isBottomZero={true} />
            </AddUpdateRoomWrapper>
        </>
    );
};

export default AddUpdateRoom;

const ICON_LIST = [
    { name: 'Health', value: 'HEALTH_ICON', Icon: HealthIcon },
    { name: 'Relationship', value: 'RELATION_ICON', Icon: RelationshipIcon },
    { name: 'Performance', value: 'PERFORMANCE_ICON', Icon: BusinessAndFinanceIcon },
    { name: 'Finance', value: 'FINANCE_ICON', Icon: PeakPerformanceIcon },
    { name: 'Pregnancy', value: 'PREGNANCY_ICON', Icon: PregnancyIcon },
    { name: 'Education', value: 'EDUCATION_ICON', Icon: ChildrenAndEducationIcon }
];

const ROOM_IMAGE_LIST = [
    { name: 'ROOM_IMAGE_1', value: 'ROOM_IMAGE_1', image: RoomImage1 },
    { name: 'ROOM_IMAGE_2', value: 'ROOM_IMAGE_2', image: RoomImage2 },
    { name: 'ROOM_IMAGE_3', value: 'ROOM_IMAGE_3', image: RoomImage3 },
    { name: 'ROOM_IMAGE_4', value: 'ROOM_IMAGE_4', image: RoomImage4, lock: true }
];
