//CORE
import React from 'react';

// LOTTIE
import Lottie from 'lottie-react';

//CUSTOM
import { WavesAnimationWrapper } from './WaveAnimation.style';

export const WaveAnimation = (props) => {
    
    return (
        <React.Fragment>
            <WavesAnimationWrapper style={{ ...props?.customStyle }}>
                <Lottie lottieRef={props.refComp} loop autoplay animationData={props.animation} />
            </WavesAnimationWrapper>
        </React.Fragment>
    );
};
