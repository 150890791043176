import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { URL_HOME_PAGE, URL_SALES_PAGE_TWO } from 'Helpers/Paths';
import { PAGE_SHARING } from 'Helpers/Constants';

export default function SharedLink() {
    const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams.get('quoteId') && searchParams.get('page')) {
            if (isLoggedIn && searchParams.get('page') === PAGE_SHARING.HOME) {
                navigate(
                    {
                        pathname: URL_HOME_PAGE,
                        search: `?quoteId=${searchParams.get('quoteId')}`
                    },
                    {
                        replace: true
                    }
                );
            } else {
                navigate(
                    {
                        pathname: URL_SALES_PAGE_TWO,
                        search: `?quoteId=${searchParams.get('quoteId')}`
                    },
                    {
                        replace: true
                    }
                );
            }
        } else {
            navigate('/', {
                replace: true
            });
        }
    }, [isLoggedIn, navigate, searchParams]);

    return <React.Fragment />;
}
