import { Dialog } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const PlaylistManipulationModalWrapper = styled(Dialog)`
    .rounded-popup {
        width: 40%;
        height: max-content;
        border-radius: 30px;
        min-width: 300px;
        max-height: 80vh;
        padding: 10px;
    }

    .main-container {
        display: flex;
        flex-direction: column;
        padding: 10px;
        overflow: auto;

        ${responsive.TABLET`
            display: flex;
            flex-direction: column;
            height: 100vh;
        `}

        .heading-container {
            display: flex;
            gap: 10px;

            .heading {
                font-family: ${FONTS.SECONDARY};
                font-weight: 700;
                color: ${COLORS.PRIMARY};
                font-size: 40px;
                line-height: 40px;
            }
        }

        .input-container {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100;
            padding: 10px 20px 6px 10px;
            gap: 10px;
            background: rgba(255, 255, 255, 0.66);
            backdrop-filter: blur(30px);
            border: 1px solid rgba(132, 132, 132, 0.4);
            border-radius: 8px;

            .input {
                vertical-align: center;
                width: 100%;
                padding: 2px 0px;
            }
        }

        .playlist-container {
            .heading {
                margin-top: 20px;
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 24px;
                line-height: 35px;
                text-transform: capitalize;
                color: ${COLORS.PRIMARY_DARK};
            }

            .scroll-container {
                height: 350px;
                padding: 20px 0px;

                ${responsive.TABLET`
                    height: 65vh;
                `}

                .playlist-item {
                    justify-content: space-evenly;

                    .list {
                        list-style-type: none;
                        margin-bottom: 8px;

                        .list-item {
                            display: flex;
                            justify-content: space-between;
                            padding: 0px 20px 0px 0px;
                            margin-bottom: 20px;
                            width: 100%;

                            .list-sub-item {
                                display: flex;
                                width: fit-content;

                                .grip-icon {
                                    width: 20px;
                                }
                            }

                            .image-container {
                                display: flex;
                                position: relative;

                                .lock-content {
                                    position: absolute;
                                    bottom: 5px;
                                    left: 5px;
                                    border-radius: 50px;
                                    background: rgba(101, 101, 101, 0.7);
                                    padding: 3px;
                                }

                                .list-image {
                                    height: 60px;
                                    width: 100px;
                                    border-radius: 12px 4px;
                                    overflow: hidden;
                                }

                                .play-icon {
                                    position: relative;
                                    margin: 8px 25px;
                                    width: 30px;
                                }
                            }

                            .play-description {
                                display: flex;
                                flex-direction: column;
                                width: 70vw;
                                margin-left: 10px;
                                margin-top: -3px;
                                flex-wrap: wrap;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                .title {
                                    font-family: ${FONTS.PRIMARY};
                                    font-weight: 500;
                                    font-size: 18px;
                                    line-height: 27px;
                                    color: ${COLORS.PRIMARY_WARM};
                                    width: 100%;
                                    white-space: pre-wrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }

                                .affected-part {
                                    font-family: ${FONTS.PRIMARY};
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 18px;
                                    color: ${COLORS.MEDIUM_GREY};
                                    width: inherit;
                                    display: block;
                                    width: 100%;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: pre-wrap;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                }

                                .time {
                                    font-family: ${FONTS.PRIMARY};
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 18px;
                                    color: ${COLORS.MEDIUM_GREY};
                                }
                            }
                            .button-container {
                                align-self: center;

                                .icon-button {
                                    background-color: ${COLORS.LIGHT_GREY};
                                    border-radius: 45px;
                                    padding: 1rem;

                                    ${responsive.TABLET`
                                        width: 30px;
                                        height: 30px;
                                    `}

                                    .grip-icon {
                                        ${responsive.TABLET`
                                            width: 10px;
                                            height: 10px;
                                        `}
                                    }
                                }
                            }
                        }
                    }

                    .load-more {
                        flex-direction: column;

                        .load-more-text {
                            font-family: ${FONTS.PRIMARY};
                            font-style: normal;
                            font-weight: 500;
                            font-size: 17px;
                            line-height: 17px;
                            color: ${COLORS.PRIMARY};
                            letter-spacing: -0.02em;
                            text-transform: capitalize;
                        }

                        .load-more-arrow {
                            transform: rotateZ(90deg);
                            width: 15px;
                            height: 15px;
                        }
                    }
                }

                .add-item {
                    display: flex;

                    .grip {
                        width: 20px;
                    }

                    .add-item-container {
                        display: flex;
                        background-color: ${COLORS.LIGHT_GREY};
                        border-radius: 12px 4px;
                        height: 60px;
                        width: 98px;
                        justify-content: center;

                        .grip-icon {
                            width: 45px;
                            margin-top: 5px;
                        }
                    }

                    .play-description {
                        display: flex;
                        align-items: center;
                        color: ${COLORS.MEDIUM_GREY};
                        margin-left: 10px;
                    }
                }
            }
        }

        .button-container {
            display: flex;
            justify-content: center;

            .button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 0px;
                gap: 10px;
                width: 100%;
                background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
                border-radius: 12px;

                .text {
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 23px;
                    text-align: right;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    color: ${COLORS.WHITE};
                }
            }
        }
    }
`;
