import { Box, Typography } from '@mui/material';
import React from 'react';
import CartBottom from '../CartBottom/CartBottom';
import { useNavigate } from 'react-router-dom';
import { URL_REFERRAL_PRODUCT_DETAILS } from 'Helpers/Paths';
import InfiniteScroll from 'react-infinite-scroll-component';
import ContentLoader from 'react-content-loader';
import { ReactComponent as CartAddedItem } from 'Assets/images/Profile/in-shopping-cart.svg';
import { ReactComponent as PurchasedItem } from 'Assets/images/Profile/purchased.svg';

const StoreItemLoader = (props) => (
    <Box className="inner-child">
        {[1, 2, 3, 4, 5, 6].map((item) => (
            <React.Fragment key={item}>
                <ContentLoader
                    speed={2}
                    width="100%"
                    height={250}
                    backgroundColor="#dfdfdf"
                    foregroundColor="#ecebeb"
                    preserveAspectRatio="none"
                    {...props}>
                    <rect x="0" y="0" rx="18" ry="18" width="100%" height="220" />
                    <rect x="0" y="230" rx="6" ry="6" width="100%" height="20" />
                </ContentLoader>
            </React.Fragment>
        ))}
    </Box>
);

export const StoreProducts = ({
    list,
    isStoreLoading,
    loadMoreItems,
    totalRecords,
    totalCartItem,
    ...props
}) => {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Box className="store-products" id="productScrollableArea">
                {isStoreLoading && <StoreItemLoader />}
                <InfiniteScroll
                    dataLength={list.length}
                    next={loadMoreItems}
                    hasMore={list?.length !== totalRecords}
                    loader={isStoreLoading && <StoreItemLoader />}
                    scrollableTarget="productScrollableArea">
                    <Box className="inner-child">
                        {list.map((item) => (
                            <Box
                                className="item hover"
                                key={item?.id}
                                onClick={() => {
                                    if (item?.isPurchased) return;
                                    navigate(URL_REFERRAL_PRODUCT_DETAILS, {
                                        state: { productId: item?.id }
                                    });
                                }}>
                                {item?.isAddedInCart && (
                                    <Box className="cart-added-item flex f-v-center f-h-center">
                                        <CartAddedItem />
                                    </Box>
                                )}
                                {item?.isPurchased && (
                                    <Box className="cart-added-item purchased flex f-v-center f-h-center">
                                        <PurchasedItem />
                                    </Box>
                                )}
                                <Box
                                    className={`product-image ${
                                        item?.isAddedInCart && 'available-in-cart'
                                    }`}
                                    style={{ backgroundImage: `url(${item?.image})` }}>
                                    <Typography
                                        className={`item-duration ${
                                            item?.isPurchased && 'purchased'
                                        }`}>
                                        {item?.isPurchased ? 'Purchased' : item?.availableDays}
                                    </Typography>
                                </Box>
                                <Typography className="item-desc">{item?.name}</Typography>
                            </Box>
                        ))}
                    </Box>
                </InfiniteScroll>
            </Box>

            <CartBottom totalCartItem={totalCartItem} isLoading={!list?.length && isStoreLoading} />
        </React.Fragment>
    );
};
