import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { TaoRoomCardWrapper } from './TaoRoomCard.style';
import { numberFormatter } from 'Helpers';
import DefaultRoomImg from 'Assets/images/MeditationRooms/room-default-image.png';
import { ReactComponent as FollowerProfileIcon } from 'Assets/images/MeditationRooms/person.svg';
import { ReactComponent as NotApprovedIcon } from 'Assets/images/MeditationRooms/not-approved-icon.svg';
import { ReactComponent as PendingIcon } from 'Assets/images/MeditationRooms/pending-icon.svg';
import { ROOM_STATUS } from 'Helpers/Constants';
import FavoriteIconAnimation from '../FavoriteIconAnimation';
import { WhiteStart } from '../StartImages/StarImages';

const TaoRoomCard = ({
    roomImage = DefaultRoomImg,
    followerCount = 0,
    ratingCount = 0,
    title = '',
    isLive = false,
    hideFollowers = false,
    isFav = false,
    isTCF = false,
    roomStatus = ROOM_STATUS.APPROVED,
    myRoom = false,
    isNew = false,
    key = '',
    favIconVisible = false,
    onClick = () => {},
    ...props
}) => {
    return (
        <TaoRoomCardWrapper isTCF={isTCF} $isLive={isLive} onClick={onClick} key={key}>
            {isLive && <Box className="live badge flex f-v-center f-h-center">LIVE</Box>}
            {isNew && <Box className="badge new flex f-v-center f-h-center">NEW</Box>}
            {favIconVisible && (
                <Box className="fav-btn">
                    <FavoriteIconAnimation isWhiteIcon isFavorite={props?.isFav} />
                </Box>
            )}

            <Box className="room-image-container" style={{ backgroundImage: `url(${roomImage})` }}>
                <Box className="black-shadow" />
                <Box className="black-shadow bottom" />
            </Box>

            {roomStatus === ROOM_STATUS.APPROVED && (
                <Box className="content-container flex f-column">
                    <Tooltip title={title}>
                        <Typography className="title">{title}</Typography>
                    </Tooltip>
                    {!hideFollowers && (
                        <Box className="follower-content">
                            <FollowerProfileIcon className="person-icon" color="primary" />
                            <Typography className="follow-text">
                                {numberFormatter(followerCount)} followers
                            </Typography>
                        </Box>
                    )}
                    <Box className="rating-container">
                        {!isNew && (
                            <>
                                <WhiteStart fill />
                                <WhiteStart fill />
                                <WhiteStart />
                                <WhiteStart />
                                <WhiteStart />
                            </>
                        )}
                        {!isNew && (
                            <Typography component="span" className="rating-count">
                                {numberFormatter(ratingCount)}
                            </Typography>
                        )}
                        {isNew && (
                            <Typography component="span" className="rating-count">
                                No ratings yet
                            </Typography>
                        )}
                    </Box>
                </Box>
            )}
            {roomStatus === ROOM_STATUS.NOT_APPROVED && (
                <Box className="content-container not-approved-room-container flex f-column">
                    <Tooltip title={title}>
                        <Typography className="title">{title}</Typography>
                    </Tooltip>
                    <Box className="status-container">
                        <NotApprovedIcon width="30" height="30" className="icon" />
                        <Typography className="text">
                            NOT
                            <br />
                            APPROVED
                        </Typography>
                    </Box>
                </Box>
            )}
            {roomStatus === ROOM_STATUS.PENDING && (
                <Box className="content-container pending-room-container flex f-column">
                    <Tooltip title={title}>
                        <Typography className="title">{title}</Typography>
                    </Tooltip>
                    <Box className="status-container">
                        <PendingIcon width="30" height="30" className="icon" />
                        <Typography className="text">
                            APPROVAL
                            <br />
                            PENDING
                        </Typography>
                    </Box>
                </Box>
            )}
        </TaoRoomCardWrapper>
    );
};

export default TaoRoomCard;
