import { useDispatch } from 'react-redux';
import { ReferralShadowBoxLayout } from '../Referral.style';
import { OrderDetailsWrapper } from './OrderDetails.style';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_URL, URL_PROFILE } from 'Helpers/Paths';
import { PROFILE_TAB_TYPE } from 'Helpers/Constants';
import Api from 'Helpers/ApiHandler';
import ContentLoader from 'react-content-loader';

const KeyValuesLoading = () => {
    return (
        <ContentLoader
            speed={2}
            width="100%"
            height={30}
            backgroundColor="#dfdfdf"
            foregroundColor="#ecebeb"
            preserveAspectRatio="none">
            <rect x="0" y="0" rx="4" ry="4" width="30%" height="100%" />
        </ContentLoader>
    );
};
const AmountLoading = ({ rightAuto = true }) => (
    <ContentLoader
        speed={2}
        width="100%"
        height={30}
        backgroundColor="#dfdfdf"
        foregroundColor="#ecebeb"
        preserveAspectRatio="none">
        <rect x={rightAuto ? '70%' : '10'} y="0" rx="4" ry="4" width="30%" height="100%" />
    </ContentLoader>
);

const ITEMs = 3;
const ItemsLoading = () => {
    return [...Array(ITEMs).keys()].map((item, index) => (
        <React.Fragment>
            <Box className="list-item flex f-v-center" key={item}>
                <ContentLoader
                    speed={2}
                    width="100%"
                    height={60}
                    backgroundColor="#dfdfdf"
                    foregroundColor="#ecebeb"
                    preserveAspectRatio="none">
                    <rect x="0" y="0" rx="4" ry="4" width="60" height="60" />
                    <rect x="80" y="0" rx="6" ry="6" width="80%" height="60" />
                </ContentLoader>
            </Box>
            <Box className={`divider-border ${ITEMs === index + 1 && 'last-child'}`} />
        </React.Fragment>
    ));
};

const KeyPairValues = ({ item, value, isLoading }) => (
    <Box className="flex f-v-center">
        <Typography className="key">{item}:</Typography>
        {isLoading ? (
            <Box className="value flex f-v-center f-h-center">
                <KeyValuesLoading />
            </Box>
        ) : (
            <Typography className="value">{value}</Typography>
        )}
    </Box>
);

const OrderDetails = ({ title, isOrderSuccess = false, orderedData = {}, ...props }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const API = useMemo(() => new Api(), []);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({ ...orderedData });
    const location = useLocation();

    const fetchOrderDetails = useCallback(async () => {
        if (!location?.state?.orderId) return;
        setLoading(true);
        let response = await API.get(API_URL.ORDER, {
            params: { orderId: location?.state?.orderId }
        });

        if (response) {
            setData(response?.data?.data);
            setLoading(false);
        }
    }, [API, location?.state?.orderId]);

    useEffect(() => {
        dispatch(hideSideBar());
        fetchOrderDetails();
        return () => dispatch(showSideBar());
    }, [dispatch, fetchOrderDetails]);

    return (
        <React.Fragment>
            <TaoCalligraphyWatermark />
            <OrderDetailsWrapper $isOrderSuccess={isOrderSuccess}>
                <ReferralShadowBoxLayout>
                    <Box className="title">{title || 'Order Details'}</Box>
                    <Box className="container">
                        <Box className="scrollable-area">
                            <KeyPairValues
                                item="OrderId"
                                value={data?.orderId}
                                isLoading={isLoading}
                            />
                            <KeyPairValues
                                item="Date"
                                value={moment(data?.orderDate, 'YYYY-MM-DD').format('MMM DD, YYYY')}
                                isLoading={isLoading}
                            />
                            <KeyPairValues
                                item="Status"
                                value={data?.orderStatus}
                                isLoading={isLoading}
                            />

                            <Typography className="sub-title">Items</Typography>

                            {isLoading ? (
                                <ItemsLoading />
                            ) : (
                                data?.cartItemList?.map((item, index) => (
                                    <React.Fragment key={item?.cartItemId}>
                                        <Box className="list-item flex f-v-center">
                                            <Box
                                                className="image"
                                                style={{
                                                    backgroundImage: `url(${item?.itemImage})`
                                                }}>
                                                <Typography className="duration elipsis">
                                                    {item?.availableDays}
                                                </Typography>
                                            </Box>

                                            <Typography className="item-title elipsis">
                                                {item?.itemName}
                                            </Typography>
                                            <Typography className="price">
                                                {item?.currencySymbol}
                                                {item?.price}
                                            </Typography>
                                        </Box>

                                        {isOrderSuccess && (
                                            <Box className="access-it-now">
                                                <Typography className="text hover">
                                                    {item?.accessStatus}
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box
                                            className={`divider-border ${
                                                data?.cartItemList?.length === index + 1 &&
                                                'last-child'
                                            }`}
                                        />
                                    </React.Fragment>
                                ))
                            )}

                            <Box className="flex f-h-space-between">
                                <Typography className="sub-title decrease-font-size text-uppercase">
                                    Subtotal
                                </Typography>
                                <Typography className="sub-title decrease-font-size">
                                    {isLoading ? <AmountLoading /> : `${data?.subTotalPrice}`}
                                </Typography>
                            </Box>

                            {/* <Box className="flex f-h-space-between">
                                <Typography className="order-tax-and-shipping-value ">
                                    {'Tax (9%)'}
                                </Typography>
                                <Typography className="order-tax-and-shipping-value">
                                    $4.50
                                </Typography>
                            </Box>
                            <Box className="flex f-h-space-between">
                                <Typography className="order-tax-and-shipping-value ">
                                    {'Shipping (7–10 Days)'}
                                </Typography>
                                <Typography className="order-tax-and-shipping-value">
                                    $10.00
                                </Typography>
                            </Box> */}
                            <Box className="divider-border" />
                            <Box className="flex f-h-space-between">
                                <Typography className="total-order-value text-uppercase">
                                    Total
                                </Typography>
                                <Typography className="total-order-value">
                                    {isLoading ? <AmountLoading /> : `${data?.totalPrice}`}
                                </Typography>
                            </Box>
                            <Box className="flex">
                                <Typography className="payment-and-shipping">Paid by:</Typography>
                                <Box className="flex f-v-center">
                                    {isLoading ? (
                                        <AmountLoading rightAuto={false} />
                                    ) : (
                                        <React.Fragment>
                                            {data?.isPaidByRewardPoints && (
                                                <Box className="payment-icon" />
                                            )}
                                            <Typography className="payment-and-shipping value">
                                                {data?.isPaidByRewardPoints
                                                    ? `${data?.totalPriceInPoint} Reward Points`
                                                    : '4242'}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                </Box>
                            </Box>
                            {/* <Box className="flex bottom-space">
                                <Typography className="payment-and-shipping">Ship to:</Typography>
                                <Typography className="payment-and-shipping value">
                                    42 Street Name, City, State, Country, ZIP
                                </Typography>
                            </Box> */}
                        </Box>
                        {isOrderSuccess && (
                            <Button
                                className="earn-points-button"
                                endIcon={<Box className="arrow" />}
                                onClick={() =>
                                    navigate(URL_PROFILE, {
                                        state: { type: PROFILE_TAB_TYPE.REFERRALS }
                                    })
                                }>
                                Earn More Points
                            </Button>
                        )}
                    </Box>
                </ReferralShadowBoxLayout>
            </OrderDetailsWrapper>
        </React.Fragment>
    );
};

export default OrderDetails;
