import React from 'react';

const RepeatSVG = (props) => {
    return (
        <>
            <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.2929 4.29289C19.6834 3.90237 20.3166 3.90237 20.7071 4.29289L24.7071 8.29289C25.0976 8.68342 25.0976 9.31658 24.7071 9.70711L20.7071 13.7071C20.3166 14.0976 19.6834 14.0976 19.2929 13.7071C18.9024 13.3166 18.9024 12.6834 19.2929 12.2929L22.5858 9L19.2929 5.70711C18.9024 5.31658 18.9024 4.68342 19.2929 4.29289Z"
                    fill={props.fill ? props.fill : '#848484'}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 10C9.20435 10 8.44129 10.3161 7.87868 10.8787C7.31607 11.4413 7 12.2044 7 13V14C7 14.5523 6.55228 15 6 15C5.44772 15 5 14.5523 5 14V13C5 11.6739 5.52678 10.4021 6.46447 9.46447C7.40215 8.52678 8.67392 8 10 8H24C24.5523 8 25 8.44772 25 9C25 9.55228 24.5523 10 24 10H10Z"
                    fill={props.fill ? props.fill : '#848484'}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.7071 16.2929C11.0976 16.6834 11.0976 17.3166 10.7071 17.7071L7.41421 21L10.7071 24.2929C11.0976 24.6834 11.0976 25.3166 10.7071 25.7071C10.3166 26.0976 9.68342 26.0976 9.29289 25.7071L5.29289 21.7071C4.90237 21.3166 4.90237 20.6834 5.29289 20.2929L9.29289 16.2929C9.68342 15.9024 10.3166 15.9024 10.7071 16.2929Z"
                    fill={props.fill ? props.fill : '#848484'}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M24 15C24.5523 15 25 15.4477 25 16V17C25 18.3261 24.4732 19.5979 23.5355 20.5355C22.5979 21.4732 21.3261 22 20 22H6C5.44772 22 5 21.5523 5 21C5 20.4477 5.44772 20 6 20H20C20.7956 20 21.5587 19.6839 22.1213 19.1213C22.6839 18.5587 23 17.7956 23 17V16C23 15.4477 23.4477 15 24 15Z"
                    fill={props.fill ? props.fill : '#848484'}
                />
                {props?.fill && !props?.playlistRepeat && props?.playOver && (
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.5 12.5C12.5 11.8096 13.0596 11.25 13.75 11.25H15C15.6904 11.25 16.25 11.8096 16.25 12.5V17.5C16.25 18.1904 15.6904 18.75 15 18.75C14.3096 18.75 13.75 18.1904 13.75 17.5V13.75C13.0596 13.75 12.5 13.1904 12.5 12.5Z"
                        fill="#B08C3D"
                    />
                )}
            </svg>
        </>
    );
};

export default RepeatSVG;
