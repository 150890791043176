import styled from 'styled-components';
import { Box, Backdrop } from '@mui/material';
import { FONTS, COLORS } from 'Styles/Constants';

export const TaoSessionRecoCardWrapper = styled(Box)`
    width: 150px;
    height: 204px;
    border: ${(props) =>
        props.$isLive
            ? '1px solid #BC1218'
            : props.$isTaoCalligraphyTeacher
            ? `1px solid ${COLORS.PRIMARY_DARK}`
            : '1px solid #E8E8E8'};
    background-color: #f2f2f2;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .card-top {
        background-image: ${(props) => props.$meditationImage && `url(${props.$meditationImage})`};
        position: relative;
        width: 100%;
        height: 76px;
        overflow: hidden;
        .avatar {
            position: absolute;
            height: 69px;
            width: 68px;
            top: 4px;
            left: 4px;
        }

        .teacher-icon {
            position: absolute;
            right: 2px;
            top: 2px;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background-color: white;
        }

        .live-bar {
            background-color: #bc1218;
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: 0;
            color: white;
            font-weight: 500;
            font-size: 11px;
            line-height: 15px;
        }
        .blur {
            background-color: rgba(255, 255, 255, 0.5);
            width: 100%;
            height: 100%;
        }
    }

    .text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 7px 0 7px 12px;
        gap: 2px;
        .session-time {
            color: #848484;
            font-size: 12.5px;
            line-height: 18px;
        }

        /* .MuiTypography-root {
            font-family: ${FONTS.PRIMARY};
            font-size: 14px;
        } */

        .session-title {
            font-family: ${FONTS.PRIMARY_MEDIUM};
            font-size: 16.0784px;
            line-height: 16px;
            max-height: 32px;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .session-teacher {
            color: #848484;
            font-size: 12.5px;
            line-height: 18px;
        }
    }

    .icon-container {
        display: flex;
        justify-content: center;
        /* gap: 0.2rem; */
        min-width: 51px;
        -webkit-tap-highlight-color: transparent;
    }

    .signup-btn {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        width: 80px;
        height: 30px;
        color: white;
        box-shadow: none;
        margin: auto;
    }

    .dummy-accordion {
        height: 27px;
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 10px;
        background-color: white;
        margin-bottom: 0.5rem;
        margin-top: auto;
        border: 1px solid #e8e8e8;
        border-radius: 6px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        .accordion-value {
            font-weight: bold;
            font-size: 13.3987px;
            line-height: 16px;
            color: black;
        }
        .dropdown-icon {
            transform: rotate(90deg);
            width: 14px;
            height: 12px;
            right: 5px;
            position: relative;
        }
    }

    .reverse-accordion {
        height: 1rem;
        width: 100%;
        overflow-y: hidden;
        margin-bottom: 0.5rem;
        margin-top: auto;
        align-self: center;
        transition: height 400ms;
        &.open {
            height: 3rem;
        }
        .accordion-item {
            min-height: 1rem;
        }
    }
    /* .select-container {
        display: flex; 
        justify-content: center;
        width:"90%";


        .tao-select {
            background-color: white;
            border: 1px solid #E8E8E8;
            border-radius: 6px;
            width: 133.99px;
            height: 27.72px;
            padding: 0;
            .dropdown {
                position: relative;
                top: 10px;
            }
            .dropdown-icon {
                transform: rotate(90deg);
                width: 14px; 
                height: 12px; 
                right: 5px; 
                position: relative;
            }
        }
    } */
`;

export const TaoSessionRecoCardMobileWrapper = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 20px;
    padding: 15px;

    .upcoming-session-image {
        position: relative;
        display: inline-block;

        .live-indicator {
            position: absolute;
            background-color: #bc1218;
            color: #ffffff;
            font-family: ${FONTS.PRIMARY_MEDIUM};
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 98%;
            top: 58px;
            border-radius: 0px 0px 10px 10px;
            margin-left: 1px;
        }

        .playlist-session {
            position: absolute;
            top: 5px;
            left: 10px;
        }
    }

    .upcoming-session-date-details {
        flex: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        color: #31290e;

        .session-time {
            color: #848484;
        }

        .MuiTypography-root {
            font-family: ${FONTS.PRIMARY};
            font-size: 14px;
        }

        .session-title {
            font-family: ${FONTS.PRIMARY_MEDIUM};
            font-size: 18px;
            line-height: 18px;
        }

        .session-teacher {
            color: #848484;
        }

        .host-container {
            display: flex;
            gap: 5px;

            .hosting-text {
                line-height: 20px;
                font-weight: 500;
            }
        }
    }

    .upcoming-session-navigate {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 15px;

        .icon-container {
            display: flex;
            justify-content: center;
            -webkit-tap-highlight-color: transparent;
        }

        .signup-btn {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            width: 80px;
            height: 30px;
            color: white;
            box-shadow: none;
        }
    }
`;

export const TaoSessionSignupModalWrapper = styled(Backdrop)`
    .dialog-container {
        width: 386px;
        height: 337px;
        background-color: #ffffff;
        border: 1px solid #e8e8e8;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
        border-radius: 24px;
        padding: 16px;
        position: absolute;
        top: 0;
        left: 0;

        .dialog-title {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-bottom: 25px;
            .close-icon-container {
                margin: 0 0 auto auto;
            }
        }
        .dialog-content {
            display: flex;
            width: 100%;
            flex-direction: column;
            .option {
                padding: 25px 0;
                text-align: center;
                width: 100%;
                border-bottom: 0.5px solid #e8e8e8;
                .free {
                    background-color: #b08c3d;
                    border-radius: 29px;
                }
            }
        }
    }
`;
