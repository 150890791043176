// import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

let horizontalAScrollStyle = {
    display: 'flex',
    width: '100%',
    float: 'left'
};

let verticalScrollStyle = {
    overflow: 'auto',
    top: 0,
    bottom: 0,
    // right: '-15px',
    width: '100%',
    position: 'absolute',
    left: 0
};

export const ScrollbarWrapper = styled(Box)`
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const TaoScrollbar = ({ children, customStyle, ref, displayScroll = false, ...props }) => {
    const scrollRef = useRef(null);
    const mouseDown = useRef(null);
    const startX = useRef(null);
    const scrollLeft = useRef(null);

    const startDragging = useCallback(
        (e) => {
            let slider = document?.getElementById(props?.keyId);
            mouseDown.current = true;
            startX.current = (e?.pageX || e?.targetTouches?.[0]?.clientX) - slider.offsetLeft;
            scrollLeft.current = slider.scrollLeft;
        },
        [props?.keyId]
    );

    const mouseMove = useCallback(
        (e) => {
            let slider = document?.getElementById(props?.keyId);
            e.preventDefault();
            if (!mouseDown.current) {
                return;
            }
            const x = (e?.pageX || e?.targetTouches?.[0]?.clientX) - slider.offsetLeft;
            const scroll = x - startX.current;
            slider.scrollLeft = scrollLeft.current - scroll;
        },
        [props?.keyId]
    );

    const stopDragging = useCallback(() => {
        mouseDown.current = false;
    }, []);

    useEffect(() => {
        if (props?.scrollLeft > -1) {
            let slider = document?.getElementById(props?.keyId);
            slider.scrollLeft = props?.scrollLeft;
        }
    }, [props?.scrollLeft, props?.keyId]);

    useEffect(() => {
        if (!props?.keyId) return;
        let slider = document?.getElementById(props?.keyId);
        slider.addEventListener('mousemove', mouseMove);
        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);

        slider.addEventListener('touchmove', mouseMove);
        slider.addEventListener('touchstart', startDragging, false);
        slider.addEventListener('touchend', stopDragging, false);
        slider.addEventListener('touchcancel', stopDragging, false);

        return () => {
            slider.removeEventListener('mousemove', mouseMove);
            slider.removeEventListener('mousedown', startDragging, false);
            slider.removeEventListener('mouseup', stopDragging, false);
            slider.removeEventListener('mouseleave', stopDragging, false);

            slider.removeEventListener('touchmove', mouseMove);
            slider.removeEventListener('touchstart', startDragging, false);
            slider.removeEventListener('touchend', stopDragging, false);
            slider.removeEventListener('touchcancel', stopDragging, false);
        };
    }, [props?.keyId, startDragging, stopDragging, mouseMove]);

    let style = props?.horizontalScroll ? horizontalAScrollStyle : verticalScrollStyle;

    return (
        <React.Fragment>
            <Box
                style={{
                    position: 'relative',
                    overflow: 'hidden',
                    height: '100%',
                    width: '100%',
                    float: 'left',
                    scrollBehavior: 'smooth'
                }}
                id={props?.keyId}>
                {displayScroll ? (
                    <Box
                        ref={scrollRef}
                        sx={{
                            ...style,
                            ...customStyle
                        }}>
                        {children}
                    </Box>
                ) : (
                    <ScrollbarWrapper
                        ref={scrollRef}
                        sx={{
                            ...style,
                            ...customStyle
                        }}>
                        {children}
                    </ScrollbarWrapper>
                )}
            </Box>
        </React.Fragment>
    );
};

export default TaoScrollbar;
