import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { showToast } from 'Redux/App/Actions';

import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import {
    Box,
    TextField,
    Button,
    Typography,
    InputAdornment,
    IconButton,
    FormHelperText
} from '@mui/material';
import { ReactComponent as Visibility } from 'Assets/images/Common/eye.svg';
import { ReactComponent as VisibilityOff } from 'Assets/images/Common/chevron-down.svg';
import { ReactComponent as PasswordCrossIcon } from 'Assets/images/Common/cross.svg';
import { ReactComponent as CheckIcon } from 'Assets/images/Common/check.svg';
import { PasswordStrengthWrapper, LinearProgressWrapper } from './ConfirmPassword.style';

import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import CODES from 'Helpers/StatusCodes';
import { PASSWORD_EXP } from 'Helpers/Constants';

const changePasswordInitialValues = {
    password: '',
    newPassword: '',
    confirmPassword: ''
};

const changePasswordValidationSchema = Yup.object({
    password: Yup.string().required('Please enter your Password'),
    newPassword: Yup.string()
        .required('Please enter your New password')
        .matches(
            PASSWORD_EXP,
            'Password must contain one upper case, one lowercase, one special character, one digit and must be of 10 characters.'
        )
        .min(10, 'Password must contain at least 10 characters long'),
    confirmPassword: Yup.string()
        .required('Please enter Confirm password')
        .oneOf([Yup.ref('newPassword'), null], `Uh oh, passwords don't match.`)
});

const LinearProgressWithLabel = (props) => {
    const { value } = props;
    return (
        <PasswordStrengthWrapper>
            <Box className="progress-bar-wrapper">
                <LinearProgressWrapper
                    sx={{
                        height: 6,
                        borderRadius: 4,
                        backgroundColor: '#fff',
                        border: '1px solid rgba(132, 132, 132, 0.4)'
                    }}
                    classes={{ barColorPrimary: 'bg-color' }}
                    variant="determinate"
                    {...value}
                />
            </Box>
            <Box className="password-strength-text-wrapper">
                <Typography className="password-strength-text">{value?.text}</Typography>
            </Box>
        </PasswordStrengthWrapper>
    );
};

const PasswordFormObserver = () => {
    const { values } = useFormikContext();
    const [passwordStrengthDetails, setPasswordStrengthDetails] = useState({
        value: 0,
        text: 'Password Strength',
        background: ''
    });
    const passwordStrength = useCallback(() => {
        if (!values?.newPassword) {
            setPasswordStrengthDetails({
                value: 0,
                text: 'Password Strength'
            });
            return;
        }
        let count = 0;
        let passwordStrengthResponse = {
            10: {
                value: 10,
                text: 'Weak Password',
                background: '#BC1218'
            },
            20: {
                value: 30,
                text: 'Weak Password',
                background: '#BC1218'
            },
            30: {
                value: 50,
                text: 'Can Be Stronger',
                background: '#FFD26D'
            },
            40: {
                value: 80,
                text: 'Can Be Stronger',
                background: '#FFD26D'
            },
            50: {
                value: 100,
                text: 'Strong Password',
                background: '#83D841'
            }
        };
        if (values?.newPassword) {
            if (values?.newPassword.length >= 10) {
                count += 10;
            }
            // ONE UPPERCASE
            if (new RegExp('(?=.*[A-Z])').test(values?.newPassword)) {
                count += 10;
            }
            // ONE LOWERCASE
            if (new RegExp('(?=.*[a-z].*[a-z].*[a-z])').test(values?.newPassword)) {
                count += 10;
            }
            // ONE NUMBER
            if (new RegExp('(?=.*[0-9])').test(values?.newPassword)) {
                count += 10;
            }
            // ONE SPECIAL CHARACTER
            if (new RegExp('(?=.*[#?!@$%^&<>*~.:+`-])').test(values?.newPassword)) {
                count += 10;
            }
        }
        if (count) {
            setPasswordStrengthDetails(passwordStrengthResponse[count]);
        }
    }, [values?.newPassword]);

    useEffect(() => {
        passwordStrength();
    }, [passwordStrength]);

    return (
        <Box sx={{ margin: '0 0 10px 0' }}>
            <Box sx={{ width: '100%' }}>
                <LinearProgressWithLabel value={passwordStrengthDetails} />
            </Box>
        </Box>
    );
};

const ConfirmPassword = ({ ...props }) => {
    const passwordForm = useRef(null);
    const API = useMemo(() => new Api(), []);
    const [passwordToggle, setPasswordToggle] = useState(false);
    const [newPasswordToggle, setNewPasswordToggle] = useState(false);
    const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);

    const dispatch = useDispatch();

    const handlePasswordChangeSubmit = async (values, { resetForm }) => {
        props.setIsLoading(true);
        const response = await API.post(API_URL.CHANGE_PASSWORD, {
            params: {
                currentPassword: values.password,
                newPassword: values.newPassword
            },
            returnError: true
        });

        if (response?.status === CODES.SUCCESS) {
            resetForm({ values: '' });
            dispatch(showToast(response?.data?.message, 'success'));
        }
        props.setIsLoading(false);
    };

    return (
        <>
            <Formik
                innerRef={passwordForm}
                initialValues={changePasswordInitialValues}
                onSubmit={handlePasswordChangeSubmit}
                validationSchema={changePasswordValidationSchema}
                enableReinitialize>
                {({ values, errors, touched, handleChange, handleSubmit, setFieldTouched }) => (
                    <form action="" onSubmit={handleSubmit}>
                        <Typography className="change-pass">change password</Typography>
                        <Box className="password-input">
                            <TextField
                                variant="filled"
                                label="Password"
                                name="password"
                                className="text-field"
                                autoComplete="off"
                                value={values.password}
                                onChange={handleChange}
                                type={passwordToggle ? 'text' : 'password'}
                                InputProps={{
                                    disableUnderline: true,
                                    className: 'filled-input',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setPasswordToggle((prevState) => !prevState)
                                                }
                                                aria-label="toggle password visibility">
                                                {!passwordToggle ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <FormHelperText error style={{ margin: '10px' }}>
                                {touched?.password && errors?.password}
                            </FormHelperText>
                        </Box>
                        <Box className="password-input">
                            <TextField
                                variant="filled"
                                label="New Password"
                                className="text-field"
                                name="newPassword"
                                autoComplete="off"
                                value={values.newPassword}
                                onChange={(e) => {
                                    setFieldTouched('newPassword');
                                    handleChange(e);
                                }}
                                type={newPasswordToggle ? 'text' : 'password'}
                                InputProps={{
                                    disableUnderline: true,
                                    className: 'filled-input',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setNewPasswordToggle((prevState) => !prevState)
                                                }
                                                aria-label="toggle new password visibility">
                                                {!newPasswordToggle ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <FormHelperText error style={{ margin: '10px' }}>
                                {touched?.newPassword && errors?.newPassword}
                            </FormHelperText>
                            <PasswordFormObserver />
                        </Box>
                        <Box className="password-input">
                            <TextField
                                variant="filled"
                                label="Confirm Password"
                                className="text-field"
                                name="confirmPassword"
                                autoComplete="off"
                                value={values.confirmPassword}
                                onChange={(e) => {
                                    setFieldTouched('confirmPassword');
                                    handleChange(e);
                                }}
                                type={confirmPasswordToggle ? 'text' : 'password'}
                                InputProps={{
                                    disableUnderline: true,
                                    className: 'filled-input',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setConfirmPasswordToggle(
                                                        (prevState) => !prevState
                                                    )
                                                }
                                                aria-label="toggle confirm password visibility">
                                                {!confirmPasswordToggle ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {touched?.confirmPassword &&
                                (errors.confirmPassword && values.confirmPassword ? (
                                    <FormHelperText error={true} component={'span'}>
                                        <Box className="confirm-password-container">
                                            <PasswordCrossIcon />
                                            <Typography className="text danger">
                                                {errors.confirmPassword}
                                            </Typography>
                                        </Box>
                                    </FormHelperText>
                                ) : !errors.confirmPassword && values.confirmPassword ? (
                                    <FormHelperText error={false} component={'span'}>
                                        <Box className="confirm-password-container">
                                            <CheckIcon />
                                            <Typography className="text success">
                                                It's a match!
                                            </Typography>
                                        </Box>
                                    </FormHelperText>
                                ) : (
                                    <>
                                        <Box className="confirm-password-container">
                                            <Typography className="text">
                                                Password Must Match
                                            </Typography>
                                        </Box>
                                    </>
                                ))}
                            {!touched?.confirmPassword && (
                                <>
                                    <Box className="confirm-password-container">
                                        <Typography className="text">
                                            Password Must Match
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </Box>
                        <Button className="change-btn" type="submit">
                            change
                        </Button>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default ConfirmPassword;
