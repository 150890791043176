import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { ReactComponent as TaoLogoIcon } from 'Assets/images/Common/tao-calligraphy-teacher-indicator.svg';
import FieldRoom1Image from 'Assets/images/MeditationRooms/field-room-1.png';
import FieldRoom1BgOriginal from 'Assets/images/MeditationRooms/MeditationRoomDetails/sun-tcf-bg.png';
import FieldRoom2Image from 'Assets/images/MeditationRooms/field-room-2.png';
import FieldRoom2BgOriginal from 'Assets/images/MeditationRooms/MeditationRoomDetails/jungle-tcf-bg.webp';
import FeaturedRoom1Image from 'Assets/images/Profile/default-user-icon.jpg';
import Teacher1Img from 'Assets/images/Profile/default-user-icon.jpg';
import Teacher2Img from 'Assets/images/Profile/default-user-icon.jpg';
import Teacher3Img from 'Assets/images/Profile/default-user-icon.jpg';
import CommunityRoomImag from 'Assets/images/Profile/default-user-icon.jpg';
import { RoomFilterWrapper } from './RoomFilter.style';
import TaoRoomCard from 'Components/common/TaoRoomCard/TaoRoomCard';
import { API_URL, URL_MEDITATION_ROOM_DETAILS } from 'Helpers/Paths';
import { ROOM_TYPES } from 'Helpers/Constants';
import TaoSlider from 'Components/common/TaoSlider';
import Api from 'Helpers/ApiHandler';
import TaoScrollbar from 'Components/common/Scrollbar';
import {
    TitleLoader,
    WellnessCategoryLoader
} from 'Components/common/ShimmerEffectLoader/ShimmerEffectLoader';
import { Container } from '@mui/system';
import { SortFilter } from 'Components/common/SortFilter';

const SORT_BY_VALUE = {
    RATING: 'Rating',
    A_Z: 'A-Z'
};

const FILTER_BY_LANGUAGE = {
    ALL_LANGUAGE: 'All Language',
    ENGLISH: 'English',
    GERMAN: 'German'
};

const RoomFilter = ({ trialUser = false, roomType = '', ...props }) => {
    const navigate = useNavigate();
    const [sortBy, setSortBy] = useState(SORT_BY_VALUE?.RATING);
    const [filterByLanguage, setFilterByLanguage] = useState(FILTER_BY_LANGUAGE?.ALL_LANGUAGE);
    const fieldRoomSliderRef = useRef(null);
    const wellnessGroupRef = useRef(null);
    const [wellnessCategoryList, setWellnessCategoryList] = useState([]);
    const [categoryLoading, setCategoryLoading] = useState(false);
    const API = useMemo(() => new Api(), []);
    const [selectedCategory, setSelectedCategory] = useState();

    const [filedRoomList, setFieldRoomList] = useState([]);
    const [featuredRoomList, setFeaturedRoomList] = useState([]);
    const [communityRoomList, setCommunityList] = useState(cloneDeep(COMMUNITY_ROOMS));

    const fetchWellnessCategory = useCallback(async () => {
        setCategoryLoading(true);
        let response = await API.get(API_URL.WELLNESS_CATEGORY);

        if (response?.data?.data?.list?.length > 0)
            setWellnessCategoryList(response?.data?.data?.list);

        setCategoryLoading(false);
    }, [API]);

    useEffect(() => {
        switch (roomType) {
            case 'ALL':
                setFieldRoomList(cloneDeep(FIELDS_ROOM));
                setFeaturedRoomList(cloneDeep(FEATURED_ROOMS));
                setCommunityList(cloneDeep(COMMUNITY_ROOMS));
                break;

            case 'FOLLOWING':
                setFieldRoomList(FIELDS_ROOM.filter((r) => r.isFollowing));
                setFeaturedRoomList(FEATURED_ROOMS.filter((r) => r.isFollowing));
                setCommunityList(COMMUNITY_ROOMS.filter((r) => r.isFollowing));
                break;

            case 'FAVORITES':
                setFieldRoomList(FIELDS_ROOM.filter((r) => r.isFav));
                setFeaturedRoomList(FEATURED_ROOMS.filter((r) => r.isFav));
                setCommunityList(COMMUNITY_ROOMS.filter((r) => r.isFav));
                break;
            default:
                break;
        }
    }, [roomType]);

    const roomClickHandler = (roomId, roomDetails) => {
        navigate(URL_MEDITATION_ROOM_DETAILS + '/' + roomId, { state: { roomDetails } });
    };

    useEffect(() => {
        fetchWellnessCategory();
    }, [fetchWellnessCategory]);

    return (
        <RoomFilterWrapper>
            <Box className="top-filter-container">
                <Box className="topic-filter-container">
                    <Box className="common-text-container">
                        <Typography className="filter-text primary">Filter by Category</Typography>
                        <Typography className="select-text">choose one</Typography>
                    </Box>

                    <Box className="category-list">
                        <TaoScrollbar keyId="category-list" horizontalScroll>
                            {categoryLoading &&
                                [1, 2, 3, 4, 5, 6]?.map((value, index) => (
                                    <Box key={index} className="wellness-list-loader-wrapper">
                                        <Box className="loader-circle flex f-h-center">
                                            <WellnessCategoryLoader
                                                width={70}
                                                height={70}
                                                fillWidth={70}
                                                fillHeight={70}
                                                customStyle={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            />
                                        </Box>
                                        <TitleLoader
                                            width={40}
                                            height={10}
                                            fillWidth={40}
                                            fillHeight={10}
                                            marginLeft={0}
                                        />
                                    </Box>
                                ))}
                            {!categoryLoading &&
                                wellnessCategoryList.map((category) => (
                                    <Box
                                        className="icon-container"
                                        onClick={() => setSelectedCategory(category)}
                                        key={category?.categoryId}>
                                        <Container
                                            className="category-icon"
                                            style={{
                                                backgroundImage: `url(${
                                                    category?.categoryId ===
                                                    selectedCategory?.categoryId
                                                        ? category.selectedIcon
                                                        : category?.icon
                                                })`
                                            }}
                                        />
                                        <Container className="text-container" disableGutters>
                                            <Typography className="category-name">
                                                {category?.categoryName}
                                            </Typography>
                                        </Container>
                                    </Box>
                                ))}
                        </TaoScrollbar>
                    </Box>
                </Box>
                <Box className="filter-sort-container">
                    <Box className="filter-container">
                        <Box className="common-text-container">
                            <Typography className="filter-text">Filter by Language</Typography>
                        </Box>
                        <SortFilter
                            sortBy={filterByLanguage}
                            sortObject={FILTER_BY_LANGUAGE}
                            onClick={(item) => setFilterByLanguage(item)}
                            customSize={160}
                        />
                    </Box>
                    <Box className="filter-container sorter">
                        <Box className="common-text-container">
                            <Typography className="filter-text">Sort By</Typography>
                        </Box>
                        <SortFilter
                            sortBy={sortBy}
                            sortObject={SORT_BY_VALUE}
                            onClick={(item) => setSortBy(item)}
                        />
                    </Box>
                </Box>
            </Box>
            <Box className="container">
                <Box className="title-container">
                    <TaoLogoIcon className="logo-img" alt="logo" />
                    <Typography className="title-text">Oneness Fields</Typography>
                    <Typography className="match-text">{filedRoomList.length} matches</Typography>
                </Box>
                <TaoSlider ref={fieldRoomSliderRef} gap={10} cardHeight={275}>
                    {filedRoomList.map((r, index) => (
                        <TaoRoomCard
                            key={index}
                            favIconVisible
                            onClick={() => roomClickHandler(index, r)}
                            {...r}
                        />
                    ))}
                </TaoSlider>
            </Box>

            <Box className="container">
                <Box className="title-container">
                    <TaoLogoIcon className="logo-img" alt="logo" />
                    <Typography className="title-text">My Wellness Groups</Typography>
                    <Typography className="match-text">
                        {featuredRoomList.length} matches
                    </Typography>
                </Box>
                <TaoSlider ref={wellnessGroupRef} gap={10} cardHeight={275}>
                    {featuredRoomList.map((r, index) => (
                        <TaoRoomCard
                            key={index}
                            favIconVisible
                            onClick={() => roomClickHandler(index, r)}
                            {...r}
                        />
                    ))}
                </TaoSlider>
            </Box>

            <Box className="container">
                <Box className="title-container">
                    <Typography className="title-text">Community Rooms</Typography>
                    <Typography className="match-text">
                        {communityRoomList.length} matches
                    </Typography>
                </Box>
                <Box className="community-rooms-list">
                    {communityRoomList.map((r, index) => (
                        <React.Fragment key={index}>
                            <TaoRoomCard
                                favIconVisible
                                onClick={() => roomClickHandler(index, r)}
                                {...r}
                            />
                        </React.Fragment>
                    ))}
                </Box>
            </Box>
        </RoomFilterWrapper>
    );
};

export default RoomFilter;

const FIELDS_ROOM = [
    {
        title: 'Global Wellness Center',
        ratingCount: 2010,
        followerCount: 10,
        roomImage: FieldRoom1Image,
        cardRootClass: 'tao-room-card',
        isLive: true,
        isFav: true,
        isTCF: true,
        bgOriginalImage: FieldRoom1BgOriginal,
        hosts: [
            { img: Teacher1Img, name: 'DR. & MASTER SHA' },
            { img: Teacher2Img, name: 'francisco quintero' },
            { img: Teacher3Img, name: 'DAVID LUSCH' }
        ],
        roomType: ROOM_TYPES.TAO_CALLIGRAPHY_FIELD
    },
    {
        title: 'Physical Wellness Center',
        ratingCount: 4000,
        followerCount: 10,
        roomImage: FieldRoom2Image,
        cardRootClass: 'tao-room-card',
        isFollowing: true,
        isTCF: true,
        bgOriginalImage: FieldRoom2BgOriginal,
        roomType: ROOM_TYPES.TAO_CALLIGRAPHY_FIELD,
        hosts: [
            { img: Teacher1Img, name: 'DR. & MASTER SHA' },
            { img: Teacher2Img, name: 'francisco quintero' }
        ]
    }
];

const FEATURED_ROOMS = [
    {
        title: '5 Element Balance with Tao Calligraphy session',
        ratingCount: 2500,
        followerCount: 10,
        roomImage: FeaturedRoom1Image,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        bgOriginalImage: FieldRoom2BgOriginal,
        host: FeaturedRoom1Image,
        roomType: ROOM_TYPES.FEATURED_ROOM,
        hostName: 'Sunil'
    },
    {
        title: '5 Element Balance with Tao Calligraphy',
        ratingCount: 4500,
        followerCount: 10,
        roomImage: FeaturedRoom1Image,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        isFollowing: true,
        isFav: true,
        bgOriginalImage: FieldRoom2BgOriginal,
        host: FeaturedRoom1Image,
        roomType: ROOM_TYPES.FEATURED_ROOM,
        hostName: 'Sunil'
    }
];

const COMMUNITY_ROOMS = [
    {
        title: '5 Element Balance with Tao Calligraphy session',
        ratingCount: 2500,
        followerCount: 10,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        isFav: true,
        isFollowing: true,
        roomType: ROOM_TYPES.COMMUNITY_ROOM,
        host: CommunityRoomImag,
        hostName: 'Sunil'
    },
    {
        title: '5 Element Balance with Tao Calligraphy',
        ratingCount: 4500,
        followerCount: 10,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        roomType: ROOM_TYPES.COMMUNITY_ROOM,
        host: CommunityRoomImag,
        hostName: 'Sunil'
    },
    {
        title: '5 Element Balance with Tao Calligraphy session',
        ratingCount: 2500,
        followerCount: 10,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        roomType: ROOM_TYPES.COMMUNITY_ROOM,
        host: CommunityRoomImag,
        hostName: 'Sunil'
    },
    {
        title: '5 Element Balance with Tao Calligraphy',
        ratingCount: 4500,
        followerCount: 10,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        isFav: true,
        isFollowing: true,
        roomType: ROOM_TYPES.COMMUNITY_ROOM,
        host: CommunityRoomImag,
        hostName: 'Sunil'
    },
    {
        title: '5 Element Balance with Tao Calligraphy',
        ratingCount: 4500,
        followerCount: 10,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        roomType: ROOM_TYPES.COMMUNITY_ROOM,
        host: CommunityRoomImag,
        hostName: 'Sunil'
    },
    {
        title: '5 Element Balance with Tao Calligraphy',
        ratingCount: 4500,
        followerCount: 10,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        isFav: true,
        roomType: ROOM_TYPES.COMMUNITY_ROOM,
        host: CommunityRoomImag,
        hostName: 'Sunil'
    },
    {
        title: '5 Element Balance with Tao Calligraphy',
        ratingCount: 4500,
        followerCount: 10,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        isFollowing: true,
        roomType: ROOM_TYPES.COMMUNITY_ROOM,
        host: CommunityRoomImag,
        hostName: 'Sunil'
    },
    {
        title: '5 Element Balance with Tao Calligraphy',
        ratingCount: 4500,
        followerCount: 10,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        roomType: ROOM_TYPES.COMMUNITY_ROOM,
        host: CommunityRoomImag,
        hostName: 'Sunil'
    },
    {
        title: '5 Element Balance with Tao Calligraphy',
        ratingCount: 4500,
        followerCount: 10,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        isFollowing: true,
        roomType: ROOM_TYPES.COMMUNITY_ROOM,
        host: CommunityRoomImag,
        hostName: 'Sunil'
    },
    {
        title: '5 Element Balance with Tao Calligraphy',
        ratingCount: 4500,
        followerCount: 10,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        isFollowing: true,
        roomType: ROOM_TYPES.COMMUNITY_ROOM,
        host: CommunityRoomImag,
        hostName: 'Sunil'
    },
    {
        title: '5 Element Balance with Tao Calligraphy',
        ratingCount: 4500,
        followerCount: 10,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        roomType: ROOM_TYPES.COMMUNITY_ROOM,
        host: CommunityRoomImag,
        hostName: 'Sunil'
    },
    {
        title: '5 Element Balance with Tao Calligraphy',
        ratingCount: 4500,
        followerCount: 10,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        roomType: ROOM_TYPES.COMMUNITY_ROOM,
        host: CommunityRoomImag,
        hostName: 'Sunil'
    },
    {
        title: '5 Element Balance with Tao Calligraphy',
        ratingCount: 4500,
        followerCount: 10,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        roomType: ROOM_TYPES.COMMUNITY_ROOM,
        host: CommunityRoomImag,
        hostName: 'Sunil'
    },
    {
        title: '5 Element Balance with Tao Calligraphy',
        ratingCount: 4500,
        followerCount: 10,
        cardRootClass: 'tao-featured-card',
        hideFollowers: true,
        roomType: ROOM_TYPES.COMMUNITY_ROOM,
        host: CommunityRoomImag,
        hostName: 'Sunil'
    }
];
