import styled from 'styled-components';
import { Box } from '@mui/material';
import { styled as materialStyled } from '@mui/material/styles';
import { responsive, FONTS, COLORS } from 'Styles/Constants';

export const BookAConsultationMainSectionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    /* justify-content: center; */
    align-items: center;

    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    .begin-booking-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        /* width: 510px; */
        width: ${(props) =>
            props.currentProcessState === 'choose-your-coach' || props.currentProcessState === 'schedule' ? '750px' : props.currentProcessState === 'final-review' ? '450px' : '510px'};
        margin-top: 150px;
        margin-bottom: 80px;

        ${responsive.TABLET`
            margin-bottom: ${(props) => (props.currentProcessState === 'what-to-expect' ? '100px' : props.currentProcessState === 'choose-a-topic' ? '0' : '100px')};
        `}

        ${responsive.MOBILE`
            width: 100%;
            padding: 0px 20px;
            margin-top: 80px;
            height: 100%;
            margin-bottom: ${(props) => (props.currentProcessState === 'what-to-expect' ? '30px' : props.currentProcessState === 'choose-a-topic' ? '0' : '180px')};
        `}

        ${responsive.MOBILE_376`
            margin-bottom: ${(props) => (props.currentProcessState === 'what-to-expect' ? '120px' : props.currentProcessState === 'choose-a-topic' ? '0' : '300px')};
        `}

        ${responsive.MOBILE_325`
            margin-bottom: ${(props) => (props.currentProcessState === 'what-to-expect' ? '150px' : props.currentProcessState === 'choose-a-topic' ? '0' : '400px')};
        `}

        .book-a-consultation-header-title {
            display: flex;
            align-items: center;
            gap: 20px;
            width: 100%;

            .back-button {
                margin-top: 5px;
            }

            .book-a-consultation-title {
                font-family: ${FONTS.SECONDARY};
                font-weight: 700;
                font-size: 36px;
                line-height: 40px;
                letter-spacing: -0.02em;
                text-transform: capitalize;
                color: ${COLORS.PRIMARY_DARK};
                width: 100%;

                ${responsive.MOBILE_325`
                    font-weight: 600;
                    font-size: 24px;
                `}
            }
        }

        .globe-image-section {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .profile-image-section {
                display: flex;
                flex-direction: row;
                gap: 18px;
                position: relative;

                .first,
                .fifth {
                    top: 80px;
                }

                .second,
                .fourth {
                    top: 20px;
                }

                .second {
                    right: 6px;
                }

                .fourth {
                    left: 6px;
                }

                .profile-images {
                    position: relative;
                    width: 70px;
                    height: 70px;
                    border: 2px solid ${COLORS.PRIMARY_DARK};
                    border-radius: 40px;
                }
            }

            .globe-image {
            }
        }

        .tag-line {
            width: 100%;
            font-family: ${FONTS.PRIMARY};
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            color: ${COLORS.PRIMARY_DARK};
        }

        .help-you-description {
            font-family: ${FONTS.PRIMARY};
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            text-transform: capitalize;
            color: ${COLORS.PRIMARY_WARM};

            ul li {
                margin: 6px 0px;
            }

            ul li::marker {
                font-size: 10px;
            }
        }

        .begin-button {
            width: 370px;
            height: 50px;
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
            border-radius: 12px;

            ${responsive.PHABLET`
                padding: 16px 16px;
            `}

            ${responsive.MOBILE_376`
                width: 100%;
            `}

            .MuiTypography-root {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
                text-align: right;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: #ffffff;
            }
        }

        /* what to expect section classes */
        .expect-title {
            font-family: ${FONTS.PRIMARY};
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            color: ${COLORS.PRIMARY_WARM};
            margin: 30px 0px 10px 0px;

            ${responsive.TABLET`
                width: 100%;
                text-align: left;
                font-size: 20px;
                margin: 0;
            `}
        }

        .expect-details-section {
            display: flex;
            flex-direction: row;
            width: 200%;
            gap: 10px;
            height: 180px;
            align-items: center;
            margin-bottom: 40px;

            ${responsive.LAPTOP`
                width: 180%;
            `}

            ${responsive.LAPTOP_1200`
                width: 160%;
            `}

            ${responsive.LAPTOP_1050`
                width: 140%;
            `}

            ${responsive.TABLET`
                width: 100%;
                height: 100%;
                flex-direction: column;
                align-items: start;
            `}

            .details-section {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: start;
                gap: 10px;

                .check-circle {
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    align-items: center;
                }

                .expect-details {
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    align-items: start;

                    .expect-details-title {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 27px;
                        text-transform: capitalize;
                        color: ${COLORS.PRIMARY_DARK};
                    }

                    .expect-details-desc {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 28px;
                        color: ${COLORS.PRIMARY_WARM};
                    }
                }
            }
        }

        .choose-your-topic-button {
            width: 370px;
            height: 50px;
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
            border-radius: 12px;
            margin-bottom: 80px;

            ${responsive.TABLET`
                padding: 16px 16px;
            `}

            ${responsive.MOBILE_376`
                width: 100%;
            `}

            .MuiTypography-root {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
                text-align: right;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: #ffffff;
            }
        }
        /* what to expect section classes */

        /* Choose a topic section classes */
        .choose-a-topic-section {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 15px;

            .what-to-discuss {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 22px;
                line-height: 35px;
                text-transform: capitalize;
                color: ${COLORS.MEDIUM_GREY};
            }

            .choose-topic-text {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
                color: ${COLORS.PRIMARY_WARM};
            }

            .wellness-list {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: start;
                flex-wrap: wrap;

                .wellness-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    align-items: center;
                    height: auto;
                    width: 33%;
                    text-align: center;
                    margin-bottom: 30px;

                    .wellness-icon-button {
                        /* height: 126px; */
                        /* width: 126px; */
                        margin-bottom: 10px;
                    }

                    .wellness-category-name {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 20px;
                        text-align: center;
                        color: ${COLORS.MEDIUM_GREY};

                        ${responsive.TABLET`
                            font-size: 16px;
                        `}
                    }

                    .wellness-icon {
                        height: 116px;
                        width: 116px;

                        ${responsive.TABLET`
                            height: 80px;
                            width: 80px;
                        `}
                    }
                }
            }

            @keyframes inAnimation {
                0% {
                    transform: scale(0.1);
                    opacity: 0;
                }
                100% {
                    transform: scale(1);
                }
            }

            @keyframes outAnimation {
                20% {
                    transform: scale(1);
                }
                100% {
                    transform: scale(0);
                    opacity: 0;
                }
            }
        }
        /* Choose a topic section classes */

        /* Choose your coach section classes */
        .choose-your-coach-section {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 80px;

            .choose-your-coach-title {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 22px;
                line-height: 35px;
                text-transform: capitalize;
                color: ${COLORS.MEDIUM_GREY};

                ${responsive.TABLET`
                    font-size: 20px;
                `}
            }

            .filter-options {
                display: flex;
                flex-direction: row;
                gap: 10px;

                ${responsive.TABLET`
                    flex-direction: column;
                `}

                .selector {
                    background-color: #fff;
                    border: 1px solid rgba(132, 132, 132, 0.4);
                    /* backdrop-filter: blur(30px); */
                    border-radius: 8px;
                    display: flex;
                    align-items: center;

                    &:hover {
                        background-color: #fff;
                    }

                    .down-arrow-icon {
                        position: absolute;
                        cursor: pointer;
                        right: 20px;
                    }

                    &.language {
                        width: 100%;
                        height: 45px;
                        background-color: #fff;
                    }

                    &.sort-select {
                        width: 150px;
                        height: 45px;
                    }
                }

                .form-margin {
                    margin-bottom: 15px;
                    flex: 50%;
                }
            }

            .coach-list-section {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 20px;
                height: calc(100vh - 400px);
                padding: 5px;
                overflow: scroll;

                ${responsive.TABLET`
                    flex-direction: column;
                    flex-wrap: nowrap;
                    height: 100%;
                    padding: 0;
                    overflow: unset;
                    margin-bottom: 120px;
                `}

                .coach-details {
                    width: 230px;
                    height: auto;
                    background-color: #ffffff;
                    border: 0px solid ${COLORS.LIGHT_GREY};
                    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.15);
                    border-radius: 10px;
                    cursor: pointer;

                    ${responsive.TABLET`
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                    `}

                    .coach-image {
                        border-radius: 10px 10px 0px 0px;

                        ${responsive.TABLET`
                            width: 170px;
                            height: 182px;
                            border-radius: 10px 0px 0px 10px;
                        `}
                    }

                    .details {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        padding: 15px 10px 0px 20px;

                        ${responsive.TABLET`
                            width: 100%;
                            overflow: hidden;
                        `}

                        .coach-name {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 24px;
                            text-transform: capitalize;
                            color: ${COLORS.PRIMARY_WARM};
                        }

                        .coach-description {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 18px;
                            color: ${COLORS.PRIMARY_WARM};
                        }

                        .specialities {
                            display: flex;
                            flex-direction: row;
                            gap: 5px;
                            overflow-x: hidden;

                            .speciality-chip {
                                /* min-width: 59.42px;
                                width: 59.42px; */
                                height: 23.1px;
                                background-color: ${COLORS.LIGHT_GREY_SECONDARY};
                                border-radius: 12.197px;

                                font-family: ${FONTS.PRIMARY};
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 17px;
                                text-transform: uppercase;
                                color: ${COLORS.TEXT_DARK};
                            }
                        }

                        .location-and-languages {
                            display: flex;
                            flex-direction: row;
                            gap: 5px;
                            flex-wrap: wrap;

                            .MuiTypography-root {
                                font-family: ${FONTS.PRIMARY};
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                color: ${COLORS.MEDIUM_GREY};
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
        /* Choose your coach section classes */

        /* Schedule Consultation section classes */
        .schedule-consultation-section {
            display: flex;
            flex-direction: column;
            width: 100%;

            .schedule-consultation-title {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 22px;
                line-height: 35px;
                text-transform: capitalize;
                color: ${COLORS.MEDIUM_GREY};
            }

            .filter-options {
                display: flex;
                flex-direction: row;
                gap: 20px;

                ${responsive.TABLET`
                    flex-direction: column;
                    gap: 5px;
                    margin-bottom: 20px;
                `}

                .form-margin {
                    margin-bottom: 15px;
                    flex: 50%;

                    .selector {
                        background-color: #fff;
                        border: 1px solid rgba(132, 132, 132, 0.4);
                        /* backdrop-filter: blur(30px); */
                        border-radius: 8px;
                        display: flex;
                        align-items: center;

                        &:hover {
                            background-color: #fff;
                        }

                        .down-arrow-icon {
                            position: absolute;
                            cursor: pointer;
                            right: 20px;
                        }

                        &.language {
                            width: 100%;
                            height: auto;
                            background-color: #fff;
                        }

                        &.sort-select {
                            width: 150px;
                            height: auto;
                        }
                    }
                }

                .text-field {
                    flex: 50%;

                    .MuiInputLabel-root {
                        color: ${COLORS.MEDIUM_GREY};
                    }

                    .MuiFilledInput-root {
                        background-color: #fff;
                        border-bottom: none;

                        &::before {
                            border-bottom: none !important;
                        }
                        &::after {
                            border-bottom: none !important;
                        }

                        .MuiFilledInput-input {
                            border: 1px solid rgba(132, 132, 132, 0.4);
                            border-radius: 8px;
                        }
                    }

                    .MuiFilledInput-underline {
                        border-bottom: 0px;
                    }
                }
            }

            .select-date-text {
                display: flex;
                flex-direction: row;
                flex: 50%;
            }

            .date-selection-section {
                display: flex;
                flex-direction: row;
                /* justify-content: space-between;
                align-items: center; */
                gap: 20px;

                ${responsive.TABLET`
                    flex-direction: column;
                    gap: 5px;
                `}

                .date-time-slots-section {
                    display: flex;
                    flex-direction: column;
                    flex: 50%;
                    gap: 10px;

                    .selected-date-display {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        margin-top: 10px;
                        width: 294px;
                        height: 72px;
                        background-color: #ffffff;
                        border: 1px solid rgba(132, 132, 132, 0.4);
                        border-radius: 12px;

                        ${responsive.TABLET`
                            width: 100%;
                        `}

                        .MuiTypography-root {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 600;
                            font-size: 28px;
                            line-height: 40px;
                            text-transform: uppercase;
                            color: ${COLORS.PRIMARY_DARK};
                        }
                    }

                    .time-slot-and-duration {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;

                        ${responsive.TABLET`
                            flex-direction: column-reverse;
                            gap: 5px;
                        `}

                        .time-slot-section {
                            display: flex;
                            flex-direction: column;
                            flex: 50%;
                            gap: 10px;

                            .time-title {
                                font-family: ${FONTS.PRIMARY};
                                font-weight: 500;
                                font-size: 24px;
                                line-height: 35px;
                                text-transform: capitalize;
                                color: ${COLORS.MEDIUM_GREY};
                            }

                            .time-slot {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;

                                width: 169px;
                                height: 47px;

                                background-color: #ffffff;
                                border: 1px solid rgba(132, 132, 132, 0.4);
                                border-radius: 12px;
                                cursor: pointer;

                                ${responsive.TABLET`
                                    width: 100%;
                                `}

                                .MuiTypography-root {
                                    font-family: ${FONTS.PRIMARY};
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 27px;
                                    text-transform: capitalize;
                                    color: ${COLORS.PRIMARY_WARM};
                                }
                            }

                            .selected {
                                border: 3px solid ${COLORS.PRIMARY_DARK};
                            }
                        }

                        .duration-section {
                            display: flex;
                            flex-direction: column;
                            flex: 50%;

                            .duration-title {
                                font-family: ${FONTS.PRIMARY};
                                font-weight: 500;
                                font-size: 24px;
                                line-height: 35px;
                                text-transform: capitalize;
                                color: ${COLORS.MEDIUM_GREY};
                            }

                            .radio-button-group {
                                ${responsive.TABLET`
                                    display: flex;
                                    flex-direction: row;
                                    gap: 10px;
                                `}

                                .radio-button.Mui-checked span {
                                    border: 2px solid ${COLORS.PRIMARY_DARK};
                                }

                                .MuiFormControlLabel-root .MuiTypography-root {
                                    font-family: ${FONTS.PRIMARY};
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 22px;
                                    color: ${COLORS.PRIMARY_WARM};
                                }
                            }
                        }
                    }
                }
            }

            .review-booking-button-section {
                display: flex;
                flex-direction: row;
                width: 50%;
                margin-top: 40px;

                ${responsive.TABLET`
                    margin-bottom: 100px;
                    width: 100%;
                `}

                .review-your-booking-button.Mui-disabled {
                    background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                    opacity: 0.5;
                }

                .review-your-booking-button {
                    width: 100%;
                    height: 50px;
                    background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                    border-radius: 12px;

                    .MuiTypography-root {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 23px;
                        text-align: right;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                        color: #ffffff;
                    }
                }
            }
        }
        /* Schedule Consultation section classes */

        /* Review Consultation section classes */
        .review-consultation-section {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 20px;

            .review-consultation-title {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 22px;
                line-height: 35px;
                text-transform: capitalize;
                color: ${COLORS.MEDIUM_GREY};
            }

            .review-consultation-details {
                display: flex;
                flex-direction: column;
                gap: 10px;
                background: #ffffff;
                border: 1px solid rgba(132, 132, 132, 0.4);
                border-radius: 12px;
                padding: 20px;

                ${responsive.TABLET`
                    padding: 10px;
                `}

                .consultation-scheduled-with-details {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 40px;

                    ${responsive.TABLET`
                        gap: 10px;
                    `}

                    .consultant-image {
                        width: 120px;
                        height: 120px;
                        border: 2px solid ${COLORS.PRIMARY_DARK};
                        border-radius: 60px;

                        ${responsive.TABLET`
                            width: 100px;
                            height: 100px;
                        `}
                    }

                    .consultant-details {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;

                        .consultant-name {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 600;
                            font-size: 22px;
                            line-height: 27px;
                            text-transform: capitalize;
                            color: ${COLORS.PRIMARY_WARM};

                            ${responsive.TABLET`
                                font-size: 20px;
                            `}
                        }

                        .consultant-date {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 27px;
                            text-transform: capitalize;
                            color: ${COLORS.PRIMARY_WARM};

                            ${responsive.TABLET`
                                font-size: 16px;
                            `}
                        }

                        .consultant-time {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 26px;
                            color: ${COLORS.PRIMARY_WARM};

                            ${responsive.TABLET`
                                font-size: 16px;
                            `}
                        }
                    }
                }

                .consultation-for,
                .consultation-contact-method,
                .consultation-language,
                .consultation-fee {
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 23px;
                    color: ${COLORS.PRIMARY_WARM};
                }
            }

            .terms-checkbox {
                display: flex;
                flex-direction: row;
                align-items: center;

                .MuiTypography-root {
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 28px;
                    color: ${COLORS.PRIMARY_WARM};
                }

                .MuiCheckbox-root.Mui-checked {
                    color: ${COLORS.TEXT_DARK};
                }

                .terms-and-conditions-link {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .review-consultation-action-section {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .add-to-cart-button {
                    width: 100%;
                    height: 50px;
                    border-radius: 12px;

                    .MuiTypography-root {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 23px;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                        color: #ffffff;
                        margin-right: 10px;
                    }
                }

                .start-over-button {
                    width: 100%;
                    height: 50px;
                    background: rgba(255, 255, 255, 0.9);
                    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
                    backdrop-filter: blur(20px);
                    border-radius: 12px;

                    .MuiTypography-root {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 23px;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                        color: ${COLORS.PRIMARY_WARM};
                    }
                }
            }
        }
        /* Review Consultation section classes */
    }

    .choose-your-coach-button {
        width: 370px;
        height: 50px;
        background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
        border-radius: 12px;
        margin-bottom: 80px;

        ${responsive.TABLET`
            padding: 16px 16px;
            margin-bottom: 120px;
        `}

        ${responsive.MOBILE_376`
            width: 90%;
        `}

        .MuiTypography-root {
            font-family: ${FONTS.PRIMARY};
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            text-align: right;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #ffffff;
        }
    }

    .waves-animation {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;

        div {
            ${responsive.LAPTOP_1200`
                width: 80%;
            `}
        }
    }
`;

export const FooterSectionWrapper = styled(Box)`
    footer {
        background-color: #ffffff;
    }
`;

export const SessionDateTimeSectionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    flex: 50%;

    .static-calendar-root {
        /* width: 100%;
        height: 100%; */
        /* background-color: rgb(232 232 232 / 50%); */
        max-height: 390px;
        max-width: 365px;
        /* max-width: 100%; */
        z-index: 25;
        margin: 0;
        border: 1px solid rgba(132, 132, 132, 0.4);
        border-radius: 11px;

        @media only screen and (max-width: 1200px) {
            max-width: 100%;
        }

        @media only screen and (max-width: 870px) {
            max-width: 100%;
        }

        /* @media only screen and (max-width: 800px) {
            display: none;
        } */

        .PrivatePickersFadeTransitionGroup-root {
            font-family: ${FONTS.PRIMARY};
            font-weight: 500;
            text-transform: uppercase;
            width: 100%;
        }

        .PrivatePickersYear-root > button {
            font-family: ${FONTS.PRIMARY};
            font-weight: 500;
        }

        .PrivatePickersSlideTransition-root {
            height: 290px;

            /* ${responsive.MOBILE`
                height: 360px;
            `}

            ${responsive.MOBILE_376`
                height: 300px;
            `} */
        }

        .PrivatePickersFadeTransitionGroup-root.MuiCalendarPicker-viewTransitionContainer {
            height: 350px;

            ${responsive.MOBILE`
                height: 400px;
            `}

            ${responsive.MOBILE_376`
                height: 340px;
            `}
        }

        .PrivatePickersSlideTransition-root > div > div {
            justify-content: space-evenly !important;
            margin: 0;
        }

        .MuiCalendarPicker-viewTransitionContainer > div > div:first-child {
            justify-content: space-evenly;

            & > span {
                margin: 0px 8px;
                font-weight: 500;
                font-size: 12.5312px;
                line-height: 18px;
                text-align: center;
                font-family: ${FONTS.PRIMARY_MEDIUM};
                color: ${COLORS.TEXT_DARK};

                @media only screen and (max-width: 1050px) {
                    margin: 0px -9px;
                }

                @media only screen and (max-width: 870px) {
                    margin: 0px 6px;
                }

                &:nth-child(1) {
                    &::after {
                        content: 'UN';
                    }
                    color: ${(props) => (props.currentWeekDay === 0 ? COLORS.PRIMARY_DARK : '#656565')};
                }

                &:nth-child(2) {
                    &::after {
                        content: 'ON';
                    }
                    color: ${(props) => (props.currentWeekDay === 1 ? COLORS.PRIMARY_DARK : '#656565')};
                }

                &:nth-child(3) {
                    &::after {
                        content: 'UE';
                    }
                    color: ${(props) => (props.currentWeekDay === 2 ? COLORS.PRIMARY_DARK : '#656565')};
                }

                &:nth-child(4) {
                    &::after {
                        content: 'ED';
                    }
                    color: ${(props) => (props.currentWeekDay === 3 ? COLORS.PRIMARY_DARK : '#656565')};
                }

                &:nth-child(5) {
                    &::after {
                        content: 'HU';
                    }
                    color: ${(props) => (props.currentWeekDay === 4 ? COLORS.PRIMARY_DARK : '#656565')};
                }

                &:nth-child(6) {
                    &::after {
                        content: 'RI';
                    }
                    color: ${(props) => (props.currentWeekDay === 5 ? COLORS.PRIMARY_DARK : '#656565')};
                }

                &:nth-child(7) {
                    &::after {
                        content: 'AT';
                    }
                    color: ${(props) => (props.currentWeekDay === 6 ? COLORS.PRIMARY_DARK : '#656565')};
                }
            }
        }
    }

    .Mui-selected {
        background-color: ${COLORS.PRIMARY_DARK} !important;
        color: #ffffff !important;
    }

    .calendar-day-root {
        /* border: 1.5px solid rgb(132 132 132 / 50%); */
        /* margin: 10px 11px; */
        width: 40px;
        height: 40px;
        font-weight: 500;
        font-size: 16px;
        line-height: 31px;
        background-color: transparent;
        font-family: ${FONTS.PRIMARY};
        color: #000000;

        /* @media only screen and (max-width: 1050px) {
            margin: 10px 7px;
        }

        @media only screen and (max-width: 850px) {
            margin: 10px 5px;
        } */

        ${responsive.MOBILE`
            font-size: 18px;
            // margin: 5px 5px;
        `}

        ${responsive.MOBILE_376`
            border: none;
            // margin: 0;
        `}
    }

    .today-date {
        border: 2px solid ${COLORS.PRIMARY} !important;
        color: ${COLORS.PRIMARY};
        background-color: #ffffff;

        &.dark-gray {
            background-color: #848484;
        }
    }

    div.calendar-day-root {
        border: 0;
    }

    .time-section {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
`;

export const CustomRadioIcon = materialStyled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 25,
    height: 25,
    border: '2px solid #989898',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
}));

export const CustomRadioCheckedIcon = materialStyled(CustomRadioIcon)({
    '&:before': {
        display: 'block',
        width: 25,
        height: 25,
        // backgroundImage: 'radial-gradient(#9C7F3C,#CAA44D 40%,transparent 52%)',
        // backgroundImage: 'radial-gradient(#9C7F3C 20.49%, #CAA44D 84.44%, transparent 52%)',
        backgroundImage: 'radial-gradient(#9C7F3C 45%, #CAA44D 48%, transparent 52%)',
        content: '""',
        position: 'absolute',
        left: '9px',
        top: '9px',
    },
});
