import { Box } from '@mui/material';
import { COLORS, FONTS } from 'Styles/Constants';
import styled from 'styled-components';
import CrossIcon from 'Assets/images/PlayList/cross-icon.svg';

export const ReferralHistoryWrapper = styled(Box)`
    height: 100%;
    .referral-container {
        padding: 0 20px;
        .title {
            font-size: 40px;
            font-family: ${FONTS.SECONDARY_BOLD};
            color: ${COLORS.PRIMARY_DARK};
        }

        .close-icon {
            width: 20px;
            height: 20px;
            background-image: url(${CrossIcon});
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .date-pickers {
        margin: 10px 20px;
        gap: 20px;

        .picker-width {
            width: 50%;
        }

        .picker {
            border-radius: 8px;
        }
    }

    .calendar-loading-icon {
        padding: 5px;
        background: linear-gradient(135deg, #cacaca 0%, #a3a3a3 100%);
        border-radius: 8px;
        margin: 0 10px 0 0;
        min-width: 60px;
        min-height: 60px;
    }

    .invite-friend {
        width: calc(100% - 40px);
        margin: 10px 0 0 20px;
        /* background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%); */
        height: 60px;
        border-radius: 12px;
        font-size: 16px;
        font-family: ${FONTS.PRIMARY_BOLD};
    }

    .invite-friend-btn-icon {
        height: 25px;
        width: 25px;
    }
`;
