import React from 'react';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ProgramEventWrapper } from './Programs.style';
import { ReactComponent as PlayButtonIcon } from 'Assets/images/Common/play-button.svg';
import { ReactComponent as TickIcon } from 'Assets/images/Programs/tick.svg';
import moment from 'moment';
import DOMPurify from 'dompurify';
import {
    convertHoursMinsSecondsToReadableString,
    handleContentRedirection,
    programTimeFormat
} from 'Helpers';
import { CONTENT } from 'Helpers/Constants';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSubscriptionModal } from 'Redux/App/Actions';
import { useSelector } from 'react-redux';
import { SubscriptionLightLock } from 'Components/common/SubscriptionLock';

const ProgramEvent = ({ data, ...props }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.Auth.userInfo);
    const navigateTo = (url, value = {}, isAccessible) => {
        if (!url) return;

        if (!isAccessible) {
            dispatch(showSubscriptionModal());
            return;
        }

        navigate(url, {
            state: {
                contentId: data?.contentId,
                programContentId: data?.programContentId
            }
        });
    };

    const onContentClick = () => {
        handleContentRedirection({ ...data, type: CONTENT }, navigateTo);
    };

    return (
        <ProgramEventWrapper {...props}>
            <Box className="image-section">
                {/* {data?.contentAvailabilityTime && (
                    <Typography className="time-text">
                        {userInfo?.is12HourFormat
                            ? programTimeFormat(
                                  moment(data?.contentAvailabilityTime, 'HH:mm:ss').format(
                                      'h:mm a'
                                  ),
                                  moment(data?.contentAvailabilityTime, 'HH:mm:ss').format('h:mm')
                              )
                            : moment(data?.contentAvailabilityTime, 'HH:mm:ss').format('H:mm')}
                    </Typography>
                )} */}

                <Box
                    className="image-box flex f-v-center f-h-center"
                    sx={{ backgroundImage: `url(${data?.thumbnailImage})` }}>
                    {!data?.subscription?.isAccessible && (
                        <Box className="unlock-container badge text-uppercase">
                            <SubscriptionLightLock />
                        </Box>
                    )}
                    {data?.subscription?.isAccessible &&
                        data?.isPaidContent &&
                        !data?.isPurchased && (
                            <Box className="unlock-container get text-uppercase">
                                <Typography className="unlock-text">{'GET'}</Typography>
                            </Box>
                        )}
                    <Button onClick={() => onContentClick()}>
                        {data?.isPlayed ? <TickIcon /> : <PlayButtonIcon />}
                    </Button>
                </Box>
            </Box>

            <Box className="text-section">
                <Typography className="event-title elipsis">{data?.contentTitle}</Typography>
                <Box>
                    <Typography
                        className="description"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(data?.contentDescription)
                        }}
                    />
                    <Typography className="more hover" onClick={() => onContentClick()}>
                        {'More >>'}
                    </Typography>
                </Box>
                <Typography className="mins">
                    {convertHoursMinsSecondsToReadableString(data?.contentDuration)}
                </Typography>
            </Box>
        </ProgramEventWrapper>
    );
};

export default ProgramEvent;
