import { Button, Typography, Box } from '@mui/material';
import React from 'react';
import { DeleteConfirmationWrapper } from './index.style';

export const DeleteConfirmation = ({ onClose, onConfirm, title, subTitle, ...props }) => {
    return (
        <DeleteConfirmationWrapper open onClose={onClose} classes={{ paper: 'root' }}>
            <Box className="flex f-column">
                <Typography className="text">{title}</Typography>
                {subTitle && <Typography className="text">{subTitle}</Typography>}
            </Box>
            <Box className="flex">
                <Button className="btn positive" onClick={() => onConfirm()}>
                    Yes
                </Button>
                <Button className="btn negative" onClick={() => onClose()}>
                    No
                </Button>
            </Box>
        </DeleteConfirmationWrapper>
    );
};
