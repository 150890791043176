import styled from 'styled-components';
import { Box } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import BottomPageBackgroundImage from 'Assets/images/HomePage/home-page-bottom-background-image.webp';

export const ActivityAndWisdomWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
        url(${BottomPageBackgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
    width: 100%;
    position: relative;

    ${responsive.TABLET`
        background-position: bottom;
        background-size: contain;
    `}

    .activity-parent {
        margin: 30px auto 50px;
        width: calc(0.8 * 45%);

        ${responsive.DESKTOP`
            width: calc(0.8 * 60%);
        `}

        ${responsive.LAPTOP`
            width: 60%;
        `}

        ${responsive.TABLET`
            width: 85%;
            flex-direction: column-reverse;
            align-items: center;
        `}

        ${responsive.PHABLET`
            width: 100%;
            padding: 0 0 0 10px;
        `}
    }

    .daily-activity-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: rgba(255, 255, 255, 0.1);
        border: 2px solid ${COLORS.WHITE};
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(40px);
        border-radius: 30px 15px;
        padding: 20px;
        height: 380px;
        width: 60%;
        position: relative;

        ${responsive.TABLET`
            background-color: transparent;
            border: none;
            box-shadow: none;
            backdrop-filter: none;
            border-radius: none;
            width: 100%;
            height: 100%;
            padding: 20px 0;
            gap: 10px;
        `}

        ${responsive.PHABLET`
            width: 100%;
        `}

        .daily-activity-details {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 30px 0;

            ${responsive.TABLET`
                flex-direction: column;
                justify-content: center;
                align-items: center;
            `}

            .daily-progress {
                width: 230px;
                height: 230px;

                ${responsive.TABLET`
                    width: 270px;
                    height: 270px;
                `}

                .CircularProgressbar {
                    .CircularProgressbar-trail {
                        strokewidth: 1px;
                    }

                    .CircularProgressbar-path {
                        stroke: #ffd26d;
                        background: conic-gradient(
                            from 0deg at 50% 50%,
                            #fff6e1 360deg,
                            #ffd26d 258.14deg,
                            #fff6e1 0deg
                        );
                    }
                }

                .meditation-progress-inner-details {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .current-week-day {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 400;
                        font-size: 14px;
                        color: ${COLORS.TEXT_DARK};
                        letter-spacing: 5.5px;
                        text-transform: uppercase;
                    }
                    .total-meditation-time {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 500;
                        font-size: 54px;
                        color: ${COLORS.PRIMARY_DARK};
                    }
                    .goal-in-percentage {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 400;
                        font-size: 14px;
                        color: ${COLORS.TEXT_DARK};
                        letter-spacing: 3px;
                    }
                }
            }

            .total-meditations-live-sessions {
                display: flex;
                flex-direction: column;

                ${responsive.TABLET`
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    
                    // COMMENT FOR PHASE 1
                    // width: 80%;
                    // COMMENT FOR PHASE 1
                `}

                .total-meditations,
                    .total-live-sessions {
                    text-align: center;
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 400;
                    font-size: 60px;
                    color: ${COLORS.PRIMARY_DARK};
                }

                .meditation-text,
                .live-session-text {
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 400;
                    font-size: 14px;
                    color: ${COLORS.TEXT_DARK};
                    letter-spacing: 2px;
                    text-align: center;
                }
            }
        }

        .daily-activity {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;

            ${responsive.TABLET`
                justify-content: space-between;
            `}

            .daily-activity-text {
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                font-weight: 600;
                font-size: 28px;
                margin: 0 10px 0 0;

                ${responsive.TABLET`
                    font-size: 24px;
                `}
            }
            .daily-activity-see-all {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 16px;
                margin-left: 20px;
                color: #848484;
                margin-top: 5px;
                cursor: pointer;
            }
        }
    }

    .wisdom-slider {
        width: 305px;
        margin-left: 10px;

        ${responsive.TABLET`
                width: 400px;
        `}

        ${responsive.MOBILE`
                width: 300px;
                margin-left: 0px;
        `}

        .wisdom-section {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 365px;
            width: 245px;
            border-radius: 12px;
            margin-inline: 4px;
            margin-bottom: 2px;

            ${responsive.TABLET`
                margin-inline: 5px;
            `};

            ${responsive.MOBILE`
                width: 250px;
                height: 400px;
                border-radius: 16px;
            `};

            .card-media {
                margin-bottom: 12px;
                height: 145px;
                background-color: #f6f6f6;

                .default-image {
                    height: 50%;
                    width: 50%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }

                ${responsive.MOBILE`
                    height: 150px;
                `};

                ${responsive.MOBILE_376`
                    height: 150px;
                `};
            }

            .card-content {
                display: flex;
                align-items: center;
                height: 175px;
                flex-direction: column;
                padding-block: 0;
                margin-bottom: 15px;

                ${responsive.MOBILE`
                    height: 215px;
                    margin-bottom:0;
                `};

                .quote-date {
                    font-family: ${FONTS.PRIMARY};
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0.7px;
                    text-align: center;
                    margin-bottom: 14px;
                    color: ${COLORS.MEDIUM_GREY};

                    ${responsive.MOBILE`
                        font-size: 14px;
                        margin-bottom:18px;
                        line-height: 18px;
                        letter-spacing: 1px;
                    `};

                    ${responsive.MOBILE_376`
                        font-size: 12px;
                        line-height: 14px;
                        margin-bottom: 14px;
                    `};
                }

                .wisdom-quote {
                    color: ${COLORS.TEXT_DARK};
                    font-family: ${FONTS.QUINARY};
                    margin-bottom: 16px;
                    max-height: 240px;
                    font-size: 17px;
                    font-style: italic;
                    font-weight: 400;
                    line-height: 26px;
                    letter-spacing: 0px;
                    text-align: center;
                    white-space: pre-wrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                }

                .quote-by {
                    font-family: ${FONTS.PRIMARY};
                    color: ${COLORS.MEDIUM_GREY};
                    font-size: 13px;
                    margin-bottom: 16px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0px;
                    text-align: center;

                    ${responsive.MOBILE`
                        font-size: 14px;
                        line-height: 14px;
                    `};

                    ${responsive.MOBILE_376`
                        font-size: 16px;
                        line-height: 16px;
                    `};
                }
            }

            .card-action {
                display: flex;
                justify-content: flex-end;
                padding: 0 15px;

                .card-action-button {
                    font-family: ${FONTS.PRIMARY};
                    font-size: 9px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0em;
                    color: ${COLORS.MEDIUM_GREY};

                    ${responsive.MOBILE`
                        font-size: 12px;
                        line-height: 18px;
                    `};

                    ${responsive.MOBILE_376`
                        font-size: 10px;
                    `};

                    .button-text {
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }
        }

        .box-loader {
            height: 365px;
            width: 245px;
            border-radius: 12px;
            margin-inline: 4px;
            margin-bottom: 2px;
            overflow: hidden;

            ${responsive.MOBILE`
                width: 250px;
                height: 400px;
                border-radius: 16px;
            `};
        }
    }

    .wisdom-title {
        display: none;

        ${responsive.TABLET`
                display:block;
                width: 100%;
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                font-size: 24px;
                text-align: start;
                margin-bottom: 26px;
            `};
    }
`;
