import styled from 'styled-components';
import { COLORS } from 'Styles/Constants';

export const TextFieldWrapper = styled.div`
    .text-field {
        width: 100%;
        .MuiInputLabel-root {
            color: ${COLORS.MEDIUM_GREY};
            width: 100%;
        }

        .MuiFilledInput-root {
            background-color: #fff;
            border-bottom: none;

            &::before {
                border-bottom: none !important;
            }
            &::after {
                border-bottom: none !important;
            }

            .MuiFilledInput-input {
                border: 1px solid rgba(132, 132, 132, 0.4);
                border-radius: 8px;
            }
        }

        .MuiFilledInput-underline {
            border-bottom: 0px;
        }
    }
`;
