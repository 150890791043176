import React from 'react';
import { Box, Typography } from '@mui/material';

import VideoIcon from 'Assets/images/Common/video-icon.svg';
import AudioIcon from 'Assets/images/Common/audio-icon.svg';
import MusicIcon from 'Assets/images/Common/music-icon.svg';
import PlayButton from 'Assets/images/Common/play-button.svg';
import HeartIcon from 'Assets/images/Reward/golden-heart.svg';
// import LockIcon from 'Assets/images/Common/lock.svg';
import FavoriteIconAnimation from '../FavoriteIconAnimation';

import { TaoMeditationCardWrapper } from './TaoMeditationCard.style';
import { MEDIA_TYPE } from 'Helpers/Constants';
import { convertHrsMinToMins } from 'Helpers';
import DOMPurify from 'dompurify';
import { SubscriptionLightLock } from '../SubscriptionLock';

const SHOW_PLAY_ICON = ['video', 'Video', 'Music', 'Guided_Meditation_Audio'];
// const SHOW_PLAY_LIST_ICON = ['playlist', 'PROGRAM_PLAYLIST', 'PAID_PROGRAM_PLAYLIST', 'program'];

const TaoMeditationCard = ({
    key = '',
    contentImage = '',
    contentName = '',
    videoIconToShow = false,
    type = '',
    contentDuration = '',
    price = '',
    heartsToOwn = false,
    description = '',
    isDangerouslySetInnerHTML = false,
    favoriteIconVisible = false,
    isFavorite = false,
    currencyDetails = null,
    heartDetails = null,
    isPaidContent = false,
    isPurchased = false,
    isAccessible = false,
    badge = '',
    ...props
}) => {
    let favIconVisible =
        (favoriteIconVisible && isAccessible && !isPaidContent) ||
        (favoriteIconVisible && isAccessible && isPaidContent && isPurchased);

    let actionButtonVisible =
        (isAccessible && !isPaidContent) || (isAccessible && isPaidContent && isPurchased);

    const getTypeTitle = (type) => {
        if (!type) return '';
        if (type === MEDIA_TYPE?.VIDEO) return 'Video';
        if (type === MEDIA_TYPE?.TEXT) return 'Text';
        if (type === MEDIA_TYPE?.MUSIC) return 'Music';
        if (type === MEDIA_TYPE?.AUDIO) return 'Audio';
        if (type === MEDIA_TYPE?.GUIDED_AUDIO) return 'Guided';
    };

    const detailsLineClampClass = () => {
        if (contentName?.length < 19) {
            if (
                currencyDetails?.symbol &&
                currencyDetails?.price &&
                heartDetails?.requiredSilverHearts
            ) {
                return 'three-line-clamp';
            }
        } else if (contentName?.length > 19) {
            if (
                currencyDetails?.symbol &&
                currencyDetails?.price &&
                heartDetails?.requiredSilverHearts
            ) {
                return 'two-line-clamp';
            }
        } else {
            return;
        }
    };

    return (
        <TaoMeditationCardWrapper>
            <Box
                className="meditation-content-container hover"
                key={key}
                onClick={props?.onCardClick}>
                <Box className="content-image-container">
                    <img className="content-image" src={contentImage} alt="" />
                </Box>
                {actionButtonVisible && SHOW_PLAY_ICON.includes(type) && (
                    <img src={PlayButton} alt="" className="play-icon" />
                )}

                <Box className="meditation-content-description-details">
                    <Typography className="meditation-content-title">{contentName}</Typography>

                    <Box className="meditation-content-details">
                        {type === MEDIA_TYPE?.VIDEO && <img src={VideoIcon} alt="" />}
                        {type === MEDIA_TYPE?.MUSIC && <img src={MusicIcon} alt="" />}
                        {type === MEDIA_TYPE?.AUDIO && <img src={AudioIcon} alt="" />}
                        {type === MEDIA_TYPE?.GUIDED_AUDIO && <img src={AudioIcon} alt="" />}

                        <Typography className="type-and-time-description">
                            {type && getTypeTitle(type)} /{' '}
                            {`${convertHrsMinToMins(contentDuration)} mins`}
                        </Typography>
                    </Box>

                    {currencyDetails?.symbol &&
                        currencyDetails?.price &&
                        heartDetails?.requiredSilverHearts && (
                            <Box className="purchase-container">
                                <Box className="purchase-text-container">
                                    <Typography className="purchase-text">
                                        {currencyDetails?.symbol} {currencyDetails?.price}
                                    </Typography>
                                </Box>
                                <Box className="purchase-by-heart-text-container">
                                    <Typography className="purchase-by-heart-text">or</Typography>
                                    <Typography className="purchase-by-heart-text">
                                        {heartDetails?.requiredSilverHearts}
                                    </Typography>
                                    <Box className="heart-image">
                                        <img src={HeartIcon} alt="" />
                                    </Box>
                                </Box>
                            </Box>
                        )}

                    {!isDangerouslySetInnerHTML && description && (
                        <Box className="meditation-content-description-container">
                            <Typography
                                className={`meditation-content-description ${detailsLineClampClass()}`}>
                                {description}
                            </Typography>
                        </Box>
                    )}
                    {isDangerouslySetInnerHTML && description && (
                        <Box className="meditation-content-description-container">
                            <Typography
                                variant="body1"
                                className={`meditation-content-description ${detailsLineClampClass()}`}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(description, {
                                        ALLOWED_TAGS: ['p', 'strong', 'b']
                                    })
                                }}
                            />
                        </Box>
                    )}
                </Box>

                {!isAccessible && badge && (
                    <Box className="unlock-container badge text-uppercase">
                        <SubscriptionLightLock />
                    </Box>
                )}
                {isAccessible && isPaidContent && !isPurchased && (
                    <Box className="unlock-container get text-uppercase">
                        <Typography className="unlock-text">{'GET'}</Typography>
                    </Box>
                )}

                {favIconVisible && (
                    <Box
                        className="favorite-heart-image-container"
                        onClick={(e) => {
                            e.stopPropagation();
                            props?.onFavClick();
                        }}>
                        <FavoriteIconAnimation isWhiteIcon isFavorite={props?.isFav} />
                    </Box>
                )}

                {/* {isLocked && !isSubscribed && !unlockDays && (
                    <Box className="lock-content-container">
                        <img src={LockIcon} alt="" className="lock-icon" />
                    </Box>
                )} */}
            </Box>
        </TaoMeditationCardWrapper>
    );
};

export default TaoMeditationCard;
