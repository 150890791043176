import styled from 'styled-components';
import { Box } from '@mui/material';

import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const WeekCalendarWrapper = styled(Box)`
    height: 66px;
    justify-content: start;

    ${responsive.PHABLET`
        height: 50px;
        padding: 0;
    `}

    .date-container {
        min-width: max-content;
        margin: 0 15px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        .name {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #656565;
            text-transform: uppercase;

            &.joined {
                color: ${COLORS.PRIMARY};
            }
        }

        .number {
            min-width: 60px;
            min-height: 60px;
            border: 2px solid transparent;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 36px;
            font-family: ${FONTS.PRIMARY_MEDIUM};
            line-height: 35px;

            &.pastYes-todayNo-selectedYes {
                color: ${COLORS.MEDIUM_GREY};
                background-color: ${COLORS.LIGHT_GREY};
                border-color: ${COLORS.PRIMARY_DARK};
                opacity: 0.4;
            }
            &.pastYes-todayNo-selectedNo {
                color: ${COLORS.MEDIUM_GREY};
                background-color: ${COLORS.LIGHT_GREY};
                opacity: 0.4;
            }
            &.pastNo-todayNo-selectedYes {
                color: ${COLORS.PRIMARY_DARK};
                background-color: ${COLORS.LIGHT_GREY};
                border-color: ${COLORS.PRIMARY_DARK};
            }
            &.pastNo-todayYes-selectedYes {
                color: ${COLORS.WHITE};
                background-color: ${COLORS.PRIMARY};
            }
            &.pastNo-todayYes-selectedNo {
                color: ${COLORS.WHITE};
                background-color: ${COLORS.MEDIUM_GREY};
            }
            &.pastNo-todayNo-selectedNo {
                color: ${COLORS.MEDIUM_GREY};
                background-color: ${COLORS.LIGHT_GREY};
            }

            &.joined {
                background-color: ${COLORS.PRIMARY};
                color: ${COLORS.WHITE};
                border: 2px solid ${COLORS.PRIMARY};
            }
            &.notJoined {
                background-color: ${COLORS?.LIGHT_GREY};
            }
        }

        .dot {
            margin-top: 8px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            &.light-gray {
                background-color: #e8e8e8;
                display: flex;
            }

            &.dark-gray {
                background-color: #848484;
                display: flex;
            }

            &.today {
                background-color: ${COLORS.PRIMARY};
                display: flex;
            }
        }

        .program-date {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 27px;
            text-align: center;
            color: ${COLORS.MEDIUM_GREY};
            width: max-content;
            text-transform: uppercase;
            margin: 3px 0;
        }
    }
`;
