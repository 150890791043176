import React, { useMemo } from 'react';

import { Backdrop, Box, Typography, Slide } from '@mui/material';

import { ReactComponent as LockIcon } from "Assets/images/MeditationRooms/lock.svg";
import { ReactComponent as SilverHeartIcon } from "Assets/images/Reward/silver-heart-icon.svg";
import { ReactComponent as UnlockIcon } from "Assets/images/Common/unlock-icon.svg";

import { UnlockModalWrapper } from './UnlockModal.style';

import { TaoButton } from '../Button.style';

const UnlockModal = ({ open = false, onClose = () => { }, RewardIcon = SilverHeartIcon, rewardCount = 5, ...props }) => {

    const rewardList = useMemo(() => {
        const rewards = [];
        for (let i = 0; i < rewardCount; i++) {
            rewards.push(
                <RewardIcon className="reward-icon" />
            )
        }
        return rewards
    }, [rewardCount])

    return (
        <UnlockModalWrapper
            open={open}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 250,
            }}
            onClose={onClose}
            closeAfterTransition
        >
            <Slide timeout={250} in={open} direction="up">
                <Box className="dialog-container">
                    <Box className="content-container">
                        <Box className="title-container">
                            <LockIcon className="lock-icon" />
                            <Typography className="unlock">UNLOCK NEW BACKGROUND?</Typography>
                            <Typography className="heart-text">Use {rewardCount} Silver Hearts</Typography>
                            <Box className="heart-list">
                                {rewardList}
                            </Box>
                        </Box>
                        <Box className="btn-container">
                            <TaoButton className="unlock-btn"><UnlockIcon /> unlock</TaoButton>
                        </Box>
                    </Box>
                </Box>
            </Slide>
        </UnlockModalWrapper>
    );
};

export default UnlockModal;