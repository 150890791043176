import { Typography } from '@mui/material';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import DOMPurify from 'dompurify';
import Api from 'Helpers/ApiHandler';
import { POLICIES_TYPES } from 'Helpers/Constants';
import { API_URL } from 'Helpers/Paths';
import React, { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { PolicyWrapper } from './Policies.style';

const CommunityGuidelines = ({ customHeight = null }) => {
    const API = useMemo(() => new Api(), []);
    const [details, setDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const fetchDetails = useCallback(async () => {
        setIsLoading(true);
        let response = await API.get(API_URL.STATIC_PAGES, {
            params: {
                type: POLICIES_TYPES.COMMUNITY_GUIDELINES
            }
        });

        if (response) {
            setIsLoading(false);
            setDetails(response?.data?.data);
        }
    }, [API]);

    useEffect(() => {
        fetchDetails();
    }, [fetchDetails]);

    return (
        <PolicyWrapper customHeight={customHeight}>
            <TaoLoader isLoading={isLoading} />
            <Typography
                className="description"
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(details?.description)
                }}
            />
        </PolicyWrapper>
    );
};

export default CommunityGuidelines;
