import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useMemo, useState } from 'react';
import { NotificationWrapper } from './Notifications.style';
import { ReactComponent as ClearNotificationIcon } from 'Assets/images/Notifications/clear-notification-icon.svg';
import NotificationDetails from './NotificationDetails';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { useEffect } from 'react';
import { useCallback } from 'react';
import TaoScrollbar from 'Components/common/Scrollbar';
import {
    NOTIFICATION_TYPE_FOR_API,
    TYPES_OF_NOTIFICATIONS,
    UPDATE_NOTIFICATION_COUNT
} from 'Helpers/Constants';
import MeditationTimerIcon from 'Assets/images/Header/UserMenu/meditation-timer-icon.svg';
import SubScriptionIcon from 'Assets/images/Header/UserMenu/subscription.svg';
import TaoLogo from 'Assets/Logo/heart-in-circle.svg';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import ClearNotificationModal from './ClearModal';
import { useDispatch } from 'react-redux';
import { updateUserInfo } from 'Redux/Auth/Actions';

const Notifications = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [clearNotificationType, setClearNotificationType] = useState(null);
    const [clearSingleNotification, setClearSingleNotification] = useState({ type: '', id: null });
    const API = useMemo(() => new Api(), []);
    const [pagination] = useState({
        per_page: 10
    });
    const userDetails = useMemo(() => JSON.parse(localStorage.getItem('userInfo')) || {}, []);
    const dispatch = useDispatch();

    const [notificationList, setNotificationList] = useState({
        [TYPES_OF_NOTIFICATIONS.PROGRAMS]: {
            list: [],
            type: NOTIFICATION_TYPE_FOR_API.PROGRAMS,
            current_page: 1,
            totalRecords: 0,
            totalUnReadNotificationCount: 0
        },
        [TYPES_OF_NOTIFICATIONS.MEDITATION]: {
            image: MeditationTimerIcon,
            list: [],
            type: NOTIFICATION_TYPE_FOR_API.MEDITATION,
            current_page: 1,
            totalRecords: 0,
            totalUnReadNotificationCount: 0
        },
        [TYPES_OF_NOTIFICATIONS.DAILY_WISDOM]: {
            image: TaoLogo,
            type: NOTIFICATION_TYPE_FOR_API.DAILY_WISDOM,
            list: [],
            current_page: 1,
            totalRecords: 0,
            totalUnReadNotificationCount: 0
        },
        [TYPES_OF_NOTIFICATIONS.SUBSCRIPTION]: {
            image: SubScriptionIcon,
            type: NOTIFICATION_TYPE_FOR_API.SUBSCRIPTION,
            list: [],
            current_page: 1,
            totalRecords: 0,
            totalUnReadNotificationCount: 0
        }
    });

    const readNotificationList = useCallback(
        async (ids, currentType) => {
            if (!ids?.length) return;
            let response = await API.put(API_URL.NOTIFICATION, {
                data: {
                    notificationId: ids
                }
            });
            if (response) {
                setNotificationList((prev) => ({
                    ...prev,
                    [currentType]: {
                        ...prev[currentType],
                        totalUnReadNotificationCount:
                            response?.data?.data?.[UPDATE_NOTIFICATION_COUNT?.[currentType]]
                    }
                }));

                let count =
                    response?.data?.data?.[UPDATE_NOTIFICATION_COUNT.DAILY_WISDOM] +
                    response?.data?.data?.[UPDATE_NOTIFICATION_COUNT.MEDITATION] +
                    response?.data?.data?.[UPDATE_NOTIFICATION_COUNT.PROGRAMS] +
                    response?.data?.data?.[UPDATE_NOTIFICATION_COUNT.SUBSCRIPTION];
                dispatch(updateUserInfo({ ...userDetails, totalUnReadNotificationCount: count }));
            }
        },
        [API, dispatch, userDetails]
    );

    const fetchNotificationList = useCallback(
        async (notificationType = NOTIFICATION_TYPE_FOR_API.ALL, currentPage = 1) => {
            let ALL_TYPE_NOTIFICATION = NOTIFICATION_TYPE_FOR_API.ALL;
            setIsLoading(true);
            let response = await API.post(API_URL.NOTIFICATION_LIST, {
                data: {
                    current_page: currentPage - 1,
                    per_page: pagination?.per_page,
                    search: '',
                    notificationType
                }
            });

            if (response) {
                const { data } = response?.data;
                if (
                    notificationType === ALL_TYPE_NOTIFICATION ||
                    notificationType === NOTIFICATION_TYPE_FOR_API.DAILY_WISDOM
                ) {
                    updateNotificationData(
                        TYPES_OF_NOTIFICATIONS.DAILY_WISDOM,
                        data?.dailyWisdom,
                        currentPage
                    );
                    if (currentPage !== 1)
                        readNotificationList(
                            data?.dailyWisdom?.list
                                .filter((item) => !item?.notificationRead)
                                .map((list) => list?.id),
                            TYPES_OF_NOTIFICATIONS.DAILY_WISDOM
                        );
                }
                if (
                    notificationType === ALL_TYPE_NOTIFICATION ||
                    notificationType === NOTIFICATION_TYPE_FOR_API.MEDITATION
                ) {
                    updateNotificationData(
                        TYPES_OF_NOTIFICATIONS.MEDITATION,
                        data?.meditation,
                        currentPage
                    );
                    if (currentPage !== 1)
                        readNotificationList(
                            data?.meditation?.list
                                .filter((item) => !item?.notificationRead)
                                .map((list) => list?.id),
                            TYPES_OF_NOTIFICATIONS.MEDITATION
                        );
                }
                if (
                    notificationType === ALL_TYPE_NOTIFICATION ||
                    notificationType === NOTIFICATION_TYPE_FOR_API.PROGRAMS
                ) {
                    updateNotificationData(
                        TYPES_OF_NOTIFICATIONS.PROGRAMS,
                        data?.programs,
                        currentPage
                    );
                    if (currentPage !== 1)
                        readNotificationList(
                            data?.programs?.list
                                .filter((item) => !item?.notificationRead)
                                .map((list) => list?.id),
                            TYPES_OF_NOTIFICATIONS.PROGRAMS
                        );
                }
                if (
                    notificationType === ALL_TYPE_NOTIFICATION ||
                    notificationType === NOTIFICATION_TYPE_FOR_API.SUBSCRIPTION
                ) {
                    updateNotificationData(
                        TYPES_OF_NOTIFICATIONS.SUBSCRIPTION,
                        data?.subscription,
                        currentPage
                    );
                    if (currentPage !== 1)
                        readNotificationList(
                            data?.subscription?.list
                                .filter((item) => !item?.notificationRead)
                                .map((list) => list?.id),
                            TYPES_OF_NOTIFICATIONS.SUBSCRIPTION
                        );
                }
                setIsLoading(false);
            }
        },
        [API, pagination?.per_page, readNotificationList]
    );

    const updateNotificationData = (typeOfNotification, data, currentPage) => {
        setNotificationList((prev) => ({
            ...prev,
            [typeOfNotification]: {
                ...prev[typeOfNotification],
                list: prev[typeOfNotification]?.list?.concat(data?.list || []),
                totalRecords: data?.totalRecords || 0,
                current_page: currentPage,
                totalUnReadNotificationCount: data?.totalUnReadNotificationCount || 0
            }
        }));
    };

    const loadMore = (type) => {
        let currentPageOfType = Object.values(notificationList).find(
            (item) => item?.type === type
        ).current_page;
        fetchNotificationList(type, currentPageOfType + 1);
    };

    const removeNotification = async (type) => {
        let data = {
            notificationId: [],
            notificationType: ''
        };
        let removeIds = [];
        let currentType = Object.keys(notificationList).find(
            (key) => notificationList?.[key].type === type
        );
        if (type === NOTIFICATION_TYPE_FOR_API.ALL)
            data.notificationType = NOTIFICATION_TYPE_FOR_API.ALL;
        else {
            if (clearSingleNotification?.id) {
                removeIds = notificationList?.[currentType]?.list?.map((item) => item?.id);
                removeIds = removeIds.filter((item) => item === clearSingleNotification?.id);
                data.notificationId = removeIds;
            } else {
                data.notificationType = type;
            }
        }

        let response = await API.delete(API_URL.NOTIFICATION, {
            data
        });

        setClearNotificationType(null);
        setClearSingleNotification({ type: '', id: null });

        if (response) {
            setNotificationList((prev) => ({
                ...prev,
                [currentType]: {
                    ...prev[currentType],
                    totalRecords: prev[currentType]?.totalRecords - removeIds?.length,
                    list: !removeIds?.length
                        ? []
                        : prev[currentType].list.filter((item) => !removeIds.includes(item?.id))
                }
            }));

            if (!removeIds?.length) fetchNotificationList();
        }
    };

    const updatedReadNotification = (type) => {
        let currentType = Object.keys(notificationList).find(
            (key) => notificationList[key].type === type
        );

        let list = notificationList?.[currentType]?.list.map((item) => ({
            ...item,
            notificationRead: true
        }));

        setNotificationList((prev) => ({
            ...prev,
            [currentType]: {
                ...prev[currentType],
                list
            }
        }));
    };

    useEffect(() => {
        fetchNotificationList();
    }, [fetchNotificationList]);

    let isNotificationExists = 0;

    Object.values?.(notificationList)?.map(
        (item) => (isNotificationExists = isNotificationExists + item?.totalRecords)
    );

    const notificationInitialItem = (notification) => {
        return notification[0];
    };

    return (
        <>
            <TaoCalligraphyWatermark />
            <TaoLoader isLoading={isLoading} />
            <NotificationWrapper>
                <Box className="container">
                    <Typography className="title">Notifications</Typography>
                    <Box
                        className={`alert-list ${
                            !notificationList.length && 'flex f-h-center f-v-center'
                        }`}>
                        <TaoScrollbar customStyle={{ padding: '0 15px' }} displayScroll>
                            {isNotificationExists ? (
                                Object.values(notificationList).map((listItem, index) => (
                                    <React.Fragment key={index}>
                                        {!!listItem?.list?.length && (
                                            <React.Fragment key={index}>
                                                <NotificationDetails
                                                    index={index}
                                                    remainingList={listItem?.list?.slice(1) || []}
                                                    totalUnReadNotificationCount={
                                                        listItem?.totalUnReadNotificationCount
                                                    }
                                                    type={listItem?.type}
                                                    notificationStateType={Object.keys(
                                                        notificationList
                                                    ).find(
                                                        (key) =>
                                                            notificationList[key].type ===
                                                            listItem?.type
                                                    )}
                                                    totalRecords={listItem?.totalRecords}
                                                    image={
                                                        listItem?.image ||
                                                        (listItem.list?.length &&
                                                            notificationInitialItem(
                                                                listItem.list.slice(0, 1)
                                                            )?.image)
                                                    }
                                                    notification={
                                                        listItem?.list?.length
                                                            ? notificationInitialItem(
                                                                  listItem?.list?.slice(0, 1)
                                                              )
                                                            : {}
                                                    }
                                                    setNotificationList={setNotificationList}
                                                    loadMore={loadMore}
                                                    removeNotification={removeNotification}
                                                    setClearNotificationType={
                                                        setClearNotificationType
                                                    }
                                                    setClearSingleNotification={
                                                        setClearSingleNotification
                                                    }
                                                    updatedReadNotification={
                                                        updatedReadNotification
                                                    }
                                                    readNotificationList={readNotificationList}
                                                />
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ))
                            ) : (
                                <Box className="flex f-v-center f-h-center full-height">
                                    <Typography className="empty-title">
                                        {isLoading
                                            ? 'Notifications loading...'
                                            : 'You have no notifications'}
                                    </Typography>
                                </Box>
                            )}
                        </TaoScrollbar>
                    </Box>
                    {isNotificationExists ? (
                        <div
                            className="flex f-v-center"
                            style={{ justifyContent: 'space-between' }}>
                            <IconButton
                                className="clear_all"
                                onClick={() =>
                                    setClearNotificationType(NOTIFICATION_TYPE_FOR_API.ALL)
                                }>
                                <Typography>CLEAR ALL</Typography>
                                <ClearNotificationIcon />
                            </IconButton>
                        </div>
                    ) : (
                        ''
                    )}
                </Box>
            </NotificationWrapper>

            {(clearNotificationType || clearSingleNotification?.type) && (
                <ClearNotificationModal
                    onOk={removeNotification}
                    onCancel={() => {
                        setClearNotificationType(null);
                        setClearSingleNotification({ type: '', id: null });
                    }}
                    type={clearNotificationType || clearSingleNotification?.type}
                    isSingleNeedToRemove={clearSingleNotification?.id}
                />
            )}
        </>
    );
};

export default Notifications;
