import styled from 'styled-components';
import { Box, Modal } from '@mui/material';
import { responsive, FONTS, COLORS } from 'Styles/Constants';

import AudioSessionBackgroundImage from 'Assets/images/MeditationRoomSession/AudioSession/audio-session-background-image.webp';

export const AudioSessionParticipantViewWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: start;

    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    background-image: url(${AudioSessionBackgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: right;

    ${responsive.TABLET`
        justify-content: start;
    `}

    .left-side-gradient {
        position: absolute;
        width: 908px;
        height: 80vh;
        left: -200px;
        top: 0px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
        transform: rotate(90deg);

        ${responsive.MOBILE_376`
            height: 100vh;
        `}

        ${responsive.MOBILE_325`
            height: 120vh;
        `}
    }

    .bottom-side-gradient {
        position: absolute;
        width: 100%;
        height: calc(100vh - 550px);
        left: 7px;
        top: 564px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);

        ${responsive.TABLET`
            top: 614px;
        `}

        ${responsive.MOBILE_376`
            top: 674px;
        `}

        ${responsive.MOBILE_325`
            top: 740px;
        `}
    }

    .host-and-session-details {
        display: flex;
        flex-direction: column;
        /* width: 480px; */
        gap: 20px;
        margin-left: 150px;
        /* margin-top: 280px; */
        z-index: 100;

        ${responsive.TABLET_800`
            width: 100%;
            margin-left: 0;
            align-items: center;
            margin-top: 50px;
        `}

        ${responsive.MOBILE_376`
            margin-bottom: 50px; 
        `}

        .back-button {
            width: 480px;
            margin-left: 30px;
            margin-bottom: 10px;

            ${responsive.PHABLET_500`
                width: 100%;
            `}
        }

        .session-details-section {
            display: flex;
            flex-direction: row;
            gap: 100px;

            ${responsive.LAPTOP`
                flex-direction: column;
                gap: 30px;
                margin: 0 10px;
            `}

            .host-details {
                display: flex;
                flex-direction: row;
                gap: 30px;
                width: 480px;

                ${responsive.PHABLET_500`
                    width: 100%;
                `}

                .host-image {
                    border: 2px solid ${COLORS.PRIMARY_DARK};
                    border-radius: 24px;
                }

                .session-details {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    ${responsive.PHABLET_500`
                        gap: 0;
                    `}

                    .session-title {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 600;
                        font-size: 36px;
                        line-height: 36px;
                        text-transform: capitalize;
                        color: ${COLORS.PRIMARY_WARM};

                        ${responsive.PHABLET_500`
                            font-size: 20px;
                        `}
                    }

                    .session-by {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: ${COLORS.MEDIUM_GREY};
                    }

                    .session-time {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 35px;
                        text-transform: capitalize;
                        color: ${COLORS.PRIMARY_DARK};

                        ${responsive.PHABLET_500`
                            font-size: 20px;
                        `}

                        .session-date {
                            font-weight: 600;
                            margin-right: 10px;
                        }
                    }

                    .session-language {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 26px;
                        text-transform: capitalize;
                        color: ${COLORS.MEDIUM_GREY};
                    }

                    .joined-user-details {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;

                        background-color: ${COLORS.PRIMARY_DARK};
                        border-radius: 18px;
                        width: 150px;
                        height: 36px;
                        padding: 6px 16px 6px 30px;
                        margin-top: 15px;
                        cursor: pointer;

                        .total-count {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 400;
                            font-size: 15.6316px;
                            line-height: 23px;
                            color: #ffffff;
                            mix-blend-mode: normal;
                            border-right: 2px solid #ffffff;
                            padding-right: 10px;
                        }

                        .view-text {
                            margin-left: 5px;
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 400;
                            font-size: 15.6316px;
                            line-height: 23px;
                            color: #ffffff;
                            mix-blend-mode: normal;
                        }
                    }
                }
            }

            .session-description {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 480px;

                ${responsive.PHABLET_500`
                    width: 100%;
                `}

                .session-breadcrumb {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;

                    .MuiTypography-root,
                    span {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 18px;
                        text-transform: uppercase;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                        color: ${COLORS.PRIMARY_DARK};
                        mix-blend-mode: multiply;
                    }
                }

                .session-description-text {
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: ${COLORS.PRIMARY_WARM};
                }
            }
        }

        .session-timer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 480px;

            background-color: rgba(255, 255, 255, 0.6);
            border-radius: 15px;
            padding: 20px;

            ${responsive.PHABLET_500`
                border-radius: 0;
                width: 100%;
            `}

            .MuiTypography-root {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 24px;
                line-height: 35px;
                color: ${COLORS.TEXT_DARK};
                text-transform: uppercase;
            }
        }

        .join-now-button {
            height: 50px;
            border-radius: 12px;
            font-family: ${FONTS.PRIMARY};
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            text-align: right;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #ffffff;
            width: 480px;

            ${responsive.PHABLET_500`
                width: 90%;
                align-self: center;
            `}
        }

        .join-as-section {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 480px;

            ${responsive.PHABLET_500`
                width: 90%;
                align-self: center;
            `}

            .join-as-actual,
            .join-as-guest {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                border-radius: 14px;
                padding: 14px;
                background-color: #ffffff;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

                .name-section {
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    align-items: start;
                    width: 70%;

                    .join-as-text {
                        font-family: ${FONTS.QUATERNARY};
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 30px;
                        color: ${COLORS.TEXT_DARK};
                    }

                    .profile-to-join {
                        font-family: ${FONTS.QUATERNARY};
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                        color: ${COLORS.TEXT_DARK};
                    }
                }
            }
        }

        .session-waiting-to-start-section {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 480px;

            ${responsive.PHABLET_500`
                width: 90%;
                align-self: center;
            `}

            .MuiButton-root {
                border-radius: 14px;
                padding: 10px 10px 10px 20px;
                background-color: #ffffff;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
            }

            .user-details {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .open-chat-button {
                    flex: 80%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    font-family: ${FONTS.PRIMARY};
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 23px;
                    text-align: center;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    color: ${COLORS.PRIMARY_WARM};
                    /* margin-right: 30px; */
                }

                .close-chat-icon {
                    flex: 10;
                }
            }

            .leave-session-button {
                height: 71px;
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                text-align: center;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: ${COLORS.PRIMARY_WARM};
            }
        }

        .waiting-for-host-text {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            padding: 14px;
            width: 480px;
            height: 71px;

            background-color: #ffffff;
            border-radius: 14px;

            ${responsive.PHABLET_500`
                width: 100%;
                background-color: rgba(255, 255, 255, 0.6);
                border-radius: 0px;
            `}

            .MuiTypography-root {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 22px;
                line-height: 35px;
                text-transform: uppercase;
                color: ${COLORS.BADGE};
            }
        }

        .live-session-details {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 15px;

            background-color: ${COLORS.BADGE};
            border-radius: 12px;
            width: 480px;
            height: 71px;

            ${responsive.PHABLET_500`
                width: 100%;
                border-radius: 0px;
            `}

            .heart-wave-animation {
                width: 50px;
                height: 50px;
            }

            .live-session-text {
                font-family: ${FONTS.PRIMARY};
                font-weight: 700;
                font-size: 24px;
                line-height: 35px;
                color: #ffffff;
            }
        }

        .actions-section {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            gap: 20px;

            ${responsive.PHABLET_500`
                padding: 0px 30px;
            `}

            .action-icon {
                display: flex;
                align-items: center;
                justify-content: center;

                background-color: #ffffff;
                border: 3px solid ${COLORS.MEDIUM_GREY};
                border-radius: 36px;
                width: 62px;
                height: 62px;
            }
        }
    }
`;

export const LeaveChatModalWrapper = styled(Modal)`
    .MuiBackdrop-root {
        background-color: rgba(255, 255, 255, 0.6);
    }

    .leave-chat-modal {
        position: absolute;
        top: 42%;
        left: 10%;
        z-index: 100;
        background-color: #fff;
        width: 401px;
        height: auto;
        border-radius: 25px;
        padding: 15px 24px 24px 24px;
        display: flex;
        flex-direction: column;
        box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(40px);
        gap: 30px;

        ${responsive.DESKTOP`
            left: 13%;
        `}

        ${responsive.LAPTOP`
            left: 15%;
        `}

        ${responsive.LAPTOP_1200`
            left: 16%;
        `}

        ${responsive.LAPTOP_1100`
            left: 17%;
        `}

        ${responsive.LAPTOP_1050`
            left: 18%;
        `}

        ${responsive.TABLET`
            left: unset;
            right: unset;
            top: unset;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: start;
        `}

        .close-modal-button {
            display: flex;
            flex-direction: row;
            justify-content: end;
            align-items: center;
        }

        .leave-session-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .session-title,
            .confirmation {
                font-family: ${FONTS.PRIMARY};
                font-weight: 500;
                font-size: 24px;
                line-height: 108.1%;
                letter-spacing: -0.01em;
                color: ${COLORS.PRIMARY_WARM};
            }

            .confirmation {
                color: ${COLORS.TEXT_DARK};
            }
        }

        .leave-session-action-buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .MuiButton-root {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.05em;

                border-radius: 12px;
                background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                color: #ffffff;
                width: 100%;
                height: 50px;
            }

            .no-button {
                background: #ffffff;
                color: ${COLORS.PRIMARY_DARK};
            }
        }
    }
`;

export const FooterContentWrapper = styled(Box)`
    .black-background {
        background-color: #ffffff;
    }
`;

export const FeedbackSectionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #ffffff;
    box-shadow: 0px 0px 24px rgba(196, 196, 196, 0.25);
    border-radius: 35px 35px 0px 0px;
    margin-left: 150px;
    width: 555px;
    padding-top: 50px;
    margin-top: 140px;
    padding-bottom: 80px;
    z-index: 100;

    ${responsive.TABLET`
        margin-left: 0px;
        margin-top: 0px;
        align-self: center;
        width: 100%;
        border-radius: 0;
    `}

    ${responsive.MOBILE`
        margin-left: 0px;
        margin-top: 0px;
    `}

    .yellow-wave-figure {
        height: 72px;
        width: 72px;
    }

    .thanks-for-joining {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 108.1%;
        color: ${COLORS.PRIMARY_DARK};
    }

    .your-experience {
        font-family: ${FONTS.PRIMARY};
        font-weight: 500;
        font-size: 24px;
        line-height: 108.1%;
        color: ${COLORS.PRIMARY_DARK};
        margin-top: 20px;
        width: 90%;
        text-align: center;

        .star-rating {
            display: flex;
            justify-content: center;
            margin: 8px 0px;
        }
    }

    .rate-experience {
        font-family: ${FONTS.TERTIARY};
        font-weight: 400;
        font-size: 16px;
        line-height: 108.1%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: ${COLORS.PRIMARY_DARK};
    }

    .i-feel-text {
        font-family: ${FONTS.PRIMARY};
        font-weight: 600;
        font-size: 24px;
        line-height: 35px;
        color: ${COLORS.PRIMARY_DARK};
        margin-top: 20px;
    }

    .feeling-chips {
        width: 50%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 10px;

        ${responsive.MOBILE`
            width: 60%;
        `}

        ${responsive.MOBILE_376`
            width: 70%;
        `}

        ${responsive.MOBILE_325`
            width: 80%;
        `}

        .feeling-chip-value {
            border: 2px solid rgba(195, 161, 87, 0.7);

            &.not-selected-chip {
                border: 2px solid rgba(195, 161, 87, 0.7);
                background-color: rgba(255, 255, 255, 0.6);

                &:hover {
                    background-color: transparent;
                }

                /* .MuiChip-label {
                        padding-left: 5px;
                        padding-right: 5px;
                    } */
            }

            &.selected-chip {
                color: white;

                /* .MuiChip-label {
                        padding-left: 5px;
                        padding-right: 5px;
                    } */
            }
        }
    }

    .other-feeling-input {
        width: 60%;

        ${responsive.MOBILE`
            width: 80%;
        `}

        ${responsive.MOBILE_376`
            width: 90%;
        `}
    }

    .action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 60%;

        ${responsive.MOBILE`
            width: 80%;
        `}

        ${responsive.MOBILE_376`
            width: 90%;
        `}

        ${responsive.MOBILE_325`
            gap: 10px;
        `}

        .save-and-close-button {
            height: 100%;
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
            border-radius: 12px;
            font-family: ${FONTS.PRIMARY};
            font-weight: 600;
            font-size: 14px;
            line-height: 23px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #ffffff;
        }

        .save-and-journal-button {
            border-radius: 12px;
            font-family: ${FONTS.PRIMARY};
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            text-transform: uppercase;
            border: 2px solid;
            padding: 5px 10px;
        }
    }

    .skip-for-now {
        font-family: ${FONTS.PRIMARY};
        font-weight: 400;
        font-size: 16px;
        line-height: 108.1%;
        letter-spacing: 0.05em;
        color: ${COLORS.MEDIUM_GREY};
        opacity: 0.7;
        margin-top: 40px;
        cursor: pointer;
    }
`;

export const PostSessionSuggestionsWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ffffff;
    box-shadow: 0px 0px 24px rgba(196, 196, 196, 0.25);
    border-radius: 35px 35px 0px 0px;
    margin-left: 150px;
    width: 555px;
    padding-top: 15px;
    margin-top: 140px;
    padding-bottom: 150px;
    z-index: 100;

    ${responsive.TABLET`
        margin-left: 0px;
        align-self: center;
        width: 90%;
    `}

    ${responsive.MOBILE`
        width: 100%;
        margin-left: 0px;
        margin-top: 0px;
    `}

    .close-modal-button {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        margin-right: 20px;
    }

    .suggestions-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* gap: 10px; */

        .continue-journey-text {
            font-family: ${FONTS.PRIMARY};
            font-weight: 400;
            font-size: 18px;
            line-height: 108.1%;
            color: ${COLORS.PRIMARY_DARK};
        }

        .category-name {
            font-family: ${FONTS.PRIMARY};
            font-weight: 500;
            font-size: 24px;
            line-height: 108.1%;
            color: ${COLORS.PRIMARY_DARK};
        }

        .topics {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            margin: 10px 0px;

            .topic-chip-value {
                border: 2px solid rgba(195, 161, 87, 0.7);

                &.not-selected-chip {
                    border: none;
                    color: ${COLORS.MEDIUM_GREY};
                    background-color: ${COLORS.LIGHT_GREY_SECONDARY};

                    &:hover {
                        background-color: transparent;
                    }

                    .MuiChip-label {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                        padding: 25px;
                    }
                }

                &.selected-chip {
                    /* color: white; */

                    .MuiChip-label {
                        font-family: ${FONTS.PRIMARY};
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                        padding: 25px;
                    }
                }
            }
        }

        .suggestion-content-data {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
            height: 500px;
            align-items: center;
            justify-content: center;

            .tao-card {
                .slider-card {
                    height: 105px;
                    width: 183px;
                }
            }
        }

        .return-home-button {
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
            border-radius: 12px;
            font-family: ${FONTS.PRIMARY};
            font-weight: 600;
            font-size: 14px;
            line-height: 23px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #ffffff;
            width: 70%;
            height: 60px;
        }
    }
`;
