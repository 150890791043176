import styled from 'styled-components';
import { Drawer, Menu, TextField } from '@mui/material';
import { responsive, FONTS, COLORS } from 'Styles/Constants';

export const CustomChatDrawer = styled(Drawer)`
    z-index: 200 !important;
    left: unset !important;
    width: 400px;

    .MuiPaper-root {
        background-color: ${COLORS.TEXT_DARK};
        backdrop-filter: blur(30px);
        height: calc(100vh - 50px);

        ${responsive.TABLET`
            height: 88%;
            border-radius: 35px 35px 0px 0px;
        `}

        .session-chats {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            padding: 110px 30px 20px 30px;
            width: 384px;
            height: calc(100vh - 70px);
            background-color: ${COLORS.TEXT_DARK};
            backdrop-filter: blur(30px);
            border-radius: 0px;
            gap: 30px;

            ${responsive.TABLET`
                width: 100%;
                height: calc(100vh - 210px);
                padding: 30px 30px 20px 30px;
            `}

            .chat-section {
                display: flex;
                flex-direction: column;

                .chat-details {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 15px;

                    ${responsive.TABLET`
                        justify-content: start;
                    `}
                    
                    .user-profile-image {
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        align-items: center;
                        height: 100%;
                        padding-top: 5px;

                        img {
                            border-radius: 8px;
                            height: 32px;
                            width: 32px;
                        }
                    }

                    .username-message-details {
                        display: flex;
                        flex-direction: column;

                        ${responsive.TABLET`
                            flex: 80%;
                        `}

                        .user-name {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            color: ${COLORS.LIGHT_GREY};
                            opacity: 0.6;
                        }

                        .user-message {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: #ffffff;
                            width: 232px;
                        }
                    }

                    .chat-time {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        font-family: ${FONTS.PRIMARY};
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 17px;
                        color: rgba(232, 232, 232, 0.6);
                    }

                    .current-user-message {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px 14px 12px 20px;
                        gap: 15px;
                        background-color: ${COLORS.MEDIUM_GREY};
                        border-radius: 20px 20px 0px 20px;
                        align-self: end;
                        width: 100%;
                        margin-left: 30px;
                        margin-right: 30px;

                        /* ${responsive.TABLET`
                            width: 70%;
                        `} */

                        .current-user-message-time {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 17px;
                            color: rgba(232, 232, 232, 0.6);
                        }

                        .current-user-message-text {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }

    .chat-message-section {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        gap: 20px;
        background-color: ${COLORS.TEXT_DARK};
        backdrop-filter: blur(30px);
        border-radius: 0px;
        padding: 10px 10px 10px 25px;

        ${responsive.TABLET`
            flex-direction: column-reverse;
        `}

        svg {
            ${responsive.TABLET`
                transform: rotate(90deg);
            `}
        }
    }
`;

export const CustomChatContextMenu = styled(Menu)`
    .MuiPaper-root {
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
        border-radius: 0px 14px 14px 14px;

        .MuiList-root {
            padding-top: 3px;
            padding-bottom: 3px;

            .MuiMenuItem-root {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 10px;

                svg {
                    margin-bottom: 5px;
                }
            }
        }
    }
`;

export const CustomChatMessageInput = styled(TextField)`
    width: 100%;
    padding: 0px 20px 0px 0px !important;

    .MuiOutlinedInput-root {
        border-radius: 30px;
        background-color: ${COLORS.LIGHT_GREY_SECONDARY};

        .MuiOutlinedInput-input {
            font-family: ${FONTS.PRIMARY};
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: ${COLORS.TEXT_DARK};
            padding: 14px 14px 14px 10px;
        }

        .MuiOutlinedInput-notchedOutline {
            border: none;
        }
    }
`;
