import styled from 'styled-components';
import DownloadBgImage from 'Assets/images/Downloads/download-bg.svg';

import { COLORS, FONTS, responsive } from 'Styles/Constants';
import { Popover } from '@mui/material';

export const DownloadWrapper = styled.div`
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: contain;
    height: 100vh;
    background-image: url(${DownloadBgImage});

    ${responsive.TABLET`
        background-size: cover;
    `}

    &.main-container {
        ${responsive.TABLET`
            align-items: flex-start;
        `}

        .parent-box {
            width: 480px;
            height: 80%;
            position: relative;

            ${responsive.TABLET`
                height: calc(100% - 100px);
            `};

            ${responsive.PHABLET`
                width: 80%;
            `};

            ${responsive.MOBILE`
                width: 95%;
            `};

            .title {
                font-size: 40px;
                color: ${COLORS.PRIMARY_DARK};
                text-transform: capitalize;
                font-family: ${FONTS.SECONDARY};
                font-weight: 700;
                height: 60px;

                ${responsive.PHABLET`
                  font-size: 32px;
                `}
            }

            .card {
                height: calc(100% - 60px);
                box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
                border-radius: 16px;
                background-color: #fff;

                .container {
                    margin: 100px 0;
                    width: 80%;

                    .download-icon {
                        min-height: 50px;
                        min-width: 50px;
                    }

                    .sub-title {
                        color: ${COLORS.TEXT_DARK};
                        font-size: 24px;
                        max-width: 265px;
                        text-align: center;
                        margin: 15px 0 45px;

                        ${responsive.PHABLET`
                          font-size: 18px;
                        `}
                    }
                    .btn {
                        width: 100%;
                        height: 60px;
                        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
                        background-color: #fff;
                        color: ${COLORS.PRIMARY_WARM};
                        border-radius: 12px;
                        margin: 0 0 15px;
                        font-size: 16px;

                        ${responsive.PHABLET`
                          font-size: 12px;
                        `}
                    }
                }

                .data-container {
                    width: 90%;
                    padding: 20px 0;

                    .header {
                        width: 100%;
                        margin: 0 0 10px;

                        .event-text {
                            font-size: 20px;
                            color: ${COLORS.TEXT_DARK};
                            font-weight: 400;
                            width: max-content;

                            ${responsive.PHABLET`
                                font-size: 18px;
                            `}
                            ${responsive.MOBILE`
                                font-size: 15px;
                            `}
                        }

                        .category-selection {
                            margin: 0 0 0 auto;
                            .sorting-btn {
                                height: 30px;
                                width: 150px;
                                border-radius: 15px;
                                background-color: #fff;
                                border: 1px solid #e8e8e8;

                                ${responsive.PHABLET`
                                    width: 130px;
                                `}
                                ${responsive.MOBILE`
                                    width: 110px;
                                `}

                                .button-text {
                                    color: ${COLORS.PRIMARY_WARM};
                                    font-family: ${FONTS.PRIMARY_MEDIUM};
                                    text-transform: none;
                                    font-size: 15px;

                                    ${responsive.PHABLET`
                                        font-size: 12px;
                                    `}
                                    ${responsive.PHABLET`
                                        font-size: 10px;
                                    `}
                                }
                            }

                            .down-arrow-icon {
                                transition: 0.2s;
                                &.rotate {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }

                    .list {
                        width: 100%;
                        height: 100%;

                        .list-item {
                            height: 100px;
                            border-bottom: 1px solid rgb(232 232 232 / 0.6);
                            margin: 0 0 20px;

                            ${responsive.PHABLET`
                                height: 85px;
                            `}

                            &:last-child {
                                border-bottom: none;
                            }

                            .item {
                                .radio-icon {
                                    height: 30px;
                                    width: 30px;

                                    ${responsive.PHABLET`
                                        height: 25px;
                                        width: 25px;
                                    `}
                                }
                                .swipe-data {
                                    transition: all 0.5s ease-in-out;
                                    &.add-margin {
                                        transition: all 0.5s ease-in-out;
                                        margin: 0 0 0 20px;

                                        ${responsive.PHABLET`
                                            margin: 0 0 0 15px;
                                        `}
                                    }
                                    .image {
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        background-size: cover;
                                        height: 65px;
                                        width: 120px;
                                        border-radius: 12px 4px;
                                        margin: 0 15px 0 0;
                                        ${responsive.PHABLET`
                                            width: 100px;
                                            height: 60px;
                                            min-width: 100px;
                                            min-height: 60px;
                                        `}
                                    }

                                    .desc {
                                        height: 65px;
                                        width: 200px;
                                        max-width: 200px;

                                        ${responsive.PHABLET`
                                            width: 150px;
                                        `}
                                        ${responsive.PHABLET`
                                            width: 130px;
                                        `}
                                        ${responsive.MOBILE`
                                            width: 120px;
                                        `}

                                        .desc-title {
                                            color: ${COLORS.PRIMARY_WARM};
                                            font-size: 18px;

                                            ${responsive.PHABLET`
                                                font-size: 15px;
                                            `}
                                        }
                                        .desc-sub-title {
                                            color: ${COLORS.TEXT_DARK};
                                            font-size: 14px;

                                            ${responsive.PHABLET`
                                                font-size: 12px;
                                            `}
                                        }
                                    }

                                    .mobile-download-icon {
                                        align-self: center;
                                        margin: 0 15px 0 auto;
                                        min-width: 25px;
                                        min-height: 25px;
                                    }
                                }
                            }
                        }

                        .load-more {
                            padding: 20px 0;
                            width: max-content;
                            margin: 0 auto;

                            &:hover {
                                .load-more-arrow {
                                    transition: all 0.3s ease-in-out;
                                    margin: 5px 0 0;
                                }
                            }

                            .load-more-text {
                                color: ${COLORS.PRIMARY};
                            }
                            .load-more-arrow {
                                width: 10px;
                                height: 10px;
                                border-right: 1px solid ${COLORS.PRIMARY};
                                border-bottom: 1px solid ${COLORS.PRIMARY};
                                transform: rotate(45deg);
                            }
                        }
                    }
                }
            }

            .delete-icon {
                position: absolute;
                right: -420px;
                gap: 20px;
                bottom: 0;
                border: 2px solid ${COLORS.MEDIUM_GREY};
                border-radius: 24px;
                height: 200px;
                width: 400px;
                background-color: #fff;
                box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.15);
                animation: fade-in 0.4s both;
                text-transform: none;

                ${responsive.DESKTOP`
                    width: 350px;
                    right: -370px;
                    animation: fade-in-desktop 0.4s both;
                `}

                ${responsive.LAPTOP`
                    width: 220px;
                    right: -240px;
                    animation: fade-in-laptop 0.4s both;
                `}

                ${responsive.TABLET`
                    width: 100%;
                    height: 70px;
                    bottom: -90px;
                    right: 0;
                    border: none;
                    gap: 10px;
                    animation: fade-in-mobile 0.4s both;
                `}

                @keyframes fade-in {
                    0% {
                        right: -100px;
                    }
                    100% {
                        right: -420px;
                    }
                }
                @keyframes fade-in-desktop {
                    0% {
                        right: -100px;
                    }
                    100% {
                        right: -370px;
                    }
                }
                @keyframes fade-in-laptop {
                    0% {
                        right: -100px;
                    }
                    100% {
                        right: -240px;
                    }
                }
                @keyframes fade-in-mobile {
                    0% {
                        bottom: -10px;
                    }
                    100% {
                        bottom: -90px;
                    }
                }

                .icon {
                    height: 50px;
                    width: 50px;
                }
                .delete-text {
                    color: ${COLORS.TEXT_DARK};
                    font-size: 24px;
                    font-weight: 500;

                    ${responsive.TABLET`
                        font-size: 18px;
                    `}
                    ${responsive.PHABLET`
                        font-size: 16px;
                    `}
                }
            }
        }
    }
`;

export const PopOverWrapper = styled(Popover)`
    .sorting-box {
        width: 150px;
        max-height: 200px;
        border: 1px solid ${COLORS.LIGHT_GREY};
        border-radius: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .box {
            padding: 0 15px;
            width: 100%;

            .list-item {
                justify-content: flex-start;
                max-width: 100%;
                min-width: 100%;
                border-bottom: 0.5px solid ${COLORS.LIGHT_GREY};

                &:last-child {
                    border-bottom: none;
                }

                .category-icon {
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    min-height: 24px;
                    min-width: 24px;
                }

                .sorting-text {
                    padding: 10px 5px;
                    font-size: 15px;
                    color: ${COLORS.MEDIUM_GREY};
                    width: 100%;
                    border-bottom: 0.5px solid rgb(132 132 132 / 0.5);
                    justify-content: center;
                    max-width: 100px;

                    &:hover {
                        cursor: pointer;
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
`;
