import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    Slide,
    Fade
} from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import defaultLocal from 'date-fns/locale/en-US';
import moment from 'moment';

import Lottie from 'lottie-react';

import { BREAKPOINTS_VALUE } from 'Styles/Constants';

import GlobeImage from 'Assets/images/BookAConsultation/book-a-consultation-map.png';
// import MasterFranciscoProfileImage from 'Assets/images/BookAConsultation/master-francisco-profile-image.png';
// import DavidProfileImage from 'Assets/images/BookAConsultation/david-profile-image.png';
// import CeciliaLiuProfileImage from 'Assets/images/BookAConsultation/cecilia-liu-profile-image.png';
import DefaultUserProfileIcon from 'Assets/images/Profile/default-user-icon.jpg';
import CheckCircle from 'Assets/images/BookAConsultation/check-circle.png';
import ShoppingCart from 'Assets/images/BookAConsultation/shopping-cart.png';

import { ReactComponent as DropDownIcon } from 'Assets/images/Common/down-arrow.svg';

import HealthIcon from 'Assets/images/Wellness/health-icon.svg';
import RelationshipIcon from 'Assets/images/Wellness/relationship-icon.svg';
import BusinessAndFinanceIcon from 'Assets/images/Wellness/business-and-finance-icon.svg';
import PeakPerformanceIcon from 'Assets/images/Wellness/peak-performance-icon.svg';
import PregnancyIcon from 'Assets/images/Wellness/pregnancy-icon.svg';
import ChlidrenAndEducationIcon from 'Assets/images/Wellness/children-and-education-icon.svg';

import wavesAnimation from 'Assets/AnimationFiles/wavy-lines-animation.json';
import Footer from 'Components/common/Footer/Footer';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';

import {
    BookAConsultationMainSectionWrapper,
    FooterSectionWrapper,
    SessionDateTimeSectionWrapper,
    CustomRadioIcon,
    CustomRadioCheckedIcon
} from './BookAConsultation.style';

import { useDispatch } from 'react-redux';
import { showSideBar, hideSideBar } from 'Redux/Sidebar/Actions';

import { getWindowDimensions } from 'Helpers';
import { BackButton } from 'Helpers/CommonSVGs';

function CustomRadioButton(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent'
                }
            }}
            className="radio-button"
            disableRipple
            color="default"
            checkedIcon={<CustomRadioCheckedIcon />}
            icon={<CustomRadioIcon />}
            {...props}
        />
    );
}

function useDelayUnmount(isMounted, delayTime) {
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        let timeoutId;
        if (isMounted && !shouldRender) {
            setShouldRender(true);
        } else if (!isMounted && shouldRender) {
            timeoutId = setTimeout(() => setShouldRender(false), delayTime);
        }
        return () => clearTimeout(timeoutId);
    }, [isMounted, delayTime, shouldRender]);
    return shouldRender;
}

const theme = createTheme({
    components: {
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    width: '445px',
                    backgroundColor: 'white',
                    borderRadius: 20,
                    height: '490px'
                },
                viewTransitionContainer: {
                    width: '445px',
                    height: '439px',
                    '.PrivatePickersSlideTransition-root': {
                        height: '399px'
                    },
                    '@media only screen and (max-width: 1050px)': {
                        width: '420px'
                    },
                    '@media only screen and (max-width: 870px)': {
                        width: '100%'
                    }
                }
            }
        }
    }
});

const BookAConsultation = (props) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const dispatch = useDispatch();

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);

            dispatch(showSideBar());
        };
    }, [dispatch]);

    useEffect(() => {
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            dispatch(hideSideBar());
        }
    }, [dispatch, windowDimensions]);

    const [currentProcessState, setCurrentProcessState] = useState('begin');

    const updateState = (updatedState) => {
        setCurrentProcessState(updatedState);
    };

    return (
        <>
            <TaoCalligraphyWatermark />
            <BookAConsultationMainSectionWrapper currentProcessState={currentProcessState}>
                <Box className="begin-booking-section">
                    {currentProcessState === 'choose-your-coach' ||
                    currentProcessState === 'choose-your-coach' ? (
                        <Box className="book-a-consultation-header-title">
                            {windowDimensions.width <= BREAKPOINTS_VALUE.TABLET && (
                                <Box className="back-button">
                                    <BackButton />
                                </Box>
                            )}
                            <Slide
                                in={
                                    currentProcessState === 'choose-your-coach' ||
                                    currentProcessState === 'final-review'
                                }
                                direction={
                                    currentProcessState === 'choose-your-coach' ? 'left' : 'right'
                                }
                                timeout={{ enter: 1000, exit: 500 }}
                                unmountOnExit>
                                <Typography className="book-a-consultation-title">
                                    Book A Consultation
                                </Typography>
                            </Slide>
                        </Box>
                    ) : (
                        <Box className="book-a-consultation-header-title">
                            {windowDimensions.width <= BREAKPOINTS_VALUE.TABLET && (
                                <Box className="back-button">
                                    <BackButton />
                                </Box>
                            )}
                            <Typography className="book-a-consultation-title">
                                Book A Consultation
                            </Typography>
                        </Box>
                    )}

                    {(currentProcessState === 'begin' ||
                        currentProcessState === 'what-to-expect') && (
                        <BookAConsultationBeginSection
                            windowDimensions={windowDimensions}
                            currentProcessState={currentProcessState}
                            updateState={updateState}
                        />
                    )}
                    {currentProcessState === 'choose-a-topic' && (
                        <BookAConsultationChooseATopicSection
                            currentProcessState={currentProcessState}
                            updateState={updateState}
                        />
                    )}
                    {currentProcessState === 'choose-your-coach' && (
                        <BookAConsultationChooseYourCoachSection
                            currentProcessState={currentProcessState}
                            updateState={updateState}
                        />
                    )}
                    {currentProcessState === 'schedule' && (
                        <BookAConsultationScheduleSection
                            currentProcessState={currentProcessState}
                            updateState={updateState}
                        />
                    )}
                    {currentProcessState === 'final-review' && (
                        <BookAConsultationReviewSection
                            currentProcessState={currentProcessState}
                            updateState={updateState}
                        />
                    )}
                </Box>

                {currentProcessState === 'choose-a-topic' && (
                    <>
                        <Box className="waves-animation">
                            <Lottie loop autoplay animationData={wavesAnimation} />
                        </Box>

                        <Fade
                            in={currentProcessState === 'choose-a-topic'}
                            timeout={{ enter: 1000, exit: 500 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="choose-your-coach-button"
                                onClick={() => updateState('choose-your-coach')}>
                                <Typography>NEXT: Choose your COACH</Typography>
                            </Button>
                        </Fade>
                    </>
                )}
            </BookAConsultationMainSectionWrapper>
            <FooterSectionWrapper>
                <Footer showWhiteBackground={false} topBorderColor="gray" />
            </FooterSectionWrapper>
        </>
    );
};

const BookAConsultationBeginSection = (props) => {
    return (
        <>
            {(props.currentProcessState === 'begin' ||
                (props.currentProcessState === 'what-to-expect' &&
                    props.windowDimensions.width > BREAKPOINTS_VALUE.TABLET)) && (
                <Box className="globe-image-section">
                    <Box className="profile-image-section">
                        <img className="profile-images first" src={DefaultUserProfileIcon} alt="" />
                        <img
                            className="profile-images second"
                            src={DefaultUserProfileIcon}
                            alt=""
                        />
                        <img
                            className="profile-images third"
                            src={DefaultUserProfileIcon}
                            alt=""
                        />
                        <img className="profile-images fourth" src={DefaultUserProfileIcon} alt="" />
                        <img className="profile-images fifth" src={DefaultUserProfileIcon} alt="" />
                    </Box>
                    <Box className="globe-image">
                        <img src={GlobeImage} alt="" />
                    </Box>
                </Box>
            )}

            {props.currentProcessState === 'begin' && (
                <>
                    <Typography className="tag-line">
                        You don't have to do it alone... <strong>You have us.</strong>
                    </Typography>

                    <Typography className="help-you-description">
                        <strong>
                            Let our Tao Calligraphy Wellness Coaches worldwide help you:
                        </strong>
                        <ul>
                            <li>Find purpose and direction in your life</li>
                            <li>
                                Feel more connected to your heart and soul and your self-healing
                                abilities
                            </li>
                            <li>Overcome blockages in health, relationships, and finances</li>
                            <li>Become more confident and clear about your decisions</li>
                            <li>Develop positive mindsets and remove negative thinking</li>
                            <li>Create a flow of abundance in your life</li>
                            <li>Experience more joy every day</li>
                        </ul>
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        className="begin-button"
                        onClick={() => props.updateState('what-to-expect')}>
                        <Typography>BEGIN</Typography>
                    </Button>
                </>
            )}

            {props.currentProcessState === 'what-to-expect' && (
                <>
                    <Typography className="expect-title">
                        Here's what to expect from your session:
                    </Typography>

                    <Slide
                        in={props.currentProcessState === 'what-to-expect'}
                        direction={'up'}
                        timeout={{ enter: 1000, exit: 500 }}
                        unmountOnExit>
                        <Box className="expect-details-section">
                            <Box className="details-section" style={{ marginTop: '20px' }}>
                                <img className="check-circle" src={CheckCircle} alt="" />
                                <Box className="expect-details">
                                    <Typography className="expect-details-title">
                                        Safe Space
                                    </Typography>
                                    <Typography className="expect-details-desc">
                                        The session will be private and confidential between you and
                                        your coach.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                className="details-section"
                                style={{
                                    width:
                                        props.windowDimensions.width <= BREAKPOINTS_VALUE.TABLET
                                            ? '100%'
                                            : '800px'
                                }}>
                                <img src={CheckCircle} alt="" />
                                <Box className="expect-details">
                                    <Typography className="expect-details-title">
                                        Subscriber perks
                                    </Typography>
                                    <Typography className="expect-details-desc">
                                        {`As a <level> subscriber to this app, you get <discount%> off. Your consultation also includes a Tao Calligraphy Field Healing service for one request, as our gift to you.`}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="details-section" style={{ marginTop: '20px' }}>
                                <img src={CheckCircle} alt="" />
                                <Box className="expect-details">
                                    <Typography className="expect-details-title">
                                        Set your comfort level
                                    </Typography>
                                    <Typography className="expect-details-desc">
                                        You can turn your camera off/on at any time. Whatever helps
                                        you feel at ease.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Slide>

                    <Fade
                        in={props.currentProcessState === 'what-to-expect'}
                        timeout={{ enter: 1000, exit: 500 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            className="choose-your-topic-button"
                            onClick={() => props.updateState('choose-a-topic')}>
                            <Slide
                                in={props.currentProcessState === 'what-to-expect'}
                                direction={'right'}
                                timeout={{ enter: 1000, exit: 500 }}
                                unmountOnExit>
                                <Typography>NEXT: CHOOSE YOUR TOPIC</Typography>
                            </Slide>
                        </Button>
                    </Fade>
                </>
            )}
        </>
    );
};

const BookAConsultationChooseATopicSection = (props) => {
    const wellnessCategoryList = [
        { wellnessIcon: HealthIcon, title: 'Health' },
        { wellnessIcon: RelationshipIcon, title: 'Relationships' },
        { wellnessIcon: BusinessAndFinanceIcon, title: 'Business & Finance' },
        { wellnessIcon: PeakPerformanceIcon, title: 'Peak Performance' },
        { wellnessIcon: PregnancyIcon, title: 'Pregnancy' },
        { wellnessIcon: ChlidrenAndEducationIcon, title: 'Children & Education' }
    ];

    const [isMounted] = useState(true);
    const shouldRenderChild = useDelayUnmount(isMounted, 500);
    const mountedStyle = { animation: 'inAnimation 1000ms ease-in' };
    const unmountedStyle = { animation: 'outAnimation 1000ms ease-in' };

    return (
        <>
            <Box className="choose-a-topic-section">
                <Typography className="what-to-discuss">
                    Tell us what you'd like to discuss with your coach.
                </Typography>

                <Typography className="choose-topic-text">Choose one or more topics:</Typography>

                {/* <Grow in={props.currentProcessState === 'choose-a-topic'} timeout={{ enter: 1000, exit: 500 }}> */}
                {shouldRenderChild && (
                    <Box
                        className="wellness-list"
                        style={isMounted ? mountedStyle : unmountedStyle}>
                        {wellnessCategoryList.map((value, index) => {
                            return (
                                <Box className="wellness-content" key={index}>
                                    <IconButton className="wellness-icon-button">
                                        <img
                                            className="wellness-icon"
                                            src={value.wellnessIcon}
                                            alt=""
                                        />
                                    </IconButton>
                                    <Typography color="primary" className="wellness-category-name">
                                        {value.title}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Box>
                )}
                {/* </Grow> */}
            </Box>
        </>
    );
};

const BookAConsultationChooseYourCoachSection = (props) => {
    const regionOptions = [
        { value: 1, label: 'Asia' },
        { value: 2, label: 'LATAM' },
        { value: 3, label: 'APAC' }
    ];

    const languageOptions = [
        { value: 1, label: 'English' },
        { value: 2, label: 'German' },
        { value: 3, label: 'Russian' }
    ];

    const coachList = [
        {
            coachImage: DefaultUserProfileIcon,
            coachName: 'Francisco Quintero',
            coachDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            coachSpeciality: ['Health', 'Relationships'],
            coachLocation: 'Toronto,',
            coachLanguages: ['Canada | English']
        },
        {
            coachImage: DefaultUserProfileIcon,
            coachName: 'David Lusch',
            coachDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            coachSpeciality: ['Health', 'Relationships'],
            coachLocation: 'London,',
            coachLanguages: ['UK | English']
        },
        {
            coachImage: DefaultUserProfileIcon,
            coachName: 'Cecilia Liu',
            coachDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            coachSpeciality: ['Business & Finances', 'Peak Performance'],
            coachLocation: 'Erlangen,',
            coachLanguages: ['Germany | English', 'Deutsch', '中文']
        },
        {
            coachImage: DefaultUserProfileIcon,
            coachName: 'Francisco Quintero',
            coachDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            coachSpeciality: ['Health', 'Relationships'],
            coachLocation: 'Toronto,',
            coachLanguages: ['Canada | English']
        },
        {
            coachImage: DefaultUserProfileIcon,
            coachName: 'David Lusch',
            coachDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            coachSpeciality: ['Health', 'Relationships'],
            coachLocation: 'London,',
            coachLanguages: ['UK | English']
        },
        {
            coachImage: DefaultUserProfileIcon,
            coachName: 'Cecilia Liu',
            coachDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            coachSpeciality: ['Business & Finances', 'Peak Performance'],
            coachLocation: 'Erlangen,',
            coachLanguages: ['Germany | English', 'Deutsch', '中文']
        }
    ];

    return (
        <>
            <Box className="choose-your-coach-section">
                <Typography className="choose-your-coach-title">
                    Choose one of our Certified Tao Calligraphy Wellness Coaches:
                </Typography>

                <Box className="filter-options">
                    <FormControl className="form-margin" variant="filled">
                        <InputLabel id="demo-simple-select-filled-label">Region</InputLabel>
                        <Select
                            variant="filled"
                            disableUnderline
                            IconComponent={(props) => {
                                return <DropDownIcon className="down-arrow-icon" />;
                            }}
                            className="selector language"
                            label="Choose a Region">
                            {regionOptions.map((option, index) => (
                                <MenuItem value={option.value} key={index}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className="form-margin" variant="filled">
                        <InputLabel id="demo-simple-select-filled-label">
                            Consultation Language
                        </InputLabel>
                        <Select
                            variant="filled"
                            disableUnderline
                            IconComponent={(props) => {
                                return <DropDownIcon className="down-arrow-icon" />;
                            }}
                            className="selector language"
                            label="Choose Consultation Language">
                            {languageOptions.map((option, index) => (
                                <MenuItem value={option.value} key={index}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box className="coach-list-section">
                    {coachList.map((coach, index) => {
                        return (
                            <Box
                                key={index}
                                className="coach-details"
                                onClick={() => props.updateState('schedule')}>
                                <img className="coach-image" src={coach.coachImage} alt="" />

                                <Box className="details">
                                    <Typography className="coach-name">
                                        {coach.coachName}
                                    </Typography>
                                    <Typography className="coach-description">
                                        {coach.coachDescription}
                                    </Typography>

                                    <Box className="specialities">
                                        {coach.coachSpeciality.map((specialityValue, index) => {
                                            return (
                                                <Chip
                                                    key={index}
                                                    className="speciality-chip"
                                                    size="medium"
                                                    color="primary"
                                                    variant="filled"
                                                    label={specialityValue}
                                                />
                                            );
                                        })}
                                    </Box>

                                    <Box className="location-and-languages">
                                        <Typography>{coach.coachLocation}</Typography>

                                        {coach.coachLanguages.map((language, index) => {
                                            return (
                                                <Typography key={index}>
                                                    {language +
                                                        (index !== coach.coachLanguages.length - 1
                                                            ? ','
                                                            : '')}
                                                </Typography>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </>
    );
};

const BookAConsultationScheduleSection = (props) => {
    const chooseContantMethod = [
        { value: 1, label: 'Phone' },
        { value: 2, label: 'Email' }
    ];

    const todayDate = useRef(moment());
    const [currentMonthView, setCurrentMonthView] = useState(moment().startOf('month'));
    const [currentSelectedDate, setCurrentSelectedDate] = useState();

    const monthChangeHandler = (month) => {
        setCurrentMonthView(moment(month));
    };

    const datesToDisabled = [
        moment('2022-06-01').format('YYYY-MM-DD'),
        moment('2022-06-02').format('YYYY-MM-DD'),
        moment('2022-06-03').format('YYYY-MM-DD'),
        moment('2022-06-04').format('YYYY-MM-DD'),
        moment('2022-06-06').format('YYYY-MM-DD'),
        moment('2022-06-07').format('YYYY-MM-DD'),
        moment('2022-06-09').format('YYYY-MM-DD'),
        moment('2022-06-12').format('YYYY-MM-DD'),
        moment('2022-06-13').format('YYYY-MM-DD'),
        moment('2022-06-14').format('YYYY-MM-DD'),
        moment('2022-06-17').format('YYYY-MM-DD'),
        moment('2022-06-20').format('YYYY-MM-DD'),
        moment('2022-06-21').format('YYYY-MM-DD'),
        moment('2022-06-22').format('YYYY-MM-DD'),
        moment('2022-06-23').format('YYYY-MM-DD'),
        moment('2022-06-25').format('YYYY-MM-DD'),
        moment('2022-06-26').format('YYYY-MM-DD'),
        moment('2022-06-27').format('YYYY-MM-DD'),
        moment('2022-06-28').format('YYYY-MM-DD')
    ];

    const checkIfDateIsToBeDisabled = (date) => {
        return datesToDisabled.includes(moment(date).format('YYYY-MM-DD'));
    };

    const [selectedTimeSlot, setSelectedTimeSlot] = useState('12:00 PM');
    const timeSlots = [
        { timeSlotValue: '12:00 PM' },
        { timeSlotValue: '12:30 PM' },
        { timeSlotValue: '07:30 PM' }
    ];

    const onTimeSlotSelectionChange = (selectedValue) => {
        setSelectedTimeSlot(selectedValue);
    };

    return (
        <>
            <Fade
                in={props.currentProcessState === 'schedule'}
                timeout={{ enter: 1000, exit: 500 }}>
                <Box className="schedule-consultation-section">
                    <Typography className="schedule-consultation-title">
                        How would you like to connect with Francisco?
                    </Typography>

                    <Box className="filter-options">
                        <FormControl className="form-margin" variant="filled">
                            <InputLabel id="demo-simple-select-filled-label">
                                Preferred Contanct Method
                            </InputLabel>
                            <Select
                                variant="filled"
                                disableUnderline
                                IconComponent={(props) => {
                                    return <DropDownIcon className="down-arrow-icon" />;
                                }}
                                className="selector language"
                                label="Choose Contact Method">
                                {chooseContantMethod.map((option, index) => (
                                    <MenuItem value={option.value} key={index}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            className="text-field"
                            disableUnderline={true}
                            id="filled-basic"
                            label="Enter Phone Number"
                            variant="filled"
                        />
                    </Box>

                    <Typography className="select-date-text">Select a Date:</Typography>

                    <Box className="date-selection-section">
                        <SessionDateTimeSectionWrapper
                            currentWeekDay={
                                moment(currentMonthView).startOf('months').format('MM/DD/YYYY') ===
                                moment(todayDate.current).startOf('months').format('MM/DD/YYYY')
                                    ? todayDate.current.day()
                                    : -1
                            }>
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={defaultLocal}>
                                <ThemeProvider theme={theme}>
                                    <CalendarPicker
                                        className="static-calendar-root"
                                        onChange={(val) => {
                                            setCurrentSelectedDate(moment(val));
                                        }}
                                        onMonthChange={monthChangeHandler}
                                        shouldDisableDate={checkIfDateIsToBeDisabled}
                                        renderDay={(day, _value, DayComponentProps) => {
                                            return (
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    alignItems="center"
                                                    mb={1}>
                                                    <PickersDay
                                                        {...DayComponentProps}
                                                        disableMargin={false}
                                                        selected={
                                                            moment(currentSelectedDate).isSame(
                                                                moment(day)
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                        classes={{
                                                            root: 'calendar-day-root',
                                                            today: 'today-date'
                                                        }}
                                                        showDaysOutsideCurrentMonth={false}
                                                    />
                                                    {/* {!DayComponentProps.outsideCurrentMonth && <Box className={`dot ${DayComponentProps.today && 'primary'} ${DARK_GRAY.includes(moment(day).format("YYYY-MM-DD")) && 'dark-gray'} ${LIGHT_GRAY.includes(moment(day).format("YYYY-MM-DD")) && 'light'} `}></Box>} */}
                                                </Box>
                                            );
                                        }}></CalendarPicker>
                                </ThemeProvider>
                            </LocalizationProvider>
                        </SessionDateTimeSectionWrapper>

                        {currentSelectedDate && currentSelectedDate !== '' && (
                            <Slide
                                in={currentSelectedDate && currentSelectedDate !== ''}
                                direction={'right'}
                                timeout={{ enter: 1000, exit: 500 }}
                                unmountOnExit>
                                <Box className="date-time-slots-section">
                                    <Box className="selected-date-display">
                                        <Typography>
                                            {moment(currentSelectedDate).format('dddd, MMM DD')}
                                        </Typography>
                                    </Box>

                                    <Box className="time-slot-and-duration">
                                        <Box className="time-slot-section">
                                            <Typography className="time-title">Time:</Typography>

                                            {timeSlots.map((value, index) => {
                                                return (
                                                    <Box
                                                        key={index}
                                                        className={`time-slot ${
                                                            selectedTimeSlot === value.timeSlotValue
                                                                ? 'selected'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            onTimeSlotSelectionChange(
                                                                value.timeSlotValue
                                                            )
                                                        }>
                                                        <Typography>
                                                            {value.timeSlotValue}
                                                        </Typography>
                                                    </Box>
                                                );
                                            })}
                                        </Box>

                                        <Box className="duration-section">
                                            <Typography className="duration-title">
                                                Duration:
                                            </Typography>

                                            <FormControl>
                                                <RadioGroup
                                                    defaultValue="30-mins"
                                                    name="duration-radio-group"
                                                    className="radio-button-group">
                                                    <FormControlLabel
                                                        value="30-mins"
                                                        control={<CustomRadioButton />}
                                                        label="30 mins ($xx)"
                                                    />
                                                    <FormControlLabel
                                                        value="60-mins"
                                                        control={<CustomRadioButton />}
                                                        label="60 mins ($xx)"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </Box>
                            </Slide>
                        )}
                    </Box>

                    <Box className="review-booking-button-section">
                        <Button
                            variant="contained"
                            color="primary"
                            className="review-your-booking-button"
                            onClick={() => props.updateState('final-review')}
                            disabled={!currentSelectedDate}>
                            <Typography>NEXT: Review your Booking</Typography>
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </>
    );
};

const BookAConsultationReviewSection = (props) => {
    return (
        <>
            <Fade
                in={props.currentProcessState === 'final-review'}
                timeout={{ enter: 1000, exit: 500 }}>
                <Box className="review-consultation-section">
                    <Typography className="review-consultation-title">
                        Please Review Your Booking:
                    </Typography>

                    <Box className="review-consultation-details">
                        <Box className="consultation-scheduled-with-details">
                            <img
                                className="consultant-image"
                                src={DefaultUserProfileIcon}
                                alt=""
                            />

                            <Box className="consultant-details">
                                <Typography className="consultant-name">
                                    Francisco Quintero
                                </Typography>
                                <Typography className="consultant-date">
                                    THURSDAY, NOV 18
                                </Typography>
                                <Typography className="consultant-time">
                                    12:00 - 12:30 PM (30 min)
                                </Typography>
                            </Box>
                        </Box>

                        <Typography className="consultation-for">
                            Consultation for: <strong>Health, Relationships</strong>
                        </Typography>
                        <Typography className="consultation-contact-method">
                            Contact Method: <strong>By Phone at ***-**-****</strong>
                        </Typography>
                        <Typography className="consultation-language">
                            Language: <strong>English</strong>
                        </Typography>
                        <Typography className="consultation-fee">
                            Fee: <strong>$**</strong>
                        </Typography>
                    </Box>

                    <Box className="terms-checkbox">
                        <Checkbox />
                        <Typography>
                            I agree to the{' '}
                            <span className="terms-and-conditions-link">terms & conditions</span>
                        </Typography>
                    </Box>

                    <Box className="review-consultation-action-section">
                        <Button variant="contained" color="primary" className="add-to-cart-button">
                            <Typography>Add to Cart</Typography>
                            <img src={ShoppingCart} alt="" />
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="start-over-button"
                            onClick={() => props.updateState('begin')}>
                            <Typography>Start Over</Typography>
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </>
    );
};

export default BookAConsultation;
