import React, { useEffect, useState } from 'react';
import { WeekCalendarWrapper } from './WeekCalendar.style';
import moment from 'moment';

import { Box, Typography } from '@mui/material';
import { numberFormat } from 'Helpers';
import TaoScrollbar from '../Scrollbar';

const WeekCalendar = ({
    start_time = moment().startOf('day'),
    cardRootClass = '',
    weekGrayDots = [], // array of moment dates
    darkGrayDots = [], // array of moment dates
    isProgramCalendar = false,
    days = [],
    ...props
}) => {
    const [dates, setDates] = useState([]);
    const currentDay = parseInt(props?.completedDay) || 1;

    const onSelectDate = (date, index) => {
        if (!isProgramCalendar) return;

        if (isProgramCalendar && parseInt(props?.currentSelectedDay) === parseInt(date?.programDay))
            return;

        let innerArray = [...dates].map((item) => ({ ...item, isSelected: false }));
        innerArray[index].isSelected = innerArray[index].isSelected ? false : true;
        setDates(innerArray);

        if (isProgramCalendar)
            return props?.onDaySelection(date?.programDay, date, props?.programDetails);
    };

    const datePickerColorUI = (day, isJoined) => {
        if (!isJoined) {
            if (day?.isSelected) return 'pastNo-todayNo-selectedYes';
            return 'pastNo-todayNo-selectedNo';
        }

        if (day.programDay === currentDay) {
            if (day.programDay === currentDay && day?.isSelected)
                return 'pastNo-todayYes-selectedYes';
            return 'pastNo-todayYes-selectedNo';
        }
        if (day?.isSelected && day.programDay > currentDay) {
            return 'pastNo-todayNo-selectedYes';
        }
        if (day.programDay < currentDay && !day?.isSelected) return 'pastYes-todayNo-selectedNo';
        if (day.programDay < currentDay && day?.isSelected) return 'pastYes-todayNo-selectedYes';

        return 'pastNo-todayNo-selectedNo';
    };

    useEffect(() => {
        let newDates = [...days];
        if (!props?.isProgramJoined && newDates?.length) {
            newDates[0].isSelected = true;
            setDates(newDates);
        } else {
            let currentDaySelection = newDates.findIndex((item) => item?.programDay === currentDay);
            if (currentDaySelection > -1) {
                newDates[currentDaySelection].isSelected = true;
                setDates(newDates);
            }
        }
    }, [days, props?.isProgramJoined, currentDay]);

    useEffect(() => {
        setDates(days);
    }, [days]);

    return (
        <WeekCalendarWrapper className={cardRootClass}>
            <TaoScrollbar keyId="dates" horizontalScroll>
                {dates?.length &&
                    dates.map((d, i) => {
                        return (
                            <Box
                                className={'date-container'}
                                key={i}
                                onClick={() => onSelectDate(d, i)}>
                                {!isProgramCalendar && (
                                    <Box
                                        className={`name ${
                                            props?.isProgramJoined && d.programDay === currentDay
                                                ? 'joined'
                                                : 'notJoined'
                                        }`}>
                                        {d.name}
                                    </Box>
                                )}

                                <Box
                                    className={`number  ${datePickerColorUI(
                                        d,
                                        props?.isProgramJoined
                                    )}`}>
                                    {isProgramCalendar ? numberFormat(d?.programDay) : d.number}
                                </Box>

                                {isProgramCalendar && (
                                    <Typography className="program-date">
                                        {props?.isProgramJoined &&
                                            moment(d?.programDate).format('MMM D')}
                                    </Typography>
                                )}

                                {!isProgramCalendar && <Box className={d.dotType + ' dot'}></Box>}
                            </Box>
                        );
                    })}
            </TaoScrollbar>
        </WeekCalendarWrapper>
    );
};

export default WeekCalendar;
