import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import TaoCard from 'Components/common/TaoCard';
import DOMPurify from 'dompurify';
import { TAO_CARD_TYPE } from 'Helpers/Constants';
import React from 'react';
import ListWrapper from './SeeAllList.style';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    BoxLoader,
    DynamicTitleLoader
} from 'Components/common/ShimmerEffectLoader/ShimmerEffectLoader';

const Loader = () => {
    return [1, 2, 3, 4, 5, 6]?.map((value, index) => (
        <Box key={index} className="flex" height={120} width={764} sx={{ margin: '20px 0' }}>
            <Box className="width-max-content">
                <BoxLoader customStyle={{ borderRadius: '16px 8px' }} width={210} height={120} />
            </Box>
            <Box sx={{ width: '100%' }}>
                <DynamicTitleLoader
                    baseCustomStyle={{
                        height: '100%',
                        width: '100%'
                    }}
                    isFullWidth={true}
                    isFullHeight={true}
                    fillWidth={100}
                    fillHeight={10}
                    marginLeft={10}
                    bar={6}
                    rectCustomStyle={{
                        height: '100%',
                        width: '100%'
                    }}
                />
            </Box>
        </Box>
    ));
};
const SeeAllList = ({
    items,
    favoriteContent,
    handleContentRedirection,
    loadMoreItems,
    totalRecords,
    isLoading,
    haveSubcategories = false,
    placeholderMessage = 'No Data Found',
    ...props
}) => {
    return (
        <ListWrapper
            id="scrollableDiv"
            $ContainSubCategory={haveSubcategories}
            $itemsExists={isLoading || items?.length}>
            <InfiniteScroll
                dataLength={items.length}
                next={loadMoreItems}
                hasMore={items?.length !== totalRecords}
                loader={<Loader />}
                className="scroll-height"
                scrollableTarget="scrollableDiv">
                {items?.length > 0 &&
                    items?.map((value, index) => (
                        <Box className="list-item" key={index}>
                            <Box className="width-max-content">
                                <TaoCard
                                    key={value?.id}
                                    backgroundImage={value?.thumbnailImage}
                                    type={
                                        value.type.toUpperCase() === TAO_CARD_TYPE.PROGRAM
                                            ? TAO_CARD_TYPE.PROGRAM
                                            : value?.contentType
                                    }
                                    isTextColorDark={true}
                                    favoriteIconVisible={true}
                                    isFav={value?.isFavorite}
                                    badge={value?.subscription?.badge}
                                    isAccessible={
                                        value?.subscription
                                            ? value?.subscription?.isAccessible
                                            : true
                                    }
                                    isPaidContent={value?.isPaidContent}
                                    isPurchased={value?.isPurchased}
                                    isFeatured={value?.isFeatured}
                                    onFavClick={() => {
                                        favoriteContent(value, index);
                                    }}
                                    onClick={() => handleContentRedirection(value)}
                                    supportedResponsiveView
                                />
                            </Box>
                            <Box className="text-container">
                                <Typography className="sub-title elipsis">
                                    {value?.title}
                                </Typography>
                                <Typography
                                    className="desc"
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(value?.description, {
                                            FORBID_TAGS: ['p']
                                        })
                                    }}
                                />
                            </Box>
                        </Box>
                    ))}
                {isLoading && <Loader />}
                {!isLoading && !items.length && (
                    <Box className="placeholder-wrapper">
                        <Typography className="placeholder-text elipsis">
                            {placeholderMessage}
                        </Typography>
                    </Box>
                )}
            </InfiniteScroll>
        </ListWrapper>
    );
};

export default SeeAllList;
