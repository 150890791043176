import styled from 'styled-components';
import { Dialog, TextField } from '@mui/material';
import CheckIcon from 'Assets/images/PlayList/check-icon.svg';
import { FONTS, COLORS, responsive } from 'Styles/Constants';

export const SelectionPageWrapper = styled(Dialog)`
    .custom-backdrop {
        background: transparent;
    }

    .rounded-popup {
        width: 40%;
        min-width: 300px;
        height: 90vh;
        padding: 1.5rem;
        border-radius: 24px;
    }

    .dialog-background {
        padding: 1.5rem;
        background: rgba(239, 239, 239);
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.12);
        backdrop-filter: blur(30px);
        gap: 20px;
    }

    .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .heading {
            font-family: ${FONTS.PRIMARY};
            font-weight: 600;
            font-size: 24px;
            line-height: 35px;
            text-transform: capitalize;
            color: ${COLORS.PRIMARY_DARK};
            text-align: center;
        }
    }

    .scroll-area {
        gap: 20px;

        .search-bar {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .heading {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                color: ${COLORS.MEDIUM_GREY};
            }
        }

        .filter-container {
            .heading {
                font-family: ${FONTS.PRIMARY};
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                color: ${COLORS.MEDIUM_GREY};
            }

            .filter-container {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .category-section {
                    display: flex;
                    justify-content: space-between;

                    .category {
                        display: flex;

                        ${responsive.LAPTOP_1050`
                            flex-direction: column;
                        `}

                        ${responsive.TABLET`
                            flex-direction: row;
                        `}
                    }

                    .combination-container-left {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        gap: 10px;
                        overflow: auto;

                        .heading {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 35px;
                            text-transform: capitalize;
                            color: ${COLORS.MEDIUM_GREY};
                        }

                        .category-tag-container {
                            gap: 10px;
                            max-width: 100%;

                            .chip-scroll {
                                margin: 0 10px 0 0;
                                display: flex;
                                /* flex-wrap: wrap; */
                                gap: 10px;

                                .chip {
                                    margin: 0 0 0 10px;
                                    font-family: ${FONTS.PRIMARY};
                                    padding: 5px 15px;
                                    background: ${COLORS.MEDIUM_GREY};
                                    border-radius: 15px;
                                    color: ${COLORS.WHITE};
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 29px;

                                    .chip-label {
                                        padding-left: 0px;
                                        padding-right: 0px;
                                    }
                                }
                            }
                        }
                    }

                    .combination-container {
                        display: flex;
                        justify-content: space-between;
                        gap: 10px;
                        align-items: center;
                        height: fit-content;

                        .text {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 20px;
                            text-align: right;
                            text-transform: lowercase;
                            color: ${COLORS.MEDIUM_GREY};
                        }

                        .arrow-button {
                            .arrow-icon {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }

                .selected-tag-chip {
                    margin: 5px 0;

                    .chip {
                        margin: 0 0 0 10px;
                        font-family: ${FONTS.PRIMARY};
                        padding: 5px 15px;
                        background: ${COLORS.MEDIUM_GREY};
                        border-radius: 15px;
                        color: ${COLORS.WHITE};
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 29px;

                        &:first-child {
                            margin: 0;
                        }

                        .chip-label {
                            padding-left: 0px;
                            padding-right: 0px;
                        }
                    }
                }
            }
        }

        .result-container {
            .heading-container {
                .heading {
                    font-family: ${FONTS.PRIMARY};
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 35px;
                    text-transform: capitalize;
                    color: ${COLORS.MEDIUM_GREY};
                }
            }

            .image-container {
                .playlist {
                    flex-wrap: wrap;
                    gap: 10px;
                }

                .card-box {
                    width: 180px;

                    .card-container {
                        height: 100px;
                        width: 180px;
                        position: relative;

                        .image-test-container {
                            width: inherit;
                            height: inherit;
                            position: relative;
                            border-radius: 24px 8px;
                            overflow: hidden;
                            border: 3px solid transparent;
                            &.selected {
                                border-color: ${COLORS.WHITE};
                            }

                            .selected-box {
                                width: inherit;
                                height: inherit;
                                position: absolute;
                                top: 0;
                                right: 0;
                                background: rgba(255, 255, 255, 0.5);

                                .tick-icon {
                                    width: 50px;
                                    height: 50px;
                                    background-image: url(${CheckIcon});
                                    background-position: center;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }

                            .unlock-container {
                                max-width: 160px;
                                background: ${COLORS.PRIMARY_DARK};
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 12;

                                &.badge {
                                    top: 60%;
                                    border-radius: 50px;
                                    background: rgba(101, 101, 101, 0.7);
                                    padding: 5px;
                                    left: 3%;
                                }

                                &.get {
                                    right: 0;
                                    left: auto;
                                    border-radius: 0 0 0 6px;
                                }

                                .unlock-text {
                                    font-family: ${FONTS.TERTIARY_BOLD};
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    color: ${COLORS.WHITE};
                                    padding: 2px 8px;
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .image-description-container {
                        margin: 10px 0px;

                        .text {
                            font-family: ${FONTS.PRIMARY};
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 18px;
                            letter-spacing: 0.01em;
                            color: ${COLORS.TEXT_DARK};
                        }
                    }
                }

                .load-more {
                    padding: 20px 0;
                    width: max-content;
                    margin: 0 auto;

                    &:hover {
                        .load-more-arrow {
                            transition: all 0.3s ease-in-out;
                            margin: 5px 0 0;
                        }
                    }

                    .load-more-text {
                        color: ${COLORS.PRIMARY};
                    }

                    .load-more-arrow {
                        width: 10px;
                        height: 10px;
                        border-right: 1px solid ${COLORS.PRIMARY};
                        border-bottom: 1px solid ${COLORS.PRIMARY};
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    .button-container {
        display: flex;
        justify-content: center;
        margin-top: auto;

        .submit-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 0px;
            gap: 10px;
            width: 100%;
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
            box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
            border-radius: 12px;

            &:disabled {
                background: linear-gradient(180deg, #cbac698f 0%, #b6954f8f 100%);
            }

            .text {
                font-family: ${FONTS.PRIMARY};
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
                text-align: right;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: ${COLORS.WHITE};
            }
        }
    }

    .no-result-found-wrapper {
        width: 100%;
        height: 250px;

        .no-result-message {
            font-family: ${FONTS.PRIMARY};
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            text-align: right;
            letter-spacing: 0.05em;
            text-transform: capitalize;
            color: ${COLORS.PRIMARY_WARM};
        }
    }
`;

export const CustomSearchInputBox = styled(TextField)`
    width: 100%;
    padding: 0px 0px 0px 0px !important;

    .search-root {
        border-radius: 30px;
        height: 50px;
        background-color: ${COLORS.LIGHT_GREY};
        border: 2px solid ${COLORS.WHITE};

        font-family: ${FONTS.PRIMARY};
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        color: ${COLORS.MEDIUM_GREY};
        padding: 14px 14px 14px 10px;
    }

    .search-notchedOutline {
        border: none;
    }
`;
