import { Box } from '@mui/material';
import styled from 'styled-components';
import { responsive } from 'Styles/Constants';

export const WavesAnimationWrapper = styled(Box)`
    width: 80%;
    margin: 20px auto 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    ${responsive.LAPTOP`
        width: 100%;
    `}
`;
