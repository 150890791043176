import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
    Box,
    Avatar,
    AvatarGroup,
    IconButton,
    Typography,
    Button,
    Chip,
    Popover,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { CSSTransition } from 'react-transition-group';

import DefaultRoomImg from 'Assets/images/MeditationRooms/MeditationRoomDetails/room-default-bg.webp';
import { ReactComponent as ShareIcon } from 'Assets/images/Common/share-icon.svg';
import { ReactComponent as FollowIcon } from 'Assets/images/Common/user-plus.svg';
import { ReactComponent as MarkFavIcon } from 'Assets/images/Common/mark-as-favorite.svg';
import NotificationCountIcon from 'Assets/images/Common/message-circle.svg';
import { ReactComponent as ArrowImage } from 'Assets/images/Common/down-arrow.svg';
import { ReactComponent as FilledStarIcon } from 'Assets/images/Common/filled-star.svg';
import { ReactComponent as OutlinedStarIcon } from 'Assets/images/Common/outlined-star.svg';
import { ReactComponent as HeartIcon } from 'Assets/images/MeditationRooms/heart.svg';
import { ReactComponent as FollowerProfileIcon } from 'Assets/images/MeditationRooms/follower-profile-icon.svg';
import { ReactComponent as MessageIcon } from 'Assets/images/MeditationRooms/message-icon.svg';
import { ReactComponent as AlertIcon } from 'Assets/images/MeditationRooms/alert-icon.svg';

import { RoomDetailsWrapper } from './RoomDetails.style';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import Footer from 'Components/common/Footer/Footer';
import { numberFormatter } from 'Helpers';
import ProgramList from './ProgramList';
import SessionList from './SessionList';
import About from './About';
import Announcement from './Announcement';
import AddUserModal from './DetialPageModals/AddUserModal';
import ReportRoomModal from './DetialPageModals/ReportRoomModal';

import { ROOM_TYPES } from 'Helpers/Constants';
import { getWindowDimensions } from 'Helpers';
import { showSideBar, hideSideBar } from 'Redux/Sidebar/Actions';
import { showTopBar, hideTopBar, showFooter, hideFooter } from 'Redux/App/Actions';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
const RoomDetails = ({ ...props }) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isFollowing, setIsFollowing] = useState(false);
    const [selectedTag, setSelectedTag] = useState(FILTER_TYPES[0]);
    const [screenNumber, setScreenNumber] = useState(SCREEN_NUMBER.SESSIONS);
    const [showPopover, setShowPopover] = useState(false);
    const [anchorEle, setAnchorEle] = useState(null);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [openAddUserModal, setOpenAddUserModal] = useState(false);
    const [openReportRoomModal, setOpenReportRoomModal] = useState(false);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);

            dispatch(showSideBar());
            dispatch(showTopBar());
            dispatch(showFooter());
        };
    }, [dispatch]);

    useEffect(() => {
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            dispatch(hideSideBar());
            dispatch(hideTopBar());
            dispatch(hideFooter());
        }
    }, [dispatch, windowDimensions.width]);

    const screenNumberHandler = () => {
        setScreenNumber(SCREEN_NUMBER[selectedTag]);
    };

    const openPopOver = (event) => {
        setAnchorEle(event.currentTarget);
        setShowPopover(true);
    };
    const closePopOver = () => {
        setAnchorEle(null);
        setShowPopover(false);
    };
    const showAddUserModal = () => {
        setOpenAddUserModal(true);
    };
    const closeAddUserModal = () => {
        setOpenAddUserModal(false);
    };
    const showReportModal = () => {
        closePopOver();
        setOpenReportRoomModal(true);
    };
    const closeReportModal = () => {
        setOpenReportRoomModal(false);
    };
    return (
        <>
            <TaoCalligraphyWatermark
                imageStyles={{ opacity: '1', zIndex: 6 }}
                className="details-wrapper"
            />
            <AddUserModal
                open={openAddUserModal}
                onClose={closeAddUserModal}
                roomDetails={state?.roomDetails}
            />
            <ReportRoomModal
                open={openReportRoomModal}
                onClose={closeReportModal}
                roomDetails={state?.roomDetails}
            />
            <RoomDetailsWrapper
                style={{
                    backgroundImage: `url("${
                        state?.roomDetails?.bgOriginalImage || DefaultRoomImg
                    }")`
                }}>
                <Box className="back-button-container">
                    <IconButton onClick={() => navigate(-1)} className="arrow-btn">
                        <ArrowImage />
                    </IconButton>
                </Box>
                <Box className="mobile-title-actions-container">
                    <Box className="btn-container">
                        <IconButton className="action-btn">
                            <ShareIcon />
                        </IconButton>
                    </Box>
                    <Box className="btn-container">
                        <IconButton onClick={showAddUserModal} className="action-btn">
                            <FollowIcon style={{ marginLeft: '5px' }} width="31" height="31" />
                        </IconButton>
                    </Box>
                    <Box className="btn-container">
                        <IconButton className="action-btn">
                            <MarkFavIcon
                                className="fav-icon"
                                style={{ color: '#656565', marginTop: 2 }}
                                width="31"
                                height="31"
                            />
                        </IconButton>
                    </Box>
                    <Box className="btn-container message-counter-container">
                        <img src={NotificationCountIcon} alt="" className="notification-img" />
                        <Typography className="notification-count">24</Typography>
                    </Box>
                </Box>
                <Box className="page-content">
                    <Box className="page-title-container">
                        <Box className="images-container">
                            {state?.roomDetails?.roomType === ROOM_TYPES.TAO_CALLIGRAPHY_FIELD ? (
                                <AvatarGroup>
                                    {state.roomDetails?.hosts?.map((h) => (
                                        <Avatar
                                            className="tcf-avatar"
                                            alt="tcf-teacher"
                                            sx={{ width: 80, height: 80 }}
                                            src={h.img}
                                        />
                                    ))}
                                </AvatarGroup>
                            ) : state?.roomDetails?.roomType === ROOM_TYPES.FEATURED_ROOM ? (
                                <Box className="fr-avatar">
                                    <img src={state?.roomDetails?.host} alt="" />
                                </Box>
                            ) : (
                                <Box className="cr-avatar">
                                    <img src={state?.roomDetails?.host} alt="" />
                                </Box>
                            )}
                        </Box>
                        <Box className="title-actions-container">
                            <Box className="btn-container">
                                <IconButton className="action-btn">
                                    <ShareIcon />
                                </IconButton>
                            </Box>
                            <Box className="btn-container">
                                <IconButton onClick={showAddUserModal} className="action-btn">
                                    <FollowIcon
                                        style={{ marginLeft: '5px' }}
                                        width="31"
                                        height="31"
                                    />
                                </IconButton>
                            </Box>
                            <Box className="btn-container">
                                <IconButton className="action-btn">
                                    <MarkFavIcon
                                        className="fav-icon"
                                        style={{ color: '#656565', marginTop: 2 }}
                                        width="31"
                                        height="31"
                                    />
                                </IconButton>
                            </Box>
                            <Box className="btn-container message-counter-container">
                                <img
                                    src={NotificationCountIcon}
                                    alt=""
                                    className="notification-img"
                                />
                                <Typography className="notification-count">24</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="page-content-container">
                        <Box className="room-info-container">
                            <Box className="room-title-container">
                                <Typography variant="h4" color="primary.dark">
                                    {state?.roomDetails?.title}
                                </Typography>
                                <IconButton className="more-icon" onClick={openPopOver}>
                                    <MoreVert />
                                </IconButton>
                            </Box>
                            <Box className="room-specs-container">
                                <Box className="spec-details-container">
                                    <FilledStarIcon className="filled-start-icon" />
                                    <FilledStarIcon className="filled-start-icon" />
                                    <FilledStarIcon className="filled-start-icon" />
                                    <FilledStarIcon className="filled-start-icon" />
                                    <OutlinedStarIcon className="outlined-start-icon" />
                                    <Typography className="count">
                                        {numberFormatter(state?.roomDetails?.ratingCount)}
                                    </Typography>
                                </Box>
                                <Box className="spec-details-container">
                                    <HeartIcon className="heart-icon" />
                                    <Typography className="count">
                                        {numberFormatter(6430)}
                                    </Typography>
                                </Box>
                                <Box className="spec-details-container">
                                    <FollowerProfileIcon className="person-icon" />
                                    <Typography className="count">
                                        {numberFormatter(state?.roomDetails?.followerCount)}
                                    </Typography>
                                </Box>
                                <Box className="btn-container">
                                    <Button
                                        onClick={() => setIsFollowing((preStat) => !preStat)}
                                        variant={isFollowing ? 'outlined' : 'contained'}
                                        className={`btn ${
                                            isFollowing ? 'following' : 'non-following'
                                        }`}>
                                        {isFollowing ? 'Unfollow' : 'Follow'}
                                    </Button>
                                    <IconButton className="more-icon" onClick={openPopOver}>
                                        <MoreVert />
                                    </IconButton>
                                    <Popover
                                        open={showPopover}
                                        anchorEl={anchorEle}
                                        onClose={closePopOver}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                        transformOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right'
                                        }}
                                        PaperProps={{
                                            style: {
                                                borderRadius: '14px'
                                            }
                                        }}>
                                        <Box className="popover-container">
                                            <List>
                                                <ListItem disablePadding>
                                                    <ListItemButton>
                                                        <ListItemIcon>
                                                            <MessageIcon />
                                                        </ListItemIcon>
                                                        <ListItemText>Message Host</ListItemText>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={showReportModal}>
                                                        <ListItemIcon>
                                                            <AlertIcon />
                                                        </ListItemIcon>
                                                        <ListItemText>Report Room</ListItemText>
                                                    </ListItemButton>
                                                </ListItem>
                                            </List>
                                        </Box>
                                    </Popover>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="filter-text">
                            <Typography variant="h6">ALL TOPICS</Typography>
                        </Box>
                        <Box className="tag-container">
                            {FILTER_TYPES.map((r) => (
                                <Chip
                                    color={selectedTag === r ? 'primary' : 'default'}
                                    clickable
                                    variant={selectedTag === r ? 'outlined' : 'filled'}
                                    label={r}
                                    className={`chip ${
                                        selectedTag === r ? 'selected-chip' : 'non-selected-chip'
                                    }`}
                                    onClick={() => {
                                        setSelectedTag(r);
                                    }}
                                />
                            ))}
                        </Box>
                        <Box className="tab-content-container">
                            <CSSTransition
                                timeout={450}
                                in={
                                    selectedTag === FILTER_TYPES[0] &&
                                    screenNumber === SCREEN_NUMBER.SESSIONS
                                }
                                classNames="tab"
                                onExited={screenNumberHandler}
                                unmountOnExit>
                                <SessionList myRoom={state?.roomDetails?.myRoom} />
                            </CSSTransition>
                            <CSSTransition
                                timeout={450}
                                in={
                                    selectedTag === FILTER_TYPES[2] &&
                                    screenNumber === SCREEN_NUMBER.PROGRAMS
                                }
                                classNames="tab"
                                onExited={screenNumberHandler}
                                unmountOnExit>
                                <ProgramList />
                            </CSSTransition>
                            <CSSTransition
                                timeout={450}
                                in={
                                    selectedTag === FILTER_TYPES[3] &&
                                    screenNumber === SCREEN_NUMBER.ABOUT
                                }
                                classNames="tab"
                                onExited={screenNumberHandler}
                                unmountOnExit>
                                <About
                                    myRoom={state?.roomDetails?.myRoom}
                                    roomType={state?.roomDetails?.roomType}
                                    hosts={state?.roomDetails?.hosts}
                                    singleHost={state?.roomDetails?.hostName}
                                />
                            </CSSTransition>
                            <CSSTransition
                                timeout={450}
                                in={
                                    selectedTag === FILTER_TYPES[1] &&
                                    screenNumber === SCREEN_NUMBER.ANNOUNCEMENT
                                }
                                classNames="tab"
                                onExited={screenNumberHandler}
                                unmountOnExit>
                                <Announcement
                                    roomType={state?.roomDetails?.roomType}
                                    hosts={state?.roomDetails?.hosts}
                                    singleHost={state?.roomDetails?.hostName}
                                />
                            </CSSTransition>
                        </Box>
                    </Box>
                </Box>
                {/* <img src={state?.roomDetails?.bgOriginalImage || DefaultRoomImg} alt="" className="room-image" /> */}
                <Footer showWhiteBackground={true} isBottomZero={true} />
            </RoomDetailsWrapper>
        </>
    );
};

export default RoomDetails;

const FILTER_TYPES = ['SESSIONS', 'ANNOUNCEMENT', 'PROGRAMS', 'ABOUT'];

const SCREEN_NUMBER = {
    [FILTER_TYPES[0]]: 0,
    [FILTER_TYPES[1]]: 1,
    [FILTER_TYPES[2]]: 2,
    [FILTER_TYPES[3]]: 3
};
