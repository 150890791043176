import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Box, IconButton } from '@mui/material';
import SliderNextButton from 'Assets/images/Common/next-arrow.svg';
import SliderPreviousButton from 'Assets/images/Common/previous-arrow.svg';

import { BREAKPOINTS_VALUE, responsive } from 'Styles/Constants';
import styled from 'styled-components';
import { getWindowDimensions } from 'Helpers';

const TaoSlider = React.forwardRef(
    (
        {
            children,
            sliderType,
            onNextArrowClick,
            slidesToShow = 0,
            slidesToShowLaptop = 2,
            showArrows = true,
            prevArrowPercentage = '-9%',
            nextArrowPercentage = '-9%',
            showShadow = true,
            slidesToShowDesktop,
            centerMode = false,
            isLeftSideShadeVisible = false,
            currentSlide = 0,
            totalSlides,
            shadeWidths = {},
            onSwipe = () => {},
            initialSlide = 0,
            loading = false,
            ...props
        },
        ref
    ) => {
        const [slideToShow, setSlideToShow] = useState(slidesToShow ? slidesToShow : 0);
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

        useEffect(() => {
            setSlideToShow(slidesToShow);
            if (windowDimensions.width <= BREAKPOINTS_VALUE.LAPTOP)
                setSlideToShow(slidesToShowLaptop);
            if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) setSlideToShow(1);

            const handleResize = () => {
                setWindowDimensions(getWindowDimensions());
            };

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, [slidesToShow, windowDimensions.width, slidesToShowLaptop]);

        const SlideNextArrow = ({ currentSlide, slideCount, type, ...props }) => {
            return (
                <React.Fragment>
                    {!(slideCount - currentSlide <= (slideToShow || 3)) && (
                        <IconButton
                            {...props}
                            className="nextArrow arrow"
                            id="nextArrow"
                            sx={{ height: '23px', width: '14px', right: nextArrowPercentage }}
                            onClick={() => {
                                ref?.current?.slickNext();
                            }}>
                            <img src={SliderNextButton} alt="" />
                        </IconButton>
                    )}
                </React.Fragment>
            );
        };

        const SlidePreviousArrow = ({ currentSlide, slideCount, ...props }) => {
            return (
                <React.Fragment>
                    {!!currentSlide && (
                        <IconButton
                            {...props}
                            className="prevArrow arrow"
                            id="prevArrow"
                            sx={{
                                height: '23px',
                                width: '14px',
                                left: prevArrowPercentage
                            }}>
                            <img src={SliderPreviousButton} alt="" />
                        </IconButton>
                    )}
                </React.Fragment>
            );
        };

        let SLIDER_SETTINGS = {
            initialSlide,
            slidesToShow: slideToShow || 3,
            slidesToScroll: slideToShow || 3,
            arrows: showArrows,
            infinite: false,
            swipeToSlide: true,
            variableWidth: true,
            accessibility: true,
            centerMode: centerMode,
            nextArrow: <SlideNextArrow />,
            prevArrow: <SlidePreviousArrow />,
            focusOnSelect: false,
            draggable: true,
            beforeChange: (oldIndex, newIndex) => {
                onSwipe(newIndex);
            },
            responsive: [
                {
                    breakpoint: BREAKPOINTS_VALUE.DISPLAY,
                    settings: {
                        slidesToShow: slideToShow || 3
                    }
                },
                {
                    breakpoint: BREAKPOINTS_VALUE.DESKTOP,
                    settings: {
                        slidesToShow: slidesToShowDesktop || 2,
                        slidesToScroll: slidesToShowDesktop || 2
                    }
                },
                {
                    breakpoint: BREAKPOINTS_VALUE.LAPTOP,
                    settings: {
                        slidesToShow: slidesToShowLaptop || 2,
                        slidesToScroll: slidesToShowLaptop || 2
                    }
                },
                {
                    breakpoint: BREAKPOINTS_VALUE.TABLET,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: BREAKPOINTS_VALUE.PHABLET,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false
                    }
                }
            ]
        };

        return (
            <ParentWrapper {...shadeWidths}>
                {showShadow && (
                    <>
                        {(currentSlide !== totalSlides || !totalSlides) && !loading && (
                            <Box className="shade" />
                        )}
                        {isLeftSideShadeVisible && currentSlide !== 0 && !loading && (
                            <LeftSideShadow className="left-shade" {...shadeWidths} />
                        )}
                    </>
                )}
                <TaoSliderWrapper
                    gap={props?.gap || 0}
                    cardHeight={props?.cardHeight || 200}
                    slickTrackPadding={props?.slickTrackPadding || '5px'}>
                    <Slider {...SLIDER_SETTINGS} ref={ref}>
                        {children}
                    </Slider>
                </TaoSliderWrapper>
            </ParentWrapper>
        );
    }
);

export default TaoSlider;

export const ParentWrapper = styled(Box)`
    position: relative;

    .shade {
        height: 100%;
        width: ${(props) => `${props?.laptop || 80}px`};
        position: absolute;
        background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        transform: rotate(180deg);
        z-index: 20;
        right: -1px;
        top: 0;

        ${responsive.TABLET`
            width: ${(props) => `${props?.tablet || 60}px`};
        `}

        ${responsive.PHABLET`
            width: ${(props) => `${props?.phablet || 30}px`};
        `}

        ${responsive.MOBILE`
            width: ${(props) => `${props?.mobile || 10}px`};
        `}
    }
`;

export const LeftSideShadow = styled(Box)`
    height: 100%;
    width: ${(props) => `${props?.laptop || 20}px`};
    position: absolute;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    transform: rotate(360deg);
    z-index: 20;
    left: -1px;
    top: 0;

    ${responsive.TABLET`
        width: ${(props) => `${props?.tablet || 60}px`};
    `}

    ${responsive.PHABLET`
        width: ${(props) => `${props?.phablet || 30}px`};
    `}

    ${responsive.MOBILE`
        width: ${(props) => `${props?.mobile || 10}px`};
    `}
`;

export const TaoSliderWrapper = styled.div`
    &.slick-slider {
        position: relative;
        display: flex;
    }
    .slider-content {
        position: relative;
        margin-bottom: 25px;
    }

    .slick-slider {
        ${responsive.PHABLET`
            display: flex;
        `}
    }

    /* .slick-track {
        display: flex;
    } */

    .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;
        margin: ${(props) => `0 ${props?.gap}px 0 0`};
    }

    .slick-initialized .slick-slide {
        display: block;
    }

    .slick-track {
        padding: ${(props) => `${props?.slickTrackPadding} 0`};
        /* gap: ${(props) => `${props?.gap}px`}; */
        display: flex;
        /* transform: none !important; */
    }

    .prevArrow {
        display: block;
        position: absolute;
        top: ${(props) => `calc(${props?.cardHeight || 200}px / 2);`};
        height: 40px;
        width: 40px;

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .nextArrow {
        display: block;
        position: absolute;
        top: ${(props) => `calc(${props?.cardHeight || 200}px / 2);`};
        height: 40px;
        width: 40px;

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
`;
