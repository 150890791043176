import { Box } from "@mui/system";
import styled from "styled-components";
import { COLORS, FONTS } from "Styles/Constants";

export const ReadMoreWrapper = styled(Box)`
    .text{
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: ${COLORS.TEXT_DARK};
    }

    .read-hide-text{
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: ${COLORS.PRIMARY_DARK};
        cursor: pointer;
        text-transform: capitalize;
    }
`