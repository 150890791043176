import { Drawer } from '@mui/material';
import styled from 'styled-components';
import CrossIcon from 'Assets/images/Common/close-icon.svg';
import RightDirectionArrow from 'Assets/images/Common/next-arrow.svg';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const MenuDrawerWrapper = styled(Drawer)`
    .paper-root {
        width: 400px;
        padding: 20px 0 100px;
        background: ${COLORS.LIGHT_GREY_SECONDARY};
        box-shadow: -2px 0px 12px rgba(0, 0, 0, 0.25);

        ${responsive.TABLET`
            width: 100%;
            padding: 20px 15% 100px;
        `}
        ${responsive.PHABLET`
            width: 100%;
            padding: 20px 0 100px;
        `}
    }

    .cross-icon {
        background-image: url(${CrossIcon});
        background-position: center;
        background-size: contain;
        height: 16px;
        width: 16px;
        display: flex;
        margin: 0 20px 0 auto;
    }

    .init-cards {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(10px);
        border-radius: 12px;
        height: 60px;
        width: 100%;
        color: ${COLORS.PRIMARY_WARM};
        margin: 15px 0 0;
        font-size: 16px;
        text-transform: uppercase;
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};

        .icon {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 24px;
            width: 24px;
            margin: 0 10px 0 0;

            &.icon-border {
                border-radius: 50%;
            }
        }

        .main-menu-name {
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            font-size: 16px;
            text-transform: uppercase;
            color: ${COLORS.BLACK};
        }
    }

    .divider {
        border: 1px solid ${COLORS.LIGHT_GREY};
        margin: 20px 0;
    }

    .chips-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .chip {
            background: ${COLORS.WHITE};
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            border-radius: 24px;
            width: 100%;
            max-width: 170px;
            margin: 15px 0 0;
            height: 48px;
            font-size: 16px;
            color: ${COLORS.TEXT_DARK};
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            display: flex;
            justify-content: flex-start;
            padding: 0 20px;
            text-transform: capitalize;
            overflow: hidden;

            ${responsive.TABLET`
                width: 48%;
                max-width: 48%;
            `}

            .icon {
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                min-height: 24px;
                min-width: 24px;
                margin: 0 10px 0 0;

                ${responsive.PHABLET`
                    margin: 0 5px 0 0;
                `}
            }

            .menu-name {
                font-family: ${FONTS.PRIMARY};
                font-size: 18px;
                text-transform: capitalize;
                color: ${COLORS.TEXT_DARK};
            }
        }
    }

    .logout-btn {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(10px);
        border-radius: 12px;
        width: calc(100% - 40px);
        margin: 0 auto;
        font-size: 18px;
        color: ${COLORS.PRIMARY_WARM};
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        height: 60px;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;

        .logout-icon {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            height: 24px;
            width: 24px;
            margin: 0 10px 0 0;
        }
    }

    .redirection-links {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0 0;

        .title {
            font-size: 16px;
            color: ${COLORS.PRIMARY_WARM};
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        }

        .right-arrow {
            background-image: url(${RightDirectionArrow});
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            min-height: 15px;
            min-width: 10px;
        }
    }
`;
