import { Box } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS } from 'Styles/Constants';

const DIALOG_COLOR = '#cacaca66';

export const PageDialogWrapper = styled(Box)`
    background: ${DIALOG_COLOR};
    padding: 10px;
    height: 100%;
    width: 100%;
    .title {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
        text-transform: capitalize;
        color: ${COLORS.PRIMARY_DARK};
    }

    .close-icon {
        float: right;
    }

    .dialog-content {
        height: calc(100% - 100px);

        .dialog-body-text {
            font-family: ${FONTS.PRIMARY};
            font-size: 21px;
            color: ${COLORS.DARK_GREY};
        }
    }
`;
